import { Link } from 'react-router-dom';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { useActionLogSender } from '@framework/action-log';
import { useCallback } from 'react';
import { ViewModelOperation } from '@view-model/domain/view-model';
import { ViewModelId, WorkspaceId } from '@schema-common/base';

type Props = {
    workspaceId: WorkspaceId;
    viewModelId: ViewModelId;
};

export const TrashedMessageModal: React.FC<Props> = ({ workspaceId, viewModelId }: Props) => {
    const actionLogSender = useActionLogSender();
    const handleRestore = useCallback(() => {
        ViewModelOperation.restore(viewModelId, actionLogSender);
    }, [actionLogSender, viewModelId]);

    return (
        <GeneralModal
            isOpen={true}
            onClose={() => void 0}
            title={'このモデルは削除されました'}
            submitButton={<></>}
            cancelButton={<></>}
            shouldCloseOnOverlayClick={false}
        >
            <div className="p-4" style={{ width: '600px' }}>
                このモデルは削除されたため、操作することは出来ません。
            </div>
            <div className="flex flex-row gap-x-4 p-4">
                <Link
                    to={UserPagePathBuilder.workspacePage(workspaceId)}
                    className="rounded bg-brand px-3 py-2 font-bold text-white hover:bg-brand-dark"
                >
                    ダッシュボードに戻る
                </Link>
                <button
                    className="rounded border border-gray-400 bg-gray-100 px-3 py-2 text-brand hover:bg-gray-300"
                    onClick={handleRestore}
                >
                    ゴミ箱から戻す
                </button>
            </div>
        </GeneralModal>
    );
};

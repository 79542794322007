import { ZoomTransform } from 'd3-zoom';
import { Rect } from '@view-model/models/common/basic';

/**
 * 幅width, 高さheightの領域で、中心座標が(0, 0) となるようなViewBoxです。
 */
export class ViewBox {
    public readonly width: number;
    public readonly height: number;

    public constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    public get minX(): number {
        return -this.width / 2;
    }

    public get minY(): number {
        return -this.height / 2;
    }

    /**
     * svgタグのviewBox属性に設定する文字列を返します。
     */
    public toSVGAttribute(): string {
        return `${this.minX} ${this.minY} ${this.width} ${this.height}`;
    }

    public visibleArea(t: ZoomTransform): Rect {
        return Rect.fromLTRB(
            t.invertX(this.minX),
            t.invertY(this.minY),
            t.invertX(this.minX + this.width),
            t.invertY(this.minY + this.height)
        );
    }
}

import { TOP_NAVIGATION_HEIGHT } from '@framework/ui/elements/TopNavigation';

type Props = {
    children: React.ReactNode;
};

export const CenteredContent: React.FC<Props> = ({ children }: Props) => {
    return (
        <div
            className="flex items-center justify-center"
            style={{
                height: `calc(100vh - ${TOP_NAVIGATION_HEIGHT}px)`,
            }}
        >
            {children}
        </div>
    );
};

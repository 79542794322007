import { useCallback, useMemo } from 'react';
import { ModelId, ViewId } from '@schema-common/base';
import { ViewEntity } from '@view-model/domain/view';
import { StickyModelContentsOperation } from '@view-model/adapter';
import { ModelCollection } from '@view-model/domain/model';
import { useViewModelOperationLogSender } from '@model-framework/action-log';
import { ViewCollectionContents } from '@view-model/domain/view/ViewCollectionContents';
import { Point, Rect } from '@view-model/models/common/basic';
import { useGetTransformedVisibleAreaCenterPoint } from '../VisibleAreaCenterPointProvider';

const defaultValue = { selectedView: undefined, selectedViewRect: Rect.fromSize(0, 0) };

const useSelectedViewContent = (
    selectedViewIds: Set<ViewId>,
    views: ViewCollectionContents
): { selectedView: ViewEntity | undefined; selectedViewRect: Rect } => {
    return useMemo(() => {
        if (selectedViewIds.size !== 1) return defaultValue;
        const selectedViewId = Array.from(selectedViewIds)[0];

        const selectedView = views.findView(selectedViewId);
        const selectedViewPosition = views.findPosition(selectedViewId);

        if (!selectedView || !selectedViewPosition) return defaultValue;

        return {
            selectedView,
            selectedViewRect: selectedView.getRect(selectedViewPosition),
        };
    }, [selectedViewIds, views]);
};

const useSelectedStickyModelContentsOperation = (
    operations: Record<ModelId, StickyModelContentsOperation>,
    models: ModelCollection,
    selectedView: ViewEntity | undefined
): StickyModelContentsOperation | undefined => {
    if (!selectedView) return;

    const selectedModel = models.get(selectedView.modelKey);
    if (!selectedModel) return;

    return operations[selectedModel.id];
};

export const useViewMenuHandlers = (
    readonly: boolean,
    selectedViewIds: Set<ViewId>,
    views: ViewCollectionContents,
    models: ModelCollection,
    operations: Record<ModelId, StickyModelContentsOperation>,
    handleCreatingCommentAdd: (modelId: ModelId, position: Point) => void
): {
    handleAddNode(): void;
    handleAddZone(): void;
    handleAddDescriptionPanel(): void;
    handleAddComment(): void;
} => {
    const logSender = useViewModelOperationLogSender();
    const { selectedView, selectedViewRect } = useSelectedViewContent(selectedViewIds, views);
    const getTransformedVisibleAreaCenterPoint = useGetTransformedVisibleAreaCenterPoint();
    const selectedOperation = useSelectedStickyModelContentsOperation(operations, models, selectedView);

    const handleAddNode = useCallback((): void => {
        if (readonly || !selectedView || !selectedOperation) return;
        const transformedVisibleAreaCenterPoint = getTransformedVisibleAreaCenterPoint(selectedViewRect);
        const createPoint = selectedOperation.getCreatePoint(transformedVisibleAreaCenterPoint);
        const nodeId = selectedOperation.createNode(createPoint);

        logSender('sticky:node:create', {
            nodeId,
            cause: 'menu_button',
            viewId: selectedView.id,
            viewName: selectedView.name.value,
        });
    }, [logSender, readonly, selectedOperation, selectedView, selectedViewRect, getTransformedVisibleAreaCenterPoint]);

    const handleAddZone = useCallback((): void => {
        if (readonly || !selectedView || !selectedOperation) return;
        const transformedVisibleAreaCenterPoint = getTransformedVisibleAreaCenterPoint(selectedViewRect);
        const createPoint = selectedOperation.getCreatePoint(transformedVisibleAreaCenterPoint);
        const zoneId = selectedOperation.createZone(createPoint);

        logSender('sticky:zone:create', {
            zoneId,
            cause: 'menu_button',
            viewId: selectedView.id,
            viewName: selectedView.name.value,
        }).then();
    }, [logSender, readonly, selectedOperation, selectedView, selectedViewRect, getTransformedVisibleAreaCenterPoint]);

    const handleAddDescriptionPanel = useCallback(() => {
        if (readonly || !selectedView || !selectedOperation) return;
        const transformedVisibleAreaCenterPoint = getTransformedVisibleAreaCenterPoint(selectedViewRect);
        const createPoint = selectedOperation.getCreatePoint(transformedVisibleAreaCenterPoint);
        const panelId = selectedOperation.addDescriptionPanel(createPoint);

        logSender('sticky:description_panel:create', {
            panelId,
            cause: 'menu_button',
            viewId: selectedView.id,
            viewName: selectedView.name.value,
        }).then();
    }, [logSender, readonly, selectedOperation, selectedView, selectedViewRect, getTransformedVisibleAreaCenterPoint]);

    const handleAddComment = useCallback(() => {
        if (readonly || !selectedView || !selectedOperation) return;
        const transformedVisibleAreaCenterPoint = getTransformedVisibleAreaCenterPoint(selectedViewRect);
        const createPoint = selectedOperation.getCreatePoint(transformedVisibleAreaCenterPoint);
        handleCreatingCommentAdd(selectedView.modelId, createPoint);
    }, [
        handleCreatingCommentAdd,
        readonly,
        selectedOperation,
        selectedView,
        selectedViewRect,
        getTransformedVisibleAreaCenterPoint,
    ]);

    return { handleAddNode, handleAddZone, handleAddDescriptionPanel, handleAddComment };
};

import { Size } from '@view-model/models/common/basic';

type Props = {
    size: Size;
    children?: React.ReactNode;
    onClick(): void;
    isSelected: boolean;
    transform: string;
};

export const RectMenu: React.FC<Props> = ({ size, onClick, isSelected, children, transform }: Props) => {
    return (
        <g transform={transform} onClick={onClick}>
            <rect
                className={isSelected ? 'fill-brand' : 'fill-white'}
                x={-size.width / 2}
                y={-size.height / 2}
                width={size.width}
                height={size.height}
            />
            {children}
        </g>
    );
};

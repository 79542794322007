import { DataSnapshot, RefBuilder, RTDBPath, ServerValue } from '@framework/repository';
import { ReactElement, useEffect, useMemo } from 'react';
import { BuildInfo } from '@config/build-info';
import {
    VersionInfoJSON,
    VersionInfoJSONOnWrite,
} from '@schema-app/application-config/versions/{hostname}/{version}/VersionInfoJSON';
import { useActionLogSender } from '@framework/action-log';
import { toast } from 'react-hot-toast';
import { useSnapshot } from '@framework/hooks';
import { Timestamp } from '@framework/Timestamp';
import Cookies from 'js-cookie';

type Props = {
    children: ReactElement;
};

export const WithVersionInfo: React.FC<Props> = ({ children }: Props) => {
    return (
        <>
            {children}

            {
                // バージョン番号がNaN(Not a Number)の場合には、バージョン情報の保存・監視は行わない
                isNaN(BuildInfo.version) ? null : (
                    <>
                        <StoreVersionInfo />
                        <WatchCurrentVersionInfo />
                        <ToastNewVersion />
                    </>
                )
            }
        </>
    );
};

/**
 * バージョン情報を保存する
 */
const StoreVersionInfo: React.FC<unknown> = () => {
    useEffect(() => {
        const ref = RefBuilder.ref(RTDBPath.ApplicationConfig.versionPath(window.location.hostname, BuildInfo.version));

        ref.get().then((snapshot) => {
            const info = snapshot.val() as VersionInfoJSON | null;
            if (info === null) {
                // バージョン情報が存在しない時には新しく作成する
                const value: VersionInfoJSONOnWrite = {
                    version: BuildInfo.version,
                    firstLoadedAt: ServerValue.TIMESTAMP,
                    latestLoadedAt: ServerValue.TIMESTAMP,
                    active: true,
                };
                ref.set(value);
            } else if (info.active) {
                // バージョン情報がすでに存在するならば、 latestLoadedAt の値のみを更新する
                ref.child('latestLoadedAt').set(ServerValue.TIMESTAMP);
            }
        });
    }, []);

    return <></>;
};

/**
 * 現在のバージョンのバージョン情報を監視し、 active の値が false に変化した時にリロードを行う
 */
const WatchCurrentVersionInfo: React.FC<unknown> = () => {
    const logSender = useActionLogSender();

    useEffect(() => {
        const ref = RefBuilder.ref(
            RTDBPath.ApplicationConfig.versionActivePath(window.location.hostname, BuildInfo.version)
        );
        const onValue = async (snapshot: DataSnapshot) => {
            const value = snapshot.val() as boolean | null;
            if (value === null) return;

            if (value === false) {
                await logSender('global:reload', {
                    currentVersion: BuildInfo.version,
                    cause: 'CurrentVersion Inactivated',
                });
                window.location.reload();
            }
        };

        ref.on('value', onValue);
        return () => ref.off('value', onValue);
    }, [logSender]);

    return <></>;
};

const ToastNewVersion: React.FC<unknown> = () => {
    const logSender = useActionLogSender();

    const [timestampOffset] = useSnapshot<number>({
        path: RTDBPath.Firebase.serverTimeOffsetPath(),
        load: ({ snapshot }) => snapshot.val(),
    });

    const [newerVersions] = useSnapshot<VersionInfoJSON[]>({
        path: RTDBPath.ApplicationConfig.versionsPath(window.location.hostname),
        load: ({ getChildValues }) => {
            const values = Object.values(getChildValues() || {}) as VersionInfoJSON[];
            return values
                .filter((info) => info.active && info.version > BuildInfo.version)
                .sort((info) => info.version)
                .reverse();
        },
    });

    const newVersion = useMemo(() => {
        const now = Timestamp.now().addMilliSeconds(timestampOffset || 0);
        // トーストメッセージで更新を促すのは、当該バージョンの初回表示から一定時間が経過している場合に限定する
        const latest = newerVersions?.find((info) => now.isAfter(new Timestamp(info.firstLoadedAt).addMinutes(30)));
        return latest?.version || 0;
    }, [newerVersions, timestampOffset]);

    useEffect(() => {
        const id = 'new-version';

        toast.dismiss(id);

        const reload = async () => {
            await logSender('global:reload', {
                currentVersion: BuildInfo.version,
                cause: 'NewVersionToast',
            });
            window.location.reload();
        };

        if (newVersion > BuildInfo.version) {
            toast(
                <div>
                    新しいBalusがリリースされています
                    <a
                        onClick={reload}
                        className="ml-2 cursor-pointer font-bold text-blue-700 underline hover:text-blue-900"
                    >
                        再読み込み
                    </a>
                </div>,
                { id, duration: Infinity }
            );
        }
    }, [logSender, newVersion]);

    const autoReloadNewVersion = useMemo(() => {
        const now = Timestamp.now().addMilliSeconds(timestampOffset || 0);
        // 自動リロードするのは、当該バージョンの初回表示から一定時間が経過している場合に限定する
        const latest = newerVersions?.find((info) => now.isAfter(new Timestamp(info.firstLoadedAt).addMinutes(60)));
        return latest?.version || 0;
    }, [newerVersions, timestampOffset]);

    useEffect(() => {
        if (autoReloadNewVersion <= BuildInfo.version) return;

        const cookieKey = `Balus-AutoReload-${window.location.pathname}`;

        const reload = async () => {
            if (Cookies.get(cookieKey)) {
                await logSender('global:reload', {
                    currentVersion: BuildInfo.version,
                    cause: 'AutoReload:Skipped',
                });
                return;
            }

            // リロードの無限ループを防ぐために Cookie を設定してから再読み込みを行う (Cookie の有効期限は 5 分間)
            Cookies.set(cookieKey, 'true', { expires: 5 / (24 * 60) });

            await logSender('global:reload', {
                currentVersion: BuildInfo.version,
                cause: 'AutoReload',
            });
            window.location.reload();
        };

        // このタブが非表示状態ならば自動でリロードする
        if (document.visibilityState === 'hidden') {
            reload();
            return;
        }

        // このタブが表示状態の場合には、タブの表示状態が変化した時にリロードする
        document.addEventListener('visibilitychange', reload);
        return () => document.removeEventListener('visibilitychange', reload);
    }, [autoReloadNewVersion, logSender]);

    return <></>;
};

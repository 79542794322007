import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { DisconnectedNotificationModal } from '@user/pages/ViewModelPage/internal/DisconnectedNotificationModal';
import { useWindowActive } from '@view-model/models/common/hooks/useWindowActive';
import { useNetworkConnected } from '@view-model/models/common/hooks/useNetworkConnected';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';

const ConnectedToastId = 'connected';
const DisconnectedToastId = 'disconnected';

export const ConnectionStatusView: React.FC = () => {
    const [showDisconnectedModal, setShowDisconnectedModal] = useState<boolean>(false);

    const windowActive = useWindowActive();
    const connected = useNetworkConnected(10);
    const prevConnected = usePrevious(connected, true);

    useEffect(() => {
        if (connected) {
            if (!prevConnected) {
                // 切断状態→接続状態になった場合のみ、接続回復の処理を行う
                toast('インターネットに接続されました | Connected', { id: ConnectedToastId });
                setShowDisconnectedModal(false);
                toast.dismiss(DisconnectedToastId);
            }
            return;
        }

        // ウィンドウがアクティブでない場合は警告メッセージを表示しない
        if (!windowActive) return;

        toast.error('インターネットに接続されていません | No internet connection', {
            duration: Infinity,
            id: DisconnectedToastId,
        });
        setShowDisconnectedModal(true);
    }, [connected, prevConnected, windowActive]);

    const handleCloseModal = () => {
        setShowDisconnectedModal(false);
    };

    return <>{showDisconnectedModal && <DisconnectedNotificationModal onClose={handleCloseModal} />}</>;
};

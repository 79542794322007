import { useState } from 'react';
import { LinkStyle } from '../../domain';
import { MenuContainer } from '@model-framework/menu';
import { Point } from '@view-model/models/common/basic';
import { LinkMenuId } from './LinkMenuId';
import { LinkMenuContent } from './LinkMenuContent';
import { MenuSize } from '@view-model/models/sticky/ui';
import { LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';

type Props = {
    style: LinkStyle;
    isMultiplicityEnabled: boolean;
    position: Point;
    onLineStyleSelected(lineStyle: LinkLineStyle): void;
    onMarkStyleSelected(markStyle: LinkMarkStyle): void;
    onColorSelected(color: LinkColor): void;
    onToggleMultiplicityEnabled(): void;
    onReverseLink(): void;
    onDeleteLink(): void;
};

export const LinkMenu: React.FC<Props> = ({
    position,
    style,
    isMultiplicityEnabled,
    onLineStyleSelected,
    onMarkStyleSelected,
    onColorSelected,
    onToggleMultiplicityEnabled,
    onReverseLink,
    onDeleteLink,
}: Props) => {
    const [openedMenuId, setOpenedMenuId] = useState<LinkMenuId | null>(null);

    const menuSize = { width: MenuSize * Object.keys(LinkMenuId).length, height: MenuSize };

    return (
        <MenuContainer position={position} size={menuSize} positionOrigin={'center-bottom'}>
            {Object.values(LinkMenuId).map((menuId, i) => (
                <g key={menuId} transform={`translate(${MenuSize * i}, 0)`}>
                    <LinkMenuContent
                        menuId={menuId}
                        style={style}
                        isMultiplicityEnabled={isMultiplicityEnabled}
                        isOpen={openedMenuId === menuId}
                        onMenuOpen={() => setOpenedMenuId(menuId)}
                        onMenuClose={() => setOpenedMenuId(null)}
                        onLineStyleSelected={onLineStyleSelected}
                        onMarkStyleSelected={onMarkStyleSelected}
                        onColorSelected={onColorSelected}
                        onToggleMultiplicityEnabled={onToggleMultiplicityEnabled}
                        onReverseLink={onReverseLink}
                        onDeleteLink={onDeleteLink}
                    />
                </g>
            ))}
        </MenuContainer>
    );
};

import { Point } from '@view-model/models/common/basic';
import { StickyNodeCreationOperation, StickyNode, NodeStyle } from '@view-model/models/sticky/StickyNodeView';

/**
 * UI要素に変換する前提のテキスト要素。
 * テキストと位置だけを予め決めて取り回したい場合に利用する。
 */
export class TextElement {
    constructor(
        private readonly text: string,
        private readonly position: Point
    ) {}

    toStickyNode(nodeCreationOperator: StickyNodeCreationOperation, offset: Point, style: NodeStyle): StickyNode {
        return nodeCreationOperator.buildNode(this.position.add(offset), style, this.text);
    }
}

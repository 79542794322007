import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataSnapshot, RTDBPath, RefBuilder, ServerValue } from '@framework/repository';
import { useAnnouncements } from './useAnnouncements';
import { useCurrentUserId } from '@framework/auth';
import { Timestamp } from '@framework/Timestamp';

const useLastViewedTime = (userId: string | null): Timestamp | null => {
    const [lastViewedTime, setLastViewedTime] = useState<Timestamp | null>(null);

    useEffect(() => {
        if (!userId) return;

        const ref = RefBuilder.ref(RTDBPath.Notification.announcementLastViewTimePath(userId));
        const callback = (snapshot: DataSnapshot) => {
            const value = snapshot.val();
            setLastViewedTime(value ? new Timestamp(value) : null);
        };

        ref.on('value', callback);
        return () => ref.off('value', callback);
    }, [userId]);

    return lastViewedTime;
};

type Returns = {
    badge: string;
    markAsRead(): void;
};

/**
 * お知らせの未既読状態と、最終閲覧時刻の更新を行う関数を返すカスタムフックです。
 */
export const useAnnouncementControl = (): Returns => {
    const currentUserId = useCurrentUserId();
    const lastViewedTime = useLastViewedTime(currentUserId);
    const announcements = useAnnouncements();

    const badge = useMemo<Returns['badge']>(() => {
        const count = announcements.length;
        // お知らせが0件の場合には「未読なし」
        if (count === 0) return '';

        // お知らせの最終閲覧時刻が null の場合には、お知らせ件数が未読件数
        if (!lastViewedTime) {
            return count > 9 ? '9+' : `${count}`;
        }

        // お知らせの最終閲覧時刻よりも後に投稿されたお知らせの件数が未読件数
        const unreadCount = announcements.filter((a) => a.isAfter(lastViewedTime)).length;
        if (unreadCount === 0) return '';
        return unreadCount > 9 ? '9+' : `${unreadCount}`;
    }, [announcements, lastViewedTime]);

    const markAsRead = useCallback(async () => {
        if (!currentUserId) return;

        const ref = RefBuilder.ref(RTDBPath.Notification.announcementLastViewTimePath(currentUserId));
        await ref.set(ServerValue.TIMESTAMP);
    }, [currentUserId]);

    return { badge, markAsRead };
};

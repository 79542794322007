import { MutableRefObject, useEffect, useRef } from 'react';

export const useMountedRef = (): MutableRefObject<boolean> => {
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    return mounted;
};

import { useMemo } from 'react';
import { Workspace } from '@workspace/domain/workspace';
import { useSidebarTreeContext } from './SidebarTreeContext';
import { WorkspaceItem } from './WorkspaceItem';

type Props = {
    target: 'Shared' | 'Personal' | 'SearchResult';
};

export const SidebarTree: React.FC<Props> = ({ target }: Props) => {
    const {
        records: { workspaces },
        control: { scrolledWorkspaceId },
    } = useSidebarTreeContext();

    const sortedWorkspaces = useMemo(() => {
        const workspaceList = Object.values(workspaces);

        if (target === 'SearchResult') {
            return [
                ...workspaceList.filter((ws) => ws.isShared).sort(Workspace.compare),
                ...workspaceList.filter((ws) => ws.isPersonal).sort(Workspace.compare),
            ];
        }

        return workspaceList
            .filter((ws) => {
                if (target === 'Shared') return ws.isShared;
                if (target === 'Personal') return ws.isPersonal;
                return false; // unreachable
            })
            .sort(Workspace.compare);
    }, [target, workspaces]);

    return (
        <div>
            {sortedWorkspaces.map((workspace) => (
                <WorkspaceItem
                    key={workspace.id}
                    workspace={workspace}
                    shouldBeScrolled={scrolledWorkspaceId === workspace.id}
                />
            ))}
        </div>
    );
};

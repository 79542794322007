import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

export class FirebaseAnalyticsOperation {
    private static readonly analytics: firebase.analytics.Analytics = firebase.analytics();

    static setUserId(userId: string): void {
        this.analytics.setUserId(userId);
    }

    static logEvent(eventName: string, eventParams?: Record<string, string>): void {
        this.analytics.logEvent(eventName, eventParams);
    }
}

import { NodeKey } from '@view-model/domain/key';
import { StickyNode } from './StickyNode';
import { NodeFontSize, NodeStyleSet } from './NodeStyle';
import { Id } from '@framework/domain';
import { Point, Rect } from '@view-model/models/common/basic';
import { stringify } from 'csv-stringify/sync';
import { ThemeColor } from '@view-model/models/common/color';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { CompositeCommand, ICommand } from '@model-framework/command';
import { RectShape, ShapeMap } from '@model-framework/shape';
import { UserKey } from '@user/domain';
import { NodeId } from '@schema-common/base';
import { StickyNodeJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/nodes/{nodeId}/StickyNodeJSON';
import { NodeRepository } from './NodeRepository';

export class NodeCollection {
    private readonly nodes: StickyNode[];

    public constructor(nodes: StickyNode[] = []) {
        this.nodes = nodes.concat();
    }

    public findByKey(key: NodeKey): StickyNode | undefined {
        return this.nodes.find((node) => node.key.isEqual(key));
    }

    findById(nodeId: NodeId): StickyNode | undefined {
        return this.nodes.find((node) => node.id == nodeId);
    }

    public includeKey(key: NodeKey): boolean {
        return this.nodes.some((node) => node.key.isEqual(key));
    }

    filter(cb: (node: StickyNode) => boolean): NodeCollection {
        return new NodeCollection(this.nodes.filter(cb));
    }

    filterByKeys(keys: NodeKey[]): NodeCollection {
        const ids = keys.map(({ id }) => id);
        return this.filterByIds(ids);
    }

    filterByIds(ids: Id[]): NodeCollection {
        const models = this.nodes.filter(({ id }) => ids.includes(id));
        return new NodeCollection(models);
    }

    public get length(): number {
        return this.nodes.length;
    }

    public entities(): StickyNode[] {
        return this.nodes.concat();
    }

    public keys(): NodeKey[] {
        return this.nodes.map((node) => node.key);
    }

    ids(): NodeId[] {
        return this.nodes.map(({ id }) => id);
    }

    public isEqual(other: NodeCollection): boolean {
        if (!(other instanceof NodeCollection)) return false;
        if (this.length != other.length) return false;

        return this.nodes.every((node) => {
            const otherNode = other.findByKey(node.key);
            return otherNode && node.isEqual(otherNode);
        });
    }

    public dump(): StickyNodeJSON[] {
        return this.nodes.map((node) => node.dump());
    }

    public static load(dump: StickyNodeJSON[]): NodeCollection {
        return new NodeCollection(dump.map((node) => StickyNode.load(node)));
    }

    public cloneNew(attributes?: Partial<StickyNodeJSON>): [NodeCollection, Record<string, NodeKey>] {
        // コピー元のNodeのKeyと新しいNodeのKeyのマッピング
        const keyMap: Record<string, NodeKey> = {};
        const newNodes: StickyNode[] = [];

        this.nodes.forEach((node) => {
            const newNode = node.cloneNew(attributes);
            keyMap[node.key.toString()] = newNode.key;
            newNodes.push(newNode);
        });

        return [new NodeCollection(newNodes), keyMap];
    }

    public added(newNode: StickyNode): NodeCollection {
        // CollectionEventsMixinのadd()のデフォルト挙動を踏襲して、すでに要素があった場合は置き換えるようにする
        const nodes = [...this.nodes.filter((node) => !node.isEqual(newNode)), newNode];
        return new NodeCollection(nodes);
    }

    addCollection(other: NodeCollection): NodeCollection {
        return new NodeCollection([...this.nodes, ...other.nodes]);
    }

    addList(nodes: StickyNode[]): NodeCollection {
        return new NodeCollection([...this.nodes, ...nodes]);
    }

    public removed(deletedNodeKey: NodeKey): NodeCollection {
        const nodes = this.nodes.filter((node) => !node.key.isEqual(deletedNodeKey));
        return new NodeCollection(nodes);
    }

    updated(id: NodeId, updater: (node: StickyNode) => StickyNode): NodeCollection {
        const nodes = this.nodes.map((node) => (node.id === id ? updater(node) : node));
        return new NodeCollection(nodes);
    }

    move(dx: number, dy: number): NodeCollection {
        const nodes = this.nodes.map((node) => node.move(dx, dy));
        return new NodeCollection(nodes);
    }

    map<T>(fn: (value: StickyNode, index: number) => T): T[] {
        return this.entities().map(fn);
    }

    forEach(fn: (value: StickyNode, index: number) => void): void {
        this.nodes.forEach(fn);
    }

    themeColors(): ThemeColor[] {
        return this.nodes.map((node) => node.style.themeColor);
    }

    fontSizes(): NodeFontSize[] {
        return this.nodes.map((node) => node.style.fontSize);
    }

    isEmpty(): boolean {
        return this.nodes.length === 0;
    }

    /**
     * コレクションを囲む矩形を返します。
     */
    getBounds(): Rect | null {
        if (this.isEmpty()) return null;

        const bounds = this.nodes.map((node) => node.getRect());
        return Rect.union(bounds);
    }

    /**
     * 付箋のテキストを外部向けに出力します。
     * TSV形式で1列につき一つの付箋のテキストを出力します。
     * https://docs.google.com/presentation/d/1G-IXu_hY7xDagUH1kgsSfNpXt5DcI8xzRy1eSgFpfww/edit#slide=id.g1043591efc8_0_9
     */
    exportTexts(): string {
        const rows = this.nodes.sort(StickyNode.compareByPositionXY).map((node) => [node.name.value]);

        return stringify(rows, { delimiter: '\t' });
    }

    styleSet(): NodeStyleSet {
        return NodeStyleSet.fromNodes(this.nodes);
    }

    positionSet(): PositionSet {
        const nodePositions = this.nodes.reduce((acc: Record<NodeId, Point>, node) => {
            acc[node.id] = node.position.toPoint();
            return acc;
        }, {});

        return new PositionSet(nodePositions);
    }

    // Node, NodeDescriptionは削除されるが、DisplayOrder, Linkは消えないのでそのまま使うのは危険
    async buildDeleteCommand(nodeRepository: NodeRepository): Promise<ICommand | null> {
        const commands = await Promise.all(this.ids().map((id) => nodeRepository.buildDeleteCommand(id)));

        return CompositeCommand.composeOptionalCommands(...commands);
    }

    shapeMap(): ShapeMap<RectShape> {
        const shape = new RectShape(StickyNode.size());

        const entries = this.nodes.map((node) => {
            const { id } = node;
            return { id, shape };
        });

        return new ShapeMap(entries);
    }

    /**
     * IDとエンティティのRecordに変換します。
     */
    toRecord(): Record<NodeId, StickyNode> {
        const result: Record<NodeId, StickyNode> = {};
        this.nodes.forEach((node) => {
            result[node.id] = node;
        });
        return result;
    }

    withCreatedUserKey(userKey: UserKey): NodeCollection {
        return new NodeCollection(this.nodes.map((node) => node.withCreatedUserKey(userKey)));
    }
}

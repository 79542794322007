import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { usePopupRef } from '@framework/hooks';
import { useCurrentUserData } from '@user/UserData';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { AnonymousUserPopupMenu, RegularUserPopupMenu } from './UserPopupMenu';
import { useUserPublicProfile } from '@user/PublicProfile';
import { classNames, generateDefaultUserIcon } from '@framework/utils';

type Props = {
    userId: string;
};

export const UserIconMenu: React.FC<Props> = ({ userId }: Props) => {
    const { isOpen, popupRef, handleOpen } = usePopupRef<HTMLDivElement>(false);
    const history = useHistory();

    const handleLogout = useCallback(() => {
        // 現在表示中のURLに対して logout パラメータを付与したURLにリダイレクトさせる
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('logout', '1');
        const currentUrl = window.location.pathname + '?' + urlParams.toString();

        const params = new URLSearchParams();
        params.set('requestPath', currentUrl);
        history.push(`${UserPagePathBuilder.logoutPage()}?${params.toString()}`);
    }, [history]);

    const profile = useUserPublicProfile(userId);
    const userData = useCurrentUserData();

    // profile が取得できないときには、アイコン・メニューを表示できないので null を返す
    if (!profile) return null;

    return (
        <div>
            <div className="flex items-center justify-end">
                <button
                    className={classNames(
                        'block h-8 w-8 overflow-hidden rounded-full',
                        'border-2 border-gray-600',
                        'focus:border-white focus:outline-none'
                    )}
                    onClick={handleOpen}
                >
                    {profile.imageUrl ? (
                        <img className="size-full object-cover" src={profile.imageUrl} alt={profile.name} />
                    ) : (
                        <div className="size-full object-cover">
                            {generateDefaultUserIcon({
                                id: userId,
                                name: profile.name,
                                size: 28,
                            })}
                        </div>
                    )}
                </button>
            </div>
            <div
                className={classNames(
                    'absolute right-0 z-50 mt-2 w-56 rounded-lg bg-white p-1 shadow-xl',
                    isOpen ? 'block' : 'hidden'
                )}
                ref={popupRef}
            >
                {/* UserData を取得できた場合には、通常ユーザ向けのメニューを表示する。それ以外は匿名ユーザ向けの目ニューを表示する */}
                {userData ? (
                    <RegularUserPopupMenu userData={userData} onLogout={handleLogout} />
                ) : (
                    <AnonymousUserPopupMenu profile={profile} onLogout={handleLogout} />
                )}
            </div>
        </div>
    );
};

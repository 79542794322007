export enum LinkLineStyle {
    Dashed = 'Dashed',
    Solid = 'Solid',
}

export namespace LinkLineStyle {
    export function isValid(lineStyle: string): lineStyle is LinkLineStyle {
        return !!LinkLineStyle[lineStyle as LinkLineStyle];
    }
}

import { useEffect, useState } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { useMountedRef } from '@framework/hooks';
import { isUserPublicProfile, UserPublicProfile } from './UserPublicProfile';
import { createUserPublicProfileRepository } from './UserPublicProfileRepository';

/**
 * 指定のユーザの公開プロフィールを取得します。
 * プロフィール情報が取得できない場合には null を返します。
 *
 * プロフィール情報を継続的にリスンして、最新のプロフィールに追従します。
 * @param userId
 * @returns
 */
export const useUserPublicProfile = (userId: string): UserPublicProfile | null => {
    const [profile, setProfile] = useState<UserPublicProfile | null>(null);
    const mountedRef = useMountedRef();

    useEffect(() => {
        const repository = createUserPublicProfileRepository(userId);

        repository.addListener((profile) => {
            if (!mountedRef.current) return; // マウント解除済みならば更新しない

            setProfile(profile);
        });

        return () => repository.removeListener();
    }, [userId, mountedRef]);

    return profile;
};

/**
 * 指定された複数ユーザの公開プロフィールを一括取得します。
 * 取得できなかったユーザのプロフィールは結果に含まれません。
 *
 * プロフィール情報は継続的なリスンを行わず、取得時点の内容を返します。
 * @param userIds
 * @returns
 */
export const useUserPublicProfiles = (userIds: string[]): UserPublicProfile[] | null => {
    const [profiles, setProfiles] = useState<UserPublicProfile[] | null>(null);
    const mountedRef = useMountedRef();

    useEffect(() => {
        Promise.all(userIds.map((userId) => createUserPublicProfileRepository(userId).get())).then((profiles) => {
            if (!mountedRef.current) return; // マウント解除済みならば更新しない

            setProfiles(profiles.filter(isUserPublicProfile).sort(UserPublicProfile.compare));
        });
    }, [userIds, mountedRef]);

    return profiles;
};

/**
 * 現在のユーザの公開プロフィールを取得します。
 * プロフィール情報が取得できない場合には null を返します。
 *
 * プロフィール情報を継続的にリスンして、最新のプロフィールに追従します。
 * @returns
 */
export const useCurrentUserPublicProfile = (): UserPublicProfile | null => {
    const currentUserId = useCurrentUserId();
    const [profile, setProfile] = useState<UserPublicProfile | null>(null);
    const mountedRef = useMountedRef();

    useEffect(() => {
        if (!currentUserId) return;
        const repository = createUserPublicProfileRepository(currentUserId);

        repository.addListener((profile) => {
            if (!mountedRef.current) return; // マウント解除済みならば更新しない

            setProfile(profile);
        });

        return () => repository.removeListener();
    }, [currentUserId, mountedRef]);

    return profile;
};

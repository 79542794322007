import { SubMenuBox } from '@model-framework/menu';

type Props = {
    menuSize: number;
    isSelected: boolean;
    reaction: string;
    onClick(): void;
};

export const NodeReactionSubMenu: React.FC<Props> = ({ menuSize, isSelected, onClick, reaction }: Props) => {
    return (
        <>
            <SubMenuBox menuSize={menuSize} isSelected={isSelected} onClick={onClick} />
            {/* MenuBoxの中央に寄せる */}
            <text
                className={isSelected ? 'fill-white' : 'fill-gray-500'}
                x={menuSize * 0.5}
                y={menuSize * 0.5}
                textAnchor="middle"
                dominantBaseline="central"
                pointerEvents="none"
                fontSize="28px"
                fontWeight="bold"
            >
                {reaction}
            </text>
        </>
    );
};

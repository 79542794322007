import { CompositeCommand, ICommand } from '@model-framework/command';
import { NodeCollection, NodeEntityOperation } from '@view-model/models/sticky/StickyNodeView';
import { LinkEntity, LinkEntityOperation } from '@view-model/models/sticky/StickyLink';
import { LinkKey, StickyZoneKey } from '@view-model/domain/key';
import { StickyZone, StickyZoneEntityOperation } from '@view-model/models/sticky/StickyZoneView';
import { ElementDescriptionSet } from '@view-model/models/sticky/ElementDescription';
import { PositionSet, PositionSetCreateCommand } from '@view-model/models/common/PositionSet';
import { RTDBPath } from '@framework/repository';
import { DisplayOrderTree, DisplayOrderRepository } from '@model-framework/display-order';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { StickyModel } from '@view-model/domain/model';
import { StickyZoneId } from '@schema-common/base';
import { ElementDescriptionTarget } from '@view-model/models/sticky/ElementDescription/domain/ElementDescriptionTarget';

export class ElementsCreateCommand implements ICommand {
    private readonly commands: ICommand;

    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly model: StickyModel,
        private readonly nodeEntityOperation: NodeEntityOperation,
        private readonly linkEntityOperation: LinkEntityOperation,
        private readonly zoneEntityOperation: StickyZoneEntityOperation,
        private readonly nodes: NodeCollection,
        private readonly links: LinkEntity[],
        private readonly zones: StickyZone[],
        zonePositionSet: PositionSet,
        nodeDescriptionSet: ElementDescriptionSet,
        zoneDescriptionSet: ElementDescriptionSet,
        private readonly displayOrderTree: DisplayOrderTree,
        private readonly displayOrderRepository: DisplayOrderRepository,
        private readonly parentZoneId?: StickyZoneId
    ) {
        const nodeDescriptionSetCreateCommand = nodeDescriptionSet.buildCreateCommand(
            viewModel.id,
            model.key.id,
            ElementDescriptionTarget.Node
        );

        const zoneDescriptionSetCreateCommand = zoneDescriptionSet.buildCreateCommand(
            viewModel.id,
            model.key.id,
            ElementDescriptionTarget.Zone
        );

        const zonePositionSetCreateCommand = new PositionSetCreateCommand(
            zonePositionSet,
            RTDBPath.Zone.positionsPath(viewModel.id, model.key.id)
        );
        this.commands = new CompositeCommand(
            nodeDescriptionSetCreateCommand,
            zoneDescriptionSetCreateCommand,
            zonePositionSetCreateCommand
        );
    }

    do(): void {
        this.commands.do();
        this.nodeEntityOperation.saveCollection(this.nodes);
        this.linkEntityOperation.saveMulti(this.links);
        this.zoneEntityOperation.saveMulti(this.zones);
        this.displayOrderRepository.save(this.displayOrderTree, this.parentZoneId).then();
    }

    undo(): void {
        this.displayOrderTree.removeFrom(this.displayOrderRepository).then();
        this.zoneEntityOperation.removeMulti(this.zoneKeys());
        this.linkEntityOperation.removeMulti(this.links);
        this.nodeEntityOperation.removeMulti(this.nodes.keys());
        this.commands.undo();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return (
            (await this.commands.canUndo()) &&
            (await this.canNodeUndo()) &&
            this.allLinksExist() &&
            this.allZonesExist() &&
            (await this.sameOrderTreeExist())
        );
    }

    async canRedo(): Promise<boolean> {
        return (
            (await this.commands.canRedo()) &&
            (await this.canNodeRedo()) &&
            !(
                LinkEntityOperation.existsSome(this.linkKeys(), this.viewModel) ||
                this.zoneEntityOperation.existsSome(this.zoneKeys())
            ) &&
            this.noOrderTreeExist()
        );
    }

    private linkKeys(): LinkKey[] {
        return this.links.map((link) => link.key);
    }

    private zoneKeys(): StickyZoneKey[] {
        return this.zones.map((zone) => zone.key);
    }

    private allLinksExist(): boolean {
        return !!LinkEntityOperation.getLinks(this.linkKeys(), this.viewModel);
    }

    private allZonesExist(): boolean {
        return !!StickyZoneEntityOperation.getZones(this.zoneKeys(), this.model);
    }

    private async sameOrderTreeExist(): Promise<boolean> {
        const currentOrderTree = await this.displayOrderRepository.load();

        return currentOrderTree.hasPartialTree(this.displayOrderTree);
    }

    private async noOrderTreeExist(): Promise<boolean> {
        const currentOrderTree = await this.displayOrderRepository.load();

        return currentOrderTree.hasNoElementsOf(this.displayOrderTree);
    }

    private async canNodeUndo(): Promise<boolean> {
        const currentNodes = NodeEntityOperation.getNodeCollection(this.nodes.keys(), this.viewModel);
        return !!currentNodes && currentNodes.isEqual(this.nodes);
    }

    private async canNodeRedo(): Promise<boolean> {
        return !NodeEntityOperation.existsSome(this.nodes.keys(), this.viewModel);
    }
}

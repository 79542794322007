import { BezierLine } from './BezierLine';

type Props = {
    bezier: BezierLine;
    className?: string;
};

export const BezierLineView: React.FC<Props & React.SVGProps<SVGPathElement>> = ({
    bezier,
    className,
    ...props
}: Props & React.SVGProps<SVGPathElement>) => {
    return <path d={bezier.getSVGPath()} className={className} {...props} />;
};

import { ICommand } from '@model-framework/command';
import { NodeEntityOperation } from '../adapter';
import { NodeCollection } from '../domain';
import { DisplayOrderRepository } from '@view-model/models/framework/display-order';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class NodeCollectionCreateCommand implements ICommand {
    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly nodeEntityOperation: NodeEntityOperation,
        private readonly displayOrderRepository: DisplayOrderRepository,
        private readonly nodes: NodeCollection
    ) {}

    do(): void {
        const { nodes } = this;

        this.nodeEntityOperation.saveCollection(nodes);
        this.displayOrderRepository.addNodes(nodes.ids()).then();
    }

    undo(): void {
        const { nodes } = this;

        this.nodeEntityOperation.removeMulti(nodes.keys());
        this.displayOrderRepository.removeNodes(nodes.ids()).then();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        const currentNodes = NodeEntityOperation.getNodeCollection(this.nodes.keys(), this.viewModel);
        return !!currentNodes && currentNodes.isEqual(this.nodes);
    }

    async canRedo(): Promise<boolean> {
        return !NodeEntityOperation.existsSome(this.nodes.keys(), this.viewModel);
    }
}

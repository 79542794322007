import { InvitationMailDataJSONOnWrite } from '@schema-app/mail/group/{groupKey}/invitation-mail-data/{InvitationMailDataId}/send/InvitationMailDataJSON';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { GroupEntity, GroupMemberInvitation, GroupMemberRole } from '@group/domain';
import { Timestamp } from '@framework/Timestamp';
import { UserData } from '@user/UserData';
import { Random } from '@framework/Random';
import { RTDBPath, ServerValue } from '@framework/repository';
import {
    GroupMemberInvitationJSON,
    GroupMemberInvitationJSONOnWrite,
} from '@schema-app/group/member-invitations/{groupId}/{invitationId}/GroupMemberInvitationJSON';

/**
 * Cloud Functions側でも同様のデータ構造を組み立てているので、変更時には両方同時に修正すること
 */
export class GroupMemberInvitationBuilder {
    // 招待状の有効期限 (functions側でも招待状を作成しているので、期限変更時には注意すること)
    private static EXPIRE_DAYS = 7;

    constructor(
        private readonly group: GroupEntity,
        private readonly sender: UserData,
        private readonly emails: string[],
        private readonly role: GroupMemberRole,
        readonly invitationMessage: string,
        private readonly baseUrl = new URL('/', window.location.href).toString()
    ) {}

    private buildInvitationMailData(
        invitations: GroupMemberInvitation[],
        expiresAt: Timestamp
    ): InvitationMailDataJSONOnWrite {
        const { group, sender, invitationMessage } = this;

        return {
            id: Random.generateRandomID(),
            metadata: {
                createdAt: ServerValue.TIMESTAMP,
            },
            personalizations: invitations.map(({ id: invitationId, mailAddress: to }) => {
                return {
                    to,
                    substitutions: {
                        invitationUrl: UserPagePathBuilder.buildGroupMemberInvitationUrl(
                            group.id,
                            invitationId,
                            this.baseUrl
                        ),
                    },
                };
            }),
            substitutions: {
                expiresAt: expiresAt.format('YYYY年M月D日 HH:mm', 'Asia/Tokyo'),
                groupName: group.name.toString(),
                senderUserName: sender.name,
                senderUserEmail: sender.mailAddress,
                invitationMessage,
            },
        };
    }

    private buildInvitations(createdAt: Timestamp, expiresAt: Timestamp): GroupMemberInvitation[] {
        const { group, sender, role, invitationMessage, emails, baseUrl } = this;

        return emails.map((email) => {
            return GroupMemberInvitation.buildNew(
                sender,
                group,
                email,
                role,
                invitationMessage,
                baseUrl,
                true,
                createdAt,
                createdAt,
                null,
                null,
                expiresAt
            );
        });
    }

    buildUpdates(
        createdAt: Timestamp = Timestamp.now()
    ): Record<string, GroupMemberInvitationJSONOnWrite | InvitationMailDataJSONOnWrite> {
        const { group } = this;

        const expiresAt = createdAt.addDays(GroupMemberInvitationBuilder.EXPIRE_DAYS);

        const invitations = this.buildInvitations(createdAt, expiresAt);
        const invitationUpdates = invitations.reduce((acc: Record<string, GroupMemberInvitationJSON>, invitation) => {
            acc[RTDBPath.Group.memberInvitationPath(group.id, invitation.id)] = invitation.dump();
            return acc;
        }, {});

        const mailData = this.buildInvitationMailData(invitations, expiresAt);
        const mailDataUpdates: Record<string, InvitationMailDataJSONOnWrite> = {
            [RTDBPath.Mail.groupInvitationMailDataSendPath(group.id, mailData.id)]: mailData,
        };

        return {
            ...invitationUpdates,
            ...mailDataUpdates,
        };
    }
}

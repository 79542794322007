import { useContext } from 'react';
import { SidebarContext, SidebarContextValues } from './SidebarContext';

const defaultValues: SidebarContextValues = {
    isSidebarVisible: false,
    isSidebarOpenButtonVisible: false,
    sidebarWidth: 256,
    toggleSidebar: () => void 0,
};

export const useSidebarContext = (): SidebarContextValues => {
    const sidebarContextValues = useContext(SidebarContext);

    return sidebarContextValues || defaultValues;
};

import { ICommand } from '@model-framework/command';
import { LinkEntityOperation } from '../adapter';
import { LinkEntity } from '../domain';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { LinkKey, LinkableTargetKey } from '@view-model/domain/key';

export class LinkReplaceCommand implements ICommand {
    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly entityOperation: LinkEntityOperation,
        private readonly key: LinkKey,
        private readonly lastKeys: { sourceKey: LinkableTargetKey; targetKey: LinkableTargetKey },
        private readonly newKeys: { sourceKey: LinkableTargetKey; targetKey: LinkableTargetKey }
    ) {}

    do(): void {
        const link = LinkEntityOperation.getLink(this.key, this.viewModel);
        if (!link) return;
        this.entityOperation.save(link.withFromToKeys(this.newKeys.sourceKey, this.newKeys.targetKey));
    }

    undo(): void {
        this.changeValue(this.lastKeys, this.newKeys);
    }

    redo(): void {
        this.changeValue(this.newKeys, this.lastKeys);
    }

    async canUndo(): Promise<boolean> {
        return !!this.retrieveLink(this.newKeys.sourceKey, this.newKeys.targetKey);
    }

    async canRedo(): Promise<boolean> {
        return !!this.retrieveLink(this.lastKeys.sourceKey, this.lastKeys.targetKey);
    }

    private changeValue(
        currentKeys: { sourceKey: LinkableTargetKey; targetKey: LinkableTargetKey },
        newKeys: { sourceKey: LinkableTargetKey; targetKey: LinkableTargetKey }
    ): void {
        const link = this.retrieveLink(newKeys.sourceKey, newKeys.targetKey);
        if (!link) return;

        this.entityOperation.save(link.withFromToKeys(currentKeys.sourceKey, currentKeys.targetKey));
    }

    private retrieveLink(sourceKey: LinkableTargetKey, targetKey: LinkableTargetKey): LinkEntity | null {
        return LinkEntityOperation.findLink({
            key: this.key,
            isTarget: (link) => {
                return link.from.isEqual(sourceKey) && link.to.isEqual(targetKey);
            },
            viewModel: this.viewModel,
        });
    }
}

import { useCallback } from 'react';
import { WorkspaceMemberRole } from '@workspace/domain/workspace/vo/WorkspaceMemberRole';

type Props = {
    role: WorkspaceMemberRole;
    onChange(role: WorkspaceMemberRole): void;
    disabled: boolean;
};

export const RoleSelect: React.FC<Props> = ({ role, disabled, onChange }: Props) => {
    const handleChangeRole = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(WorkspaceMemberRole.load(event.target.value));
        },
        [onChange]
    );

    return (
        <select
            className="form-select w-24 py-1 text-sm"
            defaultValue={role.toString()}
            onChange={handleChangeRole}
            disabled={disabled}
        >
            {WorkspaceMemberRole.values().map((role) => (
                <option key={role} value={role}>
                    {WorkspaceMemberRole.getLocalString(role)}
                </option>
            ))}
        </select>
    );
};

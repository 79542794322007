import { useEffect, useRef } from 'react';
import { useActionLogSender } from '@framework/action-log';

/**
 * 以下の2つの場面で再読み込みを行う。
 *  - ブラウザのタブがスリープから復帰したとき(resumeしたとき)
 *  - Balusをロードしてから12時間経過した後に、タブがアクティブになった時
 */
export const ReloadOnResume: React.FC = () => {
    const logSender = useActionLogSender();
    const rootNode = useRef<HTMLElement>(document.querySelector('#root'));

    useEffect(() => {
        const handleResume = async () => {
            await logSender('global:reload', {
                cause: 'resume',
            });
            window.location.reload();
        };

        if (rootNode.current) {
            rootNode.current.addEventListener('resume', handleResume);
        }
    }, [logSender]);

    const loadedAtRef = useRef<number>(Date.now());

    useEffect(() => {
        // タブの visibility が visible に変化したときに、ロードから12時間経過していたら再読み込みを行う
        const handleChangeVisibility = async () => {
            if (document.visibilityState !== 'visible') {
                return;
            }

            const now = Date.now();
            const elapsed = now - loadedAtRef.current;
            if (elapsed < 12 * 60 * 60 * 1000) {
                return;
            }

            await logSender('global:reload', {
                cause: '12 hours elapsed',
            });
            window.location.reload();
        };

        if (rootNode.current) {
            rootNode.current.addEventListener('visibilitychange', handleChangeVisibility);
        }
    }, [logSender]);

    return <></>;
};

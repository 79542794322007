import { ICommand } from '@model-framework/command/ICommand';
import { ModelElementPositionMap } from '@view-model/domain/model';
import { StickyModelElementPositionMapRepository } from '@view-model/infrastructure/basic-model/StickyModelElementPositionMapRepository';

export class ElementsMoveCommand implements ICommand {
    constructor(
        private readonly sourcePositions: ModelElementPositionMap,
        private readonly destinationPositions: ModelElementPositionMap,
        private readonly positionMapRepository: StickyModelElementPositionMapRepository
    ) {}

    do(): void {
        this.positionMapRepository.savePositions(this.destinationPositions).then();
    }

    undo(): void {
        this.positionMapRepository.savePositions(this.sourcePositions).then();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        const currentPositions = await this.positionMapRepository.getPositions(this.sourcePositions.keys());
        return currentPositions.isEqual(this.destinationPositions);
    }

    async canRedo(): Promise<boolean> {
        const currentPositions = await this.positionMapRepository.getPositions(this.sourcePositions.keys());
        return currentPositions.isEqual(this.sourcePositions);
    }
}

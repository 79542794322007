import { atomFamily } from 'jotai/vanilla/utils';
import { atom, PrimitiveAtom } from 'jotai/vanilla';
import { DescriptionPanelCollectionContents } from '@view-model/models/sticky/DescriptionPanel';
import { ModelCommentContents } from '@view-model/models/sticky/ModelComment';
import { ElementDescriptionSet } from '@view-model/models/sticky/ElementDescription';
import { DisplayOrderTree } from '@model-framework/display-order';
import { ModelKey } from '@view-model/domain/key';
import { StickyZone } from '@view-model/models/sticky/StickyZoneView';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { LinkEntity } from '@view-model/models/sticky/StickyLink';
import { ModelId } from '@schema-common/base';

export type StickyModelContentsState = {
    panelContents: DescriptionPanelCollectionContents;
    commentContents: ModelCommentContents;
    nodeDescriptionSet: ElementDescriptionSet;
    zoneDescriptionSet: ElementDescriptionSet;
    displayOrderTree: DisplayOrderTree;
    stickyZones: StickyZone[];
    stickyZonePositions: PositionSet;
    stickyNodes: StickyNode[];
    links: LinkEntity[];
};

export const stickyModelContentsAtomFamily = atomFamily<ModelId, PrimitiveAtom<StickyModelContentsState>>(
    (modelId) => {
        return atom<StickyModelContentsState>({
            panelContents: DescriptionPanelCollectionContents.buildEmpty(),
            commentContents: ModelCommentContents.buildEmpty(ModelKey.buildFromID(modelId)),
            nodeDescriptionSet: ElementDescriptionSet.fromArray([]),
            zoneDescriptionSet: ElementDescriptionSet.fromArray([]),
            displayOrderTree: new DisplayOrderTree(),
            stickyZones: [],
            stickyZonePositions: new PositionSet(),
            stickyNodes: [],
            links: [],
        });
    },
    (left, right) => left === right
);

import { MainMenuBox, MainMenuIcon } from '@model-framework/menu';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

type Props = {
    menuSize: number;
    onClick(): void;
    text: string;
};

export const ReverseLinkButton: React.FC<Props> = ({ menuSize, onClick, text }: Props) => {
    return (
        <g>
            <MainMenuBox menuSize={menuSize} isSelected={false} onClick={onClick} text={text} />
            <MainMenuIcon icon={faExchangeAlt} menuSize={menuSize} />
        </g>
    );
};

import { atom } from 'jotai';
import { StickyZoneId, ViewId } from '@schema-common/base';

type DropTarget = {
    viewId: ViewId;
    stickyZoneId?: StickyZoneId; // viewIdが示すビューの中にあるゾーンのID
};

// ドラッグで要素を移動している時に、ドロップできるビューやゾーンを示すためのatom
export const dropTargetViewAtom = atom<null | DropTarget>(null);

import { Fragment, useEffect } from 'react';
import { Header, HEADER_HEIGHT } from './Header';
import { useParams } from 'react-router-dom';
import { GroupKey } from '@group/domain/key';
import { NotFound } from '@framework/ui/pages/NotFound';
import { useCurrentUserId } from '@framework/auth';
import { NotFoundPage } from '@user/pages/NotFoundPage';
import { Loading } from '@framework/ui/atoms/Loading';
import { GroupContent } from './GroupContent';
import { usePageViewLogger } from '@framework/action-log';
import { GroupEntity } from '@group/domain';
import { useAssignedToManyGroups, useGroupEntity } from '@group/hooks';
import { UserId } from '@schema-common/base';

export const GroupPage: React.FC = () => {
    const currentUserId = useCurrentUserId();

    const { groupId } = useParams<{ groupId: string }>();
    const groupKey = GroupKey.buildFromID(groupId);

    return (
        <>{currentUserId ? <GroupPageContent groupKey={groupKey} currentUserId={currentUserId} /> : <NotFoundPage />}</>
    );
};

const useGroupPageView = (group: GroupEntity | null): void => {
    const pageViewLogger = usePageViewLogger('GroupPage');

    useEffect(() => {
        if (!group) return;

        pageViewLogger.landing({
            groupId: group.id,
            groupName: group.name.toString(),
        });

        return () => {
            pageViewLogger.leaving();
        };
    }, [group, pageViewLogger]);
};

type Props = {
    currentUserId: UserId;
    groupKey: GroupKey;
};

const GroupPageContent: React.FC<Props> = ({ currentUserId, groupKey }: Props) => {
    const hasManyGroups = useAssignedToManyGroups(currentUserId);
    const { group, loading } = useGroupEntity(groupKey.id.toString());

    useGroupPageView(group);

    return (
        <Fragment>
            {loading ? (
                <Loading />
            ) : group ? (
                <>
                    <Header currentGroup={group} currentUserId={currentUserId} />
                    <div className="overflow-y-auto px-10 py-3" style={{ height: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
                        <GroupContent currentUserId={currentUserId} group={group} showBackToHomeLink={hasManyGroups} />
                    </div>
                </>
            ) : (
                <NotFound />
            )}
        </Fragment>
    );
};

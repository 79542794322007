import { useCallback, useState } from 'react';
import { UserPublicProfile } from '@user/PublicProfile';
import { AnonymousUserNameChange } from '../AnonymousUserNameChange';
import { MenuButton } from './MenuButton';
import { MenuLink } from './MenuLink';
import { NameLabel } from './NameLabel';

type Props = {
    profile: UserPublicProfile;
    onLogout(): void;
};

/**
 * 匿名ユーザ向けのポップアップメニュー
 */
export const AnonymousUserPopupMenu: React.FC<Props> = ({ profile, onLogout }: Props) => {
    // ユーザ編集モーダルの表示制御
    const [showEditUserModal, setEditUserModal] = useState<boolean>(false);
    const handleOpenEditUserModal = useCallback(() => setEditUserModal(true), []);
    const handleCloseEditUserModal = useCallback(() => setEditUserModal(false), []);

    return (
        <>
            {/* アイコン+名前 */}
            <NameLabel name={profile.name} userId={profile.id} imageUrl={profile.imageUrl} />

            <MenuLink href="https://balus-help.levii.co.jp/" label="ヘルプ | Help" />

            <MenuButton onClick={handleOpenEditUserModal}>名前を変更 | Change name</MenuButton>
            {showEditUserModal && (
                <AnonymousUserNameChange currentName={profile.name} onClose={handleCloseEditUserModal} />
            )}

            <MenuButton onClick={onLogout}>ログアウト | Log out</MenuButton>
        </>
    );
};

import { useCallback } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { ViewSetting } from '@view-model/domain/view';

type Props = {
    isOpen: boolean;
    viewSetting: ViewSetting;
    onClose(): void;
    onShowNodeCreatedUserChanged(checked: boolean, isLocked: boolean): void;
};

export const ViewSettingModal: React.FC<Props> = ({
    isOpen,
    viewSetting,
    onClose,
    onShowNodeCreatedUserChanged,
}: Props) => {
    const handleChangeShowNodeCreatedUser = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onShowNodeCreatedUserChanged(event.target.checked, viewSetting.isLocked);
        },
        [onShowNodeCreatedUserChanged, viewSetting.isLocked]
    );

    const handleChangeIsLocked = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onShowNodeCreatedUserChanged(viewSetting.showNodeCreatedUser, event.target.checked);
        },
        [onShowNodeCreatedUserChanged, viewSetting.showNodeCreatedUser]
    );

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            title={'設定 | Settings'}
            submitButton={<></>}
            cancelButton={<></>}
        >
            <div className="p-4">
                <label>
                    <input
                        type="checkbox"
                        className="form-checkbox size-6 border-brand"
                        checked={viewSetting.showNodeCreatedUser}
                        onChange={handleChangeShowNodeCreatedUser}
                    />
                    <span className="ml-2">作成者のアイコンを表示 | Whose icon</span>
                </label>
            </div>
            <div className="p-4 pb-16">
                <label>
                    <input
                        type="checkbox"
                        className="form-checkbox size-6 border-brand"
                        checked={viewSetting.isLocked}
                        onChange={handleChangeIsLocked}
                    />
                    <span className="ml-2">ビューの編集をロックする | Lock Setting</span>
                </label>
            </div>
        </GeneralModal>
    );
};

import { useActionLogSender } from '@framework/action-log';
import { classNames } from '@framework/utils';
import { useAnnouncements } from '@notification/announcement';

type Props = unknown;

export const AnnouncementList: React.FC<Props> = () => {
    const announcements = useAnnouncements();
    const logSender = useActionLogSender();

    return (
        <>
            {announcements.length === 0 && <div className="p-4">お知らせはありません</div>}

            {announcements.map(({ id, title, url, notifiedAt }, index) => (
                <a
                    key={id}
                    className={classNames('block cursor-pointer p-4 hover:bg-gray-200', index > 0 ? 'border-t' : '')}
                    href={url}
                    onClick={() =>
                        logSender('global:notification:announcement:click', {
                            announcementTitle: title,
                            announcementUrl: url,
                        })
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="font-bold">{title}</div>
                    <div className="text-right text-sm text-gray-800">{notifiedAt.fromNow()}</div>
                </a>
            ))}
        </>
    );
};

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { generateDefaultIcon } from '@framework/utils';
import { GroupMemberInvitation } from '@group/domain';
import { useLoginErrorMessage } from '@user/Login';

type Props = {
    invitation: GroupMemberInvitation;
    children: React.ReactNode;
};

export const InvitationMenuLayout: React.FC<Props> = ({ invitation, children }: Props) => {
    const { senderGroup, senderUser } = invitation;
    const loginErrorMessage = useLoginErrorMessage();

    return (
        <div className="flex flex-col items-center py-4">
            <div>
                <UserIcon
                    name={senderUser.name}
                    id={senderUser.userId}
                    imageUrl={senderUser.imageUrl}
                    size="small"
                    className="inline-block align-middle leading-6"
                />
                {senderUser.name}さん({senderUser.email})があなたをグループに招待しています。
            </div>

            {loginErrorMessage && <div className="py-4 text-red-700">{loginErrorMessage}</div>}

            <div className="flex flex-row items-center py-4">
                <div className="block size-10 overflow-hidden rounded-full border border-gray-600">
                    {senderGroup.imageUrl ? (
                        <img src={senderGroup.imageUrl} alt={senderGroup.name} className="size-full object-cover" />
                    ) : (
                        <div className="size-full object-cover">
                            {generateDefaultIcon({ id: senderGroup.groupId, name: senderGroup.name, size: 40 })}
                        </div>
                    )}
                </div>
                <div className="pl-2 text-2xl font-bold">{senderGroup.name}</div>
            </div>

            {children}

            <div className="pt-8 text-sm text-gray-700">
                続行することにより、Balus の
                <a
                    href="https://levii.co.jp/legal/terms-of-service/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-700 underline hover:text-blue-800"
                >
                    サービス利用規約 <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
                、
                <a
                    href="https://levii.co.jp/legal/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-700 underline hover:text-blue-800"
                >
                    プライバシーポリシー <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
                に同意したものとみなされます。
            </div>
        </div>
    );
};

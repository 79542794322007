import Victor from 'victor';
import { BezierLine } from '@model-framework/link';
import { Point, Rect } from '@view-model/models/common/basic';
import { StickyNode } from '@view-model/models/sticky/StickyNodeView';

export class BezierQuadraticFactory {
    private static buildPoints(
        sourceRect: Rect,
        targetRect: Rect,
        bendingPoint: Victor,
        offset = 64
    ): [Victor, Victor, Victor] {
        /**
         * ユーザビリティのため、bending Point 上を曲線が通過するようにしたい。
         * 2 次の Bezier 曲線の場合、starting, ending Point の中点と
         * control Point を結んだ線の中点を必ず曲線が通過するので、
         * 逆にこの点を bending Point として control Point を逆算する。
         * ただし、実際には曲線の端点は中心点ではなく、border から始まるため、
         * 後から補正することになる。
         *
         * そのため、
         * sourceView, targetView の中心を結んだ線の中心の 2 分点から
         * bending Point に引いたベクトルの長さを 2 倍にした点を
         * 一旦仮の control Point にする。
         */
        const sourceBasePoint = this.calcBasePoint(sourceRect, targetRect).toVictor();
        const targetBasePoint = this.calcBasePoint(targetRect, sourceRect).toVictor();

        let controlPoint = bendingPoint
            .clone()
            .multiply(new Victor(2, 2))
            .subtract(sourceBasePoint.clone().mix(targetBasePoint, 0.5));

        /**
         * (仮の) control Point から sourceView, targetView の中心に線を引き
         * それぞれの border(offset あり) と交わった点を
         * starting Point, ending Point とする。
         */
        const startingPoint = sourceRect
            .applyMarginKeepingCenter(offset)
            .intersectPointFromCenter(Point.fromPosition(controlPoint))
            .toVictor();
        const endingPoint = targetRect
            .applyMarginKeepingCenter(offset)
            .intersectPointFromCenter(Point.fromPosition(controlPoint))
            .toVictor();

        /**
         * starting, ending Point が決まったので、
         * 改めて先の計算を行い control Point を確定させる。
         */
        controlPoint = bendingPoint
            .clone()
            .multiply(new Victor(2, 2))
            .subtract(startingPoint.clone().mix(endingPoint, 0.5));

        return [startingPoint, controlPoint, endingPoint];
    }

    /*
     */
    /**
     * buildPoints() のアルゴリズムの開始基準座標を返す。
     *
     * 元々は中心座標を開始地点としていたが、ゾーンと付箋のようにサイズが違うもの同士の中心座標を
     * 開始位置としてこのアルゴリズムを適用すると、２つの要素の距離が近い時に開始位置が突き抜ける問題がある。
     * https://github.com/levii/balus-app/issues/1580
     *
     * このため、付箋のサイズより大きい場合は、付箋のサイズを使って基準位置を調整する。
     *
     * @param src 基準位置を計算したい図形の矩形領域
     * @param dst リンク相手先となる図形の矩形領域
     * @private
     */
    private static calcBasePoint(src: Rect, dst: Rect): Point {
        const nodeSize = StickyNode.size();

        if (src.width <= nodeSize.width && src.height <= nodeSize.height) {
            return src.getCenterPoint();
        }

        return src.applyMarginKeepingCenter(-nodeSize.width / 2).intersectPointFromCenter(dst.getCenterPoint());
    }

    static buildBezierLine(srcRect: Rect, dstRect: Rect, bendingPoint: Victor, offset = 64): BezierLine {
        const [startingPoint, controlPoint, endingPoint] = this.buildPoints(srcRect, dstRect, bendingPoint, offset).map(
            (position) => Point.fromPosition(position)
        );

        return new BezierLine(startingPoint, controlPoint, endingPoint);
    }
}

import unified from 'unified';
import remarkParse from 'remark-parse';
import { isArray, isNumber, isString } from 'lodash';

type Point = { column: number; line: number; offset?: number };

type Position = {
    start: Point;
    end: Point;
};

type BaseParseResult = {
    type: string;
    position?: Position;
    url?: string;
    children?: ParseResult[];
};

type Image = Omit<BaseParseResult, 'type'> & {
    type: 'image';
    alt: string;
    url: string;
};

type Link = Omit<BaseParseResult, 'type'> & {
    type: 'link';
    url: string;
};

type ParseResult = Image | Link | BaseParseResult;

export class MarkdownParser {
    /**
     * 引数に与えられたマークダウンのテキストを解析して、 mdast のツリーを返します。
     * @param markdown
     * @returns
     */
    static parse(markdown: string): ParseResult {
        const processor = unified().use(remarkParse);
        const mdastTree = processor.parse(markdown);
        if (mdastTree.type !== 'root') {
            throw new Error('Unexpected mdast tree type: ' + mdastTree.type);
        }
        return mdastTree;
    }

    /**
     * 引数に与えられたマークダウンのテキストを解析して、画像またはリンクのURLを第二引数で指定されたマッピングに従って置き換える。
     * @param markdown マークダウンのテキスト
     * @param urlMap key-value 形式の新旧URL対応表
     * @returns
     */
    static replaceUrls(markdown: string, urlMap: Record<string, string>): string {
        // 置き換え対象のURLを含むトークンのみを取得する
        const tokens = this.filterByUrl((urlString) => !!urlMap[urlString], this.parse(markdown));

        // トークンのオフセットが後になるものから順に並べる
        const sortedTokens = tokens.sort((a, b) => {
            const offsetA = a.position?.end?.offset;
            const offsetB = b.position?.end?.offset;
            return isNumber(offsetA) && isNumber(offsetB) ? offsetB - offsetA : 0;
        });

        let result = markdown;

        // 後側のトークンから順にURLを置き換える (前方から置き換えるとインデックス位置がズレるため)
        sortedTokens.forEach((token) => {
            const url = token.url;
            const start = token.position?.start?.offset;
            const end = token.position?.end?.offset;
            if (isNumber(start) && isNumber(end) && isString(url)) {
                const newUrl = urlMap[url];
                result =
                    result.slice(0, start) +
                    // tokenの範囲中のURL文字列部分を新しいURLに置き換える
                    result.slice(start, end).replace(`(${url})`, `(${newUrl})`) +
                    result.slice(end);
            }
        });

        return result;
    }

    /**
     * checkerが真値を返すような url を含む要素の一覧を返します。
     * @param checker URLの一致チェックを行う関数
     * @param mdastTree
     * @param skipChildrenOnDetect true を指定した場合、URLに一致する要素が見つかれば、その時点で子孫の探索を中止します
     * @returns
     */
    static filterByUrl(
        checker: (url: string) => boolean,
        mdastTree: ParseResult,
        skipChildrenOnDetect = false
    ): ParseResult[] {
        const results = [];

        if (isString(mdastTree['url']) && checker(mdastTree['url'])) {
            results.push(mdastTree);
            if (skipChildrenOnDetect) {
                return results;
            }
        }

        if (isArray(mdastTree['children'])) {
            mdastTree['children'].forEach((subTree) => {
                results.push(...this.filterByUrl(checker, subTree, skipChildrenOnDetect));
            });
        }

        return results;
    }
}

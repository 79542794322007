import TextareaAutosize from 'react-textarea-autosize';

type Props = {
    autoFocus?: boolean;
    body: string;
    placeholder?: string;
    onChange(body: string): void;
    onSubmit(): void;
    onFocus?(): void;
    minRows?: number;
};

export const ModelCommentBodyForm: React.FC<Props> = ({
    autoFocus,
    body,
    placeholder,
    onChange,
    onSubmit,
    onFocus,
    minRows,
}: Props) => {
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        onChange(value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const withMetaKey = event.ctrlKey || event.metaKey;
        if (event.key == 'Enter' && withMetaKey) {
            onSubmit();
        }
    };

    return (
        <div className="[&>textarea]:w-full [&>textarea]:rounded-lg [&>textarea]:border [&>textarea]:border-gray-400 [&>textarea]:bg-white [&>textarea]:p-2">
            <TextareaAutosize
                autoFocus={autoFocus}
                minRows={minRows}
                value={body}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onFocus={onFocus}
                placeholder={placeholder}
            />
        </div>
    );
};

import { useCommandManager } from '@model-framework/command';
import { GroupId, ModelId, ViewId } from '@schema-common/base';
import { UserPublicProfile } from '@user/PublicProfile';
import { useGetTransformedVisibleAreaCenterPoint } from '@user/pages/ViewModelPage/VisibleAreaCenterPointProvider';
import { StickyModelContentsOperation } from '@view-model/adapter';
import { ViewsClipboardOperator, ViewsClipboardPayload } from '@view-model/application/clipboard';
import { MLAPIPayload } from '@view-model/application/ml-api/MLAPIPayload';
import { WindowEventManager } from '@view-model/application/shortcuts';
import { ModelContentsCollection, StickyModel } from '@view-model/domain/model';
import { ViewCollection, ViewEntity } from '@view-model/domain/view';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { Point, Rect } from '@view-model/models/common/basic';
import { CreatingModelComment } from '@view-model/models/sticky/ModelComment';
import { StickyModelContentsView } from '@view-model/ui/components/Model';
import { ViewAnalysisSelectModal } from '@view-model/ui/components/View';
import { Fragment, useCallback, useState, memo } from 'react';
import { toast } from 'react-hot-toast';
import { ConsistencyLinkTargetFrame } from './ConsistencyLinkTargetFrame';
import { useViewLogSender } from './ViewLogSender';
import { ViewPopupMenu } from './ViewPopupMenu';
import { ViewSelectionFrame } from './ViewSelectionFrame';
import { ViewTitleBar } from './ViewTitleBar';
import { MultiSelectionMode } from '@user/pages/ViewModelPage/MultiSelectionMode';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { useOpenCloseState } from './useOpenCloseState';
import { ViewTitleBarTeleporter } from '@user/pages/ViewModelPage/teleporters';
import { ViewKey } from '@view-model/domain/key';
import { ClientSelectedItemCollectionLoader } from '@view-model/models/sticky/client-selected-items/ClientSelectedItemCollectionLoader';
import { ActionLogEventParamsContextProvider } from '@framework/action-log';
import { DragContext } from '@model-framework/ui';
import { useHandler } from '@framework/hooks';

// NOTE: Propsの型を変更する場合には、propsAreEqual()も変更すること
type Props = {
    readonly: boolean;
    ownerGroupId: GroupId;
    currentUserProfile: UserPublicProfile;
    viewModel: ViewModelEntity;
    viewId: ViewId;
    view: ViewEntity;
    viewPosition: Point;
    model: StickyModel;
    isSelected: boolean;
    onSelectSingleView(viewId: ViewId): void;
    onToggleSelectedViews(viewId: ViewId): void;
    onDeselectAllViews(): void;
    setMultiSelectionMode(multiSelectionMode: MultiSelectionMode): void;
    windowEventManager: WindowEventManager;
    isLinkableTarget: boolean;
    multiSelectionMode: MultiSelectionMode;
    onClickViewModelLink(e: React.MouseEvent): void;
    modelCommentThreadId: string | null;
    stickyModelContentsOperation: StickyModelContentsOperation | undefined;
    creatingComment: CreatingModelComment | undefined;
    onCreatingCommentAdd(modelId: ModelId, position: Point): void;
    onCreatingCommentDrag(modelId: ModelId, dx: number, dy: number): void;
    onCreatingCommentDragEnd(modelId: ModelId): void;
    onCreatingCommentCancel(modelId: ModelId): void;
    onCreatingCommentSubmit(modelId: ModelId, creatingComment: CreatingModelComment): void;
    focusView: (viewId: string) => void;
    duplicateView: (payload: ViewsClipboardPayload) => void;
    openDeleteModal(): void;
    onDragStartView(viewId: ViewId): void;
    onDragView(context: DragContext): void;
    onDragEndView(): void;
    getViewRectOperations(): Record<
        ViewId,
        { view: ViewEntity; rect?: Rect; operation?: StickyModelContentsOperation }
    >;
    onZoneToView(sourceView: ViewEntity, operation: StickyModelContentsOperation): void;
};

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
    return (
        prevProps.readonly === nextProps.readonly &&
        prevProps.ownerGroupId === nextProps.ownerGroupId &&
        prevProps.currentUserProfile === nextProps.currentUserProfile &&
        prevProps.viewModel === nextProps.viewModel &&
        prevProps.viewId === nextProps.viewId &&
        prevProps.view.isEqual(nextProps.view) &&
        prevProps.viewPosition.isEqual(nextProps.viewPosition) &&
        prevProps.model.isEqual(nextProps.model) &&
        prevProps.isSelected === nextProps.isSelected &&
        prevProps.onSelectSingleView === nextProps.onSelectSingleView &&
        prevProps.onToggleSelectedViews === nextProps.onToggleSelectedViews &&
        prevProps.onDeselectAllViews === nextProps.onDeselectAllViews &&
        prevProps.setMultiSelectionMode === nextProps.setMultiSelectionMode &&
        prevProps.windowEventManager === nextProps.windowEventManager &&
        prevProps.isLinkableTarget === nextProps.isLinkableTarget &&
        prevProps.multiSelectionMode === nextProps.multiSelectionMode &&
        prevProps.onClickViewModelLink === nextProps.onClickViewModelLink &&
        prevProps.modelCommentThreadId === nextProps.modelCommentThreadId &&
        prevProps.stickyModelContentsOperation === nextProps.stickyModelContentsOperation &&
        prevProps.creatingComment === nextProps.creatingComment &&
        prevProps.onCreatingCommentAdd === nextProps.onCreatingCommentAdd &&
        prevProps.onCreatingCommentDrag === nextProps.onCreatingCommentDrag &&
        prevProps.onCreatingCommentDragEnd === nextProps.onCreatingCommentDragEnd &&
        prevProps.onCreatingCommentCancel === nextProps.onCreatingCommentCancel &&
        prevProps.onCreatingCommentSubmit === nextProps.onCreatingCommentSubmit &&
        prevProps.focusView === nextProps.focusView &&
        prevProps.duplicateView === nextProps.duplicateView &&
        prevProps.openDeleteModal === nextProps.openDeleteModal &&
        prevProps.onDragStartView === nextProps.onDragStartView &&
        prevProps.onDragView === nextProps.onDragView &&
        prevProps.onDragEndView === nextProps.onDragEndView &&
        prevProps.getViewRectOperations === nextProps.getViewRectOperations &&
        prevProps.onZoneToView === nextProps.onZoneToView
    );
};

type SwapperProps = {
    swap: boolean;
    a: React.ReactNode;
    b: React.ReactNode;
};

const Swapper: React.FC<SwapperProps> = ({ swap, a, b }: SwapperProps) => {
    // Fragmentにkeyを指定し再レンダリングさせないようにしている
    const compA = <Fragment key="a">{a}</Fragment>;
    const compB = <Fragment key="b">{b}</Fragment>;

    return <>{swap ? [compB, compA] : [compA, compB]}</>;
};

const ViewFn: React.FC<Props> = ({
    readonly,
    ownerGroupId,
    currentUserProfile,
    viewModel,
    view,
    viewPosition,
    model,
    isSelected,
    onToggleSelectedViews,
    onSelectSingleView,
    onDeselectAllViews,
    setMultiSelectionMode,
    windowEventManager,
    isLinkableTarget,
    multiSelectionMode,
    onClickViewModelLink,
    modelCommentThreadId,
    stickyModelContentsOperation,
    creatingComment,
    onCreatingCommentAdd,
    onCreatingCommentDrag,
    onCreatingCommentDragEnd,
    onCreatingCommentCancel,
    onCreatingCommentSubmit,
    focusView,
    duplicateView,
    openDeleteModal,
    onDragStartView,
    onDragView,
    onDragEndView,
    getViewRectOperations,
    onZoneToView,
}: Props) => {
    const logSender = useViewLogSender(view);
    const commandManager = useCommandManager();

    const [isPopupMenuOpen, openPopupMenu, closePopupMenu] = useOpenCloseState();
    const [isOpenAnalysisModal, openAnalysisModal, closeAnalysisModal] = useOpenCloseState();
    const [isResizing, setIsResizing] = useState(false);

    const getTransformedVisibleAreaCenterPoint = useGetTransformedVisibleAreaCenterPoint();

    const viewRect = new Rect(viewPosition, view.size);

    const handleToggleSelectedViews = useCallback(() => {
        onToggleSelectedViews(view.id);
    }, [onToggleSelectedViews, view.id]);

    const handleSelectSingleView = useHandler((viewId?: ViewId) => {
        onSelectSingleView(viewId ?? view.id);
    });

    const onClick = useCallback(() => {
        if (multiSelectionMode.isReadyForMultiSelection) {
            handleToggleSelectedViews();
            setMultiSelectionMode(MultiSelectionMode.multiViewsSelectionMode);
        } else if (multiSelectionMode.isMultiViewsSelectionMode) {
            handleToggleSelectedViews();
        } else {
            handleSelectSingleView();
        }
    }, [handleSelectSingleView, multiSelectionMode, setMultiSelectionMode, handleToggleSelectedViews]);

    const handleResizeStart = useCallback(() => setIsResizing(true), []);
    const handleResizeEnd = useCallback(() => {
        setIsResizing(false);
    }, []);

    const handleCopyView = useCallback(async () => {
        const success = await ViewsClipboardOperator.copy(
            viewModel.id,
            new ViewCollection([view]),
            new PositionSet({ [view.id]: viewPosition }),
            null,
            logSender
        );

        if (success) {
            toast.success('コピーしました | Copied');
        } else {
            toast.error('コピーに失敗しました | Copy failed');
        }
    }, [logSender, view, viewModel.id, viewPosition]);

    const handleAnalysisStart = useCallback(() => {
        toast('分析処理を開始しました | Started analysis');
    }, []);

    const handleAnalysisSuccess = useCallback(
        (payload: MLAPIPayload) => {
            // ビューの複数選択に伴いMLAPIPayload(=ViewClipboardPayload)とViewsClipboardPayloadの間の互換性がなくなったので
            // MLAPIPayloadの値を元に、ViewsClipboardPayloadを作る
            const viewsClipboardPayload = ViewsClipboardPayload.fromViewsAndModelContents({
                sourceViewModelId: payload.sourceViewModelId,
                views: ViewCollection.load([payload.view]),
                viewPositions: PositionSet.load({ [new ViewKey(payload.view.key).id]: viewPosition }),
                modelContents: ModelContentsCollection.load([payload.modelContents]),
            });
            duplicateView(viewsClipboardPayload);
            toast.success('分析処理が完了しました | Finished analysis');
        },
        [duplicateView, viewPosition]
    );

    const handleAnalysisFailure = useCallback(() => {
        toast.error('分析処理に失敗しました | Failed to perform analysis');
    }, []);

    const handleCreatingCommentAdd = useCallback(() => {
        const transformedVisibleAreaCenterPoint = getTransformedVisibleAreaCenterPoint(view.getRect(viewPosition));
        onCreatingCommentAdd(model.id, transformedVisibleAreaCenterPoint);
    }, [model.id, onCreatingCommentAdd, getTransformedVisibleAreaCenterPoint, view, viewPosition]);

    const handleCreatingCommentDrag = useCallback(
        (dx: number, dy: number) => {
            onCreatingCommentDrag(model.id, dx, dy);
        },
        [model.id, onCreatingCommentDrag]
    );

    const handleCreatingCommentDragEnd = useCallback(
        () => onCreatingCommentDragEnd(model.id),
        [model.id, onCreatingCommentDragEnd]
    );

    const handleCreatingCommentSubmit = useCallback(
        (creatingModelComment: CreatingModelComment) => onCreatingCommentSubmit(model.id, creatingModelComment),
        [model.id, onCreatingCommentSubmit]
    );

    const handleCreatingCommentCancel = useCallback(
        () => onCreatingCommentCancel(model.id),
        [model.id, onCreatingCommentCancel]
    );

    const handleFocusView = useCallback(() => focusView(view.id), [focusView, view.id]);

    return (
        <>
            {isLinkableTarget && <ConsistencyLinkTargetFrame viewRect={viewRect} />}
            {/* リサイズ中にviewContentをresizeViewの背面に持ってくるようにしている。 */}
            <Swapper
                swap={isResizing}
                a={
                    <>
                        {isSelected && (
                            <ViewSelectionFrame
                                readonly={readonly || view.setting.isLocked}
                                viewRect={viewRect}
                                onResizeStart={handleResizeStart}
                                onResizeEnd={handleResizeEnd}
                            />
                        )}
                    </>
                }
                b={
                    <g transform={viewRect.topLeft().toSVGTranslate()}>
                        {/* モデルバージョンの変更によって、必ずコンポーネントが再レンダリングされるように、 key にバージョンを含める */}
                        {stickyModelContentsOperation && (
                            <ActionLogEventParamsContextProvider modelId={model.id} modelType={model.type}>
                                <StickyModelContentsView
                                    key={`${model.key.toString()}-${model.version}`}
                                    readonly={readonly || view.setting.isLocked}
                                    ownerGroupId={ownerGroupId}
                                    currentUserProfile={currentUserProfile}
                                    viewModel={viewModel}
                                    view={view}
                                    model={model}
                                    isSelected={isSelected}
                                    getViewRectOperations={getViewRectOperations}
                                    onSelectSingleView={handleSelectSingleView}
                                    onToggleSelectedViews={handleToggleSelectedViews}
                                    onDeselectAllViews={onDeselectAllViews}
                                    setMultiSelectionMode={setMultiSelectionMode}
                                    onDeleteView={openDeleteModal}
                                    onClickViewModelLink={onClickViewModelLink}
                                    showNodeCreatedUser={view.setting.showNodeCreatedUser}
                                    windowEventManager={windowEventManager}
                                    multiSelectionMode={multiSelectionMode}
                                    commandManager={commandManager}
                                    canvasSize={viewRect.size}
                                    logSender={logSender}
                                    modelCommentThreadId={modelCommentThreadId}
                                    operation={stickyModelContentsOperation}
                                    creatingComment={creatingComment}
                                    onCreatingCommentAdd={handleCreatingCommentAdd}
                                    onCreatingCommentDrag={handleCreatingCommentDrag}
                                    onCreatingCommentDragEnd={handleCreatingCommentDragEnd}
                                    onCreatingCommentCancel={handleCreatingCommentCancel}
                                    onCreatingCommentSubmit={handleCreatingCommentSubmit}
                                    onZoneToView={onZoneToView}
                                    onAnalysisStart={handleAnalysisStart}
                                    onAnalysisSuccess={handleAnalysisSuccess}
                                    onAnalysisFailure={handleAnalysisFailure}
                                    onCopyView={handleCopyView}
                                    getTransformedVisibleAreaCenterPoint={getTransformedVisibleAreaCenterPoint}
                                    viewRect={view.getRect(viewPosition)}
                                />
                            </ActionLogEventParamsContextProvider>
                        )}
                    </g>
                }
            />

            <ViewTitleBarTeleporter.Source>
                <g>
                    <ViewTitleBar
                        viewModelId={viewModel.id}
                        readonly={readonly}
                        viewIsLocked={view.setting.isLocked}
                        viewRect={viewRect}
                        name={view.name}
                        viewId={view.id}
                        onClick={onClick}
                        onCopyView={handleCopyView}
                        onOpenPopupMenu={() => {
                            openPopupMenu();
                            handleSelectSingleView();
                        }}
                        onDragStart={onDragStartView}
                        onDrag={onDragView}
                        onDragEnd={onDragEndView}
                    />
                    <ViewPopupMenu
                        isOpen={isPopupMenuOpen}
                        viewRect={viewRect}
                        viewModelId={viewModel.id}
                        viewId={view.id}
                        groupId={ownerGroupId}
                        viewSetting={view.setting}
                        onDelete={openDeleteModal}
                        onFocusView={handleFocusView}
                        onAnalysisSelect={openAnalysisModal}
                        onClose={closePopupMenu}
                    />
                </g>
            </ViewTitleBarTeleporter.Source>
            <ViewAnalysisSelectModal
                isOpen={isOpenAnalysisModal}
                viewModel={viewModel}
                view={view}
                onStart={handleAnalysisStart}
                onSuccess={handleAnalysisSuccess}
                onFailure={handleAnalysisFailure}
                onClose={closeAnalysisModal}
            />
            <ClientSelectedItemCollectionLoader viewModelId={viewModel.id} modelId={model.id} />
        </>
    );
};

export const View = memo(ViewFn, propsAreEqual);

import { RefObject, useEffect, useRef } from 'react';
import { drag } from 'd3';
import { select } from 'd3-selection';
import { DragContext } from '@model-framework/ui';
import { D3DragEvent } from 'd3-drag';

export type DragEvent = DragContext;

type Props = {
    onDragStart?(event: DragEvent): void;
    onDrag?(event: DragEvent): void;
    onDragEnd?(event: DragEvent): void;
};

/**
 * D3Drag でDOM要素のドラッグイベントをリスニングするためのフック
 * このフックから返されるrefオブジェクトをReactコンポーネントのrefパラメータに差し込むことでドラッグイベントが
 * コールバックされるようになる。
 */
export const useD3Drag = <T extends Element>(props: Props): RefObject<T> => {
    const ref = useRef<T | null>(null);
    const propsRef = useRef<Props>(props);
    propsRef.current = props;

    useEffect(() => {
        const elem = ref.current;
        if (!elem) {
            return;
        }

        const behavior = drag<T, unknown, unknown>();

        const dragState: {
            dragStartX: number;
            dragStartY: number;
        } = {
            dragStartX: 0,
            dragStartY: 0,
        };

        behavior.on('start', (event: D3DragEvent<T, unknown, unknown>) => {
            Object.assign(dragState, {
                dragStartX: event.x,
                dragStartY: event.y,
            });

            propsRef.current.onDragStart?.({
                dragStartX: dragState.dragStartX,
                dragStartY: dragState.dragStartY,
                x: event.x,
                y: event.y,
                dx: event.dx,
                dy: event.dy,
                nativeEvent: event.sourceEvent,
            });
        });
        behavior.on('drag', (event: D3DragEvent<T, unknown, unknown>) => {
            propsRef.current.onDrag?.({
                dragStartX: dragState.dragStartX,
                dragStartY: dragState.dragStartY,
                x: event.x,
                y: event.y,
                dx: event.dx,
                dy: event.dy,
                nativeEvent: event.sourceEvent,
            });
        });
        behavior.on('end', (event: D3DragEvent<T, unknown, unknown>) => {
            propsRef.current.onDragEnd?.({
                dragStartX: dragState.dragStartX,
                dragStartY: dragState.dragStartY,
                x: event.x,
                y: event.y,
                dx: event.dx,
                dy: event.dy,
                nativeEvent: event.sourceEvent,
            });
        });

        select(elem).call(behavior);

        return () => void select(elem).on('.drag', null);
    }, []);

    return ref;
};

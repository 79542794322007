import { createContext, useContext } from 'react';
import { AuthSessionId, UserId } from '@schema-common/base';

export type AuthStatus = {
    currentUserId: UserId | null;
    currentSessionId: AuthSessionId | null;
    userType: 'regular' | 'anonymous' | null;
};

export const AuthContext = createContext<AuthStatus | null>(null);

/**
 * コンテキストから AuthStatus を取り出すカスタムフック
 */
const useAuthContext = (): AuthStatus => {
    const auth = useContext(AuthContext);
    if (!auth) {
        throw new Error('useAuthContext must be inside a Provider with a value.');
    }
    return auth;
};

/**
 * 現在ログイン中のユーザIDを返します。未ログイン時にはnullを返します。
 */
export const useCurrentUserId = (): UserId | null => {
    const auth = useAuthContext();
    return auth.currentUserId || null;
};

/**
 * 現在ログイン中のユーザIDを返します。未ログイン時には例外を返します。
 */
export const useRequiredCurrentUserId = (): UserId => {
    const userId = useCurrentUserId();
    if (!userId) throw new Error('Current user is not found');
    return userId;
};

export const useUserType = (): AuthStatus['userType'] => {
    const auth = useAuthContext();
    return auth.userType;
};

export const useCurrentSessionId = (): AuthSessionId | null => {
    const auth = useAuthContext();
    return auth.currentSessionId || null;
};

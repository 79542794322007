import { LinkMarkStyle } from '@model-framework/link';
import { SubMenuBox } from '@model-framework/menu';
import { LinkMarkStyleMenuIcon } from './LinkMarkStyleMenuIcon';
import { Point } from '@view-model/models/common/basic';

type Props = {
    markStyle: LinkMarkStyle;
    menuSize: number;
    isSelected: boolean;
    onClick(): void;
};

export const LinkMarkStyleSubMenu: React.FC<Props> = ({ markStyle, menuSize, isSelected, onClick }: Props) => {
    const centerPosition = new Point(menuSize * 0.5, menuSize * 0.5);

    return (
        <g onClick={onClick}>
            <SubMenuBox menuSize={menuSize} isSelected={isSelected} />
            <g transform={centerPosition.toSVGTranslate()}>
                <LinkMarkStyleMenuIcon style={markStyle} isSelected={isSelected} />
            </g>
        </g>
    );
};

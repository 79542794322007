import { memo, useMemo } from 'react';
import { EditingUser } from './domain';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { PopupTextView } from '@view-model/models/framework/ui';
import { generateDefaultUserIcon } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

type Props = {
    currentEditingUser: EditingUser | null;
    position: Point;
    iconSize: number;
};

export const EditingUserIconView = memo(({ currentEditingUser, position, iconSize }: Props) => {
    const parentRect = useMemo(() => new Rect(position, new Size(iconSize, iconSize)), [iconSize, position]);
    if (!currentEditingUser) return null;

    const halfIconSize = iconSize / 2;
    const userIconSize = 40;
    const userIconPosition = new Point(iconSize - 10, iconSize - userIconSize);
    const imageURL = currentEditingUser.imageURL;
    return (
        <PopupTextView text={currentEditingUser.name} parentRect={parentRect} color={'gray'} placement="bottom">
            <g transform={position.addXY(-halfIconSize, -halfIconSize).toSVGTranslate()}>
                <foreignObject
                    className="rounded-full border-3 border-brand bg-white p-[5px] text-brand"
                    height={iconSize}
                    width={iconSize}
                >
                    <FontAwesomeIcon icon={faPen} size="2x" />
                </foreignObject>
                <foreignObject height={userIconSize} width={userIconSize} transform={userIconPosition.toSVGTranslate()}>
                    {imageURL ? (
                        <img
                            className="pointer-events-none rounded-full object-cover"
                            height={userIconSize}
                            width={userIconSize}
                            src={imageURL}
                            alt={currentEditingUser.name}
                        />
                    ) : (
                        <div
                            className="pointer-events-none overflow-hidden rounded-full object-cover"
                            style={{ width: userIconSize, height: userIconSize }}
                        >
                            {generateDefaultUserIcon({
                                id: currentEditingUser.key.id,
                                name: currentEditingUser.name,
                                size: userIconSize,
                            })}
                        </div>
                    )}
                </foreignObject>
            </g>
        </PopupTextView>
    );
});
EditingUserIconView.displayName = 'EditingUserIconView';

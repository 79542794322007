import { FontSize } from './FontSize';
import { SubMenuBox } from '@model-framework/menu';

type Props = {
    fontSize: FontSize;
    menuSize: number;
    isSelected: boolean;
    onClick(): void;
};

const fontSizeToTextFontSize: { [key in FontSize]: string } = {
    [FontSize.S]: '20px',
    [FontSize.M]: '26px',
    [FontSize.L]: '32px',
    [FontSize.XL]: '38px',
    [FontSize.XXL]: '44px',
} as const;

export const FontSizeSubMenu: React.FC<Props> = ({ fontSize, menuSize, isSelected, onClick }: Props) => {
    return (
        <>
            <SubMenuBox menuSize={menuSize} isSelected={isSelected} onClick={onClick} />
            {/* MenuBoxの中央に寄せる */}
            <text
                textAnchor="middle"
                dominantBaseline="central"
                fill={isSelected ? 'white' : '#aaa'}
                fontWeight="bold"
                pointerEvents="none"
                x={menuSize * 0.5}
                y={menuSize * 0.5}
                fontSize={fontSizeToTextFontSize[fontSize]}
            >
                A
            </text>
        </>
    );
};

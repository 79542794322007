import { ChangeEvent } from 'react';
import { ViewModelSharingUserRoleType } from '@view-model/domain/view-model';

type Props = {
    sharingUserRole: ViewModelSharingUserRoleType;
    isPublicWorkspace: boolean;
    onChange(role: ViewModelSharingUserRoleType): void;
    disabled: boolean;
};

export const ShareSetting: React.FC<Props> = ({ sharingUserRole, isPublicWorkspace, onChange, disabled }: Props) => {
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        onChange(ViewModelSharingUserRoleType.load(event.target.value));
    };

    return (
        <div>
            <p className="pb-1">
                このビューモデルのリンクを知っている人なら誰でもアクセスできるようになります。アクセスしたユーザに対する権限を設定します。
            </p>
            <p className="pb-2">
                This will allow anyone with this view model link can access. Manage permission for users accessed this
                view model.
            </p>
            <select
                className="form-select block w-64 py-1 text-sm"
                value={sharingUserRole}
                onChange={handleChange}
                disabled={disabled}
            >
                {ViewModelSharingUserRoleType.values(isPublicWorkspace).map((roleType) => (
                    <option key={roleType} value={roleType}>
                        {ViewModelSharingUserRoleType.getLocalString(roleType)}
                    </option>
                ))}
            </select>
        </div>
    );
};

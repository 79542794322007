type StickyZonePositionJSON = {
    x: number;
    y: number;
};

export class StickyZonePosition {
    readonly x: number;
    readonly y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    dump(): StickyZonePositionJSON {
        return { x: this.x, y: this.y };
    }

    static load({ x, y }: StickyZonePositionJSON): StickyZonePosition {
        return new this(x, y);
    }

    add(dx: number, dy: number): StickyZonePosition {
        return new StickyZonePosition(this.x + dx, this.y + dy);
    }

    addOffset(other: StickyZonePosition): StickyZonePosition {
        return new StickyZonePosition(this.x + other.x, this.y + other.y);
    }

    isEqual(other: StickyZonePosition): boolean {
        return other instanceof StickyZonePosition && this.x === other.x && this.y === other.y;
    }

    diff(other: StickyZonePosition): StickyZonePosition {
        return new StickyZonePosition(this.x - other.x, this.y - other.y);
    }

    toString(): string {
        return `(${this.x}, ${this.y})`;
    }
}

import { ClipboardPayload } from '@model-framework/clipboard';
import {
    isTSVClipboardPayload,
    TSVClipboardPayload,
    StickyModelClipboardPayload,
    isStickyModelClipboardPayload,
    isTextClipboardPayload,
    TextClipboardPayload,
} from '@view-model/application/clipboard';
import { isViewModelClipboardPayload, ViewModelClipboardPayload } from '@view-model/application/operation';
import { isViewsClipboardPayload, ViewsClipboardPayload } from './ViewsClipboardPayload';
import { ImageClipboardPayload, isImageClipboardPayload } from './ImageClipboardPayload';

export type ApplicationClipboardPayload =
    | ViewModelClipboardPayload
    | ViewsClipboardPayload
    | StickyModelClipboardPayload
    | TSVClipboardPayload
    | TextClipboardPayload
    | ImageClipboardPayload;

export const isApplicationClipboardPayload = (payload: ClipboardPayload): payload is ApplicationClipboardPayload =>
    isViewModelClipboardPayload(payload) ||
    isViewsClipboardPayload(payload) ||
    isStickyModelClipboardPayload(payload) ||
    isTSVClipboardPayload(payload) ||
    isTextClipboardPayload(payload) ||
    isImageClipboardPayload(payload);

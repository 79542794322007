export class WorkspaceDescription {
    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public toString(): string {
        return `${this.value}`;
    }

    public isEmpty(): boolean {
        return this.value === '';
    }
}

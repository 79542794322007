import { ModelCommentThread } from './ModelCommentThread';

export class ModelCommentThreadContainer {
    public readonly id: string;

    constructor(
        public readonly modelCommentThread: ModelCommentThread,
        public readonly viewId: string
    ) {
        this.id = modelCommentThread.id;
    }

    static compareByLastPostAt(a: ModelCommentThreadContainer, b: ModelCommentThreadContainer): number {
        return a.modelCommentThread.compareByLastPostAt(b.modelCommentThread);
    }
}

import { useCallback, useMemo } from 'react';
import { UserPublicProfile, useUserPublicProfile, useUserPublicProfiles } from '@user/PublicProfile';
import { Loading } from '@framework/ui/atoms/Loading';
import { WorkspaceMemberRole, WorkspaceMemberRoles } from '@workspace/domain/workspace';
import { RoleSelect } from './RoleSelect';
import { generateDefaultUserIcon } from '@framework/utils';

type Props = {
    currentUserId: string;
    memberRoles: WorkspaceMemberRoles;
    onChange(memberRoles: WorkspaceMemberRoles): void;
};

export const MemberEditList: React.FC<Props> = ({ currentUserId, memberRoles, onChange }: Props) => {
    const userIds = useMemo(() => {
        return memberRoles.userIds().filter((id) => id !== currentUserId);
    }, [memberRoles, currentUserId]);
    const myProfile = useUserPublicProfile(currentUserId);
    const myRole = memberRoles.getRoleOf(currentUserId);
    const profiles = useUserPublicProfiles(userIds);

    const handleRoleChange = useCallback(
        (userId: string, role: WorkspaceMemberRole) => {
            onChange(memberRoles.with(userId, role));
        },
        [onChange, memberRoles]
    );

    if (!myProfile || !myRole || !profiles) return <Loading />;

    return (
        <ul className="px-2">
            <MemberItem editable={false} profile={myProfile} role={myRole} onChange={() => void 0} />

            {profiles.map((profile) => {
                const role = memberRoles.getRoleOf(profile.id);
                if (!role) return null;

                return (
                    <MemberItem
                        key={profile.id}
                        editable={true}
                        profile={profile}
                        role={role}
                        onChange={handleRoleChange}
                    />
                );
            })}
        </ul>
    );
};

type ItemProps = {
    profile: UserPublicProfile;
    role: WorkspaceMemberRole;
    editable: boolean;
    onChange(userId: string, role: WorkspaceMemberRole): void;
};

const MemberItem: React.FC<ItemProps> = ({ profile, role, editable, onChange }: ItemProps) => {
    const handleChange = useCallback(
        (role: WorkspaceMemberRole) => {
            onChange(profile.id, role);
        },
        [onChange, profile.id]
    );

    return (
        <li className="flex flex-row items-center px-3 py-2 hover:bg-gray-200">
            <span className="flex size-6 overflow-hidden rounded-full">
                {profile.imageUrl ? (
                    <img className="size-full object-cover" src={profile.imageUrl} alt={profile.name} />
                ) : (
                    <div className="size-full object-cover">
                        {generateDefaultUserIcon({
                            id: profile.id,
                            name: profile.name,
                            size: 24,
                        })}
                    </div>
                )}
            </span>
            <span className="flex h-6 grow truncate px-2 leading-6">{profile.name}</span>
            <span className="w-30">
                {editable ? (
                    <RoleSelect role={role} onChange={handleChange} disabled={false} />
                ) : (
                    WorkspaceMemberRole.getLocalString(role)
                )}
            </span>
        </li>
    );
};

import { ViewModelId, FolderId } from '@schema-common/base';
import { FolderTreeJSON } from './FolderTree';

type FolderEntry = { id: FolderId; parent: FolderId };
type ViewModelEntry = { id: ViewModelId; parent: FolderId };

export class FolderMap {
    private readonly folderEntries: Readonly<FolderEntry[]>;
    private readonly viewModelEntries: Readonly<ViewModelEntry[]>;

    /**
     * RTDB上に永続化されたデータ構造から、FolderTreeを構成するためのデータ操作用クラス。
     *
     * コンストラクタに渡される folderIdMap, viewModelIdMap は、以下のようなデータ構造となっている。
     *
     * folderIdMap = {
     *     "child-folder-id": "__root__",
     *     "sub-child-folder-id": "child-folder-id",
     *     "<対象のフォルダID>": "<親フォルダID>",
     * }
     * viewModelIdMap = {
     *     "view-model-1": "__root__",
     *     "view-model-2": "child-folder-id",
     *     "view-model-3": "sub-child-folder-id",
     *     "<対象のビューモデルID>": "<親フォルダID>",
     * }
     *
     * @param folderIdMap {FolderIdMap}
     * @param viewModelIdMap {viewModelIdMap}
     */
    constructor(
        folderIdMap: Required<FolderTreeJSON>['folderIdMap'],
        viewModelIdMap: Required<FolderTreeJSON>['viewModelIdMap']
    ) {
        const sortById = (a: { id: string }, b: { id: string }) => a.id.localeCompare(b.id);

        this.folderEntries = Object.entries(folderIdMap)
            .map(([id, parent]) => ({ id, parent }))
            .sort(sortById);

        this.viewModelEntries = Object.entries(viewModelIdMap)
            .map(([id, parent]) => ({ id, parent }))
            .sort(sortById);
    }

    getViewModelIdsOf(parentFolderId: FolderId): ViewModelId[] {
        const entries = this.viewModelEntries.filter(({ parent }) => parent === parentFolderId);
        return entries.map(({ id }) => id);
    }

    getFolderIdsOf(parentFolderId: FolderId): FolderId[] {
        const entries = this.folderEntries.filter(({ parent }) => parent === parentFolderId);
        return entries.map(({ id }) => id);
    }
}

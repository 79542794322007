import { WorkspaceSetting } from '@workspace/domain/workspace';
import { WorkspaceMemberRoleJSON } from '@schema-common/workspace';
import { ViewModelSharingUserRoleType } from './vo/ViewModelSharingUserRoleType';

// rules.boltのcanReadViewModelも参照
export const canReadViewModel = (
    workspaceSetting: WorkspaceSetting,
    workspaceRole: WorkspaceMemberRoleJSON | null,
    trashedAt: string | null,
    viewModelSharingUserRoleType: ViewModelSharingUserRoleType
): boolean => {
    const { isPublicSpace, isViewModelURLShareable } = workspaceSetting;

    // 削除されているviewModel
    // 削除されているviewModelは、管理者か編集者のみ読み取り可能
    if (trashedAt) {
        return !!workspaceRole && ['admin', 'editor'].includes(workspaceRole);
    }

    // 削除されていないviewModel
    // いずれかのworkspaceロールが割り当てられていたら読み取り可能
    if (workspaceRole) {
        return true;
    }

    // 公開ワークスペースなら読み取り可能
    if (isPublicSpace) {
        return true;
    }

    // URL共有の設定が有効で、かつviewModel共有ロールが割り当てられているなら読み取り可能
    if (isViewModelURLShareable && viewModelSharingUserRoleType !== ViewModelSharingUserRoleType.None) {
        return true;
    }

    // いずれにも合致しないときは、読み取り不可
    return false;
};

import {
    CreatingModelComment,
    ModelCommentContents,
    ModelCommentThreadRegionCollection,
    ModelCommentThreadRepository,
} from '../domain';
import { IPositionSetRepository } from '@view-model/models/common/PositionSet';
import { CommandManager } from '@model-framework/command';
import { ModelCommentThreadCreateCommand } from '../command';
import { Rect, SizeSet } from '@view-model/models/common/basic';
import { ModelCommentThreadId } from '@schema-common/base';

export class ModelCommentOperation {
    constructor(
        private readonly threadRepository: ModelCommentThreadRepository,
        private readonly positionRepository: IPositionSetRepository,
        private readonly commandManager: CommandManager
    ) {}

    async createThread(creatingModelComment: CreatingModelComment): Promise<void> {
        const command = new ModelCommentThreadCreateCommand(
            creatingModelComment,
            this.threadRepository,
            this.positionRepository
        );
        this.commandManager.execute(command);
    }

    static getModelCommentThreadIdsByRect({
        rect,
        commentContents,
        commentSizeSet,
    }: {
        rect: Rect;
        commentContents: ModelCommentContents;
        commentSizeSet: SizeSet;
    }): ModelCommentThreadId[] {
        const regions = new ModelCommentThreadRegionCollection(
            commentContents.threads.list(),
            commentContents.positions,
            commentSizeSet
        );
        return regions.filterIdsBySelectionRect(rect);
    }
}

import { FirebaseFunctionsClient } from '@framework/firebase';
import { GroupId, WorkspaceId, UserId } from '@schema-common/base';

type ResourceType = 'group-icons' | 'workspace-icons' | 'user-icons';
type ResourceId = GroupId | WorkspaceId | UserId;

type UploadIconRequest = {
    action: 'uploadIcon';
    params: {
        resourceType: 'group-icons' | 'workspace-icons' | 'user-icons';
        resourceId: string;
        file: string;
    };
};

export class PublicIconUploader {
    private static async uploadIcon(resourceType: ResourceType, resourceId: ResourceId, data: Blob) {
        const call = FirebaseFunctionsClient.httpsCallable('fileUploader-actions');
        const request: UploadIconRequest = {
            action: 'uploadIcon',
            params: {
                resourceType,
                resourceId,
                // https.onCallではBlobObjectの受け渡しができないため、base64stringに変換して受け渡す
                file: await this.fileToBase64(data),
            },
        };
        const downloadUrl = (await call(request)).data?.downloadUrl;
        return downloadUrl;
    }

    static fileToBase64(file: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as string);
            };

            reader.readAsDataURL(file);
            reader.onerror = reject;
        });
    }

    static async uploadGroupIcon(groupId: GroupId, data: Blob) {
        return this.uploadIcon('group-icons', groupId, data);
    }

    static async uploadWorkspaceIcon(workspaceId: WorkspaceId, data: Blob) {
        return this.uploadIcon('workspace-icons', workspaceId, data);
    }

    static async uploadUserIcon(userId: UserId, data: Blob) {
        return this.uploadIcon('user-icons', userId, data);
    }
}

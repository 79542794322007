import { useEffect, useState } from 'react';
import { ViewBox } from '@view-model/domain/view-model';
import { useSidebarContext } from '@user/Sidebar';

const createContentViewBox = (sidebarWidth: number, isSidebarVisible: boolean) => {
    return isSidebarVisible
        ? new ViewBox(window.innerWidth - sidebarWidth, window.innerHeight)
        : new ViewBox(window.innerWidth, window.innerHeight);
};

export const useContentViewBox = (): ViewBox => {
    const { sidebarWidth, isSidebarVisible } = useSidebarContext();

    const [contentViewBox, setContentViewBox] = useState<ViewBox>(createContentViewBox(sidebarWidth, isSidebarVisible));

    useEffect(() => {
        const canvasResize = () => {
            setContentViewBox(createContentViewBox(sidebarWidth, isSidebarVisible));
        };
        canvasResize();
        window.addEventListener('resize', canvasResize);
        return () => window.removeEventListener('resize', canvasResize);
    }, [sidebarWidth, isSidebarVisible]);

    return contentViewBox;
};

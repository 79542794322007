import { DBPath, ObjectRepository, RTDBPath } from '@framework/repository';
import { ElementDescriptionJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/element-descriptions/{elementId}/ElementDescriptionJSON';
import { ElementId, ModelId, ViewModelId } from '@schema-common/base';
import { ElementDescription } from './ElementDescription';
import { ElementDescriptionTarget } from './ElementDescriptionTarget';

export class ElementDescriptionRepository extends ObjectRepository<ElementDescriptionJSON, ElementDescription> {
    static buildFromIds(
        viewModelId: ViewModelId,
        modelId: ModelId,
        target: ElementDescriptionTarget,
        elementId: ElementId
    ): ElementDescriptionRepository {
        return new this(ElementDescription, this.getDBPath(viewModelId, modelId, target, elementId));
    }

    private static getDBPath(
        viewModelId: ViewModelId,
        modelId: ModelId,
        target: ElementDescriptionTarget,
        elementId: ElementId
    ): DBPath {
        switch (target) {
            case 'Node':
                return RTDBPath.Node.descriptionPath(viewModelId, modelId, elementId);
            case 'Zone':
                return RTDBPath.Zone.descriptionPath(viewModelId, modelId, elementId);
            default:
                throw new Error(`Invalid target: ${target}`);
        }
    }
}

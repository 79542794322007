const colors: string[] = [
    //red
    '#E57373',
    '#EF5350',
    '#F44336',
    '#E53935',
    '#D32F2F',
    '#C62828',
    '#B71C1C',

    //pink
    '#F06292',
    '#EC407A',
    '#E91E63',
    '#D81B60',
    '#C2185B',
    '#AD1457',
    '#880E4F',

    //purple
    '#BA68C8',
    '#AB47BC',
    '#9C27B0',
    '#8E24AA',
    '#7B1FA2',
    '#6A1B9A',
    '#4A148C',

    //deepPurple
    '#9575CD',
    '#7E57C2',
    '#673AB7',
    '#5E35B1',
    '#512DA8',
    '#4527A0',
    '#311B92',

    //indigo
    '#7986CB',
    '#5C6BC0',
    '#3F51B5',
    '#3949AB',
    '#303F9F',
    '#283593',
    '#1A237E',

    //blue
    '#64B5F6',
    '#42A5F5',
    '#2196F3',
    '#1E88E5',
    '#1976D2',
    '#1565C0',
    '#0D47A1',

    //lightBlue
    '#4FC3F7',
    '#29B6F6',
    '#03A9F4',
    '#039BE5',
    '#0288D1',
    '#0277BD',
    '#01579B',

    //cyan
    '#4DD0E1',
    '#26C6DA',
    '#00BCD4',
    '#00ACC1',
    '#0097A7',
    '#00838F',
    '#006064',

    //teal
    '#4DB6AC',
    '#26A69A',
    '#009688',
    '#00897B',
    '#00796B',
    '#00695C',
    '#004D40',

    //green
    '#81C784',
    '#66BB6A',
    '#4CAF50',
    '#43A047',
    '#388E3C',
    '#2E7D32',
    '#1B5E20',

    //lightGreen
    '#AED581',
    '#9CCC65',
    '#8BC34A',
    '#7CB342',
    '#689F38',
    '#558B2F',
    '#33691E',

    //lime
    '#DCE775',
    '#D4E157',
    '#CDDC39',
    '#C0CA33',
    '#AFB42B',
    '#9E9D24',
    '#827717',

    //yellow
    '#FFF176',
    '#FFEE58',
    '#FFEB3B',
    '#FDD835',
    '#FBC02D',
    '#F9A825',
    '#F57F17',

    //amber
    '#FFD54F',
    '#FFCA28',
    '#FFC107',
    '#FFB300',
    '#FFA000',
    '#FF8F00',
    '#FF6F00',

    //orange
    '#FFB74D',
    '#FFA726',
    '#FF9800',
    '#FB8C00',
    '#F57C00',
    '#EF6C00',
    '#E65100',

    //deepOrange
    '#FF8A65',
    '#FF7043',
    '#FF5722',
    '#F4511E',
    '#E64A19',
    '#D84315',
    '#BF360C',
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const char2Int: any = {
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    a: 10,
    b: 11,
    c: 12,
    d: 13,
    e: 14,
    f: 15,
    g: 16,
    h: 17,
    i: 18,
    j: 19,
    k: 20,
    l: 21,
    m: 22,
    n: 23,
    o: 24,
    p: 25,
    q: 26,
    r: 27,
    s: 28,
    t: 29,
    u: 30,
    v: 31,
    w: 32,
    x: 33,
    y: 34,
    z: 35,
    A: 36,
    B: 37,
    C: 38,
    D: 39,
    E: 40,
    F: 41,
    G: 42,
    H: 43,
    I: 44,
    J: 45,
    K: 46,
    L: 47,
    M: 48,
    N: 49,
    O: 50,
    P: 51,
    Q: 52,
    R: 53,
    S: 54,
    T: 55,
    U: 56,
    V: 57,
    W: 58,
    X: 59,
    Y: 60,
    Z: 61,
    '-': 62,
    _: 64,
};

const name2char = (name: string): string => {
    let n = name.split(/[- ]/);
    n = n.filter((i) => i);
    if (n.length > 1) {
        return n[0][0] + n[1][0];
    }
    return name[0];
};

const colorSectionNum = colors.length / 7;

const getSecondColor = (colorIndex: number): string => {
    const colorSection = Math.floor(colorIndex / 7);
    const colorIndex2 = ((colorSection + 1) % colorSectionNum) * 7 + (colorIndex % 7) + 1;
    return colors[colorIndex2];
};

const getIDInt = (id: string): number => {
    const i = Number(id);
    if (Number.isNaN(i)) {
        let j = 0;
        Array.prototype.forEach.call(id, (k: string) => {
            j += char2Int[k];
        });
        return j;
    }
    return i;
};

const name2initial = (name: string): string => {
    const names = name.split(/\s+/);
    if (names.length == 1) {
        return names[0].substring(0, 2);
    }

    return names[0][0] + names[1][0];
};

type Props = {
    id: string;
    name: string;
    size?: number;
};

export const generateDefaultIcon: React.FC<Props> = ({ id, name, size = 144 }) => {
    const colorIndex = getIDInt(id) % colors.length;
    const color = colors[colorIndex];
    const color2 = getSecondColor(colorIndex);
    const char = name2char(name);
    const fontSize = `${size / (char.length * 1.5)}px`;
    const viewBox = `-${size / 2} -${size / 2} ${size} ${size}`;
    const rect = `-${size / 2}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={viewBox}>
            <rect x={rect} y={rect} width={size} height={size} fill={color}></rect>
            <circle cx="0" cy="0" r={size / 3} fill={color2}></circle>
            <text
                x="0"
                y="0"
                fill="#fff"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={fontSize}
                fontFamily="M PLUS 1p"
                fontWeight="bold"
            >
                {char}
            </text>
        </svg>
    );
};

export const generateDefaultUserIcon: React.FC<Props> = ({ id, name, size = 144 }) => {
    const colorIndex = parseInt(id) % colors.length;
    const color = colors[colorIndex];
    const initial = name2initial(name);
    const fontSize = `${size / (initial.length * 1.1)}px`;
    const viewBox = `-${size / 2} -${size / 2} ${size} ${size}`;
    const rect = `-${size / 2}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={viewBox}>
            <rect x={rect} y={rect} width={size} height={size} fill={color}></rect>
            <text x="0" y="0" fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={fontSize}>
                {initial}
            </text>
        </svg>
    );
};

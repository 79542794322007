import { DataSnapshot, RefBuilder, Reference } from '../RefBuilder';
import { DBPath } from '@framework/repository';

export class TextRepository {
    private callback: ((value: DataSnapshot) => void) | undefined = undefined;
    private readonly ref: Reference;

    constructor(path: DBPath) {
        this.ref = RefBuilder.ref(path);
    }

    addListener(callback: (value: string | null) => void): void {
        this.callback = (snapshot) => {
            const j = snapshot.val() as string | null;
            callback(j);
        };

        this.ref.on('value', this.callback);
    }

    removeListener(): void {
        // この Repository の addListener() で登録したリスナーだけを対象に、リスン解除する。
        this.ref.off('value', this.callback);
        this.callback = undefined;
    }

    async get(): Promise<string | null> {
        const snapshot = await this.ref.once('value');

        return snapshot.val();
    }

    async save(value: string): Promise<void> {
        await this.ref.set(value);
    }

    async delete(): Promise<void> {
        await this.ref.set(null);
    }
}

import { useCallback, useMemo, useRef } from 'react';
import { Random } from '@framework/Random';
import { Timestamp } from '@framework/Timestamp';
import { useActionLogSender } from './useActionLogSender';
import { ActionEventLogJSON } from '@schema-action-log/action-event-logs/ActionEventLogJSON';

type PageName =
    | 'LoginPage'
    | 'LogoutPage'
    | 'HomePage'
    | 'GroupPage'
    | 'NotFoundPage'
    | 'ViewModelPage'
    | 'WorkspacePage';

type PageViewLogger = {
    landing(extraEventParams?: ActionEventLogJSON['eventParams']): void;
    leaving(): void;
};

export const usePageViewLogger = (pageName: PageName): PageViewLogger => {
    const logSender = useActionLogSender();
    const params = useRef<ActionEventLogJSON['eventParams']>({
        // landing とleaving を対応づけるためのランダムIDを付与する。
        // (eventId は RTDB に永続化するまで確定しないので、ランダム文字列を生成する)
        landingId: Random.generateRandomID(),
    });
    const landingTimestamp = useRef(Timestamp.now());

    // ページ表示時のイベント記録用
    const landing = useCallback(
        (extraEventParams?: ActionEventLogJSON['eventParams']) => {
            params.current = {
                ...params.current,
                ...extraEventParams,
            };
            logSender('global:page_landing', {
                pageName,
                ...params.current,
            });
        },
        [logSender, pageName]
    );

    // ページ離脱時のイベント記録用
    const leaving = useCallback(() => {
        logSender('global:page_leaving', {
            pageName,
            stayTimeSeconds: Timestamp.now().diffSeconds(landingTimestamp.current),
            ...params.current,
        });
    }, [logSender, pageName]);

    const logger = useMemo(() => ({ landing, leaving }), [landing, leaving]);

    return logger;
};

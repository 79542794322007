import { useMemo } from 'react';

const CookieName = 'authMode';

const fallbackMode = 'login';
const modeNames = ['login', 'signUp', 'connect'] as const;
export type AuthMode = (typeof modeNames)[number];

type Result = {
    store: (mode: AuthMode) => void;
    fetch: () => AuthMode;
};

/**
 * OAuth認証連携のモードをCookieに保存します。
 *
 *  - login : ログイン処理を行う
 *  - signUp : 新規ユーザ登録を行う
 *  - connect : ログイン中ユーザに対して Jiji アカウントを連携する
 *
 * ログインボタンを押下した経路によって、新規ユーザ登録の可否が変化しますが、
 * ログイン処理の途中で、外部サービス(Jiji認証)を経由するため、何らかの手段で最初の経路情報を保持する必要があります。
 * ログイン後のリダイレクト先と同様に、フロントエンドで完結可能な Cookie に保持します。
 *
 * @returns
 */
export const useStoreAuthMode = (): Result => {
    return useMemo(() => {
        const store = (mode: AuthMode) => {
            document.cookie = `${CookieName}=${mode}; path=/; max-age=600`;
        };

        const fetch = (): AuthMode => {
            const cookies = document.cookie.split('; ');
            const cookieValue = cookies.find((cookie) => cookie.startsWith(`${CookieName}=`));
            if (!cookieValue) return fallbackMode;

            const value = cookieValue.replace(`${CookieName}=`, '');
            return modeNames.find((mode) => mode === value) || fallbackMode;
        };

        return { store, fetch };
    }, []);
};

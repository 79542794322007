import { FontSize } from './FontSize';
import { FontSizeMainMenu } from './FontSizeMainMenu';
import { FontSizeSubMenu } from './FontSizeSubMenu';
import { SubMenuListContainer } from '@model-framework/menu';

type Props = {
    currentFontSize?: FontSize; // 複数選択時など現在の値が一意に決まらないケースがある
    menuSize: number;
    items: Readonly<FontSize[]>;
    isOpen: boolean;
    onMenuClicked(): void;
    onSelected(newFontSize: FontSize): void;
    text: string;
};

export const FontSizeMenu: React.FC<Props> = ({
    currentFontSize,
    menuSize,
    items,
    isOpen,
    onMenuClicked,
    onSelected,
    text,
}: Props) => {
    const rectSize = {
        width: menuSize * items.length,
        height: menuSize,
    };
    return (
        <g>
            <FontSizeMainMenu menuSize={menuSize} isSelected={isOpen} onClick={onMenuClicked} text={text} />
            {isOpen && (
                <SubMenuListContainer menuSize={menuSize} rectSize={rectSize}>
                    {items.map((fontSize, i) => (
                        <g key={fontSize} transform={`translate(${menuSize * i}, 0)`}>
                            <FontSizeSubMenu
                                fontSize={fontSize}
                                menuSize={menuSize}
                                isSelected={fontSize === currentFontSize}
                                onClick={() => onSelected(fontSize)}
                            />
                        </g>
                    ))}
                </SubMenuListContainer>
            )}
        </g>
    );
};

import { ReactNode, createContext, useContext } from 'react';
import { StickyModelContentsOperation } from '@view-model/adapter';

const StickyModelContentsOperationContext = createContext<StickyModelContentsOperation | null>(null);

export const useStickyModelContentsOperation = (): StickyModelContentsOperation => {
    const operation = useContext(StickyModelContentsOperationContext);

    if (!operation) {
        throw new Error('No StickyModelContentsOperation.Provider found when calling useStickyModelContentsOperation.');
    }

    return operation;
};

type Props = {
    operation: StickyModelContentsOperation | null;
    children: ReactNode;
};

export const StickyModelContentsOperationContextProvider: React.FC<Props> = ({ operation, children }: Props) => {
    if (!operation) return null;

    return (
        <StickyModelContentsOperationContext.Provider value={operation}>
            {children}
        </StickyModelContentsOperationContext.Provider>
    );
};

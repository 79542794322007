import { useCallback, useMemo, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import * as EmailValidator from 'email-validator';
import { Link } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { classNames } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

const FooterLink: React.FC<unknown> = () => (
    <div className="pt-8">
        <Link to={UserPagePathBuilder.loginPage()} className="text-sm text-blue-700 hover:underline">
            ログインはこちら
        </Link>
        <br />
        <a
            href="https://links.balus.app/contact-form"
            target="_blank"
            rel="noreferrer"
            className="text-sm text-blue-700 hover:underline"
        >
            お問合せフォーム <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
    </div>
);

export const RequestForm: React.FC<unknown> = () => {
    const [mailAddress, setMailAddress] = useState('');
    const [processing, setProcessing] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState('');

    const handleChangeMailAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setMailAddress(event.target.value);
    }, []);

    const handleClick = useCallback(() => {
        setProcessing(true);

        FunctionsUserActions.sendPasswordResetToken(mailAddress)
            .then(() => {
                setDone(true);
            })
            .catch((error) => {
                setError('メールの送信に失敗しました。しばらく時間をあけてからもう一度試してください。');
                console.error(error);
            })
            .finally(() => {
                setProcessing(false);
            });
    }, [mailAddress]);

    const isValid = useMemo(() => {
        return mailAddress.length > 0 && EmailValidator.validate(mailAddress);
    }, [mailAddress]);

    if (done) {
        return (
            <>
                <div className="text-2xl font-bold">メールをご確認ください</div>
                <div className="pt-8">
                    入力していただいたメールアドレス宛に、パスワードを変更できるリンクをお送りしました。
                    <br />
                    メールが確認できない場合には、迷惑メールボックスに入っていないか、入力したメールアドレスが間違っていないか、改めてご確認ください。
                </div>

                <FooterLink />
            </>
        );
    }

    return (
        <>
            <div className="py-4">
                登録に利用したメールアドレスを入力してください。
                <br />
                パスワードを変更できるリンクをメールでお送りします。
            </div>
            <input
                type="text"
                className={classNames(
                    'w-full rounded-sm border p-2',
                    processing ? 'cursor-not-allowed bg-gray-200' : ''
                )}
                value={mailAddress}
                onChange={handleChangeMailAddress}
                placeholder="メールアドレス"
                autoFocus
                disabled={processing}
            />
            {error && <div className="text-red-500">{error}</div>}

            <div className="pt-8 text-center">
                <FlatButton
                    className="px-16"
                    color="brand"
                    onClick={handleClick}
                    disabled={!isValid || processing}
                    loading={processing}
                >
                    送信
                </FlatButton>
            </div>

            <FooterLink />
        </>
    );
};

import { useCallback, useState } from 'react';
import { SizeTracker } from '@view-model/models/common/components/SizeTracker';
import { Size } from '@view-model/models/common/basic/Size';

type Props = {
    onSizeChange?: (newSize: Size) => void;
    children: React.ReactNode;
    transform?: string;
};

/**
 * <foreignObject>要素のサイズを子要素に合わせて自動調整するコンポーネント
 * @deprecated できればForeignObjectAutosize2を使用してください
 */
export const ForeignObjectAutosize: React.FC<Props> = ({ children, onSizeChange, transform }: Props) => {
    const [size, setSize] = useState(new Size(0, 0));

    const handleSizeChanged = useCallback(
        (size: Size) => {
            setSize(size);
            onSizeChange?.(size);
        },
        [onSizeChange]
    );

    return (
        <foreignObject
            width={size.width}
            height={size.height}
            className="pointer-events-auto overflow-visible"
            style={{
                // 再描画の最適化のための指定
                contain: 'size style layout',
            }}
            transform={transform}
        >
            <SizeTracker onSizeChanged={handleSizeChanged}>{children}</SizeTracker>
        </foreignObject>
    );
};

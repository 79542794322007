import { useMemo } from 'react';
import { ISerializableObject, ISerializableObjectClass, ObjectRepository } from './ObjectRepository';
import { DBPath } from '@framework/repository';

export const useObjectRepository = <DumpType, ObjectType extends ISerializableObject<DumpType>>(
    objectClass: ISerializableObjectClass<DumpType, ObjectType>,
    path: DBPath
): ObjectRepository<DumpType, ObjectType> => {
    return useMemo(() => {
        return new ObjectRepository<DumpType, ObjectType>(objectClass, path);
    }, [objectClass, path]);
};

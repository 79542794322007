import { ModelElementId } from '@view-model/domain/key';
import { ClientSelectedItemCollection, ClientSelectedItemRepository } from '@model-framework/client-selected-item';
import { SelectedItemSet } from '@view-model/models/common/basic';
import { ClientId, ModelId, ViewModelId } from '@schema-common/base';
import { getClientId } from '@framework/app';

type SelectedIdSet = SelectedItemSet<ModelElementId>;

export class SelectedItemsOperation {
    private readonly clientId: ClientId;
    private readonly repository: ClientSelectedItemRepository;

    constructor(viewModelId: ViewModelId, modelId: ModelId) {
        this.clientId = getClientId();
        this.repository = new ClientSelectedItemRepository(viewModelId, modelId);
    }

    deleteMySelection(): void {
        this.repository.deleteAllByClientId(this.clientId).then();
    }

    private update(selectedItems: SelectedIdSet, previousSelectedItems: SelectedIdSet): void {
        // 現在の選択状態を保存
        this.saveMulti(selectedItems);

        // previous に含まれるが、 current に含まれていない選択状態を削除
        this.deleteMulti(previousSelectedItems.difference(selectedItems));
    }

    private saveMulti(selectedItems: SelectedIdSet): void {
        const items = ClientSelectedItemCollection.fromItems(this.clientId, selectedItems.getSelectedItems());
        this.repository.saveMulti(items).then();
    }

    private deleteMulti(selectedItems: SelectedIdSet): void {
        const items = ClientSelectedItemCollection.fromItems(this.clientId, selectedItems.getSelectedItems());
        this.repository.deleteMulti(items.allIds()).then();
    }

    /**
     * 全ての選択を解除する
     * @param selectedItems 既存の選択状態
     */
    deselectAll(selectedItems: SelectedIdSet): void {
        this.update(selectedItems.deselectAll(), selectedItems);
    }

    /**
     * 複数のアイテムの選択を解除する
     * @param ids
     * @param selectedItems 既存の選択状態
     */
    deselectMany(ids: ModelElementId[], selectedItems: SelectedIdSet): void {
        this.update(selectedItems.deselectMany(ids), selectedItems);
    }

    /**
     * 既存のアイテムの選択を全て解除して、渡されたアイテムを選択する
     * @param id
     * @param selectedItems 既存の選択状態
     */
    selectOnly(id: ModelElementId, selectedItems: SelectedIdSet): void {
        this.update(SelectedItemSet.from([id]), selectedItems);
    }

    /**
     * アイテムを追加で選択する
     * @param id
     * @param selectedItems 既存の選択状態
     */
    addToSelection(id: ModelElementId, selectedItems: SelectedIdSet): void {
        this.update(selectedItems.addToSelection(id), selectedItems);
    }

    /**
     * 複数のアイテムを追加で選択する
     * @param ids
     * @param selectedItems 既存の選択状態
     */
    addToSelectionMany(ids: ModelElementId[], selectedItems: SelectedIdSet): void {
        this.update(selectedItems.addToSelectionMany(ids), selectedItems);
    }

    /**
     * 指定の要素の選択を解除する
     * @param id
     * @param selectedItems 既存の選択状態
     */
    deselect(id: ModelElementId, selectedItems: SelectedIdSet): void {
        this.update(selectedItems.deselect(id), selectedItems);
    }
}

import { ViewName, ViewEntity, ViewSetting } from '@view-model/domain/view';
import { ViewPlacement } from './ViewPlacement';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { StickyModel, StickyModelContents } from '@view-model/domain/model';
import { CommandManager, CompositeCommand, CreateCommand } from '@model-framework/command';
import { ModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ModelCascadeRepository';
import { ModelCreateCommand } from '@view-model/command/model/ModelCreateCommand';
import { PositionSet, PositionSetCreateCommand } from '@view-model/models/common/PositionSet';
import { ViewId, ModelId, GroupId, UserId, ViewModelId } from '@schema-common/base';
import { ViewsClipboardOperator, ViewsClipboardPayload } from '@view-model/application/clipboard';
import { ViewModelOperationLogSender } from '@view-model/models/framework/action-log';
import { RTDBPath, ObjectRepository } from '@framework/repository';

// Frame GridContainerのグリッドサイズ
const VIEW_GRID_SIZE = 512;

type CreateResult = {
    viewId: ViewId;
    modelId: ModelId;
    modelType: string;
};

export class ViewModelPageOperation {
    private readonly defaultViewPlacement = new ViewPlacement(VIEW_GRID_SIZE, 7, 5);

    constructor(
        private readonly groupId: GroupId,
        private readonly currentUserId: UserId,
        private readonly commandManager: CommandManager,
        private readonly viewModelId: ViewModelId,
        private readonly logSender: ViewModelOperationLogSender
    ) {}

    addStickyView(area: Rect, modelContents?: StickyModelContents, title?: string): CreateResult {
        let viewId;
        let model;

        if (!modelContents) {
            // 空のViewを作成
            model = StickyModel.buildNew();

            const position = this.defaultViewPlacement.chooseViewPosition(area);
            const width = this.defaultViewPlacement.viewWidth;
            const height = this.defaultViewPlacement.viewHeight;

            viewId = this.addView(
                '',
                position,
                width,
                height,
                StickyModelContents.buildEmpty(model.key, model.version)
            );
        } else {
            model = modelContents.modelEntity();
            const position = new Point(area.x, area.y);

            viewId = this.addView(title || '', position, area.width, area.height, modelContents);
        }

        return {
            viewId,
            modelId: model.id,
            modelType: model.type,
        };
    }

    private addView(
        title: string,
        position: Point,
        width: number,
        height: number,
        modelContents: StickyModelContents
    ): ViewId {
        const view = ViewEntity.buildNew({
            name: new ViewName(title),
            modelKey: modelContents.key,
            size: new Size(width, height),
            setting: ViewSetting.buildNew(),
        });

        const repository = new ObjectRepository(ViewEntity, RTDBPath.View.viewPath(this.viewModelId, view.id));
        const viewCreateCommand = new CreateCommand(view, repository);

        const viewPositionCreateCommand = new PositionSetCreateCommand(
            new PositionSet({ [view.id]: position }),
            RTDBPath.View.positionsPath(this.viewModelId)
        );

        const modelCascadeRepository = new ModelCascadeRepository(this.viewModelId);
        const modelCreateCommand = new ModelCreateCommand(modelCascadeRepository, modelContents);

        const command = new CompositeCommand(modelCreateCommand, viewCreateCommand, viewPositionCreateCommand);
        this.commandManager.execute(command);

        return view.id;
    }

    async pasteViews(
        clipboard: ViewsClipboardPayload,
        visibleAreaCenterPoint: Point
    ): Promise<[Set<ViewId>, string | null]> {
        const { groupId, currentUserId, viewModelId, commandManager, logSender } = this;
        return ViewsClipboardOperator.paste(
            clipboard,
            visibleAreaCenterPoint,
            groupId,
            viewModelId,
            currentUserId,
            commandManager,
            logSender
        );
    }

    convertZoneToView(area: Rect, modelContents: StickyModelContents, title: string): CreateResult {
        return this.addStickyView(area, modelContents, title);
    }
}

import { DisplayOrder } from './DisplayOrder';
import { DisplayOrderZoneEntry } from './DisplayOrderZoneEntry';
import { IOrderRollback } from '../domain';
import { Reference } from '@framework/repository';

/**
 * 並び順の変更を定義するインタフェース
 */
interface IDisplayOrderChange {
    /**
     * 更新データを返す
     */
    updates(): Record<string, string | null>;

    /**
     * updates()をロールバックする更新データを返す
     */
    rollbackUpdates(): Record<string, string | null>;
}

/**
 * DisplayOrder削除変更
 */
class RemoveOrderChange implements IDisplayOrderChange {
    constructor(private readonly order: DisplayOrder) {}

    updates(): Record<string, null> {
        return this.order.updatesForRemove();
    }

    rollbackUpdates(): Record<string, string> {
        return this.order.updatesForAdd();
    }
}

/**
 * DisplayOrder保存変更
 */
class AddOrderChange implements IDisplayOrderChange {
    constructor(private readonly order: DisplayOrder) {}

    updates(): Record<string, string> {
        return this.order.updatesForAdd();
    }

    rollbackUpdates(): Record<string, string | null> {
        return this.order.updatesForRemove();
    }
}

/**
 * ZoneEntry削除変更
 */
class RemoveZoneEntryChange implements IDisplayOrderChange {
    constructor(private readonly zone: DisplayOrderZoneEntry) {}

    updates(): Record<string, string | null> {
        return this.zone.updatesForRemove();
    }

    rollbackUpdates(): Record<string, string> {
        return this.zone.updatesForAdd();
    }
}

/**
 * DisplayOrder/ZoneEntryといった並び順の変更をまとめるクラス
 */
export class DisplayOrderChangeset implements IOrderRollback {
    private readonly changes: IDisplayOrderChange[];

    private constructor(changes: IDisplayOrderChange[] = []) {
        this.changes = changes;
    }

    static changeset(): DisplayOrderChangeset {
        return new DisplayOrderChangeset();
    }

    removeOrder(order: DisplayOrder): DisplayOrderChangeset {
        return this.removeOrders([order]);
    }

    removeOrders(orders: DisplayOrder[]): DisplayOrderChangeset {
        return new DisplayOrderChangeset([...this.changes, ...orders.map((order) => new RemoveOrderChange(order))]);
    }

    removeZoneEntry(zoneEntry: DisplayOrderZoneEntry): DisplayOrderChangeset {
        return this.removeZoneEntries([zoneEntry]);
    }

    removeZoneEntries(zoneEntries: DisplayOrderZoneEntry[]): DisplayOrderChangeset {
        return new DisplayOrderChangeset([
            ...this.changes,
            ...zoneEntries.map((zoneEntry) => new RemoveZoneEntryChange(zoneEntry)),
        ]);
    }

    addOrder(order: DisplayOrder): DisplayOrderChangeset {
        return this.addOrders([order]);
    }

    addOrders(orders: DisplayOrder[]): DisplayOrderChangeset {
        return new DisplayOrderChangeset([...this.changes, ...orders.map((order) => new AddOrderChange(order))]);
    }

    updates(): Record<string, string> {
        const updates = {};

        this.changes.forEach((command) => Object.assign(updates, command.updates()));

        return updates;
    }

    rollbackUpdates(): Record<string, string> {
        const updates = {};

        this.changes
            .concat() // reverse() が破壊的なのでconcat()する
            .reverse()
            .forEach((command) => Object.assign(updates, command.rollbackUpdates()));

        return updates;
    }

    async saveTo(ref: Reference): Promise<DisplayOrderChangeset> {
        await ref.update(this.updates());
        return this;
    }

    async rollback(ref: Reference): Promise<void> {
        await ref.update(this.rollbackUpdates());
    }
}

type Props = {
    size: number;
    className?: string; // tailwindcssによるスタイル設定のため
};

/**
 * 幅=size, 高さ=size*2/3 のダイヤモンド型。sizeの1/8だけx軸方向にマイナスオフセット。
 */
export const DiamondMarkView: React.FC<Props & React.SVGProps<SVGPathElement>> = ({
    size,
    className,
    ...props
}: Props & React.SVGProps<SVGPathElement>) => {
    const s = size / 2;
    const [x1, y1] = [s, 0];
    const [x2, y2] = [0, (s * 2) / 3];
    const [x3, y3] = [-s, 0];
    const [x4, y4] = [0, -(s * 2) / 3];
    const xOffset = -s / 4;

    return (
        <path
            d={`M${x1 + xOffset},${y1}L${x2 + xOffset},${y2}L${x3 + xOffset},${y3}L${x4 + xOffset},${y4}Z`}
            className={className}
            {...props}
        />
    );
};

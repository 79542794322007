import { useCallback } from 'react';
import { UserData } from '@user/UserData';
import { BasicItemMenuButton } from '../common';

type Props = {
    memberData: UserData;
    setRemovableMemberData: (memberData: UserData) => void;
    disabled: boolean;
};

export const ItemMenuButton: React.FC<Props> = ({ memberData, setRemovableMemberData, disabled }: Props) => {
    const handleRemoveMember = useCallback(() => {
        setRemovableMemberData(memberData);
    }, [memberData, setRemovableMemberData]);

    return <BasicItemMenuButton menuTitle="グループから削除" onClickMenu={handleRemoveMember} disabled={disabled} />;
};

import { useCallback, useState } from 'react';
import { UserData } from '@user/UserData';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { toast } from 'react-hot-toast';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

type Props = {
    isOpen: boolean;
    onClose(): void;
    userData: UserData;
};

export const DisconnectJijiModal: React.FC<Props> = ({ isOpen, onClose, userData }: Props) => {
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState('');

    const handleClose = useCallback(() => {
        setProcessing(false);
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        setProcessing(true);

        if (await FunctionsUserActions.disconnectJiji()) {
            toast.success('外部認証アカウントとの連携を解除しました。');
            handleClose();
        } else {
            setError('外部認証アカウントとの連携を解除できませんでした。時間をあけてもう一度試してみてください。');
            setProcessing(false);
        }
    }, [handleClose]);

    const noPassword = !userData.hasPassword;

    return (
        <Modal isOpen={isOpen} onClose={handleClose} className="flex flex-col">
            {/* header */}
            <div className="px-8 pb-4 pt-8 text-2xl font-bold">外部認証プロバイダとの連携を解除する</div>
            <hr className="w-full" />

            {/* content */}
            <div className="flex flex-col p-8">
                <div>
                    外部認証プロバイダとの連携を解除すると、次回以降のログイン時にはメールアドレスとパスワードが必要になります。
                </div>

                <div className="py-4 text-center">
                    <FlatButton
                        color="brand"
                        onClick={handleSubmit}
                        className="px-8"
                        disabled={noPassword || processing}
                        loading={processing}
                    >
                        連携を解除する
                    </FlatButton>
                </div>

                {noPassword && (
                    <div className="text-red-700">パスワードが未設定のため、連携を解除することはできません</div>
                )}

                {error && <div className="pt-8 text-red-700">{error}</div>}
            </div>

            {/* footer */}
            <hr className="w-full" />
            <div className="flex justify-end px-8 pb-8 pt-4">
                <FlatButton onClick={handleClose}>閉じる</FlatButton>
            </div>
        </Modal>
    );
};

import { BalusIcon } from './internal/BalusIcon';
import { CloseIcon } from './internal/CloseIcon';
import { classNames } from '@framework/utils';

type IconType = 'fill' | 'line';
type IconColor = IconType | 'brand' | string;
type IconSize = 'sm' | 'md' | 'lg' | 'xl' | 'brand-xl';

type IconName = 'balus' | 'close';

export type IconProps = {
    type: IconType;
    fill: string;
    className?: string;
};

const iconSize: { [key in IconSize]: string } = {
    sm: 'size-[12px]',
    md: 'size-[18px]',
    lg: 'size-[24px]',
    xl: 'size-[30px]',
    'brand-xl': 'size-[140px]',
};

const icons: {
    [key in IconName]: React.FunctionComponent<IconProps>;
} = {
    balus: BalusIcon,
    close: CloseIcon,
};

// eslint-disable-next-line react/display-name
const iconFactory = (name: IconName) => (props: IconProps) => {
    const Component = icons[name];
    return <Component className="absolute left-0 top-0" {...props} />;
};

const getIconColor = (color: IconColor) => {
    switch (color) {
        case 'fill':
        case 'line':
        case 'brand':
            return '#0097A7';
        default:
            return color;
    }
};

type Props = {
    name: IconName;
    type?: IconType;
    size?: IconSize;
    color?: IconColor;
};

export const Icon: React.FunctionComponent<Props> = ({ name, type = 'line', size = 'md', color = 'fill' }: Props) => {
    return (
        <div className={classNames('relative overflow-hidden', iconSize[size])}>
            {iconFactory(name)({ type, fill: getIconColor(color) })}
        </div>
    );
};

import { GROUP_LICENSE_TYPE_DICT } from '@schema-app/group/licenses/{groupId}/GroupLicenseJSON';
import { GroupLicense } from '@group/domain';

type Props = {
    license: GroupLicense;
};

export const GroupLicenseInfo: React.FC<Props> = ({ license }: Props) => {
    return (
        <div className="ml-4 border-2 border-slate-300 bg-gray-100 p-2">
            <div className="flex">
                <div className="min-w-32">ライセンス種類：</div>
                <div>{GROUP_LICENSE_TYPE_DICT[license.type]}</div>
            </div>
            <div className="flex">
                <div className="min-w-32">ライセンス数：</div>
                <div>{license.count}</div>
            </div>
            <div className="flex">
                <div className="min-w-32">ライセンス期限：</div>
                <div>{license.expiresAt.format('YYYY/MM/DD')}</div>
            </div>
        </div>
    );
};

import { useEffect, useState } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { RTDBPath, TextRepository } from '@framework/repository';
import { WorkspaceId } from '@schema-common/base';
import { WorkspaceMemberRoleJSON } from '@schema-common/workspace';
import { isWorkspaceMemberRole } from '@workspace/domain/workspace/vo/WorkspaceMemberRole';

/**
 * 指定のワークスペースに対する、ログイン中ユーザ自身のメンバーロールを取得して返す
 *
 * @param workspaceId
 * @returns {undefined} メンバーロールの情報をRTDBから取得中
 * @returns {null} 対象ワークスペースに所属していない、または、ログインしていない
 * @returns {WorkspaceMemberRoleJSON} 対象ワークスペースに所属するメンバーロール
 */
export const useWorkspaceMyMemberRole = (workspaceId: WorkspaceId): WorkspaceMemberRoleJSON | null | undefined => {
    const [role, setRole] = useState<WorkspaceMemberRoleJSON | null | undefined>(undefined);
    const currentUserId = useCurrentUserId();

    useEffect(() => {
        if (!currentUserId) {
            // 未ログイン状態の場合には、自身のワークスペースメンバーロールは null として扱う
            setRole(null);
            return;
        }

        // ローディング開始
        setRole(undefined);

        const repo = new TextRepository(RTDBPath.Workspace.memberRolePath(workspaceId, currentUserId));

        repo.addListener((role) => {
            if (role && isWorkspaceMemberRole(role)) {
                setRole(role);
            } else {
                setRole(null);
            }
        });

        return () => repo.removeListener();
    }, [currentUserId, workspaceId]);

    return role;
};

import { DBPath, RefBuilder, Reference } from '@framework/repository';
import { isEqual } from 'lodash';

type JSONPrimitive = boolean | number | string | null;
type JSONArray = JSONPrimitive[] | JSONObject[];
type JSONObject = {
    [key: string]: JSONPrimitive | JSONObject | JSONArray;
};
export type JSONValue = JSONPrimitive | JSONArray | JSONObject;

/**
 * JSONデータ型とRepository, Commandとの互換性を持たせるためのラッパークラス
 */
export class JSONValueWrapper {
    constructor(private readonly value: JSONValue) {}

    dump(): JSONValue {
        return this.value;
    }

    static load(dump: JSONValue): JSONValueWrapper {
        return new JSONValueWrapper(dump);
    }

    isEqual(other: JSONValueWrapper): boolean {
        return isEqual(this.value, other.value);
    }
}

export class JSONRepository {
    private readonly ref: Reference;
    constructor(path: DBPath) {
        this.ref = RefBuilder.ref(path);
    }

    async save(value: JSONValueWrapper): Promise<void> {
        await this.ref.set(value.dump());
    }

    async delete(): Promise<void> {
        await this.ref.set(null);
    }

    async get(): Promise<JSONValueWrapper> {
        const snapshot = await this.ref.once('value');
        const j = snapshot.val() as JSONValue | null;

        // nullも含めてラップして返す
        return JSONValueWrapper.load(j);
    }
}

import { LinkKey } from '@view-model/domain/key';
import { useCallback, useRef } from 'react';
import { EditingUserRepository } from '@view-model/models/framework/text/editing-user';
import { ViewModelId } from '@schema-common/base';
import { useEditingUserFromAuthContext } from '@view-model/models/framework/text/editing-user/useEditingUserFromAuthContext';
import { useNodeEditingUser } from '../../../sticky/editing-users/useNodeEditingUser';

export const useExclusiveLinkEditing = (
    linkKey: LinkKey | null,
    viewModelId: ViewModelId | null
): {
    otherUserEditing: boolean;
    startEdit: () => void;
    endEdit: () => void;
} => {
    const editingUserRepositoryRef = useRef<EditingUserRepository | null>(
        viewModelId ? new EditingUserRepository(viewModelId) : null
    );
    const authEditingUser = useEditingUserFromAuthContext();

    const editingUser = useNodeEditingUser(linkKey);
    const otherUserEditing = !!(editingUser && authEditingUser && !authEditingUser.isEqual(editingUser));

    const startEdit = useCallback(() => {
        const repo = editingUserRepositoryRef.current;
        if (!authEditingUser || !repo || !linkKey) return;
        repo.save(linkKey, authEditingUser).then();
    }, [linkKey, authEditingUser]);

    const endEdit = useCallback(() => {
        const repo = editingUserRepositoryRef.current;
        if (!linkKey || !repo) return;
        repo.delete(linkKey).then();
    }, [linkKey]);

    return { otherUserEditing, startEdit, endEdit };
};

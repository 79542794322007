import { ViewModelEntity } from '@view-model/domain/view-model';
import { StickyNode, NodeCollection } from '../domain';
import { NodeKey } from '@view-model/domain/key';
import { NodeRepository } from '../domain/NodeRepository';

export class NodeEntityOperation {
    constructor(private readonly repository: NodeRepository) {}

    static getNode(key: NodeKey, viewModel: ViewModelEntity): StickyNode | null {
        return NodeEntityOperation.findNode(key, (node) => node !== null, viewModel);
    }

    private static findNode(
        key: NodeKey,
        isTarget: (node: StickyNode) => boolean,
        viewModel: ViewModelEntity
    ): StickyNode | null {
        const models = viewModel.getModels();

        for (const model of models) {
            const node = model.findNode(key);
            if (node && isTarget(node)) return node;
        }

        return null;
    }

    private static getNodes(keys: NodeKey[], viewModel: ViewModelEntity): StickyNode[] | null {
        return NodeEntityOperation.findNodes(keys, (node) => node !== null, viewModel);
    }

    static getNodeCollection(keys: NodeKey[], viewModel: ViewModelEntity): NodeCollection | null {
        const nodes = NodeEntityOperation.getNodes(keys, viewModel);
        return nodes ? new NodeCollection(nodes) : null;
    }

    private static findNodes(
        keys: NodeKey[],
        isTarget: (node: StickyNode, index: number) => boolean,
        viewModel: ViewModelEntity
    ): StickyNode[] | null {
        const models = viewModel.getModels();

        const nodes: StickyNode[] = [];
        const foundAll = keys.every((key, i) => {
            for (const model of models) {
                const node = model.findNode(key);
                if (node && isTarget(node, i)) {
                    nodes.push(node);
                    return true;
                }
            }

            return false;
        });

        if (foundAll) return nodes;
        return null;
    }

    static existsSome(keys: NodeKey[], viewModel: ViewModelEntity): boolean {
        const models = viewModel.getModels();
        return keys.some((key) => {
            for (const model of models) {
                const node = model.findNode(key);
                if (node) return true;
            }

            return false;
        });
    }
    save(node: StickyNode): void {
        this.repository.saveNode(node).then();
    }

    saveCollection(nodes: NodeCollection): void {
        this.repository.saveNodeCollection(nodes).then();
    }

    remove(node: StickyNode): void {
        this.repository.deleteNode(node.key).then();
    }

    removeMulti(keys: NodeKey[]): void {
        this.repository.deleteNodes(keys).then();
    }
}

import { ICommand } from '@model-framework/command';
import { NodeEntityOperation } from '../adapter';
import { StickyNode } from '../domain';
import { DisplayOrderRepository } from '@model-framework/display-order/infrastructure';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class NodeCreateCommand implements ICommand {
    private readonly node: StickyNode;

    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly entityOperation: NodeEntityOperation,
        node: StickyNode,
        private readonly displayOrderRepository: DisplayOrderRepository
    ) {
        this.node = node.clone();
    }

    do(): void {
        this.entityOperation.save(this.node);
        this.displayOrderRepository.addNode(this.node.id).then();
    }

    undo(): void {
        const node = NodeEntityOperation.getNode(this.node.key, this.viewModel);
        if (!node) return;
        this.entityOperation.remove(node);
        this.displayOrderRepository.removeNode(this.node.id).then();
    }

    redo(): void {
        const node = NodeEntityOperation.getNode(this.node.key, this.viewModel);
        if (node) return;
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return !!NodeEntityOperation.getNode(this.node.key, this.viewModel);
    }

    async canRedo(): Promise<boolean> {
        return !NodeEntityOperation.getNode(this.node.key, this.viewModel);
    }
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

type Props = {
    horizontal?: boolean;
    message?: string;
};

export const Loading: React.FC<Props> = ({ message, horizontal = false }: Props) => {
    return (
        <>
            {horizontal ? (
                <div className={'flex flex-row items-center rounded-lg bg-white p-4'}>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin text-3xl text-brand" />
                    <div className="flex pl-4" />
                    <div className="text-center text-2xl font-light text-brand">{message || 'Loading...'}</div>
                </div>
            ) : (
                <div className="flex flex-col items-center rounded-lg bg-white p-4">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin text-3xl text-brand" />
                    <div className="mt-2 text-center text-2xl font-light text-brand">{message || 'Loading...'}</div>
                </div>
            )}
        </>
    );
};

import { useCallback, useState } from 'react';
import { Loading } from '@framework/ui/atoms/Loading';
import { GroupMemberInvitation, GroupMemberSignUpMethod } from '@group/domain';
import { LoginMethodMenu, PasswordLoginFormContainer, ProviderName, useOAuthLogin } from '@user/Login';
import { SignUpMethodMenu } from './SignUpMethodMenu';

type Props = {
    invitation: GroupMemberInvitation;
    availableSignUpMethods: GroupMemberSignUpMethod;
};

/**
 * 未ログイン状態のユーザ向けの、グループメンバーへの招待確認メニュー
 * @returns
 */
export const UnauthenticatedMenu: React.FC<Props> = ({ availableSignUpMethods, invitation }: Props) => {
    const [processing, setProcessing] = useState(false);
    const oauthLogin = useOAuthLogin('login');

    const handleOAuthLogin = useCallback(
        (providerName: ProviderName) => {
            setProcessing(true);
            oauthLogin(providerName);
        },
        [oauthLogin]
    );

    const handleLoginWithGoogle = useCallback(() => handleOAuthLogin('google'), [handleOAuthLogin]);
    const handleLoginWithQuadcept = useCallback(() => handleOAuthLogin('quadcept'), [handleOAuthLogin]);
    const [showPasswordLogin, setShowPasswordLogin] = useState<boolean>(false);
    const handleLoginWithPassword = useCallback(() => setShowPasswordLogin(true), []);
    const handleCloseLoginWithPassword = useCallback(() => setShowPasswordLogin(false), []);

    const [showSignUpMenu, setShowSignUpMenu] = useState(true);
    const handleShowSignUpMenu = useCallback(() => setShowSignUpMenu(false), []);
    const handleCloseSignUpMenu = useCallback(() => setShowSignUpMenu(true), []);

    if (processing) {
        return (
            <div className="flex w-80 flex-col space-y-4 px-5 py-2">
                <Loading message="ログインしています..." />
            </div>
        );
    }

    if (showPasswordLogin) {
        return <PasswordLoginFormContainer onClose={handleCloseLoginWithPassword} />;
    }

    if (showSignUpMenu) {
        return (
            <SignUpMethodMenu
                availableSignUpMethods={availableSignUpMethods}
                invitation={invitation}
                onLogin={handleShowSignUpMenu}
            />
        );
    }

    return (
        <>
            <div className="flex w-80 flex-col space-y-4 px-5 py-2">
                <div className="cursor-pointer font-bold text-brand hover:underline" onClick={handleCloseSignUpMenu}>
                    &lt; 戻る
                </div>
            </div>
            <LoginMethodMenu
                onLoginWithGoogle={handleLoginWithGoogle}
                onLoginWithQuadcept={handleLoginWithQuadcept}
                onLoginWithPassword={handleLoginWithPassword}
            />
        </>
    );
};

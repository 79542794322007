import { UserPageLocationState } from '@user/pages/UserPageLocationState';
import { useLocation } from 'react-router-dom';

export const useLoginErrorMessage = (): string | null => {
    const location = useLocation<UserPageLocationState>();

    if (location.state?.loginFailed) {
        return 'ログイン処理に失敗しました。もう一度試してみてください。';
    }

    if (location.state?.userNotFound) {
        return '利用可能なユーザが見つかりませんでした。招待メールのURLからユーザ登録を試してください。';
    }

    return location.state?.loginErrorMessage || null;
};

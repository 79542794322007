import { useEffect, useState } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { useParams } from 'react-router-dom';
import { RTDBPath, TextRepository } from '@framework/repository';
import { RouteParams } from '@user/pages/RouteParams';

export const useIsSidebarAvailable = (): boolean => {
    const [isSidebarAvailable, setIsSidebarAvailable] = useState<boolean>(false);

    const { groupId, workspaceId, viewModelId } = useParams<RouteParams>();

    const currentUserId = useCurrentUserId();

    useEffect(() => {
        if (!currentUserId) {
            setIsSidebarAvailable(false);
            return;
        }

        const checkGroupMember = async (groupId: string): Promise<void> => {
            const repo = new TextRepository(RTDBPath.Group.memberRolePath(groupId, currentUserId));
            const value = await repo.get();
            setIsSidebarAvailable(!!value);
        };

        const checkWorkspaceMember = async (workspaceId: string): Promise<void> => {
            const repo = new TextRepository(RTDBPath.Workspace.memberRolePath(workspaceId, currentUserId));
            const value = await repo.get();
            setIsSidebarAvailable(!!value);
        };

        const checkWorkspaceMemberWithViewModelId = async (viewModelId: string): Promise<void> => {
            const repo = new TextRepository(RTDBPath.ViewModel.viewModelWorkspaceIdPath(viewModelId));
            const workspaceId = await repo.get();
            if (!workspaceId) {
                setIsSidebarAvailable(false);
                return;
            }

            await checkWorkspaceMember(workspaceId);
        };

        // グループページ
        if (groupId) {
            checkGroupMember(groupId);
            return;
        }

        // ワークスペースページ
        if (workspaceId) {
            checkWorkspaceMember(workspaceId);
            return;
        }

        // ビューモデルページ
        if (viewModelId) {
            checkWorkspaceMemberWithViewModelId(viewModelId);
            return;
        }

        setIsSidebarAvailable(false);
    }, [currentUserId, groupId, workspaceId, viewModelId]);

    return isSidebarAvailable;
};

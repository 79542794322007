import { memo, useCallback, useState } from 'react';
import { Rect } from '@view-model/models/common/basic';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { HeaderMenuRight } from './HeaderMenuRight';
import { HeaderMenuLeft } from './HeaderMenuLeft';
import { ZoomButtons } from './ZoomButtons';
import { CommentList } from './CommentList';
import { CurrentMenu } from './HeaderMenuRight/HeaderMenuRight';
import { NotificationList } from '@user/notification';
import { WorkspaceEntity } from '@workspace/domain/workspace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useBannerAnnouncement } from '@notification/announcement';

// NOTE: この型を変更した場合には、メモ化のために propsAreEqual関数も変更する必要あり
type Props = {
    setClientViewBox: (viewBox: Rect) => void;
    onZoomIn: () => void;
    onZoomOut: () => void;
    onZoomReset: () => void;
    workspace: WorkspaceEntity;
    viewModel: ViewModelEntity;
    viewModelPermissions: { isEditable: boolean; isContentEditable: boolean; isDeletable: boolean };
    onGenerateLink(): void;
};

const propsAreEqual = (prev: Props, next: Props) => {
    return (
        prev.setClientViewBox === next.setClientViewBox &&
        prev.onZoomIn === next.onZoomIn &&
        prev.onZoomOut === next.onZoomOut &&
        prev.onZoomReset === next.onZoomReset &&
        prev.workspace.isEqual(next.workspace) &&
        prev.viewModel === next.viewModel &&
        prev.viewModelPermissions === next.viewModelPermissions &&
        prev.onGenerateLink === next.onGenerateLink
    );
};

const FloatingUIContainerFn: React.FC<Props> = ({
    setClientViewBox,
    onZoomIn,
    onZoomOut,
    onZoomReset,
    workspace,
    viewModel,
    viewModelPermissions,
    onGenerateLink,
}: Props) => {
    const [currentMenu, setCurrentMenu] = useState<CurrentMenu>('none');
    const onNotificationListClose = useCallback(() => setCurrentMenu('none'), []);

    const [bannerAnnouncement, setMarkAsReadBannerAnnouncement] = useBannerAnnouncement();

    return (
        <div className="pointer-events-none absolute left-0 top-0 flex size-full flex-col gap-4 py-4 pl-4 pr-8">
            {/* 上部のメニュー要素を配置する flex コンテナ。 */}
            <div className="flex flex-row justify-between gap-4">
                <HeaderMenuLeft
                    viewModel={viewModel}
                    workspace={workspace}
                    viewModelPermissions={viewModelPermissions}
                    onGenerateLink={onGenerateLink}
                />

                <HeaderMenuRight
                    setClientViewBox={setClientViewBox}
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                />
            </div>

            {currentMenu === 'none' && bannerAnnouncement && (
                <div className="mb-auto ml-auto flex justify-end">
                    <div className="pointer-events-auto flex rounded-lg bg-red-400 px-4 py-2">
                        <div>
                            <a
                                href={bannerAnnouncement.url}
                                target="_blank"
                                rel="noreferrer"
                                className="hover:underline"
                            >
                                {bannerAnnouncement.title}
                            </a>
                        </div>
                        <div className="ml-2 cursor-pointer" onClick={setMarkAsReadBannerAnnouncement}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                </div>
            )}

            {/* 左上のメニューが長い時にコメントリストの上部までメニューが伸ばせるように、上下でflexコンテナを分割している。 */}
            {currentMenu !== 'none' && (
                <div className="mb-auto ml-auto flex justify-end overflow-y-hidden rounded-lg shadow-xl">
                    {currentMenu === 'comments' && (
                        <div className="max-h-full">
                            <CommentList viewModelId={viewModel.id} />
                        </div>
                    )}

                    {currentMenu === 'notifications' && (
                        <div className="max-h-full">
                            <NotificationList onClose={onNotificationListClose} />
                        </div>
                    )}
                </div>
            )}

            <div className="mt-auto flex justify-end">
                <ZoomButtons onZoomIn={onZoomIn} onZoomOut={onZoomOut} onZoomReset={onZoomReset} />
            </div>
        </div>
    );
};

export const FloatingUIContainer = memo(FloatingUIContainerFn, propsAreEqual);

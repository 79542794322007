import { WorkspaceName } from '@workspace/domain/workspace/vo/WorkspaceName';
import { ViewModelName } from '@view-model/domain/view-model';

export class PageTitleManager {
    static readonly APP_NAME = 'Balus';

    setPageTitle(pageTitle: string): void {
        document.title = pageTitle;
    }

    setWorkspacePageTitle(workspaceName: WorkspaceName): void {
        const title = `${workspaceName.value} | ${PageTitleManager.APP_NAME}`;
        this.setPageTitle(title);
    }

    setViewModelPageTitle(viewModelName: ViewModelName): void {
        const title = `${viewModelName.value} | ${PageTitleManager.APP_NAME}`;
        this.setPageTitle(title);
    }

    setDefaultPageTitle(): void {
        this.setPageTitle(PageTitleManager.APP_NAME);
    }
}

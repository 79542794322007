import { useCallback, useContext } from 'react';
import { ViewModelId } from '@schema-common/base';
import { BookmarkedViewModelIdsContext } from '@group/contexts';
import { toast } from 'react-hot-toast';

export const useBookmark = (
    viewModelId: ViewModelId
): {
    isBookmarked: boolean;
    toggleBookmark: () => Promise<void>;
} => {
    const { addBookmark, removeBookmark, bookmarkedViewModelIds } = useContext(BookmarkedViewModelIdsContext);

    const isBookmarked = bookmarkedViewModelIds.has(viewModelId);

    return {
        isBookmarked,
        toggleBookmark: useCallback(async () => {
            if (isBookmarked) {
                await removeBookmark(viewModelId);
                toast('ブックマークを削除しました');
            } else {
                await addBookmark(viewModelId);
                toast('ブックマークしました');
            }
        }, [isBookmarked, viewModelId, addBookmark, removeBookmark]),
    };
};

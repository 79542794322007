import { BezierLine } from '@model-framework/link';
import { LinkMultiplicityView } from './LinkMultiplicityView';
import { LinkMultiplicity, Multiplicity } from '../../domain';

type Props = {
    bezier: BezierLine;
    editable: boolean;
    isLinkSelected: boolean;
    multiplicity: LinkMultiplicity;
    isSourceMultiplicityMenuShown: boolean;
    onUpdateSourceMultiplicity: (multiplicity: Multiplicity) => void;
    onUpdateTargetMultiplicity: (multiplicity: Multiplicity) => void;
    onToggleSourceMultiplicityMenu: (visible: boolean) => void;
    onToggleTargetMultiplicityMenu: (visible: boolean) => void;
};

export const LinkMultiplicityPair: React.FC<Props> = ({
    bezier,
    editable,
    isLinkSelected,
    multiplicity,
    isSourceMultiplicityMenuShown,
    onUpdateSourceMultiplicity,
    onUpdateTargetMultiplicity,
    onToggleSourceMultiplicityMenu,
    onToggleTargetMultiplicityMenu,
}: Props) => {
    const sourceMultiplicityView = (
        <LinkMultiplicityView
            key={'source'}
            bezierPoint={bezier.getPoint(0.15)}
            editable={editable}
            multiplicity={multiplicity.source}
            isLinkSelected={isLinkSelected}
            onUpdateMultiplicity={onUpdateSourceMultiplicity}
            onToggleMenu={onToggleSourceMultiplicityMenu}
        />
    );

    const targetMultiplicityView = (
        <LinkMultiplicityView
            key={'target'}
            bezierPoint={bezier.getPoint(0.85)}
            editable={editable}
            multiplicity={multiplicity.target}
            isLinkSelected={isLinkSelected}
            onUpdateMultiplicity={onUpdateTargetMultiplicity}
            onToggleMenu={onToggleTargetMultiplicityMenu}
        />
    );

    return isSourceMultiplicityMenuShown ? (
        <>
            {targetMultiplicityView}
            {sourceMultiplicityView}
        </>
    ) : (
        <>
            {sourceMultiplicityView}
            {targetMultiplicityView}
        </>
    );
};

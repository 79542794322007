import { Line } from '../Line';
import { Rect } from '../Rect';
import { Direction } from '../Direction';
import { Point } from '@view-model/models/common/basic';

/**
 * 矩形をその対角線で切ったときの平面を表すオブジェクト。
 * 矩形と点の位置関係検出に利用する。
 */
export class DiagonallyDividedArea {
    private constructor(
        private readonly topLeftToBottomRight: Line,
        private readonly bottomLeftToTopRight: Line
    ) {}

    static fromRect(rect: Rect): DiagonallyDividedArea {
        return new DiagonallyDividedArea(
            new Line(rect.topLeft(), rect.bottomRight()),
            new Line(rect.bottomLeft(), rect.topRight())
        );
    }

    directionOf(point: Point): Direction {
        // 1. 左下から右上への対角線
        const direction1 = point.isAbove(this.bottomLeftToTopRight)
            ? Direction.Up | Direction.Left
            : Direction.Down | Direction.Right;

        // 2. 左上から右下への対角線
        const direction2 = point.isAbove(this.topLeftToBottomRight)
            ? Direction.Up | Direction.Right
            : Direction.Down | Direction.Left;

        // 4方向いずれか1方向に決まる
        return direction1 & direction2;
    }
}

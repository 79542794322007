import { useEffect, useState } from 'react';
import { ClientMouseCursorSet, ClientMouseCursor } from '@view-model/domain/mouse-cursor';
import { ClientMouseCursor as Cursor } from '@view-model/ui/components/ViewModel';
import { ClientMouseCursorPubSub } from '@view-model/application/mouse-cursor/ClientMouseCursorPubSub';

type Props = {
    pubSub: ClientMouseCursorPubSub;
};

export const ClientMouseCursorView = ({ pubSub }: Props): React.ReactElement => {
    const [mouseCursors, setMouseCursors] = useState<ClientMouseCursorSet>(ClientMouseCursorSet.empty());
    const isHidden = useVisibilityState() === 'hidden';

    useEffect(() => {
        if (isHidden) {
            // 画面が非表示の場合はlistenしない
            return;
        }

        pubSub.subscribe((cursors) => setMouseCursors(cursors)).then();

        return () => pubSub.unsubscribe();
    }, [pubSub, isHidden]);

    return (
        <g>
            {/* <Cursor x={800} y={-130} userImageURL="https://app.balus.me/image/noimage.png" /> */}
            {mouseCursors.map((cursor: ClientMouseCursor) => (
                <Cursor
                    key={cursor.id}
                    x={cursor.x}
                    y={cursor.y}
                    userName={cursor.userName}
                    userId={cursor.userKey.id}
                    userImageURL={cursor.userImageURL}
                />
            ))}
        </g>
    );
};

function useVisibilityState(): DocumentVisibilityState {
    const [state, setState] = useState<DocumentVisibilityState>(document.visibilityState);

    useEffect(() => {
        const onVisibilityChange = () => {
            setState(document.visibilityState);
        };
        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, []);

    return state;
}

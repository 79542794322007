import { useCallback } from 'react';
import { GroupMemberRole } from '@group/domain';

type Props = {
    defaultRole: GroupMemberRole;
    onChange(role: GroupMemberRole): void;
    disabled: boolean;
};

export const BasicRoleSelect: React.FC<Props> = ({ defaultRole, disabled, onChange }: Props) => {
    const handleChangeRole = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(GroupMemberRole.load(event.target.value));
        },
        [onChange]
    );

    return (
        <div>
            <select
                className="form-select m-1 py-1 text-sm"
                defaultValue={defaultRole.toString()}
                onChange={handleChangeRole}
                disabled={disabled}
            >
                {GroupMemberRole.values().map((role) => (
                    <option key={role} value={role}>
                        {GroupMemberRole.getLocalString(role)}
                    </option>
                ))}
            </select>
        </div>
    );
};

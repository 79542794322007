import { UserIcon } from '@framework/ui/atoms/UserIcon';

type Props = {
    name: string;
    userId: string;
    imageUrl: string | null;
};

export const NameLabel: React.FC<Props> = ({ name, userId, imageUrl }: Props) => {
    return (
        <div className="block px-4 py-2 text-gray-800">
            <span className="inline-block">
                <UserIcon name={name} id={userId} imageUrl={imageUrl} size="medium" />
            </span>
            <span className="inline-block h-6 w-40 truncate px-2 leading-6">{name}</span>
        </div>
    );
};

import { Key } from '@framework/domain';

export class GroupKey extends Key {
    protected get KINDS(): string[] {
        return ['Group'];
    }

    public static buildFromID(id: string): GroupKey {
        return new GroupKey(`Group:${id}`);
    }

    public get groupId(): string {
        return this.id as string;
    }

    dump(): string {
        return this.toString();
    }

    static load(key: string): GroupKey {
        return new GroupKey(key);
    }
}

import { GroupMemberRole } from './GroupMemberRole';
import { UserId } from '@schema-common/base';
import { GroupMemberRolesJSON } from '@schema-app/group/member-roles/GroupMemberRolesJSON';

type GroupMemberRoleRecord = Record<string, GroupMemberRole>;

export class GroupMemberRoles {
    private readonly memberRoles: Readonly<GroupMemberRoleRecord>;
    constructor(memberRoles: GroupMemberRoleRecord) {
        this.memberRoles = { ...memberRoles };
    }

    dump(): GroupMemberRolesJSON {
        return { ...this.memberRoles };
    }

    static load(dump: Record<string, string> | null): GroupMemberRoles {
        return this.fromEntries(Object.entries(dump || {}));
    }

    static fromEntries(entries: [string, string][]): GroupMemberRoles {
        const memberRoles: GroupMemberRoleRecord = {};
        for (const [userId, role] of entries) {
            memberRoles[userId] = GroupMemberRole.load(role);
        }
        return new this(memberRoles);
    }

    get length(): number {
        return Object.keys(this.memberRoles).length;
    }

    getRoleOf(userId: UserId): GroupMemberRole | undefined {
        return this.memberRoles[userId];
    }

    userIds(): string[] {
        return Object.keys(this.memberRoles);
    }
}

import { MapRepository, RTDBPath } from '@framework/repository';
import { ConsistencyLink } from './ConsistencyLink';
import { ConsistencyLinkCollection, ConsistencyLinkCollectionJSON } from './ConsistencyLinkCollection';
import { ConsistencyLinkJSON } from '@schema-app/view-model/contents/{viewModelId}/consistency-links/{consistencyLinkId}/ConsistencyLinkJSON';
import { ViewModelId } from '@schema-common/base';

export type ConsistencyLinkMapRepository = MapRepository<
    ConsistencyLinkJSON,
    ConsistencyLink,
    ConsistencyLinkCollectionJSON,
    ConsistencyLinkCollection
>;

export const createConsistencyLinkMapRepository = (viewModelId: ViewModelId): ConsistencyLinkMapRepository => {
    return new MapRepository(
        ConsistencyLink,
        ConsistencyLinkCollection,
        RTDBPath.ConsistencyLink.linksPath(viewModelId)
    );
};

import { useD3Drag } from '@view-model/models/common/hooks/useD3Drag';
import { useHandler } from '@framework/hooks';
import { DragContext } from '@model-framework/ui';

type RectResizerProps = {
    width: number;
    height: number;
    containerWidth: number;
    containerHeight: number;
    offsetX: number;
    offsetY: number;
    cursor: string;
    onDrag: (event: DragContext) => void;
    onDragStart: (event: DragContext) => void;
    onDragEnd: (event: DragContext) => void;
};

export const RectResizeHandle = (props: RectResizerProps) => {
    const { width, height, offsetX, offsetY, containerWidth, containerHeight, cursor } = props;

    const onDrag = useHandler((event: DragContext) => {
        props.onDrag(event);
    });
    const onDragStart = useHandler((event: DragContext) => {
        props.onDragStart(event);
    });
    const onDragEnd = useHandler((event: DragContext) => {
        props.onDragEnd(event);
    });

    const ref = useD3Drag<SVGRectElement>({
        onDrag,
        onDragStart,
        onDragEnd,
    });

    return (
        <>
            {/* 色付きの矩形(直線)領域 */}
            <rect
                className="fill-brand"
                width={width}
                height={height}
                transform={`translate(${offsetX - width / 2}, ${offsetY - height / 2})`}
            />
            {/* 透明の Dragger 領域を上にかぶせる */}
            <rect
                fill="transparent"
                ref={ref}
                width={containerWidth}
                height={containerHeight}
                transform={`translate(${offsetX - containerWidth / 2}, ${offsetY - containerHeight / 2})`}
                cursor={cursor}
            />
        </>
    );
};

type ResizeCircleViewProps = {
    r: number;
    offsetX: number;
    offsetY: number;
    cursor: string;
    onDrag: (event: DragContext) => void;
    onDragStart: (event: DragContext) => void;
    onDragEnd: (event: DragContext) => void;
};

// ゾーンの角に配置するリサイズ用の円
export const CircleResizeHandle = (props: ResizeCircleViewProps) => {
    const onDrag = useHandler((event: DragContext) => {
        props.onDrag(event);
    });
    const onDragStart = useHandler((event: DragContext) => {
        props.onDragStart(event);
    });
    const onDragEnd = useHandler((event: DragContext) => {
        props.onDragEnd(event);
    });

    const ref = useD3Drag<SVGCircleElement>({ onDrag, onDragStart, onDragEnd });

    return (
        <circle
            ref={ref}
            className="fill-brand"
            r={props.r}
            transform={`translate(${props.offsetX}, ${props.offsetY})`}
            cursor={props.cursor}
        />
    );
};

import { MLAPIConfigType } from './type';

export const MLAPIConfig: MLAPIConfigType = {
    outlier: 'https://balus-ml-outliers-nfkq32i7ma-an.a.run.app/',
    cluster: 'https://balus-ml-clusters-nfkq32i7ma-an.a.run.app/',
    chatgpt_cluster: 'https://balus-ml-chatgpt-clusters-nfkq32i7ma-an.a.run.app/',
    chatgpt_outlier: 'https://balus-ml-chatgpt-outliers-nfkq32i7ma-an.a.run.app/',
    chatgpt_question: 'https://balus-ml-chatgpt-questions-nfkq32i7ma-an.a.run.app/',
    chatgpt_summary: 'https://balus-ml-chatgpt-summary-nfkq32i7ma-an.a.run.app/',
    chatgpt_modeling: 'https://balus-ml-chatgpt-modeling-nfkq32i7ma-an.a.run.app/',
    chatgpt_similar_node: 'https://balus-ml-chatgpt-similar-node-nfkq32i7ma-an.a.run.app/?key=',
    chatgpt_zone_title: 'https://balus-ml-chatgpt-zone-title-nfkq32i7ma-an.a.run.app/?key=',
};

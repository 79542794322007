import { useCallback, useEffect, useState } from 'react';

export const useWindowActive = (): boolean => {
    const [active, setActive] = useState<boolean>(true);

    const handleFocus = useCallback(() => setActive(true), [setActive]);
    const handleBlur = useCallback(() => setActive(false), [setActive]);

    useEffect(() => {
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, [handleBlur, handleFocus]);

    return active;
};

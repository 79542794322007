import { ModelCommentThreadId } from '@schema-common/base';
import { Position, Size } from '@view-model/models/common/types/ui';
import { Rect } from '@view-model/models/common/basic/Rect';

export class ModelCommentThreadRegion {
    constructor(
        public readonly id: ModelCommentThreadId,
        public readonly position: Readonly<Position>,
        public readonly size: Readonly<Size>
    ) {}

    getRect(): Rect {
        return new Rect(this.position, this.size);
    }

    /**
     * 引数で渡された2点で作られる矩形領域に、このIconRegionが触れているか(一部でも重なっているか)を判定する
     *
     * @param rect {Rect}
     * @returns{boolean} 引数で作られる領域とIconRegionが一部でも重なっているときに true を返す
     */
    overlapped(rect: Rect): boolean {
        return this.getRect().intersects(rect);
    }
}

import { useState, useEffect, useRef } from 'react';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { Markdown } from '@framework/ui/elements/Markdown';
import { WorkspaceEditModal } from '../WorkspaceEditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

type Props = {
    workspace: Workspace;
    isEditable: boolean;
};

export const Description: React.FC<Props> = ({ workspace, isEditable }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [showReadMore, setShowReadMore] = useState<boolean>(false);
    const mdRef = useRef<HTMLDivElement>(null);

    const handleIconClick = () => {
        setEditModalOpen(true);
    };

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (!mdRef.current) return;
        const { clientHeight, scrollHeight } = mdRef.current;
        setShowReadMore(clientHeight < scrollHeight);
    }, [workspace.description.value]);

    return (
        <div className="flex w-full flex-col">
            <div className="mb-1 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="text-2xl font-bold">説明</div>
                {isEditable && (
                    <div className="mx-2 block size-4 cursor-pointer" onClick={handleIconClick}>
                        <FontAwesomeIcon icon={faCog} className="text-lg" />
                    </div>
                )}
            </div>
            {workspace.description.isEmpty() ? (
                <div className="rounded border border-gray-400 bg-gray-100 px-2 py-4 text-center">説明は未設定です</div>
            ) : (
                <>
                    {isOpen ? (
                        <div>
                            <div
                                ref={mdRef}
                                className="overflow-hidden rounded border border-gray-400 bg-gray-100 p-2"
                                style={{ maxHeight: '12rem' /* max-h-48 相当 */ }}
                            >
                                <Markdown source={workspace.description.value} className="markdown-body" />
                            </div>
                            {showReadMore && (
                                <div className="cursor-pointer pt-1 text-center text-brand" onClick={handleButtonClick}>
                                    もっと表示する
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <Markdown
                                source={workspace.description.value}
                                className="markdown-body rounded border border-gray-400 bg-gray-100 p-2"
                            />
                            <div className="cursor-pointer pt-1 text-center text-brand" onClick={handleButtonClick}>
                                少なく表示する
                            </div>
                        </div>
                    )}
                </>
            )}

            {editModalOpen && (
                <WorkspaceEditModal
                    isOpen={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    workspace={workspace}
                />
            )}
        </div>
    );
};

import { useEffect, useState } from 'react';
import { DataSnapshot, RefBuilder, RTDBPath } from '@framework/repository';

// Realtime Database との接続状況を監視して、オンライン状態か否かを返す
export const useOnlineStatus = (): boolean => {
    const [online, setOnline] = useState<boolean>(true);

    useEffect(() => {
        const ref = RefBuilder.ref(RTDBPath.Firebase.connectedPath());
        const callback = (snap: DataSnapshot) => {
            setOnline(snap.val() === true);
        };

        ref.on('value', callback);
        return () => ref.off('value', callback);
    }, []);

    return online;
};

import { RefObject, useEffect } from 'react';
import { select } from 'd3-selection';

/**
 * d3 で クリックのコールバックを利用するためのフック
 * @param ref クリックイベントを受け取りたいHTML/SVG要素のref
 * @param onClick イベントハンドラ
 * @param stopPropagation イベント伝搬を停止するか否かを決めるbool値
 */
export const useD3ClickCallback = <T extends HTMLElement | SVGElement>(
    ref: RefObject<T>,
    onClick: (event: MouseEvent) => void,
    stopPropagation = false
): void => {
    useEffect(() => {
        const dom = ref.current;
        select(dom).on('click', (event: MouseEvent) => {
            if (stopPropagation) {
                event.stopPropagation();
            }
            onClick(event);
        });

        return () => {
            select(dom).on('click', null);
        };
    }, [ref, onClick, stopPropagation]);
};

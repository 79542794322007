import copy from 'copy-to-clipboard';
import { PositionSetCreateCommand } from '@view-model/models/common/PositionSet';
import { ModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ModelCascadeRepository';
import { ModelCreateCommand } from '@view-model/command/model/ModelCreateCommand';
import { ConsistencyLink } from '@view-model/domain/ConsistencyLink';
import { CommandManager, CompositeCommand, CreateCommand } from '@model-framework/command';
import { ViewModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ViewModelCascadeRepository';
import { ViewModelClipboardPayload } from '@view-model/application/operation';
import { ObjectRepository, RTDBPath } from '@framework/repository';
import { ViewEntity } from '@view-model/domain/view';
import { ViewModelAssetCollectionForClone, ViewModelContent } from '@view-model/domain/view-model';
import { GroupId, UserId, ViewId, ViewModelId, WorkspaceId } from '@schema-common/base';

export class ViewModelClipboardOperator {
    public static async copy(workspaceId: WorkspaceId, viewModelId: ViewModelId): Promise<boolean> {
        const repository = new ViewModelCascadeRepository(viewModelId);

        const content = await repository.loadContent().catch((e: unknown) => console.error(e));
        if (!content) return false;

        const payload = ViewModelClipboardPayload.fromContent(content, workspaceId, viewModelId);
        return copy(JSON.stringify(payload), { format: 'text/plain' });
    }

    private static async parsePayloadForPaste(
        groupId: GroupId,
        viewModelId: ViewModelId,
        currentUserId: UserId,
        payload: ViewModelClipboardPayload
    ): Promise<{ viewModelContent: ViewModelContent; errorMessage: string | null }> {
        // ペイロードから復元
        const originalContent = ViewModelClipboardPayload.toContent(payload);

        // 復元したペイロード中に含まれる ViewModelAssets の情報を取得して複製する
        const assets = await ViewModelAssetCollectionForClone.fetchByUrlList(originalContent.assetUrls());
        const baseUrl = location.href;
        const [assetUrlMap, errorMessage] = await assets.cloneNew(groupId, viewModelId, currentUserId, baseUrl);

        // 識別子を置き換えながら複製
        const viewModelContent = originalContent.cloneNew(assetUrlMap);
        // 同じビューモデル内でのコピペであれば、表示位置を調整する
        if (payload.sourceViewModelId === viewModelId) {
            viewModelContent.moveViewPositions(600, 300);
        }
        viewModelContent.correctViewOrders(0);

        return { viewModelContent, errorMessage };
    }

    /**
     * コマンド履歴に登録する形でビューモデルの貼り付け操作を行う。
     * @param groupId 貼り付け対象のビューモデルが属するグループID
     * @param viewModelKey 貼り付け対象のビューモデル・キー
     * @param currentUserId 貼り付け操作を行なっているユーザID
     * @param payload ビューモデル全体を表現するクリップボードペイロード
     * @param commandManager コマンドマネージャ
     * @returns 作成したビュー・キーの一覧(配列)と、エラーがある場合にはエラー文言
     */
    public static async pasteWithCommand(
        groupId: GroupId,
        viewModelId: ViewModelId,
        currentUserId: UserId,
        payload: ViewModelClipboardPayload,
        commandManager: CommandManager
    ): Promise<[Set<ViewId>, string | null]> {
        const { viewModelContent, errorMessage } = await this.parsePayloadForPaste(
            groupId,
            viewModelId,
            currentUserId,
            payload
        );

        const viewCreateCommands = viewModelContent.getViews().map((view) => {
            const repository = new ObjectRepository(ViewEntity, RTDBPath.View.viewPath(viewModelId, view.id));
            return new CreateCommand(view, repository);
        });
        const viewPositionCreateCommand = new PositionSetCreateCommand(
            viewModelContent.viewPositions,
            RTDBPath.View.positionsPath(viewModelId)
        );

        const modelCascadingRepository = new ModelCascadeRepository(viewModelId);
        const modelCreateCommands = viewModelContent
            .getModels()
            .list()
            .map((modelContents) => {
                return new ModelCreateCommand(modelCascadingRepository, modelContents);
            });

        const consistencyLinkCommands = viewModelContent.consistencyLinks.map((consistencyLink) => {
            const repository = new ObjectRepository(
                ConsistencyLink,
                RTDBPath.ConsistencyLink.linkPath(viewModelId, consistencyLink.id)
            );
            return new CreateCommand(consistencyLink, repository);
        });

        const command = new CompositeCommand(
            ...viewCreateCommands,
            viewPositionCreateCommand,
            ...modelCreateCommands,
            ...consistencyLinkCommands
        );
        commandManager.execute(command);

        return [new Set(viewModelContent.getViews().map((view) => view.id)), errorMessage];
    }

    /**
     * コマンド履歴に登録しない形でビューモデル全体のクリップボードペイロードを貼り付けする。
     * @param groupId 貼り付け対象のビューモデルが属するグループID
     * @param viewModelKey 貼り付け対象のビューモデル・キー
     * @param currentUserId 貼り付け操作を行なっているユーザID
     * @param payload ビューモデル全体を表現するクリップボードペイロード
     * @returns エラーのある場合にはエラー文言を返す
     */
    public static async pasteWithoutCommand(
        groupId: GroupId,
        viewModelId: ViewModelId,
        currentUserId: UserId,
        payload: ViewModelClipboardPayload
    ): Promise<string | null> {
        const { viewModelContent, errorMessage } = await this.parsePayloadForPaste(
            groupId,
            viewModelId,
            currentUserId,
            payload
        );
        const viewModelCascadeRepository = new ViewModelCascadeRepository(viewModelId);

        // 保存（具現化）
        await viewModelCascadeRepository.saveContent(viewModelContent);

        return errorMessage;
    }
}

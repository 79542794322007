import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { faCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@framework/ui/atoms/Button';
import { Input } from '@framework/ui/atoms/Input';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { ImageCropModal } from '@framework/ui/elements/ImageCropModal';
import { generateDefaultIcon } from '@framework/utils';
import { PublicIconUploader } from '@framework/utils/PublicIconUploader';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { Workspace } from '@workspace/domain/workspace/Workspace';

import TextareaAutosize from 'react-textarea-autosize';
type Props = {
    isOpen: boolean;
    onClose(): void;
    workspace: Workspace;
};

export const WorkspaceEditModal: React.FC<Props> = ({ isOpen, onClose, workspace }: Props) => {
    const [name, setName] = useState<string>(workspace.name.toString());
    const [description, setDescription] = useState<string>(workspace.description.toString());
    const [canSave, setCanSave] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);
    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [iconImage, setIconImage] = useState<File | null>(null);
    const [iconUrl, setIconUrl] = useState<string | null>(workspace.imageUrl);

    const handleUploadImage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) setUploadedImage(event.target.files[0]);
    }, []);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const newIconUrl = iconImage ? await PublicIconUploader.uploadWorkspaceIcon(workspace.id, iconImage) : iconUrl;
        setIconUrl(newIconUrl);
        setErrorMessage('');

        if (await WorkspaceOperation.update(workspace.id, name, description, newIconUrl)) {
            onClose();
        } else {
            setErrorMessage('ワークスペースの更新に失敗しました');
            setIsSubmitting(false);
        }
    };

    const handleCropModalClose = useCallback(() => {
        (document.getElementById('icon-image') as HTMLInputElement).value = '';
        setUploadedImage(null);
        setIsCropModalOpen(false);
    }, []);

    const handleCropModalSubmit = useCallback((image: File) => {
        setIconImage(image);
        setIconUrl(URL.createObjectURL(image));
        setIsCropModalOpen(false);
    }, []);

    const handleImageClear = useCallback(() => {
        setIconImage(null);
        setIconUrl(null);
    }, []);

    useEffect(() => {
        setCanSave(name !== '');
    }, [name, description]);

    useEffect(() => {
        if (uploadedImage) setIsCropModalOpen(true);
    }, [uploadedImage]);

    return (
        <>
            <GeneralModal
                isOpen={isOpen}
                onClose={onClose}
                title={'ワークスペースを編集'}
                submitButton={
                    <Button color="primary" onClick={handleSubmit} disabled={!canSave} loading={isSubmitting}>
                        保存
                    </Button>
                }
                cancelButton={
                    <Button color={'secondary'} onClick={onClose} disabled={isSubmitting}>
                        キャンセル
                    </Button>
                }
                // オーバーレイ部分をクリックして閉じると、書き換えの説明が消えて悲しいことになりかねないので、モーダルを閉じないようにする
                shouldCloseOnOverlayClick={false}
            >
                <div className="overflow-y-auto p-4" style={{ width: '600px', maxHeight: '70vh' }}>
                    <div className="flex justify-between">
                        {iconUrl ? (
                            <div className="relative size-16">
                                <div className="absolute size-16 overflow-hidden rounded-full">
                                    <img className="size-full" src={iconUrl} alt={name} />
                                </div>
                                <div className="absolute flex size-16 items-start justify-end">
                                    <div
                                        className="relative flex cursor-pointer items-center justify-center"
                                        onClick={handleImageClear}
                                    >
                                        <FontAwesomeIcon icon={faCircle} className="absolute text-2xl text-red-500" />
                                        <FontAwesomeIcon icon={faTimes} className="absolute text-xl text-white" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="relative size-16 overflow-hidden rounded-full">
                                <div className="absolute size-full opacity-50">
                                    {generateDefaultIcon({
                                        id: workspace.id,
                                        name: workspace.name.toString(),
                                        size: 64,
                                    })}
                                </div>
                                <div className="absolute flex size-full items-center justify-center">
                                    <FontAwesomeIcon icon={faPlus} className="text-2xl text-blue-500" />
                                </div>
                                <input
                                    type="file"
                                    id="icon-image"
                                    className="absolute size-full cursor-pointer opacity-0"
                                    accept="image/png, image/jpeg"
                                    onChange={handleUploadImage}
                                />
                            </div>
                        )}
                        <div className="w-5/6">
                            <div className="text-lg font-bold">ワークスペース名</div>
                            <Input
                                type="text"
                                placeholder="ワークスペース名"
                                value={name}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                                disabled={isSubmitting}
                                autoFocus={true}
                            />
                        </div>
                    </div>

                    <div className="py-2">
                        <div className="text-lg font-bold">説明</div>
                        <TextareaAutosize
                            className="w-full rounded border border-gray-400 bg-gray-100 p-1"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            minRows={5}
                            maxRows={20}
                            disabled={isSubmitting}
                        />
                    </div>
                    {errorMessage && <div className="py-2 text-red-500">{errorMessage}</div>}
                </div>
            </GeneralModal>
            {uploadedImage && (
                <ImageCropModal
                    isOpen={isCropModalOpen}
                    onClose={handleCropModalClose}
                    onSubmit={handleCropModalSubmit}
                    src={uploadedImage}
                />
            )}
        </>
    );
};

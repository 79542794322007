import { LinkEntity } from '../LinkEntity';
import { LinkPlacement } from '../LinkPlacement';
import { LinkConstants } from '@model-framework/link';
import { INodeShape, ShapeMap } from '@model-framework/shape';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { Point } from '@view-model/models/common/basic';
import { LinkableTargetKey } from '@view-model/domain/key';

export class LinkPlacementFactory {
    constructor(
        private readonly nodePositions: PositionSet,
        private readonly zonePositions: PositionSet,
        private readonly shapeMap: ShapeMap<INodeShape>
    ) {}

    linkPlacementOf(link: LinkEntity): LinkPlacement | null {
        const { shapeMap } = this;

        const fromPosition = this.getPosition(link.from);
        const toPosition = this.getPosition(link.to);
        if (!fromPosition || !toPosition) return null;

        const fromShape = shapeMap.get(link.fromId);
        const toShape = shapeMap.get(link.toId);
        if (!fromShape || !toShape) return null;

        return new LinkPlacement(fromPosition, toPosition, fromShape, toShape, LinkConstants.ConnectionPointMargin);
    }

    private getPosition = (key: LinkableTargetKey): Point | undefined => {
        const position = this.nodePositions.find(key.id.toString());
        if (position) {
            return position;
        }
        return this.zonePositions.find(key.id.toString());
    };
}

import { Input } from '@framework/ui/atoms/Input';
import { ViewModelSharingUserRoleType } from '@view-model/domain/view-model';
import { ShareDisabledMessage } from './ShareDisabledMessage';
import { ShareSetting } from './ShareSetting';
import { LockSetting } from './LockSetting';

type Props = {
    viewModelURLShareable: boolean;
    isPublicWorkspace: boolean;
    name: string;
    onChangeName(name: string): void;
    sharingUserRole: ViewModelSharingUserRoleType;
    onChangeSharingUserRole(role: ViewModelSharingUserRoleType): void;
    isLocked: boolean;
    onChangeIsLocked(isLocked: boolean): void;
    disabled: boolean;
};

export const SettingForm: React.FC<Props> = ({
    viewModelURLShareable,
    isPublicWorkspace,
    name,
    onChangeName,
    sharingUserRole,
    onChangeSharingUserRole,
    isLocked,
    onChangeIsLocked,
    disabled,
}: Props) => {
    return (
        <>
            <div className="py-2">
                <div className="pb-2 font-bold">名前 | Name</div>
                <Input
                    type="text"
                    placeholder="ビューモデル名"
                    value={name}
                    onChange={(e) => onChangeName(e.target.value)}
                    disabled={disabled}
                    autoFocus={true}
                />
            </div>
            <div className="pb-2 pt-6">
                <div className="pb-2 font-bold">編集のロック | Lock Setting</div>
                <LockSetting isLocked={isLocked} onChange={onChangeIsLocked} disabled={disabled} />
            </div>
            <div className="pb-2 pt-6">
                <div className="pb-2 font-bold">公開設定 | Public settings</div>
                {viewModelURLShareable ? (
                    <ShareSetting
                        isPublicWorkspace={isPublicWorkspace}
                        sharingUserRole={sharingUserRole}
                        onChange={onChangeSharingUserRole}
                        disabled={disabled}
                    />
                ) : (
                    <ShareDisabledMessage />
                )}
            </div>
        </>
    );
};

import { INodeShape } from '@model-framework/shape';
import Victor from 'victor';
import { LinkBend } from './vo/LinkBend';
import { BezierQuadraticFactory } from './BezierQuadraticFactory';
import { BezierLine } from '@model-framework/link';
import { Point, Rect } from '@view-model/models/common/basic';

type Position = {
    x: number;
    y: number;
};

export class LinkPlacement {
    public readonly fromPosition: Point;
    public readonly toPosition: Point;
    public readonly fromShape: INodeShape;
    public readonly toShape: INodeShape;
    public readonly shapeSizeOffset: number;

    /**
     * コンストラクタ
     * @param fromPosition 接続元ノードの座標（中心）
     * @param toPosition 接続先ノードの座標（中心）
     * @param fromShape 接続元ノードの形状オブジェクト
     * @param toShape 接続先ノードの形状オブジェクト
     * @param shapeSizeOffset 接続位置計算時の形状に対するオフセット
     */
    constructor(
        fromPosition: Point,
        toPosition: Point,
        fromShape: INodeShape,
        toShape: INodeShape,
        shapeSizeOffset: number
    ) {
        this.fromPosition = fromPosition;
        this.toPosition = toPosition;
        this.fromShape = fromShape;
        this.toShape = toShape;
        this.shapeSizeOffset = shapeSizeOffset;
    }

    private fromCenterPosition(): Victor {
        return Victor.fromObject(this.fromPosition).add(this.fromShape.getCenterPoint());
    }

    private toCenterPosition(): Victor {
        return Victor.fromObject(this.toPosition).add(this.toShape.getCenterPoint());
    }

    /**
     * リンクの開始位置、終了位置を返す。
     */
    private startEndPoints(): [Victor, Victor] {
        const fromPos = this.fromCenterPosition();
        const toPos = this.toCenterPosition();
        const vecFromTo = toPos.clone().subtract(fromPos);
        const vecToFrom = vecFromTo.clone().invert();

        const offset = this.shapeSizeOffset;
        const startingPoint = this.fromShape.getBorderPoint(vecFromTo, offset).add(fromPos);
        const endingPoint = this.toShape.getBorderPoint(vecToFrom, offset).add(toPos);

        return [startingPoint, endingPoint];
    }

    /**
     * リンクのベンディングコントロールの位置を返す。
     * @param bend ベンド
     */
    bendingPoint(bend: LinkBend): Victor {
        const [startingPoint, endingPoint] = this.startEndPoints();

        return bend.bendingPoint(startingPoint, endingPoint);
    }

    /**
     * ベンドを計算して返す。
     *
     * @param bendingPoint ベンディングコントロールの位置
     */
    bend(bendingPoint: Position): LinkBend {
        const [startingPoint, endingPoint] = this.startEndPoints();

        return LinkBend.fromPoints(startingPoint, endingPoint, Victor.fromObject(bendingPoint));
    }

    private fromRect(): Rect {
        const { fromPosition } = this;
        return this.fromShape.getRect().movePosition(fromPosition.x, fromPosition.y);
    }

    private toRect(): Rect {
        const { toPosition } = this;
        return this.toShape.getRect().movePosition(toPosition.x, toPosition.y);
    }

    /**
     * BezierLineを計算して返す。
     *
     * @param bendingPoint ベンディングコントロールの位置
     */
    bezierLine(bendingPoint: Position): BezierLine {
        return BezierQuadraticFactory.buildBezierLine(
            this.fromRect(),
            this.toRect(),
            Victor.fromObject(bendingPoint),
            this.shapeSizeOffset
        );
    }

    isEqual(other: LinkPlacement): boolean {
        return (
            other instanceof LinkPlacement &&
            this.fromPosition.isEqual(other.fromPosition) &&
            this.toPosition.isEqual(other.toPosition) &&
            this.fromShape.isEqual(other.fromShape) &&
            this.toShape.isEqual(other.toShape) &&
            this.shapeSizeOffset === other.shapeSizeOffset
        );
    }
}

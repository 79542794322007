import { ViewJSON } from '@schema-app/view-model/contents/{viewModelId}/views/{viewId}/ViewJSON';

export class ViewSetting {
    public readonly showNodeCreatedUser: boolean;
    public readonly isLocked: boolean;

    public constructor(showNodeCreatedUser: boolean, isLocked: boolean) {
        this.showNodeCreatedUser = showNodeCreatedUser;
        this.isLocked = isLocked;
    }

    static buildNew(): ViewSetting {
        return this.load({
            showNodeCreatedUser: false,
            isLocked: false,
        });
    }

    public dump(): ViewJSON['setting'] {
        return {
            showNodeCreatedUser: this.showNodeCreatedUser,
            isLocked: this.isLocked,
        };
    }

    public dumpWithUnlock(): ViewJSON['setting'] {
        return {
            showNodeCreatedUser: this.showNodeCreatedUser,
            isLocked: false,
        };
    }

    static load(dump: ViewJSON['setting']): ViewSetting {
        const { showNodeCreatedUser, isLocked } = dump;
        return new this(showNodeCreatedUser, isLocked);
    }

    public isEquals(other: ViewSetting): boolean {
        return (
            other instanceof ViewSetting &&
            other.showNodeCreatedUser == this.showNodeCreatedUser &&
            other.isLocked == this.isLocked
        );
    }
}

import { Rect } from '@view-model/models/common/basic';

type Props = {
    viewRect: Rect;
};

const STROKE_WIDTH = 16;

export const ReadonlyFrame: React.FC<Props> = ({ viewRect }: Props) => {
    // 選択矩形がビューにぴったりフィットするようにSTROKE_WIDTHの半分だけマージンをあける
    const { x, y, width, height } = viewRect.applyMarginKeepingCenter(STROKE_WIDTH * 0.5).getXYWH();
    return (
        <rect
            className="stroke-brand"
            x={x}
            y={y}
            width={width}
            height={height}
            strokeWidth={STROKE_WIDTH}
            fill="transparent"
            rx={64} // TODO: ModelCanvas Styled.RectContainerのrx,ryに依存しているため共通の定数定義してそれを使うようにする
            ry={64}
        />
    );
};

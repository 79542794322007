import { useCallback, useState } from 'react';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { InternalPublicSetting, ShareSettingEditModal } from './EditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

type Props = {
    workspace: Workspace;
    internalPublicSetting: InternalPublicSetting;
    isEditable: boolean;
};

export const ShareSetting: React.FC<Props> = ({ workspace, internalPublicSetting, isEditable }: Props) => {
    const [settingEditModalOpen, setSettingEditModalOpen] = useState<boolean>(false);
    const handleEditStart = useCallback(() => setSettingEditModalOpen(true), []);
    const handleEditEnd = useCallback(() => setSettingEditModalOpen(false), []);

    return (
        <div className="flex w-full flex-col">
            <div className="mb-1 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="text-2xl font-bold">公開設定</div>
                {isEditable && (
                    <div className="mx-2 block size-4 cursor-pointer" onClick={handleEditStart}>
                        <FontAwesomeIcon icon={faCog} className="text-lg" />
                    </div>
                )}
            </div>

            <div>
                {/* ワークスペースの状態 */}
                {workspace.setting.isPublicSpace ? (
                    <>
                        <div className="pt-1 font-bold">ワークスペースは外部公開されています</div>
                        <div className="pt-1 text-sm">
                            リンクを知っている人なら誰でもこの画面にアクセスできます。ワークスペースの操作は、権限を持つメンバーだけができます。
                        </div>
                    </>
                ) : internalPublicSetting ? (
                    <>
                        <div className="pt-1 font-bold">グループ内公開ワークスペースです</div>
                        <div className="pt-1 text-sm">この画面は、グループのメンバーなら誰でもアクセスできます。</div>
                    </>
                ) : (
                    <>
                        <div className="pt-1 font-bold">プライベートワークスペースです</div>
                        <div className="pt-1 text-sm">この画面は、ワークスペースのメンバーだけがアクセスできます。</div>
                    </>
                )}
            </div>

            <div className="pt-2">
                {workspace.setting.isPublicSpace ? (
                    <>
                        {/* 外部公開ワークスペースの場合 */}
                        <div className="pt-1 font-bold">ビューモデルは外部公開されています</div>
                        <div className="pt-1 text-sm">
                            ワークスペースを外部公開しているため、リンクを知っている人なら誰でもこのワークスペースのすべてのビューモデルにアクセスできます。ビューモデルの操作は、権限を持つメンバーだけができます。
                        </div>
                    </>
                ) : workspace.setting.isViewModelURLShareable ? (
                    <>
                        {/* 外部公開ワークスペースはOFFで、ビューモデルごとの外部公開が有効の場合 */}
                        <div className="pt-1 font-bold">ビューモデルごとに外部公開できます</div>
                        <div className="pt-1 text-sm">
                            地球アイコンが表示されているビューモデルは、外部公開中のビューモデルです。ビューモデルの操作は、公開設定によります。
                        </div>
                    </>
                ) : (
                    <>
                        <div className="pt-1 font-bold">ビューモデルは外部公開できません</div>
                        <div className="pt-1 text-sm">
                            このワークスペースのすべてのビューモデルは、ワークスペースのメンバーだけがアクセスできます。
                        </div>
                    </>
                )}
            </div>

            {settingEditModalOpen && (
                <ShareSettingEditModal
                    onClose={handleEditEnd}
                    workspace={workspace}
                    internalPublicSetting={internalPublicSetting}
                />
            )}
        </div>
    );
};

import { NodeReactionSubMenu } from './NodeReactionSubMenu';
import { MainMenuBox, MainMenuIcon, SubMenuListContainer } from '@model-framework/menu';
import { NodeReaction, ReactionType, reactionIcons, reactionTypeList } from '../../domain';
import { UserId } from '@schema-common/base';
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons';
import { UserPublicProfile, useCurrentUserPublicProfile } from '@user/PublicProfile';

type Props = {
    menuSize: number;
    isOpen: boolean;
    reaction: NodeReaction | null | undefined;
    onMenuClicked(): void;
    onReactionSelected(reactionName: ReactionType, userId: UserId, userName: string): void;
    text: string;
};

export const isReactionSelected = (
    reactionType: ReactionType,
    reaction: NodeReaction | null | undefined,
    profile: UserPublicProfile | null
) => {
    if (!reaction || !profile) return false;
    return reaction.isReactionExecuted(reactionType, profile.id);
};

export const NodeReactionMenu: React.FC<Props> = ({
    menuSize,
    isOpen,
    reaction,
    onMenuClicked,
    onReactionSelected,
    text,
}: Props) => {
    const profile = useCurrentUserPublicProfile();
    const handleReactionSelected = (reactionName: ReactionType) => () => {
        if (!profile) return;
        onReactionSelected(reactionName, profile.id, profile.name);
    };
    const rectSize = {
        width: menuSize * reactionTypeList.length,
        height: menuSize,
    };

    return (
        <g>
            <MainMenuBox isSelected={isOpen} menuSize={menuSize} onClick={onMenuClicked} text={text} />
            <MainMenuIcon icon={faFaceSmile} menuSize={menuSize} />
            {isOpen && (
                <SubMenuListContainer menuSize={menuSize} rectSize={rectSize}>
                    {reactionTypeList.map((reactionType, index) => {
                        return (
                            <g key={reactionType} transform={`translate(${menuSize * index}, 0)`}>
                                <NodeReactionSubMenu
                                    menuSize={menuSize}
                                    isSelected={isReactionSelected(reactionType, reaction, profile)}
                                    reaction={reactionIcons[reactionType]}
                                    onClick={handleReactionSelected(reactionType)}
                                />
                            </g>
                        );
                    })}
                </SubMenuListContainer>
            )}
        </g>
    );
};

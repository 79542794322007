import { useCallback, useState } from 'react';
import { ModelCommentEntity } from '../../domain';
import { usePopupRef } from '@framework/hooks';
import { ModelCommentThreadOperation } from '../../adapter';
import { CommentPopupMenuContainer } from './CommentPopupMenuContainer';
import { CommentMenuContainer } from './CommentMenuContainer';
import { CommentMenuButtonContainer } from './CommentMenuButtonContainer';
import { Point, Size } from '@view-model/models/common/basic';
import { CommentMenuPositionMap } from '../../positions';
import { ModelCommentId } from '@schema-common/base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPen } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useRequiredCurrentUserId } from '@framework/auth';

type Props = {
    position: Point;
    comment: ModelCommentEntity;
    threadOperation: ModelCommentThreadOperation;
    onEditStart(id: ModelCommentId): void;
};

export const ReplyCommentMenu: React.FC<Props> = ({ comment, position, threadOperation, onEditStart }: Props) => {
    const currentUserId = useRequiredCurrentUserId();
    const isCommentEditable = comment.isAuthorUser(currentUserId);
    const { isOpen, popupRef, handleClose, handleOpen } = usePopupRef<HTMLDivElement>(false);
    const [commentMenuSize, setCommentMenuSize] = useState(new Size(0, 0));
    const [commentPopupMenuSize, setCommentPopupMenuSize] = useState(new Size(0, 0));

    const handleCommentMenuSizeChange = (newSize: Size) => {
        setCommentMenuSize(newSize);
    };

    const handleCommentPopupMenuSizeChange = (newSize: Size) => {
        setCommentPopupMenuSize(newSize);
    };

    const handleEdit = useCallback(() => {
        handleClose();
        onEditStart(comment.id);
    }, [comment.id, handleClose, onEditStart]);

    const handleDelete = useCallback(() => {
        handleClose();
        threadOperation.deleteComment(comment.id).then();
    }, [comment.id, handleClose, threadOperation]);

    const commentMenuPositionMap = new CommentMenuPositionMap(position, commentMenuSize, commentPopupMenuSize);

    return (
        <>
            <CommentMenuContainer
                position={commentMenuPositionMap.getMenuButtonPosition()}
                isPrimaryComment={false}
                onSizeChange={handleCommentMenuSizeChange}
            >
                <CommentMenuButtonContainer>
                    <button
                        className="ml-[10px] px-3 pb-[6px] pt-3 font-bold text-gray-700 hover:text-gray-800"
                        onClick={handleOpen}
                    >
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                </CommentMenuButtonContainer>
            </CommentMenuContainer>
            {isOpen && (
                <CommentMenuContainer
                    position={commentMenuPositionMap.getPopupMenuPosition()}
                    isPrimaryComment={false}
                    onSizeChange={handleCommentPopupMenuSizeChange}
                >
                    <CommentPopupMenuContainer popupRef={popupRef}>
                        {isCommentEditable && (
                            <button
                                className="rounded-[4px] border border-gray-400 hover:bg-gray-400"
                                onClick={handleEdit}
                                style={{ paddingBlock: 4, paddingInline: 12 }}
                            >
                                <FontAwesomeIcon icon={faPen} /> 編集 | Edit
                            </button>
                        )}
                        <button
                            className="rounded-[4px] border border-gray-400 hover:bg-gray-400"
                            onClick={handleDelete}
                            style={{ paddingBlock: 4, paddingInline: 12 }}
                        >
                            <div className="text-red-700">
                                <FontAwesomeIcon icon={faTrashAlt} /> 削除 | Delete
                            </div>
                        </button>
                    </CommentPopupMenuContainer>
                </CommentMenuContainer>
            )}
        </>
    );
};

type NodeNameJSON = string;

export class NodeName {
    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public static load(dump: NodeNameJSON): NodeName {
        return new NodeName(dump);
    }

    public trim(): NodeName {
        return new NodeName(this.value.trim());
    }

    public dump(): NodeNameJSON {
        return this.value;
    }

    public isEqual(other: NodeName): boolean {
        return other instanceof NodeName && other.value == this.value;
    }
}

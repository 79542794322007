import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler } from 'react';

type Props = {
    onClick: MouseEventHandler<HTMLDivElement>;
    icon: IconDefinition;
    tooltip?: string;
    fontSize: number;
    buttonSize: number;
};

export const IconButton: React.FC<Props> = ({ onClick, icon, tooltip, fontSize, buttonSize }: Props) => {
    const button = (
        <div className="flex size-full cursor-pointer items-center justify-center rounded-lg font-bold text-white hover:bg-brand-hover">
            <FontAwesomeIcon icon={icon} style={{ fontSize }} />
        </div>
    );

    return (
        <div onClick={onClick} style={{ width: buttonSize, height: buttonSize, pointerEvents: 'auto' }}>
            {tooltip ? (
                <DarkTooltip className="size-full" placement="bottom" tooltip={tooltip}>
                    {button}
                </DarkTooltip>
            ) : (
                button
            )}
        </div>
    );
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { useMountedRef } from '@framework/hooks';
import { RecordRepository, ItemDumpable, ItemLoadable } from './RecordRepository';
import { DBPath } from '@framework/repository';

/**
 * 指定パス配下の値のリストを取得する。値の追加・変更・削除などを継続してリスンする。
 * @param valueClass
 * @param path
 * @returns
 */
export const useCollectionListener = <ValueDumpType, ValueType extends ItemDumpable<ValueDumpType>>(
    valueClass: ItemLoadable<ValueDumpType, ValueType>,
    path: DBPath
): ValueType[] | null => {
    const repositoryRef = useRef(new RecordRepository(valueClass, path));
    const [values, setValues] = useState<Record<string, ValueType> | null>(null);
    const [firstFetched, setFirstFetched] = useState(false);
    const mountedRef = useMountedRef();

    useEffect(() => {
        const repo = repositoryRef.current;

        // 初回取得は値リストを一括で受け取り、一度の state 更新で処理する
        repo.get().then((values) => {
            if (!mountedRef.current) return;
            setValues(values);
            setFirstFetched(true);
        });
    }, [mountedRef]);

    useEffect(() => {
        // 初回取得が完了するまではリスナー登録を行わない。
        if (!firstFetched) return;

        const repo = repositoryRef.current;
        repo.addListener(
            (added, parentKey) => {
                if (!mountedRef.current) return;
                if (parentKey === null) return;

                setValues((prevValues) => {
                    // prevValues が null (初期値) ならば、 added のみ保持する辞書で置き換える。
                    if (prevValues === null) return { [parentKey]: added };

                    // prevValues に parentKey が存在するときは、初回取得済みの要素なので、
                    // 辞書を更新することなく、prevValues をそのまま返す。
                    if (prevValues[parentKey]) return prevValues;

                    // 初回取得後に追加された要素なので、新しい辞書を作成して added を追加する。
                    return { ...prevValues, [parentKey]: added };
                });
            },
            (changed, parentKey) => {
                if (!mountedRef.current) return;
                if (parentKey === null) return;

                setValues((prevValues) => {
                    if (prevValues === null) return prevValues;
                    return { ...prevValues, [parentKey]: changed };
                });
            },
            (_removed, parentKey) => {
                if (!mountedRef.current) return;
                if (parentKey === null) return;

                setValues((prevValues) => {
                    if (prevValues === null) return prevValues;
                    const newValues = { ...prevValues };
                    delete newValues[parentKey];
                    return newValues;
                });
            }
        );

        return () => repo.removeListener();
    }, [firstFetched, mountedRef, path, valueClass]);

    return useMemo(() => {
        if (values === null) return null;
        return Object.values(values);
    }, [values]);
};

import type { ViewOperation } from '@view-model/adapter';
import { createContext, useContext, useMemo } from 'react';
import { Point } from '@view-model/models/common/basic';
import { Position } from '@view-model/models/common/types/ui';

export type ViewContextType = {
    viewOperation: ViewOperation | null;
    viewPosition: Position | null;
};

export const ViewContext = createContext<ViewContextType>({
    viewOperation: null,
    viewPosition: null,
});

export const useViewOperation = (): ViewOperation => {
    const { viewOperation } = useContext(ViewContext);

    if (!viewOperation) {
        throw new Error('No ViewContext.Provider found when calling useViewOperation.');
    }

    return viewOperation;
};

export const useViewPosition = (): Point => {
    const { viewPosition } = useContext(ViewContext);

    if (!viewPosition) {
        throw new Error('No ViewContext.Provider found when calling useViewPosition.');
    }

    return useMemo(() => new Point(viewPosition.x, viewPosition.y), [viewPosition]);
};

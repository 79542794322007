import { memo } from 'react';
import { classNames } from '@framework/utils';
import ReactModal from 'react-modal';

const modalStyle: ReactModal.Styles = {
    overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        background: 'none',
        border: 'none',
        borderRadius: '0',
    },
};

type Props = {
    isOpen: boolean;
    onClose(): void;
    // モーダルコンテンツ
    children: React.ReactNode;
    // モーダルコンテンツを囲む div 要素の追加クラス名 (tailwindcss で上書き可能)
    className?: string;
    // オーバーレイをクリックしたときにモーダルを閉じるかどうか (defaultでは閉じる)
    shouldCloseOnOverlayClick?: boolean;
};

/**
 * 横幅固定(600px)のモーダルコンポーネントのシンプルなラッパー。
 * 閉じる等のアクションボタンは `children` 内部に記述する必要がある。
 */
export const Modal: React.FC<Props> = memo(({ isOpen, onClose, children, className }: Props) => {
    return (
        <ReactModal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
            <div className={classNames('bg-white', 'rounded-lg', className)}>{children}</div>
        </ReactModal>
    );
});

Modal.displayName = 'Modal';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { Container } from 'inversify';
import { DBPath } from '../RTDBPath';

// ts-unused-exports:disable-next-line
export type Reference = firebase.database.Reference;

// ts-unused-exports:disable-next-line
export type DataSnapshot = firebase.database.DataSnapshot;
export type IteratedDataSnapshot = firebase.database.IteratedDataSnapshot;

// ts-unused-exports:disable-next-line
export type Query = firebase.database.Query;

// ts-unused-exports:disable-next-line
export const ServerValue = firebase.database.ServerValue;

// ts-unused-exports:disable-next-line
export type EventType = firebase.database.EventType;

type Database = firebase.database.Database;

/*
 * inversify によるシングルトン化
 *
 * このファイルで実装を閉じるため、@framework/container のDIコンテナ/シンボル定義を利用しない。
 * （依存性のあるDIインジェクションのためのコンテナ/シンボル管理は不要なため）
 */
const container = new Container();
const DatabaseSymbol = Symbol.for('Database');

// ts-unused-exports:disable-next-line
export class RefBuilder {
    /**
     * RTDB の指定の path に対する ref (Reference) を返します。
     * @param path
     * @returns
     */
    static ref(path?: DBPath): Reference {
        return container.get<Database>(DatabaseSymbol).ref(path);
    }

    /**
     * RefBuilder.ref() が利用する Database クライアントをバインドします。
     *
     * @param database
     */
    static bindDatabase(database: Database): void {
        const binding = container.isBound(DatabaseSymbol)
            ? container.rebind<Database>(DatabaseSymbol)
            : container.bind<Database>(DatabaseSymbol);

        binding.toConstantValue(database);
    }

    /**
     * callback に渡された間数を実行している間だけ、 RefBuilder.ref() が利用する Database クライアントを差し替えます。
     */
    static async withDatabase(database: Database, callback: () => Promise<void>): Promise<void> {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('RefBuilder.withDatabase() はテスト環境でのみ利用できます。');
        }

        container.snapshot();
        this.bindDatabase(database);
        await callback();
        container.restore();
    }
}

import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { Point, Rect } from '@view-model/models/common/basic';

type VisibleAreaCenterPointContextValue = (viewRect: Rect) => Point;

type Props = {
    visibleAreaCenterPoint: Point;
    children: React.ReactNode;
};

const VisibleAreaCenterPointContext = createContext<VisibleAreaCenterPointContextValue | null>(null);

// パフォーマンスの観点からvisibleAreaCenterPointはRefに格納しているが、
// visibleAreaCenterPointRefを呼び出すタイミングによっては最新の値を参照できないことがある
// そのため、visibleAreaCenterPointRefが必要になる処理の直前で最新の値を参照できるように
// getTransformedVisibleAreaCenterPointを取り回すようにしている
export const useGetTransformedVisibleAreaCenterPoint = (): ((viewRect: Rect) => Point) => {
    const getTransformedVisibleAreaCenterPoint = useContext(VisibleAreaCenterPointContext);
    const defaultFunc = (viewRect: Rect) => viewRect.topLeft(); // null対策のデフォルト関数

    return getTransformedVisibleAreaCenterPoint || defaultFunc;
};

export const VisibleAreaCenterPointProvider: React.FC<Props> = ({ visibleAreaCenterPoint, children }: Props) => {
    const visibleAreaCenterPointRef = useRef<Point>(visibleAreaCenterPoint);

    const getTransformedVisibleAreaCenterPoint = useCallback(
        (viewRect: Rect): Point => {
            const visibleAreaCenterPoint = visibleAreaCenterPointRef.current;
            const { x: viewTopLeftX, y: viewTopLeftY } = viewRect.topLeft();
            const { x: visibleAreaCenterPointX, y: visibleAreaCenterPointY } = visibleAreaCenterPoint;
            return new Point(visibleAreaCenterPointX - viewTopLeftX, visibleAreaCenterPointY - viewTopLeftY);
        },
        [visibleAreaCenterPointRef]
    );

    useEffect(() => {
        visibleAreaCenterPointRef.current = visibleAreaCenterPoint;
    }, [visibleAreaCenterPoint]);

    return (
        <VisibleAreaCenterPointContext.Provider value={getTransformedVisibleAreaCenterPoint}>
            {children}
        </VisibleAreaCenterPointContext.Provider>
    );
};

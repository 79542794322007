import { useCallback, useEffect, useRef } from 'react';
import { Size } from '@view-model/models/common/basic';
import { ForeignObjectAutosize } from '@view-model/models/common/components/ForeignObjectAutosize';
import TextareaAutosize from 'react-textarea-autosize';
import { useD3DblClickCallback, useTextSelectable } from '@view-model/models/common/hooks';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';

type Props = {
    editing: boolean;
    text: string;
    size: Size;
    minHeight: number;
    onChangeSize(size: Size): void;
    onStartEdit?(text: string): void;
    onEdit?(text: string): void;
    onEndEdit?(text: string): void;
    onBlur?(): void;
    onDblClick?(): void;
};

export const CalloutText: React.FC<Props> = ({
    editing,
    text,
    size,
    minHeight,
    onChangeSize,
    onStartEdit,
    onEdit,
    onEndEdit,
    onBlur,
    onDblClick,
}: Props) => {
    const doubleClickableDivRef = useRef<HTMLDivElement>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const prevEditing = usePrevious(editing);

    useTextSelectable(textAreaRef);

    // 編集開始時のコールバック
    useEffect(() => {
        if (prevEditing !== editing && editing) {
            onStartEdit?.(text);

            // 編集開始時のテキストエリアへのフォーカス＆テキスト全選択
            if (textAreaRef.current) {
                textAreaRef.current.focus();
                textAreaRef.current.select();
            }
        }
    }, [editing, onStartEdit, prevEditing, text]);

    // 編集完了時のコールバック
    useEffect(() => {
        if (prevEditing !== editing && prevEditing) {
            onEndEdit?.(text);
        }
    }, [editing, onEndEdit, prevEditing, text]);

    /*
     * TextareaAutosizeのmin-height設定
     * TextareaAutosizeではstyleの型からminHeightが除去されているため、refから直接設定する必要がある
     */
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.minHeight = `${minHeight - 40 /* padding*2の40pxを差し引く */}px`;
        }
    }, [minHeight]);

    useD3DblClickCallback(
        doubleClickableDivRef,
        useCallback(() => {
            onDblClick?.();
        }, [onDblClick]),
        true
    );

    const handleBlur = useCallback(() => {
        onBlur?.();
    }, [onBlur]);

    // テキスト編集時のテキストコールバック
    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            onEdit?.(event.target.value);
        },
        [onEdit]
    );

    // (Ctrl | Meta) + Enter によるフォーカス解除（=編集確定）
    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (!textAreaRef.current) return;

        const withMetaKey = event.ctrlKey || event.metaKey;
        if (event.key == 'Enter' && withMetaKey) {
            textAreaRef.current.blur();
        }
    }, []);

    return (
        <ForeignObjectAutosize onSizeChange={onChangeSize}>
            <div
                ref={doubleClickableDivRef}
                className="cursor-move whitespace-pre-wrap break-all p-5 text-3xl"
                style={{ width: size.width, minHeight: minHeight }}
            >
                <TextareaAutosize
                    ref={textAreaRef}
                    value={text}
                    className={editing ? 'w-full p-2 align-middle' : 'hidden'}
                    onBlur={handleBlur}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                />
                <div className={editing ? 'invisible h-0 p-2' : 'min-h-full w-full p-2 align-middle'}>{text}</div>
            </div>
        </ForeignObjectAutosize>
    );
};

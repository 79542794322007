/** Immutableな選択状態Setクラス */
export class SelectedItemSet<T> {
    private readonly itemSet: Set<T>;

    constructor(items: Set<T> | null = null) {
        this.itemSet = items || new Set<T>();
    }

    static from<T>(items: T[]): SelectedItemSet<T> {
        return new SelectedItemSet(new Set(items));
    }

    /**
     * 選択されているすべてのアイテムを解除して、渡されたアイテムを選択する
     * @param item
     */
    selectOnly(item: T): SelectedItemSet<T> {
        return new SelectedItemSet(new Set([item]));
    }

    /**
     * アイテムを選択する
     * @param item
     */
    addToSelection(item: T): SelectedItemSet<T> {
        const newItemSet = new Set(this.itemSet);
        newItemSet.add(item);
        return new SelectedItemSet(newItemSet);
    }

    /**
     * 複数のアイテムを選択する
     * @param items
     */
    addToSelectionMany(items: T[]): SelectedItemSet<T> {
        const newItemSet = new Set(this.itemSet);
        items.forEach((item) => newItemSet.add(item));
        return new SelectedItemSet(newItemSet);
    }

    /**
     * アイテムの選択を解除する
     * @param item
     */
    deselect(item: T): SelectedItemSet<T> {
        const newItemSet = new Set(this.itemSet);
        newItemSet.delete(item);
        return new SelectedItemSet(newItemSet);
    }

    /**
     * 複数のアイテムの選択を解除する
     * @param items
     */
    deselectMany(items: T[]): SelectedItemSet<T> {
        const newItemSet = new Set(this.itemSet);
        items.forEach((item) => newItemSet.delete(item));
        return new SelectedItemSet(newItemSet);
    }

    /**
     * すべての選択を解除する
     */
    deselectAll(): SelectedItemSet<T> {
        return new SelectedItemSet();
    }

    /**
     * アイテムが選択されているかどうかを返す
     * @param item
     */
    isSelected(item: T): boolean {
        return this.itemSet.has(item);
    }

    /**
     * 複数個の要素が選択されているかどうかを返す
     */
    isMultiSelected(): boolean {
        return this.itemSet.size >= 2;
    }

    /**
     * 単一選択されているかどうかを返す
     */
    isSingleSelected(): boolean {
        return this.itemSet.size == 1;
    }

    /**
     * 少なくとも1つの要素が選択されているかどうかを返す
     */
    isSomeSelected(): boolean {
        return this.itemSet.size > 0;
    }

    /**
     * 未選択状態か否かを返す
     */
    isEmpty(): boolean {
        return this.itemSet.size == 0;
    }

    /**
     * 選択されているアイテムを返す
     */
    getSelectedItems(): T[] {
        return Array.from(this.itemSet.values());
    }

    /**
     * 自身の持つ選択済みの要素から、otherの持つ選択済みの要素を取り除いた結果を新しいSetで返します。
     * (this - other の差集合を返します)
     * @param other
     */
    difference(other: SelectedItemSet<T>): SelectedItemSet<T> {
        const items = this.getSelectedItems();
        return SelectedItemSet.from<T>(items.filter((item) => !other.isSelected(item)));
    }
}

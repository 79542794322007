import { ClipboardPayload } from '@model-framework/clipboard';

const Target = 'Any'; // 情報として不要だがClipboardPayloadの互換性のためセット
const ContentType = 'image';

export type ImageClipboardPayload = ClipboardPayload & {
    target: typeof Target;
    contentType: typeof ContentType;
    files: File[];
};

export namespace ImageClipboardPayload {
    export function fromData(files: File[]): ImageClipboardPayload {
        return {
            target: Target,
            contentType: ContentType,
            files,
        };
    }
}

export const isImageClipboardPayload = (payload: ClipboardPayload): payload is ImageClipboardPayload => {
    return payload.contentType === ContentType;
};

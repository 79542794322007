import { useRef, useState, useLayoutEffect } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { createPortal } from 'react-dom';
import './style/tooltip.css';

type TooltipProps = {
    children: React.ReactNode;
    tooltip: string;
    placement: Placement;
    className?: string;
};

export const Tooltip: React.FC<TooltipProps> = ({ children, tooltip, placement, className }: TooltipProps) => {
    const referenceRef = useRef<HTMLDivElement | null>(null);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
    const [isMouseOn, setIsMouseOn] = useState<boolean>(false);
    const { styles, attributes, update } = usePopper(referenceRef.current, tooltipRef.current, {
        placement: placement,
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    });

    useLayoutEffect(() => {
        if (isMouseOn) {
            update?.();
        }
    }, [update, isMouseOn]);

    return (
        <>
            <div
                className={className}
                ref={referenceRef}
                onMouseEnter={() => {
                    setIsMouseOn(true);
                }}
                onMouseLeave={() => {
                    setIsMouseOn(false);
                }}
            >
                {children}
            </div>
            {createPortal(
                <div className="tooltip-container" ref={tooltipRef} style={styles.popper} {...attributes.popper}>
                    {isMouseOn && (
                        <>
                            <div className="tooltip-arrow" ref={setArrowElement} style={styles.arrow} />

                            <div className="flex min-w-32 items-center justify-center rounded border border-gray-400 bg-white shadow-lg">
                                <div className="p-2">{tooltip}</div>
                            </div>
                        </>
                    )}
                </div>,
                document.body
            )}
        </>
    );
};

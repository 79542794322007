import { Rect } from '@view-model/models/common/basic';

type Props = {
    rect: Rect;
};

export const MultiSelectionRect: React.FC<Props> = ({ rect }: Props) => {
    const {
        position: { x, y },
        width,
        height,
    } = rect;

    return (
        <rect
            className="stroke-gray-600"
            pointerEvents="none"
            fillOpacity={0}
            strokeWidth={3}
            x={x}
            y={y}
            width={width}
            height={height}
            rx={16}
            ry={16}
        />
    );
};

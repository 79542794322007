import { ViewModelEntity } from '@view-model/domain/view-model';
import { LinkBend, LinkBendFactory, LinkEntity } from '../domain';
import { LinkableTargetKey, LinkKey, NodeKey, StickyZoneKey } from '@view-model/domain/key';
import { StickyModel } from '@view-model/domain/model';
import { LinkRepository } from '../domain/LinkRepository';

export class LinkEntityOperation {
    constructor(private readonly repository: LinkRepository) {}

    static findLink({
        key,
        isTarget,
        viewModel,
    }: {
        key: LinkKey;
        isTarget: (link: LinkEntity) => boolean;
        viewModel: ViewModelEntity;
    }): LinkEntity | null {
        const models = viewModel.getModels();
        for (const model of models) {
            const link = model.findLink(key);
            if (link && isTarget(link)) return link;
        }

        return null;
    }

    static getLink(key: LinkKey, viewModel: ViewModelEntity): LinkEntity | null {
        return LinkEntityOperation.findLink({
            key,
            isTarget: (link) => link !== null,
            viewModel,
        });
    }

    static findLinksByNodeKeys(nodeKeys: NodeKey[], viewModel: ViewModelEntity): LinkEntity[] {
        const models = viewModel.getModels();

        const links: LinkEntity[] = [];
        nodeKeys.forEach((nodeKey) => {
            models.forEach((model: StickyModel) => {
                links.push(...model.linkEntitiesByNodeKey(nodeKey));
            });
        });

        return links;
    }

    static findLinksByZoneKeys(zoneKeys: StickyZoneKey[], viewModel: ViewModelEntity): LinkEntity[] {
        const models = viewModel.getModels();

        const links: LinkEntity[] = [];
        zoneKeys.forEach((zoneKey) => {
            models.forEach((model: StickyModel) => {
                links.push(...model.linkEntities().filter((link: LinkEntity) => link.isLinkTo(zoneKey)));
            });
        });

        return links;
    }

    /**
     * リンクをつなぐときの適切なLinkBendを返します。
     * 特に、同じ fromKey-toKey 間にすでにリンクがある時のベンド量調整をします。
     * @param fromKey
     * @param toKey
     * @param viewModel
     */
    newLinkBendBetween({
        fromKey,
        toKey,
        viewModel,
    }: {
        fromKey: LinkableTargetKey;
        toKey: LinkableTargetKey;
        viewModel: ViewModelEntity;
    }): LinkBend {
        const existsLinks = this.findLinksConnectTo(fromKey, toKey, viewModel);
        return LinkBendFactory.build(existsLinks, fromKey);
    }

    /**
     * aとbをつなぐリンクの一覧を取得する
     * @param a
     * @param b
     * @param viewModel
     */
    private findLinksConnectTo(a: LinkableTargetKey, b: LinkableTargetKey, viewModel: ViewModelEntity): LinkEntity[] {
        const models = viewModel.getModels();
        const links: LinkEntity[] = [];

        models.forEach((model: StickyModel) => {
            links.push(...model.linkEntities().filter((link: LinkEntity) => link.isConnectTo(a, b)));
        });

        return links;
    }

    static getLinks(keys: LinkKey[], viewModel: ViewModelEntity): LinkEntity[] | null {
        const models = viewModel.getModels();

        const links: LinkEntity[] = [];
        const foundAll = keys.every((key) => {
            for (const model of models) {
                const link = model.findLink(key);
                if (link) {
                    links.push(link);
                    return true;
                }
            }

            return false;
        });

        if (foundAll) return links;
        return null;
    }

    static existsSome(keys: LinkKey[], viewModel: ViewModelEntity): boolean {
        const models = viewModel.getModels();

        return keys.some((key) => {
            for (const model of models) {
                const link = model.findLink(key);
                if (link) return true;
            }

            return false;
        });
    }

    save(link: LinkEntity): void {
        this.repository.saveLink(link).then();
    }

    saveMulti(links: LinkEntity[]): void {
        this.repository.saveLinks(links).then();
    }

    remove(link: LinkEntity): void {
        this.repository.deleteLink(link.key).then();
    }

    removeMulti(links: LinkEntity[]): void {
        this.repository.deleteLinks(links.map((link) => link.key)).then();
    }
}

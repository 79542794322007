import Victor from 'victor';
import { LinkJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';

export class LinkBend {
    public readonly r: number;
    public readonly d: number;

    public constructor(attributes: LinkJSON['bend']) {
        this.r = attributes.r;
        this.d = attributes.d;
    }

    public static load(json: LinkJSON['bend']): LinkBend {
        return new LinkBend(json);
    }

    public dump(): LinkJSON['bend'] {
        return {
            r: this.r,
            d: this.d,
        };
    }

    public static Straight(): LinkBend {
        return new LinkBend({
            r: 0.5,
            d: 0,
        });
    }

    public static fromPoints(startingPoint: Victor, endingPoint: Victor, bendingPoint: Victor): LinkBend {
        const bender = bendingPoint.subtract(startingPoint);
        const direction = endingPoint.clone().subtract(startingPoint);
        const r = bender.dot(direction.clone().norm()) / direction.length();
        const d = bender.dot(direction.clone().norm().rotateDeg(90));

        return new LinkBend({ r: r, d: d });
    }

    public isEquals(other: LinkBend): boolean {
        return other instanceof LinkBend && this.r == other.r && this.d == other.d;
    }

    /**
     * 両端の Node の中心を結んだ直線が、それぞれの Node と交わった点を基準とし、
     * その内分点と、そこからの距離で bending Point を決定する。
     * それぞれの Node の中心点を基準にしないのは、Node 同士が近い場合に、
     * 内分点がどちらかの Node の内部に存在するという状況を防ぐため。
     *
     * @returns {Position}
     */
    public bendingPoint(startingPoint: Victor, endingPoint: Victor): Victor {
        // 制御点から Node を結ぶ直線への垂線の足のベクトル
        const foot = startingPoint.clone().mix(endingPoint, this.r);
        const norm = endingPoint.clone().subtract(startingPoint).norm().rotateDeg(90);
        return foot.add(norm.multiply(new Victor(this.d, this.d)));
    }

    add(r: number, d: number): LinkBend {
        return new LinkBend({ r: this.r + r, d: this.d + d });
    }

    isPositiveBend(): boolean {
        return this.d > 0;
    }
}

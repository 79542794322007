import { Multiplicity } from '@view-model/models/sticky/StickyLink';
import { MultiplicityTextValidator } from '@view-model/models/common/validator/MultiplicityTextValidator';
import { MultiplicityTextUtil } from '@view-model/models/common/utils/MultiplicityTextUtil';

export class MultiplicityTextParser {
    /**
     * 引数に与えた文字列を多重度にパースします。パースできない場合はnullを返します。
     * @param text
     */
    public static parse(text: string): Multiplicity | null {
        const replacedText = MultiplicityTextUtil.replaceToAvailableString(text);

        // 多重度として不正なテキストならnullを返す
        if (!MultiplicityTextValidator.isValid(replacedText)) {
            return null;
        }

        // 入力値が数字単体の場合（例: 「1」「3」）
        if (MultiplicityTextValidator.isNumber(replacedText)) {
            return new Multiplicity({ lower: parseInt(replacedText), upper: parseInt(replacedText) });
        }

        // 入力値が数字でない場合（例: 「1..*」「2..3」）、上限値と下限値に分割する
        const texts = replacedText.split('..');
        const lowerText = texts[0];
        const upperText = texts[1];

        if (MultiplicityTextValidator.isWildcard(upperText)) {
            return new Multiplicity({ lower: parseInt(lowerText), upper: Infinity });
        }

        const lower = parseInt(lowerText);
        const upper = parseInt(upperText);
        return new Multiplicity({ lower: lower, upper: upper });
    }
}

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

export class StorageRefBuilder {
    static ref(path: string): firebase.storage.Reference {
        return firebase.storage().ref(path);
    }
}

export class StoragePath {
    static viewModelAssetPath(groupId: string, viewModelId: string, assetId: string): string {
        return ['view-model-assets', groupId, viewModelId, assetId].join('/');
    }
}

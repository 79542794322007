import { useCallback, useMemo } from 'react';
import { UserPublicProfile, useUserPublicProfile, useUserPublicProfiles } from '@user/PublicProfile';
import { Loading } from '@framework/ui/atoms/Loading';
import { classNames, generateDefaultUserIcon } from '@framework/utils';
import { WorkspaceMemberRole, WorkspaceMemberRoles } from '@workspace/domain/workspace';

type Props = {
    currentUserId: string;
    memberRoles: WorkspaceMemberRoles;
    disabled: boolean;
    selectedUserIds: string[];
    onChange(userIds: string[]): void;
};

export const MemberRemoveList: React.FC<Props> = ({
    currentUserId,
    memberRoles,
    disabled,
    selectedUserIds,
    onChange,
}: Props) => {
    const userIds = useMemo(() => {
        return memberRoles.userIds().filter((id) => id !== currentUserId);
    }, [memberRoles, currentUserId]);
    const myProfile = useUserPublicProfile(currentUserId);
    const myRole = memberRoles.getRoleOf(currentUserId);
    const profiles = useUserPublicProfiles(userIds);

    const handleChange = useCallback(
        (userId: string, checked: boolean) => {
            if (checked) {
                onChange([...selectedUserIds, userId]);
            } else {
                onChange(selectedUserIds.filter((id) => id !== userId));
            }
        },
        [onChange, selectedUserIds]
    );

    if (!myProfile || !myRole || !profiles) return <Loading />;

    return (
        <ul className="px-2">
            <MemberItem
                removable={false}
                profile={myProfile}
                role={myRole}
                disabled={disabled}
                checked={false}
                onChange={() => void 0}
            />

            {profiles.map((profile) => {
                const role = memberRoles.getRoleOf(profile.id);
                if (!role) return null;

                return (
                    <MemberItem
                        key={profile.id}
                        removable={true}
                        profile={profile}
                        role={role}
                        disabled={disabled}
                        checked={selectedUserIds.includes(profile.id)}
                        onChange={handleChange}
                    />
                );
            })}
        </ul>
    );
};

type ItemProps = {
    profile: UserPublicProfile;
    role: WorkspaceMemberRole;
    removable: boolean;
    disabled: boolean;
    checked: boolean;
    onChange(userId: string, checked: boolean): void;
};

const MemberItem: React.FC<ItemProps> = ({ profile, role, removable, disabled, checked, onChange }: ItemProps) => {
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(profile.id, event.target.checked);
        },
        [onChange, profile.id]
    );

    return (
        <li className="flex flex-row items-center px-3 py-2 hover:bg-gray-200">
            <label className="flex grow cursor-pointer flex-row">
                <input
                    type="checkbox"
                    className={classNames(
                        'form-checkbox mr-1 h-6 w-6 border-brand',
                        disabled || !removable ? 'cursor-not-allowed bg-gray-300' : 'cursor-pointer'
                    )}
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled || !removable}
                />
                <span className="flex size-6 overflow-hidden rounded-full">
                    {profile.imageUrl ? (
                        <img className="size-full object-cover" src={profile.imageUrl} alt={profile.name} />
                    ) : (
                        <div className="size-full object-cover">
                            {generateDefaultUserIcon({
                                id: profile.id,
                                name: profile.name,
                                size: 24,
                            })}
                        </div>
                    )}
                </span>
                <span className="flex h-6 grow truncate px-2 leading-6">{profile.name}</span>
            </label>
            <span className="w-30">{WorkspaceMemberRole.getLocalString(role)}</span>
        </li>
    );
};

import { AuthSessionId, GroupId } from '@schema-common/base';
import { RTDBPath, TextRepository } from '@framework/repository';

/**
 * グループ管理者がグループメンバーのUserDataを取得するための関数
 * システム上、ユーザーは複数のグループにまたがって所属できる
 * そのため、グループ管理者がどの所属グループのグループメンバーのUserDataを参照としているかを、
 * 参照前に/group/group-console-using/{グループ管理者のsessionId}に登録する必要がある
 * この関数では、グループメンバーのUserDataを参照する際の一連の処理をまとめて実行できるようにしている
 */
export const getMemberDataViaGroupConsoleUsing = async <T>(
    groupId: GroupId,
    sessionId: AuthSessionId,
    getFunc: () => Promise<T>
): Promise<T> => {
    const groupConsoleUsingRepo = new TextRepository(RTDBPath.Group.groupConsoleUsingPath(sessionId));

    // RTDBで現在のグループ管理者がグループ内のメンバーにアクセスするためのデータを保存
    await groupConsoleUsingRepo.save(groupId);

    // UserDataを取得するグループIdの書き込みの反映待ち
    await new Promise((resolve) => setTimeout(resolve, 100));

    const result = await getFunc();

    // UserDataを取得するグループIdの書き込みの反映待ち
    // 削除前にも待たないとエラーが出てしまうのでsetTimeoutを利用している
    await new Promise((resolve) => setTimeout(resolve, 100));

    // UserDataを取得するグループIdの削除
    await groupConsoleUsingRepo.delete();

    return result;
};

import { NodeFontSize } from './NodeFontSize';
import { isThemeColor, ThemeColor } from '@view-model/models/common/color';
import { StickyNodeJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/nodes/{nodeId}/StickyNodeJSON';

type NodeStyles = {
    fillColor: string;
    borderColor: string;
    selection?: {
        backgroundColor?: string;
    };
};

const styleDefinitions: Record<ThemeColor, NodeStyles> = {
    [ThemeColor.White]: {
        fillColor: '#F5F5F5',
        borderColor: '#E0E0E0',
    },
    [ThemeColor.Red]: {
        fillColor: '#EF9A9A',
        borderColor: '#E57373',
    },
    [ThemeColor.Green]: {
        fillColor: '#C8E6C9',
        borderColor: '#A5D6A7',
    },
    [ThemeColor.Blue]: {
        fillColor: '#90CAF9',
        borderColor: '#64B5F6',
        selection: {
            // tailwindcssでは動的に色指定ができないため、直接tailwindcssのクラスをここに指定してclassNames()で結合する
            backgroundColor: 'selection:bg-gray-200',
        },
    },
    [ThemeColor.Orange]: {
        fillColor: '#FFCC80',
        borderColor: '#FFB74D',
    },
    [ThemeColor.Yellow]: {
        fillColor: '#FFF59D',
        borderColor: '#FFF176',
    },
    [ThemeColor.YellowGreen]: {
        fillColor: '#E6EE9C',
        borderColor: '#DCE775',
    },
    [ThemeColor.Purple]: {
        fillColor: '#E1BEE7',
        borderColor: '#CE93D8',
    },
};

type NodeStyleAttributes = {
    fontSize: NodeFontSize;
    themeColor: ThemeColor;
};

export class NodeStyle {
    readonly fontSize: NodeFontSize;
    readonly themeColor: ThemeColor;

    constructor(attributes: NodeStyleAttributes) {
        this.fontSize = attributes.fontSize;
        this.themeColor = attributes.themeColor;
    }

    static buildNew(themeColor?: ThemeColor): NodeStyle {
        return new this({
            fontSize: NodeFontSize.L,
            themeColor: themeColor || ThemeColor.Red,
        });
    }

    static load(dump: StickyNodeJSON['style']): NodeStyle {
        const { fontSize, themeColor } = dump;

        return new this({
            fontSize: NodeFontSize.load(fontSize),
            themeColor: isThemeColor(themeColor) ? themeColor : ThemeColor.White,
        });
    }

    dump(): StickyNodeJSON['style'] {
        return {
            fontSize: NodeFontSize.dump(this.fontSize),
            themeColor: this.themeColor,
        };
    }

    isEqual(other: NodeStyle): boolean {
        return other instanceof NodeStyle && this.fontSize === other.fontSize && this.themeColor === other.themeColor;
    }

    private _cloneWith(attributes: Partial<NodeStyleAttributes>): NodeStyle {
        return new NodeStyle({
            fontSize: attributes.fontSize || this.fontSize,
            themeColor: attributes.themeColor || this.themeColor,
        });
    }

    getStyles(): NodeStyles {
        return styleDefinitions[this.themeColor];
    }

    withThemeColor(themeColor: ThemeColor): NodeStyle {
        return this._cloneWith({ themeColor });
    }

    withFontSize(fontSize: NodeFontSize): NodeStyle {
        return this._cloneWith({ fontSize });
    }

    static getStyles(themeColor: ThemeColor): NodeStyles {
        return styleDefinitions[themeColor];
    }
}

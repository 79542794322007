import { useCallback } from 'react';
import { useCommandManager } from '@view-model/models/framework/command';
import { toast } from 'react-hot-toast';

type Handler = () => void;
type Handlers = {
    handleUndo: Handler;
    handleRedo: Handler;
};

export const useUndoRedoHandlers = (): Handlers => {
    const commandManager = useCommandManager();

    const handleUndo = useCallback(async () => {
        if (await commandManager.canUndo()) {
            commandManager.undo();
            toast('取り消しました | Canceled');
        } else {
            toast.error("取り消しできませんでした | Couldn't get back");
        }
    }, [commandManager]);

    const handleRedo = useCallback(async () => {
        if (await commandManager.canRedo()) {
            commandManager.redo();
            toast('やり直しました | Redone');
        } else {
            toast.error("やり直しできませんでした | Couldn't redone");
        }
    }, [commandManager]);

    return { handleUndo, handleRedo };
};

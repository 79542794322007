import { DataSnapshot, RefBuilder, Reference } from '../RefBuilder';
import { DBPath } from '@framework/repository';
import { NO_ASSIGN } from '@schema-common/base';

// {
//    [hogehoge-key]: 'N/A'
// }
// というようなレコードを扱うためのリポジトリ
export class KeysRepository<KeyType extends string> {
    private addedCallback: ((value: DataSnapshot) => void) | undefined = undefined;
    private removedCallback: ((value: DataSnapshot) => void) | undefined = undefined;
    private readonly ref: Reference;
    constructor(path: DBPath) {
        this.ref = RefBuilder.ref(path);
    }

    async save(keys: KeyType[]): Promise<void> {
        await this.ref.set(
            keys.reduce(
                (res, key) => ({
                    ...res,
                    [key]: NO_ASSIGN,
                }),
                {}
            )
        );
    }

    async get(): Promise<KeyType[]> {
        const result = await this.ref.get();
        if (!result) return [];

        // Object.keysの戻り値の型がstring[]なので、filterでKeyType[]が返るようにしている
        const values: KeyType[] = [];
        result.forEach((child) => {
            values.push(child.key as KeyType);
        });

        return values;
    }

    async addItem(key: KeyType): Promise<void> {
        await this.ref.child(key).set(NO_ASSIGN);
    }

    async removeItem(key: KeyType): Promise<void> {
        await this.ref.child(key).set(null);
    }

    async delete(): Promise<void> {
        await this.ref.remove();
    }

    addListener(onAdded: (key: KeyType) => void, onRemoved: (key: KeyType) => void): void {
        this.addedCallback = (snapshot) => {
            const j = snapshot.val();
            if (!j) return;
            onAdded(snapshot.key as KeyType);
        };
        this.ref.on('child_added', this.addedCallback);

        this.removedCallback = (snapshot) => {
            const j = snapshot.val();
            if (!j) return;
            onRemoved(snapshot.key as KeyType);
        };
        this.ref.on('child_removed', this.removedCallback);
    }

    removeListener(): void {
        // この Repository の addListener() で登録したリスナーだけを対象に、リスン解除する。
        if (this.addedCallback) {
            this.ref.off('child_added', this.addedCallback);
            this.addedCallback = undefined;
        }

        if (this.removedCallback) {
            this.ref.off('child_removed', this.removedCallback);
            this.removedCallback = undefined;
        }
    }
}

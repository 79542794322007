import { LinkMarkStyle } from '../LinkMarkStyle';
import { LineEdge } from '../../LineEdge';
import { DiamondMarkView, TriangleMarkView } from '../../private-components';
import { Angle, Point } from '@view-model/models/common/basic';

type Props = {
    style: LinkMarkStyle;
    isSelected: boolean;
};

const ArrowMarkSize = 12;
const DiamondMarkSize = 16;
const LineStartPoint = new Point(-10, 0);
const LineEndPoint = new Point(10, 0);
const StartEdge = new LineEdge(LineStartPoint, new Angle(180));
const EndEdge = new LineEdge(LineEndPoint, new Angle(0));

export const LinkMarkStyleMenuIcon: React.FC<Props> = ({ style, isSelected }: Props) => {
    return (
        <>
            <line
                stroke={isSelected ? 'white' : '#aaa'}
                strokeWidth={3}
                strokeLinecap="round"
                x1={LineStartPoint.x}
                y1={LineStartPoint.y}
                x2={LineEndPoint.x}
                y2={LineEndPoint.y}
            />
            {style == LinkMarkStyle.Arrow && (
                <g transform={EndEdge.toSVGTransform()}>
                    <TriangleMarkView size={ArrowMarkSize} fill={isSelected ? 'white' : '#aaa'} stroke="none" />
                </g>
            )}
            {style == LinkMarkStyle.TwoWayArrow && (
                <>
                    <g transform={StartEdge.toSVGTransform()}>
                        <TriangleMarkView size={ArrowMarkSize} fill={isSelected ? 'white' : '#aaa'} stroke="none" />
                    </g>
                    <g transform={EndEdge.toSVGTransform()}>
                        <TriangleMarkView size={ArrowMarkSize} fill={isSelected ? 'white' : '#aaa'} stroke="none" />
                    </g>
                </>
            )}
            {style == LinkMarkStyle.Diamond && (
                <g transform={EndEdge.toSVGTransform()}>
                    <DiamondMarkView size={DiamondMarkSize} fill={isSelected ? 'white' : '#aaa'} stroke="none" />
                </g>
            )}
            {style == LinkMarkStyle.HollowDiamond && (
                <g transform={EndEdge.toSVGTransform()}>
                    <DiamondMarkView
                        size={DiamondMarkSize}
                        fill={isSelected ? '#0097A7' : 'white'}
                        stroke={isSelected ? 'white' : '#aaa'}
                        strokeWidth={2}
                    />
                </g>
            )}
            {style == LinkMarkStyle.HollowTriangle && (
                <g transform={EndEdge.toSVGTransform()}>
                    <TriangleMarkView
                        size={ArrowMarkSize}
                        fill={isSelected ? '#0097A7' : 'white'}
                        stroke={isSelected ? 'white' : '#aaa'}
                        strokeWidth={2}
                    />
                </g>
            )}
        </>
    );
};

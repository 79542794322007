import { StickyNode, NodeCollection } from '@view-model/models/sticky/StickyNodeView';
import { LinkEntity, LinkCollection } from '@view-model/models/sticky/StickyLink';
import { StickyZone, StickyZoneCollection, StickyZoneStyle } from '@view-model/models/sticky/StickyZoneView';
import { ModelElementId, ModelElementKey } from '@view-model/domain/key';
import { ThemeColor } from '@view-model/models/common/color';
import { FontSize } from '@model-framework/text';
import { MenuItemList } from '@model-framework/menu';
import { LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';
import { SelectedItemSet } from '@view-model/models/common/basic';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { DisplayOrderTree, Link } from '@model-framework/display-order';
import { NodeId, LinkId, StickyZoneId } from '@schema-common/base';

export type StickyModelElement = StickyNode | LinkEntity | StickyZone;

type StickyModelSelectedSet = SelectedItemSet<ModelElementId>;

export class StickyModelElementCollection {
    private readonly nodes: NodeCollection;
    private readonly links: LinkCollection;
    private readonly zones: StickyZoneCollection;
    private readonly zonePositions: PositionSet;

    constructor(nodes: StickyNode[], links: LinkEntity[], zones: StickyZone[], zonPositions: PositionSet) {
        this.nodes = new NodeCollection(nodes);
        this.links = new LinkCollection(links);
        this.zones = new StickyZoneCollection(zones);
        this.zonePositions = zonPositions;
    }

    clone(): StickyModelElementCollection {
        return new StickyModelElementCollection(
            this.nodes.entities(),
            this.links.entities(),
            this.zones.entities(),
            this.zonePositions
        );
    }

    keys(): ModelElementKey[] {
        return [...this.nodes.keys(), ...this.links.keys(), ...this.zones.keys()];
    }

    isEqual(other: StickyModelElementCollection): boolean {
        return (
            other instanceof StickyModelElementCollection &&
            this.nodes.isEqual(other.nodes) &&
            this.links.isEqual(other.links) &&
            this.zones.isEqual(other.zones)
        );
    }

    backToFront(displayOrderTree: DisplayOrderTree, selectedItems: SelectedItemSet<string>): StickyModelElement[] {
        const links = this.links.entities().map((link) => new Link(link.id, link.fromId, link.toId));

        // findElementById() で要素持ってきていることにより、O(n^2) なので見直す余地あり
        return displayOrderTree
            .backToFront(links, selectedItems)
            .map((id) => this.findElementById(id))
            .filter((elem): elem is Exclude<typeof elem, undefined> => elem !== undefined);
    }

    private findElementById(id: NodeId | LinkId | StickyZoneId): StickyModelElement | undefined {
        const { nodes, links, zones } = this;

        return nodes.findById(id) || links.findById(id) || zones.findById(id);
    }

    /**
     * 選択中の要素の色を返す。
     * すべての要素の色が同じだった場合はその色を返し、それ以外の場合は undefined を返す。
     * @param selectedItems
     */
    getSelectedThemeColor(selectedItems: SelectedItemSet<ModelElementId>): ThemeColor | undefined {
        const nodes = this.nodes.filterByIds(selectedItems.getSelectedItems());
        const zones = this.zones.filterByIds(selectedItems.getSelectedItems());

        const items = new MenuItemList(nodes.themeColors().concat(zones.themeColors()));
        return items.compactAsSingleValue();
    }

    /**
     * ノードの周囲を囲む色を返す。
     * 対象のノードがゾーンに含まれているときには、ノード周囲の色をゾーンの色に合わせる。
     * ゾーンに含まれていないときには、白色(キャンバスと同じ色)にする。
     * @param nodeId
     * @param displayOrderTree
     */
    getNodeBaseColor(nodeId: NodeId, displayOrderTree: DisplayOrderTree) {
        const zoneId = displayOrderTree.findParentZoneIdOf(nodeId);
        if (!zoneId) return '#fff';

        const zone = this.findZoneById(zoneId);
        if (!zone) return '#fff';

        return StickyZoneStyle.getStyles(zone.style.themeColor).containedElementBorderColor;
    }

    /**
     * 選択中の要素の文字サイズを返す。
     * すべての要素の文字サイズが同じだった場合はその文字サイズを返し、それ以外の場合は undefined を返す。
     * @param selectedItems
     */
    getSelectedFontSize(selectedItems: SelectedItemSet<ModelElementId>): FontSize | undefined {
        const nodes = this.nodes.filterByIds(selectedItems.getSelectedItems());
        const zones = this.zones.filterByIds(selectedItems.getSelectedItems());

        const fontSizes = [...nodes.fontSizes(), ...zones.fontSizes()];

        const items = new MenuItemList(fontSizes);
        return items.compactAsSingleValue();
    }

    getSelectedLinkMarkStyle(selectedItems: SelectedItemSet<ModelElementId>): LinkMarkStyle | undefined {
        const links = this.links.filterByIds(selectedItems.getSelectedItems());
        const items = new MenuItemList(links.markStyles());
        return items.compactAsSingleValue();
    }

    getSelectedLinkLineStyle(selectedItems: SelectedItemSet<ModelElementId>): LinkLineStyle | undefined {
        const links = this.links.filterByIds(selectedItems.getSelectedItems());
        const items = new MenuItemList(links.lineStyles());
        return items.compactAsSingleValue();
    }

    getSelectedLinkColor(selectedItems: SelectedItemSet<ModelElementId>): LinkColor | undefined {
        const links = this.links.filterByIds(selectedItems.getSelectedItems());
        const items = new MenuItemList(links.colors());
        return items.compactAsSingleValue();
    }

    isNodeSelected(selectedItems: StickyModelSelectedSet): boolean {
        return this.selectedNodeCount(selectedItems) > 0;
    }

    isMultipleNodeSelected(selectedItems: StickyModelSelectedSet): boolean {
        return this.selectedNodeCount(selectedItems) > 1;
    }

    private selectedNodeCount(selectedItems: StickyModelSelectedSet): number {
        const ids = selectedItems.getSelectedItems();
        return this.nodes.filterByIds(ids).length;
    }

    isZoneSelected(selectedItems: StickyModelSelectedSet): boolean {
        const ids = selectedItems.getSelectedItems();
        return this.zones.filterByIds(ids).length > 0;
    }

    isLinkSelected(selectedItems: StickyModelSelectedSet): boolean {
        const ids = selectedItems.getSelectedItems();
        return this.links.filterByIds(ids).length > 0;
    }

    findZoneById(zoneId: StickyZoneId): StickyZone | undefined {
        return this.zones.findById(zoneId);
    }
}

import { Rect } from '@view-model/models/common/basic';
import { ViewName } from '@view-model/domain/view';
import { ViewId } from '@schema-common/base';
import { ViewTitleMenu } from '@view-model/ui/components/View/ViewTitleMenu';
import { ReadonlyViewTitle } from '../ViewTitleInput/ReadonlyViewTitle';
import { useAtomValue } from 'jotai';
import { viewTitleBarAtom } from '../viewTitlaBarAtom';

type Props = {
    viewRect: Rect;
    name: ViewName;
    viewId: ViewId;
    readonly: boolean;
    onCopyView(): void;
    onOpenPopupMenu(): void;
};

export const ReadonlyTitleBar: React.FC<Props> = ({
    viewRect,
    name,
    viewId,
    readonly,
    onCopyView,
    onOpenPopupMenu,
}: Props) => {
    const { height, shouldHideMenuButtons, menu, margin } = useAtomValue(viewTitleBarAtom);

    return (
        <g transform={viewRect.topLeft().toSVGTranslate()}>
            <rect
                className="fill-brand"
                width={viewRect.width}
                height={height}
                rx={height / 2}
                ry={height / 2}
                x={0}
                y={-height - margin * 2}
                stroke="none"
                cursor="move"
            />
            <foreignObject
                width={viewRect.width}
                height={height}
                transform={`translate(0, ${-height - margin * 2})`}
                className="pointer-events-none"
            >
                <div className={'flex items-center gap-8 px-16 text-[80px]'} style={{ height }}>
                    {!shouldHideMenuButtons && <div className="shrink-0" style={{ width: menu.containerWidth }} />}
                    <ReadonlyViewTitle name={name} />
                    {!shouldHideMenuButtons && (
                        <ViewTitleMenu
                            readonly={readonly}
                            viewId={viewId}
                            onCopyView={onCopyView}
                            onOpenPopupMenu={onOpenPopupMenu}
                        />
                    )}
                </div>
            </foreignObject>
        </g>
    );
};

import { useCallback, useEffect, useState } from 'react';
import { DBPath, TextRepository, useTextRepository } from '@framework/repository';

type Setter = (text: string) => void;

/**
 * RTDB上に永続化されているテキスト値と、それを更新するためのコールバック関数を返します。
 * 値の取得が完了するまでの間は undefined の値を返します。
 *
 * @param path 対象テキストの永続化されたRTDBPath
 * @returns {Array} テキスト値と、それを更新するためのコールバック関数を返す。
 * @returns {string | null | undefined} 永続化されているテキスト値。永続値が無い時にはnullを返す。値の取得途中にはundefinedを返す。
 * @returns {function(text: string): void} 値を更新するためのコールバック関数
 */
export const usePersistedText = (path: DBPath): Readonly<[string | null | undefined, Setter, TextRepository]> => {
    const repository = useTextRepository(path);
    const [text, setText] = useState<string | null | undefined>(undefined);

    useEffect(() => {
        setText(undefined);

        repository.addListener((value: string | null) => {
            setText(value);
        });
        return () => repository.removeListener();
    }, [repository]);

    const saveText = useCallback<Setter>(
        (text: string) => {
            setText(text); // 予めセットしないと、FirebaseからのコールバックでIMEの変換が確定されてしまうため先にstate更新する
            repository.save(text).then();
        },
        [repository]
    );

    return [text, saveText, repository];
};

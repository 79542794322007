import { useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { Loading } from '@framework/ui/atoms/Loading';
import { Link } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';
import { toast } from 'react-hot-toast';
import { randInt } from '@framework/index';

const animalNames = [
    'イヌ Dog',
    'ウシ Cow',
    'ネコ Cat',
    'ウマ Horse',
    'ロバ Donkey',
    'トラ Tiger',
    'ライオン Lion',
    'ヒョウ Panther',
    'チーター Cheetah',
    'クマ Bear',
    'ゾウ Elephant',
    'クマ Bear',
    'カメ Turtle',
    'ワニ Crocodile',
    'ウサギ Rabbit',
    'ヤマアラシ Porcupine',
    'ウサギ Hare',
    'ハト Pigeon',
    'アホウドリ Albatross',
    'イルカ Dolphin',
    'カエル Frog',
    'クジラ Whale',
    'ワニ Alligator',
    'ワシ Eagle',
    'ダチョウ Ostrich',
    'キツネ Fox',
    'ヤギ Goat',
    'ジャッカル Jackal',
    'エミュー Emu',
    'アルマジロ Armadillo',
    'ウナギ Eel',
    'ガチョウ Goose',
    'キツネ Fox',
    'オオカミ Wolf',
    'ビーグル Beagle',
    'ゴリラ Gorilla',
    'チンパンジー Chimpanzee',
    'サル Monkey',
    'ビーバー Beaver',
    'コウモリ Bat',
    'アナグマ Badger',
    'キリン Giraffe',
    'パンダ Panda',
    'ハムスター Hamster',
    'コブラ Cobra',
    'ドラゴン Dragon',
    'ラクダ Camel',
    'タカ Hawk',
    'シカ Deer',
    'カメレオン Chameleon',
    'カバ Hippopotamus',
    'ジャガー Jaguar',
    'チワワ Chihuahua',
    'リザード Lizard',
    'コアラ Koala',
    'カンガルー Kangaroo',
    'イグアナ Iguana',
    'ラマ Llama',
    'チンチラ Chinchillas',
    'ドードー Dodo',
    'クラゲ Jellyfish',
    'サイ Rhinoceros',
    'ヘッジホッグ Hedgehog',
    'シマウマ Zebra',
    'ポッサム Possum',
    'ウォンバット Wombat',
    'バイソン Bison',
    'ブル Bull',
    'バッファロー Buffalo',
    'シープ Sheep',
    'ミーアキャット Meerkat',
    'ネズミ Mouse',
    'カワウソ Otter',
    'ナマケモノ Sloth',
    'フクロウ Owl',
    'ハゲタカ Vulture',
    'フラミンゴ Flamingo',
    'アライグマ Racoon',
    'モグラ Mole',
    'アヒル Duck',
    'ハクチョウ Swan',
    'オオヤマネコ Lynx',
    'ヘラジカ Elk',
    'イノシシ Boar',
    'キツネザル Lemur',
    'ミュール Mule',
    'マンモス Mammoth',
    'クジラ whale',
    'ネズミ Rat',
    'ヘビ Snake',
    'クジャク Peacock',
];

type Props = {
    isOpen: boolean;
    onClose(): void;
    title?: string;
    shouldCloseOnOverlayClick?: boolean;
};
export const LoginModal: React.FC<Props> = ({ isOpen, onClose, title, shouldCloseOnOverlayClick }: Props) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [name, setName] = useState<string>(animalNames[randInt(animalNames.length - 1)]);

    const handleClickAnonymousLogin = () => {
        if (name === '') return;

        setProcessing(true);

        FunctionsUserActions.anonymousLogin(name)
            .then((token) => {
                return FirebaseAuthenticationClient.signInWithCustomToken(token);
            })
            .catch(() => toast.error('処理に失敗しました。もう一度試してみてください。'))
            .finally(() => setProcessing(false));
    };

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            title={title || 'Balusにログイン | Log in to Balus'}
            submitButton={<></>}
            cancelButton={<></>}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            <div className="flex flex-col items-center justify-center p-4" style={{ width: '600px' }}>
                {processing ? (
                    <Loading horizontal={true} />
                ) : (
                    <>
                        <div className="py-2 text-center text-lg font-bold">ユーザ登録せずに使う | Guest to enter</div>
                        <div className="w-80 py-2 text-lg">
                            <input
                                type="text"
                                className="w-full rounded border px-3 py-2"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </div>
                        <Button color="brand" onClick={handleClickAnonymousLogin} disabled={name === ''}>
                            <div className="px-2">開始 | Start</div>
                        </Button>

                        <div className="py-4 text-center text-base hover:underline">
                            <Link to={UserPagePathBuilder.loginPage(window.location.pathname + window.location.search)}>
                                Balusにログイン | Log in to Balus
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </GeneralModal>
    );
};

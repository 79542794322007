const KIND_ID_SEPARATOR = ':';
const PAIR_SEPARATOR = '/';
const digitRegexp = /^\d+$/;
const isDigit = function (s: string) {
    return s.match(digitRegexp);
};
type Kind = string;
export type Id = string;
type Pair = [Kind, Id];

export class Key {
    protected readonly pairList: Pair[];
    private readonly lastPair: Pair;
    private readonly keyString: string;
    /**
     * null 以外の string List を指定すると
     * i 番目の kind が同じ文字列でない限りエラーになる。
     * なんでもいい場合は '*' を返すこと。
     *
     * e.g.)
     *   ['Hoge', 'Fuga']
     *   => 'Hoge:1/Fuga/3' // OK
     *   ['Hoge', 'Fuga']
     *   => 'Hoge:1/Fuga/3/Moge:4' // NG
     *   ['Hoge', '*']
     *   => 'Hoge:2/Hagi:4' // OK
     */
    protected get KINDS(): Kind[] | null {
        return null;
    }

    public constructor(key: string | Pair[]) {
        if (typeof key == 'string') {
            this.pairList = this.keyString2pairList(key);
            this.keyString = key;
        } else {
            this.pairList = key;
            this.keyString = Key.pairList2key(key);
        }

        if (this.pairList.length == 0) {
            throw new Error(`Invalid key: ${key}`);
        }

        this.lastPair = this.pairList[this.pairList.length - 1];

        if (!this.validateKeyRoot()) {
            throw new Error(`Invalid key: ${key}`);
        }
    }

    protected validateKeyRoot(): boolean {
        if (!this.validateKinds()) {
            return false;
        }

        return this.validateKey();
    }

    protected validateKey(): boolean {
        return true;
    }

    protected validateKinds(): boolean {
        if (!this.KINDS) {
            return true;
        }

        if (this.KINDS.length != this.pairList.length) {
            return false;
        }

        let valid = true;

        this.KINDS.forEach((kind, index) => {
            if (kind != '*' && this.pairList[index][0] != kind) {
                valid = false;
            }
        });

        return valid;
    }

    private keyString2pairList(keyString: string): Pair[] {
        if (keyString.indexOf(KIND_ID_SEPARATOR) < 0) {
            throw new Error(`Invalid Key String: ${keyString}`);
        }

        return keyString.split(PAIR_SEPARATOR).map((pairString) => {
            const pair = pairString.split(KIND_ID_SEPARATOR);
            if (pair.length != 2) {
                throw new Error(`Invalid Pair: '${pairString}' -- keyString=${keyString}`);
            }

            const kind = pair[0];
            let id: number | string;

            if (isDigit(pair[1])) {
                id = parseInt(pair[1]);
            } else {
                id = pair[1];
            }

            return [kind, id] as Pair;
        });
    }

    protected static pairList2key(pairList: Pair[]) {
        return pairList.map((p) => `${p[0]}:${p[1]}`).join('/');
    }

    public get id(): Id {
        return this.lastPair && this.lastPair[1];
    }

    public get kind(): Kind {
        return this.lastPair && this.lastPair[0];
    }

    public get parent(): Key | null {
        const initialPairs = this.pairList.slice(0, Math.max(0, this.pairList.length - 1));
        return initialPairs.length > 0 ? new Key(initialPairs) : null;
    }

    public isEqual(other: Key): boolean {
        return other instanceof Key && this.toString() === other.toString();
    }

    public toString(): string {
        return this.keyString;
    }

    public toUrlSafeString(): string {
        return encodeURIComponent(this.toString());
    }
}

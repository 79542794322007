type ErrorReason = 'notFound' | 'expired' | 'inactive' | 'signUpMethodNotFound';

const MESSAGE_TABLE: Record<ErrorReason, [string, string]> = {
    expired: ['招待の有効期限が切れています。', '招待の送信者にもう一度招待を依頼してください。'],
    inactive: ['招待が無効になっています。', '招待の送信者にもう一度招待を依頼してください。'],
    notFound: ['招待が見つかりませんでした。', '招待URLが途中で途切れていないか、もう一度ご確認ください。'],
    signUpMethodNotFound: [
        '有効なサインアップ方式が見つかりませんでした。',
        '時間をおいてからアクセスするか、それでも解消しない場合はサポート担当者にお問合せください。',
    ],
};

type Props = {
    errorReason: ErrorReason;
};

/**
 * グループメンバーへの招待情報が見つからない場合・期限切れ等の場合に表示する内容
 */
export const ErrorMessage: React.FC<Props> = ({ errorReason }: Props) => {
    const [reason, message] = MESSAGE_TABLE[errorReason];

    return (
        <>
            <div className="text-2xl font-bold">{reason}</div>
            <div className="pt-4">{message}</div>
        </>
    );
};

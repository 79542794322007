import { Key } from '@framework/domain';
import { EditingUser } from '@model-framework/text';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { selectAtom } from 'jotai/utils';
import { EditingUsers, editingUsersAtom } from './editingUsersAtom';
import { atom } from 'jotai/vanilla';

const nullAtom = atom(null);

// ノードを編集しているユーザを取得する
export const useNodeEditingUser = (key: Key | null | undefined): EditingUser | null => {
    const keyString = key?.toUrlSafeString() ?? null;
    const editingUser = useAtomValue(
        useMemo(
            () =>
                keyString === null
                    ? nullAtom // keyがnullの場合はnullを保持するatomを返す
                    : selectAtom<EditingUsers, EditingUser | null>(editingUsersAtom, (state) => {
                          return state[keyString] ?? null;
                      }),
            [keyString]
        )
    );

    return editingUser;
};

import { NodeId, StickyZoneId } from '@schema-common/base';
import { IOrderRollback } from './IOrderRollback';
import { ICommand } from '@model-framework/command';
import { ChangeParentCommand } from '@model-framework/display-order/domain/ChangeParentCommand';
import { DisplayOrderTree } from './DisplayOrderTree';
import { DisplayOrderRepository } from '@model-framework/display-order/infrastructure';

/**
 * ドラッグ中の要素。
 * ゾーンへのドロップ処理（ドロップ可能かどうかの見た目変更や親子関係操作）のため、ドロップ先のゾーンの情報も持つ。
 */
export class DraggingElements {
    constructor(
        private readonly draggingIds: (NodeId | StickyZoneId)[],
        private readonly droppingTargetZoneId: StickyZoneId | null // ドロップ先がない場合はnull
    ) {}

    isDroppingTo(zoneId: StickyZoneId): boolean {
        return this.droppingTargetZoneId !== null && this.droppingTargetZoneId === zoneId;
    }

    /**
     * ゾーンに対してドロップするかどうかを返します。
     */
    isDroppingToZone(): boolean {
        return !!this.droppingTargetZoneId;
    }

    /**
     * ドロップ先へのドロップ処理を実行して、ドラッグ中要素の親を変更する。
     * ドロップ先ゾーンがない場合はゾーン解除する。
     *
     * @param displayOrderRepository
     */
    applyChangeParent(displayOrderRepository: DisplayOrderRepository): Promise<IOrderRollback> {
        return displayOrderRepository.changeParentZone(this.draggingIds, this.droppingTargetZoneId);
    }

    /**
     * applyChangeParent() をした場合に親子関係の変更が起きるかどうかを返します。
     * @param displayOrderRepository
     */
    async willChangeParent(displayOrderRepository: DisplayOrderRepository): Promise<boolean> {
        const { draggingIds, droppingTargetZoneId } = this;
        return displayOrderRepository.willChangeParent(draggingIds, droppingTargetZoneId);
    }

    /**
     * このインスタンスのドラッグ＆ドロップ情報を元にChangeParentCommandを生成します。
     * ドラッグ中要素がない場合はコマンドを生成せずにnullを返します。
     * @param displayOrderRepository
     */
    buildChangeParentCommand(displayOrderRepository: DisplayOrderRepository): ICommand | null {
        if (this.draggingIds.length === 0) return null;

        return new ChangeParentCommand(this, displayOrderRepository);
    }

    /**
     * ドラッグ中要素とその子孫要素を返します。
     */
    draggingElementAndDescendantIds(displayOrderTree: DisplayOrderTree): (NodeId | StickyZoneId)[] {
        return displayOrderTree.aggregateDescendantIds(this.draggingIds);
    }

    /**
     * ドロップ対象要素とその祖先要素のリストを返します。ドロップ対象がない場合は空リストを返します。
     */
    dropTargetAndAncestorIds(displayOrderTree: DisplayOrderTree): (NodeId | StickyZoneId)[] {
        if (!this.droppingTargetZoneId) return [];

        return [this.droppingTargetZoneId, ...displayOrderTree.ancestors(this.droppingTargetZoneId)];
    }
}

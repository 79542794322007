import { ViewModelEntity } from '@view-model/domain/view-model';
import { ViewModelId } from '@schema-common/base';

export class ViewModelCollection {
    private readonly ids: Readonly<ViewModelId[]>;
    private readonly items: Readonly<Record<ViewModelId, ViewModelEntity>>;

    constructor(viewModels: ViewModelEntity[]) {
        this.items = viewModels.reduce(
            (result, viewModel) => {
                result[viewModel.id] = viewModel;
                return result;
            },
            {} as Record<ViewModelId, ViewModelEntity>
        );
        this.ids = Object.values(this.items)
            .sort(ViewModelEntity.compare)
            .map(({ id }) => id);
    }

    static buildEmpty(): ViewModelCollection {
        return new ViewModelCollection([]);
    }

    count(): number {
        return this.ids.length;
    }

    all(): ViewModelEntity[] {
        return this.ids.map((id) => this.items[id]);
    }

    allIds(): ViewModelId[] {
        return this.ids.concat();
    }

    get(viewModelId: ViewModelId): ViewModelEntity | undefined {
        return this.items[viewModelId];
    }

    add(viewModel: ViewModelEntity): ViewModelCollection {
        if (this.get(viewModel.id)) {
            console.warn(`ViewModelEntity: ${viewModel.id} is already added.`);
        }

        return new ViewModelCollection(this.all().concat(viewModel));
    }

    update(viewModel: ViewModelEntity): ViewModelCollection {
        if (!this.get(viewModel.id)) {
            console.warn(`ViewModelEntity: ${viewModel.id} is not found but updated.`);
        }

        return this.remove(viewModel.id).add(viewModel);
    }

    remove(viewModelId: ViewModelId): ViewModelCollection {
        return this.filter(({ id }) => id !== viewModelId);
    }

    filter(cb: (entity: ViewModelEntity, index: number) => boolean): ViewModelCollection {
        return new ViewModelCollection(this.all().filter(cb));
    }

    filterByIds(ids: ViewModelId[]): ViewModelCollection {
        return this.filter(({ id }) => ids.includes(id));
    }

    filterByQuery(query: string): ViewModelCollection {
        return this.filter((x) => x.matchQuery(query));
    }
}

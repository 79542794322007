import { ModelCommentThread, ModelCommentEntity } from '../domain';
import { Point, RectSizeCollection, Size } from '@view-model/models/common/basic';
import { ModelCommentId } from '@schema-common/base';

/*
 * 「コメントの順番を知っているもの(commentThread)」と「各コメントのサイズを知っているもの(sizes)」を照らし合わせ、
 * 「各コメントがコメントスレッド中のどの位置に表示されるべきか」のマッピングを行うためのクラスです
 */
export class CommentPositionMap {
    constructor(
        private readonly commentThread: ModelCommentThread,
        private readonly sizes: Readonly<Record<ModelCommentId, Size>>
    ) {}

    /*
     * 「引数に与えたコメントIDのコメントが表示されるべき位置」の座標値を返します
     * ただし、存在しないコメントIDが渡されたときは(0,0)の座標値を返します
     */
    getPosition(id: ModelCommentId): Point {
        if (this.sizes[id] === undefined) {
            return new Point(0, 0);
        }
        return new Point(0, this.getStackedTotalSize(id).height);
    }

    /*
     * 引数に与えたコメントIDまでのコメントを順番に積み上げたときの総サイズを返します
     */
    private getStackedTotalSize(id: ModelCommentId): Size {
        return this.getStackedSizes(id).getTotalSize();
    }

    /*
     * 引数に与えたコメントIDまでのコメントを順番に積み上げたときのサイズ集合を返します
     */
    private getStackedSizes(id: ModelCommentId): RectSizeCollection {
        const index = this.getCommentIndex(id);
        const sizes = this.getAllComments()
            .slice(0, index)
            .map((comment) => this.sizes[comment.id]);
        return new RectSizeCollection(sizes, 'column');
    }

    /*
     * 引数に与えたコメントIDのコメントが、プライマリコメントも含めて上から何番目かを返します（0オリジン）
     */
    private getCommentIndex(id: ModelCommentId): number {
        return this.getAllComments().findIndex((comment) => {
            return id === comment.id;
        });
    }

    /*
     * プライマリコメントとリプライコメントを合わせたコメント全体を配列として返します
     */
    private getAllComments(): ModelCommentEntity[] {
        return [this.commentThread.getPrimaryComment(), ...this.commentThread.getReplyComments()];
    }
}

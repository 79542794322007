import { RectShape } from '@model-framework/shape';

type Props = {
    shape: RectShape;
    margin: number;
};

export const NodeSelected: React.FC<Props> = ({ shape, margin }: Props) => {
    return (
        <g transform={`translate(${-margin}, ${-margin})`}>
            {shape.resize({ margin }).render({
                fillOpacity: 0,
                strokeWidth: 5,
                pointerEvents: 'none',
                className: 'stroke-brand fill-black',
            })}
        </g>
    );
};

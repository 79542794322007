import { useCallback, useEffect, useRef, useState } from 'react';
import { NodeFontSize, NodeName, NodeStyle } from '../../domain';
import { ThemeColor } from '@view-model/models/common/color';
import { useD3DblClickCallback, useTextSelectable } from '@view-model/models/common/hooks';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';
import { useFontSizeAdjustTextArea } from '@model-framework/text/useFontSizeAdjustTextArea';
import { DisplayFontSize } from '@model-framework/text/DisplayFontSize';
import { classNames } from '@framework/utils';
import { FontSize } from '@model-framework/text';

type Props = {
    width: number;
    height: number;
    isMyEditing: boolean;
    otherUserEditing: boolean;
    name: NodeName;
    themeColor: ThemeColor;
    fontSize: NodeFontSize;
    isHyperLink: boolean;
    onStartEdit(): void;
    onChange(name: NodeName): void;
    onEndEdit(name: NodeName): void;
    onChangeFontSize(fontSize: FontSize | NodeFontSize): void;
};

/**
 * 付箋ノードのテキストエリア・テキスト表示を担うコンポーネント。
 *
 * 付箋へのテキスト入力まわりに手を入れる際には、以下の点も考慮して動作確認をお願いします。
 *   * （アルファベットだけでなく）日本語入力が問題なく行えること
 *   * 入力中の文章が長くなったときに、フォントサイズ変更が行われた場合にも、継続して日本語入力が行えること
 *
 * 過去の関連する不具合
 *   * https://github.com/levii/balus-app/issues/1744
 */
export const NodeTextarea: React.FC<Props> = ({
    width,
    height,
    isMyEditing,
    otherUserEditing,
    name,
    themeColor,
    fontSize,
    isHyperLink,
    onStartEdit,
    onChange,
    onEndEdit,
    onChangeFontSize,
}: Props) => {
    const containerRef = useRef<SVGGElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const {
        textareaRef,
        hiddenTextareaRef,
        textareaHeight,
        displayFontSize,
        hiddenFontSize,
        handleTextChanged,
        handleFocus,
    } = useFontSizeAdjustTextArea(name.value, fontSize, height, isMyEditing, onChangeFontSize);

    const prevIsMyEditing = usePrevious(isMyEditing);
    const [editingName, setEditingName] = useState<string>('');

    // コンテナ要素のダブルクリック時に onStartEdit() をコールする (& イベントのバブリングを抑止する)
    useD3DblClickCallback(containerRef, () => onStartEdit(), true);

    // テキストエリアのテキストをマウスドラッグで選択可能にする
    useTextSelectable(textareaRef);

    // 非編集状態から、編集状態に遷移したときには、 textarea にフォーカスをあてる
    useEffect(() => {
        if (isMyEditing && !prevIsMyEditing && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [isMyEditing, prevIsMyEditing, textareaRef]);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            const withMetaKey = event.ctrlKey || event.metaKey;

            // テキストエリアでの MetaKey(Ctrl/Cmd) + Enter でフォーカスを外して(blur)、編集内容を確定する。
            if (event.key == 'Enter' && withMetaKey) {
                textareaRef.current?.blur();
            }

            // テキストエリアでの Esc キーでフォーカスを外す。
            // mousetrapだとtextarea内のイベントは補足しないためここで処理する
            if (event.key === 'Escape') {
                textareaRef.current?.blur();
            }
        },
        [textareaRef]
    );

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        handleTextChanged(event);
        const value = event.target.value;
        const newText = new NodeName(value);
        setEditingName(value);
        onChange(newText);
    };

    const selectionBgColor = NodeStyle.getStyles(themeColor).selection?.backgroundColor;

    useEffect(() => {
        // 自分が編集中でない場合にだけ、 props で渡された name.value を editingName に更新する
        // （自分が編集中の場合には、表示中のテキストエリアの onChange で更新される）
        //
        // 付箋へのテキスト入力時に、日本語入力の途中でフォントサイズが変更されたときに、
        // 入力途中の文字列が強制的に変換確定されるような挙動を回避するために、以下の if 分岐を追加している。
        // 以下の関連Issueも参照のこと:
        // - https://github.com/levii/balus-app/pull/1773
        // - https://github.com/levii/balus-app/issues/1744
        if (!isMyEditing) {
            setEditingName(name.value);
        }
    }, [isMyEditing, name]);

    // textarea からフォーカスが外れたとき、編集内容を確定する
    const handleBlur = useCallback(() => {
        const newText = new NodeName(editingName);
        onEndEdit(newText);
    }, [editingName, onEndEdit]);

    return (
        <>
            {/* フォントサイズ調整に使うため、裏に非表示のテキスト領域を重ねる */}
            <g ref={containerRef} width={width} height={height}>
                <foreignObject
                    width={width}
                    height={height}
                    visibility="hidden"
                    style={{ fontSize: DisplayFontSize.getFontSize(hiddenFontSize) }}
                >
                    <div className="flex size-full cursor-move items-center justify-center">
                        <textarea
                            ref={hiddenTextareaRef}
                            className={classNames(
                                selectionBgColor,
                                'hidden-scrollbar m-2 resize-none whitespace-break-spaces break-all border-none bg-transparent text-center align-middle font-bold outline-none'
                            )}
                            value={editingName}
                            rows={1}
                            readOnly={true}
                            style={{
                                display: !isMyEditing ? 'none' : 'block',
                                width: 'calc(100% - 16px)',
                                height: textareaHeight,
                            }}
                        />
                    </div>
                </foreignObject>
            </g>
            <g ref={containerRef} width={width} height={height}>
                <foreignObject
                    width={width}
                    height={height}
                    style={{ fontSize: DisplayFontSize.getFontSize(displayFontSize) }}
                >
                    <div className="flex size-full cursor-move select-none items-center justify-center">
                        <textarea
                            ref={textareaRef}
                            className={classNames(
                                selectionBgColor,
                                'hidden-scrollbar m-2 resize-none whitespace-break-spaces break-all border-none bg-transparent text-center align-middle font-bold outline-none'
                            )}
                            value={editingName}
                            rows={1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            onKeyDown={handleKeyDown}
                            style={{
                                display: !isMyEditing ? 'none' : 'block',
                                width: 'calc(100% - 16px)',
                                height: textareaHeight,
                            }}
                        />
                        <div
                            ref={textRef}
                            className={
                                isHyperLink
                                    ? 'm-2 items-center justify-center whitespace-break-spaces break-all text-center font-bold text-brand underline'
                                    : 'm-2 items-center justify-center whitespace-break-spaces break-all text-center font-bold text-black no-underline'
                            }
                            style={{
                                display: isMyEditing ? 'none' : 'flex',
                                cursor: !otherUserEditing ? 'move' : 'not-allowed',
                                width: 'calc(100% - 16px)',
                            }}
                        >
                            {name.value}
                        </div>
                    </div>
                </foreignObject>
            </g>
        </>
    );
};

import { Key } from '@framework/domain';
import { DescriptionPanelId } from '@schema-common/base';

export class DescriptionPanelEditLockKey extends Key {
    protected get KINDS(): string[] {
        return ['DescriptionPanelEditLock'];
    }

    public static fromId(id: DescriptionPanelId): DescriptionPanelEditLockKey {
        return new DescriptionPanelEditLockKey(`DescriptionPanelEditLock:${id}`);
    }
}

type Props = unknown;

export const EmptyMessage: React.FC<Props> = () => {
    return (
        <div className="flex h-48 w-full items-center justify-center rounded-lg bg-gray-200">
            <div className="flex flex-col">
                <span className="justify-center">ビューモデルが見つかりませんでした</span>
            </div>
        </div>
    );
};

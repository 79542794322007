export class ViewName {
    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public static load(name: string): ViewName {
        return new ViewName(name);
    }

    public trim(): ViewName {
        return new ViewName(this.value.trim());
    }

    public isEquals(other: ViewName): boolean {
        return other instanceof ViewName && other.value == this.value;
    }

    public buildCopyName(): ViewName {
        if (this.value == '') {
            return new ViewName(this.value);
        }

        return new ViewName(`${this.value} のコピー`);
    }

    public toReadableName(): string {
        return this.value == '' ? '名称未設定のビュー' : this.value;
    }
}

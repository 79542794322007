import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

type Props = {
    onClick: () => void;
};

export const SidebarOpenButton: React.FC<Props> = ({ onClick }: Props) => {
    return (
        <button className="size-full focus:outline-none" onClick={onClick} aria-label="サイドバーを開く">
            <FontAwesomeIcon icon={faAngleDoubleRight} />
        </button>
    );
};

import { useCallback, useState } from 'react';
import { ModelId } from '@schema-common/base';
import { StickyModelContentsOperation } from '@view-model/adapter';
import { CreatingModelComment, ModelCommentAuthor } from '@view-model/models/sticky/ModelComment';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';
import { Point } from '@view-model/models/common/basic';

export const useCreatingComments = (
    operations: Record<ModelId, StickyModelContentsOperation>
): {
    creatingComments: Record<ModelId, CreatingModelComment>;
    handleCreatingCommentAdd(modelId: ModelId, position: Point): void;
    handleCreatingCommentDrag(modelId: ModelId, dx: number, dy: number): void;
    handleCreatingCommentDragEnd(modelId: ModelId): void;
    handleCreatingCommentCancel(modelId: ModelId): void;
    handleCreatingCommentSubmit(modelId: ModelId, creatingComment: CreatingModelComment): void;
} => {
    const currentProfile = useCurrentUserPublicProfile();
    const [creatingComments, setCreatingComments] = useState<Record<ModelId, CreatingModelComment>>({});
    const handleCreatingCommentAdd = useCallback(
        (modelId: ModelId, position: Point) => {
            if (creatingComments[modelId]) return;

            if (!currentProfile) return;

            const author = ModelCommentAuthor.buildFromUser(currentProfile);
            const newComment = CreatingModelComment.buildNew(author, position);

            setCreatingComments((prevState) => {
                return { ...prevState, [modelId]: newComment };
            });
        },
        [currentProfile, creatingComments]
    );

    const handleCreatingCommentDrag = useCallback(
        (modelId: ModelId, dx: number, dy: number) => {
            setCreatingComments((prevState) => {
                const comment = creatingComments[modelId];
                if (!comment) return prevState;

                return { ...prevState, [modelId]: comment.move(dx, dy) };
            });
        },
        [creatingComments]
    );

    const handleCreatingCommentDragEnd = useCallback(
        (modelId: ModelId) => {
            setCreatingComments((prevState) => {
                const comment = creatingComments[modelId];
                if (!comment) return prevState;

                return { ...prevState, [modelId]: comment.snapLayout(ModelLayout) };
            });
        },
        [creatingComments]
    );

    const handleCreatingCommentCancel = useCallback((modelId: ModelId) => {
        setCreatingComments((prevState) => {
            const { [modelId]: _removed, ...newState } = prevState;

            return newState;
        });
    }, []);

    const handleCreatingCommentSubmit = useCallback(
        (modelId: ModelId, creatingComment: CreatingModelComment) => {
            setCreatingComments((prevState) => {
                const { [modelId]: _removed, ...newState } = prevState;
                return newState;
            });

            const operation = operations[modelId];
            if (!operation) return;

            operation.createModelCommentThread(creatingComment).then();
        },
        [operations]
    );

    return {
        creatingComments,
        handleCreatingCommentAdd,
        handleCreatingCommentDrag,
        handleCreatingCommentDragEnd,
        handleCreatingCommentCancel,
        handleCreatingCommentSubmit,
    };
};

import { AuthSessionId, UserId, UserKeyString } from '@schema-common/base';
import firebase from 'firebase/compat/app';

// Cloud Functions 側の `createCustomToken()` で付与されるカスタムクレームと同じものを定義する
// https://github.com/levii/balus-app/blob/main/functions/src/user/utils/createCustomToken.ts
type Claims = {
    userId: UserId;
    userKey: UserKeyString;
    userType: 'regular' | 'anonymous';
    sessionId: AuthSessionId | null;
};

export class FirebaseAuthenticationClient {
    static async signInWithCustomToken(token: string): Promise<void> {
        await firebase.auth().signInWithCustomToken(token);
    }

    static async signInWithPopupToGoogle(): Promise<void> {
        const provider = new firebase.auth.GoogleAuthProvider();
        await firebase.auth().signInWithPopup(provider);
    }

    static async signOut(): Promise<void> {
        await firebase.auth().signOut();
    }

    static async getIdToken(): Promise<string | null> {
        const token = await firebase.auth().currentUser?.getIdToken();
        return token || null;
    }

    static async getIdTokenResult(forceRefresh?: boolean): Promise<firebase.auth.IdTokenResult | null> {
        const result = await firebase.auth().currentUser?.getIdTokenResult(forceRefresh);
        return result || null;
    }

    static async getClaims(): Promise<Claims | null> {
        const token = await this.getIdTokenResult();
        if (!token) return null;

        return token.claims as Claims;
    }

    static onAuthStateChanged(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        nextOrObserver: (a: firebase.User | null) => any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error?: (a: firebase.auth.Error) => any,
        completed?: firebase.Unsubscribe
    ): firebase.Unsubscribe {
        return firebase.auth().onAuthStateChanged(nextOrObserver, error, completed);
    }

    static onIdTokenChanged(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        nextOrObserver: (a: firebase.User | null) => any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error?: (a: firebase.auth.Error) => any,
        completed?: firebase.Unsubscribe
    ): firebase.Unsubscribe {
        return firebase.auth().onIdTokenChanged(nextOrObserver, error, completed);
    }
}

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { NodeCreatableTarget } from './NodeCreatableTarget';
import { Rect } from '@view-model/models/common/basic/Rect';
import { useD3DblClickCallback } from '@view-model/models/common/hooks';
import { D3Mouse } from '@view-model/models/common/d3/D3Mouse';
import { HandleD3Drag } from '@view-model/models/common/d3/HandleD3Drag';
import { D3DragEvent } from 'd3-drag';
import { MultiSelectionMode } from '@user/pages/ViewModelPage';
import { selectAtom } from 'jotai/utils';
import { dropTargetViewAtom } from '@view-model/adapter/dropTargetViewAtom';
import { useAtomValue } from 'jotai';
import { ViewId } from '@schema-common/base';

type Size = {
    width: number;
    height: number;
};

type Position = {
    x: number;
    y: number;
};

type Props = {
    viewId: ViewId;
    size: Size;
    onClickCanvas(): void;
    onDblClickCanvas(position: Position): void;
    onRectSelection(rect: Rect): void;
    onRectSelectionEnd(rect: Rect): void;
    isMultiSelectionMode: boolean;
    setMultiSelectionMode(multiSelectionMode: MultiSelectionMode): void;
    onSelectSingleView(): void;
};

type PointPair = {
    start: Position | null;
    end: Position | null;
};

export const ModelCanvas: React.FC<Props> = ({
    viewId,
    size,
    onClickCanvas,
    onDblClickCanvas,
    onRectSelection,
    onRectSelectionEnd,
    isMultiSelectionMode,
    setMultiSelectionMode,
    onSelectSingleView,
}: Props) => {
    const myRef = useRef<SVGRectElement>(null);
    const pointsRef = useRef<PointPair>({ start: null, end: null });
    const d3Mouse = useMemo(() => new D3Mouse(myRef), []);

    useD3DblClickCallback(
        myRef,
        useCallback(
            (event: MouseEvent) => {
                const target = event.target as HTMLElement;
                if (NodeCreatableTarget.isTarget(target)) {
                    const [x, y] = d3Mouse.getPosition(event);
                    onDblClickCanvas({ x, y });
                }
            },
            [d3Mouse, onDblClickCanvas]
        ),
        // 本来はハンドルする場合だけ伝搬を止めればいいが、現状ではズームが発動するのでstopPropagationで伝搬を止める
        true
    );

    // 複数選択モードの時はドラッグによる矩形範囲選択を有効にする
    useEffect(() => {
        const onDragStart = function (event: D3DragEvent<Element, unknown, unknown>) {
            if (!isMultiSelectionMode) {
                return;
            }

            const [x, y] = d3Mouse.getPosition(event);
            pointsRef.current.start = { x, y };
        };

        const onDrag = function (event: D3DragEvent<Element, unknown, unknown>) {
            if (!isMultiSelectionMode) {
                return;
            }

            // ビューのキャンバスクリックではonDragStartも発火するので
            // ビュー内でドラッグが行われた段階で要素の複数選択モードに移行
            onSelectSingleView();
            setMultiSelectionMode(MultiSelectionMode.multiElementsSelectionMode);

            const [x, y] = d3Mouse.getPosition(event);
            pointsRef.current.end = { x, y };

            const { start, end } = pointsRef.current;
            if (start && end) {
                onRectSelection(Rect.fromPositions([start, end]));
            }
        };

        const onDragEnd = function () {
            const { start, end } = pointsRef.current;
            if (isMultiSelectionMode && start && end) {
                onRectSelectionEnd(Rect.fromPositions([start, end]));
            }
            pointsRef.current = { start: null, end: null };
        };

        const handleD3Drag = new HandleD3Drag(myRef, isMultiSelectionMode);
        handleD3Drag.onMounted(onDrag, onDragStart, onDragEnd);
        return () => {
            handleD3Drag.onWillUnmounted();
        };
    }, [
        size,
        isMultiSelectionMode,
        onSelectSingleView,
        setMultiSelectionMode,
        onRectSelectionEnd,
        d3Mouse,
        onRectSelection,
    ]);

    const isDropTargetAtom = useMemo(
        () =>
            selectAtom(dropTargetViewAtom, (dropTarget) => viewId === dropTarget?.viewId && !dropTarget?.stickyZoneId),
        [viewId]
    );
    const isDropTarget = useAtomValue(isDropTargetAtom);

    return (
        <g width={size.width} height={size.height}>
            {/* 土台となるキャンバス */}
            <rect
                className={NodeCreatableTarget.targetClassName}
                ref={myRef}
                width={size.width}
                height={size.height}
                rx={64}
                ry={64}
                onClick={onClickCanvas}
                fill={isDropTarget ? '#f5f5f5' : 'white'}
            />
        </g>
    );
};

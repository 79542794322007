import { ICommand } from './ICommand';
import { IComparable } from './IComparable';
import { compare } from './util';

interface IDeleteUndoableRepository<T> {
    save(value: T): Promise<void>;
    delete(): Promise<void>;
    get(): Promise<T | null>;
}

export class DeleteCommand<T extends IComparable<T> | boolean | number | string> implements ICommand {
    constructor(
        private readonly value: T,
        private readonly repository: IDeleteUndoableRepository<T>
    ) {}

    do(): void {
        this.repository.delete().then();
    }

    undo(): void {
        const { value, repository } = this;

        repository.save(value).then();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        const currentValue = await this.repository.get();
        return currentValue === null;
    }

    async canRedo(): Promise<boolean> {
        const { value, repository } = this;

        const currentValue = await repository.get();
        if (currentValue === null) return false;

        return compare(currentValue, value);
    }
}

type Props = { text: string; className: string; keyword: string };

export const HighlightText: React.FC<Props> = ({ className, text, keyword }: Props) => {
    const keywordRegex = new RegExp(`(${keyword})`, 'gi');
    if (!keyword) {
        return <span className={className}>{text}</span>;
    }

    return (
        <span className={className}>
            {text.split(keywordRegex).map((part, i) =>
                part.match(keywordRegex) ? (
                    <strong
                        key={i}
                        className="truncate"
                        style={{
                            textDecoration: 'underline',
                            textDecorationThickness: '0.5em',
                            textUnderlineOffset: '-0.2em',
                            textDecorationSkipInk: 'none',
                            textDecorationColor: 'rgba(246, 224, 94, 0.5)',
                        }}
                    >
                        {part}
                    </strong>
                ) : (
                    part
                )
            )}
        </span>
    );
};

export type SignUpMethodName = 'google' | 'quadcept' | 'email';

const dict: Record<SignUpMethodName, string> = {
    google: 'Googleアカウント',
    quadcept: 'Quadceptアカウント',
    email: 'メールアドレス・パスワード',
};

export namespace SignUpMethodName {
    export function values(): SignUpMethodName[] {
        return Object.keys(dict) as SignUpMethodName[];
    }

    export function getLocalString(methodName: SignUpMethodName): string {
        return dict[methodName];
    }
}

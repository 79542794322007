import { GroupMemberRoleJSON } from '@schema-common/group';

export type GroupMemberRole = 'admin' | 'member';
const FallbackRole = 'member'; // 未知の値の場合には、一番弱い権限のロールにフォールバックする

const dict: Record<GroupMemberRole, string> = {
    admin: '管理者',
    member: 'メンバー',
};

export namespace GroupMemberRole {
    export function values(): GroupMemberRole[] {
        return Object.keys(dict) as GroupMemberRole[];
    }

    export function load(value: string): GroupMemberRole {
        return GroupMemberRole.values().find((role) => role === value) || FallbackRole;
    }

    export function dump(role: GroupMemberRole): string {
        return role.toString();
    }

    export function isAdmin(role: string | undefined | null): boolean {
        return role === 'admin';
    }

    export function getLocalString(status: GroupMemberRole): string {
        return dict[status];
    }
}

export const isGroupMemberRole = (value: string): value is GroupMemberRoleJSON => {
    return value in dict;
};

import { ClipboardPayload } from '@model-framework/clipboard';
import { ViewJSON } from '@schema-app/view-model/contents/{viewModelId}/views/{viewId}/ViewJSON';
import { ConsistencyLinkCollectionJSON } from '@view-model/domain/ConsistencyLink';
import { StickyModelContentsJSON } from '@view-model/domain/model';
import { ViewModelContent } from '@view-model/domain/view-model';
import { nanoid } from 'nanoid';
import { PositionSetJSON } from '@view-model/models/common/PositionSet';
import { ViewModelId, WorkspaceId } from '@schema-common/base';

const ContentType = 'ViewModel';
const Target = 'ViewModel';

export type ViewModelClipboardPayload = ClipboardPayload & {
    application: 'Balus/Beta';
    target: typeof Target;
    contentType: typeof ContentType;
    id: string;
    sourceViewModelId: ViewModelId;
    sourceWorkspaceId: WorkspaceId;
    name: string;
    views: ViewJSON[];
    viewPositions: PositionSetJSON;
    consistencyLinks: ConsistencyLinkCollectionJSON;
    models: StickyModelContentsJSON[];
};

export namespace ViewModelClipboardPayload {
    export function fromContent(
        content: ViewModelContent,
        sourceWorkspaceId: WorkspaceId,
        sourceViewModelId: ViewModelId
    ): ViewModelClipboardPayload {
        return {
            application: 'Balus/Beta',
            target: Target,
            contentType: ContentType,
            id: `${nanoid()}`,
            sourceViewModelId,
            sourceWorkspaceId,
            ...content.dump(),
        };
    }

    export function toContent(payload: ViewModelClipboardPayload): ViewModelContent {
        return ViewModelContent.load({
            name: payload.name,
            views: payload.views,
            viewPositions: payload.viewPositions || {},
            consistencyLinks: payload.consistencyLinks,
            models: payload.models,
        });
    }
}

export const isViewModelClipboardPayload = (payload: ClipboardPayload): payload is ViewModelClipboardPayload =>
    payload.target === Target && payload.contentType === ContentType;

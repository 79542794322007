import { useEffect, useState } from 'react';
import { NodeStyle } from '../../domain';
import { NodeMenuId } from './NodeMenuId';
import { NodeMenuContent } from './NodeMenuContent';
import { MenuContainer } from '@model-framework/menu';
import { MenuSize } from '@view-model/models/sticky/ui';
import { ThemeColor } from '@view-model/models/common/color';
import { FontSize } from '@model-framework/text';
import { GroupId, UserId } from '@schema-common/base';
import { useGroupAnalysisSettingAvailability } from '@group/hooks/useGroupAnalysisSettingAvailability';
import { NodeReaction, ReactionType } from '@view-model/models/sticky/NodeReaction';
import { Point } from '@view-model/models/common/basic';

type Props = {
    groupId: GroupId;
    currentStyle: NodeStyle;
    offset: { x: number; y: number };
    textEditing: boolean;
    url: string | null;
    onFontSizeSelected(fontSize: FontSize): void;
    onThemeColorSelected(themeColor: ThemeColor): void;
    onDeleteNode(): void;
    onToggleNodeDescription(): void;
    onGroupSelectedNode(): void;
    onSaveHyperLink(url: string | null): void;
    onAnalysisSelected(): void;
    reaction: NodeReaction | null | undefined;
    toggleReaction: (reactionName: ReactionType, userId: UserId, userName: string) => void;
};

export const NodeMenu: React.FC<Props> = ({
    groupId,
    currentStyle,
    textEditing,
    url,
    offset,
    onFontSizeSelected,
    onThemeColorSelected,
    onDeleteNode,
    onToggleNodeDescription,
    onGroupSelectedNode,
    onSaveHyperLink,
    onAnalysisSelected,
    reaction,
    toggleReaction,
}: Props) => {
    const [openedMenu, setOpenedMenu] = useState<NodeMenuId | null>(null);

    useEffect(() => {
        if (textEditing) {
            setOpenedMenu(null);
        }
    }, [textEditing]);

    // 事前にフィルタしておかないとブランクのメニューができてしまうのでここでフィルタする
    const showAnalysisSelect = useGroupAnalysisSettingAvailability(groupId);
    const availableMenuIds = NodeMenuId.values().filter(
        (menuId) => showAnalysisSelect || menuId !== NodeMenuId.Analysis
    );

    const menuSize = { width: MenuSize * availableMenuIds.length, height: MenuSize };
    const position = new Point(offset.x, offset.y);

    return (
        <MenuContainer position={position} size={menuSize} positionOrigin="left-bottom">
            {availableMenuIds.map((menuId, i) => (
                <g key={menuId} transform={`translate(${MenuSize * i}, 0)`}>
                    <NodeMenuContent
                        menuId={menuId}
                        menuSize={MenuSize}
                        currentStyle={currentStyle}
                        isOpen={openedMenu === menuId}
                        url={url}
                        reaction={reaction}
                        onMenuOpen={() => setOpenedMenu(menuId)}
                        onMenuClose={() => setOpenedMenu(null)}
                        onFontSizeSelected={onFontSizeSelected}
                        onThemeColorSelected={onThemeColorSelected}
                        onDeleteNode={onDeleteNode}
                        onGroupSelectedNode={onGroupSelectedNode}
                        onToggleNodeDescription={onToggleNodeDescription}
                        onSaveHyperLink={onSaveHyperLink}
                        onAnalysisSelected={onAnalysisSelected}
                        onReactionSelected={toggleReaction}
                    />
                </g>
            ))}
        </MenuContainer>
    );
};

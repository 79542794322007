import { StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { StickyZone } from '@view-model/models/sticky/StickyZoneView';
import { LinkableTargetKey } from '@view-model/domain/key';

export class LinkableTargetCollection {
    private readonly models: Record<string, StickyNode | StickyZone>;

    constructor(nodes: StickyNode[], zones: StickyZone[]) {
        this.models = {};

        nodes.forEach((node) => {
            this.models[node.key.toString()] = node;
        });
        zones.forEach((zone) => {
            this.models[zone.key.toString()] = zone;
        });
    }

    includeKey(key: LinkableTargetKey): boolean {
        return !!this.models[key.toString()];
    }
}

import { useCallback } from 'react';
import { useRootFolderTreeRepository } from './useRootFolderTreeRepository';
import { useActionLogSender } from '@framework/action-log';
import { ViewModelId, FolderId } from '@schema-common/base';

type Handler = (viewModelId: ViewModelId, folderId: FolderId) => Promise<void>;

export const useMoveViewModel = (workspaceId: string): Handler => {
    const rootFolderTreeRepository = useRootFolderTreeRepository(workspaceId);
    const actionLogSender = useActionLogSender();

    return useCallback(
        async (viewModelId: ViewModelId, folderId: FolderId) => {
            await rootFolderTreeRepository.moveViewModel(viewModelId, folderId);

            actionLogSender('view_model:move', {
                viewModelId,
                folderId,
            });
        },
        [rootFolderTreeRepository, actionLogSender]
    );
};

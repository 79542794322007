import { v4 as uuidv4 } from 'uuid';
import { Timestamp } from '@framework/Timestamp';
import { ViewModelAssetJSON } from '@schema-app/view-model/contents/{viewModelId}/assets/{assetId}/ViewModelAssetJSON';
import { GroupId, UserId, ViewModelAssetId, ViewModelId } from '@schema-common/base';
import { ViewModelAssetURL } from '@user/pages/urls';
import { RTDBPath, RecordRepository, RefBuilder, ServerValue } from '@framework/repository';
import { StoragePath, StorageRefBuilder } from '@framework/firebase/storage';

const MaxFileSizeInMB = 10;
const MaxFileSize = MaxFileSizeInMB * 1024 * 1024;
const MaxAssetCount = 100;

export class ViewModelAsset {
    constructor(
        public readonly id: ViewModelAssetId,
        public readonly filename: string,
        public readonly fileSize: number,
        public readonly contentType: string,
        public readonly createdUserId: UserId,
        public readonly createdAt: Timestamp
    ) {}

    static load(dump: ViewModelAssetJSON): ViewModelAsset {
        const { id, fileSize, filename, contentType, createdAt, createdUserId } = dump;
        return new this(id, filename, fileSize, contentType, createdUserId, new Timestamp(createdAt));
    }

    dump(): ViewModelAssetJSON {
        const { id, fileSize, filename, contentType, createdAt, createdUserId } = this;
        return {
            id,
            filename,
            fileSize,
            contentType,
            createdUserId,
            createdAt: createdAt.toUnixTimestamp(),
        };
    }

    static buildFromFile(file: File, createdUserId: UserId): ViewModelAsset {
        const id = uuidv4();
        return new this(id, file.name, file.size, file.type, createdUserId, Timestamp.now());
    }

    /**
     * 引数に指定された blob オブジェクトを Cloud Storage にアップロードして、
     * この asset オブジェクトを Realtime Database に保存します。
     * 成功した場合には null を、アップロードできない場合にはエラー内容を文字列で返します。
     * @param groupId
     * @param viewModelId
     * @param blob
     * @returns
     */
    async upload(groupId: GroupId, viewModelId: ViewModelId, blob: Blob): Promise<null | string> {
        if (!this.contentType.startsWith('image/')) {
            return `アップロードできるのは画像ファイルのみです: ${this.filename}`;
        }

        if (this.fileSize >= MaxFileSize) {
            return `${MaxFileSizeInMB}MBを超えるファイルはアップロードできません: ${this.filename}`;
        }

        const assets = await new RecordRepository(
            ViewModelAsset,
            RTDBPath.ViewModel.Asset.assetsPath(viewModelId)
        ).get();

        if (Object.keys(assets).length >= MaxAssetCount) {
            return `1つのビューモデルに対してアップロード可能な画像の数(${MaxAssetCount}個)を超えているため、アップロードできません。`;
        }

        const databaseRef = RefBuilder.ref(RTDBPath.ViewModel.Asset.assetPath(viewModelId, this.id));
        const storageRef = StorageRefBuilder.ref(StoragePath.viewModelAssetPath(groupId, viewModelId, this.id));

        // Cloud Storage にアップロードして、RTDBにもメタ情報を保存する
        await storageRef.put(blob, {
            contentType: this.contentType,
            contentDisposition: `inline; filename*=utf-8''${encodeURIComponent(this.filename)}`,
            customMetadata: {
                createdUserId: this.createdUserId,
                originalFilename: this.filename,
            },
        });
        await databaseRef.set({
            ...this.dump(),
            createdAt: ServerValue.TIMESTAMP,
        });

        return null;
    }

    /**
     * ビューモデル添付画像のURLを組み立てて返します。
     * @param groupId グループID
     * @param viewModelId ビューモデルID
     * @param baseUrl URL組み立て時のベースとなるURL
     */
    url(groupId: string, viewModelId: string, baseUrl: string): string {
        return new ViewModelAssetURL(groupId, viewModelId, this.id).url(baseUrl);
    }

    /**
     * markdown のリンク要素のラベルに設定可能なエスケープ済みのファイル名を返します
     */
    private escapedFilename(): string {
        return this.filename.replace('[', '\\[').replace(']', '\\]').replace('(', '\\(').replace(')', '\\)');
    }

    /**
     * ビューモデル添付画像を埋め込むmarkdown記法を生成して返します。
     * @param groupId グループID
     * @param viewModelId ビューモデルID
     * @param baseUrl URL組み立て時のベースとなるURL
     */
    imageMarkdown(groupId: string, viewModelId: string, baseUrl: string): string {
        return `![${this.escapedFilename()}](${this.url(groupId, viewModelId, baseUrl)})`;
    }

    withCreatedUserId(createdUserId: UserId): ViewModelAsset {
        const { id, filename, fileSize, contentType } = this;
        return new ViewModelAsset(id, filename, fileSize, contentType, createdUserId, Timestamp.now());
    }
}

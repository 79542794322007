import { GroupJSON } from '@schema-app/groups/{groupKey}/GroupJSON';

type AnalysisSetting = { availableAnalysis: boolean };

const defaultAnalysisSetting: AnalysisSetting = {
    availableAnalysis: false,
};

export class GroupAnalysisSetting {
    constructor(private readonly value: AnalysisSetting) {}

    dump(): GroupJSON['setting']['analysisSetting'] {
        return { ...this.value };
    }

    static load(dump: GroupJSON['setting']['analysisSetting']): GroupAnalysisSetting {
        return new this({ ...dump });
    }

    static buildNew(): GroupAnalysisSetting {
        return new this(defaultAnalysisSetting);
    }

    isAnalysisAvailable(): boolean {
        return this.value['availableAnalysis'];
    }
}

import { useCallback } from 'react';
import { Rect } from '@view-model/models/common/basic';
import { Viewer } from '../Viewer';
import { generateDefaultUserIcon } from '@framework/utils';

type Props = {
    viewer: Viewer;
    setClientViewBox: (viewBox: Rect) => void;
};

export const ViewerPopupContent: React.FC<Props> = ({ viewer, setClientViewBox }: Props) => {
    const handleClick = useCallback(() => {
        setClientViewBox(viewer.rect);
    }, [setClientViewBox, viewer.rect]);

    return (
        <div
            className="block cursor-pointer py-2 pl-3 text-gray-800 hover:bg-indigo-500 hover:text-white"
            onClick={handleClick}
        >
            <div className="inline-block size-6 overflow-hidden rounded-full">
                {viewer.imageUrl ? (
                    <img className="size-full object-cover" src={viewer.imageUrl} alt={viewer.userName} />
                ) : (
                    <div className="size-full object-cover">
                        {generateDefaultUserIcon({
                            id: viewer.userId,
                            name: viewer.userName,
                            size: 24,
                        })}
                    </div>
                )}
            </div>
            <div className="inline-block h-6 w-40 truncate px-2 text-sm leading-6">{viewer.userName}</div>
        </div>
    );
};

import { useCallback } from 'react';
import { Point, Size } from '@view-model/models/common/basic';
import { ObjectRepository } from '@framework/repository';
import { PositionDelta } from '@view-model/models/common/types/ui';
import { CompositeCommand, UpdateCommand, useCommandManager } from '@model-framework/command';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { PointJSON, SizeJSON } from '@schema-common/view-model';

/**
 * 矩形要素のリサイズコマンドを発行するコールバックフック
 *
 * 矩形要素の大きさ・位置に変更があれば、更新するコマンドを発行する。
 *
 * @param sizeRepository
 * @param positionRepository
 * @param readonly
 *
 * @returns {Object} state フックの戻り値
 * @returns {Size} state.size 矩形要素のサイズ
 * @returns {function({dx, dy}, {width, height}): void} state.onResizeConfirmed 矩形要素のリサイズ・位置変更をトリガーする関数
 */
export const useResizeCommand = (
    sizeRepository: ObjectRepository<SizeJSON, Size>,
    positionRepository: ObjectRepository<PointJSON, Point>,
    readonly: boolean
): {
    onResizeConfirmed(positionDelta: PositionDelta, newSize: Size): void;
} => {
    const commandManager = useCommandManager();

    const onResizeConfirmed = useCallback(
        async ({ dx, dy }: PositionDelta, size: Size) => {
            if (readonly) return;

            const currentPosition = await positionRepository.get();
            const currentSize = await sizeRepository.get();

            if (!currentSize) return;
            if (!currentPosition) return;

            const newPosition = Point.fromPosition(ModelLayout.snapPosition(currentPosition.addXY(dx, dy)));
            const newSize = size.snapToLayout(ModelLayout);

            // レイアウトにスナップした後の位置、サイズともに変化が無ければコマンドとして記録しない
            if (currentPosition.isEqual(newPosition) && currentSize.isEqual(newSize)) {
                return;
            }

            const command = new CompositeCommand(
                new UpdateCommand(currentPosition, newPosition, positionRepository),
                new UpdateCommand(currentSize, newSize, sizeRepository)
            );
            commandManager.execute(command);
        },
        [commandManager, positionRepository, readonly, sizeRepository]
    );

    return { onResizeConfirmed };
};

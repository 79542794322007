import { Draggable } from './Draggable';

/**
 * ドラッグ中の状態管理クラス
 *
 * 特にドラッグ完了時の Undo/Redo 登録に利用できるよう初期状態も持っておく。
 */
export class DraggableStateManager<T extends Draggable<T>> {
    private initialState: T | null = null;
    private currentState: T | null = null;

    /**
     * ドラッグ開始処理。初期状態を与えてドラッグ終了時に取り出せるようにする。
     * @param initialState
     */
    dragStart(initialState: T): void {
        this.initialState = initialState;
        this.currentState = initialState;
    }

    /**
     * ドラッグによる移動。ドラッグ後の状態を返す。
     * @param dx
     * @param dy
     */
    drag(dx: number, dy: number): T {
        if (!this.currentState) {
            throw new Error('Invalid drag() call');
        }

        this.currentState = this.currentState.addXY(dx, dy);
        return this.currentState;
    }

    /**
     * ドラッグ終了時に呼び出すコールバック。初期状態と現在の状態のペアを返す。
     * @return [初期状態, 現在の状態]
     */
    dragEnd(): [T, T] {
        const { initialState, currentState } = this;
        if (!initialState || !currentState) {
            throw new Error('Invalid drag() call');
        }

        this.initialState = null;
        this.currentState = null;

        return [initialState, currentState];
    }
}

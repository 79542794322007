import { DescriptionPanelCollection, DescriptionPanelCollectionJSON } from './DescriptionPanelCollection';
import {
    IPositionSetRepository,
    PositionSet,
    PositionSetCreateCommand,
    PositionSetDeleteCommand,
    PositionSetJSON,
    PositionSetUpdateCommand,
} from '@view-model/models/common/PositionSet';
import { DescriptionPanel } from './DescriptionPanel';
import { DescriptionPanelId, ModelId, ViewModelId } from '@schema-common/base';
import { CommandHelper, CompositeCommand, ICommand } from '@model-framework/command';
import { Rect, Point, assertIsDefined, SelectedItemSet } from '@view-model/models/common/basic';
import { DescriptionPanelDisplayOrder } from '@view-model/models/sticky/DescriptionPanel';
import { ModelElementId } from '@view-model/domain/key';
import { RTDBPath } from '@framework/repository';
import { AssetUrlMap } from '@view-model/domain/view-model';

export type DescriptionPanelCollectionContentsJSON = {
    panels: DescriptionPanelCollectionJSON;
    positions: PositionSetJSON;
};

type LoadableContentsJSON = Partial<DescriptionPanelCollectionContentsJSON>;

export class DescriptionPanelCollectionContents {
    constructor(
        public readonly panels: DescriptionPanelCollection,
        public readonly positions: PositionSet
    ) {
        // 位置情報が存在する説明パネルのみを扱う
        this.panels = panels.filter((panel) => {
            return !!positions.find(panel.id);
        });
    }

    static buildEmpty(): DescriptionPanelCollectionContents {
        return new DescriptionPanelCollectionContents(
            DescriptionPanelCollection.buildEmpty(),
            PositionSet.buildEmpty()
        );
    }

    static load(dump: LoadableContentsJSON): DescriptionPanelCollectionContents {
        const { panels, positions } = dump;
        return new DescriptionPanelCollectionContents(
            DescriptionPanelCollection.load(panels || []),
            PositionSet.load(positions || {})
        );
    }

    isEqual(other: DescriptionPanelCollectionContents): boolean {
        return this.panels.isEqual(other.panels) && this.positions.isEqual(other.positions);
    }

    isEmpty(): boolean {
        return this.panels.count() === 0;
    }

    count(): number {
        return this.panels.count();
    }

    dump(): DescriptionPanelCollectionContentsJSON {
        const { panels, positions } = this;
        return {
            panels: panels.dump(),
            positions: positions.dump(),
        };
    }

    cloneNew(assetUrlMap: AssetUrlMap): DescriptionPanelCollectionContents {
        const [panels, newIdMap] = this.panels.cloneNew(assetUrlMap);
        return new DescriptionPanelCollectionContents(panels, this.positions.cloneNew(newIdMap));
    }

    filterByIds(ids: DescriptionPanelId[]): DescriptionPanelCollectionContents {
        const panels = this.panels.filter((panel) => ids.includes(panel.id));
        const positions = this.positions.subset(ids);
        return new DescriptionPanelCollectionContents(panels, positions);
    }

    addPanel(panel: DescriptionPanel): DescriptionPanelCollectionContents {
        return new DescriptionPanelCollectionContents(this.panels.add(panel), this.positions);
    }

    updatePanel(panel: DescriptionPanel): DescriptionPanelCollectionContents {
        return new DescriptionPanelCollectionContents(this.panels.update(panel), this.positions);
    }

    remove(panelId: DescriptionPanelId): DescriptionPanelCollectionContents {
        return new DescriptionPanelCollectionContents(this.panels.remove(panelId), this.positions.remove(panelId));
    }

    withPositions(positions: PositionSet): DescriptionPanelCollectionContents {
        return new DescriptionPanelCollectionContents(this.panels, positions);
    }

    move(dx: number, dy: number): DescriptionPanelCollectionContents {
        return new DescriptionPanelCollectionContents(this.panels, this.positions.moveAll(dx, dy));
    }

    allIds(): DescriptionPanelId[] {
        return this.panels.allIds();
    }

    getRect(panelId: DescriptionPanelId): Rect | undefined {
        const panel = this.panels.get(panelId);
        const position = this.positions.find(panelId);
        if (!panel || !position) return;

        return new Rect(position, panel.size);
    }

    /**
     * 指定の矩形領域に交差する説明パネルの集合を返します
     * @param rect
     */
    filterByRectSelection(rect: Rect): DescriptionPanelCollectionContents {
        const ids = this.allIds().filter((id) => this.getRect(id)?.intersects(rect));
        return this.filterByIds(ids);
    }

    buildCreateCommand(viewModelId: ViewModelId, modelId: ModelId): ICommand {
        const panelsCommand = this.panels.buildCreateCommand(viewModelId, modelId);
        const positionsCommand = new PositionSetCreateCommand(
            this.positions,
            RTDBPath.DescriptionPanel.positionsPath(viewModelId, modelId)
        );

        return new CompositeCommand(panelsCommand, positionsCommand);
    }

    async buildDeleteCommand(
        viewModelId: ViewModelId,
        modelId: ModelId,
        positionSet: PositionSet
    ): Promise<ICommand | null> {
        if (this.isEmpty()) return null;

        const positionsCommand = new PositionSetDeleteCommand(
            positionSet,
            RTDBPath.DescriptionPanel.positionsPath(viewModelId, modelId)
        );

        const commands = await Promise.all(
            this.panels.allIds().map((id) => {
                return CommandHelper.buildDeleteCommand(RTDBPath.DescriptionPanel.panelPath(viewModelId, modelId, id));
            })
        );

        const command = CompositeCommand.composeOptionalCommands(...commands, positionsCommand);
        return command ? command : null;
    }

    buildMoveCommand(dx: number, dy: number, repository: IPositionSetRepository): ICommand | undefined {
        const { positions } = this;

        if (positions.isEmpty()) return;

        return new PositionSetUpdateCommand(positions, positions.moveAll(dx, dy), repository);
    }

    orderedPanelIds(selectedItems: SelectedItemSet<ModelElementId>): DescriptionPanelId[] {
        return new DescriptionPanelDisplayOrder(this.allIds()).buildDisplayOrder(selectedItems);
    }

    orderedEntries(selectedItems: SelectedItemSet<ModelElementId>): [DescriptionPanel, Point][] {
        const orderedIds = this.orderedPanelIds(selectedItems);
        return orderedIds.map((id) => {
            const panel = this.panels.get(id);
            assertIsDefined(panel);
            const position = this.positions.find(id);
            assertIsDefined(position);
            return [panel, position];
        }) as [DescriptionPanel, Point][];
    }

    assetUrls(): string[] {
        return this.panels.assetUrls();
    }
}

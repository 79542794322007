import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Random } from '@framework/Random';
import { isEqual } from 'lodash';

// イベントパラメータとして取り扱うキー名を列挙する。
// 全てのパラメータは Optional として扱う (ルートコンテキストでは必須でないため)
// 取得したいパラメータが増えた際には、以下の型定義に追加していくこと。
type ActionLogEventParams = Partial<{
    correlationId: string;
    groupId: string;
    workspaceId: string;
    workspaceName: string;
    viewModelId: string;
    viewModelName: string;
    viewId: string;
    viewName: string;
    modelId: string;
    modelType: string;
}>;

const ActionLogEventParamsContext = createContext<ActionLogEventParams | null>(null);

/**
 * 行動イベントログのイベントパラメータ情報をコンテキストから取得する
 */
export const useActionLogEventParamsContext = (): ActionLogEventParams => {
    const context = useContext(ActionLogEventParamsContext);
    if (!context) {
        throw new Error('useActionLogContext must be inside a Provider with a value.');
    }
    return context;
};

type Props = ActionLogEventParams & {
    children: ReactNode;
};

/**
 * 行動イベントログのイベントパラメータ情報を提供するコンテキストプロバイダー。
 * アプリケーションのルート部分に設置する。 ActionLogContextProvider で利用する。
 *
 * ユーザの一連の行動をグループ化できるように、相関ID(Correlation ID)を発行する。
 */
export const ActionLogEventParamsRootContextProvider: React.FC<Props> = ({ children, ...directProps }: Props) => {
    // useState, useEffect で相関IDを設定すると、相関IDが未設定の状態で一度レンダリングが行われてしまうため、
    // useRef() を利用して初期値にランダムな相関IDを設定している。
    const correlationId = useRef(Random.generateRandomID(40));
    const [eventParams, setEventParams] = useState<ActionLogEventParams>({});

    useEffect(() => {
        const params = {
            correlationId: correlationId.current,
            ...directProps,
        };
        // 値が一致していたら state 更新せずに return する
        if (isEqual(params, eventParams)) {
            return;
        }

        setEventParams(params);
    }, [directProps, eventParams]);

    return <ActionLogEventParamsContext.Provider value={eventParams}>{children}</ActionLogEventParamsContext.Provider>;
};

/**
 * 行動イベントログのイベントパラメータ情報を提供するコンテキストプロバイダー。
 * アプリケーションの各所で利用し、階層的に情報を付加する形で利用する。
 */
export const ActionLogEventParamsContextProvider: React.FC<Props> = ({ children, ...directProps }: Props) => {
    const logContext = useActionLogEventParamsContext();
    const [eventParams, setEventParams] = useState<ActionLogEventParams>(logContext);

    useEffect(() => {
        const params = {
            ...logContext, // 親コンテキストで設定された値
            ...directProps, // このContextProviderに指定された値 (後の値で上書きする)
        };
        // 値が一致していたら state 更新せずに return する
        if (isEqual(params, eventParams)) {
            return;
        }

        setEventParams(params);
    }, [directProps, eventParams, logContext]);

    return <ActionLogEventParamsContext.Provider value={eventParams}>{children}</ActionLogEventParamsContext.Provider>;
};

import { useMemo } from 'react';
import { usePopupRef } from '@framework/hooks';
import { classNames } from '@framework/utils';
import { Rect } from '@view-model/models/common/basic';
import { useReadViewers } from '../useReadViewers';
import { ViewerIcon } from './ViewerIcon';
import { ViewerPopup } from './ViewerPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

/**
 * ビューモデル画面の閲覧者表示コンポーネントの表示人数を
 * URLのクエリパラメータ `?viewersIconCount=3`のようなデバッグパラメータ
 * で変更可能にする。
 */
const setMaxDisplayMembers = (): number => {
    const defaultMaxDisplayMembers = 5;
    const params = new URLSearchParams(window.location.search);
    const viewersIconCount = params.get('viewersIconCount');
    return viewersIconCount && !isNaN(Number(viewersIconCount))
        ? parseInt(viewersIconCount, 10)
        : defaultMaxDisplayMembers;
};

type Props = {
    setClientViewBox: (viewBox: Rect) => void;
    className?: string;
};

export const ViewerIcons: React.FC<Props> = ({ setClientViewBox, className }: Props) => {
    const viewers = useReadViewers();

    const { isOpen, popupRef, handleOpen } = usePopupRef<HTMLDivElement>(false);

    const maxDisplayMembers = useMemo(() => setMaxDisplayMembers(), []);

    if (viewers.length === 0) return null;

    return (
        <div className={classNames('relative', className)} aria-label="viewers">
            <div
                className={classNames(
                    'flex h-12 items-center justify-center rounded-lg bg-white p-2 shadow-md',
                    viewers.length === 1 ? 'w-12' : ''
                )}
            >
                {viewers.slice(0, maxDisplayMembers).map((viewer, index) => (
                    <div key={viewer.userId} className={`${index > 0 ? '-ml-1' : ''}`}>
                        <ViewerIcon viewer={viewer} setClientViewBox={setClientViewBox} />
                    </div>
                ))}
                {viewers.length > maxDisplayMembers && (
                    <div className="relative -ml-1">
                        <button
                            className="size-6 items-center overflow-hidden rounded-full border-2 border-gray-600 bg-white text-center text-xs font-bold leading-5 text-teal-600 focus:border-teal-600 focus:outline-none"
                            onClick={handleOpen}
                        >
                            {viewers.length < maxDisplayMembers + 10 ? (
                                `+${viewers.length - maxDisplayMembers}`
                            ) : (
                                <FontAwesomeIcon icon={faEllipsisH} />
                            )}
                        </button>
                    </div>
                )}
            </div>
            {isOpen && (
                <div
                    ref={popupRef}
                    className="absolute right-0 z-50 mr-2 mt-2 block w-56 items-start overflow-y-auto rounded-lg bg-white p-1 shadow-xl"
                    style={{ maxHeight: '300px' }}
                >
                    <ViewerPopup viewers={viewers} setClientViewBox={setClientViewBox} />
                </div>
            )}
        </div>
    );
};

import { useMemo } from 'react';
import { passwordStrength } from 'check-password-strength';
import { classNames } from '@framework/utils';

type Props = {
    password: string;
};

export const PasswordStrengthIndicator: React.FC<Props> = ({ password }: Props) => {
    const strength = useMemo(() => passwordStrength(password), [password]);

    if (password.length === 0) {
        return <div className="h-6" />;
    }

    return (
        <div className="grid grid-cols-4 gap-x-2 pt-4">
            <span className={classNames('h-1', strength.id >= 0 && 'bg-red-500')}></span>
            <span className={classNames('h-1', strength.id >= 1 && 'bg-orange-500')}></span>
            <span className={classNames('h-1', strength.id >= 2 && 'bg-yellow-500')}></span>
            <span className={classNames('h-1', strength.id >= 3 && 'bg-green-500')}></span>
        </div>
    );
};

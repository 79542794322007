import { LinkStyle } from '../../domain';
import {
    LinkColor,
    LinkLineStyle,
    LinkLineStyleMenuView,
    LinkMarkStyle,
    LinkMarkStyleMenuView,
    ReverseLinkButton,
} from '@model-framework/link';
import { LinkMenuId } from './LinkMenuId';
import { LinkMultiplicityMenuContent } from './LinkMultiplicityMenuContent';
import { TrashMenuButton } from '@model-framework/menu';
import { MenuSize } from '@view-model/models/sticky/ui';
import { ColorMenu } from '@model-framework/color';

type Props = {
    menuId: LinkMenuId;
    style: LinkStyle;
    isMultiplicityEnabled: boolean;
    isOpen: boolean;
    onMenuOpen(): void;
    onMenuClose(): void;
    onLineStyleSelected(lineStyle: LinkLineStyle): void;
    onMarkStyleSelected(markStyle: LinkMarkStyle): void;
    onColorSelected(color: LinkColor): void;
    onToggleMultiplicityEnabled(): void;
    onReverseLink(): void;
    onDeleteLink(): void;
};

export const LinkMenuContent: React.FC<Props> = ({
    menuId,
    style,
    isOpen,
    isMultiplicityEnabled,
    onMenuOpen,
    onMenuClose,
    onLineStyleSelected,
    onMarkStyleSelected,
    onColorSelected,
    onToggleMultiplicityEnabled,
    onReverseLink,
    onDeleteLink,
}: Props) => {
    return (
        <>
            {menuId === LinkMenuId.LineStyle && (
                <LinkLineStyleMenuView
                    menuSize={MenuSize}
                    currentLineStyle={style.lineStyle}
                    items={LinkStyle.lineStyles}
                    isOpen={isOpen}
                    onMenuClicked={isOpen ? onMenuClose : onMenuOpen}
                    onSelected={onLineStyleSelected}
                    text="スタイル | Style"
                />
            )}
            {menuId === LinkMenuId.MarkStyle && (
                <LinkMarkStyleMenuView
                    menuSize={MenuSize}
                    currentMarkStyle={style.markStyle}
                    items={LinkStyle.markStyles}
                    isOpen={isOpen}
                    onMenuClicked={isOpen ? onMenuClose : onMenuOpen}
                    onSelected={onMarkStyleSelected}
                    text="リンクのタイプ | Type"
                />
            )}
            {menuId === LinkMenuId.Multiplicity && (
                <LinkMultiplicityMenuContent
                    isEnabled={isMultiplicityEnabled}
                    menuSize={MenuSize}
                    onClick={onToggleMultiplicityEnabled}
                    text="多重度 | Multiplicity"
                />
            )}
            {menuId === LinkMenuId.ReverseLink && (
                <ReverseLinkButton menuSize={MenuSize} onClick={onReverseLink} text="反転 | Flip" />
            )}
            {menuId === LinkMenuId.Color && (
                <ColorMenu
                    currentColorName={style.color}
                    menuSize={MenuSize}
                    items={LinkColor.menuItems()}
                    isOpen={isOpen}
                    text="カラー | Color"
                    onMenuClicked={isOpen ? onMenuClose : onMenuOpen}
                    onSelected={onColorSelected}
                />
            )}
            {menuId === LinkMenuId.DeleteLink && (
                <TrashMenuButton menuSize={MenuSize} onClick={onDeleteLink} text="削除 | Delete" />
            )}
        </>
    );
};

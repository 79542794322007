import { clientSelectedItemCollectionAtomFamily } from './clientSelectedItemCollectionAtom';
import { useMemo } from 'react';
import { ModelId, NodeId } from '@schema-common/base';
import { useAtomValue } from 'jotai/react';
import { atom } from 'jotai/vanilla';

// 他のユーザが選択しているかどうかを取得する
export function useOtherUserIsSelected({
    nodeId,
    clientId,
    modelId,
}: {
    nodeId: NodeId;
    clientId: string;
    modelId: ModelId;
}): boolean {
    const otherUserIsSelectedAtom = useMemo(() => {
        return atom((get) => {
            const selectedItemCollection = get(clientSelectedItemCollectionAtomFamily(modelId));
            return !!(
                selectedItemCollection &&
                selectedItemCollection.filterByItemIds([nodeId]).excludeByClientId(clientId).length > 0
            );
        });
    }, [nodeId, clientId, modelId]);

    return useAtomValue(otherUserIsSelectedAtom);
}

import { LinkEntity } from './LinkEntity';
import { LinkBend } from './vo';
import { LinkBendCollection } from './LinkBendCollection';
import { LinkableTargetKey } from '@view-model/domain/key';

export class LinkBendFactory {
    // この値分リンク間に隙間を設ける（LinkBendのd値をこの値分ズラす）
    static readonly BEND_INTERVAL: number = 200;

    /**
     * リンクのbend値を既に存在する道一要素間のリンクを考慮して作成する。
     * （既存のリンクと重ならないように曲げを調整する）
     *
     * @param links 既に存在するリンク。
     * @param fromKey これから作るリンクの元要素キー
     */
    static build(links: LinkEntity[], fromKey: LinkableTargetKey): LinkBend {
        if (links.length === 0) {
            return LinkBend.Straight();
        }

        const linkBends = new LinkBendCollection(links, fromKey);

        if (links.length === 1) {
            return this.buildOppositeLinkBend(linkBends.minBend());
        }
        return this.buildIntervalLinkBend(linkBends);
    }

    private static buildOppositeLinkBend(linkBend: LinkBend): LinkBend {
        const bendInterval = LinkBendFactory.BEND_INTERVAL;

        const offset = linkBend.isPositiveBend() ? bendInterval : -bendInterval;
        return linkBend.add(0, offset);
    }

    private static buildIntervalLinkBend(linkBends: LinkBendCollection): LinkBend {
        const bendInterval = LinkBendFactory.BEND_INTERVAL;

        // プラス方向のbend値しかない場合、マイナス方向に曲げる
        if (linkBends.onlyPositiveSide()) {
            return linkBends.minBend().add(0, -bendInterval);
        }

        // マイナス方向のbend値しかない場合、プラス方向に曲げる
        if (linkBends.onlyNegativeSide()) {
            return linkBends.maxBend().add(0, bendInterval);
        }

        // リンクを差し込み可能な隙間の集合を作る
        const linkableIntervals = linkBends.getLinkBendIntervals().getLinkableIntervals();

        // 内側に差し込み可能な隙間があれば、そこにリンクを追加する
        const mostInnerInterval = linkableIntervals.getMostInnerInterval();
        if (mostInnerInterval) {
            const offset = mostInnerInterval.existsLargeIntervalOnPositiveSide() ? bendInterval : -bendInterval;
            return mostInnerInterval.minBend.add(0, offset);
        }

        // リンクを差し込める隙間がない場合、一番外側のリンクのうち、より内側の方から外側に曲げる
        const outermostInnerBend = linkBends.outermostInnerBend();
        const outermostOuterBend = linkBends.outermostOuterBend();

        // 一番外側のリンクと反対側が曲げるべき方向
        const offset = outermostOuterBend.isPositiveBend() ? -bendInterval : bendInterval;
        return outermostInnerBend.add(0, offset);
    }
}

import { NodeType, NodePosition, NodeName } from './vo';
import { NodeKey } from '@view-model/domain/key';
import { NodeStyle } from './NodeStyle';
import { Model } from '@framework/domain';
import { UserKey } from '@user/domain';
import { Rect } from '@view-model/models/common/basic/Rect';
import { Point, Size } from '@view-model/models/common/basic';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { Timestamp } from '@framework/Timestamp';
import { NodeId } from '@schema-common/base';
import { StickyNodeJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/nodes/{nodeId}/StickyNodeJSON';

type StickyNodeAttributes = {
    key: NodeKey;
    name: NodeName;
    position: NodePosition;
    style: NodeStyle;
    url: string | null;
    createdUserKey: UserKey | null;
    createdAt: Timestamp;
};

export class StickyNode extends Model<NodeKey> {
    public readonly key: NodeKey;
    private readonly _name: NodeName;
    public readonly type: NodeType = NodeType.Sticky;
    private readonly _position: NodePosition;
    private readonly _style: NodeStyle;
    private readonly _url: string | null;
    public readonly createdUserKey: UserKey | null;
    public readonly createdAt: Timestamp;

    private static readonly STICKY_NODE_WIDTH = ModelLayout.GridSize * 4;
    private static readonly STICKY_NODE_HEIGHT = ModelLayout.GridSize * 4;

    public constructor(attributes: StickyNodeAttributes) {
        super();

        this.key = attributes.key;
        this._name = attributes.name;
        this._position = attributes.position;
        this._style = attributes.style;
        this._url = attributes.url || null;
        this.createdUserKey = attributes.createdUserKey || null;
        this.createdAt = attributes.createdAt;
    }

    public static buildNew(currentUserKey?: UserKey): StickyNode {
        return new StickyNode({
            key: NodeKey.buildNew(),
            name: new NodeName(''),
            position: NodePosition.buildNew(),
            style: NodeStyle.buildNew(),
            url: null,
            createdUserKey: currentUserKey || null,
            createdAt: Timestamp.now(),
        });
    }

    public static load(json: StickyNodeJSON): StickyNode {
        // [技術的な背景情報]
        // RTDB SDK のバリデーション機構の振る舞いを観察したところ、ローカルキャッシュに対して一時的な書き込みを行った後に
        // バリデーションルールの検証を行い、 invalid な場合にはそれを取り消すような callback が発生しているように見える。
        //
        // [このガード節が必要な理由]
        // 付箋を選択してドラッグ移動中に、その付箋が削除されたとしても、継続して位置情報の書き込みが行われてしまう。
        // (削除された要素の位置情報を保存しようとしており、 invalid なデータの書き込みを行なっている)
        // 本来は、移動中の選択している要素が削除された場合には、それ以降はその要素の位置情報を保存しないようにするべきだが、
        // そのような対応が直近では難しいため、 callback から呼び出される StickyNode.load() 側でガード節を導入する。
        //
        // TODO: ドラッグ移動中に対象要素が削除された場合には、その要素を移動対象から除外する
        // https://github.com/levii/balus-app/issues/2076
        if (typeof json.key !== 'string') {
            // eslint-disable-next-line no-console
            console.log(json); // 稀にエラーが発生するので、どのような値でエラーになっているか確認する (TrackJSで収集する)
            throw new Error('Invalid node format. (#2076)');
        }

        return new this({
            key: new NodeKey(json.key),
            name: NodeName.load(json.name),
            position: NodePosition.load(json.position),
            style: NodeStyle.load(json.style),
            url: json.url || null,
            createdUserKey: json.createdUserKey ? new UserKey(json.createdUserKey) : null,
            createdAt: new Timestamp(json.createdAt),
        });
    }

    /**
     * 引数に指定された位置がノードの中心になるような、 NodePosition を返します
     * @param centerPoint
     */
    static getPositionFromCenter(centerPoint: Point): NodePosition {
        return NodePosition.load(centerPoint.addXY(-this.STICKY_NODE_WIDTH / 2, -this.STICKY_NODE_HEIGHT / 2));
    }

    static size(): Size {
        return new Size(this.STICKY_NODE_WIDTH, this.STICKY_NODE_HEIGHT);
    }

    size(): Size {
        return StickyNode.size();
    }

    public dump(): StickyNodeJSON {
        return {
            key: this.key.toString(),
            name: this.name.dump(),
            position: this.position.dump(),
            style: this.style.dump(),
            type: this.type,
            createdAt: this.createdAt.toISOString(),
            ...(this.url ? { url: this.url } : {}),
            ...(this.createdUserKey ? { createdUserKey: this.createdUserKey?.toString() } : {}),
        };
    }

    public clone(): StickyNode {
        return StickyNode.load(this.dump());
    }

    public cloneNew(attributes?: Partial<StickyNodeJSON>): StickyNode {
        return StickyNode.load(
            Object.assign(
                this.dump(),
                {
                    key: NodeKey.buildNew().toString(),
                    createdAt: Timestamp.now().toISOString(),
                },
                attributes
            )
        );
    }

    public isEqual(other: StickyNode): boolean {
        return (
            other instanceof StickyNode &&
            this.key.isEqual(other.key) &&
            this.name.isEqual(other.name) &&
            this.position.isEqual(other.position) &&
            this.style.isEqual(other.style) &&
            this.type == other.type &&
            this.url == other.url
        );
    }

    public get id(): NodeId {
        return this.key.id as NodeId;
    }

    public get name(): NodeName {
        return this._name;
    }

    public get position(): NodePosition {
        return this._position;
    }

    public get style(): NodeStyle {
        return this._style;
    }

    public get url(): string | null {
        return this._url;
    }

    get createdUserId(): string | null {
        return this.createdUserKey ? `${this.createdUserKey.id}` : null;
    }

    public compareByDisplayOrder(other: StickyNode): number {
        return this.createdAt.compareTo(other.createdAt);
    }

    private attributes(): StickyNodeAttributes {
        const { key, name, position, style, url, createdUserKey, createdAt } = this;

        return { key, name, position, style, url, createdUserKey, createdAt };
    }

    move(dx: number, dy: number): StickyNode {
        return this.withPosition(this.position.add(dx, dy));
    }

    withName(name: NodeName): StickyNode {
        return new StickyNode({ ...this.attributes(), name });
    }

    withPosition(position: NodePosition): StickyNode {
        return new StickyNode({ ...this.attributes(), position });
    }

    withStyle(style: NodeStyle): StickyNode {
        return new StickyNode({ ...this.attributes(), style });
    }

    withURL(url: string | null): StickyNode {
        return new StickyNode({ ...this.attributes(), url });
    }

    withCreatedUserKey(createdUserKey: UserKey): StickyNode {
        return new StickyNode({ ...this.attributes(), createdUserKey });
    }

    /**
     * ノードの中心座標を返す
     */
    getCenterPoint(): Point {
        return this.getRect().getCenterPoint();
    }

    /**
     * 引数に指定された座標がノードの矩形領域に含まれているかを判定する
     *
     * @param point
     */
    includePoint(point: { x: number; y: number }): boolean {
        return this.getRect().includePosition(point);
    }

    getRect(): Rect {
        return new Rect(this.position, this.size());
    }

    intersectsWithRect(rect: Rect): boolean {
        return this.getRect().intersects(rect);
    }

    /**
     * ソート用の比較関数（X座標昇順 -> Y座標昇順）
     * @param a
     * @param b
     */
    static compareByPositionXY(a: StickyNode, b: StickyNode): number {
        const d = a.position.subtract(b.position);

        if (d.x !== 0) return d.x;
        return d.y;
    }
}

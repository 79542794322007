import { ElementDescription } from './ElementDescription';
import { ElementDescriptionSet, ElementDescriptionSetJSON } from './ElementDescriptionSet';
import { DBPath, MapRepository, RTDBPath } from '@framework/repository';
import { ElementDescriptionJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/element-descriptions/{elementId}/ElementDescriptionJSON';
import { ModelId, ViewModelId } from '@schema-common/base';
import { ElementDescriptionTarget } from './ElementDescriptionTarget';

export class ElementDescriptionSetRepository extends MapRepository<
    ElementDescriptionJSON,
    ElementDescription,
    ElementDescriptionSetJSON,
    ElementDescriptionSet
> {
    static buildFromIds(
        viewModelId: ViewModelId,
        modelId: ModelId,
        target: ElementDescriptionTarget
    ): ElementDescriptionSetRepository {
        return new this(ElementDescription, ElementDescriptionSet, this.getDBPath(viewModelId, modelId, target));
    }

    private static getDBPath(viewModelId: ViewModelId, modelId: ModelId, target: ElementDescriptionTarget): DBPath {
        switch (target) {
            case 'Node':
                return RTDBPath.Node.descriptionsPath(viewModelId, modelId);
            case 'Zone':
                return RTDBPath.Zone.descriptionsPath(viewModelId, modelId);
            default:
                throw new Error(`Invalid target: ${target}`);
        }
    }
}

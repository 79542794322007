import { StickyZone } from '../StickyZone';
import { StickyZoneStyle } from './StickyZoneStyle';
import { StickyZoneKeyString } from '@schema-common/base';
import { FontSize } from '@model-framework/text';
import { ThemeColor } from '@view-model/models/common/color';
import { StickyZoneKey } from '@view-model/domain/key';
import { StickyZoneRepository } from '@view-model/models/sticky/StickyZoneView';

export class StickyZoneStyleSet {
    private constructor(private readonly styles: Record<StickyZoneKeyString, StickyZoneStyle>) {}

    static fromZones(zones: StickyZone[]): StickyZoneStyleSet {
        const styles: Record<StickyZoneKeyString, StickyZoneStyle> = {};
        zones.forEach((zone) => {
            styles[zone.key.toString()] = zone.style;
        });

        return new StickyZoneStyleSet(styles);
    }

    isEqual(other: StickyZoneStyleSet): boolean {
        const { styles } = this;
        const otherStyles = other.styles;

        return (
            Object.keys(styles).length === Object.keys(otherStyles).length &&
            Object.entries(styles).every(([id, style]) => {
                const otherStyle = otherStyles[id];
                return otherStyle && style.isEqual(otherStyle);
            })
        );
    }

    isEmpty(): boolean {
        return Object.keys(this.styles).length === 0;
    }

    withThemeColor(themeColor: ThemeColor): StickyZoneStyleSet {
        const newStyles: Record<StickyZoneKeyString, StickyZoneStyle> = {};

        Object.entries(this.styles).forEach(([keyStr, style]) => {
            newStyles[keyStr] = style.withThemeColor(themeColor);
        });

        return new StickyZoneStyleSet(newStyles);
    }

    withFontSize(fontSize: FontSize): StickyZoneStyleSet {
        const newStyles: Record<StickyZoneKeyString, StickyZoneStyle> = {};

        Object.entries(this.styles).forEach(([keyStr, style]) => {
            newStyles[keyStr] = style.withFontSize(fontSize);
        });

        return new StickyZoneStyleSet(newStyles);
    }

    async saveTo(repository: StickyZoneRepository): Promise<void> {
        return repository.saveStyles(this.styles);
    }

    async isSameOn(repository: StickyZoneRepository): Promise<boolean> {
        for (const [keyStr, style] of Object.entries(this.styles)) {
            const currentStyle = await repository.getStyle(new StickyZoneKey(keyStr));

            if (!currentStyle || !currentStyle.isEqual(style)) return false;
        }

        return true;
    }
}

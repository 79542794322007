import { newDescriptionPanelId } from './DescriptionPanelId';
import { DescriptionPanel } from './DescriptionPanel';
import { CompositeCommand, ICommand } from '@model-framework/command';
import { Timestamp } from '@framework/Timestamp';
import { DescriptionPanelJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/description-panels/{descriptionPanelId}/DescriptionPanelJSON';
import { DescriptionPanelId, ModelId, ViewModelId } from '@schema-common/base';
import { AssetUrlMap } from '@view-model/domain/view-model';

export type DescriptionPanelCollectionJSON = DescriptionPanelJSON[];

export class DescriptionPanelCollection {
    private readonly ids: Readonly<DescriptionPanelId[]>;
    private readonly panels: Readonly<Record<DescriptionPanelId, DescriptionPanel>>;

    constructor(panels: DescriptionPanel[]) {
        this.panels = panels.reduce(
            (result, panel) => {
                result[panel.id] = panel;
                return result;
            },
            {} as Record<DescriptionPanelId, DescriptionPanel>
        );

        this.ids = Object.values(this.panels)
            .sort(DescriptionPanelCollection.compare)
            .map(({ id }) => id);
    }

    private static compare(a: DescriptionPanel, b: DescriptionPanel): number {
        return a.createdAt.compareTo(b.createdAt);
    }

    static buildEmpty(): DescriptionPanelCollection {
        return new DescriptionPanelCollection([]);
    }

    static load(dump: DescriptionPanelCollectionJSON): DescriptionPanelCollection {
        return new DescriptionPanelCollection(dump.map((j) => DescriptionPanel.load(j)));
    }

    dump(): DescriptionPanelCollectionJSON {
        return this.ids.map((id) => this.panels[id].dump());
    }

    toRecord(): Record<DescriptionPanelId, DescriptionPanel> {
        return { ...this.panels };
    }

    isEqual(other: DescriptionPanelCollection): boolean {
        return this.count() === other.count() && this.all().every((panel) => other.get(panel.id)?.isEqual(panel));
    }

    count(): number {
        return this.ids.length;
    }

    cloneNew(assetUrlMap: AssetUrlMap): [DescriptionPanelCollection, Record<DescriptionPanelId, DescriptionPanelId>] {
        const newIdMap = this.allIds().reduce(
            (result, id) => {
                result[id] = newDescriptionPanelId();
                return result;
            },
            {} as Record<DescriptionPanelId, DescriptionPanelId>
        );
        const createdAt = Timestamp.now();

        const panels = this.all().map((panel, index) => {
            const newId = newIdMap[panel.id];
            return panel.cloneNew(newId, createdAt.addMilliSeconds(index), assetUrlMap);
        });

        return [new DescriptionPanelCollection(panels), newIdMap];
    }

    all(): DescriptionPanel[] {
        return this.ids.map((id) => this.panels[id]);
    }

    allIds(): DescriptionPanelId[] {
        return this.ids.concat();
    }

    filter(callback: (panel: DescriptionPanel, index: number) => boolean): DescriptionPanelCollection {
        const panels = this.all().filter(callback);
        return new DescriptionPanelCollection(panels);
    }

    reduce<U>(
        callback: (previousValue: U, currentValue: DescriptionPanel, index: number, array: DescriptionPanel[]) => U,
        initialValue: U
    ): U {
        return this.all().reduce(callback, initialValue);
    }

    // for CollectionRepository
    toArray(): DescriptionPanel[] {
        return this.all();
    }

    get(panelId: DescriptionPanelId): DescriptionPanel | undefined {
        return this.panels[panelId];
    }

    add(panel: DescriptionPanel): DescriptionPanelCollection {
        if (this.get(panel.id)) {
            console.warn(`DescriptionPanel: ${panel.id} is already exists in the collection.`);
        }

        return new DescriptionPanelCollection(this.all().concat(panel));
    }

    update(panel: DescriptionPanel): DescriptionPanelCollection {
        if (!this.get(panel.id)) {
            console.warn(`DescriptionPanel: ${panel.id} is not found but added.`);
        }

        return this.remove(panel.id).add(panel);
    }

    remove(panelId: DescriptionPanelId): DescriptionPanelCollection {
        return this.filter(({ id }) => id !== panelId);
    }

    buildCreateCommand(viewModelId: ViewModelId, modelId: ModelId): ICommand {
        const commands = this.all().map((panel) => panel.buildCreateCommand(viewModelId, modelId));
        return new CompositeCommand(...commands);
    }

    assetUrls(): string[] {
        const urls = this.all().reduce((prev, panel) => {
            return [...prev, ...panel.assetUrls()];
        }, [] as string[]);
        return Array.from(new Set(urls));
    }
}

import { FontSize } from '@view-model/models/framework/text';

const NodeFontSizeList = [FontSize.S, FontSize.M, FontSize.L] as const;
export type NodeFontSize = (typeof NodeFontSizeList)[number];

export namespace NodeFontSize {
    export const S = FontSize.S;
    export const M = FontSize.M;
    export const L = FontSize.L;

    export function values(): Readonly<NodeFontSize[]> {
        return NodeFontSizeList;
    }

    export function fromString(value: string): NodeFontSize | undefined {
        return NodeFontSizeList.find((fontSize) => fontSize.toString() === value);
    }

    export function load(fontSize: string): NodeFontSize {
        // 未知の値の場合には L に fallback する
        return NodeFontSize.fromString(fontSize) || NodeFontSize.L;
    }

    export function dump(fontSize: NodeFontSize): string {
        return fontSize.toString();
    }

    export function include(fontSize: FontSize): boolean {
        return NodeFontSizeList.find((nodeFontSize) => nodeFontSize === fontSize) !== undefined;
    }

    export function fromFontSize(fontSize: FontSize): NodeFontSize {
        return load(fontSize.toString());
    }
}

export enum ViewModelSharingUserRoleType {
    None = 'None',
    Viewer = 'Viewer',
    Editor = 'Editor',
}

const RoleTypeList = [
    ViewModelSharingUserRoleType.None,
    ViewModelSharingUserRoleType.Viewer,
    ViewModelSharingUserRoleType.Editor,
] as const;

const dict: Record<string, string> = {
    None: 'なし | None',
    Editor: '変更可能 | Can make changes',
    Viewer: '閲覧のみ | View only',
};

export namespace ViewModelSharingUserRoleType {
    export function getLocalString(permission: ViewModelSharingUserRoleType): string {
        return dict[permission];
    }

    export function values(excludeNoneRole = false): Readonly<ViewModelSharingUserRoleType[]> {
        return excludeNoneRole
            ? RoleTypeList.filter((roleType) => roleType != ViewModelSharingUserRoleType.None).concat()
            : RoleTypeList.concat();
    }

    export function fromString(value: string): ViewModelSharingUserRoleType | undefined {
        return RoleTypeList.find((role) => role.toString() === value);
    }

    export function load(role: string): ViewModelSharingUserRoleType {
        return ViewModelSharingUserRoleType.fromString(role) || ViewModelSharingUserRoleType.None;
    }

    export function dump(role: ViewModelSharingUserRoleType): string {
        return role.toString();
    }

    export function hasReadablePermission(roleType: ViewModelSharingUserRoleType): boolean {
        return [ViewModelSharingUserRoleType.Viewer, ViewModelSharingUserRoleType.Editor].includes(roleType);
    }
}

export function isViewModelSharingUserRoleType(role: string): role is ViewModelSharingUserRoleType {
    return (
        role === ViewModelSharingUserRoleType.Editor ||
        role === ViewModelSharingUserRoleType.Viewer ||
        role === ViewModelSharingUserRoleType.None
    );
}

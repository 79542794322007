import { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react';
import { Size } from '@view-model/models/common/types/ui';
import { useD3ClickCallback, useD3MouseMoveCallback } from '@view-model/models/common/hooks';
import { D3Mouse } from '@view-model/models/common/d3/D3Mouse';
import { Point } from '@view-model/models/common/basic';
import { LinkerState } from './LinkerState';
import { LinkKey, LinkableTargetKey } from '@view-model/domain/key';
import { ViewId, ViewModelId } from '@schema-common/base';
import { useExclusiveLinkEditing } from '@model-framework/link';

type Props = {
    canvasSize: Size;
    linkerState: LinkerState<ViewId | LinkableTargetKey>;
    offsetPosition?: Point;
    iconCircleSize?: number;
    linkerLineWidth?: number;
    onLinkerMove(currentPosition: Point): void;
    onLinkerEnd(currentPosition: Point): void;
    // 整合性リンクの作成時もLinkerCanvasViewが使われるが、整合性リンクは付け替えをしないため以下二つはオプショナルにする
    viewModelId?: ViewModelId | null;
    replacingLinkKey?: LinkKey | null;
};

const LinkerCanvasView: React.FC<Props> = memo(
    ({
        canvasSize,
        linkerState,
        offsetPosition = new Point(0, 0),
        iconCircleSize = 16,
        linkerLineWidth = 4,
        onLinkerMove,
        onLinkerEnd,
        viewModelId = null,
        replacingLinkKey = null,
    }: Props) => {
        const myRef = useRef<SVGRectElement>(null);
        const { width, height } = canvasSize;
        const [movingPoint, setMovingPoint] = useState<Point>(linkerState.targetPosition);
        const d3Mouse = useMemo(() => new D3Mouse(myRef), []);

        const { otherUserEditing, endEdit } = useExclusiveLinkEditing(replacingLinkKey, viewModelId);

        /**
         * オフセットを加算します
         * （「親コンポーネント座標系 → LinkerCanvasView座標系」の方向の座標変換を行います）
         */
        const getOffsetAddedPosition = useCallback(
            (position: Point): Point => {
                return position.add(offsetPosition);
            },
            [offsetPosition]
        );

        /**
         * オフセットを減算します
         * （「LinkerCanvasView座標系 → 親コンポーネント座標系」の方向の座標変換を行います）
         */
        const getOffsetSubtractedPosition = useCallback(
            (position: Point): Point => {
                return position.subtract(offsetPosition);
            },
            [offsetPosition]
        );

        useD3ClickCallback(
            myRef,
            useCallback(
                (event: MouseEvent) => {
                    if (otherUserEditing) return;
                    const [x, y] = d3Mouse.getPosition(event);

                    // 親コンポーネントの座標系とLinkerCanvasViewはオフセット分ズレているので、そのズレを補正した値を返す
                    const adjustedPosition = getOffsetSubtractedPosition(Point.fromPosition({ x, y }));
                    onLinkerEnd(adjustedPosition);
                    endEdit();
                },
                [d3Mouse, getOffsetSubtractedPosition, onLinkerEnd, otherUserEditing, endEdit]
            )
        );

        useD3MouseMoveCallback(
            myRef,
            useCallback(
                (event: MouseEvent) => {
                    if (otherUserEditing) return;
                    const [x, y] = d3Mouse.getPosition(event);
                    const position = Point.fromPosition({ x, y });

                    // 親コンポーネントの座標系とLinkerCanvasViewはオフセット分ズレているので、そのズレを補正した値を返す
                    const adjustedPosition = getOffsetSubtractedPosition(position);
                    onLinkerMove(adjustedPosition);
                    // LinkerCanvasView上の座標はズレの補正は不要なのでそのまま使う
                    setMovingPoint(position);
                },
                [d3Mouse, getOffsetSubtractedPosition, onLinkerMove, otherUserEditing]
            )
        );

        useEffect(() => {
            if (linkerState.isSourceReplacing()) {
                setMovingPoint(linkerState.sourcePosition);
            } else {
                setMovingPoint(linkerState.targetPosition);
            }
        }, [linkerState]);

        // 親コンポーネントの座標系とLinkerCanvasViewはオフセット分ズレているので、そのズレを補正して描画する
        const [adjustedSourcePosition, adjustedTargetPosition] = linkerState.isSourceReplacing()
            ? [getOffsetAddedPosition(movingPoint), getOffsetAddedPosition(linkerState.targetPosition)]
            : [getOffsetAddedPosition(linkerState.sourcePosition), getOffsetAddedPosition(movingPoint)];

        return (
            <g width={width} height={height}>
                {/* 土台となる透明なキャンバス */}
                <rect
                    ref={myRef}
                    className="fill-transparent"
                    width={width}
                    height={height}
                    rx={64}
                    ry={64}
                    pointerEvents="all"
                />

                {/* リンカーの始点を描画 */}
                <circle
                    className="fill-brand"
                    r={iconCircleSize}
                    cx={adjustedSourcePosition.x}
                    cy={adjustedSourcePosition.y}
                    pointerEvents="none"
                />

                <line
                    className="stroke-brand"
                    strokeWidth={linkerLineWidth}
                    x1={adjustedSourcePosition.x}
                    y1={adjustedSourcePosition.y}
                    x2={adjustedTargetPosition.x}
                    y2={adjustedTargetPosition.y}
                    pointerEvents="none"
                />

                <circle
                    className="fill-brand"
                    r={iconCircleSize}
                    cx={adjustedTargetPosition.x}
                    cy={adjustedTargetPosition.y}
                    pointerEvents="none"
                />
            </g>
        );
    }
);

LinkerCanvasView.displayName = 'LinkerCanvasView';
export { LinkerCanvasView };

import { MainMenuBox } from '@model-framework/menu';
import { Point } from '@view-model/models/common/basic';

type Props = {
    isEnabled: boolean;
    menuSize: number;
    onClick(): void;
    text: string;
};

export const LinkMultiplicityMenuContent: React.FC<Props> = ({ isEnabled, menuSize, onClick, text }: Props) => {
    const centerPoint = new Point(menuSize / 2, menuSize / 2);
    return (
        <g>
            <MainMenuBox menuSize={menuSize} isSelected={isEnabled} onClick={onClick} text={text} />
            <text
                pointerEvents="none"
                textAnchor="middle"
                dominantBaseline="central"
                fill="black"
                fontWeight="bold"
                transform={centerPoint.toSVGTranslate()}
                fontSize="18"
            >
                0..*
            </text>
        </g>
    );
};

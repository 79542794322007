import { useCallback } from 'react';
import { ModelCommentThread } from '@view-model/models/sticky/ModelComment';
import { Markdown } from '@framework/ui/elements/Markdown';
import { useHistory } from 'react-router-dom';
import { ViewEntity } from '@view-model/domain/view';
import { generateDefaultUserIcon } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';

type Props = {
    commentThread: ModelCommentThread;
    view: ViewEntity;
};

export const CommentListItem: React.FC<Props> = ({ commentThread, view }: Props) => {
    const primaryComment = commentThread.getPrimaryComment();
    const primaryCommentAuthor = primaryComment.getAuthor();
    const authorImageURL = primaryCommentAuthor.getImageURL();
    const history = useHistory();

    const handleMoveToView = useCallback(() => {
        const query = new URLSearchParams();

        // 一度対象のビューに先に移動する。
        query.set('moveTime', Date.now().toString());
        query.set('targetView', view.id);
        history.replace({ search: query.toString() });

        // コメントリストの同一コメントを何度クリックしても
        // 同じビューに移動するためにクエリパラメータを毎回更新する必要があるので、
        // moveTimeパラメータを毎回つける

        // ビューに移動した後に対象のコメントスレッドを選択状態にするために
        // クエリパラメータにthreadIdを付与する
        query.set('threadId', commentThread.id);
        history.replace({ search: query.toString() });
    }, [view, commentThread, history]);

    return (
        <div className="cursor-pointer p-4 hover:bg-gray-200" onClick={handleMoveToView}>
            {view.name.value && (
                <div className="mb-1 h-4 truncate text-right text-2xs text-gray-600">{view.name.value}</div>
            )}
            <div className="flex">
                <div className="mr-2 size-8 overflow-hidden rounded-full">
                    {authorImageURL ? (
                        <img src={authorImageURL} className="size-full bg-gray-400 object-cover" />
                    ) : (
                        <div className="size-full bg-gray-400 object-cover">
                            {generateDefaultUserIcon({
                                id: primaryCommentAuthor.getUserKey().id,
                                name: primaryCommentAuthor.getName(),
                                size: 32,
                            })}
                        </div>
                    )}
                </div>
                <div>
                    <div className="overflow-hidden text-ellipsis text-base font-bold">
                        {primaryCommentAuthor.getName()}
                    </div>
                    <div className="text-xs">{primaryComment.getPostAtAsString()}</div>
                </div>
                {commentThread.getRegularReplyComments().length > 0 && (
                    <div className="ml-auto pt-1 text-xs text-brand">
                        <span className="mr-1">
                            <FontAwesomeIcon icon={faReply} />
                        </span>
                        {commentThread.getRegularReplyComments().length}
                    </div>
                )}
            </div>
            <div className="mt-2 whitespace-pre-wrap break-all text-sm">
                <Markdown className="markdown-body" source={primaryComment.getBody()} />
            </div>
        </div>
    );
};

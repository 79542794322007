import { Timestamp } from '@framework/Timestamp';
import { BannerAnnouncementJSON } from '@schema-app/notification/banner-announcement/BannerAnnouncementJSON';

export class BannerAnnouncement {
    constructor(
        readonly title: string,
        readonly url: string,
        readonly notifiedAt: Timestamp
    ) {}

    dump(): BannerAnnouncementJSON {
        const { title, url, notifiedAt } = this;
        return {
            title,
            url,
            notifiedAt: notifiedAt.toUnixTimestamp(),
        };
    }

    get notifiedAtAsString(): string {
        return this.notifiedAt.format('YYYY/MM/DD HH:mm');
    }

    get cookieKey(): string {
        return `banner-announcement-${this.notifiedAt.toUnixTimestamp()}`;
    }

    static load(dump: BannerAnnouncementJSON): BannerAnnouncement {
        const { title, url, notifiedAt } = dump;
        return new this(title, url, new Timestamp(notifiedAt));
    }

    static buildNew(notifiedAt = Timestamp.now().startOf('hours')): BannerAnnouncement {
        return new this('', '', notifiedAt);
    }

    withTitle(title: string): BannerAnnouncement {
        return BannerAnnouncement.load({ ...this.dump(), title });
    }

    withUrl(url: string): BannerAnnouncement {
        return BannerAnnouncement.load({ ...this.dump(), url });
    }

    withNotifiedAt(notifiedAt: Timestamp): BannerAnnouncement {
        return BannerAnnouncement.load({ ...this.dump(), notifiedAt: notifiedAt.toUnixTimestamp() });
    }

    isAfter(timestamp: Timestamp): boolean {
        return this.notifiedAt.isAfter(timestamp);
    }

    isValid(): boolean {
        return (
            !!this.title &&
            (this.url.startsWith('http://') || this.url.startsWith('https://')) &&
            this.notifiedAt.isValid()
        );
    }
}

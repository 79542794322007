import { Rect } from '@view-model/models/common/basic';

const SelectionStrokeWidth = 4; // px

type Props = {
    fillColor: string;
    menuSize: number;
    isSelected: boolean;
    onClick(): void;
};

/**
 * 選択状態に応じてメニューの領域を返す。
 * @param menuSize
 * @param isSelected
 */
const menuRectBy = (menuSize: number, isSelected: boolean): Rect => {
    const menuRect = Rect.fromSize(menuSize, menuSize);

    // 選択中の場合はストロークを描画するのでその幅分位置とサイズをオフセットする
    return isSelected ? menuRect.applyMarginKeepingCenter(-SelectionStrokeWidth / 2) : menuRect;
};

export const ColorSubMenu: React.FC<Props> = ({ fillColor, menuSize, isSelected, onClick }: Props) => {
    const menuRect = menuRectBy(menuSize, isSelected);

    return (
        <rect
            cursor={'pointer'}
            className={isSelected ? 'stroke-brand' : 'stroke-none'}
            strokeWidth={SelectionStrokeWidth}
            x={menuRect.x}
            y={menuRect.y}
            width={menuRect.width}
            height={menuRect.height}
            fill={fillColor}
            onClick={onClick}
        />
    );
};

import { createContext } from 'react';
import { ViewModelId } from '@schema-common/base';

export type BookmarkedViewModelIdsContextValues = {
    bookmarkedViewModelIds: Set<ViewModelId>;
    addBookmark: (viewModelId: string) => Promise<void>;
    removeBookmark: (viewModelId: string) => Promise<void>;
};

export const BookmarkedViewModelIdsContext = createContext<BookmarkedViewModelIdsContextValues>({
    bookmarkedViewModelIds: new Set(),
    addBookmark: async () => void 0,
    removeBookmark: async () => void 0,
});

import { ILayout } from './ILayout';

type Position = {
    x: number;
    y: number;
};

export class GridLayout implements ILayout {
    private readonly gridWidth: number;
    private readonly gridHeight: number;

    constructor(gridWidth: number, gridHeight: number) {
        this.gridWidth = gridWidth;
        this.gridHeight = gridHeight;
    }

    snapX(x: number): number {
        return this.snap(x, this.gridWidth);
    }

    snapY(y: number): number {
        return this.snap(y, this.gridHeight);
    }

    snapPosition(position: Position): Position {
        return {
            x: this.snapX(position.x),
            y: this.snapY(position.y),
        };
    }

    private snap(pos: number, gridSize: number): number {
        // スナップで移動する距離の上限をグリッドサイズの半分にするため、ポジション座標にグリッドサイズ半分の補正を加える
        const gridPosition = Math.abs(pos) + gridSize / 2;
        const gridIndex = Math.trunc(gridPosition / gridSize);
        const sign = Math.sign(pos);

        return sign * (gridIndex * gridSize);
    }
}

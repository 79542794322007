import { ViewModelItem } from '../ViewModelItem';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { ViewModelCollection } from '@workspace/view-model-folder/domain/ViewModelCollection';

type Props = {
    showEditMenu: boolean;
    viewModels: ViewModelCollection;
    workspace: Workspace;
};

export const ViewModelList: React.FC<Props> = ({ showEditMenu, viewModels, workspace }: Props) => {
    return (
        <div className="p-2">
            {viewModels.all().map((viewModel) => (
                <ViewModelItem
                    key={viewModel.id}
                    viewModel={viewModel}
                    showEditMenu={showEditMenu}
                    workspace={workspace}
                />
            ))}
        </div>
    );
};

import { LinkBend } from './vo';
import { LinkEntity } from './LinkEntity';
import { LinkBendIntervalCollection } from './LinkBendIntervalCollection';
import { LinkBendInterval } from './LinkBendInterval';
import { LinkableTargetKey } from '@view-model/domain/key';

export class LinkBendCollection {
    readonly linkBends: LinkBend[];

    constructor(links: LinkEntity[], fromKey: LinkableTargetKey) {
        // Link から LinkBend に変換する
        // 逆向のリンクは曲げ値を +/- 反転させる
        this.linkBends = links.map((link) => {
            const d = link.from.isEqual(fromKey) ? link.bend.d : -link.bend.d;
            return new LinkBend({ r: 0.5, d: d });
        });

        // 曲げ値 (d) を負から 0、正の値と数直線に沿って並べる
        this.linkBends.sort((a, b) => a.d - b.d);
    }

    get length(): number {
        return this.linkBends.length;
    }

    isEmpty(): boolean {
        return this.length === 0;
    }

    outermostInnerBend(): LinkBend {
        return Math.abs(this.maxBend().d) > Math.abs(this.minBend().d) ? this.minBend() : this.maxBend();
    }

    outermostOuterBend(): LinkBend {
        return Math.abs(this.maxBend().d) > Math.abs(this.minBend().d) ? this.maxBend() : this.minBend();
    }

    minBend(): LinkBend {
        return this.linkBends[0];
    }

    maxBend(): LinkBend {
        return this.linkBends[this.linkBends.length - 1];
    }

    onlyPositiveSide(): boolean {
        if (this.isEmpty()) {
            return true;
        }
        return this.minBend().d > 0;
    }

    onlyNegativeSide(): boolean {
        if (this.isEmpty()) {
            return true;
        }
        return this.maxBend().d < 0;
    }

    onlyOneSide(): boolean {
        return this.onlyPositiveSide() || this.onlyNegativeSide();
    }

    getLinkBendIntervals(): LinkBendIntervalCollection {
        const nullableIntervals = this.linkBends.map((current, i) => {
            if (i === 0) return null;
            const prev = this.linkBends[i - 1];
            const innerBend = Math.abs(prev.d) > Math.abs(current.d) ? current : prev;
            const outerBend = Math.abs(prev.d) > Math.abs(current.d) ? prev : current;
            return new LinkBendInterval(innerBend, outerBend, Math.abs(prev.d - current.d));
        });
        const intervals = nullableIntervals.filter((x): x is LinkBendInterval => !!x);

        return new LinkBendIntervalCollection(intervals);
    }
}

import { faGlobe, faGripVertical, faProjectDiagram, faLock } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DraggableSyntheticListeners } from '@dnd-kit/core/dist/hooks/useDraggable';
import { Timestamp } from '@framework/Timestamp';
import { Tooltip } from '@framework/ui/atoms/Tooltip';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { MenuButtons } from '@workspace/view-model-folder/ui/ViewModelItem/MenuButtons';
import { Link } from 'react-router-dom';
import { ViewModelId } from '@schema-common/base';

type Props = {
    viewModelId: ViewModelId;
    publicReadable: boolean;
    name: string;
    isLocked: boolean;
    updatedAt: Timestamp | null;
    showEditMenu: boolean;
    onEdit(): void;
    onCopy(): void;
    onDelete(): void;
    onMoveToOtherWorkspace(): void;
    dragListeners?: DraggableSyntheticListeners;
};

export const ItemContent: React.FC<Props> = ({
    viewModelId,
    publicReadable,
    name,
    isLocked,
    updatedAt,
    showEditMenu,
    onDelete,
    onCopy,
    onEdit,
    onMoveToOtherWorkspace,
    dragListeners,
}: Props) => {
    return (
        <div className="mb-2 flex h-12 w-full items-center rounded border border-gray-500 bg-white hover:bg-gray-200">
            <div className="relative flex h-full w-16 items-center justify-center rounded-l bg-brand text-center text-white">
                <span className="text-2xl">
                    <FontAwesomeIcon icon={faProjectDiagram} />
                </span>
                {publicReadable && (
                    <span className="absolute bottom-0 right-0 inline-block size-4 -translate-x-2 -translate-y-3 text-xl">
                        <Tooltip tooltip="外部公開中" placement="bottom">
                            <FontAwesomeIcon icon={faGlobe} />
                        </Tooltip>
                    </span>
                )}
            </div>
            <Link
                className="flex-1 truncate px-2 text-xl font-bold hover:text-blue-800"
                to={UserPagePathBuilder.viewModelPage(viewModelId)}
            >
                {isLocked && <FontAwesomeIcon icon={faLock} className="pr-2" />}
                {name}
                {updatedAt && (
                    <div className="text-3xs text-gray-500">最終更新日: {updatedAt.format('YYYY年M月D日 HH:mm')}</div>
                )}
            </Link>

            {showEditMenu ? (
                <div className="flex items-center justify-center px-2">
                    <>
                        <MenuButtons
                            onEdit={onEdit}
                            onCopy={onCopy}
                            onDelete={onDelete}
                            onMoveToOtherWorkspace={onMoveToOtherWorkspace}
                        />
                        {dragListeners && (
                            <button {...dragListeners} className="px-2 hover:bg-gray-400">
                                <FontAwesomeIcon icon={faGripVertical} />
                            </button>
                        )}
                    </>
                </div>
            ) : (
                <div className="flex items-center justify-center px-2">
                    <Tooltip tooltip={'ビューモデルをコピー | Copy view model'} placement={'bottom'}>
                        <div
                            className="cursor-pointer rounded border border-solid border-brand bg-white px-2 py-1 font-bold text-brand hover:bg-gray-200"
                            onClick={onCopy}
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

import { DisplayOrderTree, IOrderRollback, DisplayOrderTreeZone } from '../domain';
import { DisplayOrder } from './DisplayOrder';
import { DisplayOrderChangeset } from './DisplayOrderChangeset';
import { DisplayOrderTreeMap } from './DisplayOrderTreeMap';
import { IDisplayOrderKeyGenerator } from './IDisplayOrderKeyGenerator';
import { ModelId, NodeId, StickyZoneId, ViewModelId } from '@schema-common/base';
import { RefBuilder, Reference, RTDBPath } from '@framework/repository';

/**
 * 新しいキーを生成する
 * @param ref
 */
const newKey = (ref: Reference): string => {
    // https://firebase.google.com/docs/database/web/lists-of-data?hl=ja#append_to_a_list_of_data
    // push() 参照の .key プロパティには自動生成されたキーの値が含まれているため string とみなす。
    return ref.push().key as string;
};

class OrderKeyGenerator implements IDisplayOrderKeyGenerator {
    constructor(private readonly ref: Reference) {}

    generate(): string {
        return newKey(this.ref);
    }
}

export class DisplayOrderRepository {
    private readonly ref: Reference;

    constructor(viewModelId: ViewModelId, modelId: ModelId) {
        this.ref = RefBuilder.ref(RTDBPath.Model.displayOrderPath(viewModelId, modelId));
    }

    private async fetchTree(): Promise<DisplayOrderTreeMap> {
        const snapshot = await this.ref.once('value');
        return new DisplayOrderTreeMap(snapshot.val() || {});
    }

    /**
     * 並び順の情報を一括取得する
     */
    async load(): Promise<DisplayOrderTree> {
        return (await this.fetchTree()).toDisplayOrderTree('__root__');
    }

    /**
     * 並び順の情報を一括保存する
     *
     * @param tree
     */
    async save(tree: DisplayOrderTree, parentId?: StickyZoneId): Promise<void> {
        const { nodeIds, zones } = tree;
        const zoneIds = zones.map((zone: DisplayOrderTreeZone) => zone.id);
        await this.addNodes(nodeIds, parentId);
        await this.addZones(zoneIds, parentId);
        await Promise.all(zones.map((zone: DisplayOrderTreeZone) => this.saveZone(zone)));
    }

    private async saveZone(treeZone: DisplayOrderTreeZone): Promise<void> {
        const { id, nodeIds, zones } = treeZone;
        const zoneIds = zones.map((zone: DisplayOrderTreeZone) => zone.id);
        await this.addNodes(nodeIds, id);
        await this.addZones(zoneIds, id);
        await Promise.all(zones.map((zone: DisplayOrderTreeZone) => this.saveZone(zone)));
    }

    /**
     * ノードを指定した親内の最前面に追加する。親ID（ゾーンID）を指定しなかった場合はルート（ゾーンなし）へ追加する。
     *
     * @param nodeId ノードID
     * @param parentId 親ID（ゾーンID）
     */
    async addNode(nodeId: NodeId, parentId?: StickyZoneId): Promise<void> {
        return this.addNodes([nodeId], parentId);
    }

    /**
     * ノードを指定した親内の最前面に追加する。親ID（ゾーンID）を指定しなかった場合はルート（ゾーンなし）へ追加する。
     *
     * @param nodeIds ノードID
     * @param parentId 親ID（ゾーンID）
     */
    async addNodes(nodeIds: NodeId[], parentId?: StickyZoneId): Promise<void> {
        const { ref } = this;

        const orders = nodeIds.map((id) => {
            // Realtime Database に時系列順のキーを生成してもらう
            return DisplayOrder.node(id, parentId || '__root__', newKey(ref));
        });

        await DisplayOrderChangeset.changeset().addOrders(orders).saveTo(ref);
    }

    /**
     * 並び順からノードを削除する。Undo時などの復元用インタフェースを返す。
     * @param id ノードID
     * @return 復元用のインタフェース。rollback() で利用する。
     */
    async removeNode(id: NodeId): Promise<IOrderRollback> {
        return this.removeNodes([id]);
    }

    /**
     * 並び順からノードを削除する。Undo時などの復元用インタフェースを返す。
     * @param ids ノードID
     * @return 復元用のインタフェース。rollback() で利用する。
     */
    async removeNodes(ids: NodeId[]): Promise<IOrderRollback> {
        return this.removeNodesAndZones(ids, []);
    }

    /**
     * ゾーンを指定した親内の最前面に追加する。親ID（ゾーンID）を指定しなかった場合はルート（ゾーンなし）へ追加する。
     *
     * @param zoneId ゾーンID
     * @param parentId 親ID（ゾーンID）
     */
    async addZone(zoneId: StickyZoneId, parentId?: StickyZoneId): Promise<void> {
        return this.addZones([zoneId], parentId);
    }

    /**
     * ゾーンを指定した親内の最前面に追加する。親ID（ゾーンID）を指定しなかった場合はルート（ゾーンなし）へ追加する。
     *
     * @param zoneIds ゾーンID
     * @param parentId 親ID（ゾーンID）
     */
    async addZones(zoneIds: StickyZoneId[], parentId?: StickyZoneId): Promise<void> {
        const { ref } = this;

        const orders = zoneIds.map((id) => {
            // Realtime Database に時系列順のキーを生成してもらう
            return DisplayOrder.zone(id, parentId || '__root__', newKey(ref));
        });

        await DisplayOrderChangeset.changeset().addOrders(orders).saveTo(ref);
    }

    /**
     * 並び順からゾーンを削除する。Undo時などの復元用インタフェースを返す。
     * @param zoneId ゾーンID
     * @return 復元用のインタフェース。rollback() で利用する。
     */
    async removeZone(id: StickyZoneId): Promise<IOrderRollback> {
        return this.removeZones([id]);
    }

    /**
     * 並び順からゾーンを削除する。Undo時などの復元用インタフェースを返す。
     * @param zoneIds ゾーンID
     * @return 復元用のインタフェース。rollback() で利用する。
     */
    async removeZones(zoneIds: StickyZoneId[]): Promise<IOrderRollback> {
        return this.removeNodesAndZones([], zoneIds);
    }

    /**
     * 並び順からノードとゾーンを削除する。Undo時などの復元用インタフェースを返す。
     * @param nodeIds ノードID
     * @param zoneIds ゾーンID
     * @return 復元用のインタフェース。rollback() で利用する。
     */
    async removeNodesAndZones(nodeIds: NodeId[], zoneIds: StickyZoneId[]): Promise<IOrderRollback> {
        const { ref } = this;

        return (await this.fetchTree())
            .removeNodesAndZonesChangeset(new OrderKeyGenerator(ref), nodeIds, zoneIds)
            .saveTo(ref);
    }

    /**
     * 所属するゾーンを変更する。
     * @param childIds 親を変更するノードまたはゾーンのIDリスト
     * @param newParentZoneId 変更先の親ゾーン。nullを指定した場合はルート直下（親ゾーンなし）に変更します。
     */
    async changeParentZone(
        childIds: (NodeId | StickyZoneId)[],
        newParentZoneId: StickyZoneId | null
    ): Promise<IOrderRollback> {
        const { ref } = this;

        const parentZoneId = newParentZoneId || '__root__';

        const tree = await this.fetchTree();

        // オーダーキーを新たに発行するのでキー順が同じ順序になるようオーダキー順で並べる
        const oldOrders = DisplayOrder.sortByOrderKey(
            tree.findOrders(childIds).filter(
                // 変更不要なものは除外
                (order) => !order.parentIs(parentZoneId)
            )
        );
        const newOrders = oldOrders.map((order: DisplayOrder) => order.changeParent(parentZoneId, newKey(ref)));

        return DisplayOrderChangeset.changeset().removeOrders(oldOrders).addOrders(newOrders).saveTo(ref);
    }

    /**
     * changeParentZone() 実行時に親変更が起きるかどうかを返します。
     *
     * @param childIds
     * @param newParentZoneId
     */
    async willChangeParent(
        childIds: (NodeId | StickyZoneId)[],
        newParentZoneId: StickyZoneId | null
    ): Promise<boolean> {
        const parentId = newParentZoneId || '__root__';
        return (await this.fetchTree()).findOrders(childIds).some((order) => !order.parentIs(parentId));
    }

    /**
     * 並び順の変更を復元する
     *
     * @param orderRollback 削除などの操作メソッドから返される復元用のインタフェース
     */
    async rollback(orderRollback: IOrderRollback): Promise<void> {
        await orderRollback.rollback(this.ref);
    }
}

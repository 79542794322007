import { ClientSelectedItem, ClientSelectedItemId } from './ClientSelectedItem';
import { Id } from '@framework/domain';
import { ClientId } from '@schema-common/base';
import { Timestamp } from '@framework/Timestamp';

export class ClientSelectedItemCollection {
    constructor(private readonly selectedItems: Readonly<ClientSelectedItem[]> = []) {}

    static fromItems(clientId: ClientId, itemIds: Id[]): ClientSelectedItemCollection {
        const now = Timestamp.now();
        const items = itemIds.map((itemId) => new ClientSelectedItem(clientId, itemId, now));

        return new this(items);
    }

    get length(): number {
        return this.selectedItems.length;
    }

    forEach(cb: (item: ClientSelectedItem, index: number) => void): void {
        this.selectedItems.forEach(cb);
    }

    all(): ClientSelectedItem[] {
        return this.selectedItems.concat();
    }

    allIds(): ClientSelectedItemId[] {
        return this.selectedItems.map(({ id }) => id);
    }

    add(clientSelectedItem: ClientSelectedItem): ClientSelectedItemCollection {
        const items = this.remove(clientSelectedItem.id).all().concat(clientSelectedItem);
        return new ClientSelectedItemCollection(items);
    }

    remove(id: ClientSelectedItemId): ClientSelectedItemCollection {
        const items = this.all().filter((selected) => selected.id !== id);
        return new ClientSelectedItemCollection(items);
    }

    excludeByClientId(clientId: ClientId): ClientSelectedItemCollection {
        const items = this.all().filter((selected) => !selected.isEqualClientId(clientId));
        return new ClientSelectedItemCollection(items);
    }

    filterByClientId(clientId: ClientId): ClientSelectedItemCollection {
        const items = this.all().filter((selected) => selected.isEqualClientId(clientId));
        return new ClientSelectedItemCollection(items);
    }

    filterByItemIds(itemIds: Id[]): ClientSelectedItemCollection {
        const items = this.all().filter(({ itemId }) => itemIds.includes(itemId));
        return new ClientSelectedItemCollection(items);
    }
}

import { TopNavigation } from '@framework/ui/elements/TopNavigation';
import { NotFound } from '@framework/ui/pages/NotFound';

type Props = {
    message?: string;
    linkTo?: string;
    linkToMessage?: string;
};

export const NotFoundPage: React.FC<Props> = ({ message, linkTo, linkToMessage }: Props) => {
    return (
        <div className="block h-screen w-full overflow-y-auto">
            <TopNavigation hideLoginButton={true} />
            <NotFound message={message} linkTo={linkTo} linkToMessage={linkToMessage} />
        </div>
    );
};

import { GroupId, ViewModelId, WorkspaceId } from '@schema-common/base';
import { ViewModelAssetCollectionForClone, ViewModelOperation } from '@view-model/domain/view-model';
import { ActionLogSender } from '@framework/action-log';
import { UserKey } from '@user/domain';
import { getOnboardingViewModelPayload } from './onboardingViewModel';
import { ViewModelClipboardPayload } from '@view-model/application/operation';
import { UserPagePathBuilder } from '../../UserPagePathBuilder';
import { ViewModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ViewModelCascadeRepository';

export const createOnboardingViewModel = async (
    groupId: GroupId,
    userKey: UserKey,
    personalWorkspaceId: WorkspaceId,
    actionLogSender: ActionLogSender
): Promise<ViewModelId | null> => {
    const onboardingViewModelId = await ViewModelOperation.createToRoot(
        personalWorkspaceId,
        'オンボーディングKATA',
        actionLogSender
    );
    if (!onboardingViewModelId) return null;

    const payload = getOnboardingViewModelPayload(userKey);
    const originalContent = ViewModelClipboardPayload.toContent(payload);
    const assets = await ViewModelAssetCollectionForClone.fetchByUrlList(originalContent.assetUrls());
    const [assetUrlMap] = await assets.cloneNew(
        groupId,
        onboardingViewModelId,
        userKey.id,
        UserPagePathBuilder.viewModelPage(onboardingViewModelId)
    );
    const onboardingContent = originalContent.cloneNew(assetUrlMap);

    const viewModelCascadeRepo = new ViewModelCascadeRepository(onboardingViewModelId);

    await viewModelCascadeRepo.saveContent(onboardingContent);

    return onboardingViewModelId;
};

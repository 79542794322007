import { useCallback } from 'react';
import { DBPath, usePersistedText } from '@framework/repository';
import { useCommandManager } from '@model-framework/command/useCommandManager';
import { UpdateCommand } from '@model-framework/command/UpdateCommand';
import { useUndoableTextEdit } from '@model-framework/command/useUndoableTextEdit';
import { isUndefined } from 'lodash';

/**
 * テキストプロパティの編集コマンドを発行するコールバックフック。
 *
 * テキスト編集の開始、編集中、編集完了をトリガーする関数を返す。
 * テキスト編集途中の内容をリアルタイムに同期しつつ、編集完了時に差分があればUndo可能なようにコマンドを実行する。
 *
 * @param path 対象テキストが永続化されたRTDBPath
 *
 * @returns {Object} state フックの戻り値
 * @returns {string|null} content 対象のテキスト値
 * @returns {function(text: string): void} onStartEdit テキスト編集開始時のトリガー関数
 * @returns {function(text: string): void} onEdit テキスト編集中のトリガー関数
 * @returns {function(): void} onEndEdit テキスト編集完了のトリガー関数。開始時のテキストから差分があれば、テキスト変更コマンドが実行される。
 */
export const useTextEditingCommand = (
    path: DBPath
): {
    content: string | null;
    onStartEdit(text: string): void;
    onEdit(text: string): void;
    onEndEdit(): void;
} => {
    const commandManager = useCommandManager();
    const [content, setContent, repository] = usePersistedText(path);

    const endEditCallback = useCallback(
        (initialContent: string, currentContent: string) => {
            // 変更がないときには何もしない
            if (initialContent === currentContent) return;

            const command = new UpdateCommand(initialContent, currentContent, repository);
            commandManager.execute(command);
        },
        [commandManager, repository]
    );
    const { onStartEdit, onEdit, onEndEdit } = useUndoableTextEdit(setContent, endEditCallback);

    return {
        content: isUndefined(content) ? null : content,
        onStartEdit,
        onEdit,
        onEndEdit,
    };
};

import { GroupEntity } from '@group/domain';
import {
    GroupMemberInvitationJSON,
    GroupMemberInvitationJSONOnWrite,
} from '@schema-app/group/member-invitations/{groupId}/{invitationId}/GroupMemberInvitationJSON';

export class InvitationSenderGroup {
    constructor(
        readonly groupId: string,
        readonly name: string,
        readonly imageUrl: string | null
    ) {}

    dump(): GroupMemberInvitationJSONOnWrite['senderGroup'] {
        const { groupId, name, imageUrl } = this;
        return { groupId, name, imageUrl };
    }

    static load(dump: GroupMemberInvitationJSON['senderGroup']): InvitationSenderGroup {
        const { groupId, name, imageUrl } = dump;
        return new this(groupId, name, imageUrl || null);
    }

    static fromGroup(group: GroupEntity): InvitationSenderGroup {
        const { id, name, imageUrl } = group;
        return new this(id, name.toString(), imageUrl);
    }
}

import { useCallback, useState } from 'react';
import { ModelCommentThread, ModelCommentEntity, ModelCommentAuthor } from '../../domain';
import { usePopupRef } from '@framework/hooks';
import { ModelCommentThreadOperation } from '../../adapter';
import { CommentPopupMenuContainer } from './CommentPopupMenuContainer';
import { CommentMenuContainer } from './CommentMenuContainer';
import { CommentMenuButtonContainer } from './CommentMenuButtonContainer';
import { Point, Size } from '@view-model/models/common/basic';
import { CommentMenuPositionMap } from '../../positions';
import { ModelCommentId } from '@schema-common/base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisV, faPen, faReply } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { UserPublicProfile } from '@user/PublicProfile';

type Props = {
    commentThread: ModelCommentThread;
    position: Point;
    primaryComment: ModelCommentEntity;
    threadOperation: ModelCommentThreadOperation;
    currentUserProfile: UserPublicProfile;
    onEditStart(id: ModelCommentId): void;
};

export const PrimaryCommentMenu: React.FC<Props> = ({
    commentThread,
    position,
    primaryComment,
    threadOperation,
    currentUserProfile,
    onEditStart,
}: Props) => {
    const isCommentEditable = primaryComment.isAuthorUser(currentUserProfile.id);
    const isResolved = commentThread.isResolved();
    const { isOpen, popupRef, handleClose, handleOpen } = usePopupRef<HTMLDivElement>(false);
    const [commentMenuSize, setCommentMenuSize] = useState(new Size(0, 0));
    const [commentPopupMenuSize, setCommentPopupMenuSize] = useState(new Size(0, 0));

    const handleCommentMenuSizeChange = (newSize: Size) => {
        setCommentMenuSize(newSize);
    };

    const handleCommentPopupMenuSizeChange = (newSize: Size) => {
        setCommentPopupMenuSize(newSize);
    };

    const handleDelete = useCallback(() => {
        handleClose();
        threadOperation.deleteThread().then();
    }, [handleClose, threadOperation]);

    const handleEdit = useCallback(() => {
        handleClose();
        onEditStart(primaryComment.id);
    }, [handleClose, onEditStart, primaryComment.id]);

    const handleResolve = useCallback(() => {
        handleClose();
        const author = ModelCommentAuthor.buildFromUser(currentUserProfile);
        threadOperation.resolve(author).then();
    }, [currentUserProfile, handleClose, threadOperation]);

    const handleReopen = useCallback(() => {
        handleClose();
        const author = ModelCommentAuthor.buildFromUser(currentUserProfile);
        threadOperation.reopen(author).then();
    }, [currentUserProfile, handleClose, threadOperation]);

    const commentMenuPositionMap = new CommentMenuPositionMap(position, commentMenuSize, commentPopupMenuSize);

    return (
        <>
            <CommentMenuContainer
                position={commentMenuPositionMap.getMenuButtonPosition()}
                isPrimaryComment={true}
                onSizeChange={handleCommentMenuSizeChange}
            >
                <CommentMenuButtonContainer>
                    <button className="text-brand hover:text-brand-dark" onClick={handleResolve} disabled={isResolved}>
                        <FontAwesomeIcon icon={faCheck} />
                    </button>
                    <button
                        className="ml-[10px] px-3 pb-[6px] pt-3 font-bold text-gray-700 hover:text-gray-800"
                        onClick={handleOpen}
                    >
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                </CommentMenuButtonContainer>
            </CommentMenuContainer>
            {isOpen && (
                <CommentMenuContainer
                    position={commentMenuPositionMap.getPopupMenuPosition()}
                    isPrimaryComment={true}
                    onSizeChange={handleCommentPopupMenuSizeChange}
                >
                    <CommentPopupMenuContainer popupRef={popupRef}>
                        {isResolved ? (
                            <>
                                <button
                                    className="rounded-[4px] border border-gray-400 hover:bg-gray-400"
                                    onClick={handleReopen}
                                    style={{ paddingBlock: 4, paddingInline: 12 }}
                                >
                                    <FontAwesomeIcon icon={faReply} /> 再開 | Resume
                                </button>
                                <button
                                    className="rounded-[4px] border border-gray-400 hover:bg-gray-400"
                                    onClick={handleDelete}
                                    style={{ paddingBlock: 4, paddingInline: 12 }}
                                >
                                    <div className="text-red-700">
                                        <FontAwesomeIcon icon={faTrashAlt} /> 削除 | Delete
                                    </div>
                                </button>
                            </>
                        ) : (
                            <>
                                {isCommentEditable && (
                                    <button
                                        className="rounded-[4px] border border-gray-400 hover:bg-gray-400"
                                        onClick={handleEdit}
                                        style={{ paddingBlock: 4, paddingInline: 12 }}
                                    >
                                        <FontAwesomeIcon icon={faPen} /> 編集 | Edit
                                    </button>
                                )}
                                <button
                                    className="rounded-[4px] border border-gray-400 hover:bg-gray-400"
                                    onClick={handleDelete}
                                    style={{ paddingBlock: 4, paddingInline: 12 }}
                                >
                                    <div className="text-red-700">
                                        <FontAwesomeIcon icon={faTrashAlt} /> 削除 | Delete
                                    </div>
                                </button>
                            </>
                        )}
                    </CommentPopupMenuContainer>
                </CommentMenuContainer>
            )}
        </>
    );
};

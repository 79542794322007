import { SubMenuListContainer } from '@model-framework/menu';
import { LinkMarkMainMenu } from './LinkMarkMainMenu';
import { LinkMarkStyleSubMenu } from './LinkMarkStyleSubMenu';
import { LinkMarkStyle } from '../LinkMarkStyle';

type Props = {
    menuSize: number;
    currentMarkStyle?: LinkMarkStyle; // 複数選択時に一意に決まらないことがある
    items: Readonly<LinkMarkStyle[]>;
    isOpen: boolean;
    onMenuClicked(): void;
    onSelected(selectedMarkStyle: LinkMarkStyle): void;
    text: string;
};

export const LinkMarkStyleMenuView: React.FC<Props> = ({
    menuSize,
    currentMarkStyle,
    items,
    isOpen,
    onMenuClicked,
    onSelected,
    text,
}: Props) => {
    const rectSize = {
        width: menuSize * items.length,
        height: menuSize,
    };

    return (
        <g>
            <LinkMarkMainMenu menuSize={menuSize} isSelected={isOpen} onClick={onMenuClicked} text={text} />
            {isOpen && (
                <SubMenuListContainer menuSize={menuSize} rectSize={rectSize}>
                    {items.map((markStyle, i) => (
                        <g key={markStyle} transform={`translate(${menuSize * i}, 0)`}>
                            <LinkMarkStyleSubMenu
                                markStyle={markStyle}
                                menuSize={menuSize}
                                isSelected={markStyle === currentMarkStyle}
                                onClick={() => onSelected(markStyle)}
                            />
                        </g>
                    ))}
                </SubMenuListContainer>
            )}
        </g>
    );
};

import { RefObject } from 'react';
import { pointer } from 'd3-selection';
import { D3DragEvent } from 'd3-drag';

export class D3Mouse {
    constructor(private readonly ref: RefObject<SVGElement>) {
        this.ref = ref;
    }

    getPosition(event: MouseEvent | D3DragEvent<Element, unknown, unknown>): [number, number] {
        return pointer(event, this.ref.current);
    }
}

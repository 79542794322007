import { useEffect } from 'react';
import { useCurrentUserPublicProfile } from './PublicProfile';
import { TrackJS } from 'trackjs';
import { getClientId } from '@framework/app';
import { FirebaseAnalyticsOperation } from '@framework/firebase/analytics';

export const UserMetadata: React.FC = () => {
    const profile = useCurrentUserPublicProfile();

    useEffect(() => {
        // ブラウザのタブごとに発行されるユニークなClientIdをセッションIDとして利用する。
        const sessionId = getClientId();

        if (profile) {
            TrackJS.configure({
                userId: profile.id,
                sessionId,
            });
            TrackJS.addMetadata('UserName', profile.name);

            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get('error-report')) {
                console.error('エラーレポートのテストです');
            }
        } else {
            TrackJS.configure({
                userId: '',
                sessionId,
            });
            TrackJS.removeMetadata('UserName');
        }
    }, [profile]);

    useEffect(() => {
        FirebaseAnalyticsOperation.setUserId(profile?.id || '');
    }, [profile]);

    return <></>;
};

import { faGlobe, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@framework/ui/atoms/Tooltip';
import { WorkspaceInternalPublicMemberRoleJSON } from '@schema-common/workspace';

type Props = {
    isPublicSpace: boolean;
    isPersonal: boolean;
    internalPublicSetting: WorkspaceInternalPublicMemberRoleJSON | null;
};

export const WorkspacePublicStatusIcon: React.FC<Props> = ({
    isPublicSpace,
    isPersonal,
    internalPublicSetting,
}: Props) => {
    if (isPublicSpace) {
        return (
            <Tooltip tooltip="外部公開中" placement="bottom">
                <FontAwesomeIcon icon={faGlobe} />
            </Tooltip>
        );
    }

    // グループ内公開ワークスペース、または、パーソナルワークスペースの場合には、アイコンを表示しない
    if (internalPublicSetting || isPersonal) {
        return null;
    }

    return (
        <Tooltip tooltip="プライベートワークスペース" placement="bottom">
            <FontAwesomeIcon icon={faLock} />
        </Tooltip>
    );
};

import { RefObject, useEffect } from 'react';

/**
 * EnterキーでHTMLElement（input要素）のblurを発火するフック
 * @param ref 対象要素のref
 * @param requireCtrl 確定時にCtrlキー押下も一緒に必要かどうか（default: false）
 * @param callback 確定時のコールバック（オプション）
 */
export const useOnEnterCallback = <T extends HTMLElement | SVGElement>(
    ref: RefObject<T>,
    requireCtrl = false,
    callback?: () => void
): void => {
    useEffect(() => {
        const dom = ref.current;

        const onKeyDown = (evt: Event) => {
            /**
             * DOM の addEventListener に渡す関数のインタフェースは (evt: Event): void; と定義されている。
             * 一方で、 keydown イベントの際には、 Event インタフェースを実装した KeyboardEvent が渡される。
             *
             * TypeScript の lib.dom.d.ts がイベント名称に応じてイベントインタフェースを推論できるようになるまでは、
             * Event 型として受け取った値を KeyboardEvent 型に Type Assertion する形で回避する。
             */
            const event = evt as KeyboardEvent;

            /**
             * IME変換途中であれば早期 return
             */
            if (event.isComposing) {
                return;
            }

            /**
             * SafariはIME確定後に追加の keydown イベント (keyCode=229, key=Enter) が発火する
             * この keydown イベントで blur してしまうと、意図しない動きになってしまう
             *
             * keyCode は deprecated なプロパティであるが、他に低コストな代替策が無いので、お茶を濁す
             *
             * 参考サイト:
             *  - https://qiita.com/darai0512/items/fac4f166c23bf2075deb
             *  - https://www.stum.de/2016/06/24/handling-ime-events-in-javascript/
             *  - https://tanishiking24.hatenablog.com/entry/ime-event-handling-javascript
             */
            if (event.keyCode === 229) {
                return;
            }

            if (event.key === 'Enter') {
                const withMetaKey = event.ctrlKey || event.metaKey;
                if (requireCtrl && !withMetaKey) {
                    return;
                }

                dom?.blur();
                if (callback) {
                    callback();
                }
            }
        };

        dom?.addEventListener('keydown', onKeyDown);

        return () => {
            dom?.removeEventListener('keydown', onKeyDown);
        };
    }, [ref, requireCtrl, callback]);
};

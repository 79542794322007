/**
 * リンク接続を表すデータクラス
 */
import { LinkId } from './types';
import { NodeId, StickyZoneId } from '@schema-common/base';

export class Link {
    constructor(
        public readonly id: LinkId,
        public readonly src: NodeId | StickyZoneId,
        public readonly dest: NodeId | StickyZoneId
    ) {}
}

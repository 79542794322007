import { useGetBookmarkedViewModelIdsContextValues } from '../hooks';
import { BookmarkedViewModelIdsContext } from '../contexts';

type Props = {
    children: React.ReactNode;
};

export const WithBookmark: React.FC<Props> = ({ children }: Props) => {
    const contextValues = useGetBookmarkedViewModelIdsContextValues();

    return (
        <BookmarkedViewModelIdsContext.Provider value={contextValues}>
            {children}
        </BookmarkedViewModelIdsContext.Provider>
    );
};

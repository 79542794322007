import { useCallback, useEffect, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { Input } from '@framework/ui/atoms/Input';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { WorkspaceInternalPublicMemberRoleJSON } from '@schema-common/workspace';

type InternalPublicSetting = WorkspaceInternalPublicMemberRoleJSON | null;
const internalPublicSettingOptions: Record<WorkspaceInternalPublicMemberRoleJSON, string> = {
    editor: '変更可能 (編集者)',
    viewer: '閲覧のみ (閲覧者)',
};

type Props = {
    groupId: string;
    userId: string;
    isOpen: boolean;
    onClose(): void;
};

export const CreateWorkspaceModal: React.FC<Props> = ({ groupId, userId, isOpen, onClose }: Props) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isCreatable, setIsCreatable] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [name, setName] = useState<string>('');

    // グループ内公開設定
    const [internalPublicSetting, setInternalPublicSetting] = useState<InternalPublicSetting>(null);
    const handlePrivateWorkspace = useCallback(() => setInternalPublicSetting(null), []);
    const handleInternalPublicWorkspace = useCallback(() => setInternalPublicSetting('editor'), []);
    const handleChangeInternalSetting = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (value in internalPublicSettingOptions) {
            setInternalPublicSetting(value as InternalPublicSetting);
        }
    }, []);

    const handleClose = useCallback(() => {
        setIsProcessing(false);
        setName('');
        setInternalPublicSetting(null);
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        setIsProcessing(true);

        // ワークスペースを作成してからグループ内公開設定を更新する
        const workspaceId = await WorkspaceOperation.create(name, groupId, userId);
        if (!workspaceId) {
            setErrorMessage('エラーが発生しました。');
            setIsProcessing(false);
            return;
        }

        const result = await WorkspaceOperation.updateInternalPublicSetting(workspaceId, internalPublicSetting);
        if (result) {
            handleClose();
        } else {
            setErrorMessage('エラーが発生しました。');
            setIsProcessing(false);
        }
    }, [name, groupId, userId, internalPublicSetting, handleClose]);

    useEffect(() => {
        setIsCreatable(name.length > 0);
    }, [name]);

    const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            title="ワークスペースを作成"
            submitButton={
                <Button color={'primary'} onClick={handleSubmit} disabled={!isCreatable} loading={isProcessing}>
                    保存
                </Button>
            }
            cancelButton={
                <Button color={'secondary'} onClick={handleClose} disabled={isProcessing}>
                    キャンセル
                </Button>
            }
        >
            <div className="p-2">
                <Input
                    type="text"
                    placeholder="ワークスペース名"
                    value={name}
                    onChange={handleChangeName}
                    disabled={isProcessing}
                    autoFocus={true}
                />
                {errorMessage && <div className="text-sm text-red-700">{errorMessage}</div>}
            </div>

            <div className="p-2">
                <label className="block cursor-pointer">
                    <input
                        type="radio"
                        name="internal-setting"
                        className="form-radio mr-1 size-6 border-brand"
                        checked={internalPublicSetting === null}
                        onChange={handlePrivateWorkspace}
                        disabled={isProcessing}
                    />
                    プライベートワークスペース
                </label>
                <span className="ml-8 py-1 text-sm">ワークスペースにアクセス可能なユーザを個別に設定します。</span>
            </div>

            <div className="p-2">
                <label className="block cursor-pointer">
                    <input
                        type="radio"
                        name="internal-setting"
                        className="form-radio mr-1 size-6 border-brand"
                        checked={internalPublicSetting !== null}
                        onChange={handleInternalPublicWorkspace}
                        disabled={isProcessing}
                    />
                    グループ内公開ワークスペース
                </label>
                <span className="ml-8 py-1 text-sm">グループのユーザは全員アクセス可能にします。</span>

                <div className="ml-8">
                    <select
                        className="form-select block w-64 py-1"
                        defaultValue={internalPublicSetting || ''} // defaultValue に null を指定できないため空文字列に fallback する
                        onChange={handleChangeInternalSetting}
                        disabled={isProcessing || internalPublicSetting == null}
                    >
                        {Object.entries(internalPublicSettingOptions).map(([value, label]) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </GeneralModal>
    );
};

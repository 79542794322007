import { useCallback, useState } from 'react';
import { FolderId } from '@schema-common/base';
import { FolderItem } from '@workspace/view-model-folder/ui/FolderItem';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { FolderContainerCollection } from '@workspace/view-model-folder/domain/FolderContainer';
import { useDuplicateFolder } from '../../hooks';

type Props = {
    dropTargetFolderId: FolderId | null;
    folderContainers: FolderContainerCollection;
    showEditMenu: boolean;
    workspace: Workspace;
};

export const FolderList: React.FC<Props> = ({
    dropTargetFolderId,
    folderContainers,
    showEditMenu,
    workspace,
}: Props) => {
    const [duplicatingFolderIdList, setDuplicatingFolderIdList] = useState<FolderId[]>([]);
    const addFolderIdToList = useCallback((targetId: FolderId) => {
        setDuplicatingFolderIdList((prevState) => {
            return [...prevState, targetId];
        });
    }, []);

    const removeFolderIdFromList = useCallback((targetId: FolderId) => {
        setDuplicatingFolderIdList((prevState) => {
            return prevState.filter((id) => id !== targetId);
        });
    }, []);

    const handleDuplicateFolder = useDuplicateFolder({
        workspace,
        beforeDuplicatingFunc: addFolderIdToList,
        afterDuplicatingFunc: removeFolderIdFromList,
    });

    return (
        <>
            {folderContainers.all().map((folderContainer) => {
                return (
                    <FolderItem
                        key={folderContainer.folderId}
                        isDropTarget={folderContainer.folderId === dropTargetFolderId}
                        isDuplicating={duplicatingFolderIdList.includes(folderContainer.folderId)}
                        showEditMenu={showEditMenu}
                        workspace={workspace}
                        folderContainer={folderContainer}
                        onDuplicateFolder={handleDuplicateFolder}
                    />
                );
            })}
        </>
    );
};

import { useCallback } from 'react';
import { BasicItemMenuButton } from '../common';
import { GroupMemberInvitation, GroupMemberInvitationOperation } from '@group/domain';
import { toast } from 'react-hot-toast';

type Props = {
    invitation: GroupMemberInvitation;
};

export const ItemMenuButton: React.FC<Props> = ({ invitation }: Props) => {
    const handleDeactivate = useCallback(async () => {
        if (await GroupMemberInvitationOperation.deactivate(invitation)) {
            toast.success('招待を無効化しました');
        } else {
            toast.error('招待の無効化に失敗しました');
        }
    }, [invitation]);

    return <BasicItemMenuButton menuTitle="招待を取り消す" onClickMenu={handleDeactivate} />;
};

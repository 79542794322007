type Props = {
    movable: boolean;
    width: number;
    height: number;
    isResolved?: boolean;
};

export const ModelCommentDragHandler: React.FC<Props> = ({ movable, width, height, isResolved }: Props) => {
    return (
        <foreignObject width={width} height={height}>
            <div
                className={isResolved ? 'size-full rounded-t-2xl bg-gray-100' : 'size-full rounded-t-2xl bg-brand'}
                style={{ cursor: movable ? 'move' : 'auto' }}
            />
        </foreignObject>
    );
};

import { Timestamp } from '@framework/Timestamp';
import { Button } from '@framework/ui/atoms/Button';
import { Input } from '@framework/ui/atoms/Input';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { useCallback, useEffect, useState } from 'react';

type Props = {
    isOpen: boolean;
    onSubmit(name: string): void;
    onClose(): void;
};

export const ViewModelCreateModal: React.FC<Props> = ({ isOpen, onSubmit, onClose }: Props) => {
    const [name, setName] = useState<string>(Timestamp.now().format('YYYY-MM-DD HH:mm'));
    const [canSave, setCanSave] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpen) return;
        setName(Timestamp.now().format('YYYY-MM-DD HH:mm'));
    }, [isOpen]);

    useEffect(() => {
        setCanSave(name.trim() !== '');
    }, [name]);

    const handleSubmit = useCallback(() => {
        onSubmit(name);
        onClose();
    }, [name, onClose, onSubmit]);

    const handleClose = useCallback(() => {
        onClose();
        setName('');
    }, [onClose]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.nativeEvent.isComposing || e.key !== 'Enter' || !canSave) return;
        handleSubmit();
    };

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={handleClose}
            title={'ビューモデルを追加'}
            submitButton={
                <Button onClick={handleSubmit} disabled={!canSave}>
                    保存
                </Button>
            }
            cancelButton={
                <Button onClick={handleClose} color={'secondary'}>
                    キャンセル
                </Button>
            }
            shouldReturnFocusAfterClose={false}
        >
            <div className="p-2">
                <Input
                    type="text"
                    placeholder="ビューモデル名"
                    value={name}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    autoFocus={true}
                    autoSelect={true}
                />
            </div>
        </GeneralModal>
    );
};

import { Link } from '../Link';
import { NodeId, StickyZoneId } from '@schema-common/base';

/**
 * 単一の接続状態を表すクラス
 */
class Connector {
    constructor(
        public readonly connectorId: string,
        public readonly isConnected: boolean = false
    ) {}

    connect(connectorId: string): Connector {
        if (this.isConnected) return this;

        if (this.connectorId === connectorId) return new Connector(connectorId, true);

        return this;
    }
}

/**
 * 線の接続状態を表すクラス
 */
class LineConnector {
    constructor(
        public readonly lineId: string,
        private readonly src: Connector,
        private readonly dest: Connector
    ) {}

    connect(ids: string[]): LineConnector {
        let { src, dest } = this;

        for (const id of ids) {
            src = src.connect(id);
            dest = dest.connect(id);
        }

        return new LineConnector(this.lineId, src, dest);
    }

    isConnected(): boolean {
        return this.src.isConnected && this.dest.isConnected;
    }

    toLink(): Link {
        return new Link(this.lineId, this.src.connectorId, this.dest.connectorId);
    }
}

/**
 * リンクの描画順をコントロールする目的で、リンクの結線状態を管理するクラス
 *
 * 完全には結線されていないリンクを持ち、connect() メソッドにより結線されたものを除外していく。
 */
export class LinkPool {
    private lines: LineConnector[];

    static fromLinks(links: Link[]): LinkPool {
        const lines = links.map(
            (relation) => new LineConnector(relation.id, new Connector(relation.src), new Connector(relation.dest))
        );

        return new LinkPool(lines);
    }

    private constructor(lines: LineConnector[]) {
        this.lines = lines;
    }

    connectAndTakeConnectedLinks(ids: (NodeId | StickyZoneId)[]): Link[] {
        const lines = this.lines.map((line) => line.connect(ids));

        // まだ完全に接続されていない線は残して次回以降のconnect() に備える
        this.lines = lines.filter((line) => !line.isConnected());

        return lines.filter((line) => line.isConnected()).map((line) => line.toLink());
    }
}

import { GroupAnalysisSetting } from '@group/domain/GroupEntity';

type Props = {
    analysisSetting: GroupAnalysisSetting;
    onChange(analysisSetting: GroupAnalysisSetting): void;
};

export const AnalysisSettingForm: React.FC<Props> = ({ analysisSetting, onChange }: Props) => {
    const checked = analysisSetting.isAnalysisAvailable();
    const handleOnChange = () => {
        const newAnalysisSetting = analysisSetting.dump();
        newAnalysisSetting.availableAnalysis = !checked;
        onChange(GroupAnalysisSetting.load(newAnalysisSetting));
    };

    return (
        <>
            <div className="px-2 pb-2">
                <p className="pb-2">分析機能とは、AIを使用してビューの内容を要約したりモデル化したりする機能です。</p>
                <p>OpenAI社のAPIを使用し付箋等の内容を提供した上で分析を行います。</p>
                <p>
                    OpenAI社のポリシーに基づき、内容が30日間を超えてOpenAI社に保存されないことを確認しておりますが、御社のセキュリティポリシーに則り本機能のご利用可否の判断をお願いします。
                </p>
            </div>
            <div className="cursor-pointer p-2 hover:bg-gray-200" onClick={handleOnChange}>
                <input
                    type="checkbox"
                    className="form-checkbox size-4 cursor-pointer border-brand"
                    checked={checked}
                    onChange={handleOnChange}
                />
                <span className="px-2">上記に同意して、分析機能を使用可能にする</span>
            </div>
        </>
    );
};

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    href: string;
    label: string;
};

export const MenuLink: React.FC<Props> = ({ href, label }: Props) => {
    return (
        <a href={href} target="_blank" rel="noreferrer" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
            {label} <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />
        </a>
    );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { WorkspaceEntity, WorkspaceMemberRoles } from '@workspace/domain/workspace';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { MemberEditList } from './MemberEditList';
import { WorkspaceInternalPublicMemberRoleJSON } from '@schema-common/workspace';
import { RTDBPath, usePrimitiveValueListener } from '@framework/repository';

type Props = {
    isOpen: boolean;
    onClose(): void;
    workspace: WorkspaceEntity;
    currentUserId: string;
    onMemberAdd(): void;
    onMemberRemove(): void;
};

/**
 * 既存のワークスペースメンバーの役割(ロール)を変更するモーダル
 * @returns
 */
export const MemberEditModal: React.FC<Props> = ({
    isOpen,
    onClose,
    workspace,
    currentUserId,
    onMemberAdd,
    onMemberRemove,
}: Props) => {
    const [internalPublicSetting] = usePrimitiveValueListener<WorkspaceInternalPublicMemberRoleJSON>(
        RTDBPath.Group.internalPublicWorkspacePath(workspace.ownerGroupId, workspace.id)
    );

    const [memberRoles, setMemberRoles] = useState<WorkspaceMemberRoles>(workspace.memberRoles);
    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // 元の workspace.memberRoles から、役割が変更されたメンバーの情報のみを抽出する
    const changesMemberRoles = useMemo<WorkspaceMemberRoles>(() => {
        return workspace.memberRoles.difference(memberRoles);
    }, [memberRoles, workspace.memberRoles]);

    useEffect(() => {
        setMemberRoles(workspace.memberRoles);
    }, [workspace.memberRoles]);

    const handleClose = useCallback(() => {
        setMemberRoles(workspace.memberRoles);
        setProcessing(false);
        setErrorMessage('');

        onClose();
    }, [onClose, workspace.memberRoles]);

    const handleSubmit = useCallback(async () => {
        setProcessing(true);
        setErrorMessage('');

        if (await WorkspaceOperation.updateMemberRoles(workspace.id, changesMemberRoles)) {
            handleClose();
        } else {
            setProcessing(false);
            setErrorMessage('エラーが発生しました。');
        }
    }, [changesMemberRoles, handleClose, workspace.id]);

    const handleChange = useCallback((memberRoles: WorkspaceMemberRoles) => {
        setMemberRoles(memberRoles);
    }, []);

    const submitButton = (
        <Button
            color="primary"
            onClick={handleSubmit}
            disabled={changesMemberRoles.length === 0 || processing}
            loading={processing}
        >
            保存
        </Button>
    );

    const cancelButton = (
        <Button color="secondary" onClick={handleClose} disabled={processing}>
            キャンセル
        </Button>
    );

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={handleClose}
            title={`${workspace.name.value}のメンバー`}
            submitButton={submitButton}
            cancelButton={cancelButton}
        >
            {internalPublicSetting ? (
                <div className="m-2 rounded border border-yellow-700 bg-yellow-200 p-2 text-sm">
                    グループ内公開ワークスペースに設定されているため、メンバーの追加・削除を行うことはできません。
                    <br />
                    特定のメンバーにだけ権限を付与したい場合には、プライベートワークスペースに変更してください。
                </div>
            ) : (
                <div className="flex justify-end p-2">
                    <button
                        className="rounded border border-solid border-brand px-2 py-1 text-sm text-brand hover:bg-gray-200"
                        onClick={onMemberAdd}
                    >
                        メンバーを追加
                    </button>
                    <button
                        className="ml-2 rounded border border-solid border-red-700 px-2 py-1 text-sm text-red-700 hover:bg-gray-200"
                        onClick={onMemberRemove}
                    >
                        メンバーを削除
                    </button>
                </div>
            )}
            <div
                className="mb-4 mt-2 overflow-y-auto p-2"
                style={{ width: '600px', minHeight: '250px', maxHeight: '50vh' }}
            >
                <MemberEditList currentUserId={currentUserId} memberRoles={memberRoles} onChange={handleChange} />
            </div>
            {errorMessage && <div className="p-2 text-red-700">{errorMessage}</div>}
        </GeneralModal>
    );
};

import { classNames } from '@framework/utils';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    onClick(): void;
    icon: IconDefinition;
    title: string;
    className?: string;
};

export const MenuButton: React.FC<Props> = ({ onClick, icon, title, className }: Props) => {
    return (
        <button
            onClick={onClick}
            className={classNames(
                'flex w-full cursor-pointer items-center hover:bg-gray-200 focus:outline-none',
                className
            )}
        >
            <span className="w-10 pl-2">
                <FontAwesomeIcon icon={icon} />
            </span>
            <span className="py-2 pr-2">{title}</span>
        </button>
    );
};

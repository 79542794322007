import { SimpleFooterLayout } from '@user/layouts/SimpleFooterLayout';
import BalusLogo from '@assets/balus-logo-brand-horizontal.svg';

type Props = {
    children: React.ReactNode;
};

export const Layout: React.FC<Props> = ({ children }: Props) => {
    return (
        <SimpleFooterLayout>
            <div className="flex w-screen flex-col items-center p-8">
                <img src={BalusLogo} className="w-64" />
                <div className="py-8 text-3xl font-bold">Balusのグループに参加する</div>

                <div className="flex flex-col rounded-lg border border-gray-500 p-8" style={{ width: '600px' }}>
                    {children}
                </div>
            </div>
        </SimpleFooterLayout>
    );
};

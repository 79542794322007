import { ICommand } from '@model-framework/command';
import { NodeEntityOperation } from '../adapter';
import { NodeCollection } from '../domain';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class NodeCollectionUpdateCommand implements ICommand {
    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly nodeEntityOperation: NodeEntityOperation,
        private readonly lastNodes: NodeCollection,
        private readonly newNodes: NodeCollection
    ) {}

    do(): void {
        const { newNodes } = this;
        this.nodeEntityOperation.saveCollection(newNodes);
    }

    undo(): void {
        const { lastNodes } = this;
        this.nodeEntityOperation.saveCollection(lastNodes);
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        const currentNodes = NodeEntityOperation.getNodeCollection(this.newNodes.keys(), this.viewModel);
        return !!currentNodes && currentNodes.isEqual(this.newNodes);
    }

    async canRedo(): Promise<boolean> {
        const currentNodes = NodeEntityOperation.getNodeCollection(this.lastNodes.keys(), this.viewModel);
        return !!currentNodes && currentNodes.isEqual(this.lastNodes);
    }
}

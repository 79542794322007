import { ModelCommentAuthor, ModelCommentThread } from '../domain';
import { Position } from '@view-model/models/common/types/ui';
import { ModelCommentThreadDeleteCommand } from '../command';
import { CommandManager } from '@model-framework/command';
import { IPositionSetRepository, PositionSetRepository } from '@view-model/models/common/PositionSet';
import { ModelCommentThreadRepository } from '@view-model/models/sticky/ModelComment';
import { ModelCommentId, ModelCommentThreadId, ModelId, ViewModelId } from '@schema-common/base';
import { ModelCommentJSON } from '@schema-common/view-model';
import { RTDBPath } from '@framework/repository';

export class ModelCommentThreadOperation {
    private readonly threadRepository: ModelCommentThreadRepository;
    private readonly positionRepository: IPositionSetRepository;

    constructor(
        viewModelId: ViewModelId,
        modelId: ModelId,
        private readonly threadId: ModelCommentThreadId,
        private readonly commandManager: CommandManager
    ) {
        this.threadRepository = new ModelCommentThreadRepository(viewModelId, modelId);

        this.positionRepository = new PositionSetRepository(RTDBPath.Comment.positionsPath(viewModelId, modelId));
    }

    private async fetchThread(): Promise<ModelCommentThread | undefined> {
        return this.threadRepository.getOne(this.threadId);
    }

    private async fetchPosition(): Promise<Position | undefined> {
        const position = await this.positionRepository.getMany([this.threadId]);
        return position.find(this.threadId);
    }

    async deleteThread(): Promise<void> {
        const thread = await this.fetchThread();
        const position = await this.fetchPosition();
        if (!thread || !position) return;

        const command = new ModelCommentThreadDeleteCommand(
            thread,
            position,
            this.threadRepository,
            this.positionRepository
        );
        this.commandManager.execute(command);
    }

    async resolve(author: ModelCommentAuthor): Promise<void> {
        const thread = await this.fetchThread();
        if (!thread) return;

        await this.threadRepository.saveOne(thread.withStatus('Resolved'));
        await this.saveReplyComment(`> 解決済みです | Resolved`, author, 'System');
    }

    async reopen(author: ModelCommentAuthor): Promise<void> {
        const thread = await this.fetchThread();
        if (!thread) return;

        await this.threadRepository.saveOne(thread.withStatus('Open'));
        await this.saveReplyComment(`> 再開しました | Resumed`, author, 'System');
    }

    async addReplyComment(body: string, author: ModelCommentAuthor): Promise<void> {
        await this.saveReplyComment(body, author, 'Regular');
    }

    private async saveReplyComment(
        body: string,
        author: ModelCommentAuthor,
        type: ModelCommentJSON['type']
    ): Promise<void> {
        const thread = await this.fetchThread();
        if (!thread) return;

        await this.threadRepository.saveOne(thread.addReplyComment(author, body, type));
    }

    async updateCommentBody(commentId: ModelCommentId, body: string): Promise<void> {
        const thread = await this.fetchThread();
        if (!thread) return;

        await this.threadRepository.saveOne(thread.withCommentBody(commentId, body));
    }

    async deleteComment(commentId: ModelCommentId): Promise<void> {
        const thread = await this.fetchThread();
        if (!thread) return;

        await this.threadRepository.saveOne(thread.removeReplyComment(commentId));
    }
}

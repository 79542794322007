import { Id } from '@framework/domain';
import { INodeShape } from './INodeShape';

interface ShapeEntry<T extends INodeShape> {
    id: Id;
    shape: T;
}

export class ShapeMap<T extends INodeShape> {
    private readonly map: Readonly<Record<Id, T>>;

    constructor(shapes: ShapeEntry<T>[] = []) {
        this.map = shapes.reduce(
            (result, { id, shape }) => {
                result[id] = shape;
                return result;
            },
            {} as Record<Id, T>
        );
    }

    get length(): number {
        return Object.keys(this.map).length;
    }

    get(id: Id): T | undefined {
        return this.map[id];
    }

    set(id: Id, shape: T): ShapeMap<T> {
        return this.setMany([{ id, shape }]);
    }

    setMany(entries: ShapeEntry<T>[]): ShapeMap<T> {
        return new ShapeMap(this.entries().concat(...entries));
    }

    merge(other: ShapeMap<T>): ShapeMap<T> {
        return new ShapeMap(this.entries().concat(...other.entries()));
    }

    private entries(): ShapeEntry<T>[] {
        return Object.entries(this.map).map(([id, shape]) => ({ id, shape }));
    }
}

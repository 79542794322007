import { Key } from '@framework/domain';
import { nanoid } from 'nanoid';

export class WorkspaceKey extends Key {
    protected get KINDS(): string[] {
        return ['Workspace'];
    }

    public static buildFromID(id: string): WorkspaceKey {
        return new WorkspaceKey(`Workspace:${id}`);
    }

    public static buildNew(): WorkspaceKey {
        return WorkspaceKey.buildFromID(`${nanoid()}`);
    }

    public get workspaceID(): string {
        return `${this.id}`;
    }

    public dump(): string {
        return this.toString();
    }

    public static load(dump: string): WorkspaceKey {
        return new WorkspaceKey(dump);
    }
}

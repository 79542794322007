import { ObjectRepository, RTDBPath, RefBuilder, ServerValue } from '@framework/repository';
import { FolderId, ViewModelId, WorkspaceId } from '@schema-common/base';
import { SharingUserRoleJSON } from '@schema-common/view-model';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { Workspace } from '@workspace/domain/workspace';
import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import { RootFolderTreeRepository } from '@workspace/view-model-folder/infrastructure/FolderTree';

type CreateLogSender = (
    eventName: 'view_model:create',
    params: { viewModelId: ViewModelId; viewModelName: string }
) => void;

type UpdateLogSender = (
    eventName: 'view_model:edit',
    params: {
        viewModelId: ViewModelId;
        viewModelName: string;
        viewModelShareSetting: string;
        viewModelIsLocked: boolean;
    }
) => void;

type MoveToOtherWorkspaceLogSender = (
    eventName: 'view_model:move_to_other_workspace',
    params: { viewModelId: ViewModelId; sourceWorkspaceId: WorkspaceId; destinationWorkspaceId: WorkspaceId }
) => void;

type DeleteLogSender = (eventName: 'view_model:delete', params: { viewModelId: ViewModelId }) => void;
type RestoreLogSender = (eventName: 'view_model:restore', params: { viewModelId: ViewModelId }) => void;

export class ViewModelOperation {
    /**
     * 指定フォルダに対してビューモデルを作成する
     * @param workspaceId
     * @param folderId
     * @param name
     * @param logSender ビューモデル作成イベントログの送信関数。ログ記録が不要の場合には null を指定する。
     */
    static async create(
        workspaceId: WorkspaceId,
        folderId: FolderId,
        name: string,
        logSender: CreateLogSender
    ): Promise<ViewModelId> {
        const workspace = await new ObjectRepository(Workspace, RTDBPath.Workspace.workspacePath(workspaceId)).get();
        if (!workspace) {
            throw new Error(`Workspace not found. workspaceId=${workspaceId}`);
        }
        const groupId = workspace.ownerGroupId;

        const entity = ViewModelEntity.buildNew({ groupId, workspaceId, name });
        const viewModelId = entity.id;

        // ビューモデルエンティティを保存し、フォルダツリーに登録する
        await new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(viewModelId)).save(entity);
        const rootFolderTreeRepository = new RootFolderTreeRepository(workspaceId);
        await rootFolderTreeRepository.addViewModel(folderId, viewModelId);

        logSender('view_model:create', { viewModelId, viewModelName: name });

        return viewModelId;
    }

    /**
     * 指定ワークスペースのルート階層に対してビューモデルを作成する
     * @param workspaceId
     * @param name
     * @param logSender
     * @returns
     */
    static async createToRoot(
        workspaceId: WorkspaceId,
        name: string,
        logSender: CreateLogSender
    ): Promise<ViewModelId> {
        return await this.create(workspaceId, RootFolderTree.ROOT_ID, name, logSender);
    }

    /**
     * 指定のビューモデルの名前、URL共有の設定を更新する
     */
    static async update(
        viewModelId: ViewModelId,
        name: string,
        sharingUserRole: SharingUserRoleJSON,
        isLocked: boolean,
        logSender: UpdateLogSender
    ): Promise<void> {
        await RefBuilder.ref(RTDBPath.ViewModel.viewModelPath(viewModelId)).update({
            name,
            sharingUserRole,
            isLocked,
            updatedAt: ServerValue.TIMESTAMP,
        });

        logSender('view_model:edit', {
            viewModelId,
            viewModelName: name,
            viewModelShareSetting: sharingUserRole,
            viewModelIsLocked: isLocked,
        });
    }

    /**
     * ビューモデルをワークスペースを跨いで移動させる
     * @param sourceWorkspaceId 移動元のワークスペースID
     * @param destinationWorkspaceId 移動先のワークスペースID
     * @param viewModelId
     * @param logSender
     * @returns
     */
    static async moveToOtherWorkspace(
        sourceWorkspaceId: WorkspaceId,
        destinationWorkspaceId: WorkspaceId,
        viewModelId: ViewModelId,
        logSender: MoveToOtherWorkspaceLogSender
    ): Promise<boolean> {
        const entity = await new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(viewModelId)).get();

        if (!entity) {
            console.warn(`ViewModelEntity not found. id: ${viewModelId}`);
            return false;
        }

        try {
            // ビューモデル・エンティティの workspaceId プロパティを更新
            await RefBuilder.ref(RTDBPath.ViewModel.viewModelWorkspaceIdPath(viewModelId)).set(destinationWorkspaceId);

            // 移動元のフォルダツリーから削除する
            const sourceTree = new RootFolderTreeRepository(sourceWorkspaceId);
            await sourceTree.removeViewModel(viewModelId);

            // 移動先のフォルダツリーに追加する
            const destinationTree = new RootFolderTreeRepository(destinationWorkspaceId);
            await destinationTree.addViewModel(RootFolderTree.ROOT_ID, viewModelId);

            logSender('view_model:move_to_other_workspace', {
                sourceWorkspaceId,
                destinationWorkspaceId,
                viewModelId,
            });
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    /**
     * 指定されたビューモデルを論理削除する
     * @param viewModelId
     */
    static async delete(viewModelId: ViewModelId, logSender: DeleteLogSender): Promise<void> {
        const entity = await new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(viewModelId)).get();
        if (!entity) {
            throw new Error(`ViewModel(id=${viewModelId}) is not found.`);
        }

        await RefBuilder.ref(RTDBPath.ViewModel.trashedAtPath(viewModelId)).set(ServerValue.TIMESTAMP);

        const rootFolderTreeRepository = new RootFolderTreeRepository(entity.workspaceId);
        await rootFolderTreeRepository.removeViewModel(viewModelId);

        logSender('view_model:delete', { viewModelId });
    }

    /**
     * 指定されたビューモデルを削除済み状態から復元する
     */
    static async restore(viewModelId: ViewModelId, logSender: RestoreLogSender): Promise<void> {
        const entity = await new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(viewModelId)).get();
        if (!entity) {
            throw new Error(`ViewModel(id=${viewModelId}) is not found.`);
        }

        await RefBuilder.ref(RTDBPath.ViewModel.trashedAtPath(viewModelId)).set(null);

        const rootFolderTreeRepository = new RootFolderTreeRepository(entity.workspaceId);
        await rootFolderTreeRepository.addViewModel(RootFolderTree.ROOT_ID, viewModelId);

        logSender('view_model:restore', { viewModelId });
    }
}

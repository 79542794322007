import firebase from 'firebase/compat/app';
import { emulatorEnabled, FIREBASE_FUNCTIONS_EMULATOR_HOST } from '../../../emulatorConfiguration';
import { FirebaseConfig } from '@config/FirebaseConfig';

const functions = firebase.functions();

// ts-unused-exports:disable-next-line
export { functions as FirebaseFunctionsClient };

// ts-unused-exports:disable-next-line
export const GetFunctionsClient = () => firebase.functions();

type FunctionLocation = 'us-central1';

/**
 * Cloud Functions の HTTP 間数のURLを返します。
 *
 * HTTP間数のURLは、以下のドキュメントに記載の形で構成されます。
 * https://firebase.google.com/docs/functions/http-events?hl=ja#invoke_an_http_function
 *
 * @param name
 * @param location
 * @returns
 */
// ts-unused-exports:disable-next-line
export const FirebaseFunctionsUrlBuilder = (name: string, location: FunctionLocation = 'us-central1'): string => {
    const { projectId } = FirebaseConfig;
    if (emulatorEnabled()) {
        const [host, port] = FIREBASE_FUNCTIONS_EMULATOR_HOST.split(':', 2);
        return `http://${host}:${port}/${projectId}/${location}/${name}`;
    }

    return `https://${location}-${projectId}.cloudfunctions.net/${name}`;
};

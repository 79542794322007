import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';

type Props = {
    onAddBasicView(): void;
    onClose(): void;
};

export const EmptyMessageModal: React.FC<Props> = ({ onAddBasicView, onClose }: Props) => {
    return (
        <GeneralModal
            isOpen={true}
            onClose={onClose}
            title={'ビューを追加しましょう | Add a first view'}
            submitButton={<></>}
            cancelButton={
                <Button color="secondary" onClick={onClose}>
                    閉じる | Close
                </Button>
            }
        >
            <div className="flex items-center justify-center px-16 py-8">
                <button
                    className="w-full rounded border border-solid border-gray-500 px-4 py-2 font-bold text-brand hover:bg-gray-200"
                    onClick={() => {
                        onAddBasicView();
                        onClose();
                    }}
                >
                    ビューを追加 | Add view
                </button>
            </div>
        </GeneralModal>
    );
};

import { StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { LinkEntity, LinkCollection, LinkPlacementFactory } from '@view-model/models/sticky/StickyLink';
import { StickyZone } from '@view-model/models/sticky/StickyZoneView';
import { Rect } from '@view-model/models/common/basic';
import { LinkableTargetCollection } from '@view-model/domain/model';
import { ModelElementId } from '@view-model/domain/key';
import { INodeShape, ShapeMap } from '@model-framework/shape';
import { PositionSet } from '@view-model/models/common/PositionSet';

type Result = {
    nodes: StickyNode[];
    links: LinkEntity[];
    zones: StickyZone[];
};

export class StickyModelElementSelector {
    private readonly linkPlacementFactory: LinkPlacementFactory;

    constructor(
        private readonly nodes: StickyNode[],
        private readonly nodePositions: PositionSet,
        private readonly links: LinkEntity[],
        private readonly zones: StickyZone[],
        private readonly zonePositions: PositionSet,
        shapeMap: ShapeMap<INodeShape>
    ) {
        this.linkPlacementFactory = new LinkPlacementFactory(nodePositions, zonePositions, shapeMap);
    }

    /**
     * 指定の矩形領域に交差するモデル要素を返す
     * @param rect
     */
    selectByRect(rect: Rect): Result {
        const nodes = this.intersectsNodes(rect);
        const zones = this.intersectsZones(rect);
        // includedLinks() と intersectsLinks() の返す配列に含まれる要素は同一エンティティなので、 Set によるユニーク化が行える
        const links = Array.from(new Set([...this.includedLinks(nodes, zones), ...this.intersectsLinks(rect)]));

        return { nodes, links, zones };
    }

    selectIdsByRect(rect: Rect): ModelElementId[] {
        const { nodes, links, zones } = this.selectByRect(rect);
        return [...nodes.map(({ id }) => id), ...links.map(({ id }) => id), ...zones.map(({ id }) => id)];
    }

    /**
     * 矩形領域に交差するノード一覧を返す
     * @param rect
     * @private
     */
    private intersectsNodes(rect: Rect): StickyNode[] {
        return this.nodes.filter((node) => node.intersectsWithRect(rect));
    }

    /**
     * 矩形領域に交差するゾーン一覧を返す
     * @param rect
     * @private
     */
    private intersectsZones(rect: Rect): StickyZone[] {
        // FIXME: #2787 の対応で ZoneEntity と ZonePosition の整合しているものだけを対象にフィルタ操作を行うように変更する
        return this.zones.filter((zone) => {
            const zonePosition = this.zonePositions.find(zone.id);
            if (!zonePosition) return;
            return zone.intersectsWithRect(zonePosition, rect);
        });
    }

    /**
     * 指定のノード、ゾーンを結ぶリンク一覧を返す
     * @param nodes
     * @param zones
     * @private
     */
    private includedLinks(nodes: StickyNode[], zones: StickyZone[]): LinkEntity[] {
        const selected = new LinkableTargetCollection(nodes, zones);
        return new LinkCollection(this.links).validLinksBy(selected)?.entities() || [];
    }

    /**
     * 矩形領域に交差するリンク一覧を返す
     * @param rect
     * @private
     */
    private intersectsLinks(rect: Rect): LinkEntity[] {
        const result: LinkEntity[] = [];

        for (const link of this.links) {
            const linkPlacement = this.linkPlacementFactory.linkPlacementOf(link);
            if (!linkPlacement) continue;

            const bezierLine = linkPlacement.bezierLine(linkPlacement.bendingPoint(link.bend));
            if (bezierLine.intersects(rect)) {
                result.push(link);
            }
        }

        return result;
    }
}

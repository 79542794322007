import { ICommand } from '@model-framework/command';
import { LinkEntityOperation } from '../adapter';
import { LinkEntity } from '../domain';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class LinkDeleteCommand implements ICommand {
    private readonly link: LinkEntity;

    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly entityOperation: LinkEntityOperation,
        link: LinkEntity
    ) {
        this.link = link.clone();
    }

    do(): void {
        this.entityOperation.remove(this.link);
    }

    undo(): void {
        this.entityOperation.save(this.link);
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return !LinkEntityOperation.getLink(this.link.key, this.viewModel);
    }

    async canRedo(): Promise<boolean> {
        const currentLink = LinkEntityOperation.getLink(this.link.key, this.viewModel);
        return !!currentLink && currentLink.isEqual(this.link);
    }
}

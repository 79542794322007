import { Position, Size } from '@view-model/models/common/types/ui';
import Victor from 'victor';
import { Point } from './Point';
import { RectEdge } from '@view-model/models/common/basic/RectEdge';
import { ILayout } from '@view-model/ui/layouts';
import { Range } from './Range';
import { Direction } from './Direction';
import { Line } from '@view-model/models/common/basic/Line';
import { Size as SizeModel } from '@view-model/models/common/basic/Size';
import { DiagonallyDividedArea } from './Rect/DiagonallyDividedArea';
import { EdgeDividedArea } from './Rect/EdgeDividedArea';
import { PointJSON, SizeJSON } from '@schema-common/view-model';

const randRange = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min);

type RectJSON = {
    position: PointJSON;
    size: SizeJSON;
};

export class Rect {
    constructor(
        public readonly position: Readonly<Position>,
        public readonly size: Readonly<Size>
    ) {}

    dump(): RectJSON {
        const { position, size } = this;
        return { position, size };
    }

    static load(dump: RectJSON): Rect {
        const { position, size } = dump;
        return new Rect(position, size);
    }

    static fromLTRB(left: number, top: number, right: number, bottom: number): Rect {
        return new Rect(
            {
                x: left,
                y: top,
            },
            {
                width: right - left,
                height: bottom - top,
            }
        );
    }

    getLTRB(): { left: number; top: number; right: number; bottom: number } {
        const { x: left, y: top } = this.topLeft();
        const { x: right, y: bottom } = this.bottomRight();

        return {
            left,
            top,
            right,
            bottom,
        };
    }

    getXYWH(): { x: number; y: number; width: number; height: number } {
        // 以下のようにして取り出せるが長くなるのでシンタックスシュガーとして定義
        const {
            position: { x, y },
            size: { width, height },
        } = this;
        return { x, y, width, height };
    }

    static fromXYWH(x: number, y: number, width: number, height: number): Rect {
        return new Rect({ x, y }, { width, height });
    }

    static fromPositions(positions: [Position, Position]): Rect {
        const left = Math.min(positions[0].x, positions[1].x);
        const top = Math.min(positions[0].y, positions[1].y);
        const right = Math.max(positions[0].x, positions[1].x);
        const bottom = Math.max(positions[0].y, positions[1].y);

        return this.fromLTRB(left, top, right, bottom);
    }

    /**
     * すべてのポイントを包含する矩形を返します。
     * @param p1 座標1（必須）
     * @param p2 座標2（必須）
     * @param optionalPoints オプション座標
     */
    static fromPoints(p1: Point, p2: Point, ...optionalPoints: Point[]): Rect {
        const points = optionalPoints.concat(p1, p2);
        const xr = points.map((p) => p.x);
        const yr = points.map((p) => p.y);

        const left = Math.min(...xr);
        const top = Math.min(...yr);
        const right = Math.max(...xr);
        const bottom = Math.max(...yr);

        return this.fromLTRB(left, top, right, bottom);
    }

    /**
     * ポジション(0, 0)、サイズ(width, height) のRectを返します。
     * @param width
     * @param height
     */
    static fromSize(width: number, height: number): Rect {
        return new Rect({ x: 0, y: 0 }, { width, height });
    }

    static fromCenterPointSize(centerPoint: Point, size: Size): Rect {
        const { width, height } = size;
        return new Rect(centerPoint.addXY(-width / 2, -height / 2), size);
    }

    get x(): number {
        return this.position.x;
    }

    get y(): number {
        return this.position.y;
    }

    get width(): number {
        return this.size.width;
    }

    get height(): number {
        return this.size.height;
    }

    isEqual(other: Rect): boolean {
        return (
            this.position.x === other.position.x &&
            this.position.y === other.position.y &&
            this.size.width === other.size.width &&
            this.size.height === other.size.height
        );
    }

    /**
     * 幅と高さのうち、短い方の長さを返します。
     */
    shortestSideLength(): number {
        return Math.min(this.width, this.height);
    }

    topLeft(): Point {
        return Point.fromPosition(this.position);
    }

    topRight(): Point {
        return new Point(this.getRight(), this.getTop());
    }

    bottomLeft(): Point {
        return new Point(this.getLeft(), this.getBottom());
    }

    bottomRight(): Point {
        const { width, height } = this.size;
        const { x, y } = this.position;

        return new Point(x + width, y + height);
    }

    /**
     * 引数に指定した x,y が矩形領域に含まれるか判定する
     *
     * @param x
     * @param y
     */
    includePosition({ x, y }: Position): boolean {
        const [{ x: left, y: top }, { x: right, y: bottom }] = [this.topLeft(), this.bottomRight()];

        return left <= x && x <= right && top <= y && y <= bottom;
    }

    /**
     * 引数に指定した矩形がこの矩形に含まれるかを返す
     *
     * @param other
     */
    includeRect(other: Rect): boolean {
        return (
            this.x <= other.x &&
            this.getRight() >= other.getRight() &&
            this.y <= other.y &&
            this.getBottom() >= other.getBottom()
        );
    }

    /**
     * 同じサイズでポジションだけ移動した新しい Rect を返す
     *
     * @param position 移動先のポジション
     */
    withPosition(position: Position): Rect {
        return new Rect(position, this.size);
    }

    /**
     * 同じサイズでX座標だけ移動した新しい Rect を返す
     *
     * @param x
     */
    withX(x: number): Rect {
        const { y, size } = this;
        return new Rect({ x, y }, size);
    }

    /**
     *
     * 同じサイズでY座標だけ移動した新しい Rect を返す
     *
     * @param y
     */
    withY(y: number): Rect {
        const { x, size } = this;
        return new Rect({ x, y }, size);
    }

    /**
     * 同じサイズでポジションを dx, dy だけ移動した新しい Rect を返す
     *
     * @param dx x軸方向の移動量
     * @param dy y軸方向の移動量
     */
    movePosition(dx: number, dy: number): Rect {
        const { x, y } = this.position;
        return new Rect({ x: x + dx, y: y + dy }, this.size);
    }

    /**
     * 同じサイズでポジションをレイアウトにスナップした新しい Rect を返す
     *
     * @param layout
     */
    snapPositionToLayout(layout: ILayout): Rect {
        const position = layout.snapPosition(this.position);
        return new Rect(position, this.size);
    }

    /**
     * 矩形の各点の座標をレイアウトにスナップした Rect を返す
     * @param layout
     */
    snapTo(layout: ILayout): Rect {
        const p1 = this.topLeft();
        const p2 = this.bottomRight();

        return Rect.fromPositions([layout.snapPosition(p1), layout.snapPosition(p2)]);
    }

    /**
     * SizeObjectを返します
     */
    getSize(): SizeModel {
        return new SizeModel(this.size.width, this.size.height);
    }

    /**
     * 中心のX座標を返します
     */
    getCenterX(): number {
        return this.x + this.width / 2;
    }

    /**
     * 中心のY座標を返します
     */
    getCenterY(): number {
        return this.y + this.height / 2;
    }

    /**
     * 中心座標を返します
     */
    getCenterPoint(): Point {
        return new Point(this.getCenterX(), this.getCenterY());
    }

    /**
     * 領域内のランダムな座標を返します
     */
    getRandomPoint(): Point {
        const x = randRange(this.getLeft(), this.getRight());
        const y = randRange(this.getTop(), this.getBottom());
        return new Point(x, y);
    }

    /**
     * 矩形の中心点同士のベクトルを表すPointを返します
     * @param other
     */
    vectorBetweenCenters(other: Rect): Point {
        return other.getCenterPoint().subtract(this.getCenterPoint());
    }

    getLeft(): number {
        return this.x;
    }

    getRight(): number {
        return this.x + this.width;
    }

    getTop(): number {
        return this.y;
    }

    getBottom(): number {
        return this.y + this.height;
    }

    /**
     * 矩形の左辺の中央座標を返します。
     */
    leftCenter(): Point {
        return new Point(this.getLeft(), this.getCenterY());
    }

    /**
     * 矩形の右辺の中央座標を返します。
     */
    rightCenter(): Point {
        return new Point(this.getRight(), this.getCenterY());
    }

    /**
     * 矩形の上辺の中央座標を返します。
     */
    topCenter(): Point {
        return new Point(this.getCenterX(), this.getTop());
    }

    /**
     * 矩形の下辺の中央座標を返します。
     */
    bottomCenter(): Point {
        return new Point(this.getCenterX(), this.getBottom());
    }

    /**
     * 他のRectと交差しているかどうかを返します
     *
     * @param other
     */
    intersects(other: Rect): boolean {
        return !(
            this.x > other.getRight() ||
            this.getRight() < other.x ||
            this.y > other.getBottom() ||
            this.getBottom() < other.y
        );
    }

    /**
     * 渡された座標を含んでいるかどうかを返します
     *
     * @param point
     */
    include(point: Point): boolean {
        return this.x <= point.x && point.x <= this.getRight() && this.y <= point.y && point.y <= this.getBottom();
    }

    /**
     * 自身とotherを含む最小のRectを返します
     * @param other
     */
    union(other: Rect): Rect {
        const left = Math.min(this.x, other.x);
        const top = Math.min(this.y, other.y);
        const right = Math.max(this.getRight(), other.getRight());
        const bottom = Math.max(this.getBottom(), other.getBottom());

        return Rect.fromLTRB(left, top, right, bottom);
    }

    /**
     * この矩形の中心から dest に向かって直線を引いたときに交差する位置を返す
     * @param dest
     */
    intersectPointFromCenter(dest: Point): Point {
        // 線の方向の単位ベクトル計算
        const start = new Victor(this.getCenterX(), this.getCenterY());
        const end = new Victor(dest.x, dest.y);
        const e = end.subtract(start).norm();

        // 中心からの距離を利用するので幅高さを半分にする
        const halfW = this.width * 0.5;
        const halfH = this.height * 0.5;

        // 方向ベクトルが境界とぶつかる倍率を計算
        const xRate = Math.abs(halfW / e.x);
        const yRate = Math.abs(halfH / e.y);
        const rate = Math.min(xRate, yRate);

        const result = e.multiplyScalar(rate).add(start);
        return new Point(result.x, result.y);
    }

    /**
     * この矩形の中心から dest に向かって直線を引いたときに交差する辺を返す
     * @param dest
     */
    intersectEdgeFromCenter(dest: Point): RectEdge {
        // 矩形のサイズで方向ベクトルを正規化する
        const v = this.getCenterPoint().vectorTo(dest);
        const rx = Math.abs(v.x / this.width);
        const ry = Math.abs(v.y / this.height);

        // 正規化した上で成分が大きい方が先にエッジにあたるので、あとはベクトルの符号を見て辺を決める
        if (rx < ry) {
            return v.y < 0 ? RectEdge.Top : RectEdge.Bottom;
        }

        // （エッジケースの補足）
        // 幅、高さやベクトルのサイズが0だった場合、どの比較条件にも合致しないため Right が返る（ひとまずそれでよしとしている）
        return v.x < 0 ? RectEdge.Left : RectEdge.Right;
    }

    /**
     * 引数に与えられた座標 point を水平・垂直に移動した時に、この矩形と交差する座標を返します。
     * （与えられた座標 point から一番距離の近い交点の座標を返します）
     *
     * @param point
     */
    intersectPointByRightAngle(point: Point): Point {
        const left = new Point(this.getLeft(), point.y);
        const right = new Point(this.getRight(), point.y);
        const top = new Point(point.x, this.getTop());
        const bottom = new Point(point.y, this.getBottom());

        const points = [left, right, top, bottom].sort((a, b) => point.distance(a) - point.distance(b));
        return points[0];
    }

    /**
     * 中心をキープしたまま上下左右に指定されたマージンを適用したRectを返す
     *
     * 引数はCSSのmarginに準拠（1〜4つの可変）
     * https://developer.mozilla.org/ja/docs/Web/CSS/margin
     */
    applyMarginKeepingCenter(a: number, b?: number, c?: number, d?: number): Rect {
        const topMargin = a;
        const rightMargin = b || a;
        const bottomMargin = c || a;
        const leftMargin = d || b || a;

        return Rect.fromLTRB(
            this.getLeft() - leftMargin,
            this.getTop() - topMargin,
            this.getRight() + rightMargin,
            this.getBottom() + bottomMargin
        );
    }

    /**
     * 中心をキープしたまま上下左右に指定されたパディングを適用した内側のRectを返す
     *
     * @param padding
     */
    applyPaddingKeepingCenter(padding: number): Rect {
        return this.applyMarginKeepingCenter(-padding);
    }

    /**
     * 他の矩形にこの矩形をくっつける（接するように移動する）
     * @param other
     */
    stickTo(other: Rect): Rect {
        const direction = this.detectStickDirectionTo(other);
        return this.stickByDirection(other, direction);
    }

    /**
     * 領域中心への相対的な座標をPointとして返す
     */
    relativeToCenter(): Point {
        return new Point(this.width / 2, this.height / 2);
    }

    private detectStickDirectionTo(other: Rect): Direction {
        // この矩形の中心点の位置に応じて補正方法を変更する
        const centerPoint = this.getCenterPoint();

        // 1. 対象矩形のXY範囲内（矩形の内側も外側も含む）=> 対角線で切ったときの上下左右どの方向にあるかによって移動する
        if (other.horizontalRange().includes(centerPoint.x) || other.verticalRange().includes(centerPoint.y)) {
            return other.toDiagonallyDividedArea().directionOf(centerPoint);
        }

        // 2. 対象矩形と接しない位置の場合 => 当たり判定矩形を辺で分割したときの8方位によって移動する（近づく方向に移動）
        // 当たり判定矩形の基準（当たり判定に使う座標）はこの矩形の中心座標。
        // => 対象矩形に対してこの矩形の中心座標が、この矩形の幅と高さの半分離れるまでは衝突している。
        const collisionAreaRect = other.applyMarginKeepingCenter(this.height * 0.5, this.width * 0.5);

        if (!collisionAreaRect.includePosition(centerPoint)) {
            return collisionAreaRect.toEdgeDividedArea().directionOf(centerPoint);
        }

        // 3. それ以外（対象矩形の延長線上にはないが、対象矩形と重なる位置。位置に応じて縦方向か横方向どちらか片方に補正する）
        return this.findDirectionOnIntersectionalCorners(other);
    }

    /**
     * この矩形とotherの４隅が接触する場合の補正方角を見つける
     * @param other
     * @private
     */
    private findDirectionOnIntersectionalCorners(other: Rect): Direction {
        // この矩形の中心座標の位置を基準に判定する
        const centerPoint = this.getCenterPoint();

        // 方向判定処理を1回で済ますため、点の座標を判定にとって等価な位置（otherの右下隅）に移動する
        // （移動させるのはどこでもいいが、後続の演算で符号の扱いが楽な右下を選ぶ）
        const v = other.getCenterPoint().vectorTo(centerPoint);
        const point = other.getCenterPoint().add(v.abs());

        // この矩形のアスペクト比に応じた斜線によって移動方向を判定する
        const start = other.bottomRight();
        const line = new Line(start, start.addXY(this.width, this.height));
        // 線より上にある場合は横方向に移動。下にある場合は縦方向に移動する。
        //    ┌────┐
        //    │    │
        //    └────┘
        //           \
        //         x  \  x →
        //         ↓   \

        const directionMask = point.isAbove(line) ? Direction.Left | Direction.Right : Direction.Down | Direction.Up;

        // 実際の位置（otherに対してどの位置にいるか）を調べて移動方向を確定させる
        const direction = other.toEdgeDividedArea().directionOf(centerPoint);
        return direction & directionMask;
    }

    /**
     * 方向変数に応じて other に接するような位置に移動する。
     * directionは縦横両方の成分を含むことができる。
     *
     * @param other この矩形を移動して接触させる対象の矩形
     * @param direction otherの接触させたい辺の位置（Direction.UP ならotherの上辺に接するようにこの矩形を移動させる）
     * @private
     */
    private stickByDirection(other: Rect, direction: Direction): Rect {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let result: Rect = this;

        if (direction & Direction.Up) {
            result = result.withY(other.getTop() - this.height);
        } else if (direction & Direction.Down) {
            result = result.withY(other.getBottom());
        }

        if (direction & Direction.Left) {
            result = result.withX(other.getLeft() - this.width);
        } else if (direction & Direction.Right) {
            result = result.withX(other.getRight());
        }

        return result;
    }

    /**
     * 矩形を対角線で区切った場合の領域に変換します。
     */
    toDiagonallyDividedArea(): DiagonallyDividedArea {
        return DiagonallyDividedArea.fromRect(this);
    }

    /**
     * 矩形を辺で切ったときの領域（三目並べの目のように分割）に変換する
     */
    toEdgeDividedArea(): EdgeDividedArea {
        return EdgeDividedArea.fromRect(this);
    }

    horizontalRange(): Range {
        return new Range(this.getLeft(), this.getRight());
    }

    verticalRange(): Range {
        return new Range(this.getTop(), this.getBottom());
    }

    /**
     * 渡された矩形リストを包含する矩形を返します。
     *
     * 空配列が渡された場合はnullを返します。
     * @param rects
     */
    static union(rects: Rect[]): Rect | null {
        if (rects.length === 0) return null;

        return rects.reduce((prev, current) => prev.union(current));
    }

    /**
     * 矩形の上辺を移動することによって、この矩形のサイズを変更する。
     * @param dy Y軸方向の移動量
     * @param minHeight 最小高さ（dyがどんな値であってもこれ以上はheightが小さくならないことを保証する）
     */
    resizeByMovingTopSide(dy: number, minHeight = 0): Rect {
        const { left, top, right, bottom } = this.getLTRB();

        const newTop = bottom - (top + dy) < minHeight ? bottom - minHeight : top + dy;

        return Rect.fromLTRB(left, newTop, right, bottom);
    }

    /**
     * 矩形の下辺を移動することによって、この矩形のサイズを変更する。
     * @param dy Y軸方向の移動量
     * @param minHeight 最小高さ（dyがどんな値であってもこれ以上はheightが小さくならないことを保証する）
     */
    resizeByMovingBottomSide(dy: number, minHeight = 0): Rect {
        const { left, top, right, bottom } = this.getLTRB();

        const newBottom = bottom + dy - top < minHeight ? top + minHeight : bottom + dy;

        return Rect.fromLTRB(left, top, right, newBottom);
    }

    /**
     * 矩形の左辺を移動することによって、この矩形のサイズを変更する。
     * @param dx X軸方向の移動量
     * @param minWidth 返されるRectの最小幅（dxがどんな値であってもこれ以上はwidthが小さくならないことを保証する）
     */
    resizeByMovingLeftSide(dx: number, minWidth = 0): Rect {
        const { left, top, right, bottom } = this.getLTRB();

        const newLeft = right - (left + dx) < minWidth ? right - minWidth : left + dx;

        return Rect.fromLTRB(newLeft, top, right, bottom);
    }

    /**
     * 矩形の 右辺を移動することによって、この矩形のサイズを変更する。
     * @param dx X軸方向の移動量
     * @param minWidth 返されるRectの最小幅（dxがどんな値であってもこれ以上はwidthが小さくならないことを保証する）
     */
    resizeByMovingRightSide(dx: number, minWidth = 0): Rect {
        const { left, top, right, bottom } = this.getLTRB();

        const newRight = right + dx - left < minWidth ? left + minWidth : right + dx;

        return Rect.fromLTRB(left, top, newRight, bottom);
    }

    /**
     * 矩形の左上角を移動することによって、この矩形のサイズを変更する
     *
     * @param dx X軸方向の移動量
     * @param dy Y軸方向の移動量
     * @param minWidth リサイズ後矩形の最小幅
     * @param minHeight リサイズ後矩形の最小高さ
     */
    resizeByMovingTopLeftCorner(dx: number, dy: number, minWidth = 0, minHeight = 0): Rect {
        return this.resizeByMovingTopSide(dy, minHeight).resizeByMovingLeftSide(dx, minWidth);
    }

    /**
     * 矩形の右上角を移動することによって、この矩形のサイズを変更する
     *
     * @param dx X軸方向の移動量
     * @param dy Y軸方向の移動量
     * @param minWidth リサイズ後矩形の最小幅
     * @param minHeight リサイズ後矩形の最小高さ
     */
    resizeByMovingTopRightCorner(dx: number, dy: number, minWidth = 0, minHeight = 0): Rect {
        return this.resizeByMovingTopSide(dy, minHeight).resizeByMovingRightSide(dx, minWidth);
    }

    /**
     * 矩形の左下角を移動することによって、この矩形のサイズを変更する
     *
     * @param dx X軸方向の移動量
     * @param dy Y軸方向の移動量
     * @param minWidth リサイズ後矩形の最小幅
     * @param minHeight リサイズ後矩形の最小高さ
     */
    resizeByMovingBottomLeftCorner(dx: number, dy: number, minWidth = 0, minHeight = 0): Rect {
        return this.resizeByMovingBottomSide(dy, minHeight).resizeByMovingLeftSide(dx, minWidth);
    }

    /**
     * 矩形の右下角を移動することによって、この矩形のサイズを変更する
     *
     * @param dx X軸方向の移動量
     * @param dy Y軸方向の移動量
     * @param minWidth リサイズ後矩形の最小幅
     * @param minHeight リサイズ後矩形の最小高さ
     */
    resizeByMovingBottomRightCorner(dx: number, dy: number, minWidth = 0, minHeight = 0): Rect {
        return this.resizeByMovingBottomSide(dy, minHeight).resizeByMovingRightSide(dx, minWidth);
    }
}

import { faEllipsisVertical, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { MainMenuBox, MainMenuIcon, MenuLayout } from '@model-framework/menu';
import { Rect, Size } from '@view-model/models/common/basic';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WarningPopup } from '@model-framework/ui/WarningPopup';
import { useStickyNodeAlignInterval } from '@view-model/models/sticky/StickyNodeView/adapter';

type Props = {
    menuSize: number;
    isOpen: boolean;
    onMenuClicked(): void;
    onSelected(alignType: AlignType, interval: number): void;
    alignType: AlignType;
    text: string;
};
const MENU_HEIGHT = 80;
const MIN_INTERVAL = 1;
const MAX_INTERVAL = 100;
const TOTAL_MENU_SIZE = new Size(320, MENU_HEIGHT);
const INPUT_AREA_SIZE = new Size(92, MENU_HEIGHT);
const BUTTON_AREA_SIZE = new Size(TOTAL_MENU_SIZE.width - INPUT_AREA_SIZE.width, MENU_HEIGHT);

export type AlignType = 'vertical' | 'horizontal';

const AlignMenuIcon = { vertical: faEllipsisVertical, horizontal: faEllipsis };

export const AlignMenu: React.FC<Props> = ({ menuSize, isOpen, onMenuClicked, onSelected, alignType, text }: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { verticalInterval, setVerticalInterval, horizontalInterval, setHorizontalInterval } =
        useStickyNodeAlignInterval();
    const [interval, setInterval] = useState<number>(alignType === 'vertical' ? verticalInterval : horizontalInterval);
    const [isValid, setIsValid] = useState<boolean>(true);

    const validateInterval = (interval: number | null) => {
        if (!interval) {
            setIsValid(false);
            return;
        }
        try {
            setIsValid(interval >= MIN_INTERVAL && interval <= MAX_INTERVAL);
        } catch (_) {
            setIsValid(false);
        }
    };

    const handleFocus = () => {
        inputRef.current?.focus();
    };

    const handleChangeInterval = (e: React.ChangeEvent<HTMLInputElement>) => {
        const intervalSt = Number(e.target.value);
        if (intervalSt) setInterval(intervalSt);
        validateInterval(intervalSt);
    };

    const handleSelected = useCallback(() => {
        if (isValid && interval) {
            if (alignType === 'vertical') {
                setVerticalInterval(interval);
            } else {
                setHorizontalInterval(interval);
            }
            onSelected(alignType, interval);
        }
    }, [alignType, interval, isValid, onSelected, setHorizontalInterval, setVerticalInterval]);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                handleSelected();
            }
        },
        [handleSelected]
    );

    useEffect(() => {
        inputRef.current?.focus();
    }, [isOpen]);

    const canNotSaveInterval = !isValid || !interval;
    const position = MenuLayout.subMenuListTopLeft(menuSize);
    const parentRect = useMemo(() => new Rect(position, INPUT_AREA_SIZE), [position]);
    return (
        <g>
            <>
                <MainMenuBox menuSize={menuSize} isSelected={isOpen} onClick={onMenuClicked} text={text} />
                <MainMenuIcon icon={AlignMenuIcon[alignType]} menuSize={menuSize} />
                {isOpen && (
                    <g transform={position.toSVGTranslate()}>
                        <rect
                            className="stroke-gray-300"
                            fill={'white'}
                            strokeWidth={2}
                            width={TOTAL_MENU_SIZE.width}
                            height={TOTAL_MENU_SIZE.height}
                        />
                        <foreignObject
                            width={INPUT_AREA_SIZE.width + BUTTON_AREA_SIZE.width}
                            height={INPUT_AREA_SIZE.height}
                        >
                            <div className="flex h-full flex-nowrap p-3">
                                <input
                                    className="m-0 mr-2 flex-auto shrink grow-0 rounded px-3 py-2 text-[30px] leading-tight text-gray-700 shadow"
                                    style={{ width: INPUT_AREA_SIZE.width + 'px' }}
                                    type="text"
                                    ref={inputRef}
                                    defaultValue={interval || ''}
                                    onChange={handleChangeInterval}
                                    onKeyDown={handleKeyDown}
                                    onFocus={handleFocus}
                                />
                                <button
                                    className="m-0 w-16 flex-auto cursor-pointer p-0 text-[30px] hover:bg-gray-300 disabled:cursor-not-allowed disabled:text-neutral-500"
                                    onClick={handleSelected}
                                    disabled={canNotSaveInterval}
                                >
                                    整列 | Align
                                </button>
                            </div>
                        </foreignObject>
                        {!isValid && (
                            <WarningPopup placement={'bottom'} parentRect={parentRect}>
                                間隔は 1~100 で指定してください | Invalid interval
                            </WarningPopup>
                        )}
                    </g>
                )}
            </>
        </g>
    );
};

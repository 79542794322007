import { useCallback, useEffect, useState } from 'react';
import { ForeignObjectAutosize } from '@view-model/models/common/components/ForeignObjectAutosize';
import { Point, Rect, Size } from '@view-model/models/common/basic';

type Placement = 'top' | 'bottom';

type Props = {
    placement: Placement;
    parentRect: Rect;
    children: React.ReactNode;
};

const OFFSET_Y = 8;

export const WarningPopup: React.FC<Props> = ({ placement, parentRect, children }: Props) => {
    const [size, setSize] = useState(new Size(0, 0));
    const [position, setPosition] = useState(new Point(0, 0));

    const getPosition = useCallback(
        (parentRect: Rect, size: Size) => {
            const centerX = parentRect.width / 2 - size.width / 2;
            if (placement === 'top') {
                return new Point(centerX, -size.height).addY(OFFSET_Y);
            }
            return new Point(centerX, parentRect.height).addY(-OFFSET_Y);
        },
        [placement]
    );

    useEffect(() => {
        setPosition(getPosition(parentRect, size));
    }, [getPosition, parentRect, size]);

    const handleSizeChange = (newSize: Size) => {
        setSize(newSize);
        setPosition(getPosition(parentRect, newSize));
    };

    return (
        <ForeignObjectAutosize transform={position.toSVGTranslate()} onSizeChange={handleSizeChange}>
            {placement === 'bottom' ? (
                // DownTriangle
                <div className="mx-auto size-0 -rotate-90 border-10 border-solid border-transparent border-l-red-200" />
            ) : null}
            <div className="inline-block rounded-lg bg-red-200 px-3 py-2 font-medium text-red-600 shadow-sm">
                {children}
            </div>
            {placement === 'top' ? (
                // UpTriangle
                <div className="mx-auto size-0 rotate-90 border-10 border-solid border-transparent border-l-red-200" />
            ) : null}
        </ForeignObjectAutosize>
    );
};

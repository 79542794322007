import { GridLayout } from '@view-model/ui/layouts';

type Position = {
    x: number;
    y: number;
};

/**
 * モデル要素をレイアウトするための ILayout インタフェース互換の static クラス。
 *
 * グリッドサイズ64pxのグリッドに要素を配置する。See: GridLayout
 */
export class ModelLayout {
    static readonly GridSize = 64;
    private static readonly layout: GridLayout = new GridLayout(ModelLayout.GridSize, ModelLayout.GridSize);

    static snapX(x: number): number {
        return this.layout.snapX(x);
    }

    static snapY(y: number): number {
        return this.layout.snapY(y);
    }

    static snapPosition(position: Position): Position {
        return this.layout.snapPosition(position);
    }
}

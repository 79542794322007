import { useCallback, useEffect, useState } from 'react';
import { ViewModelPasteConfirmModal } from './ViewModelPasteConfirmModal';
import { ViewModelClipboardPayload, isViewModelClipboardPayload } from '@view-model/application/operation';
import { container } from '@framework/container';
import { WindowEventManager } from '@view-model/application/shortcuts';
import { ApplicationClipboardPayload, ViewModelClipboardOperator } from '@view-model/application/clipboard';
import { symbols } from '@framework/container/symbols';
import { useCurrentUserId } from '@framework/auth';
import { GroupId, ViewId, ViewModelId } from '@schema-common/base';
import { useCommandManager } from '@model-framework/command';
import { toast } from 'react-hot-toast';

type Props = {
    groupId: GroupId;
    viewModelId: ViewModelId;
    onSelectViewIds(viewIds: Set<ViewId>): void;
};

export const ViewModelPaste: React.FC<Props> = ({ groupId, viewModelId, onSelectViewIds }: Props) => {
    const windowEventManager = container.get<WindowEventManager>(symbols.WindowEventManager);
    const commandManager = useCommandManager();
    const [pasteClipboardPayload, setPasteClipboardPayload] = useState<ViewModelClipboardPayload | null>(null);
    const currentUserId = useCurrentUserId();

    useEffect(() => {
        const unsubscribe = windowEventManager.listenOnPaste(
            async (payload: ApplicationClipboardPayload): Promise<void> => {
                if (!payload) return;

                if (isViewModelClipboardPayload(payload)) {
                    setPasteClipboardPayload(payload);
                }
            }
        );
        return () => unsubscribe();
    }, [windowEventManager]);

    const closePasteConfirmModal = useCallback(() => {
        setPasteClipboardPayload(null);
    }, []);

    const handlePasteOnSubmit = useCallback(async () => {
        if (!currentUserId) return;

        if (pasteClipboardPayload) {
            try {
                const [viewIds, errorMessage] = await ViewModelClipboardOperator.pasteWithCommand(
                    groupId,
                    viewModelId,
                    currentUserId,
                    pasteClipboardPayload,
                    commandManager
                );

                if (errorMessage) {
                    toast.error(errorMessage);
                }

                // ビューモデルのペースト時にペーストされるビューが１つだけの場合はそのビューを選択状態にする
                if (viewIds.size === 1) {
                    onSelectViewIds(viewIds);
                }
            } catch (reason) {
                toast.error('ペーストに失敗しました | Paste failed');
                throw reason;
            }
        }
        setPasteClipboardPayload(null);
    }, [currentUserId, pasteClipboardPayload, groupId, viewModelId, commandManager, onSelectViewIds]);

    return (
        <>
            {pasteClipboardPayload && (
                <ViewModelPasteConfirmModal
                    isOpen={true}
                    onClose={closePasteConfirmModal}
                    onSubmit={handlePasteOnSubmit}
                    payload={pasteClipboardPayload}
                />
            )}
        </>
    );
};

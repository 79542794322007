import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SimpleFooterLayout } from '@user/layouts/SimpleFooterLayout';
import { FlatButton } from '@framework/ui/atoms';
import { UserPageLocationState } from '@user/pages/UserPageLocationState';
import { classNames } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import BalusLogo from '@assets/balus-logo-brand-horizontal.svg';

export const AccountConnectedPage: React.FC<unknown> = () => {
    const history = useHistory();
    const handleMoveHome = useCallback(() => history.push('/'), [history]);

    const location = useLocation<UserPageLocationState>();
    const { message, isSuccess } = location.state?.accountConnectedPage || {
        message: '外部認証プロバイダとの認証連携に失敗した可能性があります。もう一度お試しください。',
        isSuccess: false,
    };

    return (
        <SimpleFooterLayout>
            <div className="mx-auto flex flex-col items-center px-4 pb-8 pt-16 md:container">
                <img src={BalusLogo} className="w-64" />
                <div className="py-8 text-3xl font-bold">外部認証プロバイダとの連携</div>

                <div className="rounded-lg border border-gray-500 p-8" style={{ width: '600px' }}>
                    <div className={classNames(isSuccess ? '' : 'text-red-700')}>{message}</div>
                    <div className="py-8 text-center">
                        <FlatButton className="px-16" color="brand" onClick={handleMoveHome}>
                            ホームに移動する
                        </FlatButton>
                    </div>

                    {!isSuccess && (
                        <div className="text-sm">
                            連携に失敗する場合には、
                            <a
                                href="https://links.balus.app/contact-form"
                                target="_blank"
                                rel="noreferrer"
                                className="text-sm text-blue-700 hover:underline"
                            >
                                お問合せフォーム <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </a>
                            から操作内容を添えてご連絡ください。
                        </div>
                    )}
                </div>
            </div>
        </SimpleFooterLayout>
    );
};

import { isEqual } from 'lodash';
import { SidebarTreeAction } from './SidebarTreeAction';
import { SidebarTreeStore } from './SidebarTreeStore';
import { filterByKeyword } from './filterByKeyword';

export const sidebarTreeReducer = (store: SidebarTreeStore, action: SidebarTreeAction): SidebarTreeStore => {
    switch (action.type) {
        case 'FIRST_LOAD': {
            return {
                ...store,
                firstLoaded: true,
                all: { ...action.payload },
            };
        }
        case 'ADD_WORKSPACE': {
            const { workspace } = action.payload;
            if (store.all.workspaces[workspace.id] && store.all.workspaces[workspace.id].isEqual(workspace)) {
                return store;
            }

            return {
                ...store,
                all: {
                    ...store.all,
                    workspaces: {
                        ...store.all.workspaces,
                        [workspace.id]: workspace,
                    },
                },
            };
        }
        case 'REMOVE_WORKSPACE': {
            const { [action.payload.workspaceId]: removedWorkspace, ...newWorkspaces } = store.all.workspaces;
            const { [action.payload.workspaceId]: removedFolderTree, ...newFolderTrees } = store.all.folderTrees;
            // ワークスペース内のフォルダーとビューモデルも削除する必要あり
            return {
                ...store,
                all: {
                    ...store.all,
                    workspaces: newWorkspaces,
                    folderTrees: newFolderTrees,
                },
            };
        }
        case 'ADD_ROOT_FOLDER_TREE': {
            return {
                ...store,
                all: {
                    ...store.all,
                    folderTrees: {
                        ...store.all.folderTrees,
                        [action.payload.workspaceId]: action.payload.rootFolderTree,
                    },
                },
            };
        }
        case 'SET_FOLDER': {
            if (
                store.all.folders[action.payload.folder.id] &&
                isEqual(store.all.folders[action.payload.folder.id].dump(), action.payload.folder.dump())
            ) {
                return store;
            }

            return {
                ...store,
                all: {
                    ...store.all,
                    folders: {
                        ...store.all.folders,
                        [action.payload.folder.id]: action.payload.folder,
                    },
                },
            };
        }
        case 'REMOVE_FOLDER': {
            const { [action.payload.folderId]: removedFolder, ...newFolders } = store.all.folders;

            return {
                ...store,
                all: {
                    ...store.all,
                    folders: newFolders,
                },
            };
        }
        case 'SET_VIEW_MODEL': {
            if (
                store.all.viewModels[action.payload.viewModel.id] &&
                store.all.viewModels[action.payload.viewModel.id].isEqualExcludingUpdatedAt(action.payload.viewModel)
            ) {
                return store;
            }

            return {
                ...store,
                all: {
                    ...store.all,
                    viewModels: {
                        ...store.all.viewModels,
                        [action.payload.viewModel.id]: action.payload.viewModel,
                    },
                },
            };
        }
        case 'REMOVE_VIEW_MODEL': {
            const { [action.payload.viewModelId]: removedViewModel, ...newViewModels } = store.all.viewModels;

            return {
                ...store,
                all: {
                    ...store.all,
                    viewModels: newViewModels,
                },
            };
        }
        case 'TOGGLE_WORKSPACE_OPEN': {
            return {
                ...store,
                open: {
                    ...store.open,
                    workspaceIds: {
                        ...store.open.workspaceIds,
                        [action.payload.workspaceId]: store.open.workspaceIds[action.payload.workspaceId]
                            ? false
                            : true,
                    },
                },
            };
        }
        case 'TOGGLE_FOLDER_OPEN': {
            return {
                ...store,
                open: {
                    ...store.open,
                    folderIds: {
                        ...store.open.folderIds,
                        [action.payload.folderId]: store.open.folderIds[action.payload.folderId] ? false : true,
                    },
                },
            };
        }
        case 'OPEN_WORKSPACE': {
            const { workspaceId, lastOpenedWorkspaceId, lastOpenedViewModelId } = action.payload;
            return {
                ...store,
                open: {
                    ...store.open,
                    workspaceIds: {
                        ...store.open.workspaceIds,
                        [workspaceId]: true,
                    },
                },
                control: {
                    ...store.control,
                    lastOpenedWorkspaceId: lastOpenedWorkspaceId,
                    ...(lastOpenedViewModelId ? { lastOpenedViewModelId } : {}),
                },
            };
        }
        case 'OPEN_FOLDER': {
            const { folderId } = action.payload;
            return {
                ...store,
                open: {
                    ...store.open,
                    folderIds: {
                        ...store.open.folderIds,
                        [folderId]: true,
                    },
                },
            };
        }
        case 'SET_SCROLLED_WORKSPACE': {
            return {
                ...store,
                control: {
                    ...store.control,
                    scrolledWorkspaceId: action.payload.workspaceId,
                },
            };
        }
        case 'SET_SCROLLED_VIEW_MODEL': {
            return {
                ...store,
                control: {
                    ...store.control,
                    scrolledViewModelId: action.payload.viewModelId,
                },
            };
        }
        case 'REMOVE_BULK': {
            const { workspaceId } = action.payload;
            const { [workspaceId]: targetWorkspace, ...newWorkspaces } = store.all.workspaces;
            const { [workspaceId]: targetFolderTree, ...newFolderTrees } = store.all.folderTrees;

            if (!targetFolderTree) return store;

            // 削除するフォルダーの特定
            const targetFolderIds = targetFolderTree.getAllFolderIds();

            // 削除するビューモデルの特定
            const targetViewModelIds = targetFolderTree.getAllViewModelIds();

            return {
                ...store,
                all: {
                    workspaces: newWorkspaces,
                    folderTrees: newFolderTrees,
                    folders: Object.entries(store.all.folders).reduce((res, [folderId, folder]) => {
                        if (targetFolderIds.includes(folderId)) {
                            return res;
                        } else {
                            return {
                                ...res,
                                [folderId]: folder,
                            };
                        }
                    }, {}),
                    viewModels: Object.entries(store.all.viewModels).reduce((res, [viewModelId, viewModel]) => {
                        if (targetViewModelIds.includes(viewModelId)) {
                            return res;
                        } else {
                            return {
                                ...res,
                                [viewModelId]: viewModel,
                            };
                        }
                    }, {}),
                },
            };
        }
        case 'SEARCH': {
            const searchResult = filterByKeyword(action.payload.keyword, store.all);
            return {
                ...store,
                searchResult,
                open: {
                    ...store.open,
                    workspaceIds: {
                        ...store.open.workspaceIds,
                        ...Object.keys(searchResult.workspaces).reduce((res, workspaceId) => {
                            return {
                                ...res,
                                [workspaceId]: true,
                            };
                        }, {}),
                    },
                    folderIds: {
                        ...store.open.folderIds,
                        ...Object.keys(searchResult.folders).reduce((res, folderId) => {
                            return {
                                ...res,
                                [folderId]: true,
                            };
                        }, {}),
                    },
                },
            };
        }
        case 'REMOVE_SEARCH_RESULT': {
            return {
                ...store,
                searchResult: null,
            };
        }
        default:
            return store;
    }
};

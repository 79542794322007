import { useEffect, useState } from 'react';
import { GroupId } from '@schema-common/base';
import { GroupMemberSignUpMethod } from '@group/domain';
import { ObjectRepository, RTDBPath } from '@framework/repository';

export const useGroupMemberSignUpMethod = (
    groupId: GroupId
): {
    loading: boolean;
    signUpMethods: GroupMemberSignUpMethod | null;
} => {
    const [loading, setLoading] = useState(true);
    const [signUpMethods, setSignUpMethods] = useState<GroupMemberSignUpMethod | null>(null);

    useEffect(() => {
        const repo = new ObjectRepository(
            GroupMemberSignUpMethod,
            RTDBPath.Group.settingAvailableSignUpMethodsPath(groupId)
        );
        repo.get().then((methods) => {
            setSignUpMethods(methods);
            setLoading(false);
        });
    }, [groupId]);

    return { loading, signUpMethods };
};

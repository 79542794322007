type Props = {
    children: React.ReactNode;
    onClick(): void;
};

/**
 * マウスホバー時のスタイリングなどの機会を子コンポーネントに提供するためのラッパークラス。
 *
 * 子コンポーネントのclassNameに group-hover/link:fill-brand などを指定することで、
 * マウスホバーしたときに色を変えるなどのスタイリングができるようにする。
 *
 * https://tailwindcss.com/docs/hover-focus-and-other-states#group-hover
 */

export const LinkWrapperView: React.FC<Props> = ({ children, onClick }: Props) => {
    return (
        <g className="group/link" cursor={'pointer'} onClick={onClick}>
            {children}
        </g>
    );
};

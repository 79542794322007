import { DescriptionPanel } from '../domain';
import { Point } from '@view-model/models/common/basic';
import { CommandManager, CompositeCommand, CreateCommand } from '@model-framework/command';
import { ObjectRepository, RTDBPath } from '@framework/repository';
import { DescriptionPanelId, ModelId, ViewModelId } from '@schema-common/base';

export class DescriptionPanelCreationOperation {
    static create({
        viewModelId,
        modelId,
        commandManager,
        position,
        description,
    }: {
        viewModelId: ViewModelId;
        modelId: ModelId;
        commandManager: CommandManager;
        position: Point;
        description?: string;
    }): DescriptionPanelId {
        const panel = DescriptionPanel.buildNew(description);
        const { id } = panel;

        const panelRepository = new ObjectRepository(
            DescriptionPanel,
            RTDBPath.DescriptionPanel.panelPath(viewModelId, modelId, id)
        );
        const positionRepository = new ObjectRepository(
            Point,
            RTDBPath.DescriptionPanel.positionPath(viewModelId, modelId, id)
        );

        const command = new CompositeCommand(
            new CreateCommand(panel, panelRepository),
            new CreateCommand(position, positionRepository)
        );
        commandManager.execute(command);

        return id;
    }
}

import { ClipboardPayload } from '@model-framework/clipboard';
import { StickyModelContents, StickyModelContentsJSON } from '@view-model/domain/model';
import { nanoid } from 'nanoid';

const ClipboardApplicationName = 'Balus/Beta';
const Target = 'StickyModel';
const ContentType = 'StickyModel';

export type StickyModelClipboardPayload = ClipboardPayload & {
    application: string;
    target: typeof Target;
    contentType: typeof ContentType;
    id: string;
    sourceViewModelId: string;
    contents: StickyModelContentsJSON;
};

export namespace StickyModelClipboardPayload {
    export function fromContent(sourceViewModelId: string, content: StickyModelContents): StickyModelClipboardPayload {
        return {
            application: ClipboardApplicationName,
            target: Target,
            contentType: ContentType,
            sourceViewModelId,
            id: `${nanoid()}`,
            contents: content.dump(),
        };
    }

    export function toContent(payload: StickyModelClipboardPayload): StickyModelContents {
        return StickyModelContents.load(payload.contents);
    }
}

export const isStickyModelClipboardPayload = (payload: ClipboardPayload): payload is StickyModelClipboardPayload =>
    payload.target === Target && payload.contentType === ContentType;

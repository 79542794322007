interface IReadyForMultiSelectionFields {
    isReadyForMultiSelection: true;
    isMultiViewsSelectionMode: false;
    isMultiElementsSelectionMode: false;
}

interface IMultiViewsSelectionModeFields {
    isReadyForMultiSelection: false;
    isMultiViewsSelectionMode: true;
    isMultiElementsSelectionMode: false;
}

interface IMultiElementsSelectionModeFields {
    isReadyForMultiSelection: false;
    isMultiViewsSelectionMode: false;
    isMultiElementsSelectionMode: true;
}

interface ITurnOffFields {
    isReadyForMultiSelection: false;
    isMultiViewsSelectionMode: false;
    isMultiElementsSelectionMode: false;
}

type Fields =
    | IReadyForMultiSelectionFields
    | IMultiViewsSelectionModeFields
    | IMultiElementsSelectionModeFields
    | ITurnOffFields;

export class MultiSelectionMode {
    public readonly isReadyForMultiSelection: boolean;
    public readonly isMultiViewsSelectionMode: boolean;
    public readonly isMultiElementsSelectionMode: boolean;

    private constructor(fields: Fields) {
        this.isReadyForMultiSelection = fields.isReadyForMultiSelection;
        this.isMultiViewsSelectionMode = fields.isMultiViewsSelectionMode;
        this.isMultiElementsSelectionMode = fields.isMultiElementsSelectionMode;
    }

    static offMode: MultiSelectionMode = new this({
        isReadyForMultiSelection: false,
        isMultiViewsSelectionMode: false,
        isMultiElementsSelectionMode: false,
    });

    static readyForMultiSelectionMode: MultiSelectionMode = new this({
        isReadyForMultiSelection: true,
        isMultiViewsSelectionMode: false,
        isMultiElementsSelectionMode: false,
    });

    static multiElementsSelectionMode: MultiSelectionMode = new this({
        isReadyForMultiSelection: false,
        isMultiViewsSelectionMode: false,
        isMultiElementsSelectionMode: true,
    });

    static multiViewsSelectionMode: MultiSelectionMode = new this({
        isReadyForMultiSelection: false,
        isMultiViewsSelectionMode: true,
        isMultiElementsSelectionMode: false,
    });
}

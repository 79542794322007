import { Size } from '@view-model/models/common/basic/Size';
import { Point } from '@view-model/models/common/basic/Point';

/*
 * 順番と方向のある矩形サイズの集合を表現するクラスです
 * ・このクラスでは矩形の寄せ方向は以下のように考えます
 *   縦に積む場合(direction == 'column'): 左寄せを前提にする
 *   横に積む場合(direction == 'row'): 上寄せを前提にする
 * ・要素がない時、縦横サイズ 0 と考えます
 */
export class RectSizeCollection {
    constructor(
        private readonly sizes: ReadonlyArray<Size>,
        private readonly direction: 'row' | 'column'
    ) {}

    /**
     * 縦横幅0の要素をlength個もつRectSizeCollectionを返します
     */
    static build(length: number, direction: 'column' | 'row'): RectSizeCollection {
        const sizes = [...Array(length)].map(() => new Size(0, 0));
        return new RectSizeCollection(sizes, direction);
    }

    /**
     * index番目の要素を取得します
     */
    get(index: number): Size {
        return this.sizes[index];
    }

    /**
     * index番目の要素を更新し、更新後のRectSizeCollectionを返します
     */
    set(index: number, newSize: Size): RectSizeCollection {
        return new RectSizeCollection(
            this.sizes.map((size, i) => (i === index ? newSize : size)),
            this.direction
        );
    }

    /**
     * 空であるかを判定します
     */
    isEmpty(): boolean {
        return this.sizes.length === 0;
    }

    /**
     * (0, 0)を起点とした、index番目の要素の左上座標値を返します
     */
    getOffsetOf(index: number): Point {
        return this.slice(0, index).getNextOffsetOf();
    }

    /**
     * (0, 0)を起点とした、次の要素があるべき左上座標値を返します
     */
    getNextOffsetOf(): Point {
        if (this.direction === 'row') {
            return new Point(this.stackedWidth(), 0);
        }
        return new Point(0, this.stackedHeight());
    }

    /**
     * RectSizeCollection 全体のサイズを返します
     * ただし、積み上げ方向でない方向のサイズは、全要素の最大値を返します（全要素を取り囲む大きな矩形を返す、という考え方です）
     */
    getTotalSize(): Size {
        if (this.isEmpty()) {
            return new Size(0, 0);
        }
        if (this.direction === 'row') {
            return new Size(this.stackedWidth(), this.maxItemHeight());
        }
        return new Size(this.maxItemWidth(), this.stackedHeight());
    }

    /**
     * 一部分を切り取った新しいRectSizeCollectionを返します
     * ※ 当メソッドの仕様は Array.prototype.slice() に準じています
     *   start: 取り出しの開始位置を示す 0 から始まるインデックスです。
     *   end: 取り出しを終える直前の位置を示す 0 から始まるインデックスです。slice は end 自体は含めず、その直前まで取り出します。
     */
    private slice(start: number, end: number): RectSizeCollection {
        return new RectSizeCollection(this.sizes.slice(start, end), this.direction);
    }

    /**
     * RectSizeCollection の要素全体を積み上げたときの総横幅を返します
     */
    private stackedWidth(): number {
        return this.sizes.reduce((totalWidth, size) => {
            return totalWidth + size.width;
        }, 0);
    }

    /**
     * RectSizeCollection の要素全体を積み上げたときの総縦幅を返します
     */
    private stackedHeight(): number {
        return this.sizes.reduce((totalHeight, size) => {
            return totalHeight + size.height;
        }, 0);
    }

    /**
     * 全要素中の最大の横幅を返します
     */
    private maxItemWidth(): number {
        if (this.isEmpty()) {
            return 0;
        }
        return Math.max(...this.sizes.map((size) => size.width));
    }

    /**
     * 全要素中の最大の縦幅を返します
     */
    private maxItemHeight(): number {
        if (this.isEmpty()) {
            return 0;
        }
        return Math.max(...this.sizes.map((size) => size.height));
    }
}

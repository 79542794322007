import { useEffect, useState } from 'react';
import { ViewModelEntity } from './ViewModelEntity';
import { useWorkspaceMyMemberRole } from '@workspace/application/hooks/useWorkspaceMyMemberRole';
import { ObjectRepository, RTDBPath, usePersistedObject, usePersistedText } from '@framework/repository';
import { WorkspaceEntity, WorkspaceSetting } from '@workspace/domain/workspace';
import { canReadViewModel } from './canReadViewModel';
import { isViewModelSharingUserRoleType } from './vo/ViewModelSharingUserRoleType';
import { ViewModelId, isValidId } from '@schema-common/base';

type Result = {
    workspace: WorkspaceEntity | null;
    viewModel: ViewModelEntity | null;
    loading: boolean;
};

export const useViewModelEntity = (id: ViewModelId): Result => {
    const [result, setResult] = useState<Result>({ workspace: null, viewModel: null, loading: true });

    /**
     * 引数に指定された id が RTDB のパス階層で指定できない文字列パターンの場合には、非実在のフォールバック用idを返す。
     * これによって不正なRTDBパスへのアクセスによる権限エラーの発生を抑止する。
     */
    const viewModelId = isValidId(id) ? id : '(invalid-view-model-id)';

    /**
     * 取得対象のビューモデルが属する workspaceId が特定できる(RTDBから取得できる)までの間は、
     * 'missing-workspace-id' のワークスペース設定・ロール情報を一度取得させる。
     * これらの値は null だが、正しい workspaceId が設定された後には、正しいワークスペース設定・ロール情報が再取得される。
     */
    const [workspaceId] = usePersistedText(RTDBPath.ViewModel.viewModelWorkspaceIdPath(viewModelId));
    const role = useWorkspaceMyMemberRole(workspaceId || '(missing-workspace-id)');
    const [setting] = usePersistedObject(
        WorkspaceSetting,
        RTDBPath.Workspace.settingPath(workspaceId || '(missing-workspace-id)')
    );

    const [viewModelTrashedAt] = usePersistedText(RTDBPath.ViewModel.trashedAtPath(viewModelId));
    const [viewModelSharingUserRole] = usePersistedText(RTDBPath.ViewModel.sharingUserRolePath(viewModelId));

    useEffect(() => {
        // 取得対象の ViewModel が変化したときには、いったん未取得状態に更新する。
        // 別のビューモデルに移動した際に、ズーム範囲などの再調整を実行するために必要。
        setResult({ workspace: null, viewModel: null, loading: true });

        // ビューモデル取得判定に必要なものは全て揃うまで待つ
        if (
            workspaceId === undefined ||
            setting === undefined ||
            role === undefined ||
            viewModelTrashedAt === undefined ||
            viewModelSharingUserRole === undefined
        ) {
            return;
        }

        // ワークスペースの設定がない
        // ビューモデルの共有設定がない
        // ビューモデルの取得権限がない
        // 上記のいずれかに該当する場合はNot Foundにする
        if (
            workspaceId === null ||
            setting === null ||
            viewModelSharingUserRole === null ||
            !isViewModelSharingUserRoleType(viewModelSharingUserRole) ||
            !canReadViewModel(setting, role, viewModelTrashedAt, viewModelSharingUserRole)
        ) {
            setResult({ workspace: null, viewModel: null, loading: false });
            return;
        }

        const wsRepo = new ObjectRepository(WorkspaceEntity, RTDBPath.Workspace.workspacePath(workspaceId));
        const vmRepo = new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(viewModelId));

        Promise.all([wsRepo.get(), vmRepo.get()]).then(([workspace, viewModel]) => {
            // ワークスペース、ビューモデルの一方が null の場合には、両方を null にしてロード完了
            if (workspace === null && viewModel === null) {
                setResult({ workspace: null, viewModel: null, loading: false });
                return;
            }

            // 最初の取得完了のステート更新を行ってから、変更監視を始める
            setResult({ workspace, viewModel, loading: false });

            wsRepo.addListener((workspace) => setResult((prev) => ({ ...prev, workspace })));

            vmRepo.addListener((viewModel) =>
                setResult(({ viewModel: prev, ...rest }) =>
                    // ビューモデルの updatedAt 以外が一致しているならば、ビューモデル・エンティティはそのまま更新しない。
                    // (updatedAt 以外に変更があった場合には、変更監視コールバックの新しいビューモデル・エンティティに置き換える)
                    viewModel && prev && viewModel.isEqualExcludingUpdatedAt(prev)
                        ? { viewModel: prev, ...rest }
                        : { ...rest, viewModel }
                )
            );
        });

        return () => {
            wsRepo.removeListener();
            vmRepo.removeListener();
        };
    }, [role, setting, viewModelTrashedAt, viewModelSharingUserRole, viewModelId, workspaceId]);

    return result;
};

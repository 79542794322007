import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
    onSearch: (keyword: string) => void;
};

export const SidebarSearchBox: React.FC<Props> = ({ onSearch }: Props) => {
    const [keyword, setKeyword] = useState('');

    const handleSearch = () => {
        if (!keyword) return;
        onSearch(keyword);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
        if (!e.target.value) {
            onSearch('');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.nativeEvent.isComposing || e.key !== 'Enter') return;
        onSearch(keyword);
    };

    const handleSearchOff = useCallback(() => {
        onSearch('');
        setKeyword('');
    }, [onSearch]);

    // サイドバーのビューモデル検索機能からビューモデルに移動した後に、モデル要素のコピー&ペーストが行えない問題に対処するための処置。
    // 検索テキストボックスのフォーカスが外れているにも関わらず、ペーストイベントの対象が input になるため、モデル要素の貼り付け処理がスキップされていた。
    // 検索ボックスに対してフォーカス状態に連動する data-focus 属性を付与することで、ペーストイベントの対象を判別できるようにする。
    // https://www.notion.so/levii/5f46f11283a64584be7cb5ccb51b8373
    const [focus, onFocus] = useState(false);

    return (
        <div className="relative flex">
            <div className="flex min-w-0 flex-1">
                <input
                    value={keyword}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => onFocus(true)}
                    onBlur={() => onFocus(false)}
                    data-focus={focus}
                    type="text"
                    className="min-w-0 flex-1 rounded-l-md bg-gray-200 py-1 pr-2 placeholder:text-sm focus:outline-none"
                    placeholder="ビューモデル名で検索"
                    style={{ paddingInlineStart: '24px' }}
                />
            </div>
            {keyword && (
                <div className="flex w-8 items-center justify-center bg-gray-200 pr-2">
                    <button
                        className="flex size-4 items-center justify-center rounded-full bg-gray-400 p-3 focus:outline-none"
                        onClick={handleSearchOff}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            )}
            <FontAwesomeIcon
                icon={faSearch}
                className="absolute flex-none text-gray-600"
                style={{ top: '8px', left: '6px' }}
            />
            <button
                className="flex-none rounded-r-md bg-gray-600 px-2 py-1 text-white focus:outline-none"
                onClick={handleSearch}
            >
                検索
            </button>
        </div>
    );
};

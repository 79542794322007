import { ICommand } from '@model-framework/command';
import { IOrderRollback } from './IOrderRollback';
import { DraggingElements } from './DraggingElements';
import { DisplayOrderRepository } from '../infrastructure';

export class ChangeParentCommand implements ICommand {
    private orderRollback: IOrderRollback | undefined = undefined;

    constructor(
        private readonly draggingElements: DraggingElements,
        private readonly displayOrderRepository: DisplayOrderRepository
    ) {}

    do(): void {
        this.draggingElements.applyChangeParent(this.displayOrderRepository).then((rollback) => {
            this.orderRollback = rollback;
        });
    }

    undo(): void {
        if (!this.orderRollback) return;

        this.displayOrderRepository.rollback(this.orderRollback).then(() => {
            this.orderRollback = undefined;
        });
    }

    redo(): void {
        return this.do();
    }

    async canUndo(): Promise<boolean> {
        return !!this.orderRollback;
    }

    async canRedo(): Promise<boolean> {
        return !this.orderRollback;
    }
}

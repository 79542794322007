import { ICommand } from '@model-framework/command';
import { LinkKey } from '@view-model/domain/key';
import { LinkBend, LinkEntity } from '../domain';
import { LinkEntityOperation } from '../adapter';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class LinkBendUpdateCommand implements ICommand {
    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly entityOperation: LinkEntityOperation,
        private readonly key: LinkKey,
        private readonly fromValue: LinkBend,
        private readonly toValue: LinkBend
    ) {}

    do(): void {
        const link = LinkEntityOperation.getLink(this.key, this.viewModel);
        if (!link) return;

        this.entityOperation.save(link.withBend(this.toValue));
    }

    undo(): void {
        this.changeValue(this.toValue, this.fromValue);
    }

    redo(): void {
        this.changeValue(this.fromValue, this.toValue);
    }

    async canUndo(): Promise<boolean> {
        return !!this.retrieveLink(this.toValue);
    }

    async canRedo(): Promise<boolean> {
        return !!this.retrieveLink(this.fromValue);
    }

    private changeValue(fromValue: LinkBend, toValue: LinkBend): void {
        const link = this.retrieveLink(fromValue);
        if (!link) return;

        this.entityOperation.save(link.withBend(toValue));
    }

    private retrieveLink(fromValue: LinkBend): LinkEntity | null {
        return LinkEntityOperation.findLink({
            key: this.key,
            isTarget: (link) => link.bend.isEquals(fromValue),
            viewModel: this.viewModel,
        });
    }
}

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ActionLogEventName } from '../definition';
import { ActionLog } from '../ActionLog';
import { useActionLogEventParamsContext } from './ActionLogEventParamsContext';
import { ActionLogRepository } from '../ActionLogRepository';
import { BuildInfo } from '@config/build-info';
import UAParser from 'ua-parser-js';
import { ActionEventLogJSON } from 'schema/__action-log__/action-event-logs/ActionEventLogJSON';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';
import { useCurrentSessionId } from '@framework/auth/AuthContext';
import { DataSnapshot, RTDBPath, RefBuilder } from '@framework/repository';

// 行動イベントログを送信する関数。イベント名と追加パラメータ(省略可) を指定する。
// ユーザ情報と基本的なイベントパラメータは上位コンテキストから取得する。
export type ActionLogSender = (
    eventName: ActionLogEventName,
    extraEventParams?: ActionEventLogJSON['eventParams']
) => Promise<void>;

/**
 * URLのクエリパラメータ `?key=value` などの文字列をパースして、
 * キーと値のペアを `{ key: value }` として返す。
 */
const serializeLocationSearch = (search: string): string => {
    const result: Record<string, unknown> = {};
    const params = new URLSearchParams(search);
    params.forEach((value, key) => {
        result[key] = value;
    });
    return JSON.stringify(result);
};

/**
 * 行動イベントログを送信する関数を返す
 * ユーザ情報と基本的なイベントパラメータは上位コンテキストから取得する。
 */
export const useActionLogSender = (extraEventParams?: ActionEventLogJSON['eventParams']): ActionLogSender => {
    const repositoryRef = useRef(new ActionLogRepository());
    const sessionId = useCurrentSessionId();
    const currentUserProfile = useCurrentUserPublicProfile();
    const eventParams = useActionLogEventParamsContext();

    const currentIpRef = useRef<string | null>(null);

    useEffect(() => {
        if (!sessionId) return;

        const ref = RefBuilder.ref(RTDBPath.User.activeAuthSessionCurrentIpPath(sessionId));
        const callback = (snapshot: DataSnapshot) => {
            const ip = snapshot.val() as string | null;
            currentIpRef.current = ip;
        };

        ref.on('value', callback);
        return () => {
            ref.off('value', callback);
        };
    }, [sessionId]);

    const userAgent = useMemo(() => {
        const parser = new UAParser();
        const ua = parser.getResult();

        return {
            browserName: ua.browser.name,
            browserVersion: ua.browser.version,
            deviceType: ua.device.type,
            deviceVendor: ua.device.vendor,
            deviceModel: ua.device.model,
            engineName: ua.engine.name,
            engineVersion: ua.engine.version,
            osName: ua.os.name,
            osVersion: ua.os.version,
            cpuArchitecture: ua.cpu.architecture,
            userAgent: parser.getUA(),
        };
    }, []);

    const send = useCallback(
        async (eventName: ActionLogEventName, extraSendEventParams?: ActionEventLogJSON['eventParams']) => {
            const actionLog = ActionLog.buildNew(
                eventName,
                {
                    // location に基づく現在表示中のURL情報
                    locationPathname: location.pathname,
                    locationSearch: location.search,
                    locationSearchJson: serializeLocationSearch(location.search),
                    locationHash: location.hash,
                    url: location.href,
                    // Context 経由で取得したイベントパラメータ
                    ...eventParams,
                    // カスタムフック呼び出し時に追加設定されたイベントパラメータ
                    ...extraEventParams,
                    // 行動イベントログの記録時に渡された追加のイベントパラメータ
                    ...extraSendEventParams,
                },
                currentIpRef.current,
                sessionId,
                currentUserProfile?.id || null,
                {
                    userName: currentUserProfile?.name || null,
                    buildDate: BuildInfo.DATE,
                    buildVersion: BuildInfo.version,
                    buildSha1: BuildInfo.SHA1,
                    ...userAgent,
                    windowInnerHeight: window.innerHeight,
                    windowInnerWidth: window.innerWidth,
                    windowOuterHeight: window.outerHeight,
                    windowOuterWidth: window.outerWidth,
                }
            );

            await repositoryRef.current.save(actionLog);
        },
        [currentUserProfile, eventParams, extraEventParams, sessionId, userAgent]
    );

    return send;
};

import { useState } from 'react';
import { BuildInfoModal } from './BuildInfoModal';
import { RTDBConsoleURL } from '@admin/firebase';
import { RTDBPath } from '@framework/repository';
import { MenuLink } from '../UserPopupMenu/MenuLink';

export const AdminMenu: React.FC<unknown> = () => {
    const [showBuildInfoModal, setShowBuildInfoModal] = useState<boolean>(false);

    return (
        <div className="border-y border-gray-500">
            <MenuLink href={RTDBConsoleURL.build(RTDBPath.App.appPath())} label="Firebase Console" />
            <MenuLink href="/admin/console" label="Balus2.0管理画面" />

            <button
                onClick={() => setShowBuildInfoModal(true)}
                className="block w-full px-4 py-2 text-left text-gray-800 hover:bg-gray-200"
            >
                ビルド情報を表示
            </button>
            {showBuildInfoModal && <BuildInfoModal onClose={() => setShowBuildInfoModal(false)} />}
        </div>
    );
};

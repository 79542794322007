import { Timestamp } from '@framework/Timestamp';
import { getRealtimeDatabaseCurrentTimestamp } from '@framework/firebase/rtdb';
import { DataSnapshot, RTDBPath, RefBuilder } from '@framework/repository';
import { AuthSessionJSON } from '@schema-app/user/active-auth-sessions/{sessionId}/AuthSessionJSON';
import { AuthSessionId } from '@schema-common/base';

export class ActiveAuthSessionListener {
    private static unsubscribe: (() => void) | null = null;

    /**
     * 認証セッション情報の購読を開始する。認証セッション情報をコールバックで返す。
     * 認証セッションの有効期限が切れている場合にはnullを返す。
     * 認証セッションの有効期限が切れるタイミングでもコールバックが呼ばれるように、内部的にタイマーを設定する。
     * @param sessionId
     * @param callback
     */
    static async start(sessionId: AuthSessionId, callback: (auth: AuthSessionJSON | null) => void): Promise<void> {
        // 異なる sessionId で再びこの関数が呼び出される可能性があるため、念の為にまず購読解除をしておく
        this.unsubscribe?.();

        let first = true;

        return new Promise((resolve) => {
            let timerId: ReturnType<typeof setTimeout> | null = null;

            const ref = RefBuilder.ref(RTDBPath.User.activeAuthSessionPath(sessionId));
            const onValue = async (snapshot: DataSnapshot) => {
                const authSession = snapshot.val() as AuthSessionJSON | null;
                const now = await getRealtimeDatabaseCurrentTimestamp();
                const expiresAt = authSession ? new Timestamp(authSession.expiresAt) : null;

                // 認証セッションの値が変化した時点でタイマーが実行中であれば、既存のタイマーを解除する
                if (timerId) {
                    clearTimeout(timerId);
                    timerId = null;
                }

                if (expiresAt?.isAfter(now)) {
                    callback(authSession);

                    // 認証セッションの有効期限が切れる少し前のタイミングでログアウト状態のコールバックを返す
                    timerId = setTimeout(() => callback(null), expiresAt.diffMilliSeconds(now) - 30 * 1000);
                } else {
                    callback(null);
                }

                // 最初の値読み取りの場合に Promise を resolve する
                if (first) {
                    resolve();
                    first = false;
                }
            };

            ref.on('value', onValue);
            this.unsubscribe = () => {
                ref.off('value', onValue);
                timerId && clearTimeout(timerId);
                timerId = null;
            };
        });
    }

    static stop(): void {
        this.unsubscribe?.();
        this.unsubscribe = null;
    }
}

import { ICommand } from '@model-framework/command';
import { ModelCommentThread, ModelCommentContents, ModelCommentThreadRepository } from '../domain';
import { IPositionSetRepository } from '@view-model/models/common/PositionSet';
import { ModelCommentThreadId } from '@schema-common/base';
export class ModelCommentsDeleteCommand implements ICommand {
    constructor(
        private readonly commentContents: ModelCommentContents,
        private readonly threadRepository: ModelCommentThreadRepository,
        private readonly positionRepository: IPositionSetRepository
    ) {}

    private get threadIds(): ModelCommentThreadId[] {
        return this.commentContents.allIds();
    }

    do(): void {
        Promise.all([
            this.threadRepository.deleteMany(this.threadIds),
            this.positionRepository.deleteMany(this.threadIds),
        ]).then();
    }

    async canRedo(): Promise<boolean> {
        const currentThreads = await this.threadRepository.getMany(this.threadIds);
        return currentThreads.filter((thread: ModelCommentThread) => !!thread).length === this.threadIds.length;
    }

    async canUndo(): Promise<boolean> {
        const currentThreads = await this.threadRepository.getMany(this.threadIds);
        return currentThreads.filter((thread: ModelCommentThread) => !!thread).length === 0;
    }

    redo(): void {
        this.do();
    }

    undo(): void {
        const { threads, positions } = this.commentContents.contents();
        Promise.all([
            this.threadRepository.saveMany(threads.list()),
            this.positionRepository.saveMany(positions),
        ]).then();
    }
}

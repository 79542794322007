import { Point } from '@view-model/models/common/basic/Point';

/**
 * ベクトルの向き（角度: degree）を表す
 */
export class Angle {
    public readonly angle: number; // Unit: degree

    // SVG座標系での向き
    public static readonly North = new Angle(270); // ↑
    public static readonly East = new Angle(0); // →
    public static readonly South = new Angle(90); // ↓
    public static readonly West = new Angle(180); // ←

    constructor(angleDegrees: number) {
        this.angle = angleDegrees < 0 ? (angleDegrees % 360) + 360 : angleDegrees % 360;
    }

    /**
     * 2点間を線で結んだときの向きを返します。
     *
     * @param src 始点
     * @param dest 終点
     */
    public static angleBetween(src: Point, dest: Point): Angle {
        const v = src.vectorTo(dest);
        const angle = Angle.radiansToDegrees(Math.atan2(v.y, v.x));
        return new Angle(angle);
    }

    /**
     * ラジアンを度に変換する
     * @param radians
     */
    static radiansToDegrees(radians: number): number {
        return (radians * 180) / Math.PI;
    }

    /**
     * 現在の向きを水平方向成分だけ残した向きにする
     */
    toHorizontal(): Angle {
        return this.angle > 90 && this.angle <= 270 ? Angle.West : Angle.East;
    }

    /**
     * 現在の向きを垂直方向成分だけ残した向きにする
     */
    toVertical(): Angle {
        return this.angle < 180 ? Angle.South : Angle.North;
    }

    /**
     * 向きを180度反転する
     */
    reverse(): Angle {
        return new Angle(this.angle + 180);
    }

    /**
     * 座標をSVGのtransform属性のrotate文字列に変換します。
     * https://developer.mozilla.org/ja/docs/Web/SVG/Attribute/transform#rotate
     */
    toSVGRotate(): string {
        return `rotate(${this.angle})`;
    }

    toString(): string {
        return `Angle(${this.angle})`;
    }
}

import { Point, Rect } from '@view-model/models/common/basic';
import { StickyModelContents } from '@view-model/domain/model';
import { ModelLayout } from '@view-model/models/sticky/layout';

export class PastePositionAdjuster {
    /**
     * @param offsetX 連続してペーストした場合のオフセット（X値）
     * @param offsetY 連続してペーストした場合のオフセット（X値）
     * @param overhangAdjustPadding 貼り付け対象の要素がモデルキャンバスからはみ出していた時の位置調整後のパディング
     */
    constructor(
        private readonly offsetX: number,
        private readonly offsetY: number
    ) {}

    /**
     * 貼り付けた要素の座標を調整するメソッド
     */
    adjustElements(
        modelContents: StickyModelContents,
        position: Point,
        selectedContentsRect: Rect | null
    ): StickyModelContents {
        const adjustOffset = this.overhangAdjustOffset(modelContents, position, selectedContentsRect);
        const { x: dx, y: dy } = ModelLayout.snapPosition({
            x: this.offsetX + adjustOffset.x,
            y: this.offsetY + adjustOffset.y,
        });

        return modelContents.move(dx, dy);
    }

    /**
     * 貼り付ける要素の場所を指定するoffsetを指定するメソッド
     * 選択要素がない場合は、ユーザーに見えてる範囲の中心にくるようなoffsetを指定する
     * 選択要素がある場合は、選択要素に重なるようなoffsetを指定する
     * @private
     */
    private overhangAdjustOffset(
        modelContents: StickyModelContents,
        position: Point,
        selectedContentsRect: Rect | null
    ): { x: number; y: number } {
        const origin = { x: 0, y: 0 };

        // 貼り付けた要素に外接する矩形領域
        const pastedUnionRect = modelContents.getUnionRect();
        if (!pastedUnionRect) {
            return origin;
        }

        // 選択されている要素がある場合は選択されている要素に重ねる形で貼り付けを行う
        if (selectedContentsRect) {
            return selectedContentsRect.topLeft().subtract(pastedUnionRect.topLeft());
        }

        const pastedCenterPoint = pastedUnionRect.getCenterPoint();
        return position.subtract(pastedCenterPoint);
    }
}

import { ICommand } from '@model-framework/command';
import { StickyZoneKey } from '@view-model/domain/key';
import { DisplayOrderRepository } from '@view-model/models/framework/display-order';
import { NodeEntityOperation } from '../adapter';
import { StickyNode } from '../domain';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class StickyNodeDisplayOrderAddToZoneCommand implements ICommand {
    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly node: StickyNode,
        private readonly nodeEntityOperation: NodeEntityOperation,
        private readonly zoneKey: StickyZoneKey,
        private readonly displayOrderRepository: DisplayOrderRepository
    ) {}

    do(): void {
        this.nodeEntityOperation.save(this.node);
        this.displayOrderRepository.addNode(this.node.id, this.zoneKey.id.toString()).then();
    }

    undo(): void {
        const node = NodeEntityOperation.getNode(this.node.key, this.viewModel);
        if (!node) return;
        this.nodeEntityOperation.remove(node);
        this.displayOrderRepository.removeNode(this.node.id).then();
    }

    redo(): void {
        const node = NodeEntityOperation.getNode(this.node.key, this.viewModel);
        if (node) return;
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return !!NodeEntityOperation.getNode(this.node.key, this.viewModel);
    }

    async canRedo(): Promise<boolean> {
        return !NodeEntityOperation.getNode(this.node.key, this.viewModel);
    }
}

import { Position } from '@view-model/models/common/types/ui';
import { Point } from '@view-model/models/common/basic';

type LinkerType = 'create' | 'replaceSource' | 'replaceTarget';
interface ILinkerState<T> {
    source: T | null;
    sourcePosition: Position;
    target: T | null;
    targetPosition: Position;
    linkerType: LinkerType;
}

export class LinkerState<T> {
    public readonly source: T | null;
    public readonly sourcePosition: Point;
    public readonly target: T | null;
    public readonly targetPosition: Point;
    public readonly linkerType: LinkerType;

    constructor({ source, sourcePosition, target, targetPosition, linkerType }: ILinkerState<T>) {
        this.source = source;
        this.sourcePosition = Point.fromPosition(sourcePosition);
        this.target = target;
        this.targetPosition = Point.fromPosition(targetPosition);
        this.linkerType = linkerType;
    }

    static creatingStart<T>(source: T, startPosition: Position): LinkerState<T> {
        return new this<T>({
            source,
            sourcePosition: Point.fromPosition(startPosition),
            target: null,
            targetPosition: Point.fromPosition(startPosition),
            linkerType: 'create',
        });
    }

    static replacingSourceStart<T>(
        source: T,
        sourcePosition: Position,
        target: T,
        targetPosition: Position
    ): LinkerState<T> {
        return new this<T>({
            source,
            sourcePosition: Point.fromPosition(sourcePosition),
            target,
            targetPosition: Point.fromPosition(targetPosition),
            linkerType: 'replaceSource',
        });
    }

    static replacingTargetStart<T>(
        source: T,
        sourcePosition: Position,
        target: T,
        targetPosition: Position
    ): LinkerState<T> {
        return new this<T>({
            source,
            sourcePosition: Point.fromPosition(sourcePosition),
            target,
            targetPosition: Point.fromPosition(targetPosition),
            linkerType: 'replaceTarget',
        });
    }

    move(element: T | null, currentPosition: Position): LinkerState<T> {
        return this.isCreating() || this.isTargetReplacing()
            ? new LinkerState<T>({ ...this.attributes(), target: element, targetPosition: currentPosition })
            : new LinkerState<T>({ ...this.attributes(), source: element, sourcePosition: currentPosition });
    }

    isCreating(): boolean {
        return this.linkerType === 'create';
    }

    isSourceReplacing(): boolean {
        return this.linkerType === 'replaceSource';
    }

    isTargetReplacing(): boolean {
        return this.linkerType === 'replaceTarget';
    }

    private attributes(): ILinkerState<T> {
        const { source, sourcePosition, target, targetPosition, linkerType } = this;
        return { source, sourcePosition, target, targetPosition, linkerType };
    }
}

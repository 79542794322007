export class UpdateUtil {
    /**
     * objのキーの前に path を連結した obj を返します。
     *
     * @param path objのキーの前方に連結するパス
     * @param obj 連結対象のレコードオブジェクト
     */
    static prependPath<Path extends string, Key extends string>(
        path: Path,
        obj: Record<Key, unknown>
    ): Record<`${Path}/${Key}`, unknown> {
        const keys = Object.keys(obj) as (keyof typeof obj)[];
        return keys.reduce(
            (acc, key) => {
                acc[`${path}/${key}`] = obj[key];
                return acc;
            },
            {} as Record<`${Path}/${Key}`, unknown>
        );
    }
}

import { GroupEntity, GroupMemberRole } from '@group/domain';
import { BasicRoleSelect, GroupConsoleTitle } from '../common';
import { Button } from '@framework/ui/atoms/Button';
import { useCallback, useMemo, useState } from 'react';
import { EmailParseResult } from './EmailAddressParser';
import { createGroupMemberInvitations, MAX_INVITATION_COUNT } from './createGroupMemberInvitations';
import { SignUpMethodName } from '@group/domain/GroupEntity';
import { MemberInvitationEmailInput } from './MemberInvitationEmailInput';
import { useCurrentUserData } from '@user/UserData';
import { toast } from 'react-hot-toast';

type Props = {
    group: GroupEntity;
};

export const GroupConsoleInvitePage: React.FC<Props> = ({ group }: Props) => {
    const currentUserData = useCurrentUserData();
    const [emails, setEmails] = useState<EmailParseResult[]>([]);
    const [role, setRole] = useState<GroupMemberRole>('member');
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const hasInvalidAddress = useMemo(() => emails.some(({ valid }) => !valid), [emails]);
    const sendable = emails.length > 0 && emails.length <= MAX_INVITATION_COUNT && !hasInvalidAddress;

    const availableSignUpMethods = useMemo(() => group.setting.availableSignUpMethods, [group]);

    const handleAddEmails = useCallback((addedEmails: EmailParseResult[]) => {
        setEmails((emails) => [...emails, ...addedEmails]);
    }, []);

    const handleRemoveEmail = useCallback((removeEmail: string) => {
        setEmails((emails) => emails.filter(({ email }) => email != removeEmail));
    }, []);

    const handleRemoveInvalidEmails = useCallback(() => {
        setEmails((emails) => emails.filter(({ valid }) => valid));
    }, []);

    const handleChangeMessage = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    }, []);

    const handleInvite = useCallback(async () => {
        if (!currentUserData) {
            alert('送信者情報がないため、招待を行うことはできません。');
            return;
        }

        if (!sendable) {
            alert('不正なメールアドレスが含まれているか件数が多すぎるため、招待を行うことはできません。');
            return;
        }

        setLoading(true);

        const emailAddresses = emails.map(({ email }) => email);
        if (await createGroupMemberInvitations(group, currentUserData, emailAddresses, role, message)) {
            setEmails([]);
            setRole('member');
            setMessage('');
            setLoading(false);

            toast.success('招待を送信しました');
        } else {
            alert('グループメンバーの招待に失敗しました。もう一度試してみるか、サポート担当者にお問合せください。');
        }
    }, [emails, group, message, role, sendable, currentUserData]);

    return (
        <div className="flex max-w-125 flex-col">
            <GroupConsoleTitle title="招待" />
            <div className="mb-4">
                <div className="mb-2 font-bold">送付先メールアドレス</div>
                <MemberInvitationEmailInput
                    emails={emails}
                    disabled={loading}
                    onAddEmails={handleAddEmails}
                    onRemoveEmail={handleRemoveEmail}
                    onRemoveInvalidEmails={handleRemoveInvalidEmails}
                />
            </div>
            <div className="mb-4">
                <div className="mb-2 font-bold">ロール</div>
                <BasicRoleSelect defaultRole="member" onChange={setRole} disabled={loading} />
            </div>
            <div className="mb-4">
                <div className="mb-2 font-bold">招待メッセージ(オプション)</div>
                <textarea
                    value={message}
                    onChange={handleChangeMessage}
                    className="w-full rounded-sm border border-gray-500 p-2"
                    rows={5}
                    disabled={loading}
                    placeholder="メッセージを入力"
                />
            </div>
            <div className="mb-4">
                ※招待メールにはあなたの名前とメールアドレスが記載されます。
                <br />
                ※招待された方がユーザ登録時に利用可能なログイン方法は次のとおりです。
                <ul className="list-inside list-disc px-6">
                    {SignUpMethodName.values().map((name) => (
                        <li key={name}>
                            {SignUpMethodName.getLocalString(name)} (
                            {availableSignUpMethods.availableOf(name) ? '利用可能' : '利用不可'})
                        </li>
                    ))}
                </ul>
            </div>
            <Button
                color="brand"
                className="ml-auto disabled:cursor-not-allowed"
                onClick={handleInvite}
                disabled={!sendable}
            >
                招待を送信する
            </Button>
        </div>
    );
};

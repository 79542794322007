import { useCallback } from 'react';
import { NodeReaction, ReactionType } from '../domain';
import { ModelId, NodeId, UserId, ViewModelId } from '@schema-common/base';
import { RTDBPath, RefBuilder, ServerValue, usePersistedObject } from '@framework/repository';
import { toast } from 'react-hot-toast';

export const useNodeReaction = (
    viewModelId: ViewModelId,
    modelId: ModelId,
    nodeId: NodeId
): {
    reaction: NodeReaction | null | undefined;
    toggleReaction: (reactionName: ReactionType, userId: UserId, userName: string) => void;
} => {
    const [reaction] = usePersistedObject(NodeReaction, RTDBPath.Node.reactionPath(viewModelId, modelId, nodeId));

    const toggleReaction = useCallback(
        (reactionType: ReactionType, userId: UserId, userName: string) => {
            const ref = RefBuilder.ref(
                RTDBPath.Node.reactionItemPath(viewModelId, modelId, nodeId, reactionType, userId)
            );

            if (reaction && reaction.isReactionExecuted(reactionType, userId)) {
                ref.remove();
            } else {
                // 50件以上のreactionは保存しない
                if (reaction?.hasMaxReached(reactionType)) {
                    toast.error('このリアクションは登録可能な上限に達しています');
                } else {
                    ref.set({ name: userName, timestamp: ServerValue.TIMESTAMP });
                }
            }
        },
        [modelId, nodeId, reaction, viewModelId]
    );

    return { reaction, toggleReaction };
};

import { useMemo } from 'react';
import { Workspace } from '@workspace/domain/workspace/Workspace';

export const selectWorkspaces = (query: string, workspaces: Workspace[]): Workspace[] => {
    return workspaces.filter((workspace) => {
        return workspace.matchQuery(query);
    });
};

export const useWorkspaceFilter = (workspaces: Workspace[] | null, query: string): Workspace[] | null => {
    return useMemo(() => {
        if (query && workspaces) {
            return selectWorkspaces(query, workspaces);
        }
        return workspaces;
    }, [workspaces, query]);
};

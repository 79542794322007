import { JSONValue } from '@model-framework/repository/JSONRepository';
import { isEqual, keys } from 'lodash';
import { ICommand } from '.';
import { DBPath, RefBuilder } from '@framework/repository';

export class UpdateMultiPathCommand implements ICommand {
    constructor(
        private readonly oldValues: Record<DBPath, JSONValue>,
        private readonly newValues: Record<DBPath, JSONValue>
    ) {}

    do() {
        const { newValues } = this;
        RefBuilder.ref().update(newValues).then();
    }
    undo() {
        const { oldValues } = this;
        RefBuilder.ref().update(oldValues).then();
    }

    redo() {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return this.compareCurrentValueAnd(this.newValues);
    }

    async canRedo(): Promise<boolean> {
        return this.compareCurrentValueAnd(this.oldValues);
    }

    private async compareCurrentValueAnd(values: Record<string, JSONValue>): Promise<boolean> {
        const currentValues: Record<string, JSONValue> = {};
        for (const key of keys(values)) {
            const snapshot = await RefBuilder.ref().child(key).once('value');
            currentValues[key] = snapshot.val();
        }

        return isEqual(values, currentValues);
    }
}

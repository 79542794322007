import { ModelId, ViewModelId } from '@schema-common/base';
import { useSnapshot } from '@framework/hooks';
import { RTDBPath } from '@framework/repository';
import { clientSelectedItemCollectionAtomFamily } from './clientSelectedItemCollectionAtom';
import { ClientSelectedItemJSON } from '@schema-app/view-model/viewer-contents/{viewModelId}/client-selected-items/{modelId}/ClientSelectedItemJSON';
import { ClientSelectedItem, ClientSelectedItemCollection } from '@model-framework/client-selected-item/domain';
import { useSetAtom } from 'jotai/react';
import { useEffect } from 'react';

// ノードを選択しているユーザを取得してatomに読み込む
export function ClientSelectedItemCollectionLoader({
    viewModelId,
    modelId,
}: {
    viewModelId: ViewModelId;
    modelId: ModelId;
}) {
    const setState = useSetAtom(clientSelectedItemCollectionAtomFamily(modelId));

    useSnapshot({
        path: RTDBPath.ViewModelViewer.clientSelectedItemPath(viewModelId, modelId),
        load({ getChildValues }) {
            const values = Object.values(getChildValues() as Record<string, ClientSelectedItemJSON>);
            const selectedItems = values.map((item) => ClientSelectedItem.load(item));
            setState(new ClientSelectedItemCollection(selectedItems));
        },
        stateless: true,
    });

    useEffect(() => {
        return () => {
            setState(null);
        };
    }, [setState]);

    return null;
}

import { Multiplicity } from '../../domain';
import { MultiplicityMenu } from './MultiplicityMenu';
import { Point, Size } from '@view-model/models/common/basic';

const MenuItemSize = new Size(160, 80);
const OffsetY = 68;
const FontSize = 35;

type Props = {
    multiplicity: Multiplicity;
    onUpdateMultiplicity: (multiplicity: Multiplicity) => void;
};

export const LinkMultiplicityMenu: React.FC<Props> = ({ multiplicity, onUpdateMultiplicity }: Props) => {
    const menuMultiplicities = [
        new Multiplicity({ lower: 0, upper: 1 }),
        new Multiplicity({ lower: 0, upper: Infinity }),
        new Multiplicity({ lower: 1, upper: 1 }),
        new Multiplicity({ lower: 1, upper: Infinity }),
    ];
    const totalMenuHeight = MenuItemSize.height * menuMultiplicities.length;
    const menuOffset = new Point(0, totalMenuHeight / 2 + OffsetY);

    return (
        <g transform={menuOffset.toSVGTranslate()}>
            <rect
                x={0 - MenuItemSize.width / 2}
                y={0 - (MenuItemSize.height * menuMultiplicities.length) / 2}
                width={MenuItemSize.width}
                height={MenuItemSize.height * menuMultiplicities.length}
                fill="white"
                stroke="#e0e0e0"
                strokeWidth={4}
            />
            <g>
                {menuMultiplicities.map((m, i) => {
                    return (
                        <MultiplicityMenu
                            key={m.toString()}
                            size={MenuItemSize}
                            fontSize={FontSize}
                            transform={`translate(0, ${(i - 1.5) * MenuItemSize.height})`}
                            multiplicity={m}
                            isSelected={m.isEquals(multiplicity)}
                            onClick={() => onUpdateMultiplicity(m)}
                        />
                    );
                })}
            </g>
        </g>
    );
};

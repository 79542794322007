import { useCallback, useState } from 'react';
import { ModelCommentBodyForm } from '../ModelCommentBodyForm';
import { Button } from '@framework/ui/atoms/Button';
import { usePopupAvailable } from '@view-model/models/common/hooks';

type Props = {
    onReplyCommentSubmit(body: string): void;
};

export const ModelCommentReply: React.FC<Props> = ({ onReplyCommentSubmit }: Props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [body, setBody] = useState<string>('');

    usePopupAvailable(body.length > 0);

    const handleReplyEditing = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleSubmit = useCallback(() => {
        onReplyCommentSubmit(body);

        setIsEditing(false);
        setBody('');
    }, [body, onReplyCommentSubmit]);

    const handleCancel = useCallback(() => {
        setIsEditing(false);
        setBody('');
    }, []);

    return (
        <>
            <ModelCommentBodyForm
                autoFocus={false}
                body={body}
                onChange={(body) => setBody(body)}
                onSubmit={handleSubmit}
                placeholder={'コメントに返信 | Reply'}
                onFocus={handleReplyEditing}
                minRows={2}
            />
            {isEditing && (
                <div>
                    <Button color="brand" onClick={handleSubmit} disabled={!body}>
                        送信 | Send
                    </Button>
                    <Button color="flat" onClick={handleCancel}>
                        閉じる | Close
                    </Button>
                </div>
            )}
        </>
    );
};

// 吹き出ししっぽの吹き出しに接する辺
import { Direction, Point, Rect, Size, Triangle } from '@view-model/models/common/basic';
import { CalloutRectVerticalEdge } from '@model-framework/ui/Callout/CalloutRectVerticalEdge';
import { ICalloutRectEdge } from './ICalloutRectEdge';
import { CalloutRectHorizontalEdge } from './CalloutRectHorizontalEdge';

/**
 * 吹き出し矩形
 */
export class CalloutRect {
    private readonly rect: Rect;

    /**
     * @param position 矩形ポジション
     * @param size 矩形サイズ
     * @param tailBaseLineMargin しっぽの底辺の矩形端からのマージン
     * @param tailBaseLineLength しっぽの底辺の長さ
     */
    constructor(
        position: Point,
        size: Size,
        private readonly tailBaseLineMargin: number,
        private readonly tailBaseLineLength: number
    ) {
        this.rect = new Rect(position, size);
    }

    private nearestEdgeOf(targetRect: Rect): ICalloutRectEdge | null {
        const { rect } = this;
        const collisionArea = targetRect.applyMarginKeepingCenter(rect.height * 0.5, rect.width * 0.5);
        const direction = collisionArea.toDiagonallyDividedArea().directionOf(rect.getCenterPoint());

        const top = rect.getTop();
        const bottom = rect.getBottom();
        const left = rect.getLeft();
        const right = rect.getRight();

        return direction === Direction.Up
            ? new CalloutRectHorizontalEdge(left, right, bottom)
            : direction === Direction.Down
              ? new CalloutRectHorizontalEdge(left, right, top)
              : direction === Direction.Left
                ? new CalloutRectVerticalEdge(right, top, bottom)
                : direction === Direction.Right
                  ? new CalloutRectVerticalEdge(left, top, bottom)
                  : null;
    }

    /**
     * 吹き出しの言及対象矩形に対するしっぽの図形を返す。
     * @param targetRect
     */
    tailTo(targetRect: Rect): Triangle | null {
        // しっぽを描画する辺を取得
        const edge = this.nearestEdgeOf(targetRect);
        if (!edge) return null;

        // しっぽを描画する辺の上で、しっぽの底辺の位置を決める
        const tailBase = edge.tailBaseLineFor(targetRect, this.tailBaseLineMargin, this.tailBaseLineLength);

        // 底辺からターゲットに向けたしっぽを生成する
        return tailBase.tailTo(targetRect);
    }
}

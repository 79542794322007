import { useCallback, useState } from 'react';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { EditPasswordForm } from '@user/EditPasswordForm';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

type Props = {
    isOpen: boolean;
    onClose(): void;
    mailAddress: string;
};

export const UserSignUpModal: React.FC<Props> = ({ isOpen, onClose, mailAddress }: Props) => {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState('');

    const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);
    const handleChangePassword = useCallback((password: string) => {
        setPassword(password);
    }, []);
    const isEmpty = password === '';

    const handleClose = useCallback(() => {
        setProcessing(false);
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        setProcessing(true);

        FunctionsUserActions.signUpPasswordAuth(name, mailAddress, password)
            .then((token) => {
                return FirebaseAuthenticationClient.signInWithCustomToken(token);
            })
            .then(() => {
                onClose();
            })
            .catch((error) => {
                // FirebaseErrorはmessageでしかエラー内容を判別できない
                if (error.message && error.message.includes('same email address')) {
                    setError(
                        'Balusにユーザ登録されているメールアドレスです。ユーザ登録フォームを閉じてからログインを選択して、ログインしてください。'
                    );
                } else {
                    setError('ユーザ登録に失敗しました。時間をあけてもう一度試してみてください。');
                }
                console.error(error);
            })
            .finally(() => {
                setProcessing(false);
            });
    }, [name, mailAddress, password, onClose]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} className="flex flex-col">
            {/* header */}
            <div className="px-8 pb-4 pt-8 text-2xl font-bold">ユーザ登録</div>
            <hr className="w-full" />

            {/* content */}
            <div className="flex flex-col p-8">
                <div className="pb-2 font-bold">メールアドレス (変更できません)</div>
                <input
                    type="text"
                    className="cursor-not-allowed rounded-sm border bg-gray-200 p-2"
                    defaultValue={mailAddress}
                    disabled
                />

                <div className="pb-2 pt-8 font-bold">名前</div>
                <input
                    type="text"
                    className="rounded-sm border p-2"
                    value={name}
                    onChange={handleChangeName}
                    autoFocus
                />

                <EditPasswordForm onChange={handleChangePassword} disabled={processing} />

                {error && <div className="pt-8 text-red-700">{error}</div>}
            </div>

            {/* footer */}
            <hr className="w-full" />
            <div className="flex justify-end px-8 pb-8 pt-4">
                <FlatButton onClick={handleClose}>閉じる</FlatButton>
                <FlatButton
                    color="brand"
                    onClick={handleSubmit}
                    className="ml-2 px-8"
                    disabled={isEmpty || processing}
                    loading={processing}
                >
                    ユーザ登録
                </FlatButton>
            </div>
        </Modal>
    );
};

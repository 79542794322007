import { Angle, Point, Rect } from '@view-model/models/common/basic';
import { LineEdge } from '@model-framework/link';

/**
 * 2次のベジェ曲線クラス
 */
export class BezierLine {
    /**
     * コンストラクタ
     * @param p1 始点
     * @param p2 コントロールポイント
     * @param p3 終点
     */
    constructor(
        private readonly p1: Point,
        private readonly p2: Point,
        private readonly p3: Point
    ) {}

    /**
     * 指定されたパラメータt（0〜1）のベジェ曲線上の座標を返します。
     * @param t
     */
    getPoint(t: number): Point {
        const { p1, p2, p3 } = this;

        const q1 = p1.interpolated(p2, t);
        const q2 = p2.interpolated(p3, t);

        return q1.interpolated(q2, t);
    }

    startEdge(): LineEdge {
        const { p1, p2 } = this;

        // コントロールポイントからみたときの始点への向きが矢印の向き
        return new LineEdge(p1, Angle.angleBetween(p2, p1));
    }

    endEdge(): LineEdge {
        const { p2, p3 } = this;

        // コントロールポイントからみたときの終点への向きが矢印の向き
        return new LineEdge(p3, Angle.angleBetween(p2, p3));
    }

    /**
     * このベジェ曲線を包含する矩形を返します。
     */
    boundingRect(): Rect {
        const [p1, p2, ...rest] = this.toPoints();

        return Rect.fromPoints(p1, p2, ...rest);
    }

    /**
     * 始点から終点まで一定の距離閾値で区切ったベジェ曲線上の点を返します。
     * @private
     */
    private toPoints(): Point[] {
        const { p1, p2, p3 } = this;

        return BezierLine.toPointsRecursive(p1, p2, p3).concat(p3);
    }

    private static toPointsRecursive(p1: Point, p2: Point, p3: Point): Point[] {
        if (p1.distanceSquared(p3) < 10.0) return [p1];

        const p12 = p1.interpolated(p2, 0.5);
        const p23 = p2.interpolated(p3, 0.5);
        const mid = p12.interpolated(p23, 0.5);

        return this.toPointsRecursive(p1, p12, mid).concat(this.toPointsRecursive(mid, p23, p3));
    }

    /**
     * この2次ベジェ曲線を表現する、SVGの<path>要素d属性の値を返します。
     * https://developer.mozilla.org/ja/docs/Web/SVG/Element/path
     */
    getSVGPath(): string {
        const { p1, p2, p3 } = this;

        return `M${p1.toSVGPoint()} Q${p2.toSVGPoint()} ${p3.toSVGPoint()}`;
    }

    intersects(rect: Rect): boolean {
        const { p1, p2, p3 } = this;

        return rect.includePosition(p1) || rect.includePosition(p3) || BezierLine.intersectsRecursive(p1, p2, p3, rect);
    }

    private static intersectsRecursive(p1: Point, p2: Point, p3: Point, rect: Rect): boolean {
        // 始点終点が十分に近づいた場合はそこで探索を打ち切る
        if (p1.distanceSquared(p3) < 1.0) {
            return false;
        }

        // 曲線の中点を求めて当たり判定をする
        const q1 = p1.interpolated(p2, 0.5);
        const q2 = p2.interpolated(p3, 0.5);
        const q3 = q1.interpolated(q2, 0.5);

        if (rect.includePosition(q3)) return true;

        // ヒットしていなければ曲線を2分割して再起的に当たり判定をする
        return BezierLine.intersectsRecursive(p1, q1, q3, rect) || BezierLine.intersectsRecursive(q3, q2, p3, rect);
    }
}

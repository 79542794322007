type Props = {
    onClick(): void;
    children: React.ReactNode;
};

export const MenuButton: React.FC<Props> = ({ onClick, children }: Props) => {
    return (
        <button onClick={onClick} className="block w-full px-4 py-2 text-left text-gray-800 hover:bg-gray-200">
            {children}
        </button>
    );
};

import { RefBuilder, DBPath, Reference, ServerValue } from '@framework/repository';
import { IMessage, IMessageClass } from './IMessage';
import { NotificationJSON, NotificationJSONOnWrite } from '@schema-common/notification';

export class Channel<MessageJSONType, MessageType extends IMessage<MessageJSONType>> {
    private readonly ref: Reference;

    /**
     * 購読・発信可能な通知チャンネル。
     *
     * @param path 永続先リポジトリパス
     * @param messageClass メッセージクラス
     */
    constructor(
        path: DBPath,
        private readonly messageClass: IMessageClass<MessageJSONType, MessageType>
    ) {
        this.ref = RefBuilder.ref(path);
    }

    subscribe(callback: (message: MessageType) => void): void {
        const { messageClass } = this;

        // この時点に発行されたキー以降（=この時点以降）のメッセージをリッスンする
        const query = this.ref.orderByKey().startAt(this.ref.push().key);
        query.on('child_added', async (snapshot) => {
            const j = snapshot.val() as NotificationJSON<MessageJSONType>;
            if (!j) return;

            callback(messageClass.load(j.payload));
        });
    }

    unsubscribe(): void {
        this.ref.off('child_added');
    }

    notify(message: IMessage<MessageJSONType>): void {
        const value: NotificationJSONOnWrite<MessageJSONType> = {
            notified_at: ServerValue.TIMESTAMP,
            payload: message.dump(),
        };

        this.ref.push(value);
    }
}

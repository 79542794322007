import { useMemo } from 'react';
import { RTDBPath, useCollectionListener } from '@framework/repository';
import { GroupMemberInvitation } from '@group/domain';

export const useGroupMemberInvitations = (groupId: string): GroupMemberInvitation[] | null => {
    const invitations = useCollectionListener(GroupMemberInvitation, RTDBPath.Group.memberInvitationsPath(groupId));
    return useMemo(() => {
        if (!invitations) return null;
        return invitations.sort(GroupMemberInvitation.compareByCreatedAt).reverse();
    }, [invitations]);
};

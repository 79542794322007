import { useCallback, useRef } from 'react';
import { DraggableStateManager } from './DraggableStateManager';
import { Draggable } from '@model-framework/drag/Draggable';

/**
 * ドラッグ可能なUIコンポーネントのコールバック定義
 */
type DraggableComponentCallbacks<T> = {
    onDragStart(initialState: T): void;
    onDrag(dx: number, dy: number): void;
    onDragEnd(): void;
};

/**
 * ドラッグによって変更可能なオブジェクト(Draggableインタフェース）とドラッグ可能なReactコンポーネント
 * （DraggableComponentCallbacksをインタフェーストして持つ）をつなぐフック。
 *
 * このフックからはReactコンポーネントに渡すためのコールバック群（DraggableComponentCallbacks）を返すので、
 * それをUIコンポーネントと繋ぎ込むことで、状態変更を受け取れるようになる（引数に渡したコールバックによって）。
 *
 * @param onDrag UIのドラッグ時に、ドラッグ後の状態を通知するコールバック
 * @param onDragEnd UIのドラッグ完了時に、初期状態と現在の状態を通知するコールバック（Undo登録の用途を想定）
 */
export function useDraggable<T extends Draggable<T>>(
    onDrag: (draggedState: T) => void,
    onDragEnd?: (initialState: T, currentState: T) => void
): DraggableComponentCallbacks<T> {
    // DraggableStateManagerが毎回生成されてしまうが処理が軽いため許容する
    const dragManagerRef = useRef(new DraggableStateManager<T>());

    const handleOnDragStart = useCallback((initialState: T) => {
        dragManagerRef.current.dragStart(initialState);
    }, []);

    const handleOnDrag = useCallback(
        (dx: number, dy: number) => {
            const draggedState = dragManagerRef.current.drag(dx, dy);
            onDrag(draggedState);
        },
        [onDrag]
    );

    const handleOnDragEnd = useCallback(() => {
        const [initialState, currentState] = dragManagerRef.current.dragEnd();
        onDragEnd?.(initialState, currentState);
    }, [onDragEnd]);

    return {
        onDragStart: handleOnDragStart,
        onDrag: handleOnDrag,
        onDragEnd: handleOnDragEnd,
    };
}

import { ICommand } from './ICommand';

export class CompositeCommand implements ICommand {
    private commands: ICommand[];

    constructor(...commands: ICommand[]) {
        this.commands = commands;
    }

    /**
     * 未定義(undefined)になりうるコマンドリストをまとめるユーティリティ関数。
     * 一つも定義されたコマンドがない場合は undefinedを返します。
     * 一つしか定義されたコマンドがない場合は、そのコマンドそのものを返します。
     * ２つ以上のコマンドが定義されている場合は、CompositeCommandでまとめたコマンドを返します。
     * @param optionalCommands
     */
    static composeOptionalCommands(...optionalCommands: (ICommand | undefined | null)[]): ICommand | null {
        const commands = optionalCommands.filter((command): command is ICommand => !!command);

        return commands.length === 0 ? null : commands.length === 1 ? commands[0] : new CompositeCommand(...commands);
    }

    do(): void {
        this.commands.forEach((command) => command.do());
    }

    undo(): void {
        if (!this.canUndo()) return;
        this.commands
            .slice()
            .reverse()
            .forEach((command) => command.undo());
    }

    redo(): void {
        if (!this.canRedo()) return;
        this.commands.forEach((command) => command.redo());
    }

    async canUndo(): Promise<boolean> {
        const results = await Promise.all(this.commands.map((command) => command.canUndo()));
        return results.every((canUndo) => canUndo);
    }

    async canRedo(): Promise<boolean> {
        const results = await Promise.all(this.commands.map((command) => command.canRedo()));
        return results.every((canRedo) => canRedo);
    }
}

import { useCallback } from 'react';

export const useFavicon = (url: string) => {
    const FAVICON_SIZE = 48;
    const getFaviconURL = useCallback(() => {
        return `https://www.google.com/s2/favicons?sz=${FAVICON_SIZE}&domain=${url}`;
    }, [url]);

    return {
        getFaviconURL,
        FAVICON_SIZE,
    };
};

import { memo } from 'react';
import { generateDefaultUserIcon } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';

type Props = {
    x: number;
    y: number;
    userName: string;
    userId: string;
    userImageURL: string | null;
};

export const ClientMouseCursor = memo(({ x, y, userName, userId, userImageURL }: Props): React.ReactElement => {
    return (
        <g transform={`translate(${x},${y})`}>
            {/* マウスカーソルの座標位置に、 `locationArrow` の先端が来るように位置変更している */}
            {/* 詳細: https://github.com/levii/balus-app/pull/1962 */}
            <g pointerEvents="none">
                <g transform="translate(44, -12) scale(-1, 1)">
                    <FontAwesomeIcon
                        width="42"
                        height="48"
                        className="stroke-white stroke-[36px] text-brand"
                        icon={faLocationArrow}
                    />
                </g>
                <foreignObject transform="translate(36,36)" width="64" height="64">
                    {userImageURL ? (
                        <img className="z-[999] size-16 rounded-full bg-gray-500 object-cover" src={userImageURL} />
                    ) : (
                        <div className="z-[999] size-16 overflow-hidden rounded-full bg-gray-500 object-cover">
                            {generateDefaultUserIcon({
                                id: userId,
                                name: userName,
                                size: 64,
                            })}
                        </div>
                    )}
                </foreignObject>
            </g>
        </g>
    );
});

ClientMouseCursor.displayName = 'ClientMouseCursor';

import { ICommand } from '@model-framework/command';
import { NodeStyleSet } from '../domain';
import { NodeRepository } from '../domain/NodeRepository';

export class NodeStyleUpdateMultiCommand implements ICommand {
    constructor(
        private readonly fromValue: NodeStyleSet,
        private readonly toValue: NodeStyleSet,
        private readonly repository: NodeRepository
    ) {}

    do(): void {
        this.toValue.saveTo(this.repository).then();
    }

    undo(): void {
        this.fromValue.saveTo(this.repository).then();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return this.toValue.isSameOn(this.repository);
    }

    async canRedo(): Promise<boolean> {
        return this.fromValue.isSameOn(this.repository);
    }
}

import { useMemo } from 'react';
import { Viewer } from './Viewer';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';
import { useViewers } from '@view-model/models/sticky/viewers/useViewers';

/**
 * 現在ビューモデルを閲覧中のユーザ情報を返す。
 *
 * - 同一ユーザが複数のブラウザ(クライアント)で閲覧している場合でも、ユーザ情報は１件のみ返す
 * - 自分のユーザ情報は返さない
 *
 * @returns
 */
export const useReadViewers = (): Viewer[] => {
    const currentUser = useCurrentUserPublicProfile();
    const viewers = useViewers();

    return useMemo(() => {
        const map: Record<string, Viewer> = {};

        const otherViewers = (viewers || []).filter((viewer) => viewer.userId != currentUser?.id);
        // otherViewersをtimestampについて昇順にソートしている(配列の後ろのほうに行くほど、最近操作したViewer)
        const sortByTimestamp = otherViewers.sort(Viewer.compareByTimestamp);

        // 事前にtimestampについて照準にソートすることで、1人のユーザーが複数タブ開いている場合に、そのユーザーのViewer情報を最後に操作したタブのものにしている
        sortByTimestamp.forEach((otherViewer) => {
            map[otherViewer.userId] = otherViewer;
        });

        return Object.values(map).sort(Viewer.compareByClientId);
    }, [currentUser, viewers]);
};

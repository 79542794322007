import { classNames, generateDefaultUserIcon } from '@framework/utils';

type Size = 'small' | 'medium' | 'large';

type Props = {
    name: string;
    id: string;
    imageUrl: string | null;
    size: Size;
    className?: string;
};

const sizeMap: Record<Size, string> = {
    small: 'w-4 h-4',
    medium: 'w-6 h-6',
    large: 'w-10 h-10',
};

const defaultIconSizeMap: Record<Size, number> = {
    small: 14,
    medium: 22,
    large: 38,
};

export const UserIcon: React.FC<Props> = ({ name, id, imageUrl, size = 'medium', className }: Props) => {
    return (
        <div
            className={classNames(
                'flex-shrink-0', // flexbox の中でアイコン画像が潰れないようにする
                'block',
                'rounded-full',
                'overflow-hidden',
                'border border-gray-600',
                sizeMap[size],
                className
            )}
        >
            {imageUrl ? (
                <img src={imageUrl} alt={name} className="size-full object-cover" />
            ) : (
                <div className="size-full object-cover">
                    {generateDefaultUserIcon({
                        id: id,
                        name: name,
                        size: defaultIconSizeMap[size],
                    })}
                </div>
            )}
        </div>
    );
};

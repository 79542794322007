import { CreatingModelComment } from './domain';
import { CreatingModelCommentView } from './components';
import { usePopupAvailable } from '@view-model/models/common/hooks';

type Props = {
    comment: CreatingModelComment | undefined;
    onUpdateCommentPosition(dx: number, dy: number): void;
    onUpdateCommentPositionDragEnd(): void;
    onSubmit(creatingComment: CreatingModelComment): void;
    onCancel(): void;
};

/**
 * 下書きがある場合にページ遷移をブロックする仕組みを入れるためのラッパーコンポーネント。
 */
export const CreatingModelCommentWrapper: React.FC<Props> = ({
    comment,
    onUpdateCommentPosition,
    onUpdateCommentPositionDragEnd,
    onCancel,
    onSubmit,
}: Props) => {
    // ページ遷移ブロックのon/off（フックの名前が分かりにくいのでコメント）
    usePopupAvailable(!!comment);

    if (!comment) return null;

    return (
        <CreatingModelCommentView
            creatingComment={comment}
            onDrag={onUpdateCommentPosition}
            onDragEnd={onUpdateCommentPositionDragEnd}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    );
};

type Props = {
    size: number; // 2等辺三角形の底辺の長さ
    className?: string; // tailwindcssによるスタイル設定のため
};

/**
 * 右向きの二等辺三角形。底辺（左側の辺）の長さがsize。
 * 右向きなのはUI座標系（SVG座標系）で回転させるときの0度の向きと合わせるため
 */
export const TriangleMarkView: React.FC<Props & React.SVGProps<SVGPathElement>> = ({
    size,
    className,
    ...props
}: Props & React.SVGProps<SVGPathElement>) => {
    const s = size / 2;
    return <path d={`M${s},${0}L${-s},${s}L${-s},${-s}Z`} className={className} {...props} />;
};

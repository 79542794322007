type Props = unknown;

export const ShareDisabledMessage: React.FC<Props> = () => {
    return (
        <div className="my-2 rounded-lg border border-red-500 bg-red-200 p-4">
            このワークスペースではモデルのURL共有が無効になっています。
            <br />
            ワークスペースの共有設定から「ビューモデルのURL共有機能」を有効にした後に設定を行ってください。
        </div>
    );
};

import { ICommand } from '@model-framework/command';
import { LinkEntityOperation } from '../adapter';
import { LinkEntity } from '../domain';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class LinkReverseCommand implements ICommand {
    private readonly reversedLink: LinkEntity;

    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly entityOperation: LinkEntityOperation,
        private readonly link: LinkEntity
    ) {
        this.reversedLink = link.getReversedLink();
    }

    do(): void {
        this.entityOperation.save(this.reversedLink);
    }

    undo(): void {
        this.entityOperation.save(this.link);
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return this.getCurrentLink()?.isEqual(this.reversedLink) || false;
    }

    async canRedo(): Promise<boolean> {
        return this.getCurrentLink()?.isEqual(this.link) || false;
    }

    private getCurrentLink(): LinkEntity | null {
        return LinkEntityOperation.getLink(this.link.key, this.viewModel);
    }
}

import { MainMenuBox, MainMenuIcon } from '@model-framework/menu';
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons';

type Props = {
    menuSize: number;
    onClick(): void;
    text: string;
};

/**
 * ゾーンのビュー化メニューボタン
 * @param menuSize
 * @param onClick
 * @constructor
 */
export const ZoneToViewMenuButton: React.FC<Props> = ({ menuSize, onClick, text }: Props) => {
    return (
        <>
            <MainMenuBox menuSize={menuSize} isSelected={false} onClick={onClick} text={text} />
            <MainMenuIcon icon={faWindowMaximize} menuSize={menuSize} />
        </>
    );
};

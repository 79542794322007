import ReactModal from 'react-modal';
import { CloseIcon } from './CloseIcon';

type Props = {
    isOpen: boolean;
    onClose(): void;
    title: string;
    children: React.ReactNode;
    submitButton: React.ReactNode;
    cancelButton: React.ReactNode;
    shouldCloseOnOverlayClick?: boolean;
};

const style: ReactModal.Styles = {
    overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        minWidth: '500px',
    },
};

export const GeneralModal: React.FC<Props & ReactModal.Props> = ({
    isOpen,
    onClose,
    title,
    children,
    submitButton,
    cancelButton,
    shouldCloseOnOverlayClick,
    ...rest
}: Props & ReactModal.Props) => {
    return (
        <ReactModal style={style} isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false} {...rest}>
            <div className={'px-2'}>
                <div className={'absolute right-0 cursor-pointer p-2'} onClick={onClose}>
                    <CloseIcon />
                </div>

                <div className={'p-3 text-xl'}>{title}</div>
                {children}
                <div className={'flex justify-end py-2'}>
                    <div className={'pr-2'}>{submitButton}</div>
                    <div className={'pr-2'}>{cancelButton}</div>
                </div>
            </div>
        </ReactModal>
    );
};

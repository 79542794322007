import { GroupLicenseType, GroupLicenseJSON } from '@schema-app/group/licenses/{groupId}/GroupLicenseJSON';
import { Timestamp } from '@framework/Timestamp';
export class GroupLicense {
    constructor(
        readonly type: GroupLicenseType,
        readonly count: number,
        readonly expiresAt: Timestamp
    ) {}

    dump(): GroupLicenseJSON {
        return {
            type: this.type,
            count: this.count,
            expiresAt: this.expiresAt.toUnixTimestamp(),
        };
    }

    static load(dump: GroupLicenseJSON): GroupLicense {
        const { type, count, expiresAt } = dump;
        return new this(type, count, Timestamp.fromUnixTimestamp(expiresAt));
    }
}

export type GridIndex = number;
export type GridDistance = number;

export class GridRange {
    public readonly low: GridIndex;
    public readonly high: GridIndex;

    constructor(low: GridIndex, high: GridIndex) {
        this.low = low;
        this.high = high;
    }

    public get size(): GridDistance {
        return this.high - this.low;
    }
}

import { Rect } from '@view-model/models/common/basic';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type MenuIconProps = {
    icon: IconDefinition;
    menuSize: number;
    isSelected?: boolean;
};

const iconRectFromMenuSize = (menuSize: number): Rect => {
    const x = menuSize / 8;
    const y = menuSize / 6;
    const iconSize = menuSize - 12;

    return new Rect({ x, y }, { width: iconSize, height: iconSize });
};

export const MainMenuIcon: React.FC<MenuIconProps> = ({ icon, menuSize }: MenuIconProps) => {
    const iconRect = iconRectFromMenuSize(menuSize);

    return (
        <foreignObject
            style={{ textAlign: 'center', lineHeight: `${menuSize * 0.6}px` }}
            pointerEvents="none"
            fontSize={menuSize * 0.6}
            width={iconRect.width}
            height={iconRect.height}
            transform={iconRect.topLeft().toSVGTranslate()}
        >
            <FontAwesomeIcon icon={icon} />
        </foreignObject>
    );
};

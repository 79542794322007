import { useCallback, useEffect, useState } from 'react';
import { ViewModelEntity, ViewModelOperation } from '@view-model/domain/view-model';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { useActionLogSender } from '@framework/action-log';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';

type Props = {
    viewModel: ViewModelEntity;
    isOpen: boolean;
    onClose(): void;
};

export const ViewModelDeleteConfirmModal: React.FC<Props> = ({ viewModel, isOpen, onClose }: Props) => {
    const previousIsOpen = usePrevious(isOpen);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const actionLogSender = useActionLogSender();

    useEffect(() => {
        if (isOpen && !previousIsOpen) {
            // モーダルを開いた時に、各stateを初期状態に戻す
            setConfirmed(false);
            setIsSubmitting(false);
        }
    }, [isOpen, previousIsOpen]);

    const handleSubmit = useCallback(() => {
        if (!confirmed) return;

        setIsSubmitting(true);

        ViewModelOperation.delete(viewModel.id, actionLogSender)
            .then(() => {
                onClose();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, [actionLogSender, confirmed, onClose, viewModel.id]);

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            title={'削除してもよろしいですか？'}
            submitButton={
                <Button color="danger" onClick={handleSubmit} disabled={!confirmed} loading={isSubmitting}>
                    削除
                </Button>
            }
            cancelButton={
                <Button color="secondary" onClick={onClose} disabled={isSubmitting}>
                    キャンセル
                </Button>
            }
        >
            <div>
                <p>「{`${viewModel.name}`}」を削除しますか？</p>
                <p className="py-4">
                    ビューモデル内に含まれるビューもすべて削除されます。本当によろしいですか？
                    <br />
                    削除すると元に戻すことはできません。本当に削除してもよろしいですか？
                </p>
                <label className="block">
                    <input
                        type="checkbox"
                        className="form-checkbox mr-1 size-6 border-brand"
                        checked={confirmed}
                        onChange={(e) => setConfirmed(e.target.checked)}
                    />
                    削除するにはチェックしてください
                </label>
            </div>
        </GeneralModal>
    );
};

import { useContext } from 'react';
import { SidebarTreeContext, SidebarTreeContextValues } from './SidebarTreeContext';
import { SidebarTreeRecords } from '../SidebarTreeStore';

type Values = Omit<SidebarTreeContextValues, 'all' | 'searchResult'> & {
    records: SidebarTreeRecords;
    searchKeyword: string;
};

const defaultValues: Values = {
    firstLoaded: false,
    records: {
        workspaces: {},
        folderTrees: {},
        folders: {},
        viewModels: {},
    },
    searchKeyword: '',
    open: {
        workspaceIds: {},
        folderIds: {},
    },
    control: {
        lastOpenedWorkspaceId: '',
        lastOpenedViewModelId: '',
        scrolledWorkspaceId: '',
        scrolledViewModelId: '',
    },
    dispatch: () => void 0,
};

export const useSidebarTreeContext = (): Values => {
    const sidebarContextValues = useContext(SidebarTreeContext);

    if (!sidebarContextValues) return defaultValues;

    const { all, searchResult, ...rest } = sidebarContextValues;
    // 検索結果に関するデータを優先して返す
    return {
        records: searchResult || all,
        ...rest,
    };
};

import { FixedColumnTileLayout, SubMenuListContainer } from '@model-framework/menu';
import { ColorMainMenu } from './ColorMainMenu';
import { ColorSubMenu } from './ColorSubMenu';
import { MenuSize } from '@view-model/models/sticky/ui';

export type ColorMenuItem = {
    colorName: string; // 色名
    fillColor: string; // 色メニューボックスの塗りつぶし色CSS文字列（例: #cccccc）
};

type Props = {
    currentColorName?: string; // 複数選択時など現在の値が一意に決まらないケースがある
    menuSize: number;
    items: Readonly<ColorMenuItem[]>;
    isOpen: boolean;
    numColumns?: number; // 色パレットの1行に表示させるメニュー数。デフォルトは8。
    onMenuClicked(): void;
    onSelected(colorName: string): void;
    text: string;
};

export const ColorMenu: React.FC<Props> = ({
    currentColorName,
    menuSize,
    items,
    isOpen,
    numColumns = 8,
    onMenuClicked,
    onSelected,
    text,
}: Props) => {
    const subMenuLayout = new FixedColumnTileLayout(menuSize, numColumns);

    const columnCount = Math.ceil(items.length / numColumns);
    const rowCount = Math.min(items.length, numColumns);
    const rectSize = {
        width: MenuSize * rowCount,
        height: MenuSize * columnCount,
    };

    return (
        <g>
            <ColorMainMenu isSelected={isOpen} menuSize={menuSize} onClick={onMenuClicked} text={text} />
            {isOpen && (
                <SubMenuListContainer menuSize={menuSize} rectSize={rectSize}>
                    {items.map((menuItem, i) => {
                        return (
                            <g key={menuItem.colorName} transform={subMenuLayout.topLeftOf(i).toSVGTranslate()}>
                                <ColorSubMenu
                                    fillColor={menuItem.fillColor}
                                    menuSize={menuSize}
                                    isSelected={currentColorName === menuItem.colorName}
                                    onClick={() => onSelected(menuItem.colorName)}
                                />
                            </g>
                        );
                    })}
                </SubMenuListContainer>
            )}
        </g>
    );
};

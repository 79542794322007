import { useCallback, useMemo, useState } from 'react';

type Handler = () => void;

type Handlers = {
    modalOpen: boolean;
    handleModalOpen: Handler;
    handleModalClose: Handler;
};

export const useModalHandlers = (): Handlers => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleModalOpen = useCallback(() => setModalOpen(true), []);
    const handleModalClose = useCallback(() => setModalOpen(false), []);

    return useMemo(() => {
        return {
            modalOpen,
            handleModalOpen,
            handleModalClose,
        };
    }, [handleModalClose, handleModalOpen, modalOpen]);
};

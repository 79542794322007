import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from '@framework/ui/atoms/Icon';
import { SignUpForm } from '@user/Login';

export const SignUpPage: React.FC<unknown> = () => {
    const location = useLocation();

    const signUpToken = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return params.get('signUpToken') || '';
    }, [location]);

    return (
        <div className="flex w-screen flex-col items-center justify-center pt-12">
            <div className="pb-12">
                <Icon name="balus" color="brand" size="brand-xl" />
            </div>

            <div className="flex flex-col rounded-lg border border-brand p-8" style={{ width: '600px' }}>
                {signUpToken ? (
                    <SignUpForm signUpToken={signUpToken} />
                ) : (
                    <>
                        <div className="text-2xl font-bold">ユーザ登録を行うことができません</div>
                        <div className="pt-4">
                            このURLからはユーザ登録を行うことができません。招待メールのURLからもう一度試してみてください。
                            <br />
                            <br />
                            詳しくは、 所属組織の Balus 導入担当者、または、
                            <a
                                href="https://levii.co.jp/contact/"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-700 underline"
                            >
                                サポート窓口
                            </a>
                            までお問い合わせください。
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

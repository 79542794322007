import { useMemo } from 'react';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { PopupTextView } from '@view-model/models/framework/ui';
import { useUserPublicProfile } from '@user/PublicProfile';
import { generateDefaultUserIcon } from '@framework/utils';

type Props = {
    userId: string;
    position: Point;
};

const ICON_SIZE = 48;

export const CreatedUserView: React.FC<Props> = ({ userId, position }: Props) => {
    const iconPoint = useMemo(() => position.addXY(-ICON_SIZE / 2, -ICON_SIZE / 2), [position]);
    const parentRect = useMemo(() => new Rect(position, new Size(ICON_SIZE, ICON_SIZE)), [position]);

    const profile = useUserPublicProfile(userId);

    return profile ? (
        <>
            <PopupTextView text={profile.name} parentRect={parentRect} color={'gray'} placement="bottom">
                <foreignObject width={ICON_SIZE} height={ICON_SIZE} transform={iconPoint.toSVGTranslate()}>
                    {profile.imageUrl ? (
                        <img
                            className="pointer-events-none rounded-full object-cover"
                            width={ICON_SIZE}
                            height={ICON_SIZE}
                            src={profile.imageUrl}
                            alt={profile.name}
                        />
                    ) : (
                        <div
                            className="pointer-events-none overflow-hidden rounded-full object-cover"
                            style={{ height: ICON_SIZE, width: ICON_SIZE }}
                        >
                            {generateDefaultUserIcon({
                                id: userId,
                                name: profile.name,
                                size: ICON_SIZE,
                            })}
                        </div>
                    )}
                </foreignObject>
            </PopupTextView>
        </>
    ) : null;
};

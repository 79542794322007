import { useCallback, useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';

type Props = {
    isOpen: boolean;
    onClose(): void;
    onDelete(): void;
};

export const ViewsDeleteConfirmModal: React.FC<Props> = ({ isOpen, onClose, onDelete }: Props) => {
    const [checked, setChecked] = useState<boolean>(false);

    const handleDelete = useCallback(() => {
        onDelete();
        setChecked(false);
        onClose();
    }, [onDelete, onClose]);

    const handleClose = useCallback(() => {
        setChecked(false);
        onClose();
    }, [onClose]);

    return (
        <GeneralModal
            isOpen={isOpen}
            submitButton={
                <Button color="danger" onClick={handleDelete} disabled={!checked}>
                    削除 | Delete
                </Button>
            }
            cancelButton={
                <Button color="secondary" onClick={handleClose}>
                    閉じる | Cancel
                </Button>
            }
            onClose={handleClose}
            shouldCloseOnOverlayClick={false}
            title={'ビューを削除しますか？ | Delete the view ?'}
        >
            <div className="p-4" style={{ width: '600px' }}>
                <p className={'pb-4'}>選択されたビューを削除します | Delete views</p>
                <label className="block">
                    <input
                        type="checkbox"
                        className="form-checkbox mr-1 size-6 border-brand"
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                    チェックしてください | Check to delete
                </label>
            </div>
        </GeneralModal>
    );
};

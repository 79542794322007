import { MenuLayout } from '@model-framework/menu/MenuLayout';
import { Size } from '@view-model/models/common/types/ui';

type Props = {
    menuSize: number;
    children: React.ReactNode;
    rectSize: Size;
};

export const SubMenuListContainer: React.FC<Props> = ({ menuSize, children, rectSize }: Props) => {
    return (
        <g transform={MenuLayout.subMenuListTopLeft(menuSize).toSVGTranslate()}>
            <rect
                x={0}
                y={0}
                width={rectSize.width}
                height={rectSize.height}
                rx={2}
                ry={2}
                fill="white"
                stroke="#e0e0e0"
                strokeWidth={4}
            />
            {children}
        </g>
    );
};

import { RefObject, useCallback, useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';
import { FontSize, DisplayFontSize } from './index';
import { NodeFontSize } from '@view-model/models/sticky/StickyNodeView';

type EditType = 'input' | 'delete' | 'focus' | 'none';

type Result = {
    textareaRef: RefObject<HTMLTextAreaElement>;
    hiddenTextareaRef: RefObject<HTMLTextAreaElement>;
    textareaHeight: string;
    displayFontSize: DisplayFontSize;
    hiddenFontSize: DisplayFontSize;
    handleTextChanged: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleFocus: () => void;
};

/**
 * 【概要】
 * 「入力したテキストの長さに応じたフォントサイズに自動的に変更するテキストエリア」を実現するためのカスタムフックです
 * 当カスタムフックは、使用する箇所に以下が配置されていることを想定します
 *
 * 1. 入力したテキストを表示するためのtextarea
 * 2. フォントサイズ調節を行うための非表示（visibility: hidden）なtextarea（1と同じ表示サイズである必要があります）
 *
 * 【引数】
 * 現在の表示状態に関する値と、フォントサイズ変更を親コンポーネントに通知するためのメソッドを受け取ります
 * @param text 現在テキストエリアに入力されているテキスト
 * @param fontSize 現在のテキストエリアの表示フォントサイズ
 * @param height 現在のテキストエリアの表示高さ
 * @param isMyEditing 自分自身により編集中であるか否かを表すbool値
 * @param onChangeFontSize フォントサイズ変更を親コンポーネントに通知するためのメソッド
 *
 * 【返り値】
 * 当カスタムフックは返り値として、フォントサイズ調整の結果を表示に反映させるための変数群を返します。それぞれ適切に設定・使用してください。
 * textareaRef: 上記「1」のrefプロパティとして設定してください
 * hiddenTextareaRef: 上記「2」のrefプロパティとして設定してください
 * textareaHeight: 上記「1」「2」のheightプロパティとして設定してください
 * displayFontSize: 上記「1」の表示フォントサイズとして設定してください
 * hiddenFontSize: 上記「2」の表示フォントサイズとして設定してください
 * handleTextChanged: フォントサイズ変更を当カスタムフックが検知するためのメソッドです。上記「1」のonChange()時に実行されるようにしてください
 * handleFocus: textareaにフォーカスが当たったときの挙動を制御するためのメソッドです。上記「1」のonFocus()プロパティとして設定してください
 */
export const useFontSizeAdjustTextArea = (
    text: string,
    fontSize: FontSize,
    height: number,
    isMyEditing: boolean,
    onChangeFontSize: (fontSize: FontSize | NodeFontSize) => void
): Result => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const hiddenTextareaRef = useRef<HTMLTextAreaElement>(null);
    const prevScrollHeightRef = useRef<number>(0);

    const prevTextLength = usePrevious(text.length);
    const [textareaHeight, setTextareaHeight] = useState<string>('auto');
    const [editType, setEditType] = useState<EditType>('none');
    const [hiddenFontSize, setHiddenFontSize] = useState<FontSize>(FontSize.M);

    /**
     * テキストが１文字の場合に true を返す。
     * Unicodeのコードポイントによっては、見た目上1文字の場合でも `text.length` が1より大きな数値を返すことがある。
     * `Array.from(str)` を利用して、文字単位の配列に変換して長さをチェックする。
     */
    const isSingleChar = (text: string): boolean => {
        return text.length === 1 || Array.from(text).length === 1;
    };

    const displayFontSize = useMemo(() => {
        if (isMyEditing) return fontSize;

        if (isSingleChar(text)) {
            return DisplayFontSize.XL;
        }

        return fontSize;
    }, [text, isMyEditing, fontSize]);

    const getEditType = useCallback(
        (currentTextLength: number): EditType => {
            if (prevTextLength === undefined) {
                return 'none';
            }
            if (currentTextLength > prevTextLength) {
                return 'input';
            }
            if (currentTextLength <= prevTextLength) {
                return 'delete';
            }
            return 'none';
        },
        [prevTextLength]
    );

    // 入力テキストが変更されたときのハンドラー関数
    const handleTextChanged = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const value = event.target.value;

            // テキストエリアの表示高さを再調整する
            setTextareaHeight('auto');

            // 前回変更したフォントサイズを元に戻す
            setHiddenFontSize(fontSize);

            // 編集タイプをセット
            setEditType(getEditType(value.length));
        },
        [fontSize, getEditType]
    );

    const getSmallerFontSize = useCallback(
        (scrollHeight: number): FontSize => {
            // onChangeFontSize() を呼び出して、実際にfontSizeが変更されるまでの間にはタイムラグがある。
            // 何も工夫していない場合、そのタイムラグの間に複数回連続して onChangeFontSize() が呼び出されて、
            // 意図した以上に小さなフォントサイズに変化してしまう。
            // そのような問題が発生しないように、前回実行時の scrollHeight を useRef で保持しておき、
            // 変化があった場合にだけ、コールバック関数を実行する。
            const prevScrollHeight = prevScrollHeightRef.current;

            // テキストエリアのコンテンツ高さが変化していないので、フォントサイズの調整判定は不要
            if (prevScrollHeight === scrollHeight) {
                return fontSize;
            }
            prevScrollHeightRef.current = scrollHeight;

            // scrollHeight が表示領域の高さ(height) を超えていて、かつ、次の(小さな)フォントサイズが存在すれば、フォントサイズを変更する
            return FontSize.downsizeFont(fontSize);
        },
        [fontSize]
    );

    const adjustSmallerFontSize = useCallback(() => {
        if (!textareaRef.current || !hiddenTextareaRef.current) return;

        // 高さが領域内におさまっていなるなら何もしない
        if (textareaRef.current.scrollHeight <= height) {
            return;
        }

        // フォントサイズが変わっていないなら何もしない
        const scrollHeight = textareaRef.current.scrollHeight;
        const nextFontSize = getSmallerFontSize(scrollHeight);
        if (nextFontSize === fontSize) {
            return;
        }
        onChangeFontSize(nextFontSize);
        return;
    }, [fontSize, getSmallerFontSize, height, onChangeFontSize]);

    const adjustLargerFontSize = useCallback(() => {
        if (!textareaRef.current || !hiddenTextareaRef.current) return;

        const nextFontSize = FontSize.upsizeFont(fontSize);

        // 非表示テキストエリアのフォントサイズを更新。
        // レンダリングが更新された後の値を取る必要があるため、以降の処理は hiddenFontSize の更新をトリガーとしたuseEffect()内で行う
        setHiddenFontSize(nextFontSize);
    }, [fontSize]);

    // フォントサイズを(必要があれば)変更する
    const adjustFontSize = useCallback(() => {
        if (!textareaRef.current || !hiddenTextareaRef.current) return;

        /**
         * 入力(input)時
         * テキストが表示領域をはみ出たらフォントサイズを小さくする
         */
        if (editType === 'input') {
            adjustSmallerFontSize();
            return;
        }

        /**
         * 削除(delete)時
         * いったん非表示textareaのフォントサイズを1つ大きくしてレンダリングを更新する
         * 再レンダリング後に非表示textareaの高さを取得し、高さが表示領域内に収まっていれば、フォントサイズを大きくする
         */
        if (editType === 'delete') {
            adjustLargerFontSize();
            return;
        }

        /**
         * ノードがペーストされたとき、フォントサイズが適切でないことがある
         * これを簡単に修正するため、ノードのテキストエリアを入力状態にした時(focusした時)、フォントサイズを調節する
         */
        if (editType === 'focus') {
            adjustSmallerFontSize();
        }
    }, [editType, adjustSmallerFontSize, adjustLargerFontSize]);

    // フォントサイズが変化したときに、 textarea の高さを auto に変更する
    useEffect(() => {
        setTextareaHeight('auto');
    }, [fontSize]);

    // textarea の高さが auto の場合に、コンテンツの高さに合わせて再調整する
    useLayoutEffect(() => {
        if (!textareaRef.current) return;

        if (textareaHeight === 'auto') {
            const scrollHeight = textareaRef.current.scrollHeight;
            setTextareaHeight(`${scrollHeight > height ? height : scrollHeight}px`);

            // フォントサイズの調整を試みる
            adjustFontSize();
        }
    }, [textareaHeight, height, adjustFontSize]);

    // テキストを削除したとき、フォントサイズを下げても表示領域内におさまるようなら、フォントサイズを下げる＆テキストエリアの高さを調整する
    // テキスト削除（setHiddenFontSize()）された次のレンダリング時ににのみ具体的な処理を行い、それ以外のときは何もしない
    // ※ フォントサイズメニュー実行時にも当useEffect()が実行されるため、誤作動防止のため必要なタイミングでeditTypeをリセットしている
    useEffect(() => {
        if (!hiddenTextareaRef.current) {
            // ここに来るときは入力もまだの状態なので、editType のリセットはしない
            return;
        }

        // フォントサイズ変更以外の契機で呼び出されたときは何もしない
        if (fontSize === hiddenFontSize) {
            // 当useEffect()はeditTypeが更新されたとき(hiddenFontSizeの更新の反映の直前)にも一度実行される
            // したがってここでeditTypeをリセットすると、hiddenFontSize反映時の処理が途中で終わってしまうので、あえてeditTypeのリセットはしない
            return;
        }

        // テキスト削除以外の契機で呼び出されたときは何もしない
        if (editType !== 'delete') {
            // ここに来るの editType === "none" のときのみなので、あえてeditTypeのリセットはしない
            return;
        }

        // 高さが領域内におさまっていないなら何もしない
        const scrollHeight = hiddenTextareaRef.current.scrollHeight;
        if (scrollHeight > height) {
            setEditType('none');
            return;
        }
        prevScrollHeightRef.current = scrollHeight;
        onChangeFontSize(hiddenFontSize);
        setEditType('none');
    }, [fontSize, height, hiddenFontSize, editType, onChangeFontSize]);

    // textarea にフォーカスがあたったとき、全選択したうえで、表示高さを調整する
    const handleFocus = useCallback(() => {
        textareaRef.current?.select();
        setTextareaHeight('auto');
        setEditType('focus');
    }, []);

    return {
        textareaRef: textareaRef,
        hiddenTextareaRef: hiddenTextareaRef,
        textareaHeight: textareaHeight,
        displayFontSize: displayFontSize,
        hiddenFontSize: hiddenFontSize,
        handleTextChanged: handleTextChanged,
        handleFocus: handleFocus,
    };
};

export enum RectEdge {
    Top = 'Top',
    Left = 'Left',
    Bottom = 'Bottom',
    Right = 'Right',
}

export namespace RectEdge {
    /**
     * 矩形の辺が水平線（Top, Bottom）の場合はtrueを返す。
     * @param rectEdge
     */
    export function isHorizontal(rectEdge: RectEdge): boolean {
        return rectEdge === RectEdge.Top || rectEdge === RectEdge.Bottom;
    }

    /**
     * 矩形の辺が平行かどうかを返す。
     * Top, Bottom の組み合わせ、または Left, Right の組み合わせのとき true。
     * @param a
     * @param b
     */
    export function isParallel(a: RectEdge, b: RectEdge): boolean {
        return RectEdge.isHorizontal(a) === RectEdge.isHorizontal(b);
    }
}

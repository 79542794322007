import { useCallback, useMemo } from 'react';
import { difference } from 'lodash';
import { UserPublicProfile, useUserPublicProfiles } from '@user/PublicProfile';
import { Loading } from '@framework/ui/atoms/Loading';
import { classNames, generateDefaultUserIcon } from '@framework/utils';

type Props = {
    workspaceUserIds: string[];
    groupUserIds: string[];
    selectedUserIds: string[];
    disabled: boolean;
    onChange(userIds: string[]): void;
};

export const MemberAddList: React.FC<Props> = ({
    workspaceUserIds,
    groupUserIds,
    selectedUserIds,
    disabled,
    onChange,
}: Props) => {
    const availableUserIds = useMemo(() => {
        return difference(groupUserIds, workspaceUserIds);
    }, [groupUserIds, workspaceUserIds]);
    const profiles = useUserPublicProfiles(availableUserIds);

    const handleChangeUser = useCallback(
        (userId: string, checked: boolean) => {
            const newUserIds = checked ? [...selectedUserIds, userId] : selectedUserIds.filter((id) => id !== userId);
            onChange(newUserIds);
        },
        [onChange, selectedUserIds]
    );

    if (!profiles) return <Loading />;

    if (profiles.length === 0) {
        return <div className="flex justify-center px-2 py-4">追加可能なメンバーが見つかりません。</div>;
    }

    return (
        <ul className="px-2">
            {profiles.map((profile) => (
                <MemberItem
                    key={profile.id}
                    profile={profile}
                    checked={selectedUserIds.includes(profile.id)}
                    disabled={disabled}
                    onChange={handleChangeUser}
                />
            ))}
        </ul>
    );
};

type ItemProps = {
    profile: UserPublicProfile;
    checked: boolean;
    disabled: boolean;
    onChange(userId: string, checked: boolean): void;
};

const MemberItem: React.FC<ItemProps> = ({ profile, checked, disabled, onChange }: ItemProps) => {
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(profile.id, event.target.checked);
        },
        [onChange, profile.id]
    );

    return (
        <li className="flex flex-row items-center px-3 py-2 hover:bg-gray-200">
            <label className="flex grow cursor-pointer flex-row">
                <input
                    type="checkbox"
                    className={classNames(
                        'form-checkbox mr-1 h-6 w-6 border-brand',
                        disabled ? 'cursor-not-allowed bg-gray-300' : 'cursor-pointer'
                    )}
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <span className="flex size-6 overflow-hidden rounded-full">
                    {profile.imageUrl ? (
                        <img className="size-full object-cover" src={profile.imageUrl} alt={profile.name} />
                    ) : (
                        <div className="size-full object-cover">
                            {generateDefaultUserIcon({
                                id: profile.id,
                                name: profile.name,
                                size: 24,
                            })}
                        </div>
                    )}
                </span>
                <span className="flex h-6 grow truncate px-2 leading-6">{profile.name}</span>
            </label>
        </li>
    );
};

import { useCallback } from 'react';
import { GroupOperation } from '@group/domain/GroupEntity/GroupOperation';
import { toast } from 'react-hot-toast';
import { GroupId, UserId } from '@schema-common/base';
import { GroupMemberRole } from '@group/domain';
import { BasicRoleSelect } from '../common';
type Props = {
    groupId: GroupId;
    userId: UserId;
    defaultRole: GroupMemberRole;
    disabled: boolean;
};

export const RoleSelect: React.FC<Props> = ({ groupId, userId, defaultRole, disabled }: Props) => {
    const handleChange = useCallback(
        (role: GroupMemberRole) => {
            GroupOperation.updateMemberRole(groupId, userId, role).then((result) => {
                if (result) {
                    toast.success('ロールを更新しました');
                } else {
                    toast.error('ロールの更新に失敗しました');
                }
            });
        },
        [groupId, userId]
    );

    return <BasicRoleSelect defaultRole={defaultRole} onChange={handleChange} disabled={disabled} />;
};

import { Rect } from '@view-model/models/common/basic/Rect';

type RectSelectorProps = {
    rect: Rect;
};

export const RectSelectorView: React.FC<RectSelectorProps> = ({ rect }: RectSelectorProps) => {
    return (
        <rect
            className="fill-brand stroke-brand"
            fillOpacity={0.5}
            strokeWidth={8}
            transform={`translate(${rect.x}, ${rect.y})`}
            width={rect.width}
            height={rect.height}
        />
    );
};

import { ICalloutRectEdge } from './ICalloutRectEdge';
import { CalloutTailBaseLine } from './CalloutTailBaseLine';
import { Point, Rect } from '@view-model/models/common/basic';

export class CalloutRectHorizontalEdge implements ICalloutRectEdge {
    constructor(
        private readonly leftX: number,
        private readonly rightX: number,
        private readonly y: number
    ) {}

    private get centerX(): number {
        const { leftX, rightX } = this;
        return (leftX + rightX) * 0.5;
    }

    // しっぽ三角形の底辺になる線分
    private tailBaseLine(margin: number, length: number): CalloutTailBaseLine {
        const { leftX, y } = this;

        const p1 = new Point(leftX + margin, y);
        const p2 = p1.addX(length);

        return new CalloutTailBaseLine(p1, p2);
    }

    private calcBaseLine(targetCenterX: number, margin: number, length: number): CalloutTailBaseLine {
        const { centerX } = this;

        const baseLine = this.tailBaseLine(margin, length);

        // 対象との位置関係に応じて位置を反転させる
        return centerX < targetCenterX ? baseLine.symmetricOnX(centerX) : baseLine;
    }

    tailBaseLineFor(targetRect: Rect, margin: number, length: number): CalloutTailBaseLine {
        return this.calcBaseLine(targetRect.getCenterPoint().x, margin, length);
    }
}

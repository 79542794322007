import { PositionSet, PositionSetJSON } from '@view-model/models/common/PositionSet';
import { StickyZone } from '@view-model/models/sticky/StickyZoneView';
import { StickyZoneCollection } from './StickyZoneCollection';
import { StickyZoneKey } from '@view-model/domain/key';
import { UserKey } from '@user/domain';
import { assertIsDefined, Point } from '@view-model/models/common/basic';
import { StickyZoneJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/sticky-zones/{stickyZoneKey}/StickyZoneJSON';
import { StickyZoneId } from '@schema-common/base';

type StickyZoneContentsJSON = {
    zones: StickyZoneJSON[];
    positions: PositionSetJSON;
};

type LoadableContentsJSON = Partial<StickyZoneContentsJSON>;

export class StickyZoneContents {
    constructor(
        public readonly zones: StickyZoneCollection,
        public readonly positions: PositionSet
    ) {
        // 位置情報が存在するゾーンのみを扱う
        this.zones = zones.filter((zones) => {
            return !!positions.find(zones.id);
        });
    }

    static buildEmpty(): StickyZoneContents {
        return new StickyZoneContents(StickyZoneCollection.buildEmpty(), PositionSet.buildEmpty());
    }

    static load(dump: LoadableContentsJSON): StickyZoneContents {
        const { zones, positions } = dump;
        return new StickyZoneContents(StickyZoneCollection.load(zones || []), PositionSet.load(positions || {}));
    }

    cloneNew(): [StickyZoneContents, Record<string, StickyZoneKey>, Record<string, string>] {
        const [zones, newKeyMap, newIdMap] = this.zones.cloneNew();
        return [new StickyZoneContents(zones, this.positions.cloneNew(newIdMap)), newKeyMap, newIdMap];
    }

    withCreatedUserKey(userKey: UserKey): StickyZoneContents {
        const zones = this.zones.map((zone) => zone.withCreatedUserKey(userKey));
        return new StickyZoneContents(new StickyZoneCollection(zones), this.positions);
    }

    dump(): StickyZoneContentsJSON {
        const { zones, positions } = this;
        return {
            zones: zones.dump(),
            positions: positions.dump(),
        };
    }

    isEqual(other: StickyZoneContents): boolean {
        return this.zones.isEqual(other.zones) && this.positions.isEqual(other.positions);
    }

    count(): number {
        return this.zones.length;
    }

    allIds(): StickyZoneId[] {
        return this.zones.ids();
    }

    move(dx: number, dy: number): StickyZoneContents {
        return new StickyZoneContents(this.zones, this.positions.moveAll(dx, dy));
    }

    entries(): [StickyZone, Point][] {
        return this.zones.map((zone) => {
            const position = this.positions.find(zone.id);
            assertIsDefined(position);
            return [zone, position as Point];
        });
    }

    map<T>(fn: (value: [StickyZone, Point], index: number) => T): T[] {
        return this.entries().map(fn);
    }
}

import { useMemo } from 'react';
import { ConsistencyLinkCollection } from '@view-model/domain/ConsistencyLink';
import { ConsistencyLinkView } from './ConsistencyLinkView';
import { ViewCollectionContents } from '@view-model/domain/view/ViewCollectionContents';
import { ConsistencyLinkId } from '@schema-common/base';

type Props = {
    viewContents: ViewCollectionContents;
    selectedId: ConsistencyLinkId | null;
    onSelect(id: string | null): void;
    consistencyLinks: ConsistencyLinkCollection | null;
};

export const ConsistencyLinkCollectionView: React.FC<Props> = ({
    viewContents,
    selectedId,
    onSelect,
    consistencyLinks,
}: Props) => {
    // 整合性リンクの両端のViewが存在するものに絞り込む
    const validLinks = useMemo(
        () => consistencyLinks?.validLinksBy(viewContents.getViews()) ?? false,
        [consistencyLinks, viewContents]
    );

    // 描画するべきリンクが無い場合には早期return
    if (!validLinks) return null;

    return (
        <g>
            {validLinks.map((consistencyLink) => (
                <ConsistencyLinkView
                    key={consistencyLink.id}
                    consistencyLink={consistencyLink}
                    isSelected={consistencyLink.id === selectedId}
                    onSelectConsistencyLink={onSelect}
                    viewContents={viewContents}
                />
            ))}
        </g>
    );
};

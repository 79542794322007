import { DataSnapshot, DBPath, RefBuilder, Reference } from '@framework/repository';
import { useMemo, useRef, useState, useEffect } from 'react';

type UseValue<O> = [
    values: O | null,
    {
        ref: Reference;
        loading: boolean;
    },
];

type State<T> = {
    values: null | T;
    loading: boolean;
};

type Options<O> = {
    path: DBPath;
    load: (params: { snapshot: DataSnapshot; getChildValues: () => Record<string, unknown> }) => O;
    stateless?: boolean;
};

/**
 * 指定パス配下の複数のレコードを取得する
 */
export const useSnapshot = <O>(options: Options<O>): UseValue<O> => {
    const { path } = options;

    const optionsRef = useRef<Options<O>>(options);
    optionsRef.current = options;

    const ref = useMemo(() => RefBuilder.ref(path), [path]);

    const [state, setState] = useState<State<O>>({
        values: null,
        loading: true,
    });

    useEffect(() => {
        const onValue = (snapshot: DataSnapshot) => {
            const getChildValues = () => {
                const values = {} as Record<string, unknown>;
                snapshot.forEach((child) => {
                    values[child.key] = child.val();
                });
                return values;
            };

            if (optionsRef.current.stateless) {
                optionsRef.current.load({
                    snapshot,
                    getChildValues,
                });
            } else {
                setState((prev) => ({
                    ...prev,
                    values: optionsRef.current.load({
                        snapshot,
                        getChildValues,
                    }),
                    loading: false,
                }));
            }
        };

        ref.on('value', onValue);

        return () => {
            ref.off('value', onValue);
        };
    }, [ref]);

    return [
        state.values,
        {
            ref,
            loading: state.loading,
        },
    ];
};

import { Point } from '@view-model/models/common/basic';
import { StickyNodeJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/nodes/{nodeId}/StickyNodeJSON';

type NodePositionAttributes = {
    x: number;
    y: number;
};

export class NodePosition {
    public readonly x: number;
    public readonly y: number;

    public constructor(attributes: NodePositionAttributes) {
        this.x = attributes.x;
        this.y = attributes.y;
    }

    public static buildNew(): NodePosition {
        return new NodePosition({
            x: 0,
            y: 0,
        });
    }

    public static load(json: StickyNodeJSON['position']): NodePosition {
        return new NodePosition(json);
    }

    public dump(): StickyNodeJSON['position'] {
        return {
            x: this.x,
            y: this.y,
        };
    }

    public isEqual(other: NodePosition): boolean {
        return other instanceof NodePosition && this.x == other.x && this.y == other.y;
    }

    public add(dx: number, dy: number): NodePosition {
        return new NodePosition({ x: this.x + dx, y: this.y + dy });
    }

    public toString(): string {
        return `(${this.x}, ${this.y})`;
    }

    toPoint(): Point {
        return new Point(this.x, this.y);
    }

    subtract(other: NodePosition): NodePosition {
        const x = this.x - other.x;
        const y = this.y - other.y;

        return new NodePosition({ x, y });
    }
}

import { useEffect, useMemo } from 'react';
import { RTDBPath, usePrimitiveValueListener } from '@framework/repository';
import { Icon } from '@framework/ui/atoms/Icon';
import { isUndefined } from 'lodash';
import { getClientId } from '@framework/app';

type Props = {
    children: React.ReactNode;
};

export const WithMaintenancePage: React.FC<Props> = ({ children }: Props) => {
    const [maintenanceStartAt] = usePrimitiveValueListener<number>(RTDBPath.ApplicationConfig.maintenanceStartAtPath());
    const [sessionStartAt] = usePrimitiveValueListener<number>(
        RTDBPath.User.activeClientSessionStartAtPath(getClientId())
    );
    const maintenance = useMemo(() => new URLSearchParams(window.location.search).get('maintenance'), []);

    useEffect(() => {
        // メンテナンス開始時刻の前にセッションが開始されていたらリロードする
        if (sessionStartAt && maintenanceStartAt && sessionStartAt < maintenanceStartAt) {
            window.location.reload();
        }
    }, [maintenanceStartAt, sessionStartAt]);

    if (isUndefined(maintenanceStartAt)) return null;

    if ((maintenanceStartAt || maintenance === 'on') && maintenance !== 'off') {
        return <MaintenancePage />;
    }

    return <>{children}</>;
};

const MaintenancePage: React.FC = () => {
    return (
        <div className="absolute left-0 top-0 flex h-screen w-screen flex-col items-center justify-center">
            <div className="flex flex-col items-center pb-16">
                <Icon name="balus" color="brand" size="brand-xl" />
                <div className="mt-3 font-bold text-brand">Balus 2.0</div>

                <h2 className="my-5 text-2xl font-bold">メンテナンス中です。</h2>

                <p>ご迷惑をおかけして申し訳ありません。</p>
                <p>現在メンテナンス中のためサービスをご利用いただくことができません。</p>
                <p>サービス再開までの間、しばらくお待ちください。</p>
            </div>
        </div>
    );
};

import { Point, Rect, Size } from '@view-model/models/common/basic';
import { PopupTextView } from '@view-model/models/framework/ui';
import { NodeReaction, ReactionType, reactionIcons } from '../../domain';
import { isReactionSelected } from '../NodeReactionMenu';
import { UserId } from '@schema-common/base';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';

type ReactionIconProps = {
    index: number;
    icon: ReactionType;
    reaction: NodeReaction;
    position: Point;
    iconSize: number;
    onSelected(reactionType: ReactionType, userId: UserId, userName: string): void;
};

const ReactionIcon: React.FC<ReactionIconProps> = ({
    index,
    icon,
    reaction,
    position,
    iconSize,
    onSelected,
}: ReactionIconProps) => {
    const profile = useCurrentUserPublicProfile();
    const halfIconSize = iconSize / 2;
    const fontSize = iconSize * 0.6;
    const padding = 2;
    // 4つめのリアクションからは下にずらす
    const addY = index >= 3 ? iconSize * 1.1 : 0;
    const mulX = index >= 3 ? index - 3 : index;

    const handleOnClick = (reactionType: ReactionType) => () => {
        if (!profile) return;
        onSelected(reactionType, profile.id, profile.name);
    };
    const userNames = reaction.getUserNames(icon).join(', ');

    return (
        <PopupTextView
            text={userNames}
            parentRect={
                new Rect(
                    position.addXY(-(iconSize * 1.8 + padding) * mulX + iconSize * 0.4, addY),
                    new Size(iconSize * 1.8, iconSize)
                )
            }
            color={'gray'}
            placement="bottom"
            maxWidth={384}
        >
            <g
                className="hover:cursor-pointer"
                transform={position
                    .addXY(-halfIconSize - (iconSize * 1.8 + padding) * mulX, -halfIconSize + addY)
                    .toSVGTranslate()}
                onClick={handleOnClick(icon)}
            >
                <foreignObject
                    className={
                        isReactionSelected(icon, reaction, profile)
                            ? 'rounded-lg border-3 border-brand bg-brand-quarter-transparent p-1'
                            : 'rounded-lg border-3 border-brand bg-white p-1 hover:bg-gray-400'
                    }
                    width={iconSize * 1.8}
                    height={iconSize}
                />
                <text
                    textAnchor="middle"
                    dominantBaseline="central"
                    pointerEvents="none"
                    fontWeight="bold"
                    x={iconSize * 0.5}
                    y={iconSize * 0.5}
                    fontSize={fontSize}
                >
                    {reactionIcons[icon]}
                </text>
                <text
                    className={isReactionSelected(icon, reaction, profile) ? 'fill-brand' : 'fill-gray-800'}
                    textAnchor="middle"
                    dominantBaseline="central"
                    pointerEvents="none"
                    fontWeight="bold"
                    x={iconSize * 1.25}
                    y={iconSize * 0.5}
                    fontSize={fontSize}
                >
                    {reaction.countOf(icon)}
                </text>
            </g>
        </PopupTextView>
    );
};

type Props = {
    nodeRect: Rect;
    iconSize: number;
    reaction: NodeReaction | null | undefined;
    toggleReaction: (reactionName: ReactionType, userId: UserId, userName: string) => void;
};

export const NodeReactionStatusView: React.FC<Props> = ({ nodeRect, reaction, toggleReaction, iconSize }: Props) => {
    const position = nodeRect.bottomRight();
    /*
     * アイコンが4つ以上になりアイコンが上下2段になったとき、上段のアイコンのユーザー
     * 一覧の吹き出しを下段のアイコンの手前に表示させるために先に下段から描画する
     * 描画する順番を逆にしているだけなので表示順自体は変わらない
     */
    return (
        <>
            {reaction && (
                <>
                    {reaction.getExistKeys().map((_, i, icons) => (
                        <ReactionIcon
                            key={icons[i]}
                            icon={icons[i]}
                            index={icons.length - 1 - i}
                            reaction={reaction}
                            position={position}
                            iconSize={iconSize}
                            onSelected={toggleReaction}
                        />
                    ))}
                </>
            )}
        </>
    );
};

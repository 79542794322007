import { RefObject, useEffect } from 'react';

export const useTextSelectable = <T extends HTMLElement>(ref: RefObject<T>, selectable = true): void => {
    useEffect(() => {
        if (!ref.current) return;
        // テキスト選択できるようにイベント伝搬を止める
        const stopPropagation = (e: Event) => selectable && e.stopPropagation();

        const dom = ref.current;
        dom.addEventListener('mousedown', stopPropagation);
        return () => dom.removeEventListener('mousedown', stopPropagation);
    }, [ref, selectable]);
};

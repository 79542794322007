import { useCallback, useMemo } from 'react';
import { FolderId, WorkspaceId } from '@schema-common/base';
import { useSidebarTreeContext } from './SidebarTreeContext';
import { ViewModelEntity } from '@view-model/domain/view-model';

type Return = {
    isOpen: boolean;
    toggleFolder: () => void;
    sortedViewModels: ViewModelEntity[];
    scrolledViewModelId: string;
    resetScrollViewModel: () => void;
};

export const useSidebarFolder = (workspaceId: WorkspaceId, folderId: FolderId): Return => {
    const {
        records: { viewModels, folderTrees },
        dispatch,
        open: { folderIds },
        control: { scrolledViewModelId },
    } = useSidebarTreeContext();

    const toggleFolder = useCallback(() => {
        dispatch({ type: 'TOGGLE_FOLDER_OPEN', payload: { folderId } });
    }, [folderId, dispatch]);

    const sortedViewModels = useMemo(() => {
        const folderTree = folderTrees[workspaceId];

        if (!folderTree) return [];

        const targetViewModelIds = folderTree.getViewModelIdsOf(folderId);

        return targetViewModelIds
            .map((viewModelId) => viewModels[viewModelId])
            .filter((viewModel) => !!viewModel)
            .sort(ViewModelEntity.compare);
    }, [folderTrees, viewModels, workspaceId, folderId]);

    const resetScrollViewModel = useCallback(() => {
        dispatch({ type: 'SET_SCROLLED_VIEW_MODEL', payload: { viewModelId: '' } });
    }, [dispatch]);

    return {
        isOpen: !!folderIds[folderId],
        toggleFolder,
        sortedViewModels,
        scrolledViewModelId,
        resetScrollViewModel,
    };
};

import { Folder } from '../Folder';
import { FolderId } from '@schema-common/base';
import { ViewModelCollection } from '../ViewModelCollection';
import { FolderContainerCollection } from './FolderContainerCollection';

export class FolderContainer {
    constructor(
        public readonly folder: Folder,
        private readonly originalChildFolderContainers: FolderContainerCollection,
        private readonly originalChildViewModels: ViewModelCollection,
        private readonly searchQuery: string = ''
    ) {}

    get folderId(): FolderId {
        return this.folder.id;
    }

    get folderName(): string {
        return this.folder.name;
    }

    get childFolderContainers(): FolderContainerCollection {
        return this.searchQuery
            ? this.originalChildFolderContainers.filter((x) => x.hasFilteredViewModels())
            : this.originalChildFolderContainers;
    }

    get childViewModels(): ViewModelCollection {
        return this.searchQuery
            ? this.originalChildViewModels.filterByQuery(this.searchQuery)
            : this.originalChildViewModels;
    }

    hasChildren(): boolean {
        return this.originalChildFolderContainers.count() > 0 || this.originalChildViewModels.count() > 0;
    }

    filtered(): boolean {
        return !!this.searchQuery;
    }

    private hasFilteredViewModels(): boolean {
        return this.childViewModels.count() > 0;
    }
}

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { classNames } from '@framework/utils';

type Props = {
    handleClick: () => void;
    icon: IconProp;
    title: string;
    className?: string;
    iconClassName?: string;
};

export const SidebarMenuButton: React.FC<Props> = ({
    handleClick,
    icon,
    title,
    className = '',
    iconClassName = '',
}: Props) => {
    return (
        <button
            className={classNames(
                'block w-full px-3 py-1 text-left text-gray-800 hover:bg-indigo-500 hover:text-white',
                className
            )}
            onClick={handleClick}
        >
            <span className={iconClassName}>
                <FontAwesomeIcon icon={icon} />
            </span>
            <span className="ml-2">{title}</span>
        </button>
    );
};

import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';

type Props = {
    onClose(): void;
};

export const DisconnectedNotificationModal: React.FC<Props> = ({ onClose }: Props) => {
    return (
        <GeneralModal
            isOpen={true}
            onClose={onClose}
            title={'インターネットに接続されていません'}
            submitButton={
                <Button color="secondary" onClick={onClose}>
                    閉じる
                </Button>
            }
            cancelButton={<></>}
            shouldCloseOnOverlayClick={false}
        >
            <div className="p-4" style={{ width: '600px' }}>
                コンピュータが現在オフラインのため、このまま編集を続けると他の人の変更が反映されない恐れがあります。
            </div>
        </GeneralModal>
    );
};

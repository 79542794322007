import { FolderContainer } from './FolderContainer';

export class FolderContainerCollection {
    constructor(private readonly folderContainers: FolderContainer[]) {}

    count(): number {
        return this.folderContainers.length;
    }

    all(): FolderContainer[] {
        return this.folderContainers;
    }

    static buildEmpty(): FolderContainerCollection {
        return new FolderContainerCollection([]);
    }

    filter(cb: (container: FolderContainer, index: number) => boolean): FolderContainerCollection {
        return new FolderContainerCollection(this.all().filter(cb));
    }
}

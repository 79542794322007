import { ICommand } from '@model-framework/command';
import { PositionSet } from './PositionSet';
import { IPositionSetRepository } from './IPositionSetRepository';

export class PositionSetUpdateCommand implements ICommand {
    constructor(
        private readonly fromValue: PositionSet,
        private readonly toValue: PositionSet,
        private readonly repository: IPositionSetRepository
    ) {}

    do(): void {
        this.repository.saveMany(this.toValue).then();
    }

    undo(): void {
        this.repository.saveMany(this.fromValue).then();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        const currentPositions = await this.repository.getMany(this.fromValue.allIds());
        return currentPositions.isEqual(this.toValue);
    }

    async canRedo(): Promise<boolean> {
        const currentPositions = await this.repository.getMany(this.fromValue.allIds());
        return currentPositions.isEqual(this.fromValue);
    }
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { ViewModelId } from '@schema-common/base';
import { useDetectGroupId } from './useDetectGroupId';
import { useCurrentUserId, useUserType } from '@framework/auth';
import { KeysRepository } from '@framework/repository/Collection';
import { RTDBPath } from '@framework/repository';
import { BookmarkedViewModelIdsContextValues } from '../contexts';

export const useGetBookmarkedViewModelIdsContextValues = (): BookmarkedViewModelIdsContextValues => {
    const groupId = useDetectGroupId();
    const currentUserId = useCurrentUserId();
    const [bookmarkedViewModelIds, setBookmarkedViewModelIds] = useState<Set<ViewModelId>>(new Set());

    const userType = useUserType();
    const repo = useMemo(() => {
        if (!currentUserId || !groupId) return null;
        if (userType !== 'regular') return null;
        return new KeysRepository<ViewModelId>(RTDBPath.Group.groupBookmarkViewModelsPath(groupId, currentUserId));
    }, [currentUserId, groupId, userType]);

    const addBookmark = useCallback(
        async (viewModelId: ViewModelId) => {
            if (!repo) return;
            await repo.addItem(viewModelId);
        },
        [repo]
    );

    const removeBookmark = useCallback(
        async (viewModelId: ViewModelId) => {
            if (!repo) return;
            await repo.removeItem(viewModelId);
        },
        [repo]
    );

    useEffect(() => {
        if (!repo) return;

        repo.get().then((bookmarkedViewModelIds) => {
            setBookmarkedViewModelIds(new Set(bookmarkedViewModelIds));
            repo.addListener(
                (addedId) => {
                    setBookmarkedViewModelIds((ids) => {
                        //同一Setを使い回すとレンダリングのトリガーがうまく反応しないので、新たなSetを返すようにしている
                        return ids.has(addedId) ? ids : new Set(ids).add(addedId);
                    });
                },
                (removedId) => {
                    setBookmarkedViewModelIds((ids) => {
                        if (!ids.has(removedId)) {
                            return ids;
                        }

                        //同一Setを使い回すとレンダリングのトリガーがうまく反応しないので、新たなSetを返すようにしている
                        const newIdsSet = new Set(ids);
                        newIdsSet.delete(removedId);
                        return newIdsSet;
                    });
                }
            );
        });

        return () => repo.removeListener();
    }, [repo]);

    return { bookmarkedViewModelIds, addBookmark, removeBookmark };
};

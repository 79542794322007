import { Button } from './Button';
import { faExpand, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

type Props = {
    onZoomIn: () => void;
    onZoomOut: () => void;
    onZoomReset: () => void;
};

export const ZoomButtons: React.FC<Props> = ({ onZoomIn, onZoomOut, onZoomReset }: Props) => {
    return (
        <div className="flex gap-2">
            <div className="flex gap-1">
                <Button icon={faMinus} tooltip="縮小 | Zoom out" className="rounded-l-lg" onClick={onZoomOut} />
                <Button icon={faPlus} tooltip="拡大 | Zoom in" className="rounded-r-lg" onClick={onZoomIn} />
            </div>
            <Button
                icon={faExpand}
                tooltip="ビューモデル全体を見る | Zoom to fit"
                className="rounded-lg"
                onClick={onZoomReset}
            />
        </div>
    );
};

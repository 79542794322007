import { Multiplicity } from '../../domain';
import { RectMenu } from './RectMenu';
import { Size } from '@view-model/models/common/basic';

type Props = {
    size: Size;
    fontSize: number;
    transform: string;
    multiplicity: Multiplicity;
    isSelected: boolean;
    onClick(): void;
};

export const MultiplicityMenu: React.FC<Props> = ({
    size,
    fontSize,
    transform,
    multiplicity,
    isSelected,
    onClick,
}: Props) => {
    return (
        <RectMenu size={size} onClick={onClick} transform={transform} isSelected={isSelected}>
            <text
                className={isSelected ? 'fill-white' : 'fill-gray-500'}
                textAnchor="middle"
                dominantBaseline="central"
                filter="none"
                fontWeight="bold"
                x={0}
                y={0}
                fontSize={fontSize}
            >
                {multiplicity.toString()}
            </text>
        </RectMenu>
    );
};

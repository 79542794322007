import { useContext, useEffect, useRef, createContext, MutableRefObject } from 'react';
import { useHistory } from 'react-router-dom';

export const LeavingPageContext = createContext<MutableRefObject<boolean> | null>(null);

export const useShowPopupBeforeLeavingPage = (): { popupAvailableRef: MutableRefObject<boolean> } => {
    const popupAvailableRef = useRef(false);

    const history = useHistory();

    // 「進む」、「戻る」に対応
    useEffect(() => {
        const unblock = history.block(() => {
            if (popupAvailableRef.current) {
                return 'このページを離れますか？';
            }
        });

        return () => {
            unblock();
        };
    }, [popupAvailableRef, history]);

    // ページのリロードや外部サイトへの遷移に対応
    useEffect(() => {
        const handleUnload = (ev: BeforeUnloadEvent) => {
            if (popupAvailableRef.current) {
                ev.preventDefault();
                ev.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [popupAvailableRef]);

    return { popupAvailableRef };
};

/**
 * ページ遷移をブロックするかどうかをセットするフック。
 *
 * このフックでtrue をセットした後、falseに戻さない限りページ遷移をブロックするので注意。
 * （コンポーネントのアンロードではブロック状態が消えない）
 *
 * @param condition ページ遷移をブロックする場合はtrueを指定。
 */
export const usePopupAvailable = (condition: boolean): void => {
    const popupAvailableRef = useContext(LeavingPageContext);

    useEffect(() => {
        if (!popupAvailableRef) return;

        popupAvailableRef.current = condition;
    }, [condition, popupAvailableRef]);
};

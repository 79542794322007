import { ActionLog } from '@framework/action-log';
import { RTDBPath, RefBuilder, ServerValue } from '@framework/repository';

export class ActionLogRepository {
    // 連続して同じ内容のイベントが登録されるのを防ぐため、直前に保存しようとしたイベント内容を記録しておく
    private previousEvent: ActionLog | null = null;

    async save(actionLog: ActionLog): Promise<void> {
        if (this.previousEvent?.isEqual(actionLog)) {
            console.warn('ActionLog is not saved because it is same as previous one.', actionLog);
            return;
        }

        await this.saveDefault(actionLog);
        this.previousEvent = actionLog;
    }

    private async saveDefault(actionLog: ActionLog): Promise<void> {
        const ref = RefBuilder.ref(RTDBPath.ActionLog.actionEventLogsPath()).push().ref;
        const eventId = ref.key as string;

        await ref.set({
            ...actionLog.dump(),
            eventId,
            eventTimestamp: ServerValue.TIMESTAMP,
        });
    }
}

import { Point, Size } from '@view-model/models/common/basic';
import { CommentConstants } from '../constants';

/*
 * コメントの位置（position）、コメントボタンのサイズ(menuButtonSize)、コメントメニューのサイズ(popupMenuSize)から、
 * コメントメニューボタン・コメントメニューポップアップの表示位置座標を取得するためのクラスです
 */
export class CommentMenuPositionMap {
    constructor(
        private readonly position: Point,
        private readonly menuButtonSize: Size,
        private readonly popupMenuSize: Size
    ) {}

    /**
     * コメントメニューボタンの表示位置左上座標を返します
     */
    getMenuButtonPosition(): Point {
        // 右寄せにするために、自身の幅を引いている
        const x = CommentConstants.UISetting.width - this.menuButtonSize.width;

        return Point.fromPosition({ x: x, y: this.position.y });
    }

    /**
     * コメントメニューポップアップの表示位置の左上座標を返します
     */
    getPopupMenuPosition(): Point {
        // 右寄せにするために、自身の幅を引いている
        const x = CommentConstants.UISetting.width - this.popupMenuSize.width;

        // ボタンの高さ分位置をずらしている
        const y = this.position.y + this.menuButtonSize.height;

        return Point.fromPosition({ x: x, y: y });
    }
}

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnnouncementList } from './AnnouncementList';

type Props = {
    onClose: () => void;
};

export const NotificationList: React.FC<Props> = ({ onClose }: Props) => {
    return (
        <div className="pointer-events-auto w-[280px] rounded bg-white">
            <div className="flex justify-between border-b">
                <div className="p-4 font-bold">お知らせ</div>
                <div className="p-4 hover:cursor-pointer hover:bg-gray-200" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>

            <div className="max-h-full overflow-y-scroll">
                <AnnouncementList />
            </div>
        </div>
    );
};

import { useEffect } from 'react';
import { AuthStatus } from './AuthContext';
import { FirebaseAuthenticationClient } from '../firebase/auth/FirebaseAuthenticationClient';
import Cookies from 'js-cookie';

const IDTokenCookieKey = 'Balus-ID-Token';

type Props = {
    authStatus: AuthStatus;
};

export const IdTokenSyncToCookie: React.FC<Props> = ({ authStatus }: Props) => {
    // ログイン中のユーザID, セッションIDが変化した際には、強制的に IdToken をリフレッシュする
    useEffect(() => {
        const forceRefresh = true;
        FirebaseAuthenticationClient.getIdTokenResult(forceRefresh).then((tokenResult) => {
            if (!tokenResult) {
                Cookies.set(IDTokenCookieKey, '', { expires: 0 });
                return;
            }

            const { token, expirationTime } = tokenResult;
            Cookies.set(IDTokenCookieKey, token, { expires: new Date(expirationTime) });
        });
    }, [authStatus]);

    // Firebase Auth の保持する IDトークン (JWT) の更新を監視し、IDトークンが更新された時にCookieにもその値を設定する
    useEffect(() => {
        const unsubscribe = FirebaseAuthenticationClient.onIdTokenChanged((user) => {
            if (!user) {
                Cookies.set(IDTokenCookieKey, '', { expires: 0 });
                return;
            }

            // IdTokenをCookieに設定する (Cookieの有効期限と、IdTokenの有効期限を揃える)
            user.getIdTokenResult().then((tokenResult) => {
                const { token, expirationTime } = tokenResult;
                Cookies.set(IDTokenCookieKey, token, { expires: new Date(expirationTime) });
            });
        });

        return () => unsubscribe();
    }, []);

    return <></>;
};

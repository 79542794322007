import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import 'reflect-metadata';
import '@framework/TrackJS';
import '@framework/firebase/setup';

// 各種コンテキストの binding は、 reflect-metadata, framework をインポートした後に並べる
import './view-model/bind';

import 'github-markdown-css/github-markdown-light.css';
import './index.css';
import { App } from 'App';
import Modal from 'react-modal';
import { emulatorEnabled, setupFirebaseEmulator } from './emulatorConfiguration';

/**
 * App Engine のデフォルトドメインにアクセスされた際に、独自ドメインに redirect させる
 */
if (window && window.location) {
    const hostname = window.location.hostname;
    const href = window.location.href;
    const normalizeMapping: Record<string, string> = {
        // levii-balus-dev
        'levii-balus-dev.appspot.com': 'balus.dev',
        'levii-balus-dev.an.r.appspot.com': 'balus.dev',
        // levii-balus-app
        'levii-balus-app.appspot.com': 'balus.app',
        'levii-balus-app.an.r.appspot.com': 'balus.app',
    };

    if (normalizeMapping[hostname]) {
        window.location.href = href.replace(hostname, normalizeMapping[hostname]);
    }

    // admin.balus.dev または admin.balus.app のドメインで、
    // 一般ユーザ向けのフロントエンドがロードされた時に、管理者向けアプリ側にリダイレクトする。
    if (hostname === 'admin.balus.dev' || hostname === 'admin.balus.app') {
        window.location.href = `https://${hostname}/admin/console`;
    }

    const host = window.location.host;
    const hostnameMapping: Record<string, string> = {
        // ユーザ画面用エントリーポイントに対して管理画面用ポート経由でアクセスがあった場合には、ユーザ画面用ポートにリダイレクトする
        'localhost:9098': 'localhost:9082',
        'localhost:9198': 'localhost:9182',
        // 127.0.0.1 でアクセスされたときには、 localhost ドメインにリダイレクトする
        '127.0.0.1:9082': 'localhost:9082',
        '127.0.0.1:9182': 'localhost:9182',
        // リバースプロキシ用ポートにアクセスがあった時には、ユーザ画面用ポートにリダイレクトする
        'localhost:9080': 'localhost:9082',
        '127.0.0.1:9080': 'localhost:9082',
        'localhost:9180': 'localhost:9182',
        '127.0.0.1:9180': 'localhost:9182',
    };
    if (hostnameMapping[host]) {
        window.location.href = window.location.href.replace(host, hostnameMapping[host]);
    }
}

// エミュレーターセットアップ
if (emulatorEnabled()) {
    setupFirebaseEmulator();
}

// react-modal に対して、アプリケーション要素の情報を設定する。
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
    <StrictMode>
        <App />
    </StrictMode>
);

import { Fragment } from 'react';
import { generateDefaultIcon } from '@framework/utils';
import { GroupEntity } from '@group/domain';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { Link } from 'react-router-dom';

type GroupViewProps = {
    group: GroupEntity;
};

const GroupItemLink: React.FC<GroupViewProps> = ({ group }: GroupViewProps) => {
    const name = group.name.toString();
    const imageUrl = group.imageUrl;
    return (
        <Link
            to={UserPagePathBuilder.groupPage(group.id)}
            className="flex rounded px-4 py-3 text-gray-800 hover:bg-teal-500 hover:text-white"
        >
            <span className="inline-block size-8 overflow-hidden rounded-full">
                {imageUrl ? (
                    <img className="size-full object-cover" src={imageUrl} alt={name} />
                ) : (
                    <div className="size-full object-cover">
                        {generateDefaultIcon({ id: group.id, name, size: 32 })}
                    </div>
                )}
            </span>
            <span
                className="inline-block h-8 truncate px-2 text-lg leading-8"
                style={{
                    width: `calc(100% - 2rem)`,
                }}
            >
                {name}
            </span>
        </Link>
    );
};

type Props = {
    groups: GroupEntity[];
};

export const GroupList: React.FC<Props> = ({ groups }: Props) => {
    return (
        <Fragment>
            {groups.map((group) => (
                <GroupItemLink group={group} key={group.key.toString()} />
            ))}
        </Fragment>
    );
};

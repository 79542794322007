import { ViewModelEntity } from '@view-model/domain/view-model';
import { LinkMultiplicity, Multiplicity, LinkName, LinkBend } from '../domain';
import {
    LinkBendUpdateCommand,
    LinkMultiplicityUpdateCommand,
    LinkNameUpdateCommand,
    LinkDeleteCommand,
    LinkReverseCommand,
} from '../command';
import { CommandManager } from '@model-framework/command';
import { LinkEntityOperation } from './LinkEntityOperation';
import { LinkKey, ModelKey } from '@view-model/domain/key';
import { LinkRepository } from '@view-model/models/sticky/StickyLink';

export class LinkOperation {
    private readonly repository: LinkRepository;
    private readonly linkEntityOperation: LinkEntityOperation;
    private nameAtEditStart: LinkName | undefined = undefined;

    constructor(
        private readonly viewModel: ViewModelEntity,
        modelKey: ModelKey,
        private readonly linkKey: LinkKey,
        private readonly commandManager: CommandManager
    ) {
        this.repository = new LinkRepository(viewModel.id, modelKey.id);
        this.linkEntityOperation = new LinkEntityOperation(this.repository);
    }

    private updateLinkMultiplicity(newMultiplicity: LinkMultiplicity): void {
        const link = LinkEntityOperation.getLink(this.linkKey, this.viewModel);
        if (!link) return;

        const currentMultiplicity = link.multiplicity;
        if (newMultiplicity.isEquals(currentMultiplicity)) return;

        const command = new LinkMultiplicityUpdateCommand(
            this.viewModel,
            this.linkEntityOperation,
            this.linkKey,
            currentMultiplicity,
            newMultiplicity
        );
        this.commandManager.execute(command);
    }

    updateSourceMultiplicity(multiplicity: Multiplicity): void {
        const link = LinkEntityOperation.getLink(this.linkKey, this.viewModel);
        if (!link) return;

        const linkMultiplicity = link.multiplicity.withSource(multiplicity);
        return this.updateLinkMultiplicity(linkMultiplicity);
    }

    updateTargetMultiplicity(multiplicity: Multiplicity): void {
        const link = LinkEntityOperation.getLink(this.linkKey, this.viewModel);
        if (!link) return;

        const linkMultiplicity = link.multiplicity.withTarget(multiplicity);
        return this.updateLinkMultiplicity(linkMultiplicity);
    }

    toggleMultiplicityEnable(): void {
        const link = LinkEntityOperation.getLink(this.linkKey, this.viewModel);
        if (!link) return;

        const toggledMultiplicity = link.multiplicity.withToggleEnabled();
        return this.updateLinkMultiplicity(toggledMultiplicity);
    }

    updateBend(newBend: LinkBend): void {
        const link = LinkEntityOperation.getLink(this.linkKey, this.viewModel);
        if (!link) return;

        const currentBend = link.bend;
        if (!newBend.isEquals(currentBend)) {
            const command = new LinkBendUpdateCommand(
                this.viewModel,
                this.linkEntityOperation,
                this.linkKey,
                currentBend,
                newBend
            );
            this.commandManager.execute(command);
        }
    }

    handleTextChange(name: LinkName): void {
        const link = LinkEntityOperation.getLink(this.linkKey, this.viewModel);
        if (!link) return;

        this.repository.saveLink(link.withName(name)).then();
    }

    handleTextStartEdit(name: LinkName): void {
        this.nameAtEditStart = name;
    }

    handleTextEndEdit(newName: LinkName): void {
        if (!this.nameAtEditStart) return;

        const trimmedName = newName.trim();
        const command = new LinkNameUpdateCommand(
            this.viewModel,
            this.linkEntityOperation,
            this.linkKey,
            this.nameAtEditStart,
            trimmedName
        );

        if (trimmedName.isEquals(this.nameAtEditStart)) {
            // トリム後の文字列が編集開始時点のものと一致していれば、コマンド履歴に登録せずに更新する
            // (前後に空白文字を追加した状態かもしれないので、トリム後の文字列で更新する)
            command.do();
        } else {
            this.commandManager.execute(command);
        }

        this.nameAtEditStart = undefined;
    }

    reverseLink(): void {
        const link = LinkEntityOperation.getLink(this.linkKey, this.viewModel);
        if (!link) return;

        const command = new LinkReverseCommand(this.viewModel, this.linkEntityOperation, link);
        this.commandManager.execute(command);
    }

    deleteLink(): void {
        const link = LinkEntityOperation.getLink(this.linkKey, this.viewModel);
        if (!link) return;

        const command = new LinkDeleteCommand(this.viewModel, this.linkEntityOperation, link);
        this.commandManager.execute(command);
    }
}

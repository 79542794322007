import { useCallback, useEffect, useMemo } from 'react';
import { WorkspaceId } from '@schema-common/base';
import { useSidebarTreeContext } from './SidebarTreeContext';
import { useSidebarTreeWorkspaceFolderTree } from './useSidebarTreeWorkspaceFolderTree';
import { Folder } from '@workspace/view-model-folder/domain/Folder';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { RouteParams } from '@user/pages/RouteParams';
import { useParams } from 'react-router-dom';
import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';

type Return = {
    isOpen: boolean;
    isSelected: boolean;
    toggleWorkspace: () => void;
    sortedFolders: Folder[];
    sortedViewModels: ViewModelEntity[];
    resetScrollWorkspace: () => void;
    resetScrollViewModel: () => void;
    scrolledViewModelId: string;
};
export const useSidebarWorkspace = (workspaceId: WorkspaceId): Return => {
    const {
        dispatch,
        records: { folders, viewModels },
        open: { workspaceIds },
        control: { scrolledViewModelId },
    } = useSidebarTreeContext();
    const rootFolderTree = useSidebarTreeWorkspaceFolderTree(workspaceId);
    const { viewModelId: viewModelIdParam, workspaceId: workspaceIdParam } = useParams<RouteParams>();

    // ビューモデルへの直接アクセス時に、ビューモデルが所属するフォルダを展開するためのuseEffect
    // ただし、ワークスペース直下のビューモデルである場合には何もしない(ワークスペースを展開するだけで良いため)
    useEffect(() => {
        if (!viewModelIdParam) return;

        const folderTree = rootFolderTree.findFolderOfViewModel(viewModelIdParam);
        if (folderTree && folderTree.id !== RootFolderTree.ROOT_ID) {
            dispatch({
                type: 'OPEN_FOLDER',
                payload: { folderId: folderTree.id },
            });
        }
    }, [viewModelIdParam, rootFolderTree, dispatch]);

    const toggleWorkspace = useCallback(() => {
        dispatch({
            type: 'TOGGLE_WORKSPACE_OPEN',
            payload: {
                workspaceId,
            },
        });
    }, [workspaceId, dispatch]);

    const sortedFolders = useMemo(
        () =>
            rootFolderTree
                .getFolderIdsOfRoot()
                .map((folderId) => folders[folderId])
                .filter((folder) => !!folder)
                .sort(Folder.compare),
        [rootFolderTree, folders]
    );

    const sortedViewModels = useMemo(
        () =>
            rootFolderTree
                .getViewModelIdsOfRoot()
                .map((viewModelId) => viewModels[viewModelId])
                .filter((viewModel) => !!viewModel)
                .sort(ViewModelEntity.compare),
        [rootFolderTree, viewModels]
    );

    const resetScrollWorkspace = useCallback(() => {
        dispatch({ type: 'SET_SCROLLED_WORKSPACE', payload: { workspaceId: '' } });
    }, [dispatch]);

    const resetScrollViewModel = useCallback(() => {
        dispatch({ type: 'SET_SCROLLED_VIEW_MODEL', payload: { viewModelId: '' } });
    }, [dispatch]);

    const isSelected = useMemo(() => workspaceId === workspaceIdParam, [workspaceId, workspaceIdParam]);

    return {
        isOpen: !!workspaceIds[workspaceId],
        isSelected,
        toggleWorkspace,
        sortedFolders,
        sortedViewModels,
        resetScrollWorkspace,
        resetScrollViewModel,
        scrolledViewModelId,
    };
};

import { useEffect, useState } from 'react';
import { DndContext, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { FolderList } from '@workspace/view-model-folder/ui/FolderList';
import { ViewModelList } from '@workspace/view-model-folder/ui/ViewModelList';
import { FolderId } from '@schema-common/base';
import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { Droppable } from '@workspace/view-model-folder/ui/DragAndDrop';
import { RootFolderContainer } from '@workspace/view-model-folder/domain/RootFolderContainer';
import { FolderContainerCollection } from '@workspace/view-model-folder/domain/FolderContainer';
import { ViewModelCollection } from '@workspace/view-model-folder/domain/ViewModelCollection';
import { useMoveViewModel } from '@workspace/view-model-folder/hooks';

type Props = {
    showEditMenu: boolean;
    workspace: Workspace;
    rootFolderContainer: RootFolderContainer;
    searchQuery: string;
};

export const RootFolderContent: React.FC<Props> = ({
    showEditMenu,
    workspace,
    rootFolderContainer,
    searchQuery,
}: Props) => {
    const [childFolderContainers, setChildFolderContainers] = useState<FolderContainerCollection>(
        FolderContainerCollection.buildEmpty
    );
    const [childViewModels, setChildViewModels] = useState<ViewModelCollection>(ViewModelCollection.buildEmpty);

    const [dropTargetFolderId, setDropTargetFolderId] = useState<FolderId | null>(null);
    const [droppableToRootFolder, setDroppableToRootFolder] = useState<boolean>(false);

    const handleDragStart = (event: DragStartEvent) => {
        const viewModelId = event.active.id as string; // id が string | number なので、 string に限定する
        setDroppableToRootFolder(!rootFolderContainer.includesInRoot(viewModelId));
    };

    const moveViewModel = useMoveViewModel(workspace.id);
    const handleDragEnd = (event: DragEndEvent) => {
        const viewModelId = event.active.id as string; // id が string | number なので、 string に限定する
        const folderId = (event.over?.id || null) as string | null;

        if (viewModelId && folderId) {
            moveViewModel(viewModelId, folderId).then();
            setDropTargetFolderId(folderId);
        }

        setDroppableToRootFolder(false);
    };

    const viewModelList = (
        <ViewModelList viewModels={childViewModels} showEditMenu={showEditMenu} workspace={workspace} />
    );

    useEffect(() => {
        const folderContainer = rootFolderContainer.buildFolderContainerOfRoot(searchQuery);
        setChildFolderContainers(folderContainer.childFolderContainers);
        setChildViewModels(folderContainer.childViewModels);
    }, [rootFolderContainer, searchQuery]);

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
            <FolderList
                dropTargetFolderId={dropTargetFolderId}
                folderContainers={childFolderContainers}
                showEditMenu={showEditMenu}
                workspace={workspace}
            />

            {droppableToRootFolder ? (
                <Droppable id={RootFolderTree.ROOT_ID} hoveredClassName="rounded-lg bg-gray-300">
                    {viewModelList}
                </Droppable>
            ) : (
                viewModelList
            )}
        </DndContext>
    );
};

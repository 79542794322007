import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import { Folder, FolderCollection } from '@workspace/view-model-folder/domain/Folder';
import { ViewModelCollection } from '@workspace/view-model-folder/domain/ViewModelCollection';
import { FolderContainer, FolderContainerCollection } from '../FolderContainer';
import { ViewModelId, FolderId, WorkspaceId } from '@schema-common/base';

export class RootFolderContainer {
    /**
     * ワークスペース配下のフォルダ、ビューモデルの一覧を取り扱うコンテナオブジェクト
     *
     * ビューモデルとフォルダ階層の親子関係のみを保持するツリーオブジェクト (rootFolderTree) と、
     * それらの実体を扱うオブジェクト (folders, viewModels) を合わせて管理するためのコンテナ。
     *
     * @param rootFolderTree {RootFolderTree} ビューモデルとフォルダ階層の親子関係をツリーとして表現するオブジェクト
     * @param folders {FolderCollection} フォルダ実体のコレクションオブジェクト
     * @param viewModels {ViewModelCollection} ビューモデル実体のコレクションオブジェクト
     */
    constructor(
        private readonly workspaceId: WorkspaceId,
        private readonly rootFolderTree: RootFolderTree,
        private readonly folders: FolderCollection,
        private readonly viewModels: ViewModelCollection
    ) {}

    isEmpty(): boolean {
        return this.rootFolderTree.isEmpty();
    }

    /**
     * 指定の要素(ビューモデルIDまたはフォルダID)が、ルートフォルダに属しているときに true を返す
     * @param id
     */
    includesInRoot(id: ViewModelId | FolderId): boolean {
        return this.rootFolderTree.includesInRoot(id);
    }

    /**
     * 指定したフォルダに属する子フォルダの集合を返します
     * @param folderId
     */
    foldersOf(folderId: FolderId): FolderCollection {
        const ids = this.rootFolderTree.getFolderIdsOf(folderId);
        return this.folders.filterByIds(ids);
    }

    /**
     * 指定したフォルダに属する子フォルダの集合を返します
     * @param folderId
     */
    viewModelsOf(folderId: FolderId): ViewModelCollection {
        const ids = this.rootFolderTree.getViewModelIdsOf(folderId);
        return this.viewModels.filterByIds(ids);
    }

    foldersOfRoot(): FolderCollection {
        return this.foldersOf(RootFolderTree.ROOT_ID);
    }

    viewModelsOfRoot(): ViewModelCollection {
        return this.viewModelsOf(RootFolderTree.ROOT_ID);
    }

    hasChildrenOf(folderId: FolderId): boolean {
        return this.rootFolderTree.hasChildrenOf(folderId);
    }

    buildFolderContainerOfRoot(searchQuery: string): FolderContainer {
        const rootFolder = new Folder(RootFolderTree.ROOT_ID, '', this.workspaceId);
        return this.buildFolderContainer(rootFolder, this.folders, searchQuery);
    }

    private buildFolderContainer(folder: Folder, folders: FolderCollection, searchQuery: string): FolderContainer {
        const folderContainers = new FolderContainerCollection(
            folders.all().map((folder) => this.buildFolderContainer(folder, this.foldersOf(folder.id), searchQuery))
        );
        const viewModels = this.viewModelsOf(folder.id);
        return new FolderContainer(folder, folderContainers, viewModels, searchQuery);
    }
}

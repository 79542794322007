import { NodeName, NodeStyle } from '../domain';
import { CommandHelper, CommandManager } from '@model-framework/command';
import { NodeKey } from '@view-model/domain/key';
import { ElementDescriptionOperation } from '@view-model/models/sticky/ElementDescription';
import { NodeRepository } from '../domain/NodeRepository';
import { RTDBPath } from '@framework/repository';
import { UserPublicProfile } from '@user/PublicProfile';
import { ModelId, ViewModelId } from '@schema-common/base';
import { ElementDescriptionTarget } from '../../ElementDescription/domain/ElementDescriptionTarget';
import { EditingUser, EditingUserRepository } from '@model-framework/text/editing-user';
import { getClientId } from '@framework/app';

export class NodeOperation {
    private readonly nodeRepository: NodeRepository;
    private readonly editingUserRepository: EditingUserRepository;
    private readonly editingUser: EditingUser;
    private readonly elementDescriptionOperation: ElementDescriptionOperation;

    constructor(
        private readonly viewModelId: ViewModelId,
        private readonly modelId: ModelId,
        private readonly nodeKey: NodeKey,
        private readonly commandManager: CommandManager,
        currentUserProfile: UserPublicProfile
    ) {
        this.nodeRepository = new NodeRepository(viewModelId, modelId);
        this.editingUserRepository = new EditingUserRepository(viewModelId);
        this.editingUser = EditingUser.buildFromProfile(currentUserProfile, getClientId());
        this.elementDescriptionOperation = new ElementDescriptionOperation(
            commandManager,
            currentUserProfile,
            viewModelId,
            modelId,
            ElementDescriptionTarget.Node,
            nodeKey.id
        );
    }

    async handleEditingNameChanged(name: NodeName): Promise<void> {
        const { nodeKey } = this;
        return this.nodeRepository.saveNodeName(nodeKey.id, name);
    }

    async confirmEditName(name: NodeName, previousName: NodeName): Promise<void> {
        const {
            viewModelId,
            modelId,
            nodeKey: { id: nodeId },
        } = this;

        const trimmedName = name.trim();
        const command = CommandHelper.buildUpdateCommand(
            RTDBPath.Node.nodeNamePath(viewModelId, modelId, nodeId),
            previousName.dump(),
            trimmedName.dump()
        );

        if (trimmedName.isEqual(previousName)) {
            // トリム後の文字列が編集開始時点のものと一致していれば、コマンド履歴に登録せずに更新する
            // (前後に空白文字を追加した状態かもしれないので、トリム後の文字列で更新する)
            command.do();
        } else {
            this.commandManager.execute(command);
        }
    }

    async handleUpdateStyle(style: NodeStyle, previousStyle: NodeStyle): Promise<void> {
        if (style.isEqual(previousStyle)) return;

        const {
            viewModelId,
            modelId,
            nodeKey: { id: nodeId },
        } = this;
        const command = CommandHelper.buildUpdateCommand(
            RTDBPath.Node.nodeStylePath(viewModelId, modelId, nodeId),
            previousStyle.dump(),
            style.dump()
        );

        this.commandManager.execute(command);
    }

    saveMyEditingUser(): void {
        this.editingUserRepository.save(this.nodeKey, this.editingUser).then();
    }

    clearMyEditingUser(): void {
        this.editingUserRepository.delete(this.nodeKey).then();
    }

    toggleNodeDescription(): void {
        this.elementDescriptionOperation.toggle();
    }

    handleEditingURLChanged(url: string | null, previousURL: string | null): void {
        const {
            viewModelId,
            modelId,
            nodeKey: { id: nodeId },
        } = this;

        const command = CommandHelper.buildUpdateCommand(
            RTDBPath.Node.nodeUrlPath(viewModelId, modelId, nodeId),
            previousURL,
            url
        );
        this.commandManager.execute(command);
    }
}

import { GroupId, ViewModelAssetId, ViewModelId } from '@schema-common/base';

const PathPrefix = 'view-model-assets';

const pathSplitter = (
    pathname: string
): {
    groupId: GroupId | null;
    viewModelId: ViewModelId | null;
    assetId: ViewModelAssetId | null;
} => {
    // pathname先頭は'/'区切りで始まり、path[0] は空文字になるのでスキップする
    // https://developer.mozilla.org/ja/docs/Web/API/URL/pathname
    const [, prefix, groupId, viewModelId, assetId] = pathname.split('/');
    if (prefix === PathPrefix) {
        return { groupId, viewModelId, assetId };
    }

    return {
        groupId: null,
        viewModelId: null,
        assetId: null,
    };
};

export class ViewModelAssetURL {
    constructor(
        private readonly groupId: GroupId,
        public readonly viewModelId: ViewModelId,
        public readonly assetId: ViewModelAssetId
    ) {}

    static fromURLString(urlStr: string): ViewModelAssetURL | null {
        try {
            // 有効なURL形式じゃない場合は例外を投げます。
            // https://developer.mozilla.org/ja/docs/Web/API/URL/URL
            const url = new URL(urlStr);
            const { groupId, viewModelId, assetId } = pathSplitter(url.pathname);
            if (groupId && viewModelId && assetId) {
                return new this(groupId, viewModelId, assetId);
            }
        } catch {
            return null;
        }

        return null;
    }

    get path(): string {
        const { groupId, viewModelId, assetId } = this;

        return `/${PathPrefix}/${groupId}/${viewModelId}/${assetId}`;
    }

    url(baseUrl: string): string {
        const url = new URL(this.path, baseUrl);
        return url.toString();
    }
}

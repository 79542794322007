import { ModelCommentThread } from './ModelCommentThread';
import { ModelCommentAuthor } from './ModelCommentAuthor';
import { Position } from '@view-model/models/common/types/ui';
import { ModelCommentEntity } from './ModelCommentEntity';
import { ILayout } from '@view-model/ui/layouts';
import { ModelCommentThreadId } from '@schema-common/base';

export class CreatingModelComment {
    constructor(
        public readonly id: ModelCommentThreadId,
        public readonly author: ModelCommentAuthor,
        public readonly body: string,
        public readonly position: Readonly<Position>
    ) {}

    static buildNew(author: ModelCommentAuthor, position: Readonly<Position>): CreatingModelComment {
        const id = ModelCommentThread.buildNewId();
        return new this(id, author, '', position);
    }

    move(dx: number, dy: number): CreatingModelComment {
        const {
            id,
            author,
            body,
            position: { x, y },
        } = this;
        return new CreatingModelComment(id, author, body, { x: x + dx, y: y + dy });
    }

    withBody(body: string): CreatingModelComment {
        const { id, author, position } = this;
        return new CreatingModelComment(id, author, body, position);
    }

    buildThread(): ModelCommentThread {
        const { id, author, body } = this;
        const primaryComment = ModelCommentEntity.buildNew(author, body);
        return new ModelCommentThread(id, 'Open', primaryComment, []);
    }

    snapLayout(layout: ILayout): CreatingModelComment {
        const { id, author, body, position } = this;
        return new CreatingModelComment(id, author, body, layout.snapPosition(position));
    }
}

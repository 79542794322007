import { TrackJSConfigType } from './type';

const config =
    process.env.GOOGLE_CLOUD_PROJECT === 'levii-balus-app'
        ? require('./balus-app.ts')
        : process.env.GOOGLE_CLOUD_PROJECT === 'levii-balus-dev'
          ? require('./balus-dev.ts')
          : require('./shared-dev.ts');

export const TrackJSConfig: TrackJSConfigType = config.TrackJSConfig;

import { ICommand } from '@model-framework/command';
import { StickyZoneStyleSet } from '../domain';
import { StickyZoneRepository } from '@view-model/models/sticky/StickyZoneView';

export class StickyZoneStyleUpdateMultiCommand implements ICommand {
    constructor(
        private readonly fromValue: StickyZoneStyleSet,
        private readonly toValue: StickyZoneStyleSet,
        private readonly repository: StickyZoneRepository
    ) {}

    do(): void {
        this.toValue.saveTo(this.repository).then();
    }

    undo(): void {
        this.fromValue.saveTo(this.repository).then();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return this.toValue.isSameOn(this.repository);
    }

    async canRedo(): Promise<boolean> {
        return this.fromValue.isSameOn(this.repository);
    }
}

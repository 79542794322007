import { LinkableTargetKey } from '@view-model/domain/key';
import { StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { StickyZone } from '@view-model/models/sticky/StickyZoneView';
import { DisplayOrderTree } from '@model-framework/display-order';
import { LinkerState } from '@view-model/models/common/components/LinkCreator/LinkerState';

type LinkableTargetElement = StickyNode | StickyZone;

export class LinkerOperation {
    // リンク対象のキーを取得
    static getLinkableElementKey(params: {
        linkerState: LinkerState<LinkableTargetKey>;
        linkableElement: LinkableTargetElement | null;
        displayOrderTree: DisplayOrderTree;
    }): null | LinkableTargetKey {
        const { linkableElement, linkerState, displayOrderTree } = params;

        if (!linkableElement || !linkerState) {
            return null;
        }

        const fixedElementKey =
            linkerState.isCreating() || linkerState.isTargetReplacing() ? linkerState.source : linkerState.target;

        // 自己参照のリンクは対象外
        if (fixedElementKey?.isEqual(linkableElement.key)) {
            return null;
        }

        if (fixedElementKey?.kind === 'StickyZone') {
            if (displayOrderTree.hasZoneWithDescendant(fixedElementKey.id.toString(), linkableElement.id)) return null;
        }

        if (linkableElement instanceof StickyZone) {
            if (
                fixedElementKey &&
                displayOrderTree.hasZoneWithDescendant(linkableElement.id, fixedElementKey.id.toString())
            )
                return null;
        }

        return linkableElement.key;
    }
}

import { useCallback } from 'react';
import { GroupMemberSignUpMethod, SignUpMethodName } from '@group/domain/GroupEntity';

type Props = {
    signUpMethod: GroupMemberSignUpMethod;
    onChange(signUpMethod: GroupMemberSignUpMethod): void;
};

export const AvailableSignUpMethodForm: React.FC<Props> = ({ signUpMethod, onChange }: Props) => {
    const handleChange = useCallback(
        (name: SignUpMethodName, checked: boolean) => {
            onChange(signUpMethod.with(name, checked));
        },
        [onChange, signUpMethod]
    );

    return (
        <>
            <ul className="flex flex-col">
                {SignUpMethodName.values().map((name) => {
                    return (
                        <SignUpMethod
                            key={name}
                            name={name}
                            checked={signUpMethod.availableOf(name)}
                            onChange={handleChange}
                        />
                    );
                })}
            </ul>
            {signUpMethod.isEmpty() && (
                <div className="text-red-500">
                    ※ 新規ユーザ登録時に利用可能なログイン方法を、1つ以上選択してください。
                </div>
            )}
        </>
    );
};

type ItemProps = {
    name: SignUpMethodName;
    checked: boolean;
    onChange(name: SignUpMethodName, checked: boolean): void;
};

const SignUpMethod: React.FC<ItemProps> = ({ name, checked, onChange }: ItemProps) => {
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(name, event.target.checked);
        },
        [name, onChange]
    );

    return (
        <li className="flex flex-row items-center p-2 hover:bg-gray-200">
            <label className="flex grow cursor-pointer flex-row items-center">
                <input
                    type="checkbox"
                    className="form-checkbox size-4 cursor-pointer border-brand"
                    checked={checked}
                    onChange={handleChange}
                />
                <span className="grow px-2">{SignUpMethodName.getLocalString(name)}</span>
            </label>
        </li>
    );
};

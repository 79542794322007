import { NodeId } from '@schema-common/base';
import { NodeStyle } from './NodeStyle';
import { StickyNode } from '../StickyNode';
import { ThemeColor } from '@view-model/models/common/color';
import { NodeRepository } from '../NodeRepository';
import { NodeFontSize } from './NodeFontSize';

export class NodeStyleSet {
    constructor(private readonly styles: Record<NodeId, NodeStyle>) {}

    static fromNodes(nodes: StickyNode[]): NodeStyleSet {
        const styles: Record<NodeId, NodeStyle> = {};
        nodes.forEach((node) => {
            styles[node.key.id] = node.style;
        });

        return new NodeStyleSet(styles);
    }

    isEqual(other: NodeStyleSet): boolean {
        const { styles } = this;
        const otherStyles = other.styles;

        return (
            Object.keys(styles).length === Object.keys(otherStyles).length &&
            Object.entries(styles).every(([id, style]) => {
                const otherStyle = otherStyles[id];
                return otherStyle && style.isEqual(otherStyle);
            })
        );
    }

    isEmpty(): boolean {
        return Object.keys(this.styles).length === 0;
    }

    withThemeColor(themeColor: ThemeColor): NodeStyleSet {
        const newStyles: Record<NodeId, NodeStyle> = {};

        Object.entries(this.styles).forEach(([id, style]) => {
            newStyles[id] = style.withThemeColor(themeColor);
        });

        return new NodeStyleSet(newStyles);
    }

    withFontSize(fontSize: NodeFontSize): NodeStyleSet {
        const newStyles: Record<NodeId, NodeStyle> = {};

        Object.entries(this.styles).forEach(([id, style]) => {
            newStyles[id] = style.withFontSize(fontSize);
        });

        return new NodeStyleSet(newStyles);
    }

    async saveTo(repository: NodeRepository): Promise<void> {
        return repository.saveStyles(this.styles);
    }

    /**
     * 保持しているスタイルすべてがリポジトリ上の値と同じかどうかを返す
     * @param repository
     */
    async isSameOn(repository: NodeRepository): Promise<boolean> {
        for (const [id, style] of Object.entries(this.styles)) {
            const currentStyle = await repository.getStyle(id);

            if (!currentStyle || !currentStyle.isEqual(style)) return false;
        }

        return true;
    }
}

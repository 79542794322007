import { useRef, useLayoutEffect } from 'react';
import { Size } from '@view-model/models/common/basic';

type Props = {
    children: React.ReactNode;
    onSizeChange?: (newSize: Size) => void;
    transform?: string;
};

// <foreignObject>要素のサイズを子要素に合わせて自動調整するコンポーネント
// ForeignObjectAutosizeとの違いは、子要素のサイズを取得するためにResizeObserverではなく、useLayoutEffectを使用している点
export const ForeignObjectAutosize2: React.FC<Props> = ({ children, onSizeChange, transform }: Props) => {
    const foreignObjectRef = useRef<SVGForeignObjectElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const sizeRef = useRef<Size | null>(null);

    useLayoutEffect(() => {
        const foreignObject = foreignObjectRef.current;
        const wrapper = wrapperRef.current;
        if (!foreignObject || !wrapper) {
            return;
        }

        const width = wrapper.offsetWidth;
        const height = wrapper.offsetHeight;

        foreignObject.setAttribute('width', width.toString());
        foreignObject.setAttribute('height', height.toString());

        // ループしないようにするため、サイズが変わった場合のみコールバックを呼ぶ
        if (!sizeRef.current || sizeRef.current.width !== width || sizeRef.current.height !== height) {
            const size = new Size(width, height);
            sizeRef.current = size;
            onSizeChange?.(size);
        }
    });

    return (
        <foreignObject
            ref={foreignObjectRef}
            className="pointer-events-auto overflow-visible"
            style={{
                // 再描画の最適化のための指定
                contain: 'size style layout',
            }}
            transform={transform}
        >
            <div ref={wrapperRef} className="inline-block whitespace-nowrap">
                {children}
            </div>
        </foreignObject>
    );
};

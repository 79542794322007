import { ICommand } from '@model-framework/command';
import { ModelCommentThread, ModelCommentThreadRepository } from '../domain';
import { Position } from '@view-model/models/common/types/ui';
import { IPositionSetRepository } from '@view-model/models/common/PositionSet';
import { ModelCommentThreadId } from '@schema-common/base';

export class ModelCommentThreadDeleteCommand implements ICommand {
    constructor(
        private readonly commentThread: ModelCommentThread,
        private readonly position: Position,
        private readonly threadRepository: ModelCommentThreadRepository,
        private readonly positionRepository: IPositionSetRepository
    ) {}

    private get threadId(): ModelCommentThreadId {
        return this.commentThread.id;
    }

    do(): void {
        Promise.all([
            this.threadRepository.deleteOne(this.threadId),
            this.positionRepository.deleteOne(this.threadId),
        ]).then();
    }

    async canRedo(): Promise<boolean> {
        const currentThread = await this.threadRepository.getOne(this.threadId);
        return !!currentThread && currentThread.isEqual(this.commentThread);
    }

    async canUndo(): Promise<boolean> {
        const currentThread = await this.threadRepository.getOne(this.threadId);
        return !currentThread;
    }

    redo(): void {
        this.do();
    }

    undo(): void {
        Promise.all([
            this.threadRepository.saveOne(this.commentThread),
            this.positionRepository.saveOne(this.threadId, this.position),
        ]).then();
    }
}

import { UserKey } from '@user/domain';
import { ModelCommentJSON } from '@schema-common/view-model';
import { UserPublicProfile } from '@user/PublicProfile';

export class ModelCommentAuthor {
    constructor(
        private readonly userKey: UserKey,
        private readonly name: string,
        private readonly imageURL: string | null
    ) {}

    static buildFromUser(profile: UserPublicProfile): ModelCommentAuthor {
        return new this(UserKey.buildFromId(profile.id), profile.name, profile.imageUrl);
    }

    static load(dump: ModelCommentJSON['author']): ModelCommentAuthor {
        const { userKey, name, imageURL } = dump;
        return new this(UserKey.load(userKey), name, imageURL);
    }

    dump(): ModelCommentJSON['author'] {
        const { userKey, name, imageURL } = this;
        return {
            userKey: userKey.toString(),
            name,
            imageURL: imageURL || null,
        };
    }

    getName(): string {
        return this.name;
    }

    getUserKey(): UserKey {
        return this.userKey;
    }

    getImageURL(): string | null {
        return this.imageURL;
    }

    isEqualUserKey(key: UserKey): boolean {
        return this.userKey.isEqual(key);
    }
}

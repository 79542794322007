import { LinkStyle } from './LinkStyle';
import { LinkId } from '@schema-common/base';
import { LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';
import { LinkEntity } from '../../LinkEntity';
import { LinkRepository } from '../../LinkRepository';

export class LinkStyleSet {
    constructor(private readonly styles: Record<LinkId, LinkStyle>) {}

    static fromLinks(links: LinkEntity[]): LinkStyleSet {
        const styles: Record<LinkId, LinkStyle> = {};

        links.forEach((link) => {
            styles[link.key.id] = link.style;
        });

        return new LinkStyleSet(styles);
    }

    isEqual(other: LinkStyleSet): boolean {
        const { styles } = this;
        const otherStyles = other.styles;

        return (
            Object.keys(styles).length === Object.keys(otherStyles).length &&
            Object.entries(styles).every(([id, style]) => {
                const otherStyle = otherStyles[id];
                return otherStyle && style.isEquals(otherStyle);
            })
        );
    }

    withLineStyle(lineStyle: LinkLineStyle): LinkStyleSet {
        return this.styleMap((style) => style.withLineStyle(lineStyle));
    }

    withMarkStyle(markStyle: LinkMarkStyle): LinkStyleSet {
        return this.styleMap((style) => style.withMarkStyle(markStyle));
    }

    withColor(color: LinkColor): LinkStyleSet {
        return this.styleMap((style) => style.withColor(color));
    }

    private styleMap(f: (style: LinkStyle) => LinkStyle): LinkStyleSet {
        const newStyles: Record<LinkId, LinkStyle> = {};

        Object.entries(this.styles).forEach(([id, style]) => {
            newStyles[id] = f(style);
        });

        return new LinkStyleSet(newStyles);
    }

    async saveTo(repository: LinkRepository): Promise<void> {
        return repository.saveStyles(this.styles);
    }

    async isSameOn(repository: LinkRepository): Promise<boolean> {
        for (const [id, style] of Object.entries(this.styles)) {
            const currentStyle = await repository.getStyle(id);

            if (!currentStyle || !currentStyle.isEquals(style)) return false;
        }

        return true;
    }
}

type Props = {
    width: number;
    height: number;
    color: 'primary' | 'gray';
};

export const DraggableHeader: React.FC<Props> = ({ width, height, color }: Props) => {
    return (
        <foreignObject width={width} height={height}>
            <div
                className={
                    color === 'primary'
                        ? 'size-full cursor-move rounded-t-2xl bg-brand'
                        : 'size-full cursor-move rounded-t-2xl bg-gray-200'
                }
            />
        </foreignObject>
    );
};

import { MutableRefObject, useEffect, useRef } from 'react';
import { CommandManager } from './CommandManager';
import { ActionLogSender } from '@framework/action-log';
import { ViewModelId } from '@schema-common/base';

export const useCommandManagerRef = (
    viewModelId: ViewModelId,
    actionLogSender?: ActionLogSender
): MutableRefObject<CommandManager> => {
    const commandManagerRef = useRef<CommandManager>(new CommandManager(viewModelId, actionLogSender));

    useEffect(() => {
        if (actionLogSender) {
            commandManagerRef.current.setActionLogSender(actionLogSender);
        }
    }, [actionLogSender]);

    return commandManagerRef;
};

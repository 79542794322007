import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import { SidebarTreeRecords } from './SidebarTreeStore';

export const filterByKeyword = (keyword: string, all: SidebarTreeRecords): SidebarTreeRecords => {
    const { workspaces, folderTrees, folders, viewModels } = all;
    // keywordに引っかかるワークスペースを検索
    const targetWorkspaces = Object.values(workspaces).filter((w) => w.name.includesMulti([keyword]));
    const resultWithTargetWorkspaces = targetWorkspaces.reduce(
        (res, targetWorkspace) => {
            const targetRootFolderTree = folderTrees[targetWorkspace.id];
            if (!targetRootFolderTree) return res;

            return {
                workspaces: {
                    ...res.workspaces,
                    [targetWorkspace.id]: targetWorkspace,
                },
                folderTrees: {
                    ...res.folderTrees,
                    [targetWorkspace.id]: targetRootFolderTree,
                },
            };
        },
        {
            workspaces: {},
            folderTrees: {},
        }
    );

    // keywordに引っかかるフォルダーを検索
    const targetFolders = Object.values(folders).filter((f) => f.name.toLowerCase().includes(keyword.toLowerCase()));
    const resultWithTargetFolders = targetFolders.reduce(
        (res, targetFolder) => {
            const target = Object.entries(folderTrees).find(([, ft]) => ft.has(targetFolder.id));
            if (!target) return res;

            const [targetWorkspaceId, targetRootFolderTree] = target;
            const targetWorkspace = workspaces[targetWorkspaceId];
            if (!targetWorkspace) return res;

            return {
                workspaces: {
                    ...res.workspaces,
                    [targetWorkspace.id]: targetWorkspace,
                },
                folderTrees: {
                    ...res.folderTrees,
                    [targetWorkspace.id]: targetRootFolderTree,
                },
                folders: {
                    ...res.folders,
                    ...(targetFolder ? { [targetFolder.id]: targetFolder } : {}),
                },
            };
        },
        { workspaces: {}, folderTrees: {}, folders: {} }
    );

    // keywordに引っかかるビューモデルを検索
    const targetViewModels = Object.values(viewModels).filter((v) => v.name.includesMulti([keyword]));

    const resultWithTargetViewModels = targetViewModels.reduce(
        (res, targetViewModel) => {
            // targetViewModelsをもとに、どのワークスペースやフォルダーに入っているかをチェック
            const target = Object.entries(folderTrees).find(([, ft]) => ft.hasDescendantViewModel(targetViewModel.id));
            if (!target) return res;

            const [targetWorkspaceId, targetRootFolderTree] = target;
            const targetWorkspace = workspaces[targetWorkspaceId];
            if (!targetWorkspace) return res;

            const targetFolderTree = targetRootFolderTree.findFolderOfViewModel(targetViewModel.id);
            const targetFolder =
                (targetFolderTree && targetFolderTree.id === RootFolderTree.ROOT_ID) || !targetFolderTree
                    ? undefined
                    : folders[targetFolderTree.id];

            return {
                workspaces: {
                    ...res.workspaces,
                    [targetWorkspace.id]: targetWorkspace,
                },
                folderTrees: {
                    ...res.folderTrees,
                    [targetWorkspace.id]: targetRootFolderTree,
                },
                folders: {
                    ...res.folders,
                    ...(targetFolder ? { [targetFolder.id]: targetFolder } : {}),
                },
                viewModels: {
                    ...res.viewModels,
                    [targetViewModel.id]: targetViewModel,
                },
            };
        },
        { workspaces: {}, folderTrees: {}, folders: {}, viewModels: {} }
    );

    return {
        workspaces: {
            ...resultWithTargetWorkspaces.workspaces,
            ...resultWithTargetFolders.workspaces,
            ...resultWithTargetViewModels.workspaces,
        },
        folderTrees: {
            ...resultWithTargetWorkspaces.folderTrees,
            ...resultWithTargetFolders.folderTrees,
            ...resultWithTargetViewModels.folderTrees,
        },
        folders: {
            ...resultWithTargetFolders.folders,
            ...resultWithTargetViewModels.folders,
        },
        viewModels: {
            ...resultWithTargetViewModels.viewModels,
        },
    };
};

import { useCallback, useState, PointerEvent } from 'react';
import { SidebarContextValues } from './SidebarContext';
import Cookies from 'js-cookie';
import { Timestamp } from '@framework/Timestamp';
import { useIsSidebarAvailable } from './useIsSidebarAvailable';

const DEFAULT_SIDEBAR_WIDTH = 256;
const MAX_SIDEBAR_WIDTH = 440;
const MIN_SIDEBAR_WIDTH = 220;

const SidebarWidthCookieName = 'balus_sidebar_width';
const SidebarIsOpenCookieName = 'balus_sidebar_is_open';

// DragEventを使うと、ドラッグ終了時にドラッグ中に出るがドラッグ開始時の場所まで戻ってしまう
// また、MouseEventでは、ドラッグがうまく作動しない
// そのため、PointerEventを利用している
type SidebarValues = SidebarContextValues & {
    startResizingSidebarWidth: (e: PointerEvent<HTMLDivElement>) => void;
    resizingSidebarWidth: (e: PointerEvent<HTMLDivElement>) => void;
    endResizingSidebarWidth: (e: PointerEvent<HTMLDivElement>) => void;
};

const getSidebarWidthFromCookie = (): string | null => {
    const sidebarWidth = Cookies.get(SidebarWidthCookieName);

    return sidebarWidth || null;
};

const setSidebarWidthToCookie = (sidebarWidth: number): void => {
    const now = Timestamp.now();
    const expires = now.addDays(365).toDate();
    Cookies.set(SidebarWidthCookieName, JSON.stringify(sidebarWidth), { expires });
};

const getSidebarIsOpenFromCookie = (): boolean => {
    const cookieValue = Cookies.get(SidebarIsOpenCookieName);

    if (!cookieValue) return true;

    return JSON.parse(cookieValue) === true;
};

const setSidebarIsOpenToCookie = (isOpen: boolean): void => {
    const now = Timestamp.now();
    const expires = now.addDays(365).toDate();
    Cookies.set(SidebarIsOpenCookieName, JSON.stringify(isOpen), { expires });
};

const correctSidebarWidth = (value: string | null): number => {
    // Cookieにサイドバー幅の値がない場合
    if (!value) return DEFAULT_SIDEBAR_WIDTH;

    const sidebarWidth = Number(value);

    // Cookieに数値以外の値が保存されている場合
    if (isNaN(sidebarWidth)) return DEFAULT_SIDEBAR_WIDTH;

    // Cookieに保存されている値が最小値より小さい場合
    if (sidebarWidth < MIN_SIDEBAR_WIDTH) return MIN_SIDEBAR_WIDTH;

    // Cookieに保存されている値が最大値より大きい場合
    if (MAX_SIDEBAR_WIDTH < sidebarWidth) return MAX_SIDEBAR_WIDTH;

    return sidebarWidth;
};

export const useSidebarValues = (): SidebarValues => {
    const [sidebarWidth, setSidebarWidth] = useState<number>(correctSidebarWidth(getSidebarWidthFromCookie()));
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(getSidebarIsOpenFromCookie());
    const [isResizing, setIsResizing] = useState<boolean>(false);

    const isSidebarAvailable = useIsSidebarAvailable();

    const toggleSidebar = useCallback(() => {
        setSidebarIsOpenToCookie(!isSidebarOpen);
        setIsSidebarOpen(!isSidebarOpen);
    }, [isSidebarOpen]);

    const startResizingSidebarWidth = useCallback((e: PointerEvent<HTMLDivElement>) => {
        // PointerEventを利用する際はsetPointerCaptureを併せて指定する必要がある
        e.currentTarget.setPointerCapture(e.pointerId);
        setIsResizing(true);
    }, []);

    const resizingSidebarWidth = useCallback(
        (e: PointerEvent<HTMLDivElement>) => {
            if (!isResizing) return;

            if (e.pageX < MIN_SIDEBAR_WIDTH) {
                setSidebarWidth(MIN_SIDEBAR_WIDTH);
                return;
            }

            if (MAX_SIDEBAR_WIDTH < e.pageX) {
                setSidebarWidth(MAX_SIDEBAR_WIDTH);
                return;
            }

            setSidebarWidth(Math.floor(e.pageX));
        },
        [isResizing]
    );

    const endResizingSidebarWidth = useCallback(
        (e: PointerEvent<HTMLDivElement>) => {
            // PointerEventを利用する際はreleasePointerCaptureを併せて指定する必要がある
            e.currentTarget.releasePointerCapture(e.pointerId);
            setSidebarWidthToCookie(sidebarWidth);
            setIsResizing(false);
        },
        [sidebarWidth]
    );

    return {
        sidebarWidth,
        isSidebarVisible: isSidebarAvailable && isSidebarOpen,
        isSidebarOpenButtonVisible: isSidebarAvailable && !isSidebarOpen,
        toggleSidebar,
        startResizingSidebarWidth,
        resizingSidebarWidth,
        endResizingSidebarWidth,
    };
};

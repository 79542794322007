import { useCallback } from 'react';
import { SizeTracker } from '@view-model/models/common/components/SizeTracker';
import { Size } from '@view-model/models/common/basic';
import { ModelCommentId } from '@schema-common/base';
import { ItemContainer } from './ItemContainer';

type Props = {
    id: ModelCommentId;
    width: number;
    withTopDivider?: boolean;
    onSizeChange: (id: ModelCommentId, newSize: Size) => void;
    children: React.ReactNode;
};

export const ModelCommentContentItemContainer: React.FC<Props> = ({
    id,
    width,
    withTopDivider,
    onSizeChange,
    children,
}: Props) => {
    const handleSizeChanged = useCallback(
        (size: Size) => {
            onSizeChange?.(id, size);
        },
        [id, onSizeChange]
    );

    return (
        <div>
            <SizeTracker onSizeChanged={handleSizeChanged}>
                <ItemContainer width={width} withTopDivider={withTopDivider}>
                    {children}
                </ItemContainer>
            </SizeTracker>
        </div>
    );
};

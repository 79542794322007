import { Rect } from '@view-model/models/common/basic';

type Props = {
    resizingViewRect: Rect;
};

export const ResizingOverlay: React.FC<Props> = ({ resizingViewRect }: Props) => {
    const { x, y, width, height } = resizingViewRect.getXYWH();
    return <rect x={x} y={y} width={width} height={height} fill="#eee" fillOpacity="0.7" />;
};

import { Icon } from '@framework/ui/atoms/Icon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

/**
 * 画面全体を使ってローディング中であることを表示する。
 * Balusブランドロゴと、ローディングアイコンを表示する。
 * @returns
 */
export const FullScreenLoading: React.FC<unknown> = () => (
    <div className="absolute left-0 top-0 flex h-screen w-screen flex-col items-center justify-center">
        <div className="flex flex-col items-center pb-24">
            <Icon name="balus" color="brand" size="brand-xl" />
            <div className="pt-8">
                <FontAwesomeIcon icon={faSpinner} className="fa-spin text-3xl text-brand" />
            </div>
        </div>
    </div>
);

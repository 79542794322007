import { GroupEntity, GroupMemberInvitationStatus, GroupMemberRole } from '@group/domain';
import { GroupConsoleTitle } from '../common';
import { useGroupMemberInvitations } from './useGroupMemberInvitations';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { ItemMenuButton } from './ItemMenuButton';

type Props = {
    group: GroupEntity;
};

export const GroupConsoleInvitationsPage: React.FC<Props> = ({ group }: Props) => {
    const invitations = useGroupMemberInvitations(group.id);
    return (
        <>
            <div className="mb-4 flex items-center justify-between">
                <GroupConsoleTitle title="招待一覧" />
            </div>
            <div className="overflow-y-scroll pb-8" style={{ maxHeight: 'calc(100% - 80px)' }}>
                <table className="w-full border-separate">
                    <thead className="sticky top-0 z-10 bg-white">
                        <tr className="text-left">
                            <th className="border-y-2 p-2">送付先メールアドレス</th>
                            <th className="border-y-2 p-2">ロール</th>
                            <th className="border-y-2 p-2">招待者</th>
                            <th className="border-y-2 p-2">状態</th>
                            <th className="border-y-2 p-2" />
                        </tr>
                    </thead>
                    <tbody>
                        {!invitations || invitations.length <= 0 ? (
                            <tr>
                                <td>招待が見つかりません</td>
                            </tr>
                        ) : (
                            invitations.map((i) => (
                                <tr key={i.id} className="border-b-2">
                                    <td className="border-b-2 border-gray-400 py-4 align-middle">{i.mailAddress}</td>
                                    <td className="border-b-2 border-gray-400 py-4 align-middle">
                                        {GroupMemberRole.getLocalString(i.invitation.memberRole)}
                                    </td>
                                    <td className="border-b-2 border-gray-400 py-4 align-middle">
                                        <div className="flex items-center">
                                            <div className="mr-1 flex size-10 items-center justify-center">
                                                <UserIcon
                                                    name={i.senderUser.name}
                                                    id={i.senderUser.userId}
                                                    imageUrl={i.senderUser.imageUrl}
                                                    size="large"
                                                    className="grow-0"
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                {i.senderUser.name}
                                                <span className="text-sm text-gray-600">{i.senderUser.email}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="relative border-b-2 border-gray-400 py-4 align-middle">
                                        {GroupMemberInvitationStatus.getLocalString(i.status)}
                                    </td>
                                    <td className="relative border-b-2 border-gray-400 py-4 align-middle">
                                        {/* 承諾待ちの時のみ表示 */}
                                        {i.isPending() && <ItemMenuButton invitation={i} />}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

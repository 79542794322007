import { LineEdge } from './LineEdge';
import { LinkColor, LinkConstants, LinkMarkStyle, linkColorSelector } from '../index';
import { DiamondMarkView, TriangleMarkView } from '../private-components';

type Props = {
    linkColor: LinkColor;
    markStyle: LinkMarkStyle;
    startEdge: LineEdge;
    endEdge: LineEdge;
    isSelected: boolean;
    isOtherUserSelected: boolean;
    isRelatedNodeSelected: boolean;
};

export const LineEdgeMarkView: React.FC<Props> = ({
    linkColor,
    markStyle,
    startEdge,
    endEdge,
    isSelected,
    isOtherUserSelected,
    isRelatedNodeSelected,
}: Props) => {
    if (markStyle === LinkMarkStyle.NoMark) return null;

    return (
        <>
            {markStyle === LinkMarkStyle.Arrow && (
                <g transform={endEdge.toSVGTransform()}>
                    <TriangleMarkView
                        className="group-hover/link:fill-brand"
                        fill={linkColorSelector(linkColor, isSelected, isOtherUserSelected, isRelatedNodeSelected)}
                        stroke="none"
                        size={LinkConstants.LineEdgeArrowMarkSize}
                    />
                </g>
            )}
            {markStyle === LinkMarkStyle.TwoWayArrow && (
                <>
                    <g transform={startEdge.toSVGTransform()}>
                        <TriangleMarkView
                            className="group-hover/link:fill-brand"
                            fill={linkColorSelector(linkColor, isSelected, isOtherUserSelected, isRelatedNodeSelected)}
                            stroke="none"
                            size={LinkConstants.LineEdgeArrowMarkSize}
                        />
                    </g>
                    <g transform={endEdge.toSVGTransform()}>
                        <TriangleMarkView
                            className="group-hover/link:fill-brand"
                            fill={linkColorSelector(linkColor, isSelected, isOtherUserSelected, isRelatedNodeSelected)}
                            stroke="none"
                            size={LinkConstants.LineEdgeArrowMarkSize}
                        />
                    </g>
                </>
            )}
            {markStyle === LinkMarkStyle.Diamond && (
                <g transform={endEdge.toSVGTransform()}>
                    <DiamondMarkView
                        className="group-hover/link:fill-brand"
                        fill={linkColorSelector(linkColor, isSelected, isOtherUserSelected, isRelatedNodeSelected)}
                        stroke="none"
                        size={LinkConstants.LineEdgeDiamondMarkSize}
                    />
                </g>
            )}
            {markStyle === LinkMarkStyle.HollowDiamond && (
                <g transform={endEdge.toSVGTransform()}>
                    <DiamondMarkView
                        className="group-hover/link:fill-brand"
                        fill="white"
                        stroke={linkColorSelector(linkColor, isSelected, isOtherUserSelected, isRelatedNodeSelected)}
                        strokeWidth={4}
                        size={LinkConstants.LineEdgeDiamondMarkSize}
                    />
                </g>
            )}
            {markStyle === LinkMarkStyle.HollowTriangle && (
                <g transform={endEdge.toSVGTransform()}>
                    <TriangleMarkView
                        className="group-hover/link:fill-brand"
                        fill="white"
                        stroke={linkColorSelector(linkColor, isSelected, isOtherUserSelected, isRelatedNodeSelected)}
                        strokeWidth={4}
                        size={LinkConstants.LineEdgeArrowMarkSize}
                    />
                </g>
            )}
        </>
    );
};

import { Grid } from './Grid';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { GridDistance, GridIndex, GridRange } from './GridRange';

/**
 * 0以上max以下の整数を返す
 * @param max 整数
 */
function randInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max + 1));
}

/**
 * ビューの配置位置を決める
 */
export class ViewPlacement {
    private readonly grid: Grid;
    public readonly viewGridWidth: number;
    public readonly viewGridHeight: number;

    constructor(gridSize: number, viewGridWidth: GridDistance, viewGridHeight: GridDistance) {
        this.grid = new Grid(gridSize);
        this.viewGridWidth = viewGridWidth;
        this.viewGridHeight = viewGridHeight;
    }

    static fromViewSize(gridSize: number, size: Size): ViewPlacement {
        return new this(gridSize, Math.ceil(size.width / gridSize), Math.ceil(size.height / gridSize));
    }

    public get viewWidth(): number {
        return this.grid.toPosition(this.viewGridWidth);
    }

    public get viewHeight(): number {
        return this.grid.toPosition(this.viewGridHeight);
    }

    /**
     * 指定された範囲内にコンポーネントを追加するグリッド位置を適切な範囲でランダムに返す。
     * コンポーネントが表示範囲に二つ以上入る場合は、表示領域の中央半分の範囲から配置するグリッドを選ぶ。
     * それ以外の場合は表示範囲内のグリッドに配置。
     * @param range グリッド単位での表示範囲
     * @param componentSize コンポーネントのサイズ（グリッドインデックス単位）
     */
    private chooseGridIndex(range: GridRange, componentSize: GridDistance): GridIndex {
        // size全体が収まる範囲のgrid単位のindex幅
        const randomRange = Math.max(range.size - componentSize, 0);

        // 表示領域が十分広い場合、画面中央付近に配置されるようにインデックス範囲を調整する
        if (randomRange > componentSize) {
            // 画面中央の全体の半分の範囲が選択されるように調整。端から1/4をマージンとする。
            const margin = Math.trunc(range.size / 4);

            const startIndex = range.low + margin;
            const randomMax = randomRange - margin * 2;

            return startIndex + randInt(randomMax);
        }

        return range.low + randInt(randomRange);
    }

    public chooseViewPosition(area: Rect): Point {
        const xIndexRange = this.grid.toGridRange(area.getLeft(), area.getRight());
        const yIndexRange = this.grid.toGridRange(area.getTop(), area.getBottom());

        const xIndex = this.chooseGridIndex(xIndexRange, this.viewGridWidth);
        const yIndex = this.chooseGridIndex(yIndexRange, this.viewGridHeight);

        return new Point(this.grid.toPosition(xIndex), this.grid.toPosition(yIndex));
    }
}

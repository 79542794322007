import { useCallback, useMemo } from 'react';
import { SimpleFooterLayout } from '@user/layouts/SimpleFooterLayout';
import { RequestForm } from './RequestForm';
import { useHistory, useLocation } from 'react-router-dom';
import { ResetForm } from './ResetForm';
import { FlatButton } from '@framework/ui/atoms';
import BalusLogo from '@assets/balus-logo-brand-horizontal.svg';

export const PasswordResetPage: React.FC<unknown> = () => {
    const history = useHistory();
    const location = useLocation();
    const token = useMemo(() => new URLSearchParams(location.search).get('token'), [location]);
    const success = useMemo(() => new URLSearchParams(location.search).get('success'), [location]);

    // パスワード変更に成功した際のハンドリング
    const handleResetSuccess = useCallback(() => {
        // クエリパラメータでパスワード変更の情報を記録する
        // ログイン中ユーザの情報が変化した際に、セッション管理の最上位コンポーネントでコンポーネントの再生成が行われる。
        // そのとき、配下コンポーネントの state は全てリセットされるため、URLパラメータで状態を保持する。
        const params = new URLSearchParams();
        params.set('success', 'true');
        history.replace({ search: params.toString() });
    }, [history]);

    const handleMoveHome = useCallback(() => history.push('/'), [history]);

    return (
        <SimpleFooterLayout>
            <div className="mx-auto flex flex-col items-center px-4 pb-8 pt-16 md:container">
                <img src={BalusLogo} className="w-64" />

                <div className="py-8 text-3xl font-bold">パスワードを再設定する</div>

                <div className="rounded-lg border border-gray-500 p-8" style={{ width: '600px' }}>
                    {success ? (
                        <>
                            <div className="text-2xl font-bold">パスワードを変更しました</div>
                            <div className="pt-8">
                                次回から指定していただいたパスワードでログインを行なってください。
                            </div>

                            <div className="pb-8 pt-16 text-center">
                                <FlatButton className="px-16" color="brand" onClick={handleMoveHome}>
                                    ホームに移動する
                                </FlatButton>
                            </div>
                        </>
                    ) : (
                        <>{token ? <ResetForm token={token} onResetSuccess={handleResetSuccess} /> : <RequestForm />}</>
                    )}
                </div>
            </div>
        </SimpleFooterLayout>
    );
};

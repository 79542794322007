import { Workspace } from '@workspace/domain/workspace/Workspace';
import { WorkspaceListItem } from './WorkspaceListItem';

type Props = {
    workspaces: Workspace[];
};

export const WorkspaceList: React.FC<Props> = ({ workspaces }: Props) => {
    return (
        <div className="min-h-full list-none">
            {workspaces.map((workspace) => (
                <div key={workspace.key.toString()} className={'py-2'}>
                    <WorkspaceListItem workspace={workspace} />
                </div>
            ))}
        </div>
    );
};

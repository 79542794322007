import { useCallback, useMemo, useState } from 'react';
import { Point, Rect, Size, Triangle } from '@view-model/models/common/basic';
import { ForeignObjectAutosize2 } from '@view-model/models/common/components/ForeignObjectAutosize';

type FillColor = 'gray' | 'white';

type Placement = 'top' | 'bottom';

type Props = {
    text: string;
    parentRect: Rect;
    color: FillColor;
    placement: Placement;
    maxWidth?: number;
    children: React.ReactNode;
};

const TAIL_WIDTH = 32;
const TAIL_HEIGHT = 28;
const TAIL_MARGIN = 8;

/**
 * 吹き出し型のラベルを表示する
 *
 * @param text ラベルの文言
 * @param parentRect 吹き出しをつける要素のRect
 * @param color 吹き出しの色
 * @param placement 吹き出しの位置。上か下
 * @maxWidth 吹き出しの最大幅
 * @param children 吹き出しをつける要素
 */
export const PopupTextView: React.FC<Props> = ({ text, parentRect, color, placement, maxWidth, children }: Props) => {
    const top = useMemo(
        () =>
            placement === 'top'
                ? new Point(-parentRect.width / 2, -parentRect.height - TAIL_MARGIN)
                : new Point(-parentRect.width / 2, TAIL_MARGIN),
        [placement, parentRect]
    );

    const tail = useMemo(
        () =>
            placement === 'top'
                ? new Triangle(top, top.addXY(-TAIL_WIDTH / 2, -TAIL_HEIGHT), top.addXY(TAIL_WIDTH / 2, -TAIL_HEIGHT))
                : new Triangle(top, top.addXY(-TAIL_WIDTH / 2, TAIL_HEIGHT), top.addXY(TAIL_WIDTH / 2, TAIL_HEIGHT)),
        [placement, top]
    );

    const [size, setSize] = useState(new Size(0, 0));
    const [textPosition, setTextPosition] = useState(new Point(0, 0));
    const handleSizeChange = useCallback(
        (size: Size) => {
            setSize(size);
            setTextPosition(
                placement === 'top'
                    ? parentRect
                          .topLeft()
                          .addXY(-size.width / 2, -parentRect.height / 2 - TAIL_HEIGHT - size.height - TAIL_MARGIN)
                    : parentRect.leftCenter().addXY(-size.width / 2, TAIL_HEIGHT + TAIL_MARGIN)
            );
        },
        [parentRect, placement]
    );

    // rc-resizer-observer のバージョンアップ (v1.0.0 → v1.4.0) を行った際に、
    // ビューモデル要素に対する操作メニューのポップアップがチラつくようになった。
    // see: https://github.com/levii/balus-app/pull/4534
    // この原因は定かではないが、 rc-resize-observer が内部で利用している処理が複雑化したことで、
    // レンダリング・リサイズ完了までに時間を要するようになったことが考えられる。
    //
    // このため、ポップアップの表示・非表示の切り替えを { hoveing && <div /> } ではなく、
    // visibility で行うことで正しい位置でのポップアップをあらかじめ描画しておくようにした。

    const [hovering, setHovering] = useState(false);

    const handleMouseOver = () => {
        setHovering(true);
    };

    const handleMouseLeave = () => {
        setHovering(false);
    };

    return (
        <>
            <g onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                {children}
            </g>
            <g style={{ visibility: hovering ? 'visible' : 'hidden' }}>
                <path
                    className={color === 'gray' ? 'fill-gray-300 stroke-gray-300' : 'fill-white stroke-white'}
                    d={tail.toSVGPath()}
                    transform={parentRect.getCenterPoint().toSVGTranslate()}
                    color={color}
                />
                <ForeignObjectAutosize2 onSizeChange={handleSizeChange} transform={textPosition.toSVGTranslate()}>
                    <div
                        className={
                            color === 'gray'
                                ? 'min-h-12 rounded border border-solid border-gray-300 bg-gray-300 px-4 py-1 text-[24px]'
                                : 'min-h-12 rounded border border-solid border-white bg-white px-4 py-1 text-[24px]'
                        }
                        style={
                            // maxWidthがpropsで指定されており、その90%の横幅に達したときには、固定幅で文字を折り返し表示する
                            maxWidth && size.width > maxWidth * 0.9
                                ? {
                                      width: `${maxWidth}px`,
                                      whiteSpace: 'initial',
                                  }
                                : {}
                        }
                    >
                        {text}
                    </div>
                </ForeignObjectAutosize2>
            </g>
        </>
    );
};

import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageSection } from '@framework/ui/elements/PageSection';
import { GroupEntity } from '@group/domain';
import { Loading } from '@framework/ui/atoms/Loading';
import { useAssignedWorkspaces, WorkspaceList } from '@workspace/ui/WorkspaceList';
import { Input } from '@framework/ui/atoms/Input';
import { useWorkspaceFilter } from './utils';
import { CreateWorkspaceButton } from './createWorkspace';
import { UserId } from '@schema-common/base';
import { useGroupMyMemberRole } from '@group/hooks/useGroupMyMemberRole';

type Props = {
    currentUserId: UserId;
    group: GroupEntity;
    showBackToHomeLink: boolean;
};

export const GroupContent: React.FC<Props> = ({ currentUserId, group, showBackToHomeLink }: Props) => {
    const workspaces = useAssignedWorkspaces(currentUserId, group.id);
    const isAdminRole = useGroupMyMemberRole(group.id) === 'admin';
    const [query, setQuery] = useState<string>('');

    const filteredWorkspaces = useWorkspaceFilter(
        useMemo(() => {
            if (workspaces === null) return null;
            // グループ画面のワークスペース一覧には、パーソナルワークスペースは表示しない
            return workspaces.filter((ws) => ws.isShared);
        }, [workspaces]),
        query
    );

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    return (
        <PageSection>
            {showBackToHomeLink && (
                <Link to={'/'} className="hover:text-blue-700 hover:underline">
                    グループ選択に戻る
                </Link>
            )}

            <div className="flex items-center justify-between pb-5">
                <div className="truncate text-2xl">ワークスペース一覧</div>

                <div className="flex flex-row items-center justify-end">
                    {/* グループメンバーのロールが "Admin" の場合にのみ、グループ編集、ワークスペース作成が可能。 */}
                    {isAdminRole && <CreateWorkspaceButton groupId={group.id} userId={currentUserId} />}
                    <div className="w-2/3 pl-4">
                        <Input
                            type="text"
                            placeholder="ワークスペース名で検索"
                            value={query}
                            onChange={handleOnChange}
                        />
                    </div>
                </div>
            </div>
            {filteredWorkspaces === null ? (
                <Loading />
            ) : filteredWorkspaces.length === 0 ? (
                <div className={'items-center p-20 text-center text-lg'}>
                    表示可能なワークスペースがありません。
                    <br />
                    <br />
                    グループに招待された場合には、管理者にワークスペースへの追加を依頼してください。
                </div>
            ) : (
                <WorkspaceList workspaces={filteredWorkspaces} />
            )}
        </PageSection>
    );
};

import firebase from 'firebase/compat/app';
import { FirebaseConfig } from '@config/FirebaseConfig';
import { RefBuilder } from '@framework/repository';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

if (firebase.apps.length == 0) {
    // デフォルトRTDBに接続する FirebaseApp の初期化
    firebase.initializeApp(FirebaseConfig);
    RefBuilder.bindDatabase(firebase.app().database());
}

import { ICommand } from '@model-framework/command';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { LinkEntityOperation } from '../adapter';
import { LinkEntity } from '../domain';

export class LinkMultiDeleteCommand implements ICommand {
    private readonly links: LinkEntity[];

    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly entityOperation: LinkEntityOperation,
        links: LinkEntity[]
    ) {
        this.links = links.map((link) => link.clone());
    }

    do(): void {
        this.entityOperation.removeMulti(this.links);
    }

    undo(): void {
        this.entityOperation.saveMulti(this.links);
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        const linkKeys = this.links.map((link) => link.key);
        return !LinkEntityOperation.getLinks(linkKeys, this.viewModel);
    }

    async canRedo(): Promise<boolean> {
        const linkKeys = this.links.map((link) => link.key);
        const currentLinks = LinkEntityOperation.getLinks(linkKeys, this.viewModel);
        if (!currentLinks) return false;
        const sortedLinks = this.links.sort();
        // LinksとcurrentLinksが同じ順番で同じリンクを持っているかを確認
        // すべて一致していれば空配列が返る
        const unequalLinks = currentLinks.sort().filter((link, index) => {
            return !link.isEqual(sortedLinks[index]);
        });
        return !unequalLinks.length;
    }
}

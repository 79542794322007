import { GroupEntity } from '@group/domain';

import { CenteredContent } from '@user/pages/HomePage/CenteredContent';
import { GroupList } from '@user/pages/HomePage/GroupList';
import { Redirect } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';

const GroupNotFoundMessage: React.FC<unknown> = () => {
    return (
        <div className="py-2">
            Balus 2.0 への招待メールに記載されたURLから登録をお願いします。
            <br />
            詳しくは、 所属組織の Balus 導入担当者、または、
            <a href="https://levii.co.jp/contact/" target="_blank" rel="noreferrer" className="text-blue-700 underline">
                サポート窓口
            </a>
            までお問い合わせください。
        </div>
    );
};

type ContentProps = {
    title: string;
    children: React.ReactNode;
};

const Content: React.FC<ContentProps> = ({ title, children }: ContentProps) => {
    return (
        <div
            className="rounded border border-gray-500 bg-white shadow"
            style={{
                width: '600px',
                minHeight: '300px',
            }}
        >
            <div className="border-b border-gray-500 px-4 py-2 text-xl">{title}</div>
            <div
                className="overflow-y-auto p-4"
                style={{
                    maxHeight: '60vh',
                }}
            >
                {children}
            </div>
        </div>
    );
};

type Props = {
    groups: GroupEntity[];
};

export const GroupSelection: React.FC<Props> = ({ groups }: Props) => {
    return (
        <div className="size-full bg-gray-100">
            <CenteredContent>
                {groups.length == 0 ? (
                    <Content title={'所属するグループが見つかりませんでした'}>
                        <GroupNotFoundMessage />
                    </Content>
                ) : groups.length == 1 ? (
                    <Redirect to={UserPagePathBuilder.groupPage(groups[0].id)} />
                ) : (
                    <Content title={'グループを選択してください'}>
                        <GroupList groups={groups} />
                    </Content>
                )}
            </CenteredContent>
        </div>
    );
};

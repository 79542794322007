import { useEffect } from 'react';
import { DataSnapshot, RTDBPath, RefBuilder } from '@framework/repository';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

export const CheckIp: React.FC = () => {
    useEffect(() => {
        const ref = RefBuilder.ref(RTDBPath.Firebase.connectedPath());
        const callback = (snapshot: DataSnapshot) => {
            const isOnline = !!snapshot.val();

            if (isOnline) {
                FunctionsUserActions.checkIp();
            }
        };

        ref.on('value', callback);
        return () => {
            ref.off('value', callback);
        };
    }, []);

    return <></>;
};

import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';
import { FirebaseAnalyticsOperation } from '@framework/firebase/analytics';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

export const LogoutPage: React.FC<unknown> = () => {
    const history = useHistory();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        // RTDB上に永続化された認証セッション情報を無効化した後に、Firebase Authentication のサインアウト処理を行う。
        FunctionsUserActions.signOut()
            .then(() => {
                return FirebaseAuthenticationClient.signOut();
            })
            .then(() => {
                return FirebaseAnalyticsOperation.logEvent('logout');
            })
            .then(() => {
                history.push(searchParams.get('requestPath') || '/');
            });
    }, [history]);

    return (
        <div className="fixed left-0 top-0 z-50 flex h-screen w-screen flex-col items-center justify-center">
            <FontAwesomeIcon icon={faSpinner} className="fa-spin text-4xl text-brand" />
            <div className="pt-4 text-center text-2xl font-bold text-brand">ログアウトしています...</div>
            <Link to="/" className="pt-8 text-xl text-blue-500 hover:text-blue-900 hover:underline">
                ホームに戻る
            </Link>
        </div>
    );
};

import { useEffect, useMemo, useState } from 'react';
import { isWorkspace, Workspace } from '@workspace/domain/workspace';
import { GroupId, UserId, WorkspaceId, WorkspaceKeyString } from '@schema-common/base';
import { KeysRepository, ObjectRepository, RTDBPath } from '@framework/repository';
import { WorkspaceJSON } from '@schema-app/workspaces/{workspaceKey}/WorkspaceJSON';

type WorkspacesRecord = Record<WorkspaceId, Workspace>;

/**
 * 指定のユーザが、指定のグループ配下で所属するワークスペース・エンティティの一覧を返す。
 * 一覧を未取得・取得中には null を返し、取得完了後にはエンティティの配列を返す。
 * ワークスペースの変更を継続的にリスンする。
 * @param userId
 * @param groupId
 * @returns
 */
export const useAssignedWorkspaces = (userId: UserId | null, groupId: GroupId): Workspace[] | null => {
    const [workspaces, setWorkspaces] = useState<WorkspacesRecord | null>(null);

    useEffect(() => {
        if (!userId) return;

        const idsRepo = new KeysRepository<WorkspaceKeyString>(
            RTDBPath.Workspace.assignedGroupWorkspaceIndexPath(userId, groupId)
        );
        const entityRepos: Record<WorkspaceId, ObjectRepository<WorkspaceJSON, Workspace>> = {};

        // 初回は一括で取得してワークスペースをまとめて設定する
        idsRepo.get().then((workspaceIds) => {
            Promise.all(
                workspaceIds.map(async (id) => {
                    const repo = new ObjectRepository(Workspace, RTDBPath.Workspace.workspacePath(id));
                    return repo.get();
                })
            ).then((result) => {
                const workspaces = result.filter(isWorkspace);

                // 初回一括取得の内容をまとめて state に反映する
                setWorkspaces(
                    workspaces.reduce((record, workspace) => {
                        return { ...record, [workspace.id]: workspace };
                    }, {} as WorkspacesRecord)
                );

                // 一括取得を完了した後に、idの追加削除と、個別のエンティティの変更を監視する
                idsRepo.addListener(
                    (id) => {
                        // idが追加されたとき
                        if (entityRepos[id]) {
                            return; // エンティティのレポジトリが登録済みならば、既に監視済みなので何もしない
                        }

                        entityRepos[id] = new ObjectRepository(Workspace, RTDBPath.Workspace.workspacePath(id));
                        entityRepos[id].addListener((workspace) => {
                            setWorkspaces((prev) => {
                                // WorkspaceEntity が既に state に登録済みのものと一致していれば、変更前の state をそのまま使う
                                if (prev && workspace && prev[id]?.isEqual(workspace)) {
                                    return prev;
                                }

                                if (workspace) {
                                    return { ...(prev || {}), [id]: workspace };
                                } else {
                                    const { [id]: _removed, ...rest } = prev || {};
                                    return { ...rest };
                                }
                            });
                        });
                    },
                    (id) => {
                        // idが削除されたとき
                        entityRepos[id]?.removeListener();
                        setWorkspaces((prev) => {
                            const { [id]: _removed, ...rest } = prev || {};
                            return { ...rest };
                        });
                    }
                );
            });
        });

        // クリーンアップ処理
        return () => {
            idsRepo.removeListener();
            Object.values(entityRepos).map((repo) => repo.removeListener());
        };
    }, [groupId, userId]);

    return useMemo(() => {
        if (workspaces === null) return null;
        return Object.values(workspaces).filter(isWorkspace).sort(Workspace.compare);
    }, [workspaces]);
};

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DarkTooltip } from '@framework/ui/atoms/Tooltip';

export const ReadonlyIcon: React.FC<unknown> = () => {
    return (
        <DarkTooltip tooltip="閲覧のみ | View only" placement="bottom">
            <div className={'flex size-10 cursor-pointer items-center justify-center text-gray-400'}>
                <FontAwesomeIcon icon={faEye} />
            </div>
        </DarkTooltip>
    );
};

/** ノード作成イベントの起点となるDOM（ビュー領域）とイベントハンドラを結び付けるためのヘルパークラス
 *
 *  上に重なるDOMがあったとき、その重なるDOMのそれぞれでイベントのstopPropagationしなくても誤って
 *  ノード作成が走らないようにするため。
 */
export class NodeCreatableTarget {
    // 対象となるDOMにセットするクラス名
    public static readonly targetClassName = 'node-creatable';

    /** 対象かどうかを返す。
     * @param target Event.target
     * https://developer.mozilla.org/ja/docs/Web/API/Event/target
     */
    public static isTarget(target: HTMLElement): boolean {
        return target.classList.contains(NodeCreatableTarget.targetClassName);
    }
}

import { DBPath } from '../RTDBPath';
import { DataSnapshot, RefBuilder, Reference } from '../RefBuilder';

export interface ISerializableObject<DumpType> {
    dump(): DumpType;
}

export interface ISerializableObjectClass<DumpType, ObjectType extends ISerializableObject<DumpType>> {
    load(dump: DumpType): ObjectType;
}

export class ObjectRepository<DumpType, ObjectType extends ISerializableObject<DumpType>> {
    private callback: ((value: DataSnapshot) => void) | undefined = undefined;
    private readonly ref: Reference;

    constructor(
        private readonly objectClass: ISerializableObjectClass<DumpType, ObjectType>,
        path: DBPath
    ) {
        this.ref = RefBuilder.ref(path);
    }

    addListener(callback: (value: ObjectType | null) => void): void {
        this.callback = (snapshot) => {
            const j = snapshot.val() as DumpType | null;
            const obj = j ? this.objectClass.load(j) : null;
            callback(obj);
        };

        this.ref.on('value', this.callback);
    }

    removeListener(): void {
        // この Repository でリスン開始したリスナーだけを対象に、リスン解除する。
        if (this.callback) {
            this.ref.off('value', this.callback);
            this.callback = undefined;
        }
    }

    async save(value: ObjectType): Promise<void> {
        await this.ref.set(value.dump());
    }

    async delete(): Promise<void> {
        await this.ref.set(null);
    }

    async get(): Promise<ObjectType | null> {
        const snapshot = await this.ref.once('value');
        const j = snapshot.val() as DumpType | null;

        return j && this.objectClass.load(j);
    }

    /**
     * RTDBとの接続が切れたときに、この Repository でリスンしている対象パスの値を削除する Presence を登録する。
     *
     * 解説: https://firebase.google.com/docs/database/web/offline-capabilities#section-presence
     * 使用例:
     *   // 以下のようなコードを記述すると、RTDBとの接続が切れたときに path に書き込まれたデータが削除されます。
     *   const repo = new ObjectRepository(Example, path);
     *   await repo.onDisconnectRemove();
     *   await repo.save(example);
     */
    async onDisconnectRemove(): Promise<void> {
        await this.ref.onDisconnect().remove();
    }
}

import { Button } from '@framework/ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

type ExternalLinkProps = {
    href: string;
    children: React.ReactNode;
};
const ExternalLink: React.FC<ExternalLinkProps> = ({ href, children }: ExternalLinkProps) => {
    return (
        <a href={href} target="_blank" rel="noreferrer" className="text-blue-700 underline hover:text-blue-800">
            {children} <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
    );
};

type Props = {
    onAgree(): void;
};

export const EulaAgreementFooter: React.FC<Props> = ({ onAgree }: Props) => {
    return (
        <div className="pointer-events-auto mx-auto flex w-4/5 rounded-t-xl bg-brand-light p-4">
            <div className="flex grow flex-col">
                <div className="py-2">
                    サービスの利用を続けた場合、「
                    <ExternalLink href="https://levii.co.jp/legal/terms-of-service/">利用規約</ExternalLink>
                    」「
                    <ExternalLink href="https://levii.co.jp/legal/privacy-policy/">プライバシーポリシー</ExternalLink>
                    」に同意したことになります。
                    <br />
                    これらの情報には、画面右上ユーザアイコンをクリックして、「ヘルプ」からいつでもアクセスすることができます。
                </div>
                <div className="py-2">
                    By continuing to use the service, you agree to our Terms of Service and Privacy Policy. You can
                    always read these terms in the Help page.
                </div>
            </div>
            <div className="my-auto px-8">
                <Button color="brand" onClick={onAgree} className="w-56">
                    同意する | I Agree
                </Button>
            </div>
        </div>
    );
};

import { useCallback } from 'react';
import { usePopupRef } from '@framework/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPaste, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCopy, faTrashAlt } from '@fortawesome/free-regular-svg-icons';

type Props = {
    onEdit(): void;
    onDelete(): void;
    onAddViewModel(): void;
    onPasteViewModel(): void;
    onDuplicateDirectory(): void;
};

export const MenuButtons: React.FC<Props> = ({
    onDelete,
    onEdit,
    onAddViewModel,
    onPasteViewModel,
    onDuplicateDirectory,
}: Props) => {
    const { isOpen, popupRef, handleClose, handleOpen } = usePopupRef<HTMLDivElement>(false);

    const handleDelete = () => {
        onDelete();
        handleClose();
    };

    const handleAddViewModel = useCallback(() => {
        onAddViewModel();
        handleClose();
    }, [onAddViewModel, handleClose]);

    const handleDuplicateDirectory = () => {
        onDuplicateDirectory();
        handleClose();
    };

    const handlePaste = () => {
        onPasteViewModel();
        handleClose();
    };

    return (
        <div className="relative">
            <div className="inline-flex text-sm">
                <button
                    className="whitespace-nowrap rounded-l bg-gray-500 py-1 pl-3 pr-2 font-bold text-white hover:bg-gray-700"
                    onClick={onEdit}
                >
                    <FontAwesomeIcon icon={faPen} className="mr-1" />
                    編集
                </button>
                <button
                    className="rounded-r bg-gray-500 px-2 py-1 font-bold text-white hover:bg-gray-700"
                    onClick={handleOpen}
                >
                    <FontAwesomeIcon icon={faEllipsisV} />
                </button>
            </div>

            <div
                className={'absolute z-10 w-56 rounded-lg bg-white py-1 shadow-lg ' + (isOpen ? 'block' : 'hidden')}
                ref={popupRef}
            >
                <button
                    className="block w-full px-2 py-1 text-left text-gray-800 hover:bg-indigo-500 hover:text-white"
                    onClick={handleAddViewModel}
                >
                    <span className="inline-block w-6 text-center">
                        <FontAwesomeIcon icon={faPlus} />
                    </span>
                    新規ビューモデル
                </button>
                <button
                    className="block w-full px-2 py-1 text-left text-gray-800 hover:bg-indigo-500 hover:text-white"
                    onClick={handlePaste}
                >
                    <span className="inline-block w-6 text-center">
                        <FontAwesomeIcon icon={faPaste} />
                    </span>
                    ビューモデルをペースト
                </button>
                <button
                    className="block w-full px-2 py-1 text-left text-gray-800 hover:bg-indigo-500 hover:text-white"
                    onClick={handleDuplicateDirectory}
                >
                    <span className="inline-block w-6 text-center">
                        <FontAwesomeIcon icon={faCopy} />
                    </span>
                    フォルダを複製
                </button>
                <button
                    className="block w-full px-2 py-1 text-left text-red-700 hover:bg-indigo-500 hover:text-white"
                    onClick={handleDelete}
                >
                    <span className="inline-block w-6 text-center">
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                    削除
                </button>
            </div>
        </div>
    );
};

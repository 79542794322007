import { ICommand } from '@model-framework/command';
import { LinkKey } from '@view-model/domain/key';
import { LinkEntity, LinkMultiplicity } from '../domain';
import { LinkEntityOperation } from '../adapter';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class LinkMultiplicityUpdateCommand implements ICommand {
    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly entityOperation: LinkEntityOperation,
        private readonly key: LinkKey,
        private readonly fromValue: LinkMultiplicity,
        private readonly toValue: LinkMultiplicity
    ) {}

    do(): void {
        const link = LinkEntityOperation.getLink(this.key, this.viewModel);
        if (!link) return;

        this.entityOperation.save(link.withMultiplicity(this.toValue));
    }

    undo(): void {
        this.changeValue(this.toValue, this.fromValue);
    }

    redo(): void {
        this.changeValue(this.fromValue, this.toValue);
    }

    async canUndo(): Promise<boolean> {
        return !!this.retrieveLink(this.toValue);
    }

    async canRedo(): Promise<boolean> {
        return !!this.retrieveLink(this.fromValue);
    }

    private changeValue(fromValue: LinkMultiplicity, toValue: LinkMultiplicity): void {
        const link = this.retrieveLink(fromValue);
        if (!link) return;

        this.entityOperation.save(link.withMultiplicity(toValue));
    }

    private retrieveLink(fromValue: LinkMultiplicity): LinkEntity | null {
        return LinkEntityOperation.findLink({
            key: this.key,
            viewModel: this.viewModel,
            isTarget: (link: LinkEntity) => link.multiplicity.isEquals(fromValue),
        });
    }
}

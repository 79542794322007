export class ViewModelName {
    constructor(public readonly value: string) {}

    toString(): string {
        return this.value;
    }

    dump(): string {
        return this.toString();
    }

    static load(dump: string): ViewModelName {
        return new ViewModelName(dump);
    }

    isValid(): boolean {
        return this.value.trim() !== '';
    }

    static compare(a: ViewModelName, b: ViewModelName): number {
        return a.compareTo(b);
    }

    compareTo(other: ViewModelName): number {
        const name = this.value.toLowerCase();
        const otherName = other.value.toLowerCase();
        return name.localeCompare(otherName);
    }

    includesMulti(keywords: string[]): boolean {
        return keywords.some((keyword) => {
            if (!keyword) return false;
            return this.value.toLowerCase().includes(keyword.toLowerCase());
        });
    }
}

export type AuthSessionId = string;
export type AnnouncementId = string;
export type ClientId = string;
export type GroupId = string;
export type JijiId = string;
export type OperatorUserId = string;
export type UserId = string;
export type GroupMemberInvitationId = string;
export type InvitationMailDataId = string;
export type ViewId = string;
export type ViewModelId = string;
export type WorkspaceId = string;
export type FolderId = string;
export type ModelId = string;
export type NodeId = string;
export type StickyZoneId = string;
export type ElementId = NodeId | StickyZoneId;
export type LinkId = string;
export type ModelCommentThreadId = string;
export type ModelCommentId = string;
export type DescriptionPanelId = string;
export type ConsistencyLinkId = string;
export type ViewModelAssetId = string;
export type MigrationJobId = string;
export type MigrationTaskId = string;

/**
 * RTDBのパス階層に指定するidとして適切な場合にtrueを返します。
 * パス文字列に含めることのできない以下の記号に加えて、階層区切り文字 "/" と空白文字 " " も利用禁止とする。
 * Paths must be non-empty strings and can't contain ".", "#", "$", "[", or "]"
 * @param id
 * @returns
 */
export function isValidId(id: string): boolean {
    const pattern = /^[^.#$[\] /]+$/;
    return pattern.test(id);
}

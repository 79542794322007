import { MIN_VIEW_HEIGHT, MIN_VIEW_WIDTH, VIEW_GRID_SIZE } from '@view-model/ui/components/View/constants';
import { Size } from '@view-model/models/common/basic';

type StickyZoneSizeJSON = {
    width: number;
    height: number;
};

export class StickyZoneSize {
    readonly width: number;
    readonly height: number;

    public static DEFAULT_WIDTH = 256 * 5;
    public static DEFAULT_HEIGHT = 256 * 3;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;

        if (this.width <= 0 || this.height <= 0) {
            throw new Error(`Invalid StickyZoneSize (width=${width}, height=${height})`);
        }
    }

    dump(): StickyZoneSizeJSON {
        return { width: this.width, height: this.height };
    }

    static load({ width, height }: StickyZoneSizeJSON): StickyZoneSize {
        return new this(width, height);
    }

    static buildNew(): StickyZoneSize {
        return new this(this.DEFAULT_WIDTH, this.DEFAULT_HEIGHT);
    }

    add(dWidth: number, dHeight: number): StickyZoneSize {
        return new StickyZoneSize(this.width + dWidth, this.height + dHeight);
    }

    isEqual(other: StickyZoneSize): boolean {
        return other instanceof StickyZoneSize && this.width === other.width && this.height === other.height;
    }

    /**
     * Zoneのサイズ以上で一番近い、かつViewとして設定可能なサイズ
     */
    getClosestViewSize(): Size {
        return new Size(
            this.calcClosest(this.width, MIN_VIEW_WIDTH, VIEW_GRID_SIZE),
            this.calcClosest(this.height, MIN_VIEW_HEIGHT, VIEW_GRID_SIZE)
        );
    }

    private calcClosest(value: number, minValue: number, unitValue: number): number {
        if (value < minValue) {
            return minValue;
        }
        const remainder = value % unitValue;
        if (remainder === 0) {
            return value;
        }
        return value - remainder + unitValue;
    }
}

import { useCallback } from 'react';
import { useActionLogSender } from '@framework/action-log';
import { ViewModelOperationName } from './ViewModelOperationName';
import { ActionEventLogJSON } from '@schema-action-log/action-event-logs/ActionEventLogJSON';

export type ViewModelOperationLogSender = (
    operationName: ViewModelOperationName,
    extraEventParams: Partial<{
        cause: string;
        clipboardPayloadId: string;
        commandManagerMethod: string;
        commandRedoStackLength: number;
        commandUndoStackLength: number;
        linkId: string;
        mlAPIName: string;
        mlAPIPayloadId: string;
        modelId: string;
        modelType: string;
        nodeId: string;
        panelId: string;
        viewId: string;
        viewName: string;
        zoneId: string;
    }>
) => Promise<void>;

/**
 * ビューモデルに対する操作時の行動イベントログを送信する関数を返すカスタムフック。
 */
export const useViewModelOperationLogSender = (
    extraEventParams?: ActionEventLogJSON['eventParams']
): ViewModelOperationLogSender => {
    const actionLogSender = useActionLogSender();

    const sender = useCallback(
        async (operationName: ViewModelOperationName, extraSendEventParams?: ActionEventLogJSON['eventParams']) => {
            await actionLogSender('view_model:operation', {
                operationName,
                // カスタムフック呼び出し時に追加設定されたイベントパラメータ
                ...extraEventParams,
                // ログ記録時に追加設定されたイベントパラメータ
                ...extraSendEventParams,
            });
        },
        [actionLogSender, extraEventParams]
    );

    return sender;
};

/**
 * 線の接続点を表す。接続位置とマーク（矢印）の角度を持つ。
 */
import { Angle, Point } from '@view-model/models/common/basic';

export class LineEdge {
    constructor(
        public readonly position: Point,
        public readonly markAngle: Angle
    ) {}

    toSVGTransform(): string {
        return `${this.position.toSVGTranslate()} ${this.markAngle.toSVGRotate()}`;
    }
}

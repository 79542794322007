/**
 * 1次元の数直線範囲
 */
export class Range {
    constructor(
        private readonly lower: number,
        private readonly upper: number
    ) {}

    /**
     * 値が範囲（終端含む）に含まれるかどうかを返します。
     * @param value
     */
    includes(value: number): boolean {
        return value >= this.lower && value <= this.upper;
    }
}

import { Point, Rect, Size } from '@view-model/models/common/basic';
import { StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { ElementDescriptionCalloutPositionAdjuster } from './ElementDescriptionCalloutPositionAdjuster';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { ElementDescriptionTarget } from '../domain/ElementDescriptionTarget';
import { StickyZone } from '../../StickyZoneView/domain';

/**
 * 付箋に対してくっつくレイアウトを持つ吹き出しの位置、サイズを管理するクラス
 */
export class ElementDescriptionLayout {
    private static readonly InitialCalloutWidth = ModelLayout.GridSize * 8; // 吹き出し矩形の初期幅
    private static readonly InitialCalloutHeight = ModelLayout.GridSize * 4; // 吹き出し矩形の初期高さ
    private static readonly SpaceMargin = ModelLayout.GridSize; // 付箋の周りの空白スペース
    private static readonly TailMargin = ModelLayout.GridSize; // 吹き出しのしっぽ描画領域を確保するスペース

    /**
     * 吹き出し矩形の初期サイズを返す
     */
    static initialSize(): Size {
        return new Size(this.InitialCalloutWidth, this.InitialCalloutHeight);
    }

    /**
     * 付箋に対する初期位置（相対位置）を返す
     */
    public static initialRelativePosition(target: ElementDescriptionTarget): Point {
        // 初期位置: x=右寄せ, y=中央寄せ
        const { width, height } = this.getElementSize(target);

        return new Point(width + ModelLayout.GridSize * 2, (height - this.InitialCalloutHeight) * 0.5);
    }

    private static getElementSize(target: ElementDescriptionTarget): Size {
        switch (target) {
            case 'Node':
                return StickyNode.size();
            case 'Zone':
                return StickyZone.size();
            default:
                throw new Error(`Invalid target: ${target}`);
        }
    }

    /**
     * 付箋矩形を元に吹き出しのしっぽがターゲットとする（接する）矩形を返します。
     * @param rect
     */
    static tailTargetRectFor(rect: Rect): Rect {
        return rect.applyMarginKeepingCenter(this.SpaceMargin);
    }

    /**
     * 吹き出しの位置を調整するオブジェクトを返す
     * @param position 吹き出しの位置（絶対座標）
     * @param size 吹き出しのサイズ
     */
    static createPositionAdjuster(position: Point, size: Size): ElementDescriptionCalloutPositionAdjuster {
        const { SpaceMargin, TailMargin } = ElementDescriptionLayout;

        return new ElementDescriptionCalloutPositionAdjuster(new Rect(position, size), SpaceMargin + TailMargin);
    }
}

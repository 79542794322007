import { classNames } from '@framework/utils';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Color = 'red' | 'black';

const colorMap: Record<Color, string> = {
    red: 'text-red-700',
    black: 'text-gray-700',
};

type Props = {
    icon: IconDefinition;
    title: string;
    color?: Color;
    onClick(): void;
};

export const MenuButton: React.FC<Props> = ({ icon, title, color = 'black', onClick }: Props) => {
    return (
        <button
            className={classNames(
                'flex cursor-pointer px-8 py-4 hover:bg-gray-200 focus:outline-none',
                colorMap[color]
            )}
            onClick={onClick}
        >
            <div className="fa-fw inline-block w-16 rounded">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="inline-block pl-4">{title}</div>
        </button>
    );
};

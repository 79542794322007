import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { BuildInfo } from '@config/build-info';
import { getClientId } from '@framework/app';
import { useCurrentUserId } from '@framework/auth';
import { useCurrentSessionId } from '@framework/auth/AuthContext';
import { DataSnapshot, RTDBPath, RefBuilder, ServerValue } from '@framework/repository';
import { ActiveClientSessionJSON } from '@schema-app/user/active-client-sessions/ActiveClientSessionJSON';

/**
 * アクティブなクライアント接続セッション情報を永続化します。
 * このセッション情報は、ブラウザのタブごとに生成されます。
 */
export const PersistActiveClientSession: React.FC = () => {
    const ref = useRef(false);
    const currentUserId = useCurrentUserId();
    const currentSessionId = useCurrentSessionId();
    const clientId = getClientId();

    useEffect(() => {
        // オンライン状態になったときにアクティブクライアントセッションを記録する
        const connectedRef = RefBuilder.ref(RTDBPath.Firebase.connectedPath());
        const sessionRef = RefBuilder.ref(RTDBPath.User.activeClientSessionPath(clientId));

        const session: ActiveClientSessionJSON = {
            clientId,
            startAt: 0,
            updatedAt: 0,
            locationHref: window.location.href,
            sessionId: currentSessionId,
            userId: currentUserId,
            version: isNaN(BuildInfo.version) ? -1 : BuildInfo.version,
        };

        // RTDBとの接続状態が変化した時に実行されるコールバック関数
        const onConnectionChanged = async (snapshot: DataSnapshot) => {
            if (!snapshot.val()) return; // オフライン状態のときは何もしない

            // オフライン状態になった時にセッション削除を予約したうえで、セッション情報を保存する。
            await sessionRef.onDisconnect().remove();
            await sessionRef.set({
                ...session,
                startAt: ServerValue.TIMESTAMP,
                updatedAt: ServerValue.TIMESTAMP,
            });
        };

        // 最初にセッションを永続化してから、接続状態の監視を開始する
        sessionRef
            .set({
                ...session,
                startAt: ServerValue.TIMESTAMP,
                updatedAt: ServerValue.TIMESTAMP,
            })
            .then(() => {
                ref.current = true;
            });

        connectedRef.on('value', onConnectionChanged);
        return () => {
            connectedRef.off('value', onConnectionChanged);
            sessionRef.remove();
            ref.current = false;
        };
    }, [clientId, currentSessionId, currentUserId]);

    const location = useLocation();

    useEffect(() => {
        // 既にセッション情報を削除済みならば location 更新を行わない
        if (!ref.current) return;

        const sessionRef = RefBuilder.ref(RTDBPath.User.activeClientSessionPath(clientId));
        sessionRef.update({
            locationHref: `${location.pathname}${location.search}${location.hash}`,
            updatedAt: ServerValue.TIMESTAMP,
        });
    }, [clientId, location]);

    return <></>;
};

import { GridIndex, GridRange } from '@view-model/application/operation';

export class Grid {
    private readonly gridSize: number;

    constructor(gridSize: number) {
        this.gridSize = gridSize;
    }

    toGridRange(low: number, high: number): GridRange {
        // 指定された範囲の内側のグリッドに寄せる
        const lowIndex = Math.ceil(low / this.gridSize);
        const highIndex = Math.floor(high / this.gridSize);

        return new GridRange(lowIndex, highIndex);
    }

    toPosition(gridIndex: GridIndex): number {
        return gridIndex * this.gridSize;
    }
}

import { ICommand } from '@model-framework/command';
import { LinkStyleSet } from '../domain';
import { LinkRepository } from '../domain/LinkRepository';

export class LinkStyleUpdateMultiCommand implements ICommand {
    constructor(
        private readonly from: LinkStyleSet,
        private readonly to: LinkStyleSet,
        private readonly repository: LinkRepository
    ) {}

    do(): void {
        this.to.saveTo(this.repository).then();
    }

    undo(): void {
        this.from.saveTo(this.repository).then();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return this.to.isSameOn(this.repository);
    }

    async canRedo(): Promise<boolean> {
        return this.from.isSameOn(this.repository);
    }
}

import { ChangeEvent } from 'react';

type Props = {
    isLocked: boolean;
    onChange(isLocked: boolean): void;
    disabled: boolean;
};

export const LockSetting: React.FC<Props> = ({ isLocked, onChange, disabled }: Props) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <div>
            <p className="pb-1">
                このビューモデルの編集ができないようにロックします。ロックと解除は、このビューモデルを編集する権限を持っていれば誰でもできます。
            </p>
            <p className="pb-2">
                This will lock the view model to prevent editing. Anyone with the authority to edit this ViewModel can
                lock and unlock it.
            </p>
            <div className="flex items-center p-2 hover:bg-gray-200">
                <label className="flex grow cursor-pointer flex-row items-center">
                    <input
                        type="checkbox"
                        className="form-checkbox size-4 cursor-pointer border-brand"
                        checked={isLocked}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                    <p className="grow px-2">ビューモデルをロックする</p>
                </label>
            </div>
        </div>
    );
};

const pathSplitter = (
    pathname: string
): {
    isLegacyPath: boolean;
    isCurrentPath: boolean;
    workspaceId: string;
    viewModelId: string;
} => {
    // pathname先頭は'/'区切りで始まり、path[0] は空文字になるのでスキップする
    // https://developer.mozilla.org/ja/docs/Web/API/URL/pathname
    const [, path1, firstId, path2, secondId] = pathname.split('/');

    // 旧パス
    if (path1 === 'workspaces' && !!firstId && path2 === 'view-models' && !!secondId) {
        return {
            isLegacyPath: true,
            isCurrentPath: false,
            workspaceId: firstId,
            viewModelId: secondId,
        };
    }

    // 新パス
    if (path1 === 'view-models' && !!firstId) {
        return {
            isLegacyPath: false,
            isCurrentPath: true,
            workspaceId: '',
            viewModelId: firstId,
        };
    }

    return {
        isLegacyPath: false,
        isCurrentPath: false,
        workspaceId: '',
        viewModelId: '',
    };
};

const checkDomainSame = (url: URL) => {
    const currentOrigin = window.location.origin;
    return currentOrigin === url.origin;
};

const BALUS_SITE = ['https://balus.dev', 'https://balus.app'];

export class ViewModelURL {
    constructor(
        private readonly viewModelId: string,
        private readonly params: URLSearchParams
    ) {}

    static fromURLString(urlStr: string): ViewModelURL | null {
        try {
            // 有効なURL形式じゃない場合は例外を投げます。
            // https://developer.mozilla.org/ja/docs/Web/API/URL/URL
            const url = new URL(urlStr);
            const { isLegacyPath, isCurrentPath, viewModelId } = pathSplitter(url.pathname);

            const isSameDomain = checkDomainSame(url);
            const isBalus = BALUS_SITE.includes(url.origin);

            if (!isSameDomain && !isBalus) {
                return null;
            }

            if (isLegacyPath || isCurrentPath) {
                return new this(viewModelId, url.searchParams);
            }
        } catch {
            return null;
        }

        return null;
    }

    get path(): string {
        const { viewModelId } = this;

        // 現在のビューモデルを新パスで表示している場合、
        // 同一ビューモデル内にあるビューへの移動では画面のリロードを挟みたくないので、
        // ノードのURLに旧パスが設定されている場合でも新パスを返すようにする
        return `/view-models/${viewModelId}`;
    }

    get queryString(): string {
        return this.params.toString();
    }

    get href(): string {
        const { path, queryString } = this;

        return queryString !== '' ? `${path}?${queryString}` : path;
    }
}

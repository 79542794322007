import { ModelKey } from '@view-model/domain/key';
import { ModelCommentContents } from './ModelCommentContents';
import { IPositionSetRepository, PositionSet, PositionSetRepository } from '@view-model/models/common/PositionSet';
import { ModelCommentThreadRepository } from './ModelCommentThreadRepository';
import { RTDBPath } from '@framework/repository';
import { ModelId, ViewModelId } from '@schema-common/base';

type Repositories = {
    threadRepository: ModelCommentThreadRepository;
    positionSetRepository: IPositionSetRepository;
};

export class ModelCommentCascadeRepository {
    constructor(private readonly viewModelId: ViewModelId) {}

    async saveContents(contents: ModelCommentContents): Promise<void> {
        const { threadRepository, positionSetRepository } = this.createRepositories(contents.modelKey.id);
        const { threads, positions } = contents.contents();

        await Promise.all([threadRepository.saveMany(threads.list()), positionSetRepository.saveMany(positions)]);
    }

    async loadContents(modelKey: ModelKey): Promise<ModelCommentContents> {
        const { threadRepository, positionSetRepository } = this.createRepositories(modelKey.id);

        const [threads, positions] = await Promise.all([
            await threadRepository.load(),
            await positionSetRepository.loadAll(),
        ]);

        return new ModelCommentContents(modelKey, threads, positions || new PositionSet());
    }

    private createRepositories(modelId: ModelId): Repositories {
        const threadRepository = new ModelCommentThreadRepository(this.viewModelId, modelId);

        const positionSetRepository = new PositionSetRepository(
            RTDBPath.Comment.positionsPath(this.viewModelId, modelId)
        );

        return { threadRepository, positionSetRepository };
    }
}

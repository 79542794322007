import { useCallback, useState } from 'react';

type OpenModalFunc = () => void;
type CloseModalFunc = () => void;

export const useOpenCloseState = (initialValue = false): [boolean, OpenModalFunc, CloseModalFunc] => {
    const [isOpen, setIsOpen] = useState(initialValue);

    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);

    return [isOpen, open, close];
};

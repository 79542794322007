import { ISerializableObject, ISerializableObjectClass } from './ObjectRepository';
import { useCallback, useEffect, useState } from 'react';
import { useObjectRepository } from './useObjectRepository';
import { DBPath } from '../RTDBPath';

type Setter<U> = (value: U | null) => void;

/**
 * RTDB上に永続化されているオブジェクトと、それを更新するためのコールバック関数を返します。
 * 値の取得が完了するまでの間は undefined の値を返します。
 *
 * @param repository 対象オブジェクトを操作するRepository
 * @returns {Array} オブジェクトと、それを更新するためのコールバック関数を返す
 * @returns {U | null | undefined} 永続化されているオブジェクト。永続値が無いときにはnullを返す。値の取得途中にはundefinedを返す。
 * @returns {function(value: U): void} 値を更新するためのコールバック関数
 */
export const usePersistedObject = <T, U extends ISerializableObject<T>>(
    objectClass: ISerializableObjectClass<T, U>,
    path: DBPath
): Readonly<[U | null | undefined, Setter<U>]> => {
    const repository = useObjectRepository(objectClass, path);
    const [value, setValue] = useState<U | null | undefined>(undefined);

    useEffect(() => {
        setValue(undefined);

        repository.addListener((value: U | null) => {
            setValue(value);
        });
        return () => repository.removeListener();
    }, [repository]);

    const saveValue = useCallback<Setter<U>>(
        (value: U | null) => {
            if (value) {
                repository.save(value).then();
            } else {
                repository.delete().then();
            }
        },
        [repository]
    );

    return [value, saveValue];
};

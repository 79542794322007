import { IPositionSetRepository } from '@view-model/models/common/PositionSet/IPositionSetRepository';
import { PositionSet, PositionSetJSON } from '@view-model/models/common/PositionSet';
import { ObjectRepository, RefBuilder, DBPath, DataSnapshot, Reference } from '@framework/repository';
import { Position } from '@view-model/models/common/types/ui';

export class PositionSetRepository implements IPositionSetRepository {
    private readonly repo: ObjectRepository<PositionSetJSON, PositionSet>;
    private readonly ref: Reference;
    private positions = new PositionSet();
    private valueCallback: ((value: DataSnapshot) => void) | undefined = undefined;

    constructor(path: DBPath) {
        this.repo = new ObjectRepository(PositionSet, path);
        this.ref = RefBuilder.ref(path);
    }

    async saveAll(positions: PositionSet): Promise<void> {
        await this.repo.save(positions);
    }

    async saveOne(id: string, position: Position): Promise<void> {
        await this.ref.child(id).set(position);
    }

    async saveMany(partialPositions: PositionSet): Promise<void> {
        // 引数で受け取った partialPositions に含まれる値のみを更新する。
        // （他の既存データは変更しない）
        await this.ref.update(partialPositions.dump());
    }

    async delete(): Promise<void> {
        await this.repo.delete();
    }

    async deleteOne(id: string): Promise<void> {
        await this.ref.child(id).remove();
    }

    async deleteMany(ids: string[]): Promise<void> {
        if (ids.length === 0) {
            return;
        }

        const updates: Record<string, null> = {};
        ids.forEach((id) => {
            updates[id] = null;
        });

        await this.ref.update(updates);
    }

    async loadAll(): Promise<PositionSet | null> {
        return this.repo.get();
    }

    async getMany(ids: string[]): Promise<PositionSet> {
        const positions = await this.loadAll();
        if (!positions) return new PositionSet();
        return positions.subset(ids);
    }

    addListener(callback: (positions: PositionSet) => void): void {
        this.valueCallback = (snapshot) => {
            const data = snapshot.val();
            this.positions = PositionSet.load(data as Record<string, Position> | null);
            callback(this.positions);
        };
        this.ref.on('value', this.valueCallback);
    }

    removeListener(): void {
        this.ref.off('value', this.valueCallback);
        this.valueCallback = undefined;
    }
}

import { StickyZone, StickyZoneCollection, StickyZoneRepository } from '@view-model/models/sticky/StickyZoneView';
import { ModelCommentCascadeRepository, ModelCommentContents } from '@view-model/models/sticky/ModelComment';
import { NodeCollection, StickyNode, NodeRepository } from '@view-model/models/sticky/StickyNodeView';
import { LinkCollection, LinkEntity, LinkRepository } from '@view-model/models/sticky/StickyLink';
import { StickyModel, StickyModelContents } from '@view-model/domain/model';
import {
    DescriptionPanelCascadeRepository,
    DescriptionPanelCollectionContents,
} from '@view-model/models/sticky/DescriptionPanel';
import { ElementDescriptionSet, ElementDescriptionSetRepository } from '@view-model/models/sticky/ElementDescription';
import { IPositionSetRepository, PositionSet } from '@view-model/models/common/PositionSet';
import { DisplayOrderTree } from '@model-framework/display-order';
import { DisplayOrderRepository } from '@model-framework/display-order/infrastructure';
import { ViewModelId } from '@schema-common/base';
import { ElementDescriptionTarget } from '@view-model/models/sticky/ElementDescription/domain/ElementDescriptionTarget';

type StickyModelElementRepositories = {
    nodeRepository: NodeRepository;
    linkRepository: LinkRepository;
    zoneRepository: StickyZoneRepository;
    zonePositionsRepository: IPositionSetRepository;
    descriptionPanelsRepository: DescriptionPanelCascadeRepository;
    nodeDescriptionSetRepository: ElementDescriptionSetRepository;
    zoneDescriptionSetRepository: ElementDescriptionSetRepository;
    displayOrderRepository: DisplayOrderRepository;
};

export class StickyModelCascadeRepository {
    private readonly commentRepository: ModelCommentCascadeRepository;

    constructor(private readonly viewModelId: ViewModelId) {
        this.commentRepository = new ModelCommentCascadeRepository(this.viewModelId);
    }

    async saveContents(
        model: StickyModel,
        nodes: StickyNode[],
        links: LinkEntity[],
        zones: StickyZone[],
        zonePositions: PositionSet,
        commentContents: ModelCommentContents,
        descriptionPanelContents: DescriptionPanelCollectionContents,
        nodeDescriptions: ElementDescriptionSet,
        zoneDescriptions: ElementDescriptionSet,
        displayOrderTree: DisplayOrderTree
    ): Promise<void> {
        const {
            nodeRepository,
            linkRepository,
            zoneRepository,
            zonePositionsRepository,
            descriptionPanelsRepository,
            nodeDescriptionSetRepository,
            zoneDescriptionSetRepository,
            displayOrderRepository,
        } = this.createRepositories(model);

        await Promise.all([
            nodeRepository.saveNodes(nodes),
            linkRepository.saveLinks(links),
            zoneRepository.saveZones(zones),
            zonePositionsRepository.saveMany(zonePositions),
            this.commentRepository.saveContents(commentContents),
            descriptionPanelsRepository.saveContents(descriptionPanelContents),
            nodeDescriptionSetRepository.save(nodeDescriptions),
            zoneDescriptionSetRepository.save(zoneDescriptions),
            displayOrderRepository.save(displayOrderTree),
        ]);
    }

    async loadContents(model: StickyModel): Promise<StickyModelContents> {
        const {
            nodeRepository,
            linkRepository,
            zoneRepository,
            zonePositionsRepository,
            descriptionPanelsRepository,
            nodeDescriptionSetRepository,
            zoneDescriptionSetRepository,
            displayOrderRepository,
        } = this.createRepositories(model);

        const [nodes, links, zones, comments, descriptionPanels, nodeDescriptions, zoneDescriptions, displayOrderTree] =
            await Promise.all([
                nodeRepository.loadNodes(),
                linkRepository.loadLinks(),
                zoneRepository.loadZones(),
                this.commentRepository.loadContents(model.key),
                descriptionPanelsRepository.loadContents(),
                nodeDescriptionSetRepository.get(),
                zoneDescriptionSetRepository.get(),
                displayOrderRepository.load(),
            ]);

        const zonePositions = await zonePositionsRepository.getMany(
            zones.map((zone: StickyZone) => {
                return zone.id;
            })
        );

        const { contents } = StickyModelContents.fromFragments(
            model.key,
            model.version,
            new NodeCollection(nodes),
            new LinkCollection(links),
            new StickyZoneCollection(zones),
            zonePositions,
            comments,
            descriptionPanels,
            nodeDescriptions || ElementDescriptionSet.fromArray([]),
            zoneDescriptions || ElementDescriptionSet.fromArray([]),
            displayOrderTree
        );

        return contents;
    }

    private createRepositories(model: StickyModel): StickyModelElementRepositories {
        const nodeRepository = new NodeRepository(this.viewModelId, model.id);
        const linkRepository = new LinkRepository(this.viewModelId, model.id);
        const zoneRepository = new StickyZoneRepository(this.viewModelId, model.id);
        const zonePositionsRepository = StickyZoneRepository.createPositionsRepository(this.viewModelId, model.id);
        const displayOrderRepository = new DisplayOrderRepository(this.viewModelId, model.id);

        return {
            nodeRepository,
            linkRepository,
            zoneRepository,
            zonePositionsRepository,
            descriptionPanelsRepository: new DescriptionPanelCascadeRepository(this.viewModelId, model.id),
            nodeDescriptionSetRepository: ElementDescriptionSetRepository.buildFromIds(
                this.viewModelId,
                model.id,
                ElementDescriptionTarget.Node
            ),
            zoneDescriptionSetRepository: ElementDescriptionSetRepository.buildFromIds(
                this.viewModelId,
                model.id,
                ElementDescriptionTarget.Zone
            ),
            displayOrderRepository,
        };
    }
}

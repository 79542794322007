import { createContext, useContext, useState } from 'react';

type StickyNodeAlignIntervalContextValue = {
    verticalInterval: number;
    setVerticalInterval(interval: number): void;
    horizontalInterval: number;
    setHorizontalInterval(interval: number): void;
};

const StickyNodeAlignIntervalContext = createContext<StickyNodeAlignIntervalContextValue | null>(null);

export const useStickyNodeAlignInterval = (): StickyNodeAlignIntervalContextValue => {
    const context = useContext(StickyNodeAlignIntervalContext);
    if (!context) {
        throw new Error('No StickyNodeAlignIntervalProvider found when calling useStickyNodeAlignInterval.');
    }
    const { verticalInterval, setVerticalInterval, horizontalInterval, setHorizontalInterval } = context;

    return {
        verticalInterval,
        setVerticalInterval,
        horizontalInterval,
        setHorizontalInterval,
    };
};

type Props = {
    children: React.ReactNode;
};

export const StickyNodeAlignIntervalProvider: React.FC<Props> = ({ children }: Props) => {
    const [verticalInterval, setVerticalInterval] = useState(1);
    const [horizontalInterval, setHorizontalInterval] = useState(1);

    return (
        <StickyNodeAlignIntervalContext.Provider
            value={{
                verticalInterval,
                setVerticalInterval,
                horizontalInterval,
                setHorizontalInterval,
            }}
        >
            {children}
        </StickyNodeAlignIntervalContext.Provider>
    );
};

export class BuildInfo {
    public static readonly projectId: string = process.env.GOOGLE_CLOUD_PROJECT || '$GOOGLE_CLOUD_PROJECT';
    public static readonly DATE: string = process.env.BUILD_DATE || '$BUILD_DATE';
    public static readonly SHA1: string = process.env.GIT_SHA1 || '$GIT_SHA1';
    public static readonly version: number = parseInt(process.env.BUILD_NUM || '$BUILD_NUM');

    private constructor() {
        // nothing
    }

    /**
     * 引数に指定された要求バージョン条件をこのビルドが充足しているか否かを判定する。
     * @param requiredVersion
     * @returns
     */
    static isSatisfied(requiredVersion: number | null | undefined): boolean {
        // 要求バージョンが null, undefined, 0 の場合には、バージョン条件を充足していると判断する
        if (!requiredVersion) {
            return true;
        }

        // 現在バージョンが NaN (Not a Number) の場合には、常にバージョン条件を充足しているとみなす
        if (isNaN(this.version)) {
            return true;
        }

        return this.version >= requiredVersion;
    }
}

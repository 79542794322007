import { ItemContainer } from './ItemContainer';

type Props = {
    width: number;
    withTopDivider?: boolean;
    children: React.ReactNode;
};

export const ModelCommentReplyFormContainer: React.FC<Props> = ({ width, withTopDivider, children }: Props) => {
    return (
        <ItemContainer width={width} withTopDivider={withTopDivider}>
            {children}
        </ItemContainer>
    );
};

import { Point } from './Point';

export class Triangle {
    constructor(
        private readonly p1: Point,
        private readonly p2: Point,
        private readonly p3: Point
    ) {}

    toSVGPath(): string {
        const { p1, p2, p3 } = this;
        return `M${p1.toSVGPoint()}L${p2.toSVGPoint()}L${p3.toSVGPoint()}Z`;
    }
}

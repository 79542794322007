import { ICommand } from '@model-framework/command';
import { PositionSet } from './PositionSet';
import { IPositionSetRepository } from './IPositionSetRepository';
import { DBPath } from '@framework/repository';
import { PositionSetRepository } from './infrastructure';

export class PositionSetCreateCommand implements ICommand {
    private readonly repository: IPositionSetRepository;

    constructor(
        private readonly toValue: PositionSet,
        path: DBPath
    ) {
        this.repository = new PositionSetRepository(path);
    }

    do(): void {
        this.repository.saveMany(this.toValue).then();
    }

    undo(): void {
        this.repository.deleteMany(this.toValue.allIds()).then();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        const currentPositions = await this.repository.getMany(this.toValue.allIds());
        return currentPositions.isEqual(this.toValue);
    }

    async canRedo(): Promise<boolean> {
        const currentPositions = await this.repository.getMany(this.toValue.allIds());
        return currentPositions.isEmpty();
    }
}

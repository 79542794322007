import { useCallback, useState } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { WorkspaceEntity } from '@workspace/domain/workspace';
import { MemberAddModal } from './MemberAddModal';
import { MemberEditModal } from './MemberEditModal';
import { MemberRemoveModal } from './MemberRemoveModal';

type TargetModal = 'Add' | 'Edit' | 'Remove';

type Props = {
    isOpen: boolean;
    onClose(): void;
    workspace: WorkspaceEntity;
};

export const WorkspaceMemberEditModal: React.FC<Props> = ({ isOpen, onClose, workspace }: Props) => {
    const currentUserId = useCurrentUserId();
    const [targetModal, setTargetModal] = useState<TargetModal>('Edit');

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleMemberAddStart = useCallback(() => setTargetModal('Add'), []);
    const handleMemberRemoveStart = useCallback(() => setTargetModal('Remove'), []);
    const handleCancel = useCallback(() => setTargetModal('Edit'), []);

    if (!currentUserId) return null;
    if (!isOpen) return null;

    return (
        <>
            <MemberEditModal
                isOpen={targetModal === 'Edit'}
                onClose={handleClose}
                workspace={workspace}
                currentUserId={currentUserId}
                onMemberAdd={handleMemberAddStart}
                onMemberRemove={handleMemberRemoveStart}
            />

            <MemberRemoveModal
                isOpen={targetModal === 'Remove'}
                onClose={handleCancel}
                workspace={workspace}
                currentUserId={currentUserId}
            />

            <MemberAddModal isOpen={targetModal === 'Add'} onClose={handleCancel} workspace={workspace} />
        </>
    );
};

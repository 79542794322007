import { ModelCommentThread } from '../../domain';
import { PrimaryCommentMenu } from './PrimaryCommentMenu';
import { ReplyCommentMenu } from './ReplyCommentMenu';
import { CommentPositionMap } from '../../positions';
import { ModelCommentThreadOperation } from '../../adapter';
import { ModelCommentId } from '@schema-common/base';
import { UserPublicProfile } from '@user/PublicProfile';

type Props = {
    commentThread: ModelCommentThread;
    positionMap: CommentPositionMap;
    threadOperation: ModelCommentThreadOperation;
    currentUserProfile: UserPublicProfile;
    onEditStart(id: ModelCommentId): void;
};

export const CommentMenuList: React.FC<Props> = ({
    positionMap,
    commentThread,
    threadOperation,
    currentUserProfile,
    onEditStart,
}: Props) => {
    const primaryComment = commentThread.getPrimaryComment();
    const regularReplyComments = commentThread.getRegularReplyComments();
    const reversedRegularReplyComments = regularReplyComments.reverse();
    return (
        <>
            {/* コメントメニューが重ならないように、位置が下のコメントから順に描画している */}
            {...reversedRegularReplyComments.map((replyComment) => (
                <ReplyCommentMenu
                    key={replyComment.id}
                    position={positionMap.getPosition(replyComment.id)}
                    comment={replyComment}
                    threadOperation={threadOperation}
                    onEditStart={onEditStart}
                />
            ))}
            <PrimaryCommentMenu
                commentThread={commentThread}
                position={positionMap.getPosition(primaryComment.id)}
                primaryComment={primaryComment}
                threadOperation={threadOperation}
                currentUserProfile={currentUserProfile}
                onEditStart={onEditStart}
            />
        </>
    );
};

import { TrackJS } from 'trackjs';
import { BuildInfo } from '@config/build-info';
import { TrackJSConfig } from '@config/TrackJSConfig';

TrackJS.install(
    Object.assign(TrackJSConfig, {
        version: `${BuildInfo.version}`,
    })
);

TrackJS.addMetadata('GoogleCloudProjectID', BuildInfo.projectId);
TrackJS.addMetadata('GitSHA1', BuildInfo.SHA1);
TrackJS.addMetadata('BuildDate', BuildInfo.DATE);

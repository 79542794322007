import { MainMenuBox, MainMenuIcon } from '@model-framework/menu';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';

type Props = {
    menuSize: number;
    isSelected: boolean;
    onClick(): void;
    text: string;
};

export const LinkLineStyleMainMenu: React.FC<Props> = ({ menuSize, isSelected, onClick, text }: Props) => {
    return (
        <>
            <MainMenuBox menuSize={menuSize} isSelected={isSelected} onClick={onClick} text={text} />
            <MainMenuIcon icon={faGripLines} menuSize={menuSize} />
        </>
    );
};

import { MainMenuBox } from '@model-framework/menu/MenuBox';
import { MainMenuIcon } from '@model-framework/menu/MenuIcon';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

type Props = {
    menuSize: number;
    onClick(): void;
    text: string;
};

export const TrashMenuButton: React.FC<Props> = ({ menuSize, onClick, text }: Props) => {
    return (
        <>
            <MainMenuBox menuSize={menuSize} isSelected={false} onClick={onClick} text={text} />
            <MainMenuIcon icon={faTrashAlt} menuSize={menuSize} />
        </>
    );
};

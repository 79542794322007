import { Point } from '@view-model/models/common/basic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { ViewModelURL } from '@user/pages/urls';
import { useFavicon } from './hooks/useFavicon';

type Props = {
    url: string;
};

export const HyperLinkViewModelIcon: React.FC<Props> = ({ url }: Props) => {
    const { getFaviconURL, FAVICON_SIZE } = useFavicon(url);

    const viewModelURL = ViewModelURL.fromURLString(url);
    const position = new Point(-FAVICON_SIZE / 2, -FAVICON_SIZE / 2);

    return (
        <g fill={'white'} transform={position.toSVGTranslate()}>
            {viewModelURL ? (
                <foreignObject width={FAVICON_SIZE} height={FAVICON_SIZE}>
                    <div className="bg-brand px-1 py-2 text-white">
                        <FontAwesomeIcon icon={faProjectDiagram} size="2x" />
                    </div>
                </foreignObject>
            ) : (
                <image xlinkHref={getFaviconURL()} width={FAVICON_SIZE} height={FAVICON_SIZE} />
            )}
        </g>
    );
};

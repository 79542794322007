import './style/modelCommentBody.css';
import { Markdown } from '@framework/ui/elements/Markdown';

type Props = {
    body: string;
};

export const ModelCommentBody: React.FC<Props> = ({ body }: Props) => {
    return (
        <div>
            <Markdown className="model-comment-body-markdown markdown-body" source={body} />
        </div>
    );
};

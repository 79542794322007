import { useCallback, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { Link } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { useFetchPasswordResetToken } from './useFetchPasswordResetToken';
import { EditPasswordForm } from '@user/EditPasswordForm';
import { Loading } from '@framework/ui/atoms/Loading';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';

type Props = {
    token: string;
    onResetSuccess(): void;
};

export const ResetForm: React.FC<Props> = ({ token, onResetSuccess }: Props) => {
    const tokenStatus = useFetchPasswordResetToken(token);

    const [password, setPassword] = useState('');
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState('');

    const handleChangePassword = useCallback((value: string) => {
        setPassword(value);
    }, []);

    const handleClick = useCallback(() => {
        setProcessing(true);

        FunctionsUserActions.resetPasswordAndLogin(token, password)
            .then((token) => {
                onResetSuccess();
                return FirebaseAuthenticationClient.signInWithCustomToken(token);
            })
            .catch((error) => {
                setError('パスワードの再設定に失敗しました。しばらく時間をあけてからもう一度試してください。');
                console.error(error);
            })
            .finally(() => {
                setProcessing(false);
            });
    }, [onResetSuccess, password, token]);

    const isValid = !!password;

    if (tokenStatus === null) {
        return <Loading />;
    }

    if (tokenStatus === 'notFound') {
        return (
            <div className="py-4">
                パスワード再設定用の情報が見つかりませんでした。URLが間違っていないか、途中で途切れていないか、確認してください。
            </div>
        );
    }

    if (tokenStatus === 'invalid') {
        return (
            <>
                <div className="py-4">
                    パスワード再設定用の情報が無効になっています。もう一度パスワードの再設定リンクを取得し直してください。
                </div>
                <div className="pt-4">
                    <Link
                        to={UserPagePathBuilder.passwordResetPage()}
                        className="text-sm text-blue-700 hover:underline"
                    >
                        パスワード再設定用のリンクを取得する
                    </Link>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="py-4">新しく設定するパスワードを以下に入力してください。</div>

            <EditPasswordForm onChange={handleChangePassword} disabled={processing} />

            {error && <div className="text-red-500">{error}</div>}

            <div className="pb-8 pt-16 text-center">
                <FlatButton
                    className="px-16"
                    color="brand"
                    onClick={handleClick}
                    disabled={!isValid || processing}
                    loading={processing}
                >
                    パスワードを再設定する
                </FlatButton>
            </div>
        </>
    );
};

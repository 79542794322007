import { Key } from '@framework/domain';
import { nanoid } from 'nanoid';
import { NodeId, ModelId, LinkId, ModelCommentThreadId, StickyZoneId, ViewId } from '@schema-common/base';

export class ViewKey extends Key {
    protected get KINDS(): string[] {
        return ['View'];
    }

    public static buildFromID(id: ViewId): ViewKey {
        return new ViewKey(`View:${id}`);
    }

    public static buildNew(): ViewKey {
        return ViewKey.buildFromID(`${nanoid()}`);
    }
}

export class ModelKey extends Key {
    protected get KINDS(): string[] {
        return ['Model'];
    }

    public static buildFromID(id: string): ModelKey {
        return new ModelKey(`Model:${id}`);
    }

    public static buildNew(): ModelKey {
        return ModelKey.buildFromID(`${nanoid()}`);
    }

    public static load(dump: string): ModelKey {
        return new ModelKey(dump);
    }

    dump(): string {
        return this.toString();
    }

    get modelID(): ModelId {
        return this.id as ModelId;
    }
}

type NodeKeyKIND = 'Node';

export class NodeKey extends Key {
    static KIND: NodeKeyKIND = 'Node';

    protected get KINDS(): string[] {
        return ['Node'];
    }

    public static buildFromID(id: NodeId): NodeKey {
        return new NodeKey(`Node:${id}`);
    }

    public static buildNew(): NodeKey {
        return NodeKey.buildFromID(`${nanoid()}`);
    }
}

export class LinkKey extends Key {
    protected get KINDS(): string[] {
        return ['Link'];
    }

    public static buildFromID(id: LinkId): LinkKey {
        return new LinkKey(`Link:${id}`);
    }

    public static buildNew(): LinkKey {
        return LinkKey.buildFromID(`${nanoid()}`);
    }
}

export class ConsistencyLinkKey extends Key {
    protected get KINDS(): string[] {
        return ['ConsistencyLink'];
    }

    public static buildFromID(id: string): ConsistencyLinkKey {
        return new ConsistencyLinkKey(`ConsistencyLink:${id}`);
    }

    public static buildNew(): ConsistencyLinkKey {
        return ConsistencyLinkKey.buildFromID(`${nanoid()}`);
    }
}

type StickyZoneKeyKIND = 'StickyZone';

export class StickyZoneKey extends Key {
    static KIND: StickyZoneKeyKIND = 'StickyZone';

    protected get KINDS(): string[] {
        return ['StickyZone'];
    }

    public static buildFromID(id: StickyZoneId): StickyZoneKey {
        return new StickyZoneKey(`StickyZone:${id}`);
    }

    public static buildNew(): StickyZoneKey {
        return StickyZoneKey.buildFromID(`${nanoid()}`);
    }
}

export const isNodeKey = (key: Key): key is NodeKey => key instanceof NodeKey;
export const isStickyZoneKey = (key: Key): key is StickyZoneKey => key instanceof StickyZoneKey;

export type ModelElementKey = NodeKey | LinkKey | StickyZoneKey;
export type ModelElementId = NodeId | LinkId | StickyZoneId | ModelCommentThreadId;

export type LinkableTargetKey = NodeKey | StickyZoneKey;
export type LinkableTargetId = NodeId | StickyZoneId;

export type DescriptionTargetKey = NodeKey | StickyZoneKey;

import { NodeId, StickyZoneId } from '@schema-common/base';
import { FirebaseOrderedKey } from '@schema-common/firebase';

export class DisplayOrder {
    private constructor(
        private readonly type: 'nodeOrders' | 'zoneOrders',
        private readonly id: NodeId | StickyZoneId,
        private readonly parentId: StickyZoneId,
        private readonly orderKey: FirebaseOrderedKey
    ) {}

    static node(id: NodeId, parentId: StickyZoneId, orderKey: FirebaseOrderedKey): DisplayOrder {
        return new DisplayOrder('nodeOrders', id, parentId, orderKey);
    }

    static zone(id: StickyZoneId, parentId: StickyZoneId, orderKey: FirebaseOrderedKey): DisplayOrder {
        return new DisplayOrder('zoneOrders', id, parentId, orderKey);
    }

    private updatesPath(): string {
        const { parentId, type, id } = this;

        return [parentId, type, id].join('/');
    }

    elementId(): NodeId | StickyZoneId {
        return this.id;
    }

    parentIs(parentId: StickyZoneId): boolean {
        return parentId === this.parentId;
    }

    isChildOfAny(parentIds: StickyZoneId[]): boolean {
        return parentIds.includes(this.parentId);
    }

    updatesForAdd(): Record<string, string> {
        return { [this.updatesPath()]: this.orderKey };
    }

    updatesForRemove(): Record<string, null> {
        return { [this.updatesPath()]: null };
    }

    /**
     * 親が同じオーダー（ゾーン）を生成する
     * @param zoneId
     * @param orderKey
     */
    createSiblingZone(zoneId: StickyZoneId, orderKey: FirebaseOrderedKey): DisplayOrder {
        return new DisplayOrder('zoneOrders', zoneId, this.parentId, orderKey);
    }

    /**
     * 親が同じオーダー（ノード）を生成する
     * @param nodeId
     * @param orderKey
     */
    createSiblingNode(nodeId: NodeId, orderKey: FirebaseOrderedKey): DisplayOrder {
        return new DisplayOrder('nodeOrders', nodeId, this.parentId, orderKey);
    }

    /**
     * 親を変更する
     * @param parentId 変更先の親ID
     * @param orderKey 変更先でのOrderKey
     */
    changeParent(parentId: StickyZoneId, orderKey: FirebaseOrderedKey): DisplayOrder {
        return new DisplayOrder(this.type, this.id, parentId, orderKey);
    }

    /**
     * OrderKeyを変更する
     * @param orderKey 変更先のOrderKey
     */
    changeOrderKey(orderKey: FirebaseOrderedKey): DisplayOrder {
        return new DisplayOrder(this.type, this.id, this.parentId, orderKey);
    }

    /**
     * オーダーキー順にソートして返す
     * @param orders
     */
    static sortByOrderKey(orders: DisplayOrder[]): DisplayOrder[] {
        return orders.concat().sort((a, b) => a.orderKey.localeCompare(b.orderKey));
    }
}

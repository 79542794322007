import { Id } from '@framework/domain';
import { Size } from './Size';
import { SizeJSON } from '@schema-common/view-model';

type SizeSetJSON = Record<Id, SizeJSON>;

export class SizeSet {
    private readonly sizes: Record<Id, Size>;

    constructor(sizes: Record<Id, Size> = {}) {
        this.sizes = sizes;
    }

    dump(): SizeSetJSON {
        return this.entries().reduce(
            (result, [id, size]) => {
                result[id] = size.dump();
                return result;
            },
            {} as Record<Id, SizeJSON>
        );
    }

    static load(dump: SizeSetJSON): SizeSet {
        const sizes: Record<Id, Size> = {};
        Object.entries(dump).forEach(([id, size]) => {
            sizes[id] = Size.load(size);
        });
        return new SizeSet(sizes);
    }

    cloneNew(newIdMap: Record<Id, Id>): SizeSet {
        const sizes: Record<Id, Size> = {};
        this.entries().forEach(([id, size]) => {
            sizes[newIdMap[id]] = size;
        });
        return new SizeSet(sizes);
    }

    find(id: Id): Size | undefined {
        return this.sizes[id];
    }

    set(id: Id, size: Size): SizeSet {
        return SizeSet.load({
            ...this.dump(),
            [id]: size,
        });
    }

    subset(ids: Id[]): SizeSet {
        const sizes: Record<Id, Size> = {};
        ids.forEach((id) => {
            const size = this.find(id);
            if (size) {
                sizes[id] = size;
            }
        });

        return new SizeSet(sizes);
    }

    merge(other: SizeSet): SizeSet {
        const dump = this.dump();
        other.entries().forEach(([id, size]) => {
            dump[id] = size.dump();
        });

        return SizeSet.load(dump);
    }

    count(): number {
        return Object.keys(this.sizes).length;
    }

    entries(): [Id, Size][] {
        return Object.entries(this.sizes);
    }

    isEqual(other: SizeSet): boolean {
        return (
            other instanceof SizeSet &&
            this.count() == other.count() &&
            this.entries().every(([id, size]) => {
                const otherSize = other.find(id);
                return otherSize && size.isEqual(otherSize);
            })
        );
    }
}

import { PositionSet, PositionSetJSON } from '@view-model/models/common/PositionSet';
import { ViewId } from '@schema-common/base';
import { Point, Rect } from '@view-model/models/common/basic';
import { Position } from '@view-model/models/common/types/ui';
import { ViewEntity } from '@view-model/domain/view/ViewEntity';
import { ViewJSON } from '@schema-app/view-model/contents/{viewModelId}/views/{viewId}/ViewJSON';
import { ViewCollection } from './ViewCollection';

type ViewCollectionContentsJSON = {
    views: ViewJSON[];
    positions: PositionSetJSON;
};

export class ViewCollectionContents {
    constructor(
        private readonly views: ViewCollection,
        private readonly positions: PositionSet
    ) {
        // 位置情報が存在するビューのみを扱う
        this.views = views.filterByPositionSet(positions);
        this.positions = positions.getMulti(this.views.allIds());
    }

    public dump(): ViewCollectionContentsJSON {
        return {
            views: this.views.dump(),
            positions: this.positions.dump(),
        };
    }

    public static load(viewCollectionContentsJSON: ViewCollectionContentsJSON): ViewCollectionContents {
        const views = ViewCollection.load(viewCollectionContentsJSON.views);
        const positions = PositionSet.load(viewCollectionContentsJSON.positions);
        return new ViewCollectionContents(views, positions);
    }

    static buildEmpty(): ViewCollectionContents {
        return new ViewCollectionContents(new ViewCollection([]), PositionSet.buildEmpty());
    }

    public getBounds(): Rect | null {
        return this.views.getBounds(this.positions);
    }

    public getViews(): ViewCollection {
        return this.views;
    }

    public getViewsByIds(ids: ViewId[]): ViewCollection {
        return this.views.filterByIds(ids);
    }

    public getPositions(): PositionSet {
        return this.positions;
    }

    public getPositionsByIds(ids: ViewId[]): PositionSet {
        return this.positions.getMulti(ids);
    }

    public findView(viewId: ViewId): ViewEntity | undefined {
        return this.views.findById(viewId);
    }

    public findPosition(viewId: ViewId): Point | undefined {
        return this.positions.find(viewId);
    }

    public isEmpty(): boolean {
        return this.views.isEmpty();
    }

    addViews(newViews: ViewEntity[], newPositions: PositionSet): ViewCollectionContents {
        const addedViews = this.views.addViews(newViews);
        const addedPositionSet = this.positions.merge(newPositions);
        return new ViewCollectionContents(addedViews, addedPositionSet);
    }

    addView(newView: ViewEntity, newPosition: Point): ViewCollectionContents {
        const newViews = this.views.added(newView);
        const newPositions = this.positions.set(newView.id, newPosition);
        return new ViewCollectionContents(newViews, newPositions);
    }

    public getRectOf(viewId: ViewId): Rect | null {
        const targetView = this.views.findById(viewId);
        const targetViewPosition = this.positions.find(viewId);
        if (targetView && targetViewPosition) return targetView.getRect(targetViewPosition);

        return null;
    }

    /**
     * 引数に与えたpositionにおいて一番手前に存在するビューを取得する
     */
    public findForegroundViewByPosition = (position: Position): ViewEntity | null => {
        return this.views.findForegroundViewByPosition(this.positions, position);
    };

    public getCenterPoint(viewId: ViewId): Point | null {
        const view = this.views.findById(viewId);
        if (!view) return null;
        const position = this.positions.find(view.id);
        if (!position) return null;
        return view.getRect(position).getCenterPoint();
    }

    public getUnionRect(): Rect | null {
        const { views, positions } = this;
        if (views.isEmpty() || positions.isEmpty()) return null;

        const rects = views
            .map((view) => {
                const position = positions.find(view.id);
                if (!position) return null;

                return view.getRect(position);
            })
            .filter((rect): rect is Rect => !!rect);

        return rects.reduce((result, rect) => result.union(rect), rects[0]);
    }
}

import { useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Loading } from '@framework/ui/atoms/Loading';
import { Button } from '@framework/ui/atoms/Button';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';
import { toast } from 'react-hot-toast';

type Props = {
    currentName: string;
    onClose(): void;
};

export const AnonymousUserNameChange: React.FC<Props> = ({ onClose, currentName }: Props) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [name, setName] = useState<string>(currentName);

    const handleAnonymousLogin = () => {
        if (name === '') return;

        setProcessing(true);
        FunctionsUserActions.anonymousUserChangeName(name)
            .then(() => onClose())
            .catch(() => toast.error('保存に失敗しました'))
            .finally(() => setProcessing(false));
    };

    return (
        <GeneralModal
            isOpen={true}
            onClose={onClose}
            title={'名前を変更 | Change name'}
            submitButton={<></>}
            cancelButton={<></>}
        >
            <div className="flex flex-col items-center justify-center p-4" style={{ width: '600px' }}>
                {processing ? (
                    <Loading horizontal={true} message={'保存しています... | Saving...'} />
                ) : (
                    <>
                        <div className="w-3/4 py-2 text-lg">
                            <input
                                type="text"
                                className="w-full rounded border-2 border-gray-500 px-3 py-2"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </div>
                        <Button color="brand" onClick={handleAnonymousLogin} disabled={name === ''}>
                            <div className="px-2">名前を変更する | Change name</div>
                        </Button>
                    </>
                )}
            </div>
        </GeneralModal>
    );
};

import { useCallback, useState } from 'react';
import { CreateWorkspaceModal } from './CreateWorkspaceModal';
import { FlatButton } from '@framework/ui/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

type Props = {
    groupId: string;
    userId: string;
};

export const CreateWorkspaceButton: React.FC<Props> = ({ groupId, userId }: Props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = useCallback(() => setModalOpen(true), []);
    const handleClose = useCallback(() => setModalOpen(false), []);

    return (
        <>
            <FlatButton color="brand" className="w-56 px-3 py-2" onClick={handleOpen}>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                新規ワークスペース
            </FlatButton>

            <CreateWorkspaceModal groupId={groupId} userId={userId} isOpen={modalOpen} onClose={handleClose} />
        </>
    );
};

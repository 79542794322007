import { Key } from '@framework/domain';
import { ElementId } from '@schema-common/base';

export class ElementDescriptionEditLockKey extends Key {
    protected get KINDS(): string[] {
        return ['ElementDescriptionEditLock'];
    }

    // 編集ロックに利用するキーを生成。elementIdを流用してキーを生成する。
    public static fromElementId(id: ElementId): ElementDescriptionEditLockKey {
        return new ElementDescriptionEditLockKey(`ElementDescriptionEditLock:${id}`);
    }
}

import { useEffect, useState } from 'react';
import { createWorkspaceEntityRepository, WorkspaceEntity, WorkspaceSetting } from '@workspace/domain/workspace';
import { useWorkspaceMyMemberRole } from './useWorkspaceMyMemberRole';
import { RTDBPath, usePersistedObject } from '@framework/repository';
import { WorkspaceId, isValidId } from '@schema-common/base';

type Result = {
    workspace: WorkspaceEntity | null;
    loading: boolean;
};

export const useWorkspaceEntity = (id: WorkspaceId): Result => {
    const [loading, setLoading] = useState<boolean>(true);
    const [workspace, setWorkspace] = useState<WorkspaceEntity | null>(null);

    /**
     * 引数に指定された id が RTDB のパス階層で指定できない文字列パターンの場合には、非実在のフォールバック用idを返す。
     * これによって不正なRTDBパスへのアクセスによる権限エラーの発生を抑止する。
     */
    const workspaceId = isValidId(id) ? id : '(invalid-workspace-id)';

    const role = useWorkspaceMyMemberRole(workspaceId);
    const [setting] = usePersistedObject(WorkspaceSetting, RTDBPath.Workspace.settingPath(workspaceId));

    useEffect(() => {
        // 権限チェック用の情報を取得途中の場合は、早期return
        if (role === undefined || setting === undefined) {
            return;
        }

        // ワークスペースの設定が存在しないとき、エンティティに対するRead権限が無い時には、ロード完了状態に遷移させる
        if (setting === null || !setting.canReadEntity(role)) {
            setWorkspace(null);
            setLoading(false);
            return;
        }

        // ワークスペース・エンティティの取得を開始する
        setLoading(true);

        const repository = createWorkspaceEntityRepository(workspaceId);
        repository
            .get()
            .then((workspace) => {
                setWorkspace(workspace);
            })
            .finally(() => {
                setLoading(false);
            });

        repository.addListener((workspace) => {
            setWorkspace(workspace);
        });

        return () => repository.removeListener();
    }, [workspaceId, role, setting]);

    return { workspace, loading };
};

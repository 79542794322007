import { Rect } from '@view-model/models/common/basic';

type Props = {
    viewRect: Rect;
};

const MARGIN = 12;
const FILL_OPACITY = 0;
const STROKE_WIDTH = 10;

export const ConsistencyLinkTargetFrame: React.FC<Props> = ({ viewRect }: Props) => {
    const r = viewRect.applyMarginKeepingCenter(MARGIN);

    return (
        <rect
            className="pointer-events-none stroke-indigo-200"
            x={r.x}
            y={r.y}
            width={r.width}
            height={r.height}
            fillOpacity={FILL_OPACITY}
            strokeWidth={STROKE_WIDTH}
        />
    );
};

import { ElementDescription } from './ElementDescription';
import { DescriptionTargetKey, ModelElementId } from '@view-model/domain/key';
import { CompositeCommand, ICommand } from '@model-framework/command';
import { ElementDescriptionJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/element-descriptions/{elementId}/ElementDescriptionJSON';
import { ElementId, ModelId, ViewModelId } from '@schema-common/base';
import { ElementDescriptionTarget } from './ElementDescriptionTarget';

export type ElementDescriptionSetJSON = Record<ElementId, ElementDescriptionJSON>;

export class ElementDescriptionSet {
    private constructor(private readonly descriptions: Record<ElementId, ElementDescription>) {}

    get size(): number {
        return Object.keys(this.descriptions).length;
    }

    static fromArray(descriptions: ElementDescription[]): ElementDescriptionSet {
        const record: Record<ElementId, ElementDescription> = {};
        descriptions.forEach((desc: ElementDescription) => {
            record[desc.elementId] = desc;
        });

        return new this(record);
    }

    dump(): ElementDescriptionSetJSON {
        const dump: ElementDescriptionSetJSON = {};
        Object.entries(this.descriptions).forEach(([elementId, description]: [ElementId, ElementDescription]) => {
            dump[elementId] = description.dump();
        });

        return dump;
    }

    merge(other: ElementDescriptionSet): ElementDescriptionSet {
        return other.toArray().reduce<ElementDescriptionSet>((set, desc) => set.add(desc), this);
    }

    toArray(): ElementDescription[] {
        return Object.values(this.descriptions);
    }

    static load(dump: ElementDescriptionSetJSON): ElementDescriptionSet {
        const descriptions = Object.values(dump).map((d) => ElementDescription.load(d));
        return this.fromArray(descriptions);
    }

    add(value: ElementDescription): ElementDescriptionSet {
        return new ElementDescriptionSet({
            ...this.descriptions,
            [value.elementId]: value,
        });
    }

    delete(value: ElementDescription): ElementDescriptionSet {
        const { [value.elementId]: _deleting, ...rest } = this.descriptions;

        return new ElementDescriptionSet(rest);
    }

    cloneNew(elementKeyMap: Record<string, DescriptionTargetKey>): ElementDescriptionSet {
        const newDescriptions = this.toArray().map((desc) => desc.cloneNew(elementKeyMap));

        return ElementDescriptionSet.fromArray(newDescriptions);
    }

    filterByModelElementIds(ids: ModelElementId[]): ElementDescriptionSet {
        const newDescriptions = this.toArray().filter((desc) => ids.some((id) => desc.relatedTo(id)));

        return ElementDescriptionSet.fromArray(newDescriptions);
    }

    buildCreateCommand(viewModelId: ViewModelId, modelId: ModelId, target: ElementDescriptionTarget): ICommand {
        const commands = this.toArray().map((desc) => desc.buildCreateCommand(viewModelId, modelId, target));

        return new CompositeCommand(...commands);
    }
}

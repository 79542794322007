import { IconProps } from '../../Icon';

export const BalusIcon: React.FC<IconProps> = ({ fill, className }: IconProps) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 566.93 566.93">
        <path
            fill={fill}
            d="M119.855,217.546c-4.502-2.598-8.184-0.474-8.184,4.727c0,5.195,3.682,11.572,8.184,14.175
		c4.497,2.598,8.183,0.469,8.183-4.727S124.353,220.144,119.855,217.546z"
        />
        <path
            fill={fill}
            d="M87.121,236.443c-4.502-2.598-8.184,2.642-8.184,11.645v137.352c0,8.994,3.682,18.486,8.184,21.084
		c4.497,2.598,8.184-2.637,8.184-11.641V257.536C95.305,248.532,91.618,239.045,87.121,236.443z"
        />
        <path
            fill={fill}
            d="M87.121,198.646c-4.502-2.598-8.184-0.469-8.184,4.727c0,5.195,3.682,11.577,8.184,14.174
		c4.497,2.598,8.184,0.474,8.184-4.727C95.305,207.625,91.618,201.248,87.121,198.646z"
        />
        <path
            fill={fill}
            d="M512.634,132.508L299.883,9.678c-2.534-1.46-5.361-2.192-8.184-2.192c-2.827,0-5.654,0.732-8.184,2.192
		L54.397,141.961c-5.063,2.92-8.184,8.325-8.184,14.174v264.567c0,5.84,3.12,11.25,8.184,14.17l212.751,122.83
		c2.534,1.455,5.356,2.197,8.184,2.197s5.649-0.742,8.184-2.197c5.063-2.919,8.184-8.329,8.184-14.169V297.863
		c0-5.83,3.135-11.26,8.184-14.175l212.751-122.831c5.063-2.925,8.184-8.325,8.184-14.174
		C520.817,140.838,517.697,135.433,512.634,132.508z M275.342,404.336c0,9.004-7.368,12.119-16.367,6.924l-16.367-9.453
		c-9.003-5.195-16.367-16.807-16.367-25.81V314.24c0-9.004,3.682-14.248,8.184-11.65c4.497,2.607,8.183,12.09,8.183,21.094v42.86
		c0,9.004,3.682,18.495,8.184,21.093c4.497,2.598,8.184-2.637,8.184-11.64V314.24c0-9.004-7.368-20.625-16.367-25.82l-16.367-9.453
		c-9.004-5.195-16.367-2.08-16.367,6.919v99.554c0,9.004,7.363,20.615,16.367,25.82l32.734,18.896
		c8.999,5.195,16.367,16.816,16.367,25.82v61.757c0,9.004-7.368,12.109-16.367,6.914l-114.569-66.151
		c-9.004-5.195-16.367-16.816-16.367-25.811V276.438c0-9.004-3.686-18.496-8.183-21.094c-4.502-2.598-8.184,2.642-8.184,11.641
		v156.248c0,9.004-7.368,12.119-16.367,6.924l-16.367-9.453c-9.004-5.195-16.367-16.816-16.367-25.82V181.941
		c0-8.999,7.363-12.114,16.367-6.919l49.101,28.35c8.999,5.2,16.367,16.816,16.367,25.82v194.05c0,8.994,3.682,18.486,8.183,21.084
		c4.497,2.607,8.184-2.637,8.184-11.641V238.64c0-9.004,3.682-14.243,8.184-11.646c4.497,2.603,8.184,12.09,8.184,21.093v194.041
		c0,9.004,3.682,18.496,8.184,21.094c4.497,2.598,8.184-2.637,8.184-11.641v-5.059c0-9.004,7.363-12.109,16.367-6.914l16.367,9.443
		c8.999,5.205,16.367,13.701,16.367,18.896s-7.368,5.195-16.367,0c-9.004-5.195-16.367-5.195-16.367,0s7.363,13.711,16.367,18.906
		l16.367,9.453c8.999,5.195,16.367,2.08,16.367-6.924V465.42c0-8.994-7.368-20.615-16.367-25.811l-32.733-18.906
		c-9.004-5.195-16.367-16.816-16.367-25.82V257.536c0-8.999,7.363-12.114,16.367-6.914l49.101,28.345
		c8.999,5.2,16.367,16.816,16.367,25.82V404.336z"
        />
    </svg>
);

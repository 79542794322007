import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { classNames } from '@framework/utils';

type Props = {
    className?: string;
};

export const HelpPageIcon: React.FC<Props> = ({ className }: Props) => {
    const onClick = () => {
        window.open('https://balus-help.levii.co.jp');
    };

    return (
        <div className={classNames(className, 'relative')}>
            <DarkTooltip tooltip={'ヘルプを表示 | Show help page'} placement={'bottom'}>
                <button
                    className={classNames(
                        'relative flex h-12 w-12 items-center justify-center',
                        'bg-white text-2xl text-gray-700 hover:bg-gray-200',
                        'cursor-pointer rounded-lg shadow-md focus:outline-none'
                    )}
                    onClick={onClick}
                    aria-label="ヘルプ | Help page"
                >
                    <FontAwesomeIcon icon={faCircleQuestion} />
                </button>
            </DarkTooltip>
        </div>
    );
};

import { ICommand } from '@model-framework/command';
import { ModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ModelCascadeRepository';
import { StickyModelContents } from '@view-model/domain/model';

export class ModelCreateCommand implements ICommand {
    constructor(
        private readonly modelCascadingRepository: ModelCascadeRepository,
        private readonly modelContents: StickyModelContents
    ) {}

    do(): void {
        this.modelCascadingRepository.saveModelContents(this.modelContents).then();
    }

    undo(): void {
        if (!this.canUndo()) return;
        this.modelCascadingRepository.delete(this.modelContents.key.id).then();
    }

    redo(): void {
        if (!this.canRedo()) return;
        this.do();
    }

    async canUndo(): Promise<boolean> {
        // すでに削除されていたらできない
        const modelContents = await this.modelCascadingRepository.load(this.modelContents.key.id);
        if (!modelContents) return false;

        // 変更されていたらできない
        return this.modelContents.isEqual(modelContents);
    }

    async canRedo(): Promise<boolean> {
        // 存在する場合はできない
        const modelContents = await this.modelCascadingRepository.load(this.modelContents.key.id);
        return !modelContents;
    }
}

import { StickyZoneId } from '@schema-common/base';
import { DisplayOrder } from './DisplayOrder';

export class DisplayOrderZoneEntry {
    constructor(
        private readonly zoneId: StickyZoneId,
        private readonly childNodeOrders: DisplayOrder[],
        private readonly childZoneOrders: DisplayOrder[]
    ) {}

    updatesForAdd(): Record<string, string> {
        const updates = {};

        // 子のパスにparentIdが含まれるので、this.parentIdは特に保存しない
        this.childNodeOrders.forEach((order) => Object.assign(updates, order.updatesForAdd()));
        this.childZoneOrders.forEach((order) => Object.assign(updates, order.updatesForAdd()));

        return updates;
    }

    updatesForRemove(): Record<string, null> {
        // 子はzoneId配下に配置するので親だけ削除するとまとめて消える
        return { [this.zoneId]: null };
    }

    nodeOrders(): DisplayOrder[] {
        return this.childNodeOrders.concat();
    }

    zoneOrders(): DisplayOrder[] {
        return this.childZoneOrders.concat();
    }

    getZoneId(): StickyZoneId {
        return this.zoneId;
    }
}

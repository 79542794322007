import { LinkBend } from './vo';

export class LinkBendInterval {
    readonly minBend: LinkBend;
    readonly maxBend: LinkBend;
    readonly interval: number;

    constructor(minBend: LinkBend, maxBend: LinkBend, interval: number) {
        this.minBend = minBend;
        this.maxBend = maxBend;
        this.interval = interval;
    }

    /*
     * 小さい側のbend値の絶対値が小さいものを内側とする
     */
    isInnerThan(interval: LinkBendInterval): boolean {
        return Math.abs(interval.minBend.d) > Math.abs(this.minBend.d);
    }

    /*
     * 正の側により大きな隙間があるか
     */
    existsLargeIntervalOnPositiveSide(): boolean {
        return this.maxBend.d > 0;
    }
}

import { useCallback, useEffect, useState } from 'react';
import { Point, Size } from '@view-model/models/common/basic';
import { RectResizer } from '@view-model/models/common/components/RectResizer';
import { PositionDelta, Size as SizeType } from '@view-model/models/common/types/ui';
import { DraggableHeader } from './DraggableHeader';

type Props = {
    headerHeight: number;
    size: Size;
    minSize: Size;
    lineStrokeWidth: number;
    onResizeConfirmed(positionDelta: PositionDelta, size: Size): void;
};

export const DescriptionPanelResizer: React.FC<Props> = ({
    headerHeight,
    size,
    minSize,
    lineStrokeWidth,
    onResizeConfirmed,
}: Props) => {
    const [resizing, setResizing] = useState<boolean>(false);
    const [headerOffset, setHeaderOffset] = useState<Point>(new Point(-lineStrokeWidth / 2, 0));
    const [headerWidth, setHeaderWidth] = useState<number>(size.width + lineStrokeWidth);

    // 初回描画後の説明パネルのサイズ変更に追従する (リサイズ開始した瞬間にヘッダーがズレるのを防止する)
    useEffect(() => {
        // 自身がリサイズ途中であれば、ステート更新は不要
        if (resizing) return;

        setHeaderWidth(size.width + lineStrokeWidth);
    }, [lineStrokeWidth, resizing, size.width]);

    const handleResizeStart = useCallback(() => setResizing(true), []);

    const handleResize = useCallback(
        (positionDelta: PositionDelta, size: SizeType) => {
            setHeaderOffset(new Point(positionDelta.dx - lineStrokeWidth / 2, 0));
            setHeaderWidth(size.width + lineStrokeWidth);
        },
        [lineStrokeWidth]
    );

    const handleResizeEnd = useCallback(
        (positionDelta: PositionDelta, size: SizeType) => {
            onResizeConfirmed(positionDelta, Size.load(size).addHeight(headerHeight));
            setResizing(false);
            setHeaderOffset(new Point(-lineStrokeWidth / 2, 0));
            setHeaderWidth(size.width + lineStrokeWidth);
        },
        [headerHeight, lineStrokeWidth, onResizeConfirmed]
    );

    const currentSize = size.addHeight(-headerHeight);

    return (
        <>
            {/* リサイズ途中の場合には、変更中のサイズに合わせてヘッダー部分を表示する */}
            {resizing && (
                <g transform={headerOffset.toSVGTranslate()}>
                    <DraggableHeader width={headerWidth} height={headerHeight} color="primary" />
                </g>
            )}
            <g transform={new Point(0, headerHeight).toSVGTranslate()}>
                <RectResizer
                    size={currentSize}
                    minSize={minSize}
                    onResizeStart={handleResizeStart}
                    onResize={handleResize}
                    onResizeEnd={handleResizeEnd}
                    resizerElements={{
                        left: true,
                        right: true,
                        bottom: true,
                        leftBottom: true,
                        rightBottom: true,
                    }}
                    lineStrokeWidth={lineStrokeWidth}
                />
            </g>
        </>
    );
};

export enum LinkMarkStyle {
    NoMark = 'NoMark',
    Arrow = 'Arrow',
    TwoWayArrow = 'TwoWayArrow',
    Diamond = 'Diamond',
    HollowDiamond = 'HollowDiamond',
    HollowTriangle = 'HollowTriangle',
}

export namespace LinkMarkStyle {
    export function isValid(value: string): value is LinkMarkStyle {
        return !!LinkMarkStyle[value as LinkMarkStyle];
    }
}

import { useCallback, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Workspace } from '@workspace/domain/workspace';
import { useGroupEntityOnce } from '@group/hooks';
import { MemberRemoveList } from './MemberRemoveList';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';

type Props = {
    isOpen: boolean;
    onClose(): void;
    workspace: Workspace;
    currentUserId: string;
};

export const MemberRemoveModal: React.FC<Props> = ({ isOpen, onClose, workspace, currentUserId }: Props) => {
    const group = useGroupEntityOnce(workspace.ownerGroupId);
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const changed = selectedUserIds.length > 0;

    const handleChange = useCallback((userIds: string[]) => setSelectedUserIds(userIds), []);

    const handleClose = useCallback(() => {
        setSelectedUserIds([]);
        setProcessing(false);
        setErrorMessage('');

        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        if (!group) return;

        setProcessing(true);
        setErrorMessage('');

        const result = await WorkspaceOperation.removeMembers(workspace.id, group.id, selectedUserIds);

        if (result) {
            handleClose();
        } else {
            setProcessing(false);
            setErrorMessage('エラーが発生しました。');
        }
    }, [handleClose, selectedUserIds, workspace.id, group]);

    const submitButton = (
        <Button color="danger" onClick={handleSubmit} disabled={!changed || processing} loading={processing}>
            メンバーを削除
        </Button>
    );

    const cancelButton = (
        <Button color="secondary" onClick={handleClose} disabled={processing}>
            キャンセル
        </Button>
    );

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={handleClose}
            title={`${workspace.name.value}のメンバーを削除する`}
            submitButton={submitButton}
            cancelButton={cancelButton}
        >
            <div
                className="mb-4 mt-2 overflow-y-auto p-2"
                style={{ width: '600px', minHeight: '250px', maxHeight: '50vh' }}
            >
                <MemberRemoveList
                    currentUserId={currentUserId}
                    memberRoles={workspace.memberRoles}
                    onChange={handleChange}
                    selectedUserIds={selectedUserIds}
                    disabled={processing}
                />
            </div>
            {errorMessage && <div className="p-2 text-red-700">{errorMessage}</div>}
        </GeneralModal>
    );
};

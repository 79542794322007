import { useEffect, useState } from 'react';
import { DataSnapshot, RefBuilder, RTDBPath } from '@framework/repository';
import { TrackJS } from 'trackjs';

/**
 * Realtime Database の 「接続状態の検出」機構を利用して、クライアントのオンライン接続状態を判断する
 *
 * https://firebase.google.com/docs/database/web/offline-capabilities?hl=ja#section-connection-state
 */
export const useNetworkConnected = (timeoutSeconds = 0): boolean => {
    const [connected, setConnected] = useState<boolean>(true);

    useEffect(() => {
        const ref = RefBuilder.ref(RTDBPath.Firebase.connectedPath());
        let timeoutId: ReturnType<typeof setTimeout> | null = null;

        const callback = (snapshot: DataSnapshot) => {
            const connected = snapshot.val() === true;
            if (connected) {
                // 接続状態に変化した時には、切断状態に切り替えるタイマーを停止し、Stateを「接続中」に変更する
                if (timeoutId) {
                    clearTimeout(timeoutId);
                    timeoutId = null;
                    TrackJS.track('Network connected, and timer cleared.');
                }

                setConnected(connected);
                console.warn('Network connected, and state changed.');
            } else if (timeoutId === null) {
                // 切断状態に変化した時には、 timeoutSeconds の経過後にStateを「切断中」に変更する
                // timeoutSeconds の経過前に「接続中」に状態変化した時にはタイマーはキャンセルされ、Stateは「接続中」のまま維持される
                console.warn(`Network disconnected, wait ${timeoutSeconds} seconds before changing state.`);

                timeoutId = setTimeout(() => {
                    setConnected(connected);
                    console.warn('Network disconnected, and state changed.');
                }, timeoutSeconds * 1000);
            }
        };

        ref.on('value', callback);
        return () => ref.off('value', callback);
    }, [timeoutSeconds]);

    return connected;
};

/**
 * コマンドの実装で共通利用するユーティリティ関数（commandパッケージ外へのパブリック公開はしない）を定義
 */

import { IComparable } from '@model-framework/command/IComparable';

/**
 * コマンドのUndo/Redoチェック可否に利用する
 */
export const compare = <T extends IComparable<T> | boolean | number | string>(a: T, b: T): boolean => {
    switch (typeof a) {
        case 'string':
        case 'boolean':
        case 'number':
            return a === b;
        default:
            // IComparable<T>
            return a.isEqual(b);
    }
};

import { useCallback, useState } from 'react';
import { Loading } from '@framework/ui/atoms/Loading';
import { LoginMethodMenu } from './LoginMethodMenu';
import { useLoginErrorMessage } from './useLoginErrorMessage';
import { useOAuthLogin, ProviderName } from './useOAuthLogin';
import { PasswordLoginFormContainer } from './PasswordLoginFormContainer';

type Props = {
    allowSignUp?: boolean;
};

export const LoginForm: React.FC<Props> = ({ allowSignUp = false }: Props) => {
    const [processing, setProcessing] = useState(false);
    const oauthLogin = useOAuthLogin(allowSignUp ? 'signUp' : 'login');

    const handleOAuthLogin = useCallback(
        (providerName: ProviderName) => {
            setProcessing(true);
            oauthLogin(providerName);
        },
        [oauthLogin]
    );

    const handleLoginWithGoogle = useCallback(() => handleOAuthLogin('google'), [handleOAuthLogin]);
    const handleLoginWithQuadcept = useCallback(() => handleOAuthLogin('quadcept'), [handleOAuthLogin]);

    const [showPasswordLogin, setShowPasswordLogin] = useState<boolean>(false);
    const handleLoginWithPassword = useCallback(() => setShowPasswordLogin(true), []);
    const handleCloseLoginWithPassword = useCallback(() => setShowPasswordLogin(false), []);

    const loginErrorMessage = useLoginErrorMessage();

    if (showPasswordLogin) {
        return <PasswordLoginFormContainer onClose={handleCloseLoginWithPassword} />;
    }

    if (processing) {
        return (
            <div className="flex w-80 flex-col space-y-4 px-5 py-2">
                <Loading message="ログインしています..." />
            </div>
        );
    }

    return (
        <>
            <LoginMethodMenu
                onLoginWithGoogle={handleLoginWithGoogle}
                onLoginWithQuadcept={handleLoginWithQuadcept}
                onLoginWithPassword={handleLoginWithPassword}
            />

            {loginErrorMessage && <div className="w-1/2 min-w-80 text-red-700">{loginErrorMessage}</div>}
        </>
    );
};

import { UnixTimestampMilliSeconds } from '@schema-common/timestamp';

export const GROUP_LICENSE_TYPE_DICT: Record<GroupLicenseType, string> = {
    standard: 'スタンダード',
    academic: 'アカデミック',
    trial: 'トライアル',
    free: '無償提供',
} as const;

export type GroupLicenseType = 'standard' | 'academic' | 'trial' | 'free';

export type GroupLicenseJSON = {
    type: GroupLicenseType;
    count: number;
    expiresAt: UnixTimestampMilliSeconds;
};

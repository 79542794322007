import { useCallback, useEffect, useState } from 'react';
import { UserData } from '@user/UserData';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { PublicIconUploader } from '@framework/utils/PublicIconUploader';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';
import { EditUserForm } from './EditUserForm';
import { ConnectJijiModal, DisconnectJijiModal } from './jiji';
import { PasswordChangeModal, PasswordCreationModal } from './password';
import { ImageCropModal } from '@framework/ui/elements/ImageCropModal';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

type ModalName = 'createPassword' | 'changePassword' | 'connectJiji' | 'disconnectJiji';

type Props = {
    isOpen: boolean;
    onClose(): void;
    userData: UserData;
};

export const EditUserModal: React.FC<Props> = ({ isOpen, onClose, userData }: Props) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [name, setName] = useState<string>(userData.name);
    const [imageUrl, setImageUrl] = useState<string | null>(userData.imageUrl);
    const previousIsOpen = usePrevious(isOpen);
    const [modalName, setModalName] = useState<ModalName | null>(null);
    const [iconImage, setIconImage] = useState<File | null>(null);
    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);

    // isOpen が false から true に変化したときに、name を userData.name に設定する。
    useEffect(() => {
        if (isOpen && !previousIsOpen) {
            setName(userData.name);
            setImageUrl(userData.imageUrl);
        }
    }, [isOpen, previousIsOpen, userData]);

    useEffect(() => {
        if (uploadedImage) setIsCropModalOpen(true);
    }, [uploadedImage]);

    const handleChangeName = useCallback((name: string) => setName(name), []);

    const handleClose = useCallback(() => {
        setProcessing(false);
        setModalName(null);
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        if (name.length === 0) return;

        setProcessing(true);

        const newIconUrl = iconImage ? await PublicIconUploader.uploadUserIcon(userData.id, iconImage) : imageUrl;
        setImageUrl(newIconUrl);

        if (await FunctionsUserActions.editUserProfile(name, newIconUrl)) {
            handleClose();
        } else {
            alert('ユーザ情報の変更に失敗しました。');
            setProcessing(false);
        }
    }, [iconImage, userData.id, imageUrl, name, handleClose]);

    const handleCropModalClose = useCallback(() => {
        (document.getElementById('icon-image') as HTMLInputElement).value = '';
        setUploadedImage(null);
        setIsCropModalOpen(false);
    }, []);

    const handleCropModalSubmit = useCallback((image: File) => {
        setIconImage(image);
        setImageUrl(URL.createObjectURL(image));
        setUploadedImage(null);
        setIsCropModalOpen(false);
    }, []);

    const handleImageClear = useCallback(() => {
        setIconImage(null);
        setImageUrl(null);
    }, []);

    const handleUploadImage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) setUploadedImage(event.target.files[0]);
    }, []);

    const handleCreatePassword = useCallback(() => setModalName('createPassword'), []);
    const handleChangePassword = useCallback(() => setModalName('changePassword'), []);
    const handleConnectJiji = useCallback(() => setModalName('connectJiji'), []);
    const handleDisconnectJiji = useCallback(() => setModalName('disconnectJiji'), []);
    const handleCloseSubModal = useCallback(() => setModalName(null), []);

    if (modalName === 'createPassword') {
        return <PasswordCreationModal isOpen={isOpen} onClose={handleCloseSubModal} userData={userData} />;
    }

    if (modalName === 'changePassword') {
        return <PasswordChangeModal isOpen={isOpen} onClose={handleCloseSubModal} userData={userData} />;
    }

    if (modalName === 'connectJiji') {
        return <ConnectJijiModal isOpen={isOpen} onClose={handleCloseSubModal} userData={userData} />;
    }

    if (modalName === 'disconnectJiji') {
        return <DisconnectJijiModal isOpen={isOpen} onClose={handleCloseSubModal} userData={userData} />;
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleClose} className="flex flex-col">
                {/* header */}
                <div className="px-8 pb-4 pt-8 text-2xl font-bold">アカウント設定 | Account settings</div>
                <hr className="w-full" />

                {/* content */}
                <div className="flex flex-col p-8">
                    <EditUserForm
                        userData={userData}
                        name={name}
                        imageUrl={imageUrl}
                        onChangeName={handleChangeName}
                        onCreatePassword={handleCreatePassword}
                        onChangePassword={handleChangePassword}
                        onConnectJiji={handleConnectJiji}
                        onDisconnectJiji={handleDisconnectJiji}
                        onImageClear={handleImageClear}
                        onUploadImage={handleUploadImage}
                    />
                </div>

                {/* footer */}
                <hr className="w-full" />
                <div className="flex justify-end px-8 pb-8 pt-4">
                    <FlatButton onClick={handleClose}>閉じる | Close</FlatButton>
                    <FlatButton
                        color="brand"
                        onClick={handleSubmit}
                        className="ml-2 px-8"
                        disabled={name === '' || processing}
                        loading={processing}
                    >
                        保存 | Save
                    </FlatButton>
                </div>
            </Modal>
            {uploadedImage && (
                <ImageCropModal
                    isOpen={isCropModalOpen}
                    onClose={handleCropModalClose}
                    onSubmit={handleCropModalSubmit}
                    src={uploadedImage}
                />
            )}
        </>
    );
};

import { useCallback, useState } from 'react';
import { UserData } from '@user/UserData';
import { FlatButton } from '@framework/ui/atoms';
import { Loading } from '@framework/ui/atoms/Loading';
import { Modal } from '@framework/ui/elements';
import { ProviderName, useOAuthLogin } from '@user/Login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

type Props = {
    isOpen: boolean;
    onClose(): void;
    userData: UserData;
};

export const ConnectJijiModal: React.FC<Props> = ({ isOpen, onClose, userData }: Props) => {
    const [processing, setProcessing] = useState(false);
    const oauthLogin = useOAuthLogin('connect');

    const handleOAuthLogin = useCallback(
        (providerName: ProviderName) => {
            setProcessing(true);
            oauthLogin(providerName);
        },
        [oauthLogin]
    );

    const handleLoginWithGoogle = useCallback(() => handleOAuthLogin('google'), [handleOAuthLogin]);
    const handleLoginWithQuadcept = useCallback(() => handleOAuthLogin('quadcept'), [handleOAuthLogin]);

    const handleClose = useCallback(() => {
        setProcessing(false);
        onClose();
    }, [onClose]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} className="flex flex-col">
            {/* header */}
            <div className="px-8 pb-4 pt-8 text-2xl font-bold">外部認証プロバイダと連携する</div>
            <hr className="w-full" />

            {/* content */}
            <div className="flex flex-col items-center p-8">
                {processing ? (
                    <Loading />
                ) : (
                    <>
                        <div className="pb-4">
                            Balusに登録のメールアドレス<span className="px-2">{userData.mailAddress}</span>
                            と同じメールアドレスのアカウントを連携することが可能です。
                            <div className="pt-1 text-xs">
                                ※ メールアドレスの変更をご希望の場合には、
                                <a
                                    href="https://links.balus.app/contact-form"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-blue-700 hover:underline"
                                >
                                    お問合せフォーム <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                                からご連絡ください。
                            </div>
                        </div>

                        <div className="flex w-80 flex-col space-y-4 px-5 py-2">
                            <FlatButton color="brand" onClick={handleLoginWithGoogle}>
                                <span className="pr-2 text-lg">
                                    <FontAwesomeIcon icon={faGoogle} />
                                </span>
                                Googleでログインする
                            </FlatButton>

                            <FlatButton color="brand" onClick={handleLoginWithQuadcept}>
                                <span className="pr-2 text-lg font-bold">Q</span>
                                Quadceptでログインする
                            </FlatButton>
                        </div>
                    </>
                )}
            </div>

            {/* footer */}
            <hr className="w-full" />
            <div className="flex justify-end px-8 pb-8 pt-4">
                <FlatButton onClick={handleClose}>閉じる</FlatButton>
            </div>
        </Modal>
    );
};

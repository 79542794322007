import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { UserPublicProfile } from '@user/PublicProfile';
import { useOnlineStatus } from '@view-model/models/common/hooks/useOnlineStatus';
import { Viewer } from './Viewer';
import { createViewerRepository } from './ViewerRepository';
import { Rect } from '@view-model/models/common/basic';
import { getRealtimeDatabaseCurrentTimestamp } from '@framework/firebase/rtdb';
import { getClientId } from '@framework/app';
import throttle from 'lodash/throttle';

type ActivityHandler = () => void;

/**
 * 最後の操作から一定時間遅延させた後にアクティブ状態を変化させるカスタムフック。
 *
 * 引数: delaySeconds ... 遅延させる時間(秒数)
 * 戻り値: boolean ... アクティブ状態
 *        ActivityHandler ... 操作状態を伝えるハンドラー関数
 */
const useLastActiveTimer = (delaySeconds: number): [boolean, ActivityHandler] => {
    const [active, setActive] = useState(true);
    const timeoutIdRef = useRef<number>(null);
    const isUnmountedRef = useRef(false);

    const mouseMoveHandler = useCallback(() => {
        setActive(true);

        if (timeoutIdRef.current !== null) {
            window.clearTimeout(timeoutIdRef.current);
        }

        // マウスの操作がない場合、一定時間後にアクティブ状態をfalseにする。
        const timeoutId = window.setTimeout(() => {
            if (isUnmountedRef.current) {
                return;
            }
            setActive(false);
        }, delaySeconds * 1000);

        Object.assign(timeoutIdRef, { current: timeoutId });
    }, [delaySeconds]);

    useEffect(() => {
        return () => {
            isUnmountedRef.current = true;
        };
    }, []);

    return [active, mouseMoveHandler];
};

/**
 * 閲覧中ユーザのアイコン情報を永続化する。
 *
 * 画面上のマウスカーソルの動きに応じて ActivityHandler を呼び出すことで、
 * 最後の操作から一定時間だけアイコンを表示する。
 */
export const useWriteViewer = (
    viewModelId: string,
    currentUser: UserPublicProfile | null,
    rect: Rect
): ActivityHandler => {
    const DelaySeconds = 180;
    const [active, mouseMoveHandler] = useLastActiveTimer(DelaySeconds);

    const onlineStatus = useOnlineStatus();
    const saveViewer = useMemo(
        () =>
            throttle((currentUser: UserPublicProfile, rect: Rect, viewModelId: string) => {
                const repository = createViewerRepository(viewModelId, getClientId());
                const { id: userId, name, imageUrl } = currentUser;
                const clientId = getClientId();
                getRealtimeDatabaseCurrentTimestamp().then((timestamp) => {
                    const viewer = new Viewer(userId, name, imageUrl, clientId.toString(), rect, timestamp);
                    // ネットワークが切れたときに削除
                    repository.onDisconnectRemove().then(() => {
                        repository.save(viewer);
                    });
                });
            }, 1000), // ズームや移動時に高頻度で呼び出されるので、スロットリングをかける
        []
    );

    useEffect(() => {
        // ユーザーが取得できていない時には、書き込みをしない。
        if (currentUser == null) return;
        // オフラインの時には、書き込みをしない。また、onlineStatusを監視することで、オフラインからオンラインになった際に登録し直すことができる。
        if (!onlineStatus) return;

        const repository = createViewerRepository(viewModelId, getClientId());

        if (active) {
            // 正確な時刻を取得したTimestampでViewerを作成する
            saveViewer(currentUser, rect, viewModelId);
        } else {
            repository.delete();
        }
    }, [active, currentUser, onlineStatus, rect, saveViewer, viewModelId]);

    return mouseMoveHandler;
};

import { UserKey } from '@user/domain';
import { WorkspaceMemberRole } from './WorkspaceMemberRole';
import { WorkspaceJSON } from '@schema-app/workspaces/{workspaceKey}/WorkspaceJSON';

type WorkspaceMemberRoleRecord = Record<string, WorkspaceMemberRole>;

export class WorkspaceMemberRoles {
    private readonly memberRoles: Readonly<WorkspaceMemberRoleRecord>;
    constructor(memberRoles: WorkspaceMemberRoleRecord) {
        this.memberRoles = { ...memberRoles };
    }

    dump(): WorkspaceJSON['members'] {
        return { ...this.memberRoles };
    }

    static load(dump: WorkspaceJSON['members'] | null): WorkspaceMemberRoles {
        return this.fromEntities(Object.entries(dump || {}));
    }

    static fromEntities(entries: [string, string][]): WorkspaceMemberRoles {
        const roles: WorkspaceMemberRoleRecord = {};
        entries.forEach(([key, value]) => {
            roles[key] = WorkspaceMemberRole.load(value);
        });
        return new WorkspaceMemberRoles(roles);
    }

    getRoleOf(userId: string): WorkspaceMemberRole | null {
        return this.memberRoles[`User:${userId}`] || null;
    }

    get length(): number {
        return Object.keys(this.memberRoles).length;
    }

    entries(): [UserKey, WorkspaceMemberRole][] {
        return Object.entries(this.memberRoles).map(([key, value]) => [UserKey.load(key), value]);
    }

    userIds(): string[] {
        return Object.keys(this.memberRoles).map((key) => key.replace('User:', ''));
    }

    userIdsByRole(role: WorkspaceMemberRole): string[] {
        const entries = this.entries().filter(([, value]) => value === role);
        return entries.map(([key]) => `${key.id}`);
    }

    hasMembersOf(role: WorkspaceMemberRole): boolean {
        return this.userIdsByRole(role).length > 0;
    }

    with(userId: string, role: WorkspaceMemberRole): WorkspaceMemberRoles {
        const roles = { ...this.memberRoles };
        roles[`User:${userId}`] = role;
        return new WorkspaceMemberRoles(roles);
    }

    /**
     * もう一つの WorkspaceMemberRoles オブジェクトを受け取り、
     * 両者のオブジェクトに存在する役割の変化したワークスペースメンバーを返します。
     * （返却するオブジェクトの役割は、 other で指定された役割を返します）
     *
     * @param other 差分を求める WorkspaceMemberRoles オブジェクト
     */
    difference(other: WorkspaceMemberRoles): WorkspaceMemberRoles {
        let diff = new WorkspaceMemberRoles({});

        this.userIds().forEach((userId) => {
            const currentRole = this.getRoleOf(userId);
            const otherRole = other.getRoleOf(userId);

            if (currentRole && otherRole && currentRole != otherRole) {
                diff = diff.with(userId, otherRole);
            }
        });
        return diff;
    }
}

import { DisplayOrderTreeZone } from './DisplayOrderTreeZone';
import { LinkPool } from './LinkPool';
import { LinkId } from '../types';
import { NodeId, StickyZoneId } from '@schema-common/base';
import { differenceBy } from 'lodash';

// 順序ルール: https://docs.google.com/presentation/d/1pVI090GpRnhEA2ATDSj3UQNuz5oD5e0Afeb2CARN7G8/edit#slide=id.gb7ff438ae1_0_25
export const orderItemIds = (
    linkPool: LinkPool,
    zones: DisplayOrderTreeZone[],
    nodeIds: NodeId[],
    shouldExcludedZoneIds: StickyZoneId[],
    shouldExcludedNodeIds: NodeId[]
): (NodeId | StickyZoneId | LinkId)[] => {
    const zoneIds = zones.map((zone) => zone.id);

    // ゾーンのリンク
    let zoneLinks = linkPool.connectAndTakeConnectedLinks(zoneIds);

    // ゾーン（内部の要素含む）とリンク
    const orderedZoneIds = zones
        .map((zone) => {
            // ゾーン同士を結ぶリンクは、どちらかのゾーンが描画される直前に描画されるようにする
            // そうすることで、リンクが他のゾーンの後ろに隠れてしまうのを防ぐ
            // https://github.com/levii/balus-app/issues/2676
            const connectedZoneLinks = zoneLinks.filter(
                (zoneLink) => zoneLink.dest === zone.id || zoneLink.src === zone.id
            );
            const connectedLinkIds = connectedZoneLinks.map((link) => link.id);

            // 描画されるべきゾーンリンクを除去
            zoneLinks = differenceBy(zoneLinks, connectedZoneLinks, 'id');

            return [...connectedLinkIds, ...zone.order(linkPool, shouldExcludedNodeIds, shouldExcludedZoneIds)];
        })
        .flat();

    // ノードとそのリンク
    const nodeLinks = linkPool.connectAndTakeConnectedLinks(nodeIds);
    const nodeLinkIds = nodeLinks.map((rel) => rel.id);

    return orderedZoneIds.concat(nodeLinkIds).concat(nodeIds);
};

import { ModelKey } from '@view-model/domain/key';
import { ModelId } from '@schema-common/base';
import { ModelType, ModelNamespace } from '@view-model/domain/model';
import { Model } from '@framework/domain/collection/CollectionEvents';

export interface IModel {
    key: ModelKey;
    version: number;
}

export abstract class ModelEntity extends Model<ModelKey> {
    public readonly key: ModelKey;
    public readonly type: ModelType = ModelType.Model;
    public readonly namespace: ModelNamespace = ModelNamespace.Balus;
    public readonly version: number;

    protected constructor(attributes: IModel) {
        super();

        this.key = attributes.key;
        this.version = attributes.version;
    }

    public isEqual(other: ModelEntity): boolean {
        return (
            other instanceof ModelEntity &&
            this.key.isEqual(other.key) &&
            this.type == other.type &&
            this.namespace == other.namespace
        );
    }

    get id(): ModelId {
        return this.key.id as ModelId;
    }

    public abstract clone(): ModelEntity;

    public abstract cloneNew(): ModelEntity;
}

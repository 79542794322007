import { MainMenuBox } from '@model-framework/menu';

type Props = {
    menuSize: number;
    isSelected: boolean;
    onClick(): void;
    text: string;
};

export const FontSizeMainMenu: React.FC<Props> = ({ menuSize, isSelected, onClick, text }: Props) => {
    return (
        <>
            <MainMenuBox isSelected={isSelected} menuSize={menuSize} onClick={onClick} text={text} />
            {/* MenuBoxの中央に寄せる */}
            <text
                pointerEvents="none"
                textAnchor="middle"
                dominantBaseline="central"
                fill="black"
                fontWeight="bold"
                x={menuSize * 0.5}
                y={menuSize * 0.5}
                fontSize="38"
            >
                A
            </text>
        </>
    );
};

import { Point } from '@view-model/models/common/basic';

/**
 * 固定列で折り返すメニューレイアウト
 */
export class FixedColumnTileLayout {
    constructor(
        private readonly menuSize: number,
        private readonly numColumns: number
    ) {}

    /**
     * メニュー番号に応じたメニューの左上座標位置を返す。
     * @param menuIndex
     */
    topLeftOf(menuIndex: number): Point {
        const { menuSize, numColumns } = this;

        const row = Math.floor(menuIndex / numColumns);
        const col = menuIndex % numColumns;

        return new Point(col * menuSize, row * menuSize);
    }
}

import { ICommand } from '@model-framework/command';
import { IOrderRollback } from '@model-framework/display-order/domain';
import { NodeId, StickyZoneId } from '@schema-common/base';
import { DisplayOrderRepository } from '@model-framework/display-order/infrastructure';

export class StickyZoneChangeParentZoneCommand implements ICommand {
    private orderRollback: IOrderRollback | undefined;

    constructor(
        private readonly childIds: (NodeId | StickyZoneId)[],
        private readonly newParentZoneId: StickyZoneId | null,
        private readonly displayOrderRepository: DisplayOrderRepository
    ) {}

    do(): void {
        this.displayOrderRepository
            .changeParentZone(this.childIds, this.newParentZoneId)
            .then((orderRollback) => (this.orderRollback = orderRollback));
    }

    undo(): void {
        if (!this.orderRollback) return;
        this.displayOrderRepository.rollback(this.orderRollback);
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return !!this.orderRollback;
    }

    async canRedo(): Promise<boolean> {
        return !!this.orderRollback;
    }
}

import { Id, Key } from '@framework/domain';

export class UserKey extends Key {
    protected get KINDS(): string[] {
        return ['User'];
    }

    public static buildFromId(id: Id): UserKey {
        return new UserKey(`User:${id}`);
    }

    public static buildUnknownUserKey(): UserKey {
        return this.buildFromId('unknown');
    }

    public dump(): string {
        return this.toString();
    }

    public static load(dump: string): UserKey {
        return new UserKey(dump);
    }
}

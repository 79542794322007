import { LinkLineStyleMainMenu } from './LinkLineStyleMainMenu';
import { LinkLineStyle } from '../LinkLineStyle';
import { SubMenuListContainer } from '@model-framework/menu';
import { LinkLineStyleSubMenu } from './LinkLineStyleSubMenu';

type Props = {
    menuSize: number;
    currentLineStyle?: LinkLineStyle; // 複数選択時に一意に決まらないことがある
    items: Readonly<LinkLineStyle[]>;
    isOpen: boolean;
    onMenuClicked(): void;
    onSelected(newLineStyle: LinkLineStyle): void;
    text: string;
};

export const LinkLineStyleMenuView: React.FC<Props> = ({
    menuSize,
    currentLineStyle,
    items,
    isOpen,
    onMenuClicked,
    onSelected,
    text,
}: Props) => {
    const size = {
        width: menuSize * items.length,
        height: menuSize,
    };

    return (
        <g>
            <LinkLineStyleMainMenu menuSize={menuSize} isSelected={isOpen} onClick={onMenuClicked} text={text} />
            {isOpen && (
                <SubMenuListContainer menuSize={menuSize} rectSize={size}>
                    {items.map((lineStyle: LinkLineStyle, i: number) => (
                        <g key={lineStyle} transform={`translate(${menuSize * i}, 0)`}>
                            <LinkLineStyleSubMenu
                                menuSize={menuSize}
                                lineStyle={lineStyle}
                                isSelected={currentLineStyle === lineStyle}
                                onClick={() => onSelected(lineStyle)}
                            />
                        </g>
                    ))}
                </SubMenuListContainer>
            )}
        </g>
    );
};

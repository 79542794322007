import { RefObject, useEffect } from 'react';
import { select } from 'd3-selection';

/**
 * d3 で ダブルクリックのコールバックを利用するためのフック
 * @param ref ダブルクリックイベントを受け取りたいHTML/SVG要素のref
 * @param onDblClick イベントハンドラ
 * @param stopPropagation イベント伝搬を停止するか否かを決めるbool値
 */
export const useD3DblClickCallback = <T extends HTMLElement | SVGElement>(
    ref: RefObject<T>,
    onDblClick: (event: MouseEvent) => void,
    stopPropagation = false
): void => {
    useEffect(() => {
        if (!ref.current) return;

        const dom = ref.current;
        select(dom).on('dblclick', (event: MouseEvent) => {
            if (stopPropagation) {
                event.stopPropagation();
            }
            onDblClick(event);
        });

        return () => {
            select(dom).on('dblclick', null);
        };
    }, [ref, onDblClick, stopPropagation]);
};

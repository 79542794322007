import { MultiplicityTextUtil } from '@view-model/models/common/utils/MultiplicityTextUtil';

export class MultiplicityTextValidator {
    /**
     * 引数に与えた文字列が多重度として正しい書式であるかを判定します。
     * @param text
     */
    public static isValid(text: string): boolean {
        const replacedText = MultiplicityTextUtil.replaceToAvailableString(text);

        // 入力値が数字の場合（例: 「1」「3」）
        if (MultiplicityTextValidator.isNumber(replacedText)) {
            return true;
        }

        // 入力値が数字でない場合（例: 「1..*」「2..3」）、「..」区切りで上限値と下限値に分割できなければならない
        const texts = replacedText.split('..');
        if (texts.length !== 2) {
            return false;
        }

        // 下限値と上限値を取得
        const lowerText = texts[0];
        const upperText = texts[1];

        // 下限値は数字でなければならない
        if (!MultiplicityTextValidator.isNumber(lowerText)) {
            return false;
        }

        // 上限値は数値かワイルドカードでなければならない
        if (!MultiplicityTextValidator.isNumber(upperText) && !MultiplicityTextValidator.isWildcard(upperText)) {
            return false;
        }

        // ここまでの書式を満たした上で上限値がワイルドカードなら、下限値がどのような値であっても多重度として正しい
        if (MultiplicityTextValidator.isWildcard(upperText)) {
            return true;
        }

        // 上限値がワイルドカードでない場合は、上限値が下限値以上であれば多重度として正しい
        const lower = parseInt(lowerText);
        const upper = parseInt(upperText);
        return lower <= upper;
    }

    public static isNumber(text: string): boolean {
        const regex = /^[0-9]+$/;
        return !!text.match(regex);
    }

    public static isWildcard(text: string): boolean {
        return text === '*';
    }
}

import { Timestamp } from '@framework/Timestamp';
import { RefBuilder, RTDBPath } from '@framework/repository';

/**
 * Realtime Database サーバとクライアントの時刻オフセットを取得し、
 * 精度の高い現在時刻タイムスタンプオブジェクトを返す。
 *
 * @return {Timestamp} 現在時刻タイムスタンプ
 */
export const getRealtimeDatabaseCurrentTimestamp = async (): Promise<Timestamp> => {
    // ミリ秒単位のクライアントとRealtimeDatabaseサーバーのオフセット(ミリ秒)を取得
    // https://firebase.google.com/docs/database/web/offline-capabilities?hl=ja
    const offsetRef = RefBuilder.ref(RTDBPath.Firebase.serverTimeOffsetPath());
    const snapshot = await offsetRef.once('value');

    const offset = snapshot.val() as number;

    return Timestamp.now().addMilliSeconds(offset);
};

import { ModelId, ViewId, ViewModelId } from '@schema-common/base';
import type { Position } from '@view-model/models/common/types/ui';
import { useCommandManager } from '@model-framework/command';
import { useRef } from 'react';
import { ViewOperation } from '@view-model/adapter';
import { ViewContext, ViewContextType } from '@view-model/ui/components/View/ViewContext';

type Props = {
    viewModelId: ViewModelId;
    viewId: ViewId;
    modelId: ModelId;
    children: React.ReactNode;
    viewPosition: Position;
};

export const ViewContextProvider: React.FC<Props> = ({
    viewModelId,
    viewId,
    modelId,
    children,
    viewPosition,
}: Props) => {
    const commandManager = useCommandManager();

    // https://react.dev/reference/react/useRef#avoiding-recreating-the-ref-contents
    const adapterRef = useRef<ViewContextType>({
        viewOperation: null,
        viewPosition: null,
    });

    if (adapterRef.current.viewOperation === null) {
        adapterRef.current.viewOperation = new ViewOperation(viewModelId, viewId, modelId, commandManager);
    }

    adapterRef.current.viewPosition = viewPosition;

    return <ViewContext.Provider value={adapterRef.current}>{children}</ViewContext.Provider>;
};

import { Point, Rect } from '@view-model/models/common/basic';
import { CalloutTailBaseLine } from './CalloutTailBaseLine';
import { ICalloutRectEdge } from './ICalloutRectEdge';

export class CalloutRectVerticalEdge implements ICalloutRectEdge {
    constructor(
        private readonly x: number,
        private readonly topY: number,
        private readonly bottomY: number
    ) {}

    private get centerY(): number {
        const { topY, bottomY } = this;
        return (topY + bottomY) * 0.5;
    }

    // しっぽ三角形の底辺になる線分
    private tailBaseLine(margin: number, length: number): CalloutTailBaseLine {
        const { x, topY } = this;

        const p1 = new Point(x, topY + margin);
        const p2 = p1.addY(length);

        return new CalloutTailBaseLine(p1, p2);
    }

    private calcBaseLine(targetCenterY: number, margin: number, length: number): CalloutTailBaseLine {
        const { centerY } = this;

        const line = this.tailBaseLine(margin, length);

        // 対象との位置関係に応じて位置を反転させる
        return centerY < targetCenterY ? line.symmetricOnY(centerY) : line;
    }

    tailBaseLineFor(targetRect: Rect, margin: number, length: number): CalloutTailBaseLine {
        return this.calcBaseLine(targetRect.getCenterPoint().y, margin, length);
    }
}

export class MultiplicityTextUtil {
    public static replaceToAvailableString(text: string): string {
        const numberReplacedText = MultiplicityTextUtil.replaceNumberToHalfwidth(text);
        const replacedText = MultiplicityTextUtil.replaceWildcardToHalfwidth(numberReplacedText);
        return MultiplicityTextUtil.removeSpace(replacedText);
    }

    private static replaceNumberToHalfwidth(text: string): string {
        return text.replace(/[０-９]/g, function (s) {
            // 全角数字の文字コードから65248個前が半角数字の文字コード
            return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
    }

    private static replaceWildcardToHalfwidth(text: string): string {
        return text.replace(/[＊]/g, '*');
    }

    private static removeSpace(text: string): string {
        return text.replace(/\s/g, '');
    }
}

import { useCallback, useEffect, useState } from 'react';
import { CreateCommand, UpdateCommand, useCommandManager, useUndoableTextEdit } from '@model-framework/command';
import { DeleteCommand } from '@model-framework/command/DeleteCommand';
import { ElementDescription, ElementDescriptionRepository } from '../domain';

export const useElementDescriptionContent: (
    description: ElementDescription,
    descriptionRepository: ElementDescriptionRepository
) => {
    content: string | null;
    onStartEdit(text: string): void;
    onEdit(text: string): void;
    onEndEdit(text: string): void;
} = (description: ElementDescription, descriptionRepository: ElementDescriptionRepository) => {
    const [content, setContent] = useState<string | null>(null);

    useEffect(() => {
        setContent(description.content);
    }, [description.content]);

    const saveText = useCallback(
        (text: string) => {
            setContent(text); // 予めセットしないと、FirebaseからのコールバックでIMEの変換が確定されてしまうため先にstate更新する
            descriptionRepository.save(description.withContent(text)).then();
        },
        [description, descriptionRepository]
    );

    const commandManager = useCommandManager();

    /**
     * 初回編集時のハンドラ。
     */
    const handleOnInitialEdit = useCallback(
        (initialContent: string) => {
            // 初回の編集は作成として扱い、Undo時には削除されるようにする
            const command = new CreateCommand(description.withContent(initialContent), descriptionRepository);
            commandManager.execute(command);
        },

        [commandManager, description, descriptionRepository]
    );

    /**
     * 編集した結果が空文字だった場合の処理ハンドラ。
     */
    const handleOnEmptyContent = useCallback(
        (initialContentOnEdit: string) => {
            // 削除の復元は編集開始時のコンテンツに戻す
            const command = new DeleteCommand(description.withContent(initialContentOnEdit), descriptionRepository);

            if (initialContentOnEdit === '') {
                // 元々空だった場合は初回編集でUndoの必要がないため、Undoスタックに積まずに削除する
                command.do();
            } else {
                commandManager.execute(command);
            }
        },
        [commandManager, description, descriptionRepository]
    );

    const handleOnEndEdit = useCallback(
        (initialContent: string, currentContent: string) => {
            // 空文字のときは削除
            if (currentContent === '') {
                handleOnEmptyContent(initialContent);
                return;
            }

            // 変化がなかった場合は何もしない
            if (initialContent === currentContent) return;

            // 初回編集の場合、更新ではなく作成処理として扱う（Undoを考慮）
            if (initialContent === '') {
                handleOnInitialEdit(currentContent);
                return;
            }

            const command = new UpdateCommand(
                description.withContent(initialContent),
                description.withContent(currentContent),
                descriptionRepository
            );
            commandManager.execute(command);
        },
        [description, descriptionRepository, commandManager, handleOnEmptyContent, handleOnInitialEdit]
    );

    const { onStartEdit, onEdit, onEndEdit } = useUndoableTextEdit(saveText, handleOnEndEdit);

    return { content, onStartEdit, onEdit, onEndEdit };
};

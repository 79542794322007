import { LinkLineStyle } from '../LinkLineStyle';
import { SubMenuBox } from '@model-framework/menu';

type Props = {
    lineStyle: LinkLineStyle;
    menuSize: number;
    isSelected: boolean;
    onClick(): void;
};

export const LinkLineStyleSubMenu: React.FC<Props> = ({ lineStyle, menuSize, isSelected, onClick }: Props) => {
    return (
        <g onClick={onClick}>
            <SubMenuBox isSelected={isSelected} menuSize={menuSize} />
            <g transform={`translate(${menuSize / 2}, ${menuSize / 2})`}>
                <line
                    stroke={isSelected ? 'white' : '#aaa'}
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeDasharray={lineStyle === LinkLineStyle.Solid ? 'none' : '3px 6px'}
                    x1={-10}
                    y1={0}
                    x2={10}
                    y2={0}
                />
            </g>
        </g>
    );
};

import { Point } from '@view-model/models/common/basic';

export class MenuLayout {
    /**
     * メニューサイズからサブメニューリストの左上座標（メインメニュー左上座標からの相対座標）を計算して返します
     * @param menuSize
     */
    static subMenuListTopLeft(menuSize: number): Point {
        return new Point(0, menuSize * 1.2);
    }
}

import { classNames } from '@framework/utils';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type TabButtonProps = {
    children: React.ReactNode;
    isActive: boolean;
    handleClick: () => void;
};

const TabButton: React.FC<TabButtonProps> = ({ children, isActive, handleClick }: TabButtonProps) => {
    return (
        <button
            className={classNames(
                'flex-1 border-0 border-b-2 hover:bg-gray-300 focus:outline-none',
                isActive ? 'border-brand text-brand' : 'border-gray-300'
            )}
            onClick={handleClick}
            // tailwind v3 に更新すれば、 break-keep クラス指定が利用可能。
            style={{ wordBreak: 'keep-all' }}
        >
            {children}
        </button>
    );
};

export type SidebarTabTarget = 'Shared' | 'Personal' | 'Bookmark';

type Props = {
    isSearchMode: boolean;
    target: SidebarTabTarget;
    handleShowBookmarks: () => void;
    handleShowShared: () => void;
    handleShowPersonal: () => void;
};

export const SidebarTabContainer: React.FC<Props> = ({
    isSearchMode,
    target,
    handleShowBookmarks,
    handleShowShared,
    handleShowPersonal,
}: Props) => {
    if (isSearchMode) {
        return <div className="border-0 border-b-2 border-gray-400 pl-4 text-xs text-gray-700">検索結果</div>;
    }

    return (
        <div className="flex text-xs">
            <TabButton isActive={target === 'Shared'} handleClick={handleShowShared}>
                <FontAwesomeIcon icon={faUsers} />
                <span className="mr-1" />
                共有
            </TabButton>
            <TabButton isActive={target === 'Personal'} handleClick={handleShowPersonal}>
                <FontAwesomeIcon icon={faUser} />
                <span className="mr-1" />
                個人
            </TabButton>
            <TabButton isActive={target === 'Bookmark'} handleClick={handleShowBookmarks}>
                ブックマーク
            </TabButton>
        </div>
    );
};

import { useCallback, useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Loading } from '@framework/ui/atoms';
import { useStickyModelContentsOperation } from '../Model';
import { Rect } from '@view-model/models/common/basic';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';
import { GroupId, ViewModelId } from '@schema-common/base';
import { useDropzone } from 'react-dropzone';
import { classNames } from '@framework/utils';
import { toast } from 'react-hot-toast';
import { ViewModelAsset } from '@view-model/domain/view-model';
import { useGetTransformedVisibleAreaCenterPoint } from '@user/pages/ViewModelPage';

type Props = {
    groupId: GroupId;
    viewModelId: ViewModelId;
    viewRect: Rect;
    onClose(): void;
};

export const AssetUploadModal: React.FC<Props> = ({ groupId, viewModelId, viewRect, onClose }: Props) => {
    const [uploading, setUploading] = useState(false);
    const operation = useStickyModelContentsOperation();
    const userProfile = useCurrentUserPublicProfile();
    const getTransformedVisibleAreaCenterPoint = useGetTransformedVisibleAreaCenterPoint();

    const onDrop = useCallback(
        async (files: File[]) => {
            if (!userProfile) return;

            if (files.length > 10) {
                toast.error('一度にアップロード可能な画像は10枚までです');
                return;
            }

            setUploading(true);

            await Promise.all(
                files.map(async (file) => {
                    const asset = ViewModelAsset.buildFromFile(file, userProfile.id);
                    const result = await asset.upload(groupId, viewModelId, file);

                    if (result) {
                        toast.error(result);
                        return;
                    }

                    const markdown = asset.imageMarkdown(groupId, viewModelId, location.href);
                    const createPoint = operation.getCreatePoint(getTransformedVisibleAreaCenterPoint(viewRect));
                    operation.addDescriptionPanel(createPoint, markdown);
                    return;
                })
            );

            setUploading(false);
            onClose();
        },
        [operation, groupId, onClose, userProfile, viewModelId, getTransformedVisibleAreaCenterPoint, viewRect]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <GeneralModal
            isOpen
            onClose={onClose}
            title="画像をアップロードする | Upload image"
            submitButton={<></>}
            cancelButton={<></>}
        >
            <div className="m-4" style={{ width: '600px', height: '300px' }}>
                <div
                    {...getRootProps()}
                    className={classNames(
                        'h-full w-full cursor-pointer border border-dashed border-black',
                        'flex items-center justify-center',
                        isDragActive ? 'bg-gray-200' : ''
                    )}
                >
                    {uploading ? (
                        <Loading message="アップロードしています..." />
                    ) : (
                        <span>
                            クリックして画像ファイルを選択するか、
                            <br />
                            ドラッグ&ドロップでアップロードできます。
                        </span>
                    )}
                    <input type="file" {...getInputProps()} accept="image/*" disabled={uploading} />
                </div>
            </div>
        </GeneralModal>
    );
};

import { useCallback, useEffect, useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { Folder } from '@workspace/view-model-folder/domain/Folder';

type Props = {
    folder: Folder;
    isOpen: boolean;
    hasChildren: boolean;
    onSubmit(): void;
    onClose(): void;
};

export const FolderDeleteModal: React.FC<Props> = ({ folder, isOpen, hasChildren, onSubmit, onClose }: Props) => {
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        setDisabled(hasChildren);
    }, [hasChildren, isOpen]);

    const handleSubmit = useCallback(() => {
        onSubmit();
        onClose();
    }, [onSubmit, onClose]);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={handleClose}
            title={'フォルダを削除します'}
            submitButton={
                <Button color="danger" onClick={handleSubmit} disabled={disabled}>
                    削除
                </Button>
            }
            cancelButton={
                <Button onClick={handleClose} color="secondary">
                    キャンセル
                </Button>
            }
        >
            <div className="p-2">
                {/* ゴミ箱機能が実装されるまでは、中身のあるフォルダは削除不可とする */}
                {hasChildren ? (
                    <div>
                        中身のあるフォルダは削除できません。
                        <br />
                        フォルダの中身を空にしてから削除してください。
                    </div>
                ) : (
                    <div>
                        削除したフォルダは復元することはできません。
                        <br />「{folder.name}」を削除しますか？
                    </div>
                )}
            </div>
        </GeneralModal>
    );
};

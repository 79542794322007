import { Point, Rect, Triangle } from '@view-model/models/common/basic';

export class CalloutTailBaseLine {
    constructor(
        private readonly p1: Point,
        private readonly p2: Point
    ) {}

    symmetricOnX(centerX: number): CalloutTailBaseLine {
        const { p1, p2 } = this;
        return new CalloutTailBaseLine(p1.symmetricOnX(centerX), p2.symmetricOnX(centerX));
    }

    symmetricOnY(centerY: number): CalloutTailBaseLine {
        const { p1, p2 } = this;
        return new CalloutTailBaseLine(p1.symmetricOnY(centerY), p2.symmetricOnY(centerY));
    }

    tailTo(targetRect: Rect): Triangle {
        const { p1, p2 } = this;

        return new Triangle(p1, p2, targetRect.intersectPointFromCenter(p1));
    }
}

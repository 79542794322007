import { useCallback } from 'react';
import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { Rect } from '@view-model/models/common/basic';
import { Viewer } from '../Viewer';
import { generateDefaultUserIcon } from '@framework/utils';

type Props = {
    viewer: Viewer;
    setClientViewBox: (viewBox: Rect) => void;
};

export const ViewerIcon: React.FC<Props> = ({ viewer, setClientViewBox }: Props) => {
    const handleClick = useCallback(() => {
        setClientViewBox(viewer.rect);
    }, [setClientViewBox, viewer.rect]);

    return (
        <DarkTooltip tooltip={viewer.userName} placement={'bottom'}>
            {/* 背後にグレーの丸を配置して、その手前に中心を揃えてアイコン画像を表示している */}
            {/* 詳細:https://github.com/levii/balus-app/pull/3019 */}
            <div
                className="relative size-6 cursor-pointer rounded-full bg-gray-400 focus:border-white focus:outline-none"
                onClick={handleClick}
            >
                {viewer.imageUrl ? (
                    <img
                        className="absolute left-1/2 top-1/2 size-5/6 -translate-x-1/2 -translate-y-1/2 transform-gpu overflow-hidden rounded-full object-cover"
                        src={viewer.imageUrl}
                        alt={viewer.userName}
                    />
                ) : (
                    <div className="absolute left-1/2 top-1/2 size-5/6 -translate-x-1/2 -translate-y-1/2 transform-gpu overflow-hidden rounded-full object-cover">
                        {generateDefaultUserIcon({
                            id: viewer.userId,
                            name: viewer.userName,
                            size: 20,
                        })}
                    </div>
                )}
            </div>
        </DarkTooltip>
    );
};

import { Point, Size } from '@view-model/models/common/basic';
import { TextElement } from '@view-model/ui/components/Model';

class FixedCellSizeTableRow {
    private readonly cols: string[];

    constructor(
        texts: string[],
        private readonly cellWidth: number,
        private readonly margin: number
    ) {
        this.cols = texts.map((text) => text.trim());
    }

    width(): number {
        const { colWidth, cols, margin } = this;
        return Math.max(0, colWidth * cols.length - margin);
    }

    cells(y: number): TextElement[] {
        return this.cols.map((text, i) => new TextElement(text, new Point(i * this.colWidth, y)));
    }

    cellCount(): number {
        return this.cols.length;
    }

    private get colWidth(): number {
        return this.cellWidth + this.margin;
    }
}

/**
 * 固定のセルサイズを持つテキストテーブル（テキスト行列）。
 * UI要素に変換する前提でUI座標系のサイズを持つクラス。UI要素への変換前に全体のサイズを知りたい場合に有用。
 */
export class FixedCellSizeTextTable {
    private readonly rows: FixedCellSizeTableRow[];
    private readonly cellHeight: number;

    constructor(
        texts: string[][],
        cellSize: Size,
        private readonly margin: number
    ) {
        this.rows = texts.map((row) => new FixedCellSizeTableRow(row, cellSize.width, margin));
        this.cellHeight = cellSize.height;
    }

    cells(): TextElement[] {
        return this.rows.flatMap((row, j) => {
            const y = j * this.rowHeight;

            return row.cells(y);
        });
    }

    width(): number {
        // 行ごとに幅が変わる可能性があるので、最大幅を取得する
        return this.rows.reduce((maxWidth, row) => Math.max(maxWidth, row.width()), 0);
    }

    height(): number {
        const { rows, rowHeight, margin } = this;
        return Math.max(0, rowHeight * rows.length - margin);
    }

    size(): Size {
        return new Size(this.width(), this.height());
    }

    cellCount(): number {
        return this.rows.reduce((count, row) => count + row.cellCount(), 0);
    }

    private get rowHeight(): number {
        return this.cellHeight + this.margin;
    }
}

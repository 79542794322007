import { ModelEntity, ModelCollection, StickyModel } from '@view-model/domain/model';
import { ModelJSON } from '@schema-app/view-model/contents/{viewModelId}/models/{modelId}/ModelJSON';
import { ModelContentMapper } from './ModelContentMapper';
import { EventType, RefBuilder, Reference, RTDBPath } from '@framework/repository';
import { ModelId, ViewModelId } from '@schema-common/base';
import { ClientSelectedItemRepository } from '@model-framework/client-selected-item';
import { StickyModelLoadableJSON } from '@view-model/domain/model/StickyModel';

type Unsubscribe = [EventType, ReturnType<Reference['on']>];
type Callback = (model: StickyModel) => void;

export class ModelRepository {
    private readonly _mapper: ModelContentMapper;
    private unsubscribes: Array<Unsubscribe> = [];

    public constructor(private readonly viewModelId: ViewModelId) {
        this._mapper = new ModelContentMapper();
    }

    private modelsRef(): Reference {
        return RefBuilder.ref(RTDBPath.Model.modelsPath(this.viewModelId));
    }

    private modelRef(modelId: ModelId): Reference {
        return RefBuilder.ref(RTDBPath.Model.modelPath(this.viewModelId, modelId));
    }

    async delete(modelId: ModelId): Promise<void> {
        await RefBuilder.ref().update({
            [RTDBPath.Model.modelPath(this.viewModelId, modelId)]: null,
            [RTDBPath.Model.modelContentPath(this.viewModelId, modelId)]: null,
        });

        // 削除したモデル内の要素を選択している情報を一括削除する
        const selectingUserRepository = new ClientSelectedItemRepository(this.viewModelId, modelId);
        await selectingUserRepository.deleteAll();
    }

    async load(modelId: ModelId): Promise<StickyModel | null> {
        const ref = this.modelRef(modelId);
        const snapshot = await ref.once('value');
        const value = snapshot && (snapshot.val() as StickyModelLoadableJSON);
        if (!value) return null;

        return StickyModel.load(value);
    }

    async save(model: ModelEntity): Promise<void> {
        const ref = this.modelRef(model.id);
        const value = this._mapper.toSerialize(model);
        await ref.set(value);
    }

    async saveCollection(models: ModelCollection): Promise<void> {
        const ref = this.modelsRef();
        const updates: Record<string, ModelJSON> = {};

        models.list().forEach((model: ModelEntity) => {
            updates[model.key.id] = this._mapper.toSerialize(model);
        });
        await ref.update(updates);
    }

    async loadCollection(): Promise<ModelCollection> {
        const ref = this.modelsRef();
        const snapshot = await ref.once('value');
        const models = (snapshot.val() as Record<ModelId, StickyModelLoadableJSON>) || {};

        return ModelCollection.load(Object.values(models));
    }

    addListener(
        onAdded: (model: StickyModel) => void,
        onChanged: (model: StickyModel) => void,
        onRemoved: (model: StickyModel) => void
    ): void {
        const ref = this.modelsRef();

        const callbacks: Array<[EventType, Callback]> = [
            ['child_added', onAdded],
            ['child_changed', onChanged],
            ['child_removed', onRemoved],
        ];

        this.unsubscribes = callbacks.map(([eventType, callback]) => {
            const unsubscribe = ref.on(eventType, (snapshot) => {
                const value = snapshot.val() as ModelJSON;
                if (!value) return;

                const model = this._mapper.toDomainObject(value);
                if (model) callback(model);
            });

            return [eventType, unsubscribe];
        });
    }

    removeListener(): void {
        const ref = this.modelsRef();
        this.unsubscribes.forEach(([eventType, unsubscribe]) => ref.off(eventType, unsubscribe));
    }
}

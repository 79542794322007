import { LinkBendFactory } from './LinkBendFactory';
import { LinkBendInterval } from './LinkBendInterval';

export class LinkBendIntervalCollection {
    readonly intervals: LinkBendInterval[];
    constructor(linkBendIntervals: LinkBendInterval[]) {
        this.intervals = linkBendIntervals;

        // 位置がより内側のものから順に並び替える
        this.intervals.sort((a, b) => (a.isInnerThan(b) ? 1 : -1));
    }

    /*
     * リンクを差し込める隙間があるか
     */
    getLinkableIntervals(): LinkBendIntervalCollection {
        const bendInterval = LinkBendFactory.BEND_INTERVAL;
        const linkableIntervals = this.intervals.filter((interval) => {
            return interval.interval >= bendInterval * 2;
        });
        return new LinkBendIntervalCollection(linkableIntervals);
    }

    getMostInnerInterval(): LinkBendInterval | null {
        if (this.isEmpty()) {
            return null;
        }
        return this.intervals[0];
    }

    isEmpty(): boolean {
        return this.intervals.length === 0;
    }
}

import { useEffect, useState } from 'react';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

type Status = 'ok' | 'notFound' | 'invalid';

export const useFetchPasswordResetToken = (resetTokenId: string): Status | null => {
    const [status, setStatus] = useState<Status | null>(null);

    useEffect(() => {
        if (!resetTokenId) {
            setStatus(null);
            return;
        }

        FunctionsUserActions.fetchPasswordResetToken(resetTokenId).then((status) => setStatus(status));
    }, [resetTokenId]);

    return status;
};

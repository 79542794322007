import { ClientMouseCursor } from '@view-model/domain/mouse-cursor';
import { RefBuilder, Reference, RTDBPath, ServerValue } from '@framework/repository';
import { ClientMouseCursorJSON } from '@schema-app/view-model/viewer-contents/{viewModelId}/client-mouse-cursors/{cursorId}/ClientMouseCursorJSON';
import { getRealtimeDatabaseCurrentTimestamp } from '@framework/firebase/rtdb';
import { ViewModelId } from '@schema-common/base';

export class ClientMouseCursorRepository {
    private static readonly oldCursorExpireMilliseconds = 5000; // ミリ秒

    constructor(private readonly viewModelId: ViewModelId) {}

    private mouseCursorRef(cursorId: string): Reference {
        return RefBuilder.ref(RTDBPath.ViewModelViewer.clientMouseCursorPath(this.viewModelId, cursorId));
    }

    private mouseCursorsRef(): Reference {
        return RefBuilder.ref(RTDBPath.ViewModelViewer.clientMouseCursorsPath(this.viewModelId));
    }

    async save(cursor: ClientMouseCursor): Promise<void> {
        const value = {
            ...cursor.dump(),
            updatedAt: ServerValue.TIMESTAMP,
        };

        const ref = await this.mouseCursorRef(cursor.id);
        // 切断時に確実に値が削除されるように、先に削除オペレーションを登録してから、値の書き込みを行う
        ref.onDisconnect().remove();
        await ref.set(value);
    }

    async delete(cursorId: string): Promise<void> {
        const ref = this.mouseCursorRef(cursorId);
        await ref.remove();
    }

    async deleteOldCursors(): Promise<void> {
        // クライアント同士の時間ずれを考慮するため、RealtimeDatabase のサーバー時間を基準にする
        // https://github.com/levii/balus-app/issues/682
        const now = (await getRealtimeDatabaseCurrentTimestamp()).valueOf();
        const expiredAt = now - ClientMouseCursorRepository.oldCursorExpireMilliseconds;
        const cursors = await this.fetchAllCursors();

        const oldCursors = cursors.filter((cursor) => cursor.updatedAt && cursor.updatedAt <= expiredAt);

        const updates = oldCursors.reduce(
            (acc, cursor) => {
                acc[cursor.id] = null;
                return acc;
            },
            {} as Record<string, null>
        );

        const ref = this.mouseCursorsRef();
        await ref.update(updates);
    }

    async fetchAllCursors(): Promise<ClientMouseCursor[]> {
        const ref = this.mouseCursorsRef();
        const snapshot = await ref.once('value');

        // snapshot.val()の初期状態はnullなのでデフォルトは空辞書にしておく
        const val: Record<string, ClientMouseCursorJSON> = snapshot.val() || {};

        return Object.values(val).map((j) => ClientMouseCursor.load(j));
    }

    onAdded(callback: (cursor: ClientMouseCursor) => void): void {
        const ref = this.mouseCursorsRef();

        ref.on('child_added', (snapshot) => {
            const j = snapshot.val() as ClientMouseCursorJSON;
            callback(ClientMouseCursor.load(j));
        });
    }

    offAdded(): void {
        this.mouseCursorsRef().off('child_added');
    }

    onRemoved(callback: (cursorId: string) => void): void {
        const ref = this.mouseCursorsRef();

        ref.on('child_removed', (snapshot) => {
            const j = snapshot.val() as ClientMouseCursorJSON;
            callback(j.id);
        });
    }

    offRemoved(): void {
        this.mouseCursorsRef().off('child_removed');
    }

    onChanged(callback: (cursor: ClientMouseCursor) => void): void {
        const ref = this.mouseCursorsRef();

        ref.on('child_changed', (snapshot) => {
            const j = snapshot.val() as ClientMouseCursorJSON;
            callback(ClientMouseCursor.load(j));
        });
    }

    offChanged(): void {
        this.mouseCursorsRef().off('child_changed');
    }
}

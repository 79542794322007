import { DisplayOrderTree, DraggingElements } from '@model-framework/display-order';
import { RectShape, ShapeMap } from '@model-framework/shape';
import { LinkJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';
import { StickyNodeJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/nodes/{nodeId}/StickyNodeJSON';
import { StickyZoneJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/sticky-zones/{stickyZoneKey}/StickyZoneJSON';
import { ModelJSON } from '@schema-app/view-model/contents/{viewModelId}/models/{modelId}/ModelJSON';
import { StickyZoneId } from '@schema-common/base';
import { LinkKey, ModelElementId, ModelKey, NodeKey, StickyZoneKey } from '@view-model/domain/key';
import { LinkableTargetCollection, ModelNamespace, ModelType } from '@view-model/domain/model';
import { PositionSet, PositionSetJSON } from '@view-model/models/common/PositionSet';
import { Point, Rect } from '@view-model/models/common/basic';
import { ArrayUtil } from '@view-model/models/common/utils/ArrayUtil';
import { LinkCollection, LinkEntity, LinkPlacementFactory } from '@view-model/models/sticky/StickyLink';
import { NodeCollection, StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { StickyZone, StickyZoneCollection } from '@view-model/models/sticky/StickyZoneView';
import { IModel, ModelEntity } from './ModelEntity';

interface IStickyModel extends IModel {
    nodes: StickyNode[];
    links: LinkEntity[];
    zones: StickyZone[];
    zonePositions: PositionSet;
}

type StickyModelProperty = {
    nodes: StickyNode[];
    links: LinkEntity[];
    zones: StickyZone[];
    zonePositions: PositionSet;
};

type StickyModelPropertyJSON = {
    nodes: StickyNodeJSON[];
    links: LinkJSON[];
    zones: StickyZoneJSON[];
    zonePositions: PositionSetJSON;
};

export type StickyModelJSON = ModelJSON & StickyModelPropertyJSON;
export type StickyModelLoadableJSON = ModelJSON & Partial<StickyModelPropertyJSON>;

export class StickyModel extends ModelEntity {
    public static DEFAULT_VERSION = 3;

    public readonly type: ModelType = ModelType.Sticky;
    public readonly namespace: ModelNamespace = ModelNamespace.Balus;
    private nodes: NodeCollection;
    private links: LinkCollection;
    public readonly zones: StickyZoneCollection;
    public zonePositions: PositionSet;

    public constructor(attributes: IStickyModel) {
        super(attributes);

        this.nodes = new NodeCollection(attributes.nodes);
        this.links = new LinkCollection(attributes.links);
        this.zones = new StickyZoneCollection(attributes.zones);
        this.zonePositions = attributes.zonePositions;
    }

    public findNode(key: NodeKey): StickyNode | undefined {
        return this.nodes.findByKey(key);
    }

    public nodeEntities(): StickyNode[] {
        return this.nodes.entities();
    }

    setNodes(nodes: StickyNode[]): void {
        this.nodes = new NodeCollection(nodes);
    }

    setLinks(links: LinkEntity[]): void {
        this.links = new LinkCollection(links);
    }

    setZones(zones: StickyZone[]): void {
        this.zones.replace(zones);
    }

    setZonePositions(zonePositions: PositionSet): void {
        this.zonePositions = zonePositions;
    }

    public findLink(key: LinkKey): LinkEntity | undefined {
        return this.links.findByKey(key);
    }

    public addLink(link: LinkEntity): boolean {
        const oldLink = this.links.findByKey(link.key);
        if (oldLink?.isEqual(link)) return false;

        this.links = this.links.added(link);
        return true;
    }

    public removeLink(deletedLinkKey: LinkKey): void {
        this.links = this.links.removed(deletedLinkKey);
    }

    updateLink(link: LinkEntity): boolean {
        const oldLink = this.links.findById(link.id);
        if (oldLink?.isEqual(link)) return false;

        this.links = this.links.updated(link.id, () => link);
        return true;
    }

    public linkEntities(): LinkEntity[] {
        return this.links.entities();
    }

    public linkEntitiesByNodeKey(nodeKey: NodeKey): LinkEntity[] {
        return this.links.containByNodeKey(nodeKey);
    }

    public findZone(key: StickyZoneKey): StickyZone | undefined {
        return this.zones.findByKey(key);
    }

    public static buildNew(attributes?: Partial<IStickyModel>): StickyModel {
        return new this({
            key: ModelKey.buildNew(),
            nodes: attributes?.nodes || [],
            links: attributes?.links || [],
            zones: attributes?.zones || [],
            zonePositions: attributes?.zonePositions || new PositionSet(),
            version: attributes?.version || StickyModel.DEFAULT_VERSION,
        });
    }

    public static load(dump: StickyModelLoadableJSON): StickyModel {
        const nodes = NodeCollection.load(dump.nodes || []);
        const links = LinkCollection.load(dump.links || []);
        const zones = StickyZoneCollection.load(dump.zones || []);
        const zonePositions = PositionSet.load(dump.zonePositions || {});
        const version = dump.version || StickyModel.DEFAULT_VERSION;

        const linkableTargets = new LinkableTargetCollection(nodes.entities(), zones.models);

        // 不整合なリンク (接続先のノードが欠けている) が発生することがあるので、そのガードを入れておく
        const invalidLinks = links.invalidLinksBy(linkableTargets);
        if (invalidLinks) {
            invalidLinks.keys().forEach((key: LinkKey) => {
                console.warn(`Invalid Link is found and ignore this one: ${key}`);
            });
        }
        const validLinks = invalidLinks ? links.validLinksBy(linkableTargets) : links;

        return new StickyModel({
            key: new ModelKey(dump.key),
            nodes: nodes.entities(),
            links: validLinks?.entities() || [],
            zones: zones.models,
            zonePositions: zonePositions,
            version: version,
        });
    }

    public dump(): StickyModelJSON {
        return {
            key: this.key.toString(),
            type: this.type.toString(),
            namespace: this.namespace.toString(),
            nodes: this.nodes.dump(),
            links: this.links.dump(),
            zones: this.zones.dump(),
            zonePositions: this.zonePositions.dump(),
            version: this.version,
        };
    }

    public clone(): StickyModel {
        return StickyModel.load(this.dump());
    }

    public cloneNew(): StickyModel {
        const [newNodes, nodeKeyMap] = this.nodes.cloneNew();
        const [newZones, zoneKeyMap] = this.zones.cloneNew();
        const newLinks = this.links.cloneNew({ ...nodeKeyMap, ...zoneKeyMap });

        const zoneIdMap = Object.entries(zoneKeyMap).reduce((acc: Record<string, string>, [oldKeyString, newKey]) => {
            const oldKey = new StickyZoneKey(oldKeyString);
            acc[oldKey.id.toString()] = newKey.id.toString();
            return acc;
        }, {});

        return new StickyModel({
            key: ModelKey.buildNew(),
            nodes: newNodes.entities(),
            links: newLinks.entities(),
            zones: newZones.models,
            zonePositions: this.zonePositions.cloneNew(zoneIdMap),
            version: this.version,
        });
    }

    public cloneWith(params: Partial<StickyModelProperty>): StickyModel {
        const nodes = params.nodes ? params.nodes.map((node) => node.dump()) : this.nodes.dump();
        const links = params.links ? params.links.map((link) => link.dump()) : this.links.dump();
        const zones = params.zones ? params.zones.map((zone) => zone.dump()) : this.zones.dump();

        return StickyModel.load({ ...this.dump(), nodes, links, zones });
    }

    elementIds(): ModelElementId[] {
        return [...this.nodes.ids(), ...this.links.ids(), ...this.zones.ids()];
    }

    /**
     * 指定されたIDリストに含まれる付箋のコレクションを返します。
     * @param ids
     */
    getNodes(ids: ModelElementId[]): NodeCollection {
        return this.nodes.filterByIds(ids);
    }

    /**
     * 指定されたIDリストに含まれるゾーンのコレクションを返します。
     * @param ids
     */
    getZones(ids: ModelElementId[]): StickyZoneCollection {
        return this.zones.filterByIds(ids);
    }

    getZonePositions(ids: StickyZoneId[]): PositionSet {
        return this.zonePositions.subset(ids);
    }

    /**
     * 指定されたIDリストに含まれるリンクのコレクションを返します。
     * @param ids
     */
    getLinks(ids: ModelElementId[]): LinkCollection {
        return this.links.filterByIds(ids);
    }

    getAllLinks(): LinkCollection {
        return this.links;
    }

    private linkPlacementFactory(): LinkPlacementFactory {
        const { nodes, zonePositions } = this;

        return new LinkPlacementFactory(nodes.positionSet(), zonePositions, this.nodeZoneShapeMap());
    }

    public nodeZoneShapeMap(): ShapeMap<RectShape> {
        const { nodes, zones } = this;

        return nodes.shapeMap().merge(zones.shapeMap());
    }

    /**
     * 指定された要素（ノード、ゾーン、リンク）を囲む矩形を返します。
     */
    getBoundsOf(ids: ModelElementId[]): Rect | null {
        const nodesBound = this.getNodes(ids).getBounds();
        const linksBound = this.getLinks(ids).getBounds(this.linkPlacementFactory());
        const zoneBound = this.getZones(ids).getBounds(this.zonePositions);

        return Rect.union(ArrayUtil.filterNull([nodesBound, linksBound, zoneBound]));
    }

    /**
     * 選択された要素のドロップ対象となるゾーンを返します。見つからなかった場合はnullを返します。
     *
     * @param selectedIds ドロップ先を探す対象となる選択要素のID
     * @param displayOrderTree 並び順ツリー
     */
    findDropTargetZone(selectedIds: ModelElementId[], displayOrderTree: DisplayOrderTree): StickyZone | null {
        const droppingRect = this.getBoundsOf(selectedIds);
        if (!droppingRect) return null;

        const selectedZoneIds = new Set(displayOrderTree.aggregateDescendantZoneIds(selectedIds));

        for (const [zone, zoneBound] of this.getOrderedZonesAndBounds(displayOrderTree)) {
            // 選択されているゾーンは除外
            if (selectedZoneIds.has(zone.id)) continue;

            // いずれかのゾーンに交差したらそのゾーンに対するドロップ判定をして、ドロップ判定を満たさなければ裏側のゾーンに対しても判定を行う
            if (droppingRect.intersects(zoneBound)) {
                if (!zoneBound.isEqual(droppingRect) && zoneBound.includeRect(droppingRect)) return zone;
            }
        }

        return null;
    }

    findDropTargetZoneByRect(droppingRect: Rect, displayOrderTree: DisplayOrderTree): StickyZone | null {
        for (const [zone, zoneBound] of this.getOrderedZonesAndBounds(displayOrderTree)) {
            // いずれかのゾーンに交差したらそのゾーンに対するドロップ判定をして、ドロップ判定を満たさなければ裏側のゾーンに対しても判定を行う
            if (droppingRect.intersects(zoneBound)) {
                if (!zoneBound.isEqual(droppingRect) && zoneBound.includeRect(droppingRect)) return zone;
            }
        }

        return null;
    }

    /**
     * 選択された要素の貼り付け対象となるゾーンを返します。見つからなかった場合はnullを返します。
     *
     * @param position 貼り付け先の座標
     * @param displayOrderTree 並び順ツリー
     */
    findPasteTargetZone(position: Point, displayOrderTree: DisplayOrderTree): StickyZone | null {
        for (const [zone, zoneBound] of this.getOrderedZonesAndBounds(displayOrderTree)) {
            if (zoneBound.include(position)) return zone;
        }
        return null;
    }

    private getOrderedZonesAndBounds(displayOrderTree: DisplayOrderTree): [StickyZone, Rect][] {
        const { zones, zonePositions } = this;
        const record = zones.toRecord();

        const bounds = displayOrderTree.frontToBackZone().map<[StickyZone, Rect] | null>((zoneId) => {
            const zone = record[zoneId];
            const position = zonePositions.find(zoneId);
            return zone && position ? [zone, zone.getRect(position)] : null;
        });

        return ArrayUtil.filterNull(bounds);
    }

    findForegroundElementByPosition(
        position: Point,
        displayOrderTree: DisplayOrderTree
    ): StickyNode | StickyZone | null {
        const zoneMap = this.zones.toRecord();
        const nodeMap = this.nodes.toRecord();

        for (const id of displayOrderTree.frontToBack()) {
            const node = nodeMap[id];
            if (node && node.includePoint(position)) return node;

            const zone = zoneMap[id];
            const zonePosition = this.zonePositions.find(id);
            if (zone && zonePosition && zone.includePoint(zonePosition, position)) return zone;
        }

        return null;
    }

    /**
     * 渡されたドラッグ＆ドロップによる親子関係変更にともなって削除すべきリンクのリストを返します。
     *
     * @param draggingElements 親子関係変更元になるドラッグ中要素とドロップ先要素
     * @param displayOrderTree 親子関係ツリー
     */
    aggregateRemovingLinksBy(draggingElements: DraggingElements, displayOrderTree: DisplayOrderTree): LinkCollection {
        if (!draggingElements.isDroppingToZone()) return LinkCollection.empty();

        const ancestors = new Set(draggingElements.dropTargetAndAncestorIds(displayOrderTree));
        const descendants = new Set(draggingElements.draggingElementAndDescendantIds(displayOrderTree));

        return this.links.filter((link) => link.isLinkBetweenAny(ancestors, descendants));
    }
}

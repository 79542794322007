import { useCallback } from 'react';
import { Folder } from '@workspace/view-model-folder/domain/Folder';
import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import { useRootFolderTreeRepository } from './useRootFolderTreeRepository';
import { useActionLogSender } from '@framework/action-log';
import { RefBuilder, RTDBPath } from '@framework/repository';

type Handler = (name: string) => Promise<Folder>;

export const useCreateFolderToRoot = (workspaceId: string): Handler => {
    const rootFolderTreeRepository = useRootFolderTreeRepository(workspaceId);
    const actionLogSender = useActionLogSender();

    return useCallback(
        async (name: string) => {
            const folder = Folder.buildNew(name, workspaceId);

            await RefBuilder.ref(RTDBPath.Workspace.folderPath(folder.id)).set(folder.dump());

            await rootFolderTreeRepository.addFolder(RootFolderTree.ROOT_ID, folder.id);

            actionLogSender('view_model_folder:create', {
                folderId: folder.id,
                folderName: name,
            });
            return folder;
        },
        [workspaceId, rootFolderTreeRepository, actionLogSender]
    );
};

import { ActionLogSender } from '@framework/action-log';
import { ICommand } from './ICommand';
import { RTDBPath, RefBuilder, ServerValue } from '@framework/repository';
import { ViewModelId } from '@schema-common/base';

export class CommandManager {
    private undoStack: ICommand[] = [];
    private redoStack: ICommand[] = [];

    constructor(
        private readonly viewModelId: ViewModelId,
        private actionLogSender: ActionLogSender | undefined
    ) {}

    async canUndo(): Promise<boolean> {
        return this.undoStack[this.undoStack.length - 1]?.canUndo();
    }

    async canRedo(): Promise<boolean> {
        return this.redoStack[this.redoStack.length - 1]?.canRedo();
    }

    undo(): void {
        const command = this.undoStack.pop();
        if (!command) {
            return;
        }

        command.undo();
        this.redoStack.push(command);

        this.sendLog('undo');
    }

    redo(): void {
        const command = this.redoStack.pop();
        if (!command) {
            return;
        }

        command.redo();
        this.undoStack.push(command);

        this.sendLog('redo');
    }

    execute(command: ICommand): void {
        command.do();

        this.undoStack.push(command);
        this.redoStack = [];

        this.sendLog('execute');
        this.touch();
    }

    /**
     * 行動ログ送信用の関数を更新する。行動ログに付与するメタデータの変化に応じて関数も更新される。
     * 関数が更新されたとしても、Undo/Redoの連続性を担保するために、 setter を用意する。
     * @param actionLogSender
     */
    setActionLogSender(actionLogSender: ActionLogSender): void {
        this.actionLogSender = actionLogSender;
    }

    private sendLog(commandManagerMethod: string): void {
        // コマンドごとに異なる値を付与しないと、同じ操作ログの重複と見做されて記録されないため、
        // Undo/Redo スタックのサイズも付与している
        this.actionLogSender?.('view_model:edit_content', {
            commandManagerMethod,
            commandUndoStackLength: this.undoStack.length,
            commandRedoStackLength: this.redoStack.length,
        });
    }

    private touch(): void {
        RefBuilder.ref(RTDBPath.ViewModel.updatedAtPath(this.viewModelId)).set(ServerValue.TIMESTAMP);
    }
}

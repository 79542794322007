import { FirebaseConfigType } from './type';

export const FirebaseConfig: FirebaseConfigType = {
    apiKey: 'AIzaSyBWHzOpnWOuYIDaA1McBcfJ63eoZ4VFbyI',
    authDomain: 'levii-balus-dev.firebaseapp.com',
    databaseURL: 'https://levii-balus-dev.firebaseio.com',
    projectId: 'levii-balus-dev',
    storageBucket: 'levii-balus-dev.appspot.com',
    messagingSenderId: '313808386095',
    appId: '1:313808386095:web:25ec2439c4fccea53d2eda',
    measurementId: 'G-H3CJ8R4LNK',
};

import { classNames } from '@framework/utils';

type Props = {
    className?: string;
    children: React.ReactNode;
};

export const ButtonGroup: React.FC<Props> = ({ children, className }: Props) => {
    return (
        <div className={classNames(className, 'pointer-events-auto flex flex-col rounded-lg bg-white shadow-md')}>
            {children}
        </div>
    );
};

import { useRef, useCallback, useLayoutEffect } from 'react';

/**
 * 渡された関数を呼び出す同一の関数を返す。
 *
 * React.memo()でメモ化したコンポーネントにコールバックを渡す際に、コールバックの値が変わると再レンダリングされる。
 * このフックは、再レンダリングされないように同じ関数の値を返す。useEffectEvent()と同じような動作をする。
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useHandler<T extends (...args: any[]) => unknown>(handler: T): T {
    const handlerRef = useRef<T | null>(null);
    useLayoutEffect(() => {
        handlerRef.current = handler;
    });

    return useCallback((...args: Parameters<T>) => {
        const fn = handlerRef.current;
        if (!fn) {
            throw new Error('Internal callback is not defined');
        }
        return fn(...args);
    }, []) as T;
}

import { customAlphabet } from 'nanoid';

const DEFAULT_LENGTH = 20;
const RANDOM_ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz';

export class Random {
    /**
     * 指定桁数のランダムなID文字列を生成する。
     * https://zelark.github.io/nano-id-cc/
     *
     * 英小文字 + 数字の 36文字種で 20桁の乱数を毎秒1000件生成し続けたとして、
     * 少なくとも1回の衝突確率が1%に達するまでに 5900万年かかる
     *
     * @param length ID文字列長。未指定時には 20文字。
     * @param prefix ID文字列のプレフィックス。
     */
    static generateRandomID(length?: number, prefix = ''): string {
        length = length || DEFAULT_LENGTH;

        return `${prefix}${this._generateRandomID(length)}`;
    }

    private static _generateRandomID(length: number): string {
        const nanoid = customAlphabet(RANDOM_ALPHABET, length);
        return nanoid();
    }
}

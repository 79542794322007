import { useEffect, useMemo, useState } from 'react';
import { Rect } from '@view-model/models/common/basic';
import { ElementId, ModelId, ViewModelId } from '@schema-common/base';
import { ElementDescriptionContent } from './components';
import { ElementDescription, ElementDescriptionRepository } from './domain';
import { ElementDescriptionTarget } from './domain/ElementDescriptionTarget';

type Props = {
    viewModelId: ViewModelId;
    modelId: ModelId;
    target: ElementDescriptionTarget;
    elementId: ElementId;
    elementRect: Rect;
    readonly: boolean;
};

/**
 * 要素への補足説明（吹き出し）
 *
 * @param elementId
 * @param elementRect 言及対象要素の矩形領域
 * @param readonly 編集可能かどうか
 * @param nodeRepository
 */
export const ElementDescriptionView: React.FC<Props> = ({
    viewModelId,
    modelId,
    target,
    elementId,
    elementRect,
    readonly,
}: Props) => {
    const repository = useMemo(() => {
        return ElementDescriptionRepository.buildFromIds(viewModelId, modelId, target, elementId);
    }, [modelId, elementId, target, viewModelId]);

    const [description, setDescription] = useState<ElementDescription | null>(null);

    // 「Can't perform a React state update on an unmounted component〜」というwarningは無視しても問題ない
    // cf. https://github.com/levii/balus-app/discussions/2686
    useEffect(() => {
        repository.addListener((desc) => {
            setDescription(desc);
        });

        return () => repository.removeListener();
    }, [repository]);

    if (!description?.isVisible()) return null;

    return (
        <ElementDescriptionContent
            viewModelId={viewModelId}
            elementId={elementId}
            description={description}
            readonly={readonly}
            elementRect={elementRect}
            repository={repository}
        />
    );
};

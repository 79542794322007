import { StickyModel } from '@view-model/domain/model';
import { ModelKey } from '@view-model/domain/key';
import { Id } from '@framework/domain/vo/key';
import { TrackJS } from 'trackjs';
import { StickyModelJSON, StickyModelLoadableJSON } from '@view-model/domain/model/StickyModel';
import { ModelId } from '@schema-common/base';

/**
 * イミュータブルなModelEntityコレクション
 */
export class ModelCollection {
    private readonly models: Record<Id, StickyModel>;

    constructor(models: StickyModel[] = []) {
        this.models = models.reduce(
            (result, model) => {
                result[model.key.id] = model;
                return result;
            },
            {} as Record<Id, StickyModel>
        );
    }

    get(key: ModelKey): StickyModel | undefined {
        return this.models[key.id];
    }

    list(): StickyModel[] {
        return Object.values(this.models);
    }

    add(model: StickyModel): ModelCollection {
        if (this.get(model.key)) {
            // 追加しようとしている ModelEntity が既にこのコレクションに含まれている場合には、
            // 警告を出しつつ、引数で指定された ModelEntity に置き換えた新しいコレクションを返します。
            console.warn(`ModelCollection: modelId=${model.id} is already exists but replaced.`);
            const models = this.list().map((entity) => (entity.key.isEqual(model.key) ? model : entity));

            // この分岐に入るときは、不適切な状態更新が行われている可能性が高い。
            // 開発者が気づくことができるように、 TrackJS でトラッキングする。
            TrackJS.track('ModelCollection.add() is called multiple times.');
            return new ModelCollection(models);
        }

        return new ModelCollection(this.list().concat(model));
    }

    remove(modelKey: ModelKey): ModelCollection {
        if (!this.get(modelKey)) {
            return this;
        }
        return new ModelCollection(this.list().filter((model) => !model.key.isEqual(modelKey)));
    }

    dump(): StickyModelJSON[] {
        return this.list().map((model) => model.dump());
    }

    static load(models: StickyModelLoadableJSON[]): ModelCollection {
        const entities = models.map((model) => StickyModel.load(model));
        return new ModelCollection(entities);
    }

    cloneNew(): [ModelCollection, Record<string, ModelKey>] {
        const models: StickyModel[] = [];
        const keyMap: Record<string, ModelKey> = {};
        this.list().forEach((model) => {
            const newModel = model.cloneNew();
            keyMap[model.key.toString()] = newModel.key;
            models.push(newModel);
        });
        return [new ModelCollection(models), keyMap];
    }

    filterByIds(modelIds: ModelId[]): ModelCollection {
        const filteredModels = Object.values(this.models).filter((model) => modelIds.includes(model.id));
        return new ModelCollection(filteredModels);
    }
}

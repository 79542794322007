import { Point, Size } from '@view-model/models/common/basic';
import { ForeignObjectAutosize } from '@view-model/models/common/components/ForeignObjectAutosize';

type Props = {
    position: Point;
    isPrimaryComment: boolean;
    onSizeChange?: (newSize: Size) => void;
    children: React.ReactNode;
};

export const CommentMenuContainer: React.FC<Props> = ({
    position,
    isPrimaryComment,
    onSizeChange,
    children,
}: Props) => {
    const handleSizeChange = (newSize: Size) => {
        onSizeChange?.(newSize);
    };

    return (
        <g transform={position.toSVGTranslate()}>
            <ForeignObjectAutosize onSizeChange={handleSizeChange}>
                <div style={{ paddingTop: isPrimaryComment ? 5 : 12, paddingInline: 15 }}>{children}</div>
            </ForeignObjectAutosize>
        </g>
    );
};

import { useLocation } from 'react-router-dom';
import { GroupId, GroupMemberInvitationId } from '@schema-common/base';
import { Layout } from './Layout';
import { ErrorMessage } from './ErrorMessage';
import { PageContent } from './PageContent';

type QueryParam = {
    groupId: GroupId | null;
    invitationId: GroupMemberInvitationId | null;
};

const useQueryParams = (): QueryParam => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    return { groupId: params.get('groupId'), invitationId: params.get('invitationId') };
};

/**
 * グループメンバーへの招待情報を表示するページ
 */
export const GroupMemberInvitationPage: React.FC<unknown> = () => {
    const { groupId, invitationId } = useQueryParams();

    return (
        <Layout>
            {!groupId || !invitationId ? (
                <ErrorMessage errorReason="notFound" />
            ) : (
                <PageContent groupId={groupId} invitationId={invitationId} />
            )}
        </Layout>
    );
};

import { GroupId, UserId, ViewModelId } from '@schema-common/base';
import { ViewModelAsset } from './ViewModelAsset';
import { ViewModelAssetURL } from '@user/pages/urls';
import { ObjectRepository, RTDBPath } from '@framework/repository';

type UrlString = string;

export type AssetUrlMap = Record<UrlString, UrlString>;

type CloneResult = {
    url: UrlString;
    message?: string;
};

const FallbackImage = 'https://storage.googleapis.com/levii-balus-app-public-contents/noimage.png';

class ViewModelAssetItem {
    constructor(
        public readonly url: UrlString,
        public readonly viewModelId: ViewModelId,
        public readonly asset?: ViewModelAsset,
        public readonly blob?: Blob
    ) {}

    async cloneNew(
        groupId: GroupId,
        viewModelId: ViewModelId,
        currentUserId: UserId,
        baseUrl: UrlString
    ): Promise<CloneResult> {
        // 複製元と複製先が同一のビューモデルの場合には、画像の複製は不要 (なので、URLをそのまま返す)
        if (this.viewModelId === viewModelId) {
            return { url: this.url };
        }

        // Assetオブジェクト、または、画像データを取得できなかった場合には、フォールバック画像のURLに置き換える
        if (!this.asset || !this.blob) {
            return { url: FallbackImage, message: '画像の複製に失敗しました' };
        }

        const newAsset = this.asset.withCreatedUserId(currentUserId);
        const result = await newAsset.upload(groupId, viewModelId, this.blob);

        if (result) {
            return { url: FallbackImage, message: result };
        }

        return {
            url: newAsset.url(groupId, viewModelId, baseUrl),
        };
    }
}

export class ViewModelAssetCollectionForClone {
    private constructor(private readonly items: ViewModelAssetItem[]) {}

    /**
     * ビューモデルに添付された画像を別のビューモデルに対して複製するためのコレクションオブジェクトを生成する。
     * ビューモデルに添付された画像URLの一覧から、添付された画像メタデータと画像データ本体の取得を試みる。
     * @param urls
     * @returns
     */
    static async fetchByUrlList(urls: UrlString[]): Promise<ViewModelAssetCollectionForClone> {
        const items = await Promise.all(urls.map((url) => this.fetchByUrl(url)));
        return new this(items.filter((item): item is ViewModelAssetItem => !!item));
    }

    /**
     * 指定のビューモデル添付URLから添付画像とメタデータを取得する。
     * 正しくデータ取得できた場合には AssetItem オブジェクトを返し、データ取得に失敗した時には null を返す。
     * @param urlString
     * @returns
     */
    private static async fetchByUrl(urlString: UrlString): Promise<ViewModelAssetItem | null> {
        const url = ViewModelAssetURL.fromURLString(urlString);
        if (url === null) {
            return null;
        }

        // URLのパラメータから ViewModelAsset オブジェクトの取得を試みる
        const { viewModelId, assetId } = url;
        let asset: ViewModelAsset | null = null;
        try {
            const assetRepo = new ObjectRepository(
                ViewModelAsset,
                RTDBPath.ViewModel.Asset.assetPath(viewModelId, assetId)
            );
            asset = await assetRepo.get();
        } catch {
            // 権限不足時に処理中断しないように例外を握りつぶす
            console.warn(`Can't access to ViewModelAsset(viewModelId=${viewModelId}, assetId=${assetId})`);
        }
        if (!asset) {
            // asset情報を取得できなかった場合には、asset情報の欠損したアイテムを生成する
            return new ViewModelAssetItem(urlString, viewModelId);
        }

        // URLにアクセスして、(リダイレクト先を辿って)画像データの実体を取得する
        const response = await fetch(urlString, {
            method: 'GET',
            redirect: 'follow',
        });
        if (!response.ok) {
            return new ViewModelAssetItem(urlString, viewModelId, asset);
        }

        // Blob(画像ファイルの実体)のサイズが0バイトの場合には取得失敗と同様に扱う
        const blob = await response.blob();
        if (blob.size === 0) {
            return new ViewModelAssetItem(urlString, viewModelId, asset);
        }

        return new ViewModelAssetItem(urlString, viewModelId, asset, blob);
    }

    /**
     * ViewModelAssetを引数に指定されたビューモデルに対して複製します。
     * 複製元のURLと複製先のURLの対応関係を表したMapを返します。
     * 複製処理に失敗した画像は、fallback用の画像に置き換えられます。
     * @returns
     */
    async cloneNew(
        groupId: GroupId,
        viewModelId: ViewModelId,
        currentUserId: UserId,
        baseUrl: UrlString
    ): Promise<[AssetUrlMap, string | null]> {
        const assetUrlMap: AssetUrlMap = {};
        let hasError = false;

        await Promise.all(
            this.items.map(async (item) => {
                const { url, message } = await item.cloneNew(groupId, viewModelId, currentUserId, baseUrl);
                assetUrlMap[item.url] = url;
                if (message) {
                    hasError ||= true;
                }
            })
        );

        return [assetUrlMap, hasError ? '画像の複製に失敗しました' : ''];
    }
}

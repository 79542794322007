import { ActionLogEventName } from './definition';
import { ActionLogParameters } from './ActionLogParameters';
import { Timestamp } from '@framework/Timestamp';
import { isEqual } from 'lodash';
import { ActionEventLogJSON } from '@schema-action-log/action-event-logs/ActionEventLogJSON';
import { AuthSessionId } from '@schema-common/base';
import { getClientId } from '@framework/app';

export class ActionLog {
    private constructor(
        private readonly eventName: ActionLogEventName,
        private readonly eventParams: ActionLogParameters,
        private readonly ip: string | null,
        private readonly sessionId: AuthSessionId | null,
        private readonly userId: string | null,
        private readonly userProperties: ActionLogParameters
    ) {}

    static buildNew(
        eventName: ActionLogEventName,
        eventParams: ActionEventLogJSON['eventParams'],
        ip: string | null,
        sessionId: AuthSessionId | null | undefined,
        userId: string | null | undefined,
        userProperties: ActionEventLogJSON['userProperties']
    ): ActionLog {
        return new ActionLog(
            eventName,
            ActionLogParameters.buildFrom(eventParams),
            ip,
            sessionId || null,
            userId || null,
            ActionLogParameters.buildFrom(userProperties)
        );
    }

    dump(): Omit<Omit<ActionEventLogJSON, 'eventId'>, 'eventTimestamp'> {
        const { eventName, eventParams, ip, sessionId, userId, userProperties } = this;

        return {
            clientId: getClientId(),
            eventName: eventName,
            eventSource: 'frontend',
            eventParams: eventParams.dump(),
            ip,
            sessionId,
            userId,
            userProperties: userProperties.dump(),
        };
    }

    /**
     * RTDBへの保存用のJSONシリアライズ結果を返す
     *
     * @param eventId {string} イベントの識別子
     * @param now {Timestamp} 現在時刻の Timestamp オブジェクト
     * @deprecated action-log データベースへの保存廃止時にこのメソッドも削除する
     */
    dumpToSave(
        eventId: string,
        now: Timestamp
    ): Omit<ActionEventLogJSON, 'eventTimestamp'> & { eventTimestamp: string } {
        const dump = this.dump();
        return {
            ...dump,
            eventId,
            eventTimestamp: now.toISOString(),
        };
    }

    isEqual(other: ActionLog): boolean {
        return isEqual(this.dump(), other.dump());
    }
}

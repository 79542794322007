import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { selectAtom } from 'jotai/utils';
import { clientSelectedItemCollectionAtomFamily } from './clientSelectedItemCollectionAtom';
import { SelectedItemSet } from '../../common/basic';

// 現在選択している要素のIDセットを取得する
export const useSelectedItems = ({ clientId, modelId }: { clientId: string; modelId: string }) => {
    const selectedItems = useAtomValue(
        useMemo(
            () =>
                selectAtom(
                    clientSelectedItemCollectionAtomFamily(modelId),
                    (state) => {
                        const ids =
                            state
                                ?.filterByClientId(clientId)
                                .all()
                                .map((selected) => selected.itemId) ?? [];
                        return SelectedItemSet.from(ids);
                    },
                    (left, right) => {
                        const leftArray = left.getSelectedItems();
                        const rightArray = right.getSelectedItems();
                        return (
                            leftArray.length === rightArray.length &&
                            leftArray.every((item) => rightArray.includes(item))
                        );
                    }
                ),
            [clientId, modelId]
        )
    );

    return selectedItems;
};

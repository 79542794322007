import { useCallback } from 'react';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { ViewModelClipboardOperator } from '@view-model/application/clipboard';
import { toast } from 'react-hot-toast';
import { useActionLogSender } from '@framework/action-log';

export const useViewModelCopy = (viewModel: ViewModelEntity) => {
    const logSender = useActionLogSender();

    return useCallback(() => {
        const start = new Date();
        let end: Date | null = null;

        ViewModelClipboardOperator.copy(viewModel.workspaceId, viewModel.id)
            .then(() => {
                end = new Date();

                toast(`ビューモデル「${viewModel.name}」をコピーしました | Copied`);
            })
            .then(() => {
                // 行動ログを記録する
                logSender('view_model:all_copy', {
                    viewModelId: viewModel.id,
                    viewModelName: viewModel.name.toString(),
                    processingTimeSec: end ? (end.getTime() - start.getTime()) / 1000.0 : null,
                });
            })
            .catch((reason) => {
                console.error(reason);
                toast.error(`ビューモデル「${viewModel.name}」のコピーに失敗しました | Copy failed`);
            });
    }, [logSender, viewModel]);
};

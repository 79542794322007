import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { ForeignObjectAutosize } from '@view-model/models/common/components/ForeignObjectAutosize';
import { ModelCommentContentItemContainer } from './ModelCommentContentItemContainer';
import { ModelCommentReplyFormContainer } from './ModelCommentReplyFormContainer';
import { ModelCommentItem } from '../ModelCommentItem';
import { ModelCommentReply } from './ModelCommentReply';
import { CommentMenuList } from '../ModelCommentMenuList';
import { CommentConstants } from '../../constants';
import { ModelCommentAuthor, ModelCommentThread } from '../../domain';
import { ModelCommentThreadOperation } from '../../adapter';
import { Size } from '@view-model/models/common/basic';
import { CommentPositionMap } from '../../positions';
import { useTextSelectable } from '@view-model/models/common/hooks';
import { useCommandManager } from '@model-framework/command';
import { ModelCommentId, ModelCommentThreadId, ModelId, ViewModelId } from '@schema-common/base';
import { UserPublicProfile } from '@user/PublicProfile';

type Props = {
    viewModelId: ViewModelId;
    modelId: ModelId;
    commentThread: ModelCommentThread;
    isSelected: boolean;
    readonly: boolean;
    currentUserProfile: UserPublicProfile;
    onSizeChange(id: ModelCommentThreadId, newSize: Size): void;
};

export const ModelCommentThreadContent = memo(
    ({ viewModelId, modelId, commentThread, isSelected, readonly, currentUserProfile, onSizeChange }: Props) => {
        const threadId = commentThread.id;
        const isResolved = commentThread.isResolved();
        const primaryComment = commentThread.getPrimaryComment();
        const replyComments = commentThread.getReplyComments();

        const commandManager = useCommandManager();

        const threadOperation = useMemo(
            () => new ModelCommentThreadOperation(viewModelId, modelId, threadId, commandManager),
            [commandManager, modelId, threadId, viewModelId]
        );

        const [containerSize, setContainerSize] = useState<Size>(new Size(0, 0));
        const [sizes, setSizes] = useState<Record<ModelCommentId, Size>>({});
        const [editingCommentId, setEditingCommentId] = useState<ModelCommentId | null>(null);
        const positionMap = new CommentPositionMap(commentThread, sizes);

        const ref = useRef(null);
        // マウスイベントが伝搬することでテキスト操作（選択、リンククリック）ができず、ドラッグによりキャンバス自体が移動してしまうのを止める
        useTextSelectable(ref);

        const { width, headerBarHeight } = CommentConstants.UISetting;

        const handleChangeContainerSize = useCallback(
            (size: Size) => {
                // foreignObject の大きさに対してヘッダー高さを加えた値が、このコメントの表示サイズ
                const newSize = size.addHeight(headerBarHeight);
                setContainerSize(newSize);
                onSizeChange(threadId, newSize);
            },
            [headerBarHeight, onSizeChange, threadId]
        );

        const handleChangeSize = useCallback((id: ModelCommentId, newSize: Size) => {
            setSizes((sizes) => ({ ...sizes, [id]: newSize }));
        }, []);

        const handleEditStart = useCallback((id: ModelCommentId) => {
            setEditingCommentId(id);
        }, []);

        const handleEditCancel = useCallback(() => {
            setEditingCommentId(null);
        }, []);

        const handleEditSubmit = useCallback(() => {
            setEditingCommentId(null);
        }, []);

        const handleReplyCommentSubmit = useCallback(
            (body: string) => {
                const author = ModelCommentAuthor.buildFromUser(currentUserProfile);
                threadOperation.addReplyComment(body, author).then();
            },
            [currentUserProfile, threadOperation]
        );

        return (
            <>
                {/* rectで背景を作成 */}
                <rect
                    width={containerSize.width}
                    height={containerSize.height}
                    opacity={isResolved ? 0.6 : 1}
                    fill="white"
                    stroke="#e0e0e0"
                    strokeWidth="4"
                    rx="16"
                    ry="16"
                />

                {/* ドラッグハンドル部分の高さ分だけ y軸方向にオフセットする */}
                <g ref={ref} transform={`translate(0, ${headerBarHeight})`} opacity={isResolved ? 0.6 : 1}>
                    <ForeignObjectAutosize onSizeChange={handleChangeContainerSize}>
                        <div
                            className={
                                isSelected
                                    ? 'rounded-b-2xl border-x-6 border-b-6 border-t-0 border-brand bg-white pb-3 text-3xl'
                                    : 'rounded-b-2xl border-x-6 border-b-6 border-t-0 border-white bg-white pb-3 text-3xl'
                            }
                            style={{ width: width }}
                        >
                            {/* スレッドの元コメント */}
                            <ModelCommentContentItemContainer
                                id={primaryComment.id}
                                width={width}
                                onSizeChange={handleChangeSize}
                            >
                                <ModelCommentItem
                                    isEditing={primaryComment.id === editingCommentId}
                                    comment={primaryComment}
                                    threadOperation={threadOperation}
                                    onCancel={handleEditCancel}
                                    onSubmit={handleEditSubmit}
                                />
                            </ModelCommentContentItemContainer>

                            {/* 返信コメントを順に列挙 */}
                            {...replyComments.map((replyComment) => (
                                <ModelCommentContentItemContainer
                                    key={replyComment.id}
                                    id={replyComment.id}
                                    width={width}
                                    withTopDivider={true}
                                    onSizeChange={handleChangeSize}
                                >
                                    <ModelCommentItem
                                        isEditing={replyComment.id === editingCommentId}
                                        comment={replyComment}
                                        threadOperation={threadOperation}
                                        onCancel={handleEditCancel}
                                        onSubmit={handleEditSubmit}
                                    />
                                </ModelCommentContentItemContainer>
                            ))}

                            {/* 返信コメントの投稿フォーム (readonly の場合には非表示) */}
                            {!readonly && (
                                <ModelCommentReplyFormContainer width={width} withTopDivider={true}>
                                    <ModelCommentReply onReplyCommentSubmit={handleReplyCommentSubmit} />
                                </ModelCommentReplyFormContainer>
                            )}
                        </div>
                    </ForeignObjectAutosize>

                    {/*
                    コメントに対する操作メニューを表示 (readonly の場合には非表示)

                    foreignObject の中で relative, absolute 等の相対位置指定を利用すると、Safari で表示崩れが発生する。
                    この表示崩れを回避するために、メニュー項目ごとに独立した foreignObject を用意して transform する形で実現する。
                  */}
                    {!readonly && (
                        <CommentMenuList
                            commentThread={commentThread}
                            positionMap={positionMap}
                            threadOperation={threadOperation}
                            currentUserProfile={currentUserProfile}
                            onEditStart={handleEditStart}
                        />
                    )}
                </g>
            </>
        );
    }
);
ModelCommentThreadContent.displayName = 'ModelCommentThreadContent';

import { useEffect, useState } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { useMountedRef } from '@framework/hooks';
import { UserData } from './UserData';
import { createUserDataRepository } from './UserDataRepository';

/**
 * 現在ログイン中ユーザの UserData を取得します。
 * 匿名ユーザの場合には UserData が作成されないため、 null が返ります。
 *
 * @returns
 */
export const useCurrentUserData = (): UserData | null => {
    const currentUserId = useCurrentUserId();
    const [userData, setUserData] = useState<UserData | null>(null);
    const mountedRef = useMountedRef();

    useEffect(() => {
        if (!currentUserId) {
            setUserData(null);
            return;
        }

        const repo = createUserDataRepository(currentUserId);
        repo.addListener((userData) => {
            if (!mountedRef.current) return;

            setUserData(userData);
        });

        return () => repo.removeListener();
    }, [currentUserId, mountedRef]);

    return userData;
};

export type GroupMemberInvitationStatus = 'pending' | 'accepted' | 'expired' | 'invalid';

const dict: Record<GroupMemberInvitationStatus, string> = {
    pending: '承諾待ち',
    accepted: '承諾済み',
    expired: '期限切れ',
    invalid: '無効',
};

export namespace GroupMemberInvitationStatus {
    export function getLocalString(status: GroupMemberInvitationStatus): string {
        return dict[status];
    }
}

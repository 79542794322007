import { useCallback, useState } from 'react';
import { UserData } from '@user/UserData';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { EditPasswordForm, PasswordInput } from '@user/EditPasswordForm';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-hot-toast';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

type Props = {
    isOpen: boolean;
    onClose(): void;
    userData: UserData;
};

export const PasswordChangeModal: React.FC<Props> = ({ isOpen, onClose, userData }: Props) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState('');

    const handleChangeCurrentPassword = useCallback((password: string) => setCurrentPassword(password), []);
    const handleChangeNewPassword = useCallback(
        (newPassword: string) => {
            setNewPassword(newPassword);

            if (newPassword && currentPassword == '') {
                setCurrentPasswordError('現在のパスワードを入力してください。');
            }
        },
        [currentPassword]
    );
    const validateOnBlur = useCallback(() => {
        if (currentPassword === '') {
            setCurrentPasswordError('現在のパスワードを入力してください。');
        } else {
            setCurrentPasswordError('');
        }
    }, [currentPassword]);

    const isEmpty = currentPassword === '' || newPassword === '';

    const handleClose = useCallback(() => {
        setProcessing(false);
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        if (currentPassword === '') return;
        if (newPassword === '') return;

        setProcessing(true);
        setError('');
        setCurrentPasswordError('');

        const result = await FunctionsUserActions.changePassword(currentPassword, newPassword);
        setProcessing(false);

        if (result === 'success') {
            toast.success('パスワードを変更しました');
            handleClose();
            return;
        }

        if (result === 'incorrect-current-password') {
            setCurrentPasswordError('現在のパスワードが間違っているため、パスワードの変更に失敗しました。');
        } else {
            setError('パスワードの変更に失敗しました。時間をあけてもう一度試してみてください。');
        }
    }, [currentPassword, handleClose, newPassword]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} className="flex flex-col">
            {/* header */}
            <div className="px-8 pb-4 pt-8 text-2xl font-bold">パスワードを変更する</div>
            <hr className="w-full" />

            {/* content */}
            <div className="flex flex-col p-8">
                <div className="pb-2 font-bold">メールアドレス (変更できません)</div>
                <input
                    type="text"
                    className="cursor-not-allowed rounded-sm border bg-gray-200 p-2"
                    defaultValue={userData.mailAddress}
                    disabled
                />

                <div className="pb-2 pt-8 font-bold">現在のパスワード</div>
                <PasswordInput
                    value={currentPassword}
                    onChange={handleChangeCurrentPassword}
                    onBlur={validateOnBlur}
                    error={currentPasswordError}
                    autoFocus
                    disabled={processing}
                />
                <div className="pt-1 text-xs">
                    ※ 現在のパスワードが不明の場合には
                    <a
                        href={UserPagePathBuilder.passwordResetPage()}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-700 hover:underline"
                    >
                        こちら <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a>
                    からパスワードの再設定をお願いします。
                </div>

                <EditPasswordForm onChange={handleChangeNewPassword} disabled={processing} />

                {error && <div className="pt-8 text-red-700">{error}</div>}
            </div>

            {/* footer */}
            <hr className="w-full" />
            <div className="flex justify-end px-8 pb-8 pt-4">
                <FlatButton onClick={handleClose}>閉じる</FlatButton>
                <FlatButton
                    color="brand"
                    onClick={handleSubmit}
                    className="ml-2 px-8"
                    disabled={isEmpty || processing}
                    loading={processing}
                >
                    保存
                </FlatButton>
            </div>
        </Modal>
    );
};

import { LinkKey, LinkableTargetKey } from '@view-model/domain/key';
import { LinkReplaceCommand } from '../command';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { LinkEntityOperation } from './LinkEntityOperation';
import { CommandManager } from '@view-model/models/framework/command';

export class StickyLinkReplacementOperation {
    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly linkEntityOperation: LinkEntityOperation,
        private readonly commandManager: CommandManager
    ) {}

    replaceLink(
        linkKey: LinkKey,
        currentKeys: { sourceKey: LinkableTargetKey; targetKey: LinkableTargetKey },
        newKeys: { sourceKey: LinkableTargetKey; targetKey: LinkableTargetKey }
    ): void {
        if (newKeys.sourceKey.isEqual(newKeys.targetKey)) return;

        const command = new LinkReplaceCommand(this.viewModel, this.linkEntityOperation, linkKey, currentKeys, newKeys);
        this.commandManager.execute(command);
    }
}

import { ModelCommentThread } from './ModelCommentThread';
import { ModelCommentThreadId } from '@schema-common/base';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { SizeSet } from '@view-model/models/common/basic/SizeSet';
import { ModelCommentThreadRegion } from './ModelCommentThreadRegion';
import { Rect } from '@view-model/models/common/basic/Rect';

export class ModelCommentThreadRegionCollection {
    private readonly regions: ModelCommentThreadRegion[];

    constructor(
        private readonly threads: ModelCommentThread[],
        private readonly positions: PositionSet,
        private readonly sizeSet: SizeSet
    ) {
        const nullableRegions = threads.map(({ id }) => {
            const position = this.positions.find(id);
            const size = this.sizeSet.find(id);
            if (position && size) {
                return new ModelCommentThreadRegion(id, position, size);
            }
        });
        this.regions = nullableRegions.filter((region): region is ModelCommentThreadRegion => !!region);
    }

    filterIdsBySelectionRect(rect: Rect): ModelCommentThreadId[] {
        const regions = this.regions.filter((region) => region.overlapped(rect));
        return regions.map(({ id }) => id);
    }
}

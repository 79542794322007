import { ModelKey } from '@view-model/domain/key';
import { ModelCollection, StickyModelContents, StickyModelContentsJSON } from '@view-model/domain/model';
import { AssetUrlMap } from '@view-model/domain/view-model';

export class ModelContentsCollection {
    private readonly models: StickyModelContents[];

    constructor(models: StickyModelContents[]) {
        this.models = models;
    }

    list(): StickyModelContents[] {
        return [...this.models];
    }

    dump(): StickyModelContentsJSON[] {
        return this.models.map((modelContents) => modelContents.dump());
    }

    static load(dumps: StickyModelContentsJSON[]): ModelContentsCollection {
        const entities = dumps.map((dump) => StickyModelContents.load(dump));
        return new ModelContentsCollection(entities);
    }

    cloneNew(assetUrlMap: AssetUrlMap): [ModelContentsCollection, Record<string, ModelKey>] {
        const models: StickyModelContents[] = [];
        const keyMap: Record<string, ModelKey> = {};
        this.models.forEach((model) => {
            const newModel = model.cloneNew(assetUrlMap);
            keyMap[model.key.toString()] = newModel.key;
            models.push(newModel);
        });
        return [new ModelContentsCollection(models), keyMap];
    }

    modelCollection(): ModelCollection {
        const entities = this.models.map((modelContents) => modelContents.modelEntity());
        return new ModelCollection(entities);
    }

    assetUrls(): string[] {
        const urls = this.list().reduce((prev, model) => {
            return [...prev, ...model.assetUrls()];
        }, [] as string[]);
        return Array.from(new Set(urls));
    }
}

import { Point, Rect } from '@view-model/models/common/basic';

/**
 * 要素に張り付く吹き出しのポジション調整クラス
 */
export class ElementDescriptionCalloutPositionAdjuster {
    /**
     * コンストラクタ
     * @param calloutRect 吹き出し矩形
     * @param margin 吹き出ししっぽ先端と対象矩形との間のスペース
     */
    constructor(
        private readonly calloutRect: Rect,
        private readonly margin: number
    ) {}

    /**
     * 対象矩形に接するような吹き出し矩形の位置を返す
     * @param targetRect
     */
    adjustPositionTo(targetRect: Rect): Point {
        const { calloutRect, margin } = this;
        const correctedRect = calloutRect.stickTo(targetRect.applyMarginKeepingCenter(margin));

        // Rect.position は Point型 ではなく Position型 のため変換して返す
        return Point.fromPosition(correctedRect.position);
    }
}

import { useCallback, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { GroupOperation } from '@group/domain/GroupEntity/GroupOperation';
import { UserData } from '@user/UserData';
import { GroupId } from '@schema-common/base';
import { toast } from 'react-hot-toast';

type Props = {
    isOpen: boolean;
    selectedMemberData: UserData;
    onClose(): void;
    groupId: GroupId;
};

export const GroupMemberRemoveModal: React.FC<Props> = ({ isOpen, onClose, selectedMemberData, groupId }: Props) => {
    const [loading, setLoading] = useState(false);

    const handleRemove = useCallback(async () => {
        const confirmMessage = [
            `選択された ${selectedMemberData.name} さんをグループメンバーから削除します。`,
            'グループメンバーから削除されると、所属していたワークスペースからも削除されます。',
            'この操作は元に戻すことはできません。よく確認してから実行してください。',
        ].join('\n');

        if (!confirm(confirmMessage)) {
            onClose();
            return;
        }

        setLoading(true);

        if (await GroupOperation.removeMembers(groupId, [selectedMemberData.id])) {
            toast.success(`${selectedMemberData.name} さんをグループメンバーから削除しました`);
            onClose();
        } else {
            alert('グループメンバーの削除に失敗しました。もう一度試してみるか、サポート担当者にお問い合わせください。');
        }

        setLoading(false);
    }, [onClose, groupId, selectedMemberData]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} className="flex flex-col">
            {/* header */}
            <div className="mb-1 p-4 text-2xl font-bold">メンバーの削除</div>

            {/* content */}
            <div className="overflow-y-auto px-4 py-2" style={{ maxHeight: 'calc(85vh - 160px)' }}>
                {selectedMemberData.name}さんをグループメンバーから削除
            </div>

            {/* footer */}
            <hr className="my-2 w-full" />
            <div className="flex justify-end px-8 py-4">
                <FlatButton onClick={onClose}>閉じる</FlatButton>
                <FlatButton onClick={handleRemove} color="brand" className="ml-2" loading={loading}>
                    選択したメンバーを削除
                </FlatButton>
            </div>
        </Modal>
    );
};

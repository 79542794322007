type Props = {
    children: React.ReactNode;
};

/**
 * シンプルなフッター付きのページレイアウト
 * @param props {Object}
 * @param props.children {React.ReactNode}
 */
export const SimpleFooterLayout: React.FC<Props> = ({ children }: Props) => {
    return (
        <div className="flex min-h-screen flex-col">
            <div className="grow">{children}</div>

            {/* footer */}
            <div className="bg-gray-200">
                <div className="p-2 text-center text-xs">
                    <a
                        href="https://levii.co.jp/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-700 hover:underline"
                    >
                        株式会社レヴィ
                    </a>
                </div>
            </div>
        </div>
    );
};

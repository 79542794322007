import { useSnapshot } from '@framework/hooks';
import { RTDBPath } from '@framework/repository';
import { GroupId, UserId } from '@schema-common/base';

/**
 * 指定のグループに所属するメンバーのユーザID一覧を配列で返します。
 * 取得完了までの間は undefined を返します。
 * @param groupId
 * @returns
 */
export const useGroupMemberUserIds = (groupId: GroupId): UserId[] | undefined => {
    const [userIds, { loading }] = useSnapshot<UserId[]>({
        path: RTDBPath.Group.memberRolesPath(groupId),
        load: ({ snapshot }) => {
            return Object.keys(snapshot.val() || {}).sort();
        },
    });

    return loading ? undefined : userIds || [];
};

import { useEffect, useState } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { RTDBPath, TextRepository } from '@framework/repository';
import { GroupMemberRoleJSON } from '@schema-common/group';
import { GroupId } from '@schema-common/base';
import { isGroupMemberRole } from '@group/domain';

/**
 * 指定のグループに対する、ログイン中ユーザ自身のメンバーロールを取得して返す
 *
 * @param groupId
 * @returns {undefined} メンバーロールの情報をRTDBから取得中
 * @returns {null} 対象グループに所属していない、または、ログインしていない
 * @returns {GroupMemberRoleJSON} 対象グループに所属するメンバーロール
 */
export const useGroupMyMemberRole = (groupId: GroupId): GroupMemberRoleJSON | null | undefined => {
    const [role, setRole] = useState<GroupMemberRoleJSON | null | undefined>(undefined);
    const currentUserId = useCurrentUserId();

    useEffect(() => {
        if (!currentUserId) {
            // 未ログイン状態の場合には、自身のグループメンバーロールは null として扱う
            setRole(null);
            return;
        }

        setRole(undefined);

        const repo = new TextRepository(RTDBPath.Group.memberRolePath(groupId, currentUserId));

        repo.addListener((role) => {
            if (role && isGroupMemberRole(role)) {
                setRole(role);
            } else {
                setRole(null);
            }
        });

        return () => repo.removeListener();
    }, [currentUserId, groupId]);

    return role;
};

import { useCallback } from 'react';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useActionLogSender } from '@framework/action-log';
import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { classNames } from '@framework/utils';
import { useAnnouncementControl } from '@notification/announcement';

type Props = {
    className?: string;
    showNotifications: boolean;
    onToggle: () => void;
};

export const NotificationIcon: React.FC<Props> = ({ className, showNotifications, onToggle }: Props) => {
    const { badge, markAsRead } = useAnnouncementControl();
    const logSender = useActionLogSender();

    const onClick = useCallback(async () => {
        if (!showNotifications) {
            // 通知リストが非表示の状態でクリックされたとき、既読にして、イベントを記録する
            markAsRead();
            logSender('global:notification:open');
        }

        onToggle();
    }, [logSender, markAsRead, onToggle, showNotifications]);

    return (
        <div className={classNames(className, 'relative')}>
            <DarkTooltip
                tooltip={
                    showNotifications ? 'お知らせをしまう | Hide notifications' : 'お知らせを表示 | Show notifications'
                }
                placement={'bottom'}
            >
                <button
                    className={classNames(
                        'relative flex h-12 w-12 items-center justify-center',
                        'bg-white text-2xl text-gray-700 hover:bg-gray-200',
                        'cursor-pointer rounded-lg shadow-md focus:outline-none'
                    )}
                    onClick={onClick}
                    aria-label="お知らせ | Notifications"
                >
                    <FontAwesomeIcon icon={faBell} />

                    {badge && (
                        <div className="absolute right-0 top-0 -mr-1 -mt-1 block h-5 w-6 rounded-full bg-red-700 text-xs text-white">
                            {badge}
                        </div>
                    )}
                </button>
            </DarkTooltip>
        </div>
    );
};

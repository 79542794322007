import { Rect } from '@view-model/models/common/basic';
import { ReadonlyFrame } from './ReadonlyFrame';
import { ResizableFrame } from './ResizableFrame';

type Props = {
    readonly: boolean;
    viewRect: Rect;
    onResizeStart(): void;
    onResizeEnd(viewRect: Rect): void;
};

export const ViewSelectionFrame: React.FC<Props> = ({ readonly, viewRect, onResizeStart, onResizeEnd }: Props) => {
    return readonly ? (
        <ReadonlyFrame viewRect={viewRect} />
    ) : (
        <ResizableFrame viewRect={viewRect} onResizeStart={onResizeStart} onResizeEnd={onResizeEnd} />
    );
};

import { Point } from '@view-model/models/common/basic';

import { Size } from '@view-model/models/common/types/ui';
import { contentTransformAtom } from '@user/pages/ViewModelPage/contentTransformAtom';
import { useAtomValue } from 'jotai';
import { PopupMenuTeleporter } from '@user/pages/ViewModelPage/teleporters';
import { useViewPosition } from '@view-model/ui/components/View/ViewContext';

type Props = {
    position: Point;
    size: Size;
    children: React.ReactNode;
    positionOrigin: 'left-bottom' | 'center-bottom'; // メニューの表示位置の基準
    withoutFrameRect?: boolean; // 枠や背景が必要ないかどうか
};

export const MenuContainer: React.FC<Props> = ({
    position,
    children,
    size,
    positionOrigin,
    withoutFrameRect = false,
}: Props) => {
    const contentTransform = useAtomValue(contentTransformAtom);
    const scale = (1 / contentTransform.k) * 0.45; // メニューを常に同じ大きさで表示するために補正する
    const origin = positionOrigin === 'left-bottom' ? { x: 0, y: size.height } : { x: size.width / 2, y: size.height };

    const viewPosition = useViewPosition();

    const matrix = new DOMMatrix()
        .translateSelf(-origin.x, -origin.y)
        .translateSelf(viewPosition.x, viewPosition.y)
        .translateSelf(position.x, position.y)
        .scaleSelf(scale, scale, 1, origin.x, origin.y)
        .translateSelf(0, -20); // margin

    return (
        <PopupMenuTeleporter.Source>
            <g transform={matrix.toString()} fill="white">
                {!withoutFrameRect && (
                    <rect
                        x={0}
                        y={0}
                        width={size.width}
                        height={size.height}
                        rx={2}
                        ry={2}
                        fill="white"
                        stroke="#e0e0e0"
                        strokeWidth={4}
                    />
                )}
                {children}
            </g>
        </PopupMenuTeleporter.Source>
    );
};

import { useContext } from 'react';
import { CommandManagerContext } from './CommandManagerContext';
import { CommandManager } from './CommandManager';

/**
 * コンテキストからCommandManagerを取り出すフック
 */
export const useCommandManager = (): CommandManager => {
    const commandManager = useContext(CommandManagerContext);
    if (!commandManager) {
        throw new Error('useCommandManager must be inside a Provider with a value');
    }
    return commandManager;
};

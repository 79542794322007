import { Button } from '@framework/ui/atoms/Button';
import { LoginForm } from '@user/Login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-regular-svg-icons';
import { UserPublicProfile, useCurrentUserPublicProfile } from '@user/PublicProfile';
import { useUserType } from '@framework/auth';

type Props = {
    message?: string;
    linkTo?: string;
    linkToMessage?: string;
};

export const NotFound: React.FC<Props> = ({ message, linkTo, linkToMessage }: Props) => {
    const currentUserProfile = useCurrentUserPublicProfile();
    const isAnonymousUser = useUserType() === 'anonymous';

    return (
        <div className="flex items-center justify-center" style={{ height: `calc(100vh - 5rem)` }}>
            <div className="flex flex-col items-center rounded-lg bg-white px-5 py-2">
                <FontAwesomeIcon icon={faFrown} className="text-4xl text-brand" />

                <div className="mt-2 text-center text-2xl font-bold text-brand">
                    {message || '指定のページが見つかりませんでした。'}
                </div>

                <div className="mt-4 text-center text-2xl font-bold">
                    <a href={linkTo || '/'}>
                        <Button>{linkToMessage || 'Balus 2.0 のトップページに戻る'}</Button>
                    </a>
                </div>

                <div className="pt-8" />

                <NotFoundContent isAnonymousUser={isAnonymousUser} currentUserProfile={currentUserProfile} />
            </div>
        </div>
    );
};

type ContentProps = {
    isAnonymousUser: boolean;
    currentUserProfile: UserPublicProfile | null;
};

/**
 * 対象ページが見つからない場合のコンテンツ
 * (新ログインフォームを利用)
 */
const NotFoundContent: React.FC<ContentProps> = ({ isAnonymousUser, currentUserProfile }: ContentProps) => {
    // 未ログイン状態ならば、シンプルにログインボタンを表示する
    if (!currentUserProfile) {
        return <LoginForm />;
    }

    // 匿名ユーザとしてログイン状態ならば、その旨を表示して、正規のBalusユーザとしてログインを促す
    if (isAnonymousUser) {
        return (
            <>
                <div className="pb-4 text-center">
                    現在、匿名ユーザとしてログインしています。
                    <br />
                    Balusアカウントをお持ちの場合にはログイン後に再度アクセスしてみてください。
                </div>
                <LoginForm />
            </>
        );
    }

    // 通常ユーザとしてログイン済みでアクセスできない場合には、ユーザ名を表示して確認を促す
    return (
        <>
            <div className="pb-4 text-center">
                現在「{currentUserProfile.name}」としてログインしています。
                <br />
                他のBalusアカウントをお持ちの場合には、ログインし直した後に再度アクセスしてみてください。
            </div>
            <LoginForm />
        </>
    );
};

import { useCallback, useEffect, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { ViewModelEntity, ViewModelOperation, ViewModelSharingUserRoleType } from '@view-model/domain/view-model';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { SettingForm } from './SettingForm';
import { useActionLogSender } from '@framework/action-log';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';

type Props = {
    isOpen: boolean;
    onClose(): void;
    workspace: Workspace;
    viewModel: ViewModelEntity;
};

type ViewModelSetting = {
    name: string;
    sharingUserRole: ViewModelSharingUserRoleType;
    isLocked: boolean;
};

export const ViewModelSettingModal: React.FC<Props> = ({ isOpen, onClose, workspace, viewModel }: Props) => {
    const previousIsOpen = usePrevious(isOpen);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [viewModelSetting, setViewModelSetting] = useState<ViewModelSetting>({
        name: '',
        sharingUserRole: ViewModelSharingUserRoleType.None,
        isLocked: false,
    });
    const actionLogSender = useActionLogSender();
    const canSave = viewModelSetting.name.trim() !== '';

    useEffect(() => {
        if (isOpen && !previousIsOpen) {
            // モーダルを開いた時に、各stateにビューモデル・エンティティの情報を設定する
            setViewModelSetting({
                name: viewModel.name.value,
                sharingUserRole: viewModel.sharingUserRole,
                isLocked: viewModel.isLocked,
            });
        }
    }, [isOpen, previousIsOpen, viewModel.isLocked, viewModel.name.value, viewModel.sharingUserRole]);

    const handleChangeName = useCallback(
        (name: string) =>
            setViewModelSetting({
                ...viewModelSetting,
                name,
            }),
        [viewModelSetting]
    );

    const handleChangeSharingUserRole = useCallback(
        (role: ViewModelSharingUserRoleType) =>
            setViewModelSetting({
                ...viewModelSetting,
                sharingUserRole: role,
            }),
        [viewModelSetting]
    );

    const handleChangeIsLocked = useCallback(
        (isLocked: boolean) =>
            setViewModelSetting({
                ...viewModelSetting,
                isLocked,
            }),
        [viewModelSetting]
    );

    const handleSubmit = useCallback(async () => {
        if (viewModelSetting.name.trim() === '') return;

        setIsSubmitting(true);
        setErrorMessage('');

        try {
            await ViewModelOperation.update(
                viewModel.id,
                viewModelSetting.name,
                viewModelSetting.sharingUserRole,
                viewModelSetting.isLocked,
                actionLogSender
            );
            onClose();
        } catch (err) {
            console.error(err);
            setErrorMessage('ビューモデルの更新に失敗しました。');
        } finally {
            setIsSubmitting(false);
        }
    }, [
        actionLogSender,
        onClose,
        viewModel.id,
        viewModelSetting.isLocked,
        viewModelSetting.name,
        viewModelSetting.sharingUserRole,
    ]);

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            submitButton={
                <Button color="primary" onClick={handleSubmit} disabled={!canSave} loading={isSubmitting}>
                    保存 | Done
                </Button>
            }
            cancelButton={
                <Button color="secondary" onClick={onClose} disabled={isSubmitting}>
                    閉じる | Cancel
                </Button>
            }
            title={'設定 | Settings'}
        >
            <div className="p-4" style={{ width: '600px' }}>
                <SettingForm
                    viewModelURLShareable={workspace.setting.isViewModelURLShareable}
                    isPublicWorkspace={workspace.setting.isPublicSpace}
                    name={viewModelSetting.name}
                    onChangeName={handleChangeName}
                    sharingUserRole={viewModelSetting.sharingUserRole}
                    onChangeSharingUserRole={handleChangeSharingUserRole}
                    isLocked={viewModelSetting.isLocked}
                    onChangeIsLocked={handleChangeIsLocked}
                    disabled={isSubmitting}
                />

                {errorMessage && <div className="py-2 text-red-500">{errorMessage}</div>}
            </div>
        </GeneralModal>
    );
};

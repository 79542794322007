import { Workspace } from '@workspace/domain/workspace';
import { useCallback } from 'react';

type Props = {
    workspace: Workspace;
    onDelete(workspace: Workspace): void;
};

export const DeleteWorkspaceMemberButton: React.FC<Props> = ({ workspace, onDelete }: Props) => {
    const handleOpenModal = useCallback(() => {
        onDelete(workspace);
    }, [onDelete, workspace]);

    return (
        <button
            className={'mr-2 border border-red-700 p-1 text-sm text-red-700 hover:bg-red-100 focus:outline-none'}
            onClick={handleOpenModal}
        >
            ワークスペースメンバーから削除
        </button>
    );
};

import { UserPublicProfileJSON } from '@schema-app/user/public-profiles/{userId}/UserPublicProfileJSON';

export class UserPublicProfile {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly imageUrl: string | null
    ) {}

    dump(): UserPublicProfileJSON {
        const { id, name, imageUrl } = this;
        return { id, name, imageUrl };
    }

    static load(dump: UserPublicProfileJSON): UserPublicProfile {
        const { id, name, imageUrl } = dump;
        return new this(id, name, imageUrl);
    }

    static compare(a: UserPublicProfile, b: UserPublicProfile): number {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }
}

export const isUserPublicProfile = (value: unknown): value is UserPublicProfile => {
    return value instanceof UserPublicProfile;
};

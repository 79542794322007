import { useState } from 'react';
import { MultiFactorAuthForm } from './MultiFactorAuthForm';
import { PasswordLoginForm } from './PasswordLoginForm';

type Props = {
    onClose(): void;
};

export const PasswordLoginFormContainer: React.FC<Props> = ({ onClose }: Props) => {
    const [temporaryId, setTemporaryId] = useState('');

    return (
        <>
            {temporaryId ? (
                <MultiFactorAuthForm temporaryId={temporaryId} setTemporaryId={setTemporaryId} />
            ) : (
                <PasswordLoginForm setTemporaryId={setTemporaryId} onClose={onClose} />
            )}
        </>
    );
};

import { ClientMouseCursor } from '@view-model/domain/mouse-cursor';

export class ClientMouseCursorSet {
    private readonly cursors: Record<string, ClientMouseCursor>;

    private constructor(cursors: Record<string, ClientMouseCursor>) {
        this.cursors = cursors;
    }

    static empty(): ClientMouseCursorSet {
        return new ClientMouseCursorSet({});
    }

    add(cursor: ClientMouseCursor): ClientMouseCursorSet {
        const newCursors = { ...this.cursors, [cursor.id]: cursor };
        return new ClientMouseCursorSet(newCursors);
    }

    remove(id: string): ClientMouseCursorSet {
        const newCursors = { ...this.cursors };
        delete newCursors[id];

        return new ClientMouseCursorSet(newCursors);
    }

    map<T>(callback: (cursor: ClientMouseCursor) => T): T[] {
        return this.values().map(callback);
    }

    filter(callback: (cursor: ClientMouseCursor) => boolean): ClientMouseCursorSet {
        const newCursors = this.values().reduce(
            (acc, cursor) => {
                if (callback(cursor)) {
                    acc[cursor.id] = cursor;
                }
                return acc;
            },
            {} as Record<string, ClientMouseCursor>
        );

        return new ClientMouseCursorSet(newCursors);
    }

    private values(): ClientMouseCursor[] {
        return Object.values(this.cursors);
    }
}

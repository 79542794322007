import { GroupEntity, GroupMemberRole } from '@group/domain';
import { GroupMemberInvitationBuilder } from '@group/domain/GroupMemberInvitation/GroupMemberInvitationBuilder';
import { UserData } from '@user/UserData';
import { RefBuilder } from '@framework/repository';
import { getRealtimeDatabaseCurrentTimestamp } from '@framework/firebase/rtdb';

export const MAX_INVITATION_COUNT = 100;

export const createGroupMemberInvitations = async (
    group: GroupEntity,
    sender: UserData,
    emails: string[],
    memberRole: GroupMemberRole,
    message: string
): Promise<boolean> => {
    if (emails.length < 1 || emails.length > MAX_INVITATION_COUNT) {
        return false;
    }

    const now = await getRealtimeDatabaseCurrentTimestamp();

    const builder = new GroupMemberInvitationBuilder(group, sender, emails, memberRole, message);
    const update = builder.buildUpdates(now);

    return new Promise((resolve) => {
        RefBuilder.ref().update(update, (err) => {
            if (err) console.error(err);
            resolve(!err);
        });
    });
};

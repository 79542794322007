import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPen } from '@fortawesome/free-solid-svg-icons';
import { usePopupRef } from '@framework/hooks';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { classNames } from '@framework/utils';

type Props = {
    onEdit: () => void;
    onDelete: () => void;
};

export const WorkspaceEditButton: React.FC<Props> = ({ onEdit, onDelete }: Props) => {
    const { isOpen, popupRef, handleClose, handleOpen } = usePopupRef<HTMLDivElement>(false);

    const handleDelete = useCallback(() => {
        onDelete();
        handleClose();
    }, [handleClose, onDelete]);

    return (
        <div className="relative">
            <div className="inline-flex">
                <button
                    className="rounded-l bg-gray-500 py-1 pl-3 pr-2 font-bold text-white hover:bg-gray-700 focus:outline-none"
                    onClick={onEdit}
                >
                    <FontAwesomeIcon icon={faPen} className="mr-1" />
                    編集
                </button>
                <button
                    className="rounded-r bg-gray-500 px-2 py-1 hover:bg-gray-700 focus:outline-none"
                    onClick={handleOpen}
                >
                    <FontAwesomeIcon icon={faEllipsisVertical} className="text-white" />
                </button>
            </div>

            <div
                className={classNames(
                    'absolute right-0 z-10 w-32 rounded-lg bg-white py-1 shadow-lg',
                    isOpen ? 'block' : 'hidden'
                )}
                ref={popupRef}
            >
                <button
                    className="block w-full px-2 py-1 text-left text-red-700 hover:bg-indigo-500 hover:text-white"
                    onClick={handleDelete}
                >
                    <span className="inline-block w-6 text-center">
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                    削除
                </button>
            </div>
        </div>
    );
};

import { ClipboardPayload } from '@model-framework/clipboard';

const Target = 'Any'; // 情報として不要だがClipboardPayloadの互換性のためセット
const ContentType = 'text';

export type TextClipboardPayload = ClipboardPayload & {
    target: typeof Target;
    contentType: typeof ContentType;
    data: string;
};

export namespace TextClipboardPayload {
    export function fromData(data: string): TextClipboardPayload {
        return {
            target: Target,
            contentType: ContentType,
            data,
        };
    }
}

export const isTextClipboardPayload = (payload: ClipboardPayload): payload is TextClipboardPayload => {
    return payload.contentType === ContentType;
};

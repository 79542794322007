import { ModelCommentAuthor } from './ModelCommentAuthor';
import { Random } from '@framework/Random';
import { UserKey } from '@user/domain';
import { Timestamp } from '@framework/Timestamp';
import { isEqual } from 'lodash';
import { ModelCommentId, UserId } from '@schema-common/base';
import { ModelCommentJSON } from '@schema-common/view-model';

export class ModelCommentEntity {
    constructor(
        public readonly id: ModelCommentId,
        private readonly author: ModelCommentAuthor,
        private readonly body: string,
        private readonly createdAt: Timestamp,
        private readonly updatedAt: Timestamp,
        private readonly type: ModelCommentJSON['type']
    ) {}

    static buildNewId(): ModelCommentId {
        return Random.generateRandomID(20, 'MC');
    }

    static buildNew(author: ModelCommentAuthor, body: string, type?: ModelCommentJSON['type']): ModelCommentEntity {
        const now = Timestamp.now();
        return new this(this.buildNewId(), author, body, now, now, type || 'Regular');
    }

    static load(dump: ModelCommentJSON): ModelCommentEntity {
        const { id, author, body, type } = dump;

        const createdAt = Timestamp.fromISOString(dump.createdAt);
        const updatedAt = Timestamp.fromISOString(dump.updatedAt);

        return new this(id, ModelCommentAuthor.load(author), body, createdAt, updatedAt, type);
    }

    isEqual(other: ModelCommentEntity): boolean {
        return isEqual(this.dump(), other.dump());
    }

    dump(): ModelCommentJSON {
        const { id, author, body, type } = this;
        return {
            id,
            author: author.dump(),
            body,
            type,
            createdAt: this.createdAt.toISOString(),
            updatedAt: this.updatedAt.toISOString(),
        };
    }

    cloneNew(): ModelCommentEntity {
        return new ModelCommentEntity(
            ModelCommentEntity.buildNewId(),
            this.author,
            this.body,
            this.createdAt,
            this.updatedAt,
            this.type
        );
    }

    compareByPostAt(other: ModelCommentEntity): number {
        return this.getPostAt().compareTo(other.getPostAt());
    }

    getAuthor(): ModelCommentAuthor {
        return this.author;
    }

    getPostAt(): Timestamp {
        return this.createdAt;
    }

    getPostAtAsString(): string {
        return this.createdAt.format('YYYY/MM/DD HH:mm');
    }

    getBody(): string {
        return this.body;
    }

    isAuthorUser(userId: UserId): boolean {
        return this.author.isEqualUserKey(UserKey.buildFromId(userId));
    }

    isRegularComment(): boolean {
        return this.type === 'Regular';
    }

    isSystemComment(): boolean {
        return this.type === 'System';
    }

    withBody(body: string): ModelCommentEntity {
        return new ModelCommentEntity(this.id, this.author, body, this.createdAt, Timestamp.now(), this.type);
    }
}

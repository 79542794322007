import { useEffect } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { FullScreenLoading } from '@framework/ui';
import { UserPageLocationState } from '@user/pages/UserPageLocationState';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { useHistory } from 'react-router-dom';
import { useStoreAuthMode } from './useStoreAllowSignUp';
import { useStoreRequestPath } from './useStoreRequestPath';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

export const LoginCallback: React.FC<unknown> = () => {
    const { fetch: fetchRequestPath } = useStoreRequestPath();
    const { fetch: fetchAuthMode } = useStoreAuthMode();
    const currentUserId = useCurrentUserId();
    const history = useHistory<UserPageLocationState>();

    useEffect(() => {
        const path = UserPagePathBuilder.oauthCallbackPage();
        const callbackUrl = new URL(path, window.location.href).toString();

        const params = new URLSearchParams(window.location.search);
        const code = params.get('code') || '';
        const authMode = fetchAuthMode();

        // 既存のログイン中ユーザに対してJiji認証を連携させる場合には、連携ページにリダイレクトする。
        if (authMode === 'connect') {
            FunctionsUserActions.connectJiji(callbackUrl, code).then(([isSuccess, message]) => {
                const path = UserPagePathBuilder.accountConnectedPage();

                history.push(path, {
                    accountConnectedPage: { message, isSuccess },
                });
            });
            return;
        }

        // ログイン処理に完了している場合、ログイン前のページにリダイレクトする。
        if (currentUserId) {
            // リクエスト元がログイン画面の場合には "/" に遷移する。
            const path = fetchRequestPath();
            const nextPath = path === '/login' || path.startsWith('/login?') ? '/' : path;
            history.push(nextPath);
            return;
        }

        // ログイン処理に失敗したときには、もともとアクセスしようとしていたパスに遷移させる
        // (もとのアクセスしようとしていた画面でログインフォームが表示されているはず)
        const redirectToRequestPath = (locationState: UserPageLocationState) => {
            const path = fetchRequestPath();
            history.push(path, locationState);
        };

        const onSignIn = (token: string) => {
            FirebaseAuthenticationClient.signInWithCustomToken(token).catch((error) => {
                // Firebase Authentication のログイン処理に失敗した時には、
                // ログイン画面に遷移した後で、再度、例外を投げる。
                console.error(error);
                redirectToRequestPath({ loginFailed: true });
                throw error;
            });
        };

        const onSignUp = (token: string) => {
            const params = new URLSearchParams();
            params.set('signUpToken', token);
            params.set('requestPath', fetchRequestPath());
            const path = `${UserPagePathBuilder.signUpPage()}?${params.toString()}`;
            history.push(path, { signUpFinished: true });
        };

        const onNotFound = () => {
            redirectToRequestPath({ userNotFound: true });
        };

        const onAlreadyExists = (mailAddress: string) => {
            redirectToRequestPath({
                loginErrorMessage: `指定のメールアドレス ${mailAddress} で既にユーザが登録されています。下記からログインを選択して、ログインしてください。`,
            });
        };

        // Cloud Functions からエラーが返された場合。
        const onError = (message?: string) => {
            console.warn('Login failed', message);
            redirectToRequestPath({ loginFailed: true });
        };

        FunctionsUserActions.authCallback(
            callbackUrl,
            code,
            authMode === 'signUp',
            onSignIn,
            onSignUp,
            onNotFound,
            onAlreadyExists,
            onError
        );
    }, [history, fetchRequestPath, fetchAuthMode, currentUserId]);

    return <FullScreenLoading />;
};

import { isLinkColor, LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';
import { isEqual } from 'lodash';
import { LinkJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';

export class LinkStyle {
    // 各スタイル属性のデフォルト値
    private static readonly defaultLineStyle: LinkLineStyle = LinkLineStyle.Solid;
    private static readonly defaultMarkStyle: LinkMarkStyle = LinkMarkStyle.Arrow;
    private static readonly defaultColor: LinkColor = LinkColor.Gray;

    // 各スタイル属性に設定可能な値のリスト
    public static readonly lineStyles: Readonly<LinkLineStyle[]> = [LinkLineStyle.Solid, LinkLineStyle.Dashed] as const;
    public static readonly markStyles: Readonly<LinkMarkStyle[]> = [
        LinkMarkStyle.NoMark,
        LinkMarkStyle.Arrow,
        LinkMarkStyle.TwoWayArrow,
        LinkMarkStyle.Diamond,
        LinkMarkStyle.HollowDiamond,
        LinkMarkStyle.HollowTriangle,
    ] as const;

    public constructor(
        public readonly lineStyle: LinkLineStyle,
        public readonly markStyle: LinkMarkStyle,
        public readonly color: LinkColor
    ) {}

    public static buildNew(): LinkStyle {
        return new LinkStyle(this.defaultLineStyle, this.defaultMarkStyle, this.defaultColor);
    }

    public static load(dump: Partial<LinkJSON['style']>): LinkStyle {
        const { lineStyle, markStyle, color } = dump;

        return new LinkStyle(
            lineStyle && LinkLineStyle.isValid(lineStyle) ? lineStyle : this.defaultLineStyle,
            markStyle && LinkMarkStyle.isValid(markStyle) ? markStyle : this.defaultMarkStyle,
            color && isLinkColor(color) ? color : this.defaultColor
        );
    }

    public isEquals(other: LinkStyle): boolean {
        return isEqual(this, other);
    }

    public dump(): LinkJSON['style'] {
        const { color, markStyle, lineStyle } = this;

        return {
            lineStyle,
            markStyle,
            color,
        };
    }

    public withLineStyle(lineStyle: LinkLineStyle): LinkStyle {
        return new LinkStyle(lineStyle, this.markStyle, this.color);
    }

    public withMarkStyle(markStyle: LinkMarkStyle): LinkStyle {
        return new LinkStyle(this.lineStyle, markStyle, this.color);
    }

    withColor(color: LinkColor): LinkStyle {
        return new LinkStyle(this.lineStyle, this.markStyle, color);
    }
}

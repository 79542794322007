import { ViewEntity, ViewName, ViewSetting, ViewCollection } from '@view-model/domain/view';
import { RefBuilder, Reference, RTDBPath } from '@framework/repository';
import { ViewJSON } from '@schema-app/view-model/contents/{viewModelId}/views/{viewId}/ViewJSON';
import { ViewId, ViewModelId } from '@schema-common/base';

export class ViewRepository {
    public constructor(private readonly viewModelId: ViewModelId) {}

    private viewsRef(): Reference {
        return RefBuilder.ref(RTDBPath.View.viewsPath(this.viewModelId));
    }

    private viewRef(viewId: ViewId): Reference {
        return RefBuilder.ref(RTDBPath.View.viewPath(this.viewModelId, viewId));
    }

    private nameRef(viewId: ViewId): Reference {
        return this.viewRef(viewId).child('name');
    }

    private settingRef(viewId: ViewId): Reference {
        return this.viewRef(viewId).child('setting');
    }

    public async save(view: ViewEntity): Promise<ViewEntity> {
        const ref = this.viewRef(view.id);
        const value = view.dump();
        await ref.set(value);

        return view;
    }

    public async saveCollection(views: ViewCollection): Promise<void> {
        await this.viewsRef().update(views.toDumpRecord());
    }

    public async loadCollection(): Promise<ViewCollection> {
        const ref = this.viewsRef();
        const snapshot = await ref.once('value');
        const views = (snapshot.val() as Record<ViewId, ViewJSON>) || {};
        const entities = Object.values(views).map((content) => ViewEntity.load(content));

        return new ViewCollection(entities);
    }

    public async delete(viewId: ViewId): Promise<void> {
        const viewRef = this.viewRef(viewId);

        await viewRef.remove();
    }

    // FIXME: 2022/01/26 後でViewの各値を監視するlistenerはaddListener、removeListenerとして一元化する
    public onUpdateName(viewId: ViewId, callback: (name: ViewName) => void): void {
        this.nameRef(viewId).on('value', (snapshot) => {
            const value = snapshot.val() as ViewJSON['name'];
            const name = new ViewName(value || '');
            callback(name);
        });
    }

    // FIXME: 2022/01/26 後でViewの各値を監視するlistenerはaddListener、removeListenerとして一元化する
    public offUpdateName(viewId: ViewId): void {
        this.nameRef(viewId).off('value');
    }

    async saveSetting(viewId: ViewId, setting: ViewSetting): Promise<ViewSetting> {
        await this.settingRef(viewId).set(setting.dump());
        return setting;
    }
}

import { ElementDescription, ElementDescriptionEditLockKey, ElementDescriptionRepository } from '../domain';
import { EditingUser, EditingUserRepository } from '@model-framework/text/editing-user';
import { CommandManager, UpdateCommand } from '@model-framework/command';
import { ElementId, ModelId, ViewModelId } from '@schema-common/base';
import { UserPublicProfile } from '@user/PublicProfile';
import { ElementDescriptionTarget } from '../domain/ElementDescriptionTarget';
import { getClientId } from '@framework/app';

export class ElementDescriptionOperation {
    private readonly repository: ElementDescriptionRepository;
    private readonly editingUserRepository: EditingUserRepository;
    private readonly editingUser: EditingUser;
    private readonly commandManager: CommandManager;
    private readonly elementId: ElementId;
    private readonly target: ElementDescriptionTarget;

    constructor(
        commandManager: CommandManager,
        currentUserProfile: UserPublicProfile,
        viewModelId: ViewModelId,
        modelId: ModelId,
        target: ElementDescriptionTarget,
        elementId: ElementId
    ) {
        this.commandManager = commandManager;
        this.editingUser = EditingUser.buildFromProfile(currentUserProfile, getClientId());
        this.repository = ElementDescriptionRepository.buildFromIds(viewModelId, modelId, target, elementId);
        this.editingUserRepository = new EditingUserRepository(viewModelId);
        this.elementId = elementId;
        this.target = target;
    }

    /**
     * 要素の補足説明の表示・非表示切り替え。存在しない場合は新規作成。
     */
    toggle(): void {
        this.repository.get().then((description: ElementDescription | null) => {
            if (!description) {
                // 存在しない場合は新規作成。ここではエンティティを永続化するだけでUndoスタックには詰まない。
                // テキストが空文字のため、有効なテキストがUndo/Redoで復元されるように、初回の編集時にUndoスタックを詰む。
                this.create();
                return;
            }

            const command = new UpdateCommand(description, description.toggleVisibility(), this.repository);
            this.commandManager.execute(command);
        });
    }

    private create(): void {
        const newDescription = ElementDescription.build(this.elementId, this.target);
        this.repository.save(newDescription).then();

        // 新規作成時は編集開始する
        const editLockKey = ElementDescriptionEditLockKey.fromElementId(this.elementId);
        this.editingUserRepository.save(editLockKey, this.editingUser).then();
    }
}

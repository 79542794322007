export class FirebaseConfigUtil {
    /**
     * FirebaseのデータベースURLからデータベース名（固有のサブドメイン名）を抽出します。
     *
     * 想定URL形式: https://xxxx-xxxx-xxx.firebaseio.com
     * @param databaseUrl
     */
    static parseDBNameFromURL(databaseUrl: string): string {
        const [scheme, url] = databaseUrl.split('//');
        if (scheme !== 'https:') throw new Error(`Database URL parse error. ${databaseUrl}`);

        const [name, second, top] = url.split('.');
        if (second !== 'firebaseio' || top !== 'com') throw new Error(`Database URL parse error. ${databaseUrl}`);

        return name;
    }
}

import { useCallback } from 'react';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { AuthMode, useStoreAuthMode } from './useStoreAllowSignUp';
import { useStoreRequestPath } from './useStoreRequestPath';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

export type ProviderName = 'google' | 'quadcept';

type LoginFunction = (providerName: ProviderName) => void;

export const useOAuthLogin = (authMode: AuthMode): LoginFunction => {
    const { store: storeRequestPath } = useStoreRequestPath();
    const { store: storeAuthMode } = useStoreAuthMode();

    return useCallback(
        async (providerName: ProviderName) => {
            const redirectUrl = new URL(UserPagePathBuilder.oauthCallbackPage(), window.location.href).toString();

            // 現時点のURLをCookieに保存しておく (ログイン完了後に元のURLに遷移できるように)
            storeRequestPath();
            storeAuthMode(authMode);

            // Jijiの認証画面URLを取得して遷移する
            FunctionsUserActions.getAuthorizationUrl(redirectUrl, providerName).then((url) => {
                window.location.href = url;
            });
        },
        [authMode, storeAuthMode, storeRequestPath]
    );
};

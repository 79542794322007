/**
 * コマンドに関連してよく使われるパターンをまとめたヘルパークラス
 */
import { DBPath } from '@framework/repository';
import { JSONRepository, JSONValueWrapper } from '@model-framework/repository';
import { UpdateCommand } from '@model-framework/command/UpdateCommand';
import { JSONValue } from '@model-framework/repository/JSONRepository';

export class CommandHelper {
    /**
     * 指定されたパス配下を削除するコマンドを返します。
     * @param path 削除対象のパス
     * @return 削除対象のデータが取得できなかった場合はnullを返します。
     */
    static async buildDeleteCommand(path: DBPath): Promise<UpdateCommand<JSONValueWrapper> | null> {
        // Undo時の復元用に値を取得
        const json = await new JSONRepository(path).get();

        // すでに削除されている場合は(=null）削除の必要がないためコマンドを作らずnullを返す
        if (json.dump() === null) return null;

        return this.buildUpdateCommand(path, json.dump(), null);
    }

    static async buildDeleteCommandMulti(...paths: DBPath[]): Promise<(UpdateCommand<JSONValueWrapper> | null)[]> {
        const commands = paths.map((path) => this.buildDeleteCommand(path));
        return Promise.all(commands);
    }

    static buildUpdateCommand(path: DBPath, fromValue: JSONValue, toValue: JSONValue): UpdateCommand<JSONValueWrapper> {
        return new UpdateCommand(
            new JSONValueWrapper(fromValue),
            new JSONValueWrapper(toValue),
            new JSONRepository(path)
        );
    }
}

/**
 * Array に実装されていてほしいが、標準にはない関数をまとめたユーティリティクラス
 */
export class ArrayUtil {
    /**
     * 配列からnullを除外した配列を返す。
     *
     * @param arr 対象の配列
     */
    static filterNull<T>(arr: (T | null)[]): T[] {
        return arr.filter((e): e is Exclude<T, null> => e !== null);
    }

    /**
     * Array を指定の長さまで拡張し、value で埋めたコピーを返します。
     * もし元の array の長さが指定のlengthよりも長かった場合は何もせずコピーをそのまま返します。
     *
     * @param array 配列
     * @param length 返される配列の長さ
     * @param value 拡張する際に埋める値
     */
    static extend<T>(array: T[], length: number, value: T): T[] {
        const arr = array.concat();
        for (let i = arr.length; i < length; i++) {
            arr.push(value);
        }
        return arr;
    }

    /**
     * 配列を渡された評価関数で評価し、結果によって２つの配列に分けて返します。
     * @template T
     * @param arr 配列
     * @param cb 評価関数
     * @returns [true評価の配列, false評価の配列]
     */
    static splitWith<T>(arr: T[], cb: (e: T) => boolean): [T[], T[]] {
        const a: T[] = [];
        const b: T[] = [];

        arr.forEach((elem) => {
            if (cb(elem)) {
                a.push(elem);
            } else {
                b.push(elem);
            }
        });

        return [a, b];
    }
}

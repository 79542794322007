import { ModelCommentAuthor } from '../../domain';
import { generateDefaultUserIcon } from '@framework/utils';

const Constants = {
    avatar: {
        padding: 6,
        width: 48,
        height: 48,
    },
};

type Props = {
    author: ModelCommentAuthor;
};

export const ModelCommentHeader: React.FC<Props> = ({ author }: Props) => {
    const authorImageURL = author.getImageURL();
    return (
        <div className="mb-2 flex items-center">
            <div
                style={{
                    padding: Constants.avatar.padding,
                    width: Constants.avatar.width + Constants.avatar.padding * 2,
                    height: Constants.avatar.height + Constants.avatar.padding * 2,
                }}
            >
                {authorImageURL ? (
                    <img
                        className="max-w-none rounded-full bg-neutral-300 object-cover"
                        src={authorImageURL}
                        style={{ width: Constants.avatar.width, height: Constants.avatar.height }}
                    />
                ) : (
                    <div
                        className="max-w-none overflow-hidden rounded-full bg-neutral-300 object-cover"
                        style={{ width: Constants.avatar.width, height: Constants.avatar.height }}
                    >
                        {generateDefaultUserIcon({
                            id: author.getUserKey().id,
                            name: author.getName(),
                            size: 48,
                        })}
                    </div>
                )}
            </div>
            <div className="overflow-hidden text-ellipsis font-bold">{author.getName()}</div>
        </div>
    );
};

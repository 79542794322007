import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '@framework/utils';

type Result = {
    isPopupOpen: boolean;
    isMouseOver: boolean;
    showMenuButton: boolean;
    handleMouseEnter: () => void;
    handleMouseLeave: () => void;
    handleToggleMenuButton: () => void;
    menuButtonRef: React.RefObject<HTMLButtonElement>;
};

type Handler = (e: MouseEvent) => unknown;

const useSidebarPopupRef = (): Result => {
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const rootNode = useRef<HTMLElement>(document.querySelector('#root'));
    const menuButtonRef = useRef<HTMLButtonElement | null>(null);
    const documentClickHandler = useRef<Handler>();

    const handleMouseEnter = useCallback(() => {
        setIsMouseOver(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        // ポップアップが開いている時は要素にマウスオーバーされた状態を維持
        if (isPopupOpen) return;
        setIsMouseOver(false);
    }, [isPopupOpen]);

    const handleToggleMenuButton = useCallback(() => {
        const handler = documentClickHandler.current;
        if (!handler) return;

        if (isPopupOpen) {
            setIsPopupOpen(false);
        } else {
            setIsPopupOpen(true);
            if (rootNode.current) {
                rootNode.current.addEventListener('click', handler);
            }
        }
    }, [isPopupOpen, documentClickHandler]);

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (!menuButtonRef.current) {
                return;
            }

            // ポップアップかメニューボタンをクリックした時は何もしない
            // また、メニューボタンが乗っているタイトル部分をクリックした時も何もしない
            if (
                menuButtonRef.current.contains(e.target as Node) ||
                menuButtonRef.current?.parentNode?.contains(e.target as Node)
            ) {
                return;
            }

            setIsMouseOver(false);
            setIsPopupOpen(false);
            if (rootNode.current) {
                rootNode.current.removeEventListener('click', handler);
            }
        };

        documentClickHandler.current = handler;
    }, [menuButtonRef, documentClickHandler]);

    return {
        isPopupOpen,
        isMouseOver,
        showMenuButton: isPopupOpen || isMouseOver,
        handleMouseEnter,
        handleMouseLeave,
        handleToggleMenuButton,
        menuButtonRef,
    };
};

type SidebarMenuButtonProps = {
    showMenuButton: boolean;
    menuButtonRef: React.Ref<HTMLButtonElement>;
    handleToggleMenuButton: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const SidebarMenuButton: React.FC<SidebarMenuButtonProps> = ({
    showMenuButton,
    menuButtonRef,
    handleToggleMenuButton,
}: SidebarMenuButtonProps) => {
    return (
        <button
            ref={menuButtonRef}
            className={classNames(
                'absolute h-5 w-5 rounded-sm bg-gray-500 text-white focus:outline-none',
                showMenuButton ? 'block' : 'hidden'
            )}
            onClick={handleToggleMenuButton}
            style={{ top: '4px', right: '4px' }}
        >
            <FontAwesomeIcon icon={faEllipsisH} />
        </button>
    );
};

type SidebarPopupWrapperProps = {
    isPopupOpen: boolean;
    children: ReactNode;
};

const SidebarPopupWrapper: React.FC<SidebarPopupWrapperProps> = ({
    isPopupOpen,
    children,
}: SidebarPopupWrapperProps) => {
    return (
        <div
            className={classNames(
                'absolute z-10 block rounded-lg border border-gray-500 bg-white py-1 shadow-lg',
                isPopupOpen ? 'block' : 'hidden'
            )}
            style={{ top: '2em', right: '4px' }}
        >
            {children}
        </div>
    );
};

type Props = {
    children: ReactNode;
    popupMenu: ReactNode | null;
    isSelected: boolean;
    isMenuButtonShowable: boolean;
};

export const SidebarMenuItem: React.FC<Props> = ({ children, popupMenu, isSelected, isMenuButtonShowable }: Props) => {
    const { isPopupOpen, showMenuButton, handleMouseEnter, handleMouseLeave, handleToggleMenuButton, menuButtonRef } =
        useSidebarPopupRef();

    return (
        <>
            <div
                className={classNames(
                    'relative flex-grow overflow-hidden rounded-sm py-1 pr-1',
                    showMenuButton ? (isSelected ? 'bg-gray-400' : 'bg-gray-300') : isSelected ? 'bg-gray-300' : null
                )}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
                {isMenuButtonShowable && showMenuButton && (
                    <SidebarMenuButton
                        showMenuButton={showMenuButton}
                        menuButtonRef={menuButtonRef}
                        handleToggleMenuButton={handleToggleMenuButton}
                    />
                )}
            </div>
            {isPopupOpen && <SidebarPopupWrapper isPopupOpen={isPopupOpen}>{popupMenu}</SidebarPopupWrapper>}
        </>
    );
};

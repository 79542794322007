import { StickyZoneKey } from '@view-model/domain/key';
import { StickyZone } from './StickyZone';
import { StickyZoneStyle } from './StickyZoneStyle';
import { RefBuilder, Reference, RTDBPath } from '@framework/repository';
import { StickyZoneJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/sticky-zones/{stickyZoneKey}/StickyZoneJSON';
import { ModelId, StickyZoneId, StickyZoneKeyString, ViewModelId } from '@schema-common/base';
import { ClientSelectedItemRepository } from '@model-framework/client-selected-item';
import { PositionSetRepository } from '@view-model/models/common/PositionSet';

export class StickyZoneRepository {
    private readonly clientSelectedItemRepository: ClientSelectedItemRepository;

    public constructor(
        private readonly viewModelId: ViewModelId,
        private readonly modelId: ModelId
    ) {
        this.clientSelectedItemRepository = new ClientSelectedItemRepository(viewModelId, modelId);
    }

    static createPositionsRepository(viewModelId: ViewModelId, modelId: ModelId): PositionSetRepository {
        return new PositionSetRepository(RTDBPath.Zone.positionsPath(viewModelId, modelId));
    }

    private zonesRef(): Reference {
        return RefBuilder.ref(RTDBPath.Zone.zonesPath(this.viewModelId, this.modelId));
    }

    private zoneRef(zoneKey: StickyZoneKey): Reference {
        return RefBuilder.ref(RTDBPath.Zone.zonePath(this.viewModelId, this.modelId, zoneKey));
    }

    async saveZone(zone: StickyZone): Promise<StickyZone> {
        return (await this.saveZones([zone]))[0];
    }

    async saveZones(zones: StickyZone[]): Promise<StickyZone[]> {
        const updates: Record<string, StickyZoneJSON> = {};
        zones.forEach((zone) => {
            updates[zone.key.toString()] = zone.dump();
        });

        await this.zonesRef().update(updates);
        return zones;
    }

    async saveStyles(styles: Record<StickyZoneKeyString, StickyZoneStyle>): Promise<void> {
        if (Object.keys(styles).length === 0) return;

        const updates: Record<string, StickyZoneJSON['style']> = {};
        Object.entries(styles).forEach(([keyStr, style]) => {
            updates[`${keyStr}/style`] = style.dump();
        });

        await this.zonesRef().update(updates);
    }

    async getStyle(zoneKey: StickyZoneKey): Promise<StickyZoneStyle | null> {
        const ref = this.zoneRef(zoneKey).child('style');
        const snapshot = await ref.once('value');
        const j = snapshot.val() as StickyZoneJSON['style'];
        if (!j) return null;

        return StickyZoneStyle.load(j);
    }

    async loadZones(): Promise<StickyZone[]> {
        const snapshot = await this.zonesRef().once('value');
        const zones = (snapshot.val() as Record<StickyZoneId, StickyZoneJSON>) || {};

        return Object.values(zones).map((content) => StickyZone.load(content));
    }

    async deleteZone(key: StickyZoneKey): Promise<void> {
        await this.deleteZones([key]);
    }

    async deleteZones(keys: StickyZoneKey[]): Promise<void> {
        const updates: Record<string, null> = {};
        keys.forEach((key) => {
            updates[key.toString()] = null;
        });

        await this.zonesRef().update(updates);

        const itemIds = keys.map((key) => key.id);
        await this.clientSelectedItemRepository.deleteAllByItemIds(itemIds);
    }

    onAddZone(callback: (newZone: StickyZone) => void): void {
        this.zonesRef().on('child_added', (snapshot) => {
            const j = snapshot.val() as StickyZoneJSON;
            callback(StickyZone.load(j));
        });
    }

    offAddZone(): void {
        this.zonesRef().off('child_added');
    }

    onDeleteZone(callback: (deletedZoneKey: StickyZoneKey) => void): void {
        this.zonesRef().on('child_removed', (snapshot) => {
            const j = snapshot.val() as StickyZoneJSON;
            j?.key && callback(new StickyZoneKey(j.key));
        });
    }

    offDeleteZone(): void {
        this.zonesRef().off('child_removed');
    }

    onChangeZone(callback: (updatedZone: StickyZone) => void): void {
        this.zonesRef().on('child_changed', (snapshot) => {
            const j = snapshot.val() as StickyZoneJSON;
            callback(StickyZone.load(j));
        });
    }
    offChangeZone(): void {
        this.zonesRef().off('child_changed');
    }
}

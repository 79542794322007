import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePopupRef } from '@framework/hooks';
import { classNames } from '@framework/utils';

type Props = {
    menuTitle: string;
    onClickMenu: () => void;
    disabled?: boolean;
};

export const BasicItemMenuButton: React.FC<Props> = ({ menuTitle, onClickMenu, disabled = false }: Props) => {
    const { isOpen, popupRef, handleToggle } = usePopupRef<HTMLButtonElement>(false);
    return (
        <>
            <button
                ref={popupRef}
                className={classNames(
                    'flex h-8 w-8 items-center justify-center rounded-sm border-2 border-gray-500 focus:outline-none',
                    disabled ? 'cursor-not-allowed bg-gray-400' : ''
                )}
                onClick={handleToggle}
                style={{ top: '4px', right: '4px' }}
                disabled={disabled}
            >
                <FontAwesomeIcon icon={faEllipsisH} />
            </button>
            <div
                className={classNames(
                    'absolute z-10 block w-40 rounded-lg border border-gray-500 bg-white py-1 shadow-lg',
                    isOpen ? 'block' : 'hidden'
                )}
                style={{ right: '24px' }}
            >
                <button
                    className={classNames(
                        'flex w-full items-center justify-center py-2 pl-1 text-left text-red-700 hover:bg-indigo-500 hover:text-white'
                    )}
                    onClick={onClickMenu}
                >
                    {menuTitle}
                </button>
            </div>
        </>
    );
};

import { useCallback } from 'react';
import { classNames } from '@framework/utils';

type Props = {
    value: string;
    onChange(value: string): void;
    error: string | null;
    autoFocus?: boolean;
    disabled?: boolean;
    onBlur?(): void;
};

export const PasswordInput: React.FC<Props> = ({ value, onChange, error, autoFocus, disabled, onBlur }: Props) => {
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        },
        [onChange]
    );

    return (
        <div>
            <input
                type="password"
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                className={classNames(
                    'w-full rounded-sm border p-2',
                    error ? 'border-red-700' : '',
                    disabled ? 'bg-gray-200' : ''
                )}
                autoFocus={autoFocus}
                disabled={disabled}
            />
            {error && <div className="text-sm text-red-700">{error}</div>}
        </div>
    );
};

import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { classNames } from '@framework/utils';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    tooltip: string;
    icon: IconDefinition;
    className?: string;
    onClick(): void;
    disabled?: boolean;
};

export const Button: React.FC<Props> = ({ tooltip, icon, className, onClick, disabled }: Props) => {
    return (
        <DarkTooltip tooltip={tooltip} placement="right">
            <button
                onClick={onClick}
                className={classNames(
                    'h-10 w-10 cursor-pointer text-2xl font-bold focus:outline-none',
                    disabled ? 'text-gray-500' : 'text-gray-700 hover:bg-gray-200',
                    className
                )}
                aria-label={tooltip}
                // FIXME: https://github.com/levii/balus-app/issues/2899
                // disabledの場合、hover時に表示されるtooptipがhoverが解除された後も表示され続けるので、
                // ここではbuttonのstyleのみをdisabledにしておく
                // disabled={disabled}
            >
                <FontAwesomeIcon icon={icon} />
            </button>
        </DarkTooltip>
    );
};

import { ModelElementPositionableKey, ModelElementPositionMap } from '@view-model/domain/model';
import { NodePosition, NodeRepository } from '@view-model/models/sticky/StickyNodeView';
import { StickyZonePosition, StickyZoneRepository } from '@view-model/models/sticky/StickyZoneView';
import { isNodeKey, isStickyZoneKey, NodeKey, StickyZoneKey } from '@view-model/domain/key';
import { IPositionSetRepository, PositionSet } from '@view-model/models/common/PositionSet';
import { Position } from '@view-model/models/common/types/ui';
import { ModelId, ViewModelId } from '@schema-common/base';

export class StickyModelElementPositionMapRepository {
    private readonly nodeRepository: NodeRepository;
    private readonly zonePositionRepository: IPositionSetRepository;

    constructor(viewModelId: ViewModelId, modelId: ModelId) {
        this.nodeRepository = new NodeRepository(viewModelId, modelId);
        this.zonePositionRepository = StickyZoneRepository.createPositionsRepository(viewModelId, modelId);
    }

    async savePositions(positionMap: ModelElementPositionMap): Promise<void> {
        const nodePositions = positionMap.nodeItems();
        const zonePositions = positionMap.zoneItems();

        await Promise.all([this.saveNodePositions(nodePositions), this.saveZonePositions(zonePositions)]);
    }

    async getPositions(keys: ModelElementPositionableKey[]): Promise<ModelElementPositionMap> {
        const nodePositions = await this.getNodePositions(keys.filter(isNodeKey));
        const zonePositions = await this.getZonePositions(keys.filter(isStickyZoneKey));
        return nodePositions.merge(zonePositions);
    }

    private async saveNodePositions(nodePositions: [NodeKey, NodePosition][]): Promise<void> {
        await this.nodeRepository.saveNodePositions(nodePositions);
    }

    private async saveZonePositions(zonePositions: [StickyZoneKey, StickyZonePosition][]): Promise<void> {
        const zonePositionsMap = zonePositions.reduce((rec: Record<string, Position>, [key, position]) => {
            rec[key.id] = position.dump();
            return rec;
        }, {});
        const zonePositionSet = PositionSet.load(zonePositionsMap);
        await this.zonePositionRepository.saveMany(zonePositionSet);
    }

    private async getNodePositions(keys: NodeKey[]): Promise<ModelElementPositionMap> {
        const positions = await this.nodeRepository.getNodePositions(keys);
        return ModelElementPositionMap.fromPositions(positions);
    }

    private async getZonePositions(keys: StickyZoneKey[]): Promise<ModelElementPositionMap> {
        const positionSet = await this.zonePositionRepository.getMany(keys.map((key) => key.id.toString()));
        const positions = positionSet.all().map(([id, position]) => {
            return [StickyZoneKey.buildFromID(id), StickyZonePosition.load(position)];
        }) as [StickyZoneKey, StickyZonePosition][];

        return ModelElementPositionMap.fromPositions(positions);
    }
}

export enum FontSize {
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
    XXL = 'XXL',
}

// 漏れなくFontSizeListを列挙するための中間データ
// （enumに新しい色を追加したり、削除した場合にこちらも編集しないとコンパイルエラーになる仕組み）
// 冗長なので他にいいやり方あれば直した方がよい。
const FontSizeMap: { [key in FontSize]: FontSize } = {
    [FontSize.S]: FontSize.S,
    [FontSize.M]: FontSize.M,
    [FontSize.L]: FontSize.L,
    [FontSize.XL]: FontSize.XL,
    [FontSize.XXL]: FontSize.XXL,
} as const;

const FontSizeList = Object.values(FontSizeMap);

export namespace FontSize {
    export function values(): Readonly<FontSize[]> {
        return FontSizeList;
    }

    export function downsizeFont(size: FontSize): FontSize {
        if (size == FontSize.S) {
            return FontSize.S;
        }
        if (size == FontSize.M) {
            return FontSize.S;
        }
        if (size == FontSize.L) {
            return FontSize.M;
        }
        if (size == FontSize.XL) {
            return FontSize.L;
        }
        if (size == FontSize.XXL) {
            return FontSize.XL;
        }

        // 条件に合致しないときには、フォントサイズを変更しない
        return size;
    }

    export function upsizeFont(size: FontSize): FontSize {
        if (size == FontSize.S) {
            return FontSize.M;
        }
        if (size == FontSize.M) {
            return FontSize.L;
        }
        if (size == FontSize.L) {
            return FontSize.XL;
        }
        if (size == FontSize.XL) {
            return FontSize.XXL;
        }
        if (size == FontSize.XXL) {
            return FontSize.XXL;
        }

        // 条件に合致しないときには、フォントサイズを変更しない
        return size;
    }
}

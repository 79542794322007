import { FontSize } from '@model-framework/text';

const DescriptionPanelFontSizes = [FontSize.S, FontSize.M, FontSize.L] as const;
export type DescriptionPanelFontSize = (typeof DescriptionPanelFontSizes)[number];

export namespace DescriptionPanelFontSize {
    export const S = FontSize.S;
    export const M = FontSize.M;
    export const L = FontSize.L;

    export function values(): Readonly<DescriptionPanelFontSize[]> {
        return DescriptionPanelFontSizes;
    }

    export function fromString(value: string | null): DescriptionPanelFontSize | undefined {
        return DescriptionPanelFontSizes.find((fontSize) => fontSize.toString() === value);
    }

    export function load(value: string | null): DescriptionPanelFontSize {
        return DescriptionPanelFontSize.fromString(value) || DescriptionPanelFontSize.S;
    }

    export function dump(fontSize: DescriptionPanelFontSize): string {
        return fontSize.toString();
    }

    const PixelSizes: Record<DescriptionPanelFontSize, number> = {
        [S]: 32,
        [M]: 48,
        [L]: 64,
    };
    export function toPixelSize(fontSize: DescriptionPanelFontSize): number {
        return PixelSizes[fontSize];
    }
}

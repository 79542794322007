import { DBPath } from '../RTDBPath';
import { DataSnapshot, RefBuilder, Reference } from '../RefBuilder';

// 保存対象の要素オブジェクト
export interface ItemDumpable<DumpType> {
    dump(): DumpType;
}

// 保存対象の要素オブジェクトのクラス (static method のインタフェース定義)
export interface ItemLoadable<DumpType, T extends ItemDumpable<DumpType>> {
    load(dump: DumpType): T;
}

export class RecordRepository<ValueDumpType, ValueType extends ItemDumpable<ValueDumpType>> {
    private addedCallback: ((value: DataSnapshot) => void) | undefined = undefined;
    private changedCallback: ((value: DataSnapshot) => void) | undefined = undefined;
    private removedCallback: ((value: DataSnapshot) => void) | undefined = undefined;
    private readonly ref: Reference;

    /**
     * 指定パス配下の値リストを操作するRepositoryを返します。
     * このRepositoryは値リストを Record 形式で保持します。
     * Record の key は RTDB上の祖先キーと一致します。
     *
     * オブジェクト集合を任意のオブジェクトで表現したい場合には、 MapRepository を利用してください。
     *
     * @param valueClass
     * @param path
     */
    constructor(
        private readonly valueClass: ItemLoadable<ValueDumpType, ValueType>,
        path: DBPath
    ) {
        this.ref = RefBuilder.ref(path);
    }

    /**
     * 指定パス配下に値リスト(Record)を保存する。
     * @param values
     */
    async save(values: Record<string, ValueType>): Promise<void> {
        const dumpValues: Record<string, ValueDumpType> = {};
        for (const key in values) {
            dumpValues[key] = values[key].dump();
        }
        await this.ref.set(dumpValues);
    }

    /**
     * 指定パス配下の値リストをRecord形式で取得する。
     * @returns
     */
    async get(): Promise<Record<string, ValueType>> {
        const snapshot = await this.ref.once('value');
        const values: Record<string, ValueType> = {};
        snapshot.forEach((childSnapshot) => {
            const key = childSnapshot.key;
            const value = this.valueClass.load(childSnapshot.val());
            if (key && value) {
                values[key] = value;
            }
        });
        return values;
    }

    async delete(): Promise<void> {
        await this.ref.remove();
    }

    /**
     * 指定パス配下の値リストの追加・更新・削除時にコールバックするリスナーを登録する。
     * @param onAdded
     * @param onChanged
     * @param onRemoved
     */
    addListener(
        onAdded: (value: ValueType, parentKey: string | null) => void,
        onChanged: (value: ValueType, parentKey: string | null) => void,
        onRemoved: (value: ValueType, parentKey: string | null) => void
    ): void {
        const { ref } = this;

        this.addedCallback = (snapshot) => {
            const j = snapshot.val() as ValueDumpType | null;
            if (!j) return;
            onAdded(this.valueClass.load(j), snapshot.key);
        };
        ref.on('child_added', this.addedCallback);

        this.changedCallback = (snapshot) => {
            const j = snapshot.val() as ValueDumpType | null;
            if (!j) return;
            onChanged(this.valueClass.load(j), snapshot.key);
        };
        ref.on('child_changed', this.changedCallback);

        this.removedCallback = (snapshot) => {
            const j = snapshot.val() as ValueDumpType | null;
            if (!j) return;
            onRemoved(this.valueClass.load(j), snapshot.key);
        };
        ref.on('child_removed', this.removedCallback);
    }

    removeListener(): void {
        const { ref } = this;

        // この Repository の addListener() で登録したリスナーだけを対象に、リスン解除する。
        if (this.addedCallback) {
            ref.off('child_added', this.addedCallback);
            this.addedCallback = undefined;
        }

        if (this.changedCallback) {
            ref.off('child_changed', this.changedCallback);
            this.changedCallback = undefined;
        }

        if (this.removedCallback) {
            ref.off('child_removed', this.removedCallback);
            this.removedCallback = undefined;
        }
    }
}

import { useEffect, useState } from 'react';
import { GroupId, GroupMemberInvitationId } from '@schema-common/base';
import { GroupMemberInvitation } from '@group/domain';
import { ObjectRepository, RTDBPath } from '@framework/repository';

export const useGroupMemberInvitation = (
    groupId: GroupId,
    invitationId: GroupMemberInvitationId
): {
    loading: boolean;
    invitation: GroupMemberInvitation | null;
} => {
    const [loading, setLoading] = useState(true);
    const [invitation, setInvitation] = useState<GroupMemberInvitation | null>(null);

    useEffect(() => {
        const repo = new ObjectRepository(
            GroupMemberInvitation,
            RTDBPath.Group.memberInvitationPath(groupId, invitationId)
        );
        repo.get()
            .then((invitation) => {
                setInvitation(invitation);
                setLoading(false);
            })
            .catch(() => {
                // permission denied の場合例外となりこのフローに来る(invitationIdが正しくないことやセキュリティルールに依存）。
                // permission 以外エラーも発生する可能性はあるが一律ここでキャッチして招待状が見つからなかったものとして処理を続行させる。
                setInvitation(null);
                setLoading(false);
            });
    }, [groupId, invitationId]);

    return { loading, invitation };
};

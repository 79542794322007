import { ReactNode, useEffect } from 'react';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Icon } from '@framework/ui/atoms/Icon';
import { useHistory } from 'react-router-dom';
import { TrackJS } from 'trackjs';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }: FallbackProps) => {
    useEffect(() => TrackJS.track(error), [error]);

    const history = useHistory();

    return (
        <div className="absolute left-0 top-0 flex h-screen w-screen flex-col items-center justify-center">
            <div className="flex flex-col items-center">
                <Icon name="balus" color="brand" size="brand-xl" />
                <div className="mt-3 font-bold text-brand">Balus 2.0</div>

                <h2 className="my-5 text-2xl font-bold">システムエラーが発生しました。</h2>

                <p>ご迷惑をおかけして申し訳ありません。</p>
                <p>エラーの内容はサービス運営者に通知されました。</p>
                <p>しばらく時間をあけてから再度試してみてください。</p>

                <a
                    href="#"
                    className="mt-5 text-brand underline"
                    onClick={() => {
                        resetErrorBoundary();
                        try {
                            // 過去のページに戻れない場合 (file:// の場合には戻れない) は、トップページに遷移する
                            history.goBack();
                        } catch {
                            history.push('/');
                        }
                    }}
                >
                    前のページに戻る
                </a>
            </div>
        </div>
    );
};

type Props = {
    children: ReactNode;
};

export const ErrorBoundary: React.FC<Props> = ({ children }: Props) => {
    return <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>;
};

import { parse as parseCsv } from 'csv-parse/sync';
import { ClipboardPayload } from '@model-framework/clipboard';
import { ApplicationClipboardPayload, isApplicationClipboardPayload } from './ApplicationClipboardPayload';
import { ImageClipboardPayload, TSVClipboardPayload, TextClipboardPayload } from '@view-model/application/clipboard';

export class ApplicationClipboardPayloadParser {
    static parse(content: string): ApplicationClipboardPayload | null {
        if (content === '') return null;

        // JSON文字列もTSVとしてvalidなためTSVパースは後に回す
        const payload = this.parseAsJSON(content) || this.parseAsText(content);

        return payload && isApplicationClipboardPayload(payload) ? payload : null;
    }

    static parseFromFiles(files: FileList): ApplicationClipboardPayload | null {
        const images: File[] = [];

        Array.from(files).forEach((file) => {
            if (file.type.startsWith('image/')) {
                images.push(file);
            }
        });

        if (images.length === 0) return null;

        return ImageClipboardPayload.fromData(images);
    }

    private static parseAsJSON(content: string): ClipboardPayload | null {
        try {
            const payload = JSON.parse(content) as ClipboardPayload;

            if (!payload.target) return null;

            return payload;
        } catch (e) {
            return null;
        }
    }

    private static parseAsText(content: string): TSVClipboardPayload | TextClipboardPayload | null {
        // タブまたは改行を含む場合はTSVとしてパースし、パースに失敗した場合はTextClipboardPayloadに
        // フォールバックせずエラーとしてnullを返す
        if (content.includes('\t') || content.includes('\n')) return this.parseAsTSV(content);

        return TextClipboardPayload.fromData(content);
    }

    private static parseAsTSV(content: string): TSVClipboardPayload | null {
        try {
            // データ分析用途などと違いなるべく貼り付けできた方がいいので、各行ごとの列数の不一致は許容する
            // https://csv.js.org/parse/options/relax_column_count/
            const data = parseCsv(content, { delimiter: '\t', relax_column_count: true });

            return TSVClipboardPayload.fromData(data);
        } catch (e) {
            return null;
        }
    }
}

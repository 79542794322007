import { useCallback, useEffect, useMemo, useState } from 'react';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { ElementDescriptionLayout } from '../components';
import { useDraggable } from '@model-framework/drag';
import { UpdateCommand, useCommandManager } from '@model-framework/command';
import { ElementDescription, ElementDescriptionRepository } from '../domain';
import { DragContext } from '@model-framework/ui';

type StickyCalloutHookState = {
    position: Point | null;
    size: Size;
    targetRect: Rect;
    setSize(size: Size): void;
    onDragStart(initialState: Point): void;
    onDrag(context: DragContext): void;
    onDragEnd(): void;
};

/**
 * 付箋にくっつく吹き出しコンポーネントの状態フック
 *
 * @param nodeRect くっつく対象となる付箋領域
 * @param repository
 * @param description
 * @param readonly
 * @return 吹き出しの位置、 吹き出しのサイズ、吹き出しのしっぽが接する対象矩形領域、吹き出し位置のstate更新関数を返します。
 */
export const useElementDescriptionPlacement = (
    nodeRect: Rect,
    repository: ElementDescriptionRepository,
    description: ElementDescription,
    readonly: boolean
): StickyCalloutHookState => {
    const [size, setSize] = useState<Size>(() => ElementDescriptionLayout.initialSize());

    // ユーザーの環境ごとに表示位置が変わる（補足説明を付箋の上に配置。編集中とそうでない人の場合など）を許容するためのstate。
    // 基本的には永続化されたrelativePositionの値を持つが、コンテンツサイズによる位置補正はこのstateだけに反映させる。
    // その上でこのstateを元にした補足説明の絶対座標（position変数）を公開する。
    // https://github.com/levii/balus-app/issues/1311
    const [currentRelativePosition, setCurrentRelativePosition] = useState(description.relativePosition);

    useEffect(() => {
        setCurrentRelativePosition(description.relativePosition);
    }, [description.relativePosition]);

    // このフックの外側で利用するプロパティ（絶対座標）
    const position = useMemo(() => {
        if (!currentRelativePosition) return null;

        const basePosition = Point.fromPosition(nodeRect.position);

        return currentRelativePosition.add(basePosition);
    }, [currentRelativePosition, nodeRect.position]);

    // このフックの外側で利用するプロパティ（吹き出しが接する矩形領域）
    const targetRect = useMemo(() => {
        // マージンを考慮したしっぽの対象矩形を付箋矩形から計算する
        return ElementDescriptionLayout.tailTargetRectFor(nodeRect);
    }, [nodeRect]);

    // コンポーネントの座標系から扱いやすいように、絶対座標による吹き出し位置の更新関数を提供する
    const setPosition = useCallback(
        (position: Point): void => {
            const adjuster = ElementDescriptionLayout.createPositionAdjuster(position, size);
            const basePosition = Point.fromPosition(nodeRect.position);

            const newRelativePosition = adjuster.adjustPositionTo(nodeRect).subtract(basePosition);
            repository.save(description.withRelativePosition(newRelativePosition)).then();
        },
        [description, nodeRect, repository, size]
    );

    // サイズ変更時はレイアウトし直す。
    // ユーザーごとにサイズは異なる可能性があるので（編集中とそうでない人など）、サイズ変更による位置の共有（永続化）はしない。
    useEffect(() => {
        if (!currentRelativePosition) return;
        const basePosition = Point.fromPosition(nodeRect.position);

        const adjuster = ElementDescriptionLayout.createPositionAdjuster(
            currentRelativePosition.add(basePosition),
            size
        );
        const newRelativePosition = adjuster.adjustPositionTo(nodeRect).subtract(basePosition);

        // relativePositionに依存してるのでガードしないと無限ループする
        if (newRelativePosition.isEqual(currentRelativePosition)) return;

        setCurrentRelativePosition(newRelativePosition);
    }, [nodeRect, nodeRect.position, currentRelativePosition, setCurrentRelativePosition, size]);

    const commandManager = useCommandManager();

    const handleDragEnd = useCallback(
        // ハンドルするのは絶対座標だけど保存は付箋に対する相対座標で行う必要がある
        (startPosition: Point, currentPosition: Point) => {
            if (startPosition.isEqual(currentPosition)) return;

            const basePosition = Point.fromPosition(nodeRect.position);

            const adjustedCurrentPosition = ElementDescriptionLayout.createPositionAdjuster(
                currentPosition,
                size
            ).adjustPositionTo(nodeRect);

            const initialPosition = startPosition.subtract(basePosition);
            const endPosition = adjustedCurrentPosition.subtract(basePosition);

            const command = new UpdateCommand(
                description.withRelativePosition(initialPosition),
                description.withRelativePosition(endPosition),
                repository
            );
            commandManager.execute(command);
        },
        [commandManager, description, nodeRect, repository, size]
    );

    const { onDragStart, onDrag, onDragEnd } = useDraggable<Point>(setPosition, handleDragEnd);

    const handleDrag = useCallback(
        ({ dx, dy }: DragContext) => {
            if (readonly) return;
            onDrag(dx, dy);
        },
        [onDrag, readonly]
    );

    return {
        position,
        size,
        targetRect,
        setSize,
        onDragStart,
        onDrag: handleDrag,
        onDragEnd,
    } as const;
};

import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { Crop, ReactCrop } from 'react-image-crop';
import Resizer from 'react-image-file-resizer';
import 'react-image-crop/dist/ReactCrop.css';

type Props = {
    isOpen: boolean;
    onClose(): void;
    onSubmit(image: File): void;
    src: File;
};

export const ImageCropModal: React.FC<Props> = ({ isOpen, onClose, onSubmit, src }: Props) => {
    const [dataURL, setDataURL] = useState<string | null>(null);
    const [croppedImage, setCroppedImage] = useState<File | null>(null);
    const [crop, setCrop] = useState<Crop>({ x: 0, y: 0, unit: '%', width: 0, height: 0 });
    const canSave = useMemo(() => croppedImage && crop && crop.width > 0 && crop.height > 0, [crop, croppedImage]);
    const imageRef = useRef<HTMLImageElement | null>(null);

    // モーダルを閉じた時に、画像のデータをクリアする
    useEffect(() => {
        if (isOpen) return;

        setDataURL(null);
        setCroppedImage(null);
    }, [isOpen]);

    useEffect(() => {
        const reader = new FileReader();
        reader.addEventListener('load', () => setDataURL(reader.result as string));
        reader.readAsDataURL(src);
    }, [src]);

    const resizeImage = (image: Blob) =>
        new Promise((resolve: (blob: Blob) => void) => {
            Resizer.imageFileResizer(
                image,
                200,
                200,
                'PNG',
                100,
                0,
                (resizedImage) => {
                    resolve(resizedImage as Blob);
                },
                'blob'
            );
        });

    const getCroppedImg = useCallback((image: HTMLImageElement, crop: Crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;

        ctx?.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve: (blob: Blob) => void, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }
                    resolve(blob);
                },
                'image/png',
                1
            );
        });
    }, []);

    const handleCropComplete = useCallback(
        async (crop: Crop) => {
            if (!imageRef.current) return;

            try {
                const image = await getCroppedImg(imageRef.current, crop);
                const resizedImage = await resizeImage(image);
                setCroppedImage(resizedImage as File);
            } catch {
                setCroppedImage(null);
            }
        },
        [getCroppedImg]
    );

    const handleCropChange = useCallback((crop: Crop) => {
        setCrop(crop);
    }, []);

    const handleSubmit = useCallback(() => {
        if (!croppedImage) return;
        onSubmit(croppedImage);
    }, [croppedImage, onSubmit]);

    // dataURLが変更されたら、画像のサイズに合わせてcropの初期値を設定する
    useEffect(() => {
        if (!dataURL) return;

        const img = imageRef.current;
        if (!img) return;

        const size = img.width > img.height ? img.height : img.width;

        const crop: Crop = {
            x: 0,
            y: 0,
            width: size,
            height: size,
            unit: 'px',
        };
        setCrop(crop);
        handleCropComplete(crop);
    }, [dataURL, handleCropComplete]);

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            title={'画像の切り取り範囲を設定'}
            submitButton={
                <Button color="primary" onClick={handleSubmit} disabled={!canSave}>
                    保存
                </Button>
            }
            cancelButton={
                <Button color={'secondary'} onClick={onClose}>
                    キャンセル
                </Button>
            }
            // オーバーレイ部分をクリックして閉じると、書き換えの説明が消えて悲しいことになりかねないので、モーダルを閉じないようにする
            shouldCloseOnOverlayClick={false}
        >
            <div className="overflow-y-auto p-4" style={{ width: '600px', maxHeight: '70vh' }}>
                {dataURL && (
                    <ReactCrop
                        crop={crop}
                        aspect={1}
                        ruleOfThirds
                        onComplete={handleCropComplete}
                        onChange={handleCropChange}
                        circularCrop={true}
                    >
                        <img src={dataURL} ref={imageRef} />
                    </ReactCrop>
                )}
            </div>
        </GeneralModal>
    );
};

import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { Input } from '@framework/ui/atoms/Input';

type Props = {
    isOpen: boolean;
    onSubmit(name: string): void;
    onClose(): void;
};

export const FolderCreateModal: React.FC<Props> = ({ isOpen, onSubmit, onClose }: Props) => {
    const [name, setName] = useState<string>('');
    const [canSave, setCanSave] = useState<boolean>(false);

    useEffect(() => {
        setCanSave(name.trim() !== '');
    }, [name]);

    const handleSubmit = useCallback(() => {
        onSubmit(name);
        onClose();
        setName('');
    }, [onSubmit, name, onClose]);

    const handleClose = useCallback(() => {
        setName('');
        onClose();
    }, [onClose]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={handleClose}
            title={'フォルダを追加'}
            submitButton={
                <Button onClick={handleSubmit} disabled={!canSave}>
                    保存
                </Button>
            }
            cancelButton={
                <Button onClick={handleClose} color={'secondary'}>
                    キャンセル
                </Button>
            }
        >
            <div className="p-2">
                <Input type="text" placeholder="フォルダ名" value={name} onChange={handleChange} autoFocus={true} />
            </div>
        </GeneralModal>
    );
};

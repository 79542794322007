export enum NodeMenuId {
    FontSize = 'FontSize',
    ThemeColor = 'ThemeColor',
    HyperLink = 'HyperLink',
    DeleteNode = 'DeleteNode',
    NodeDescription = 'NodeDescription',
    Analysis = 'Analysis',
    Grouping = 'Grouping',
    Reaction = 'Reaction',
}

const NodeMenuIdList: Readonly<NodeMenuId[]> = [
    NodeMenuId.FontSize,
    NodeMenuId.ThemeColor,
    NodeMenuId.HyperLink,
    NodeMenuId.NodeDescription,
    NodeMenuId.Grouping,
    NodeMenuId.Reaction,
    NodeMenuId.Analysis,
    NodeMenuId.DeleteNode,
] as const;

export namespace NodeMenuId {
    export function values(): Readonly<NodeMenuId[]> {
        return NodeMenuIdList;
    }
}

import { useMemo } from 'react';
import { Announcement } from './Announcement';
import { RTDBPath, useCollectionListener } from '@framework/repository';

/**
 * お知らせの一覧を返すカスタムフックです。
 * お知らせ一覧は日付降順に並んでいます。
 */
export const useAnnouncements = (): Announcement[] => {
    const announcements = useCollectionListener(Announcement, RTDBPath.Notification.announcementsPath());
    return useMemo(() => (announcements || []).sort(Announcement.compare), [announcements]);
};

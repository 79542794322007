import { StickyZoneFontSize } from './StickyZoneFontSize';
import { isThemeColor, ThemeColor } from '@view-model/models/common/color';
import { StickyZoneJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/sticky-zones/{stickyZoneKey}/StickyZoneJSON';

type StickyZoneStyles = {
    fillColor: string;
    borderColor: string;
    hoverFillColor: string;
    hoverBorderColor: string;
    containedElementBorderColor: string; // ゾーンに含まれている要素の周囲の色
};

const styleDefinitions: Record<ThemeColor, StickyZoneStyles> = {
    [ThemeColor.White]: {
        fillColor: '#FFFFFF',
        borderColor: '#E0E0E0',
        hoverFillColor: '#F5F5F5',
        hoverBorderColor: '#E0E0E0',
        containedElementBorderColor: '#F5F5F5',
    },
    [ThemeColor.Red]: {
        fillColor: '#FFCDD2',
        borderColor: '#E57373',
        hoverFillColor: '#EF9A9A',
        hoverBorderColor: '#E57373',
        containedElementBorderColor: '#EF9A9A',
    },
    [ThemeColor.Green]: {
        fillColor: '#E8F5E9',
        borderColor: '#A5D6A7',
        hoverFillColor: '#C8E6C9',
        hoverBorderColor: '#A5D6A7',
        containedElementBorderColor: '#C8E6C9',
    },
    [ThemeColor.Blue]: {
        fillColor: '#BBDEFB',
        borderColor: '#64B5F6',
        hoverFillColor: '#90CAF9',
        hoverBorderColor: '#64B5F6',
        containedElementBorderColor: '#90CAF9',
    },
    [ThemeColor.Orange]: {
        fillColor: '#FFE0B2',
        borderColor: '#FFB74D',
        hoverFillColor: '#FFCC80',
        hoverBorderColor: '#FFB74D',
        containedElementBorderColor: '#FFCC80',
    },
    [ThemeColor.Yellow]: {
        fillColor: '#FFF9C4',
        borderColor: '#FFF176',
        hoverFillColor: '#FFF59D',
        hoverBorderColor: '#FFF176',
        containedElementBorderColor: '#FFF59D',
    },
    [ThemeColor.YellowGreen]: {
        fillColor: '#F0F4C3',
        borderColor: '#DCE775',
        hoverFillColor: '#E6EE9C',
        hoverBorderColor: '#DCE775',
        containedElementBorderColor: '#E6EE9C',
    },
    [ThemeColor.Purple]: {
        fillColor: '#F3E5F5',
        borderColor: '#CE93D8',
        hoverFillColor: '#E1BEE7',
        hoverBorderColor: '#CE93D8',
        containedElementBorderColor: '#E1BEE7',
    },
};

type StickyZoneStyleAttributes = {
    fontSize: StickyZoneFontSize;
    themeColor: ThemeColor;
};

export class StickyZoneStyle {
    readonly fontSize: StickyZoneFontSize;
    readonly themeColor: ThemeColor;

    constructor(attributes: StickyZoneStyleAttributes) {
        this.fontSize = attributes.fontSize;
        this.themeColor = attributes.themeColor;
    }

    static buildNew(themeColor?: ThemeColor): StickyZoneStyle {
        return new this({
            fontSize: StickyZoneFontSize.L,
            themeColor: themeColor || ThemeColor.White,
        });
    }

    static load(dump: StickyZoneJSON['style']): StickyZoneStyle {
        return new this({
            fontSize: StickyZoneFontSize.load(dump.fontSize),
            themeColor: isThemeColor(dump.themeColor) ? dump.themeColor : ThemeColor.White,
        });
    }

    dump(): StickyZoneJSON['style'] {
        return {
            fontSize: StickyZoneFontSize.dump(this.fontSize),
            themeColor: this.themeColor,
        };
    }

    isEqual(other: StickyZoneStyle): boolean {
        return (
            other instanceof StickyZoneStyle && this.fontSize === other.fontSize && this.themeColor === other.themeColor
        );
    }

    private _cloneWith(attributes: Partial<StickyZoneStyleAttributes>): StickyZoneStyle {
        return new StickyZoneStyle({
            fontSize: attributes.fontSize || this.fontSize,
            themeColor: attributes.themeColor || this.themeColor,
        });
    }

    getStyles(): StickyZoneStyles {
        return styleDefinitions[this.themeColor];
    }

    withThemeColor(themeColor: ThemeColor): StickyZoneStyle {
        return this._cloneWith({ themeColor });
    }

    withFontSize(fontSize: StickyZoneFontSize): StickyZoneStyle {
        return this._cloneWith({ fontSize });
    }

    static getStyles(themeColor: ThemeColor): StickyZoneStyles {
        return styleDefinitions[themeColor];
    }
}

import { useCallback, useEffect, useState } from 'react';
import { PositionDelta, Size } from '@view-model/models/common/types/ui';
import { ResizerLineElement } from './ResizerLineElement';
import { ResizerCircleElement } from './ResizerCircleElement';

type ResizerElementNames = 'left' | 'top' | 'right' | 'bottom' | 'rightTop' | 'rightBottom' | 'leftTop' | 'leftBottom';
type ResizerElements = Record<ResizerElementNames, boolean>;
const DefaultResizerElements: ResizerElements = {
    left: true,
    top: true,
    right: true,
    bottom: true,
    rightTop: true,
    rightBottom: true,
    leftTop: true,
    leftBottom: true,
};

type Props = {
    size: Size;
    minSize?: Size;
    onResizeStart?: () => void;
    onResize?(positionDelta: PositionDelta, size: Size): void;
    onResizeEnd(position: PositionDelta, size: Size): void;
    resizerElements?: Partial<ResizerElements>;
    lineStrokeWidth?: number;
    gripLineStrokeWidth?: number;
    circleRadius?: number;
};

/**
 * 矩形のリサイズを行うためのUIを提供する
 *
 *  * 4辺を掴んでのリサイズ
 *  * 4隅の角を掴んでのリサイズ
 * を提供する。
 *
 * 矩形は左上を原点にしてサイズ(幅・高さ)を取る。
 * 右辺、下辺、右下隅を掴んで移動する場合には、原点は移動せずにサイズのみが変化する。
 * それ以外の箇所を掴んで移動する場合には、原点とサイズが連動して変化する。
 * callback では原点の移動量と、変化後のサイズを返す。
 *
 * @param size {Size} 現在のサイズ
 * @param minSize {Size} 変更可能な最小サイズ (省略可能)
 * @param onResizeStart リサイズ開始時に呼ばれるcallback.
 * @param onResize リサイズ途中に呼ばれるcallback. 左上原点の移動量と、変更後のサイズを返す
 * @param onResizeEnd リサイズ完了時に呼ばれるcallback. 左上原点の移動量と、変更後のサイズを返す
 * @param resizerElements 表示するリサイズ要素を指定する。省略時には全てのリサイズ要素を表示する。
 * @param lineStrokeWidth 4辺の線の太さ
 * @param gripLineStrokeWidth 4辺のドラッグ可能領域の太さ
 * @param circleRadius 4角の円の大きさ
 * @constructor
 */
export const RectResizer: React.FC<Props> = ({
    size,
    minSize,
    onResizeStart,
    onResize,
    onResizeEnd,
    resizerElements = DefaultResizerElements,
    lineStrokeWidth = 6,
    gripLineStrokeWidth = 24,
    circleRadius = 12,
}: Props) => {
    const [resizing, setResizing] = useState<boolean>(false);
    const [positionDelta, setPositionDelta] = useState<PositionDelta>({ dx: 0, dy: 0 });
    const [currentSize, setCurrentSize] = useState<Size>(size);

    useEffect(() => {
        if (!resizing) {
            setCurrentSize(size);
        }
    }, [size, resizing]);

    const handleResizeStart = useCallback(() => {
        setResizing(true);
        onResizeStart?.();
    }, [onResizeStart]);

    const handleResize = useCallback(
        (dx: number, dy: number, dWidth: number, dHeight: number) => {
            // 下限サイズを下回らないように調整する
            if (minSize) {
                if (currentSize.width + dWidth - dx < minSize.width) {
                    dx = 0;
                    dWidth = 0;
                }
                if (currentSize.height + dHeight - dy < minSize.height) {
                    dy = 0;
                    dHeight = 0;
                }
            }

            const newPositionDelta = {
                dx: positionDelta.dx + dx,
                dy: positionDelta.dy + dy,
            };
            const newSize = {
                width: currentSize.width + dWidth,
                height: currentSize.height + dHeight,
            };

            setPositionDelta(newPositionDelta);
            setCurrentSize(newSize);
            onResize?.(newPositionDelta, newSize);
        },
        [currentSize.height, currentSize.width, minSize, onResize, positionDelta.dx, positionDelta.dy]
    );

    const handleResizeEnd = useCallback(() => {
        onResizeEnd(positionDelta, currentSize);

        setResizing(false);
        setPositionDelta({ dx: 0, dy: 0 });
        setCurrentSize(size);
    }, [currentSize, onResizeEnd, positionDelta, size]);

    const resizerLineElementAttributes = {
        lineStrokeWidth,
        draggerStrokeWidth: gripLineStrokeWidth,
        onResize: handleResize,
        onResizeStart: handleResizeStart,
        onResizeEnd: handleResizeEnd,
    };
    const resizerCircleElementAttributes = {
        circleRadius,
        onResize: handleResize,
        onResizeStart: handleResizeStart,
        onResizeEnd: handleResizeEnd,
    };

    const { dx, dy } = positionDelta;
    const { width, height } = currentSize;

    return (
        <>
            {resizing && <rect width={width} height={height} x={dx} y={dy} fill="#eee" fillOpacity="0.7" />}

            {resizerElements.right && (
                <ResizerLineElement
                    cursor={'ew-resize'}
                    positions={[
                        { x: dx + width, y: dy },
                        { x: dx + width, y: dy + height },
                    ]}
                    handlePosition={'right'}
                    {...resizerLineElementAttributes}
                />
            )}

            {resizerElements.left && (
                <ResizerLineElement
                    cursor={'ew-resize'}
                    positions={[
                        { x: dx, y: dy },
                        { x: dx, y: dy + height },
                    ]}
                    handlePosition={'left'}
                    {...resizerLineElementAttributes}
                />
            )}

            {resizerElements.top && (
                <ResizerLineElement
                    cursor={'ns-resize'}
                    positions={[
                        { x: dx, y: dy },
                        { x: dx + width, y: dy },
                    ]}
                    handlePosition={'top'}
                    {...resizerLineElementAttributes}
                />
            )}

            {resizerElements.bottom && (
                <ResizerLineElement
                    cursor={'ns-resize'}
                    positions={[
                        { x: dx, y: dy + height },
                        { x: dx + width, y: dy + height },
                    ]}
                    handlePosition={'bottom'}
                    {...resizerLineElementAttributes}
                />
            )}

            {resizerElements.rightTop && (
                <ResizerCircleElement
                    cursor={'nesw-resize'}
                    position={{ x: dx + width, y: dy }}
                    handlePosition={'right-top'}
                    {...resizerCircleElementAttributes}
                />
            )}

            {resizerElements.rightBottom && (
                <ResizerCircleElement
                    cursor={'nwse-resize'}
                    position={{ x: dx + width, y: dy + height }}
                    handlePosition={'right-bottom'}
                    {...resizerCircleElementAttributes}
                />
            )}

            {resizerElements.leftTop && (
                <ResizerCircleElement
                    cursor={'nwse-resize'}
                    position={{ x: dx, y: dy }}
                    handlePosition={'left-top'}
                    {...resizerCircleElementAttributes}
                />
            )}

            {resizerElements.leftBottom && (
                <ResizerCircleElement
                    cursor={'nesw-resize'}
                    position={{ x: dx, y: dy + height }}
                    handlePosition={'left-bottom'}
                    {...resizerCircleElementAttributes}
                />
            )}
        </>
    );
};

import { Placement } from '@popperjs/core';
import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { FlipProp, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { classNames } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    icon: IconDefinition;
    tooltip: string;
    placement: Placement;
    disabled?: boolean;
    onClick(): void;
    flip?: FlipProp;
    className?: string;
};

export const IconButton: React.FC<Props> = ({
    icon,
    tooltip,
    placement,
    disabled,
    onClick,
    flip,
    className,
}: Props) => {
    return (
        <DarkTooltip tooltip={tooltip} placement={placement}>
            <button
                onClick={onClick}
                disabled={disabled}
                className={classNames(
                    'h-10 w-10 cursor-pointer rounded-lg text-gray-700 hover:bg-gray-200 focus:outline-none',
                    className
                )}
                aria-label={tooltip}
            >
                <FontAwesomeIcon icon={icon} flip={flip} />
            </button>
        </DarkTooltip>
    );
};

import { Point } from '@view-model/models/common/basic/Point';
import { DescriptionTargetKey, ModelElementId, NodeKey, StickyZoneKey } from '@view-model/domain/key';
import { ElementDescriptionLayout } from '../components';
import { ElementId, ModelId, ViewModelId } from '@schema-common/base';
import { ElementDescriptionJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/element-descriptions/{elementId}/ElementDescriptionJSON';
import { CreateCommand } from '@model-framework/command';
import { DBPath, ObjectRepository, RTDBPath } from '@framework/repository';
import { ElementDescriptionTarget } from './ElementDescriptionTarget';

export class ElementDescription {
    private constructor(
        public readonly elementId: ElementId,
        public readonly content: string,
        public readonly relativePosition: Point,
        private readonly visible: boolean
    ) {}

    static build(elementId: ElementId, target: ElementDescriptionTarget, visible = true): ElementDescription {
        return new ElementDescription(elementId, '', ElementDescriptionLayout.initialRelativePosition(target), visible);
    }

    isVisible(): boolean {
        return this.visible;
    }

    dump(): ElementDescriptionJSON {
        const { elementId, content, relativePosition, visible } = this;
        return {
            elementId,
            content,
            relativePosition: relativePosition.dump(),
            visible,
        };
    }

    static load(dump: ElementDescriptionJSON): ElementDescription {
        const { elementId, content, relativePosition, visible } = dump;

        return new ElementDescription(elementId, content, Point.fromPosition(relativePosition), visible);
    }

    withRelativePosition(newRelativePosition: Point): ElementDescription {
        const { elementId, content, visible } = this;
        return new ElementDescription(elementId, content, newRelativePosition, visible);
    }

    withContent(newContent: string): ElementDescription {
        const { elementId, visible, relativePosition } = this;
        return new ElementDescription(elementId, newContent, relativePosition, visible);
    }

    toggleVisibility(): ElementDescription {
        const { elementId, content, relativePosition, visible } = this;
        return new ElementDescription(elementId, content, relativePosition, !visible);
    }

    isEqual(other: ElementDescription): boolean {
        return (
            other.elementId === this.elementId &&
            other.content === this.content &&
            other.visible === this.visible &&
            other.relativePosition.isEqual(this.relativePosition)
        );
    }

    cloneNew(elementKeyMap: Record<string, DescriptionTargetKey>): ElementDescription {
        const { elementId, content, relativePosition, visible } = this;

        const newElementId = this.getNewElementId(elementKeyMap);

        return new ElementDescription(newElementId || elementId, content, relativePosition, visible);
    }

    private getNewElementId(elementKeyMap: Record<string, DescriptionTargetKey>): string | null {
        let newElementId = null;
        for (const [orgElementKeyStr, newElementKey] of Object.entries(elementKeyMap)) {
            const orgElementKey = this.getOrgElementKey(newElementKey);
            if (orgElementKey.toString() === orgElementKeyStr) {
                // コピー元の対象要素のKeyにマッピングされた、新しい対象要素のKeyからIDを取得する
                newElementId = newElementKey.id;
                break;
            }
        }
        return newElementId;
    }

    private getOrgElementKey(key: DescriptionTargetKey): DescriptionTargetKey {
        switch (key.kind) {
            case NodeKey.KIND:
                return NodeKey.buildFromID(this.elementId);
            case StickyZoneKey.KIND:
                return StickyZoneKey.buildFromID(this.elementId);
            default:
                throw new Error(`Not supported key kind: ${key.kind}`);
        }
    }

    /**
     * 与えられた elementId が補足説明の説明対象かどうかを返します。
     * @param elementId
     */
    relatedTo(elementId: ModelElementId): boolean {
        return this.elementId === elementId;
    }

    buildCreateCommand(
        viewModelId: ViewModelId,
        modelId: ModelId,
        target: ElementDescriptionTarget
    ): CreateCommand<ElementDescription> {
        const repo = new ObjectRepository(
            ElementDescription,
            this.getDBPath(viewModelId, modelId, target, this.elementId)
        );

        return new CreateCommand(this, repo);
    }

    private getDBPath(
        viewModelId: ViewModelId,
        modelId: ModelId,
        target: ElementDescriptionTarget,
        elementId: ElementId
    ): DBPath {
        switch (target) {
            case 'Node':
                return RTDBPath.Node.descriptionPath(viewModelId, modelId, elementId);
            case 'Zone':
                return RTDBPath.Zone.descriptionPath(viewModelId, modelId, elementId);
            default:
                throw new Error(`Invalid target: ${target}`);
        }
    }
}

import { useEffect } from 'react';
import { TopNavigation } from '@framework/ui/elements/TopNavigation';
import { Redirect } from 'react-router-dom';
import { useCurrentUserId } from '@framework/auth';
import { GroupSelection } from '@user/pages/HomePage/GroupSelection';
import { usePageViewLogger } from '@framework/action-log';
import { useAssignedGroups } from '@group/hooks';
import { FullScreenLoading } from '@framework/ui';
import { UserId } from '@schema-common/base';

type Props = {
    currentUserId: UserId;
};

const HomePageContent: React.FC<Props> = ({ currentUserId }: Props) => {
    const groups = useAssignedGroups(currentUserId);

    if (!groups) return <FullScreenLoading />;

    return (
        <div>
            <TopNavigation />
            <GroupSelection groups={groups} />
        </div>
    );
};

export const HomePage: React.FunctionComponent<unknown> = () => {
    const currentUserId = useCurrentUserId();
    const pageViewLogger = usePageViewLogger('HomePage');

    useEffect(() => {
        if (!currentUserId) return;

        pageViewLogger.landing();
        return () => pageViewLogger.leaving();
    }, [currentUserId, pageViewLogger]);

    return <>{currentUserId ? <HomePageContent currentUserId={currentUserId} /> : <Redirect to={'/login'} />}</>;
};

import { ILayout } from '@view-model/ui/layouts';
import { SizeJSON } from '@schema-common/view-model';

export class Size {
    static readonly empty = new Size(0, 0);

    public readonly width: number;
    public readonly height: number;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    isEqual(other: Size): boolean {
        return this.width === other.width && this.height === other.height;
    }

    calcRatio(other: Size): number {
        return Math.min(this.width / other.width, this.height / other.height);
    }

    dump(): SizeJSON {
        return {
            width: this.width,
            height: this.height,
        };
    }

    static load(dump: SizeJSON): Size {
        return new Size(dump.width, dump.height);
    }

    addWidth(dWidth: number): Size {
        return new Size(this.width + dWidth, this.height);
    }

    addHeight(dHeight: number): Size {
        return new Size(this.width, this.height + dHeight);
    }

    snapToLayout(layout: ILayout): Size {
        return new Size(layout.snapX(this.width), layout.snapY(this.height));
    }

    isEmpty(): boolean {
        return this.width === 0 && this.height === 0;
    }
}

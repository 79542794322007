import { useCallback } from 'react';
import { ViewModelNotification } from './ViewModelNotification';
import { HuddleMessage } from './message/HuddleMessage';
import { toast } from 'react-hot-toast';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';
import { ViewModelId } from '@schema-common/base';

type Handler = () => void;

export const useNotifyHuddleMessageHandler = (viewModelId: ViewModelId, viewId: string): Handler => {
    const profile = useCurrentUserPublicProfile();

    return useCallback(() => {
        if (!profile) return;

        const notification = new ViewModelNotification(viewModelId);
        const channel = notification.huddleChannel();

        channel.notify(HuddleMessage.build(viewId, profile));

        toast('参加者を集めました | Gathered');
    }, [profile, viewId, viewModelId]);
};

import { RefObject, useEffect } from 'react';
import { select } from 'd3-selection';

/**
 * d3 で mouseupのコールバックを利用するためのフック
 * @param ref mouseupイベントを受け取りたいHTML/SVG要素のref
 * @param onMouseUp イベントハンドラ
 * @param stopPropagation イベント伝搬を停止するか否かを決めるbool値
 */
export const useD3MouseUpCallback = <T extends HTMLElement | SVGElement>(
    ref: RefObject<T>,
    onMouseUp: () => void,
    stopPropagation = false
): void => {
    useEffect(() => {
        const dom = ref.current;
        select(dom).on('mouseup', (event: MouseEvent) => {
            if (stopPropagation) {
                event.stopPropagation();
            }
            onMouseUp();
        });

        return () => {
            select(dom).on('mouseup', null);
        };
    }, [ref, onMouseUp, stopPropagation]);
};

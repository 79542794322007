import {
    NodeReactionJSON,
    IconReactionsJSON,
} from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/node-reactions/{nodeId}/NodeReactionJSON';
import { UserId } from '@schema-common/base';

export type ReactionType = keyof NodeReactionJSON;

export const reactionIcons: Record<ReactionType, string> = {
    thumbsup: '👍',
    tada: '🎉',
    heart: '❤️',
    eyes: '👀',
    bowing: '🙇',
};

export const reactionTypeList = ['thumbsup', 'tada', 'heart', 'eyes', 'bowing'] as ReactionType[];

export class NodeReaction {
    private readonly thumbsup: IconReactionsJSON;
    private readonly tada: IconReactionsJSON;
    private readonly heart: IconReactionsJSON;
    private readonly eyes: IconReactionsJSON;
    private readonly bowing: IconReactionsJSON;

    public constructor(attributes?: Partial<NodeReactionJSON> | null) {
        this.thumbsup = attributes?.thumbsup || {};
        this.tada = attributes?.tada || {};
        this.heart = attributes?.heart || {};
        this.eyes = attributes?.eyes || {};
        this.bowing = attributes?.bowing || {};
    }

    public static load(json: NodeReactionJSON): NodeReaction {
        return new NodeReaction(json);
    }

    public dump(): NodeReactionJSON {
        return {
            thumbsup: this.thumbsup || {},
            tada: this.tada || {},
            heart: this.heart || {},
            eyes: this.eyes || {},
            bowing: this.bowing || {},
        };
    }

    public getExistKeys(): ReactionType[] {
        return reactionTypeList.filter((key) => Object.keys(this[key]).length);
    }

    // そのユーザーがすでにreactionを付与しているかどうか
    public isReactionExecuted(reactionType: ReactionType, userId: UserId): boolean {
        return !!this[reactionType][userId];
    }

    public countOf(reactionType: ReactionType): number {
        return Object.keys(this[reactionType]).length;
    }

    public hasMaxReached(reactionType: ReactionType): boolean {
        return this.countOf(reactionType) >= 50;
    }

    public getUserNames(reactionType: ReactionType): string[] {
        // Reactionした順番に名前が並ぶようにソート
        const reaction = Object.values(this[reactionType]).sort((a, b) => a.timestamp - b.timestamp);
        return reaction.map((detail) => detail.name);
    }
}

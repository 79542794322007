import { GroupMemberInvitation } from '@group/domain';
import { GroupMemberSignUpMethod } from '@group/domain/GroupEntity';
import { AuthenticatedMenu } from './AuthenticatedMenu';
import { UnauthenticatedMenu } from './UnauthenticatedMenu';
import { InvitationMenuLayout } from '@user/pages/GroupMemberInvitationPage/InvitationMenuLayout';
import { useCurrentUserId } from '@framework/auth';

type Props = {
    invitation: GroupMemberInvitation;
    availableSignUpMethods: GroupMemberSignUpMethod;
};

/**
 * グループメンバーへの招待情報を表示し、グループへの参加や、(必要ならば)ユーザ登録・ログインを行う。
 */
export const InvitationMenu: React.FC<Props> = ({ invitation, availableSignUpMethods }: Props) => {
    const currentUserId = useCurrentUserId();

    return (
        <InvitationMenuLayout invitation={invitation}>
            {currentUserId ? (
                <AuthenticatedMenu currentUserId={currentUserId} invitation={invitation} />
            ) : (
                <UnauthenticatedMenu invitation={invitation} availableSignUpMethods={availableSignUpMethods} />
            )}
        </InvitationMenuLayout>
    );
};

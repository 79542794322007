export class WorkspaceName {
    public static readonly MAX_NAME_LENGTH = 200;
    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public toString(): string {
        return `${this.value}`;
    }

    localeCompare(other: WorkspaceName): number {
        return this.value.toLowerCase().localeCompare(other.value.toLowerCase());
    }

    public isEmpty(): boolean {
        return this.value === '';
    }

    public isMaxLengthExcess(): boolean {
        return this.value.length > WorkspaceName.MAX_NAME_LENGTH;
    }

    public isInvalid(): boolean {
        return this.isEmpty() || this.isMaxLengthExcess();
    }
    public isValid(): boolean {
        return !this.isInvalid();
    }
    public includesMulti(keywords: string[]): boolean {
        return keywords.some((keyword) => {
            if (!keyword) return false;
            return this.value.toLowerCase().includes(keyword.toLowerCase());
        });
    }
}

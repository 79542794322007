import ReactResizeObserver from 'rc-resize-observer';
import { Size } from '@view-model/models/common/basic/Size';

type Props = {
    onSizeChanged(size: Size): void;
    children: React.ReactNode;
};

export const SizeTracker: React.FC<Props> = ({ onSizeChanged, children }: Props) => {
    // パディングやボーダーの幅を含む、offsetWidth, offsetHeight を利用する
    // https://developer.mozilla.org/ja/docs/Web/API/HTMLElement/offsetWidth
    const handleResize = ({ offsetWidth, offsetHeight }: { offsetWidth: number; offsetHeight: number }) => {
        requestAnimationFrame(() => {
            onSizeChanged(new Size(offsetWidth, offsetHeight));
        });
    };

    return (
        <ReactResizeObserver onResize={handleResize}>
            <div className="inline-block whitespace-nowrap">{children}</div>
        </ReactResizeObserver>
    );
};

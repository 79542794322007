import { useLayoutEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { createPortal } from 'react-dom';

type TooltipProps = {
    children: React.ReactNode;
    tooltip: string;
    placement: Placement;
    className?: string;
};

export const DarkTooltip: React.FC<TooltipProps> = ({ children, tooltip, placement, className }: TooltipProps) => {
    const referenceRef = useRef<HTMLDivElement | null>(null);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const [isMouseOn, setIsMouseOn] = useState<boolean>(false);
    const { styles, attributes, update } = usePopper(referenceRef.current, tooltipRef.current, {
        placement: placement,
        modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    });

    useLayoutEffect(() => {
        if (isMouseOn) {
            update?.();
        }
    }, [update, isMouseOn]);

    return (
        <>
            <div
                className={className}
                ref={referenceRef}
                onMouseEnter={() => {
                    setIsMouseOn(true);
                }}
                onMouseLeave={() => {
                    setIsMouseOn(false);
                }}
            >
                {children}
            </div>
            {createPortal(
                <div ref={tooltipRef} style={styles.popper} {...attributes.popper}>
                    {isMouseOn && (
                        <div className="flex items-center justify-center rounded-lg border border-gray-400 bg-gray-700 px-3 py-2 text-sm text-white shadow-lg">
                            {tooltip}
                        </div>
                    )}
                </div>,
                document.body
            )}
        </>
    );
};

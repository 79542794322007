import { createContext } from 'react';

export type SidebarContextValues = {
    sidebarWidth: number;
    isSidebarVisible: boolean;
    isSidebarOpenButtonVisible: boolean;
    toggleSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContextValues | null>(null);

import { isNumber, isFinite, isUndefined } from 'lodash';

type ParameterRecordLoadableType = Record<string, string | number | null | undefined>;
type ParameterRecordType = Record<string, string | number | null>;

type ActionLogParametersJSON = ParameterRecordType;

export class ActionLogParameters {
    private readonly records: Readonly<ParameterRecordType>;
    constructor(records: ParameterRecordType) {
        this.records = records;
    }

    /**
     * Record形式のオブジェクトを受け取って、 ActionLogParameters を生成する。
     * そのとき、値が RTDB で扱えないもの (undefined, Infinity, -Infinity, NaN) については別の値に置き換える。
     * @param records
     */
    static buildFrom(records: ParameterRecordLoadableType): ActionLogParameters {
        const entries = Object.entries(records).map(([key, value]) => {
            if (isUndefined(value)) {
                return [key, null];
            }

            // 数値だが、有限数ではない場合 (=Infinity, -Infinity, NaN）
            if (isNumber(value) && !isFinite(value)) {
                return [key, `${value}`];
            }

            return [key, value];
        });

        return new ActionLogParameters(Object.fromEntries(entries));
    }

    dump(): ActionLogParametersJSON {
        return { ...this.records };
    }
}

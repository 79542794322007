import { GetFunctionsClient } from '@framework/firebase';

export namespace FunctionsUserActions {
    type Request =
        | LoginWithPasswordRequest
        | OneTimePasswordVerificationRequest
        | SignUpRequest
        | SignUpPasswordAuthRequest
        | GetAuthorizationUrlRequest
        | AuthCallbackRequest
        | FetchSignUpUserDataRequest
        | CheckIpRequest
        | ReAuthenticateRequest
        | SignOutRequest
        | UserUpdateRequest
        | ConnectJijiRequest
        | DisconnectJijiRequest
        | DeleteUserRequest
        | ChangeMailAddressRequest
        | AnonymousLoginRequest
        | AnonymousUserNameChangeRequest
        | CreatePasswordRequest
        | ChangePasswordRequest
        | SendPasswordResetTokenRequest
        | FetchPasswordResetTokenRequest
        | ResetPasswordRequest;
    const call = async (data: Request) => GetFunctionsClient().httpsCallable('user-actions')(data);

    // -----------------------------------------------------------------------------------
    type LoginWithPasswordRequest = {
        action: 'loginWithPassword';
        params: {
            mailAddress: string;
            password: string;
        };
    };

    /**
     * メールアドレスとパスワードを利用したログイン認証を行います。
     */
    export const loginWithPassword = async (
        mailAddress: string,
        password: string,
        onSuccess: (token: string) => void,
        onMultiFactorAuth: (temporaryId: string) => void,
        onFailed: () => void
    ): Promise<void> => {
        const response = await call({
            action: 'loginWithPassword',
            params: { mailAddress, password },
        });
        const data = response.data;
        if (data.ok) {
            if ('token' in data) {
                onSuccess(data.token);
                return;
            }

            if ('temporaryId' in data) {
                onMultiFactorAuth(data.temporaryId);
                return;
            }
        } else {
            onFailed();
        }
    };

    // -----------------------------------------------------------------------------------
    type OneTimePasswordVerificationRequest = {
        action: 'oneTimePasswordVerification';
        params: { oneTimePassword: string; temporaryId: string };
    };

    /**
     * ワンタイムパスワードを利用した多要素認証を行います。
     */
    export const oneTimePasswordVerification = async (
        oneTimePassword: string,
        temporaryId: string
    ): Promise<string | null> => {
        const response = await call({
            action: 'oneTimePasswordVerification',
            params: { oneTimePassword, temporaryId },
        });
        const data = response.data;
        return data.ok && 'token' in data ? data.token : null;
    };

    // -----------------------------------------------------------------------------------
    type SignUpRequest = {
        action: 'userSignUp';
        params: {
            signUpToken: string;
            name: string;
        };
    };

    /**
     * 外部認証プロバイダ(jiji)経由で新規ユーザ登録を行います。
     * 成功した場合にはカスタムログイントークンを返します。
     */
    export const signUp = async (signUpToken: string, name: string): Promise<string | null> => {
        const response = await call({
            action: 'userSignUp',
            params: { signUpToken, name },
        });
        const data = response.data;
        return data && 'token' in data ? data.token : null;
    };

    // -----------------------------------------------------------------------------------
    type SignUpPasswordAuthRequest = {
        action: 'signUpPasswordAuth';
        params: {
            name: string;
            mailAddress: string;
            password: string;
        };
    };

    export const signUpPasswordAuth = async (name: string, mailAddress: string, password: string): Promise<string> => {
        const response = await call({
            action: 'signUpPasswordAuth',
            params: { name, mailAddress, password },
        });
        return response.data.token;
    };

    // -----------------------------------------------------------------------------------
    type GetAuthorizationUrlRequest = {
        action: 'getAuthorizationUrl';
        params: {
            redirectUrl: string;
            autoRedirect: 'google' | 'quadcept';
        };
    };

    /**
     * Jiji認証ログインのための Authorization URL を取得します。
     */
    export const getAuthorizationUrl = async (
        redirectUrl: string,
        autoRedirect: 'google' | 'quadcept'
    ): Promise<string> => {
        const response = await call({
            action: 'getAuthorizationUrl',
            params: { redirectUrl, autoRedirect },
        });
        return response.data.authorizationUrl;
    };

    // -----------------------------------------------------------------------------------
    type AuthCallbackRequest = {
        action: 'authCallback';
        params: {
            redirectUrl: string;
            code: string;
            allowSignUp: boolean;
        };
    };

    type AuthCallbackResponse =
        | { type: 'signIn'; token: string }
        | { type: 'signUp'; token: string }
        | { type: 'notFound'; message: string }
        | { type: 'alreadyExists'; mailAddress: string };

    /**
     * OAuth認証のコールバックを処理します。
     */
    export const authCallback = async (
        redirectUrl: string,
        code: string,
        allowSignUp: boolean,
        onSignIn: (token: string) => void,
        onSignUp: (token: string) => void,
        onNotFound: () => void,
        onAlreadyExists: (mailAddress: string) => void,
        onError: (message: string) => void
    ): Promise<void> => {
        const response = await call({
            action: 'authCallback',
            params: { redirectUrl, code, allowSignUp },
        });
        const data = response.data as AuthCallbackResponse;

        if (data.type === 'signIn') {
            onSignIn(data.token);
            return;
        }

        if (data.type === 'signUp') {
            onSignUp(data.token);
            return;
        }

        if (data.type === 'notFound') {
            onNotFound();
            return;
        }

        if (data.type === 'alreadyExists') {
            onAlreadyExists(data.mailAddress);
            return;
        }

        onError('ログイン処理に失敗しました');
    };

    // -----------------------------------------------------------------------------------
    type FetchSignUpUserDataRequest = {
        action: 'fetchSignUpUserData';
        params: {
            signUpToken: string;
        };
    };

    type SignUpUserData = {
        id: string;
        name: string;
        mailAddress: string;
    };

    /**
     * サインアップトークンを利用して、サインアップ時のユーザ情報を取得します。
     */
    export const fetchSignUpUserData = async (signUpToken: string): Promise<SignUpUserData> => {
        const response = await call({
            action: 'fetchSignUpUserData',
            params: { signUpToken },
        });
        return response.data;
    };

    // -----------------------------------------------------------------------------------
    type CheckIpRequest = {
        action: 'checkIp';
    };

    /**
     * 自分自身のネットワーク状態が変化したときに、接続元IPアドレス情報の更新を要求します。
     */
    export const checkIp = async (): Promise<void> => {
        await call({ action: 'checkIp' });
    };

    // -----------------------------------------------------------------------------------
    type ReAuthenticateRequest = {
        action: 'reAuthenticate';
    };

    /**
     * 永続化される前に開始された認証情報を利用して、新形式の永続化された認証セッションを作成し、ログインするためのトークンを取得します。
     * 未ログイン状態の場合や、既に新形式の認証セッションの場合には null を返します。
     */
    export const reAuthenticate = async (): Promise<string | null> => {
        const response = await call({ action: 'reAuthenticate' });
        return response.data;
    };

    // -----------------------------------------------------------------------------------
    type SignOutRequest = {
        action: 'userSignOut';
    };

    /**
     * 認証セッションを無効化します。
     * Firebase Authentication のサインアウト処理の前にこのメソッドを呼び出す必要があります。
     */
    export const signOut = async (): Promise<void> => {
        await call({ action: 'userSignOut' });
    };

    // -----------------------------------------------------------------------------------
    type UserUpdateRequest = {
        action: 'editUserProfile';
        params: {
            name: string;
            imageUrl: string | null;
        };
    };

    /**
     * ログイン中ユーザのプロフィール情報を編集します。
     */
    export const editUserProfile = async (name: string, imageUrl: string | null): Promise<boolean> => {
        try {
            await call({
                action: 'editUserProfile',
                params: { name, imageUrl },
            });
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    // -----------------------------------------------------------------------------------
    type ConnectJijiRequest = {
        action: 'connectJiji';
        params: {
            redirectUrl: string;
            code: string;
        };
    };

    type ConnectJijiResponseStatus =
        | 'success'
        | 'already-connected'
        | 'already-connected-to-other-user'
        | 'mail-address-mismatch';

    /**
     * ログイン中ユーザとJiji認証連携を設定します。
     */
    export const connectJiji = async (redirectUrl: string, code: string): Promise<[boolean, string]> => {
        const messages: Record<ConnectJijiResponseStatus, string> = {
            success:
                '外部認証プロバイダのアカウントとの連携に成功しました。次回からログインする際に、外部認証プロバイダを利用することができます。',
            'already-connected':
                '既に外部認証プロバイダのアカウントと連携しているため、新たに連携設定を行うことはできません。',
            'already-connected-to-other-user':
                '指定された外部認証プロバイダのアカウントは、既に他のユーザと関連づけられているため、連携に失敗しました。',
            'mail-address-mismatch':
                '指定された外部認証プロバイダのアカウントのメールアドレスが一致しませんでした。先にメールアドレスの変更を行なってください。',
        };

        try {
            const response = await call({
                action: 'connectJiji',
                params: { redirectUrl, code },
            });
            const data = response.data as ConnectJijiResponseStatus;
            return [data === 'success', messages[data]];
        } catch (e) {
            console.error(e);
            return [false, '外部認証プロバイダとの連携に失敗しました。時間をあけてもう一度試してみてください。'];
        }
    };

    // -----------------------------------------------------------------------------------
    type DisconnectJijiRequest = {
        action: 'disconnectJiji';
    };

    /**
     * ログイン中ユーザのJiji認証連携を解除します。
     */
    export const disconnectJiji = async (): Promise<boolean> => {
        try {
            await call({ action: 'disconnectJiji' });
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    // -----------------------------------------------------------------------------------
    type DeleteUserRequest = {
        action: 'deleteUser';
        userId: string;
    };

    /**
     * ユーザを削除します。
     */
    export const deleteUser = async (
        userId: string
    ): Promise<'success' | 'still-belonging-to-groups' | 'failed-to-delete'> => {
        const response = await call({
            action: 'deleteUser',
            userId,
        });
        return response.data.result;
    };

    // -----------------------------------------------------------------------------------
    type ChangeMailAddressRequest = {
        action: 'changeMailAddress';
        params: {
            userId: string;
            newMailAddress: string;
        };
    };

    /**
     * ユーザのメールアドレスを変更します。
     */
    export const changeMailAddress = async (userId: string, newMailAddress: string): Promise<boolean> => {
        try {
            await call({
                action: 'changeMailAddress',
                params: { userId, newMailAddress },
            });
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    // -----------------------------------------------------------------------------------
    type AnonymousLoginRequest = {
        action: 'anonymousUserSignIn';
        params: {
            name: string;
        };
    };

    /**
     * 匿名ユーザとしてログインします。ログイン用のカスタムトークンを返します。
     */
    export const anonymousLogin = async (name: string): Promise<string> => {
        try {
            const response = await call({
                action: 'anonymousUserSignIn',
                params: { name },
            });
            const token = response.data?.token;
            if (!token) {
                throw new Error('ログイントークンの取得に失敗しました');
            }
            return token;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    // -----------------------------------------------------------------------------------
    type AnonymousUserNameChangeRequest = {
        action: 'anonymousUserChangeName';
        params: {
            name: string;
        };
    };

    /**
     * 匿名ユーザの名前を変更します。
     */
    export const anonymousUserChangeName = async (name: string): Promise<void> => {
        await call({
            action: 'anonymousUserChangeName',
            params: { name },
        });
    };

    // -----------------------------------------------------------------------------------
    type CreatePasswordRequest = {
        action: 'createPassword';
        params: {
            password: string;
        };
    };

    /**
     * ログイン中ユーザのパスワードを設定します。
     */
    export const createPassword = async (password: string): Promise<boolean> => {
        try {
            await call({
                action: 'createPassword',
                params: { password },
            });
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    // -----------------------------------------------------------------------------------
    type ChangePasswordRequest = {
        action: 'changePassword';
        params: {
            currentPassword: string;
            newPassword: string;
        };
    };

    type ChangePasswordResponse = {
        result: 'success' | 'incorrect-current-password';
    };

    /**
     * ログイン中ユーザのパスワードを変更します。
     */
    export const changePassword = async (
        currentPassword: string,
        newPassword: string
    ): Promise<'success' | 'incorrect-current-password' | 'failed'> => {
        try {
            const response = await call({
                action: 'changePassword',
                params: { currentPassword, newPassword },
            });

            const { result } = response.data as ChangePasswordResponse;
            return result;
        } catch (e) {
            console.error(e);
            return 'failed';
        }
    };

    // -----------------------------------------------------------------------------------
    type SendPasswordResetTokenRequest = {
        action: 'sendPasswordResetToken';
        params: {
            mailAddress: string;
        };
    };

    /**
     * パスワード再設定用のメールを送信する
     */
    export const sendPasswordResetToken = async (mailAddress: string): Promise<void> => {
        await call({
            action: 'sendPasswordResetToken',
            params: { mailAddress },
        });
    };

    // -----------------------------------------------------------------------------------
    type FetchPasswordResetTokenRequest = {
        action: 'fetchPasswordResetToken';
        params: { resetTokenId: string };
    };

    /**
     * パスワード再設定トークンの状態を取得する
     */
    export const fetchPasswordResetToken = async (resetTokenId: string): Promise<'ok' | 'notFound' | 'invalid'> => {
        const response = await call({
            action: 'fetchPasswordResetToken',
            params: { resetTokenId },
        });
        return response.data.status;
    };

    // -----------------------------------------------------------------------------------
    type ResetPasswordRequest = {
        action: 'resetPasswordAndLogin';
        params: { resetTokenId: string; password: string };
    };

    /**
     * パスワードの再設定とログインを実行
     */
    export const resetPasswordAndLogin = async (resetTokenId: string, password: string): Promise<string> => {
        const response = await call({
            action: 'resetPasswordAndLogin',
            params: { resetTokenId, password },
        });
        return response.data.token;
    };
}

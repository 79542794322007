import { useCallback } from 'react';
import { Folder } from '@workspace/view-model-folder/domain/Folder';
import { useActionLogSender } from '@framework/action-log';
import { RefBuilder, RTDBPath } from '@framework/repository';

type Handler = (name: string) => Promise<void>;

export const useUpdateFolder = (folder: Folder): Handler => {
    const actionLogSender = useActionLogSender();

    return useCallback(
        async (name: string) => {
            await RefBuilder.ref(RTDBPath.Workspace.folderPath(folder.id)).set(folder.withName(name).dump());

            actionLogSender('view_model_folder:edit', {
                folderId: folder.id,
                folderName: folder.name,
            });
        },
        [folder, actionLogSender]
    );
};

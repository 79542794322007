import { UserPublicProfile } from '@user/PublicProfile';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { classNames } from '@framework/utils';

type Props = {
    profiles: UserPublicProfile[];
    numberOfDisplayIcons: number;
    className?: string;
};

export const MemberIconList: React.FC<Props> = ({ profiles, numberOfDisplayIcons, className }: Props) => {
    return (
        <div className={classNames('flex items-end justify-end', className || '')}>
            {profiles.slice(0, numberOfDisplayIcons).map((profile) => (
                <UserIcon key={profile.id} {...profile} size="medium" />
            ))}
            {profiles.length > numberOfDisplayIcons && (
                <span className="pl-1 text-sm">他{profiles.length - numberOfDisplayIcons}人</span>
            )}
        </div>
    );
};

import { atom } from 'jotai/vanilla';
import { ViewCollectionContents } from '@view-model/domain/view/ViewCollectionContents';
import { ViewModelId } from '@schema-common/base';

type AtomValue = {
    viewContentsRecord: Record<ViewModelId, ViewCollectionContents | undefined>;
};

export const viewModelPageContentAtom = atom<AtomValue>({
    viewContentsRecord: {},
});

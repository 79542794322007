import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { BuildInfo } from '@config/build-info';
import { RTDBConsoleURL } from '@admin/firebase';
import { RTDBPath, usePrimitiveValueListener } from '@framework/repository';

type Props = {
    onClose(): void;
};

export const BuildInfoModal: React.FC<Props> = ({ onClose }: Props) => {
    const [requiredVersion] = usePrimitiveValueListener<number | null>(
        RTDBPath.ApplicationConfig.requiredVersionPath()
    );

    return (
        <GeneralModal
            isOpen={true}
            onClose={onClose}
            title={'ビルド情報'}
            submitButton={<></>}
            cancelButton={
                <Button onClick={onClose} color={'secondary'}>
                    閉じる
                </Button>
            }
        >
            <div className="flex flex-col p-4">
                <div className="flex flex-row py-2">
                    <span className="pr-2 font-bold">ビルド日</span>
                    <span>{BuildInfo.DATE}</span>
                </div>
                <div className="flex flex-row py-2">
                    <span className="pr-2 font-bold">ビルド番号</span>
                    <span>{BuildInfo.version.toString()}</span>
                    <span className="flex items-end pl-2 text-sm">
                        <a
                            href={RTDBConsoleURL.build(RTDBPath.ApplicationConfig.requiredVersionPath())}
                            className="text-indigo-500 hover:underline"
                            target="_blank"
                            rel="noreferrer"
                        >
                            要求バージョン: {requiredVersion || '未設定'}
                        </a>
                    </span>
                </div>
                <div className="flex flex-row py-2">
                    <span className="pr-2 font-bold">Git SHA1</span>
                    <span>{BuildInfo.SHA1}</span>
                </div>
            </div>
        </GeneralModal>
    );
};

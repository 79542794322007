import { useEffect, useState } from 'react';
import { createGroupEntityRepository, GroupEntity } from '@group/domain';
import { isValidId } from '@schema-common/base';

/**
 * 指定のグループIDの情報を取得します。 (最初の1回取得のみ。継続的なリスンはしません。)
 * @param groupId
 * @returns
 */
export const useGroupEntityOnce = (id: string): GroupEntity | null => {
    const [group, setGroup] = useState<GroupEntity | null>(null);

    /**
     * 引数に指定された id が RTDB のパス階層で指定できない文字列パターンの場合には、非実在のフォールバック用idを返す。
     * これによって不正なRTDBパスへのアクセスによる権限エラーの発生を抑止する。
     */
    const groupId = isValidId(id) ? id : '(invalid-group-id)';

    useEffect(() => {
        setGroup(null);
        const repo = createGroupEntityRepository(groupId);
        repo.get().then((group) => setGroup(group));
    }, [groupId]);

    return group;
};

import { LinkColor } from './index';

export const linkColorSelector = (
    linkColor: LinkColor,
    isSelected: boolean,
    isOtherUserSelected: boolean,
    isRelatedNodeSelected: boolean
): string => {
    if (isSelected) return '#0097A7'; //brand;
    if (isOtherUserSelected) return '#f6ad55'; //orange-400
    if (isRelatedNodeSelected) return '#A1C9D3'; //brand-half-transparent
    return LinkColor.colorCodeOf(linkColor);
};

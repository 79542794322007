import { RefObject, useEffect, useRef } from 'react';
import { select, pointer } from 'd3-selection';
import { ZoomTransform } from 'd3-zoom';
import { useD3DblClickCallback } from '@view-model/models/common/hooks';

type Props = {
    children: React.ReactNode;
    onMouseMove: (x: number, y: number) => void;
    frameRef: RefObject<SVGGElement>;
    transform: ZoomTransform;
    deselection: () => void;
};

export const FLAME_SIZE = 204800;

export const Frame: React.FC<Props> = ({ frameRef, children, onMouseMove, transform, deselection }: Props) => {
    const frameContainerRef = useRef<SVGGElement>(null);

    useEffect(() => {
        const target = frameContainerRef.current;
        select(target).on('mousemove', (event) => {
            const [x, y] = pointer(event);
            onMouseMove(x, y);
        });

        return () => {
            select(target).on('mousemove', null);
        };
    }, [frameContainerRef, onMouseMove]);

    useD3DblClickCallback(frameContainerRef, () => void 0, true);

    return (
        <g ref={frameRef}>
            <g transform={`translate(${transform.x}, ${transform.y}) scale(${transform.k})`} ref={frameContainerRef}>
                <rect
                    className="cursor-grab fill-neutral-200 active:cursor-grabbing"
                    width={FLAME_SIZE}
                    height={FLAME_SIZE}
                    transform={`translate(${-FLAME_SIZE / 2}, ${-FLAME_SIZE / 2})`}
                    onClick={deselection}
                />
                <g pointerEvents="none" opacity={1}>
                    <defs>
                        <pattern
                            id="small-grid"
                            patternUnits="userSpaceOnUse"
                            viewBox="0 0 512 512"
                            x="0"
                            y="0"
                            width="512"
                            height="512"
                        >
                            <rect width="512" height="512" style={{ fill: 'transparent' }} />
                            <line className="stroke-gray-700" x1="0" y1="0" x2="512" y2="0" strokeWidth={3} />
                            <line className="stroke-gray-700" x1="0" y1="0" x2="0" y2="512" strokeWidth={3} />
                        </pattern>
                    </defs>
                    <rect
                        width={FLAME_SIZE}
                        height={FLAME_SIZE}
                        transform={`translate(${-FLAME_SIZE / 2}, ${-FLAME_SIZE / 2})`}
                        style={{ fill: 'url(#small-grid)', stroke: 'none' }}
                    />
                    {/* Chrome最新版（89）でd3のズームが効かなくなった問題への対応（Chromeやd3の対応によって不要になるかも）
                    d3 が rect 要素からのイベントを拾えなくなってしまったようなので透明なdivを置くことでd3がイベントを送れるようにする
                    https://docs.google.com/document/d/1U2_GlDOYjCiogsfda6IXkkC3HsD_9CJSs7TXJx7yfpY/edit#heading=h.tnexj6jyzd9l
                    */}
                    <g transform={`translate(${-FLAME_SIZE / 2}, ${-FLAME_SIZE / 2})`}>
                        <foreignObject width={FLAME_SIZE} height={FLAME_SIZE}>
                            <div className="size-full" />
                        </foreignObject>
                    </g>
                </g>
                {children}
            </g>
        </g>
    );
};

import { Fragment, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { LoginModal } from '@framework/ui/elements/LoginModal';

export const HeaderLoginButton: React.FC<unknown> = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const handleClick = () => {
        setShowModal(true);
    };

    return (
        <Fragment>
            <Button color="brand" size="sm" onClick={handleClick}>
                <span className="px-2 font-bold">ログイン</span>
            </Button>
            {showModal && <LoginModal isOpen={showModal} onClose={() => setShowModal(false)} />}
        </Fragment>
    );
};

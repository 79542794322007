import { LinkPool } from './LinkPool';
import { LinkId } from '../types';
import { Link } from '../Link';
import { DisplayOrderTreeZone, DisplayOrderTreeZoneJSON } from './DisplayOrderTreeZone';
import { SelectedItemSet } from '@view-model/models/common/basic';
import { intersection } from 'lodash';
import { ArrayUtil } from '@view-model/models/common/utils/ArrayUtil';
import { orderItemIds } from './orderItemIds';
import { IOrderRollback } from '@model-framework/display-order';
import { NodeId, StickyZoneId } from '@schema-common/base';
import { DisplayOrderRepository } from '../../infrastructure';

export type DisplayOrderTreeJSON = {
    nodeIds: NodeId[];
    zones: DisplayOrderTreeZoneJSON[];
};

export class DisplayOrderTree {
    constructor(
        public readonly nodeIds: NodeId[] = [],
        public readonly zones: DisplayOrderTreeZone[] = []
    ) {}

    addNode(id: NodeId): DisplayOrderTree {
        return this.addNodeList([id]);
    }

    addNodeList(ids: NodeId[]): DisplayOrderTree {
        return new DisplayOrderTree(this.nodeIds.concat(ids), this.zones);
    }

    addZone(id: StickyZoneId): DisplayOrderTree {
        return this.addZoneList([id]);
    }

    addZoneList(ids: StickyZoneId[]): DisplayOrderTree {
        const zones = ids.map((zoneId) => new DisplayOrderTreeZone(zoneId));
        return new DisplayOrderTree(this.nodeIds, this.zones.concat(zones));
    }

    addNodeToZone(nodeId: NodeId, zoneId: StickyZoneId): DisplayOrderTree {
        const zones = this.zones.map((zone) => zone.addNodeToZone(nodeId, zoneId));

        return new DisplayOrderTree(this.nodeIds, zones);
    }

    // 子を持つゾーンを追加したり、移動したりするわけではないので注意
    addZoneToZone(childZoneId: StickyZoneId, parentZoneId: StickyZoneId): DisplayOrderTree {
        const zones = this.zones.map((zone) => zone.addZoneToZone(childZoneId, parentZoneId));

        return new DisplayOrderTree(this.nodeIds, zones);
    }

    /**
     * ドラッグ対象を最低限のものに絞り込む。ゾーンと一緒にゾーンに含まれる子供があった場合、その子供を除外する
     * @param ids
     */
    minimizeElementIdsForDrag(ids: (NodeId | StickyZoneId)[]): (NodeId | StickyZoneId)[] {
        const results: (NodeId | StickyZoneId)[] = [];

        for (const id of ids) {
            // idの祖先IDを取得する、祖先が一つでもidsに含まれている場合、そのidは除外する
            const ancestors = this.ancestors(id);
            if (ancestors.some((ancestorId) => ids.includes(ancestorId))) continue;

            results.push(id);
        }

        return results;
    }

    /**
     * 指定されたidの親ゾーンリストを返す
     * @param id
     */
    ancestors(id: NodeId | StickyZoneId): StickyZoneId[] {
        if (this.nodeIds.includes(id)) return [];
        if (this.zones.map((zone) => zone.id).includes(id)) return [];

        for (const zone of this.zones) {
            const result = zone.ancestors(id);
            if (result.length !== 0) return result;
        }

        return [];
    }

    /**
     * 渡されたIDのゾーンの子孫要素を含むすべてのIDを追加して返す。
     * @param ids
     */
    addZoneDescendantIds(ids: (NodeId | StickyZoneId)[]): (NodeId | StickyZoneId)[] {
        const idSet: Set<NodeId | StickyZoneId> = new Set(ids);
        // ゾーンをすべて列挙する
        const zones = this.findZones(ids);

        // ゾーン内の子IDをすべて追加する
        for (const zone of zones) {
            zone.descendantIds().forEach((id) => idSet.add(id));
        }

        return Array.from(idSet);
    }

    private findZone(zoneId: StickyZoneId): DisplayOrderTreeZone | null {
        const zones = this.findZones([zoneId]);
        return zones[0] || null;
    }

    private findZones(ids: (NodeId | StickyZoneId)[]): DisplayOrderTreeZone[] {
        const results: DisplayOrderTreeZone[] = [];
        for (const id of ids) {
            if (this.nodeIds.includes(id)) continue;

            for (const childZone of this.zones) {
                if (childZone.id === id) {
                    results.push(childZone);
                    continue;
                }

                const zone = childZone.findZoneFromDescendants(id);
                if (zone) {
                    results.push(zone);
                }
            }
        }

        return results;
    }

    /**
     * 最背面から順に要素を返す。
     * 順序ルール: https://docs.google.com/presentation/d/1pVI090GpRnhEA2ATDSj3UQNuz5oD5e0Afeb2CARN7G8/edit#slide=id.gb7ff438ae1_0_25
     *
     * @param links
     * @param selectedItems
     */
    backToFront(links: Link[] = [], selectedItems?: SelectedItemSet<string>): (NodeId | StickyZoneId | LinkId)[] {
        const [selectedLinks, unselectedLinks] = ArrayUtil.splitWith(
            links,
            (link) => selectedItems?.getSelectedItems().includes(link.id) || false
        );
        const selectedLinkIds = selectedLinks.map((link) => link.id);

        // 選択されたリンクは常に最前面に表示されるため、linkPoolは選択されていないリンクから作る
        const linkPool = LinkPool.fromLinks(unselectedLinks);

        // ルート直下の選択されていないノード
        const unselectedNodeIds = this.nodeIds.filter((nodeId) => !selectedItems?.getSelectedItems().includes(nodeId));

        // ルート直下の選択されていないゾーン
        const unselectedZones = this.zones.filter((zone) => !selectedItems?.getSelectedItems().includes(zone.id));

        // selectedItemsにはルート直下のノードだけではなくゾーン内のノードのidも含まれるので、
        // ビュー内の全てのノードをもとに選択されたノードのidを特定する必要がある
        // TODO: 複数ノード選択時もなるべくゾーン内ノード→ルート直下ノードの順番を維持したくてこのような結合にしているが、このやり方だと心許ない気もする
        const allNodeIds = [...this.zones.map((zone) => zone.descendantNodeIds()).flat(), ...this.nodeIds];
        const selectedNodeIdsFromAll = intersection(allNodeIds, selectedItems?.getSelectedItems() || []);

        // selectedItemsにはルート直下のゾーンだけではなくゾーン内のゾーンのidも含まれるので、
        // ビュー内の全てのゾーンをもとに選択されたゾーンを特定する必要がある
        const allZones = [...this.zones, ...this.zones.map((zone) => zone.descendantZones()).flat()];
        const selectedZonesFromAll = allZones.filter((zone) => selectedItems?.getSelectedItems().includes(zone.id));
        const selectedZoneIdsFromAll = selectedZonesFromAll.map((zone) => zone.id);

        // 選択されていないもの
        const unselectedItemIds = orderItemIds(
            linkPool,
            unselectedZones,
            unselectedNodeIds,
            selectedZoneIdsFromAll,
            selectedNodeIdsFromAll
        );

        // 選択されたもの
        const selectedItemIds = orderItemIds(
            linkPool,
            selectedZonesFromAll,
            selectedNodeIdsFromAll,
            selectedZoneIdsFromAll,
            selectedNodeIdsFromAll
        );

        return (
            // 選択されていないもののみが含まれたレイヤー
            unselectedItemIds
                // 選択されたもののみが含まれたレイヤー
                .concat(selectedItemIds)
                // 選択されたリンクがゾーンやノードに隠れないように、常に一番上に持ってくる
                .concat(selectedLinkIds)
        );
    }

    /**
     * 最前面から順に要素を返す。
     * @param links
     * @see backToFront()
     */
    frontToBack(links: Link[] = []): (NodeId | StickyZoneId | LinkId)[] {
        return this.backToFront(links).reverse();
    }

    /**
     * 最背面から順にゾーンを返します。
     */
    backToFrontZone(): StickyZoneId[] {
        return this.zones.reduce<StickyZoneId[]>((acc, zone) => acc.concat(zone.backToFrontZone()), []);
    }

    /**
     * 最前面から順にゾーンを返します。
     */
    frontToBackZone(): StickyZoneId[] {
        return this.backToFrontZone().reverse();
    }

    dump(): DisplayOrderTreeJSON {
        const { nodeIds, zones } = this;
        return {
            nodeIds,
            zones: zones.map((zone) => zone.dump()),
        };
    }

    static load(dump: DisplayOrderTreeJSON): DisplayOrderTree {
        const { nodeIds, zones } = dump;
        return new DisplayOrderTree(
            nodeIds,
            zones.map((zone) => DisplayOrderTreeZone.load(zone))
        );
    }

    cloneNew(newIdMap: Record<NodeId | StickyZoneId, NodeId | StickyZoneId>): DisplayOrderTree {
        const { nodeIds, zones } = this;
        const newNodeIds = nodeIds.map((id) => newIdMap[id]);
        const newZones = zones.map((zone) => zone.cloneNew(newIdMap));
        return new DisplayOrderTree(newNodeIds, newZones);
    }

    isEqual(other: DisplayOrderTree): boolean {
        return (
            this.nodeIds.length === other.nodeIds.length &&
            this.zones.length === other.zones.length &&
            this.nodeIds.every((nodeId, index) => nodeId === other.nodeIds[index]) &&
            this.zones.every((zone, index) => zone.isEqual(other.zones[index]))
        );
    }

    /**
     * このツリーから渡されたノードID、ゾーンIDを含む部分ツリーを作成します。
     *
     * 現在のツリーの親子関係はなるべく維持するようにして新しいツリーを作成します。
     * @param selectingIds
     */
    partialTree(selectingIds: (NodeId | StickyZoneId)[]): DisplayOrderTree {
        const partialNodeIds = this.nodeIds.filter((id) => selectingIds.includes(id));
        const partialZones: DisplayOrderTreeZone[] = [];

        this.zones.map((zone) => {
            const [nodeIds, zones] = zone.select(selectingIds);
            partialNodeIds.push(...nodeIds);
            partialZones.push(...zones);
        });

        return new DisplayOrderTree(partialNodeIds, partialZones);
    }

    /**
     * 渡されたIDの要素を移動する際に、移動対象となる（ゾーンの子要素を含む）NodeId, ZoneIdのリストを返します。
     *
     * @param selectingIds ID収集の対象となる要素IDのリスト
     */
    aggregateDescendantIds(selectingIds: (NodeId | StickyZoneId)[]): (NodeId | StickyZoneId)[] {
        // 注意: 再帰的アルゴリズムの簡潔さのため結果をミュータブルなSetオブジェクトに蓄積している
        const aggregatedIds = new Set<NodeId | StickyZoneId>();

        // ID判定のオーダーを減らすのにSetに変換
        const selectingIdsSet = new Set(selectingIds);

        this.nodeIds.filter((id) => selectingIdsSet.has(id)).forEach((id) => aggregatedIds.add(id));
        this.zones.forEach((zone) => zone.aggregateDescendantIds(selectingIdsSet, aggregatedIds));

        return Array.from(aggregatedIds);
    }

    /**
     * 渡されたIDの要素のうち、ゾーンとそのゾーンに含まれる全てのゾーンのIDを返す。
     */
    aggregateDescendantZoneIds(selectingIds: (NodeId | StickyZoneId)[]): StickyZoneId[] {
        // 注意: 再帰的アルゴリズムの簡潔さのため結果をミュータブルなSetオブジェクトに蓄積している
        const aggregatedIds = new Set<StickyZoneId>();
        // ID判定のオーダーを減らすのにSetに変換
        const selectingIdsSet = new Set(selectingIds);
        this.zones.forEach((zone) => zone.aggregateDescendantZoneIds(selectingIdsSet, aggregatedIds));

        return Array.from(aggregatedIds);
    }

    /**
     * 指定されたノードがこのツリーに含まれているかどうかを返す
     * @param nodeId
     * @private
     */
    private hasNode(nodeId: NodeId): boolean {
        const { nodeIds, zones } = this;
        return nodeIds.includes(nodeId) || zones.some((zone) => zone.hasNode(nodeId));
    }

    /**
     * 指定されたゾーンがこのツリーに含まれているかどうかを返す
     * @param zoneId
     * @private
     */
    private hasZone(zoneId: StickyZoneId): boolean {
        const { zones } = this;
        return zones.some((zone) => zone.id === zoneId || zone.hasZone(zoneId));
    }

    /**
     * 渡されたノードIDのうち、このツリーに含まれていないものをルート直下に追加します.
     * @param nodeIds
     */
    addNodesNotIncluded(nodeIds: NodeId[]): DisplayOrderTree {
        const notIncludedIds = nodeIds.filter((nodeId) => !this.hasNode(nodeId));
        return this.addNodeList(notIncludedIds);
    }

    /**
     * 渡されたゾーンIDのうち、このツリーに含まれていないものをルート直下に追加します.
     * @param zoneIds
     */
    addZonesNotIncluded(zoneIds: StickyZoneId[]): DisplayOrderTree {
        const notIncludedIds = zoneIds.filter((zoneId) => !this.hasZone(zoneId));
        return this.addZoneList(notIncludedIds);
    }

    /**
     * このツリーに渡された部分ツリーが含まれているかどうかを返します。
     */
    hasPartialTree(tree: DisplayOrderTree): boolean {
        return this.partialTree(tree.allIds()).isEqual(tree);
    }

    hasNoElementsOf(tree: DisplayOrderTree): boolean {
        return this.partialTree(tree.allIds()).isEmpty();
    }

    /**
     * このツリーにzoneIdを持つゾーンがあり、かつそのゾーンにdescendantIdを持つノードorゾーンが属するかどうかを判定します。
     * @param zoneId
     * @param descendantId
     */
    hasZoneWithDescendant(zoneId: StickyZoneId, descendantId: NodeId | StickyZoneId): boolean {
        const zone = this.findZone(zoneId);
        return !!(zone && zone.hasDescendant(descendantId));
    }

    /**
     * このツリーに含まれる全てのノード、ゾーンのIDを返します。
     */
    private allIds(): (NodeId | StickyZoneId)[] {
        return this.allNodeIds().concat(this.allZoneIds());
    }

    /**
     * このツリーに含まれる全てのノードのIDを返します。
     */
    private allNodeIds(): NodeId[] {
        const { nodeIds, zones } = this;

        return zones.reduce((ids, zone) => [...ids, ...zone.allNodeIds()], nodeIds);
    }

    /**
     * このツリーに含まれる全てのゾーンのIDを返します。
     */
    private allZoneIds(): StickyZoneId[] {
        return this.zones.reduce<StickyZoneId[]>((ids, zone) => [...ids, ...zone.allZoneIds()], []);
    }

    /**
     * このツリーが空かどうかを返します。
     */
    isEmpty(): boolean {
        return this.nodeIds.length === 0 && this.zones.length === 0;
    }

    /**
     * リポジトリからこのツリーの要素を全て削除します。
     * @param repository
     */
    async removeFrom(repository: DisplayOrderRepository): Promise<IOrderRollback> {
        return repository.removeNodesAndZones(this.allNodeIds(), this.allZoneIds());
    }

    /**
     * 与えられたノードの親ゾーンIDを返します。親ゾーンがいない場合はnullを返します。
     * @param nodeId
     */
    findParentZoneIdOf(nodeId: NodeId): StickyZoneId | null {
        for (const zone of this.zones) {
            const parentZoneId = zone.findParentZoneIdOf(nodeId);
            if (parentZoneId) return parentZoneId;
        }

        return null;
    }
}

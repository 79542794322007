import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlatButton } from '@framework/ui/atoms';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';

import { Link } from 'react-router-dom';

type Props = {
    onLoginWithGoogle(): void;
    onLoginWithQuadcept(): void;
    onLoginWithPassword(): void;
};

export const LoginMethodMenu: React.FC<Props> = ({
    onLoginWithGoogle,
    onLoginWithQuadcept,
    onLoginWithPassword,
}: Props) => {
    return (
        <div className="flex w-80 flex-col space-y-4 px-5 py-2">
            <FlatButton color="brand" onClick={onLoginWithGoogle}>
                <span className="pr-2 text-lg">
                    <FontAwesomeIcon icon={faGoogle} />
                </span>
                Googleでログインする
            </FlatButton>

            <FlatButton color="brand" onClick={onLoginWithQuadcept}>
                <span className="pr-2 text-lg font-bold">Q</span>
                Quadceptでログインする
            </FlatButton>

            <FlatButton color="brand" onClick={onLoginWithPassword}>
                メールアドレスでログインする
            </FlatButton>

            <div className="text-center">
                <Link to={UserPagePathBuilder.passwordResetPage()} className="text-sm text-blue-700 hover:underline">
                    パスワードを忘れた方はこちら
                </Link>
            </div>
        </div>
    );
};

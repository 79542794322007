import { ConsistencyLinkKey, ViewKey } from '@view-model/domain/key';
import { ViewCollection } from '@view-model/domain/view';
import { ConsistencyLinkJSON } from '@schema-app/view-model/contents/{viewModelId}/consistency-links/{consistencyLinkId}/ConsistencyLinkJSON';

export class ConsistencyLink {
    constructor(
        public readonly key: ConsistencyLinkKey,
        public readonly from: ViewKey,
        public readonly to: ViewKey
    ) {}

    static buildNew(from: ViewKey, to: ViewKey): ConsistencyLink {
        if (from.isEqual(to)) {
            throw new Error(`整合性リンクの接続元と接続先が同じViewです: ${from.toString()}`);
        }

        return new ConsistencyLink(ConsistencyLinkKey.buildNew(), from, to);
    }

    static load(json: ConsistencyLinkJSON): ConsistencyLink {
        return new ConsistencyLink(
            new ConsistencyLinkKey(json.key),
            new ViewKey(json.fromViewKey),
            new ViewKey(json.toViewKey)
        );
    }

    get id(): string {
        return this.key.id as string;
    }

    dump(): ConsistencyLinkJSON {
        return {
            key: this.key.toString(),
            fromViewKey: this.from.toString(),
            toViewKey: this.to.toString(),
        };
    }

    clone(): ConsistencyLink {
        return ConsistencyLink.load(this.dump());
    }

    cloneNew(viewKeyMap: Record<string, ViewKey>): ConsistencyLink {
        return ConsistencyLink.load(
            Object.assign(this.dump(), {
                key: ConsistencyLinkKey.buildNew().toString(),
                fromViewKey: viewKeyMap[this.from.toString()].toString(),
                toViewKey: viewKeyMap[this.to.toString()].toString(),
            })
        );
    }

    isRelatedTo(viewKey: ViewKey): boolean {
        return this.to.isEqual(viewKey) || this.from.isEqual(viewKey);
    }

    isValidBy(views: ViewCollection): boolean {
        return views.includeKey(this.from) && views.includeKey(this.to);
    }

    isEqual(other: ConsistencyLink): boolean {
        return this.key.isEqual(other.key) && this.from.isEqual(other.from) && this.to.isEqual(other.to);
    }

    isLinkTo(viewKey: ViewKey): boolean {
        return this.from.isEqual(viewKey) || this.to.isEqual(viewKey);
    }
}

import { useMemo } from 'react';
import { Rect } from '@view-model/models/common/basic';
import { useElementDescriptionPlacement, useElementDescriptionContent } from '../hook';
import { NodeId, ViewModelId } from '@schema-common/base';
import { useExclusiveTextEditing } from '@model-framework/text/editing-user';
import { ElementDescription, ElementDescriptionEditLockKey, ElementDescriptionRepository } from '../domain';
import { Callout } from '@model-framework/ui';
import { ElementDescriptionLayout } from './ElementDescriptionLayout';
import { ModelLayout } from '@view-model/models/sticky/layout';

type Props = {
    viewModelId: ViewModelId;
    elementId: NodeId;
    description: ElementDescription;
    readonly: boolean;
    elementRect: Rect;
    repository: ElementDescriptionRepository;
};

export const ElementDescriptionContent: React.FC<Props> = ({
    viewModelId,
    elementId,
    description,
    readonly,
    elementRect,
    repository,
}: Props) => {
    const { position, size, targetRect, setSize, onDragStart, onDrag, onDragEnd } = useElementDescriptionPlacement(
        elementRect,
        repository,
        description,
        readonly
    );

    const { content, onStartEdit, onEdit, onEndEdit } = useElementDescriptionContent(description, repository);

    const editLockKey = useMemo(() => ElementDescriptionEditLockKey.fromElementId(elementId), [elementId]);

    const { editing, startEdit, endEdit } = useExclusiveTextEditing(viewModelId, editLockKey, content, readonly);

    // 空文字はfalseなのでcontentだけ厳密にnullチェックする
    if (!position || !targetRect || content === null) return null;

    return (
        <Callout
            editing={editing}
            text={content}
            position={position}
            size={size}
            minHeight={ElementDescriptionLayout.initialSize().height}
            targetRect={targetRect}
            tailBaseLineLength={ModelLayout.GridSize}
            tailBaseLineMargin={ModelLayout.GridSize}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onChangeSize={setSize}
            onStartEdit={onStartEdit}
            onEdit={onEdit}
            onEndEdit={onEndEdit}
            onBlur={endEdit}
            onDblClick={startEdit}
        />
    );
};

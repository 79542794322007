import { ILayout } from '@view-model/ui/layouts';
import { Point } from '@view-model/models/common/basic';
import { PointJSON } from '@schema-common/view-model';

type Id = string;
export type PositionSetJSON = Record<Id, PointJSON>;

export class PositionSet {
    private readonly positions: Readonly<Record<Id, Point>>;

    constructor(positions: Record<Id, Point> = {}) {
        this.positions = positions;
    }

    dump(): Record<Id, PointJSON> {
        return this.all().reduce(
            (result, [id, pos]) => {
                result[id] = pos.dump();
                return result;
            },
            {} as Record<Id, PointJSON>
        );
    }

    static load(positions: Record<Id, PointJSON> | null): PositionSet {
        if (!positions) {
            return this.buildEmpty();
        }
        return this.fromArray(Object.entries(positions));
    }

    static buildEmpty(): PositionSet {
        return new PositionSet();
    }

    static fromArray(positions: [Id, Point | PointJSON][]): PositionSet {
        return new PositionSet(
            positions.reduce(
                (result, [id, pos]) => {
                    if (pos instanceof Point) {
                        result[id] = pos;
                    } else {
                        result[id] = Point.fromPosition(pos);
                    }

                    return result;
                },
                {} as Record<Id, Point>
            )
        );
    }

    find(id: Id): Point | undefined {
        return this.positions[id];
    }

    set(id: Id, position: Point | PointJSON): PositionSet {
        const newPositions = { ...this.positions };
        if (position instanceof Point) {
            newPositions[id] = position;
        } else {
            newPositions[id] = Point.fromPosition(position);
        }

        return new PositionSet(newPositions);
    }

    move(id: Id, dx: number, dy: number): PositionSet {
        return this.moveMulti([id], dx, dy);
    }

    moveMulti(ids: Id[], dx: number, dy: number): PositionSet {
        const newPositions = this.all().map(([id, position]) => {
            return [id, ids.includes(id) ? position.addXY(dx, dy) : position] as [Id, Point];
        });

        return PositionSet.fromArray(newPositions);
    }

    moveAll(dx: number, dy: number): PositionSet {
        return this.moveMulti(this.allIds(), dx, dy);
    }

    getMulti(ids: Id[]): PositionSet {
        const newPositions = this.all().filter(([id]) => ids.includes(id));
        return PositionSet.fromArray(newPositions);
    }

    all(): [Id, Point][] {
        return Object.entries(this.positions);
    }

    allIds(): Id[] {
        return Object.keys(this.positions);
    }

    subset(ids: Id[]): PositionSet {
        return this.getMulti(ids);
    }

    count(): number {
        return Object.keys(this.positions).length;
    }

    isEmpty(): boolean {
        return this.count() === 0;
    }

    remove(id: Id): PositionSet {
        return this.removeMany([id]);
    }

    removeMany(ids: Id[]): PositionSet {
        const newPositions = this.all().filter(([id]) => !ids.includes(id));
        return PositionSet.fromArray(newPositions);
    }

    isEqual(other: PositionSet): boolean {
        return (
            other instanceof PositionSet &&
            this.count() === other.count() &&
            this.all().every(([id, pos]) => other.find(id)?.isEqual(pos))
        );
    }

    cloneNew(newIdMap: Record<Id, Id>): PositionSet {
        const newPositions = this.all().map(([id, position]) => [newIdMap[id], position] as [Id, Point]);
        return PositionSet.fromArray(newPositions);
    }

    merge(other: PositionSet): PositionSet {
        // other の値で上書きする
        return PositionSet.fromArray([...this.all(), ...other.all()]);
    }

    snapToLayout(layout: ILayout): PositionSet {
        const newPositions = this.all().map(([id, position]) => {
            return [id, Point.fromPosition(layout.snapPosition(position))] as [Id, Point];
        });
        return PositionSet.fromArray(newPositions);
    }
}

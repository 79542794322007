import { ICommand } from '@model-framework/command';
import { NodeId, StickyZoneId } from '@schema-common/base';
import { DisplayOrderRepository } from '../infrastructure';
import { IOrderRollback } from './IOrderRollback';

export class DisplayOrderDeleteCommand implements ICommand {
    private rollback: IOrderRollback | null;

    constructor(
        private readonly nodeIds: NodeId[],
        private readonly zoneIds: StickyZoneId[],
        private readonly repository: DisplayOrderRepository
    ) {
        this.rollback = null;
    }

    static build(
        nodeIds: NodeId[],
        zoneIds: StickyZoneId[],
        repository: DisplayOrderRepository
    ): DisplayOrderDeleteCommand | null {
        if (nodeIds.length === 0 && zoneIds.length === 0) return null;

        return new this(nodeIds, zoneIds, repository);
    }

    do(): void {
        const { nodeIds, zoneIds } = this;
        this.repository.removeNodesAndZones(nodeIds, zoneIds).then((rollback) => {
            this.rollback = rollback;
        });
    }

    undo(): void {
        const { repository, rollback } = this;

        if (!rollback) return;

        repository.rollback(rollback).then(() => {
            this.rollback = null;
        });
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        return !!this.rollback;
    }

    async canRedo(): Promise<boolean> {
        return !this.rollback;
    }
}

import { NodePosition } from '@view-model/models/sticky/StickyNodeView';
import { StickyZonePosition } from '@view-model/models/sticky/StickyZoneView';
import { isNodeKey, isStickyZoneKey, NodeKey, StickyZoneKey } from '@view-model/domain/key';
import { ILayout } from '@view-model/ui/layouts';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { Position } from '@view-model/models/common/types/ui';

type NodePositionPair = [NodeKey, NodePosition];
type ZonePositionPair = [StickyZoneKey, StickyZonePosition];
type Positions = (NodePositionPair | ZonePositionPair)[];

export type ModelElementPositionableKey = NodeKey | StickyZoneKey;

export class ModelElementPositionMap {
    constructor(
        private readonly nodePositionSet: PositionSet = new PositionSet(),
        private readonly zonePositionSet: PositionSet = new PositionSet()
    ) {}

    static fromPositions(positions: Positions): ModelElementPositionMap {
        const nodePositions = positions.filter(([key]) => key.kind === NodeKey.KIND);
        const nodePositionRecords = nodePositions.reduce((rec: Record<string, Position>, [key, position]) => {
            rec[key.id] = position;
            return rec;
        }, {});
        const nodePositionSet = PositionSet.load(nodePositionRecords);

        const zonePositions = positions.filter(([key]) => key.kind === StickyZoneKey.KIND);
        const zonePositionRecords = zonePositions.reduce((rec: Record<string, Position>, [key, position]) => {
            rec[key.id] = position;
            return rec;
        }, {});
        const zonePositionSet = PositionSet.load(zonePositionRecords);

        return new ModelElementPositionMap(nodePositionSet, zonePositionSet);
    }

    get length(): number {
        return this.nodePositionSet.count() + this.zonePositionSet.count();
    }

    keys(): ModelElementPositionableKey[] {
        const nodeKeys = this.nodePositionSet.all().map(([id]) => NodeKey.buildFromID(id));
        const zoneKeys = this.zonePositionSet.all().map(([id]) => StickyZoneKey.buildFromID(id));
        return [...nodeKeys, ...zoneKeys];
    }

    get(key: ModelElementPositionableKey): NodePosition | StickyZonePosition | undefined {
        if (isNodeKey(key)) {
            return this.getNode(key);
        }
        if (isStickyZoneKey(key)) {
            return this.getZone(key);
        }
    }

    getNode(key: NodeKey): NodePosition | undefined {
        const position = this.nodePositionSet.find(key.id.toString());
        if (position) {
            return NodePosition.load({ x: position.x, y: position.y });
        }
        return undefined;
    }

    getZone(key: StickyZoneKey): StickyZonePosition | undefined {
        const position = this.zonePositionSet.find(key.id.toString());
        if (position) {
            return StickyZonePosition.load({ x: position.x, y: position.y });
        }
        return undefined;
    }

    nodeItems(): [NodeKey, NodePosition][] {
        return this.nodePositionSet
            .all()
            .map(([id, position]) => [NodeKey.buildFromID(id), new NodePosition(position)]);
    }

    zoneItems(): [StickyZoneKey, StickyZonePosition][] {
        return this.zonePositionSet
            .all()
            .map(([id, position]) => [StickyZoneKey.buildFromID(id), new StickyZonePosition(position.x, position.y)]);
    }

    isEqual(other: ModelElementPositionMap): boolean {
        return (
            this.nodePositionSet.isEqual(other.nodePositionSet) && this.zonePositionSet.isEqual(other.zonePositionSet)
        );
    }

    add(key: ModelElementPositionableKey, position: NodePosition | StickyZonePosition): ModelElementPositionMap {
        if (isStickyZoneKey(key)) {
            return new ModelElementPositionMap(
                this.nodePositionSet,
                this.zonePositionSet.set(key.id.toString(), position)
            );
        }
        return new ModelElementPositionMap(this.nodePositionSet.set(key.id.toString(), position), this.zonePositionSet);
    }

    remove(key: ModelElementPositionableKey): ModelElementPositionMap {
        if (key.kind === StickyZoneKey.KIND) {
            const newZonePositionSet = this.zonePositionSet.remove(key.id.toString());
            return new ModelElementPositionMap(this.nodePositionSet, newZonePositionSet);
        }
        const newNodePositionSet = this.nodePositionSet.remove(key.id.toString());
        return new ModelElementPositionMap(newNodePositionSet, this.zonePositionSet);
    }

    movePosition(dx: number, dy: number): ModelElementPositionMap {
        return new ModelElementPositionMap(this.nodePositionSet.moveAll(dx, dy), this.zonePositionSet.moveAll(dx, dy));
    }

    merge(positions: ModelElementPositionMap): ModelElementPositionMap {
        return new ModelElementPositionMap(
            this.nodePositionSet.merge(positions.nodePositionSet),
            this.zonePositionSet.merge(positions.zonePositionSet)
        );
    }

    snapToLayout(layout: ILayout): ModelElementPositionMap {
        const newPositionMap = new ModelElementPositionMap(
            this.nodePositionSet.snapToLayout(layout),
            this.zonePositionSet.snapToLayout(layout)
        );

        if (this.length !== newPositionMap.length) {
            console.warn(
                `Some items forgot to snap: current=${this.length}items, newMap=${newPositionMap.length}items.`
            );
        }
        return newPositionMap;
    }

    isEmpty(): boolean {
        return this.nodePositionSet.isEmpty() && this.zonePositionSet.isEmpty();
    }
}

export enum MultiSelectionMenuId {
    FontSize = 'FontSize',
    ThemeColor = 'ThemeColor',
    LinkLineStyle = 'LinkLineStyle',
    LinkMarkStyle = 'LinkMarkStyle',
    ReverseLink = 'ReverseLink',
    LinkColor = 'LinkColor',
    GroupStickyElements = 'GroupStickyElements',
    RemoveMultiSelectedLinks = 'RemoveMultiSelectedLinks',
    HorizontalAlignMenu = 'HorizontalAlignMenu',
    VerticalAlignMenu = 'VerticalAlignMenu',
}

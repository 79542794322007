import { LinkEntity } from './LinkEntity';
import { LinkableTargetId, LinkableTargetKey, LinkKey, NodeKey } from '@view-model/domain/key';
import { LinkableTargetCollection } from '@view-model/domain/model';
import { LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';
import { Id } from '@framework/domain';
import { LinkEntityOperation, LinkPlacementFactory, LinkStyleSet } from '@view-model/models/sticky/StickyLink';
import { LinkDeleteCommand } from '@view-model/models/sticky/StickyLink/command';
import { CompositeCommand, ICommand } from '@model-framework/command';
import { Rect } from '@view-model/models/common/basic';
import { ArrayUtil } from '@view-model/models/common/utils/ArrayUtil';
import { LinkJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';
import { LinkId } from '@schema-common/base';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class LinkCollection {
    private readonly links: LinkEntity[];

    public constructor(links: LinkEntity[]) {
        this.links = links;
    }

    static empty(): LinkCollection {
        return new LinkCollection([]);
    }

    public containByNodeKey(nodeKey: NodeKey): LinkEntity[] {
        return this.links.filter((link) => {
            return link.from.isEqual(nodeKey) || link.to.isEqual(nodeKey);
        });
    }

    filter(cb: (link: LinkEntity) => boolean): LinkCollection {
        return new LinkCollection(this.links.filter(cb));
    }

    /**
     * 渡されたターゲットIDリストに接続可能かどうかによってコレクションを２つに分割します。
     * @param ids
     * @returns [接続可能なリンクコレクション, 接続できないリンクコレクション]
     */
    splitByConnectivity(ids: LinkableTargetId[]): [LinkCollection, LinkCollection] {
        const [a, b] = ArrayUtil.splitWith(this.links, (link) => link.canConnectWithin(ids));
        return [new LinkCollection(a), new LinkCollection(b)];
    }

    public findByKey(key: LinkKey): LinkEntity | undefined {
        return this.links.find((link) => link.key.isEqual(key));
    }

    findById(linkId: LinkId): LinkEntity | undefined {
        return this.links.find((link) => link.id == linkId);
    }

    public get length(): number {
        return this.links.length;
    }

    public keys(): LinkKey[] {
        return this.links.map((link) => link.key);
    }

    ids(): LinkId[] {
        return this.links.map(({ id }) => id);
    }

    public entities(): LinkEntity[] {
        return this.links.concat();
    }

    public isEqual(other: LinkCollection): boolean {
        if (!(other instanceof LinkCollection)) return false;
        if (this.length != other.length) return false;

        return this.links.every((link) => {
            const otherLink = other.findByKey(link.key);
            return otherLink && link.isEqual(otherLink);
        });
    }

    isEmpty(): boolean {
        return this.links.length === 0;
    }

    /**
     * link を追加した新しいコレクションを返す。すでにlinkがコレクション内にあった場合は置き換える。
     * @param newLink
     */
    public added(newLink: LinkEntity): LinkCollection {
        // CollectionEventsMixinのadd()のデフォルト挙動を踏襲して、すでに要素があった場合は置き換えるようにする
        const newEntities = [...this.links.filter((link) => !link.isEqual(newLink)), newLink];
        return new LinkCollection(newEntities);
    }

    /**
     * 指定されたキーの要素を削除した新しいコレクションを返す
     * @param deletedLinkKey
     */
    public removed(deletedLinkKey: LinkKey): LinkCollection {
        const newEntities = this.links.filter((link) => !link.key.isEqual(deletedLinkKey));
        return new LinkCollection(newEntities);
    }

    updated(linkId: LinkId, updater: (link: LinkEntity) => LinkEntity): LinkCollection {
        const links = this.links.map((link) => (link.id === linkId ? updater(link) : link));
        return new LinkCollection(links);
    }

    public dump(): LinkJSON[] {
        return this.links.map((link) => link.dump());
    }

    public static load(links: LinkJSON[]): LinkCollection {
        return new LinkCollection(links.map((link) => LinkEntity.load(link)));
    }

    public cloneNew(newKeyMap: Record<string, LinkableTargetKey>): LinkCollection {
        return new LinkCollection(this.links.map((link) => link.cloneNew(newKeyMap)));
    }

    filterByIds(ids: Id[]): LinkCollection {
        const models = this.links.filter(({ id }) => ids.includes(id));
        return new LinkCollection(models);
    }

    /**
     * 与えられた要素の集合で整合性が取れているか (from, to の要素が存在するか) をチェックし、
     * 整合性の取れていないリンクがあればそのコレクションを返す。
     *
     * @param elements {LinkableTargetCollection} リンク対象の要素の集合
     */
    public invalidLinksBy(elements: LinkableTargetCollection): LinkCollection | undefined {
        const links = this.links.filter((link) => !link.isValidBy(elements));
        return links.length > 0 ? new LinkCollection(links) : undefined;
    }

    /**
     * 与えられた要素の集合で整合性が取れているか (from, to の要素が存在するか) をチェックし、
     * 整合性の取れているリンクがあればそのコレクションを返す。
     *
     * @param elements {LinkableTargetCollection} リンク対象の要素の集合
     */
    public validLinksBy(elements: LinkableTargetCollection): LinkCollection | undefined {
        const links = this.links.filter((link) => link.isValidBy(elements));
        return links.length > 0 ? new LinkCollection(links) : undefined;
    }

    markStyles(): LinkMarkStyle[] {
        return this.links.map((link) => link.style.markStyle);
    }

    lineStyles(): LinkLineStyle[] {
        return this.links.map((link) => link.style.lineStyle);
    }

    colors(): LinkColor[] {
        return this.links.map((link) => link.style.color);
    }

    styleSet(): LinkStyleSet {
        return LinkStyleSet.fromLinks(this.links);
    }

    /**
     * 渡されたリンク配列を加えた新しいコレクションを返す。
     * idが重複する要素は追加されない。また、返されるコレクションの要素の順序は不定。
     * @param links
     */
    addList(links: LinkEntity[]): LinkCollection {
        const linkMap: Record<LinkId, LinkEntity> = {};

        links.concat(this.links).forEach((link) => {
            linkMap[link.id.toString()] = link;
        });

        return new LinkCollection(Object.values(linkMap));
    }

    buildDeleteCommand(viewModel: ViewModelEntity, linkEntityOperation: LinkEntityOperation): ICommand | null {
        if (this.isEmpty()) return null;

        const commands = this.links.map((link) => new LinkDeleteCommand(viewModel, linkEntityOperation, link));

        return CompositeCommand.composeOptionalCommands(...commands);
    }

    /**
     * コレクションを囲む矩形を返します。
     */
    getBounds(linkPlacementFactory: LinkPlacementFactory): Rect | null {
        if (this.isEmpty()) return null;

        const bounds = this.links.map((link) => link.getBounds(linkPlacementFactory));
        return Rect.union(ArrayUtil.filterNull(bounds));
    }

    deleteCommands(viewModel: ViewModelEntity, entityOperation: LinkEntityOperation): LinkDeleteCommand[] {
        return this.links.map((link) => new LinkDeleteCommand(viewModel, entityOperation, link));
    }
}

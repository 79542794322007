import { useCallback, useEffect, useState } from 'react';
import { ModelCommentEntity } from '../../domain';
import { ModelCommentHeader } from './ModelCommentHeader';
import { ModelCommentBody } from './ModelCommentBody';
import { ModelCommentBodyForm } from '../ModelCommentBodyForm';
import { Button } from '@framework/ui/atoms/Button';
import { ModelCommentThreadOperation } from '../../adapter';

type Props = {
    comment: ModelCommentEntity;
    isEditing: boolean;
    threadOperation: ModelCommentThreadOperation;
    onSubmit(): void;
    onCancel(): void;
};

export const ModelCommentItem: React.FC<Props> = ({
    comment,
    isEditing,
    threadOperation,
    onSubmit,
    onCancel,
}: Props) => {
    const [body, setBody] = useState<string>('');

    useEffect(() => {
        setBody(comment.getBody());
    }, [comment, isEditing]);

    const handleChange = useCallback((newBody: string) => {
        setBody(newBody);
    }, []);

    const handleEditSubmit = useCallback(() => {
        threadOperation.updateCommentBody(comment.id, body).then();
        onSubmit();
    }, [body, comment.id, onSubmit, threadOperation]);

    const handleEditCancel = useCallback(() => {
        setBody(comment.getBody());
        onCancel();
    }, [onCancel, comment]);

    return (
        <>
            <ModelCommentHeader author={comment.getAuthor()} postAt={comment.getPostAtAsString()} />
            {isEditing ? (
                <>
                    <ModelCommentBodyForm
                        autoFocus={true}
                        body={body}
                        onChange={handleChange}
                        onSubmit={handleEditSubmit}
                        minRows={4}
                    />
                    <div>
                        <Button color="brand" onClick={handleEditSubmit} disabled={!body}>
                            保存
                        </Button>
                        <Button color="flat" onClick={handleEditCancel}>
                            キャンセル
                        </Button>
                    </div>
                </>
            ) : (
                <ModelCommentBody body={comment.getBody()} />
            )}
        </>
    );
};

import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { contentTransformAtom } from '@user/pages/ViewModelPage/contentTransformAtom';
import {
    TITLE_BAR_FONT_SIZE,
    TITLE_BAR_HEIGHT,
    TITLE_BAR_MARGIN,
} from '@view-model/ui/components/View/ViewTitleBar/constants';
import { maxIconCount, menuButtonGap } from '@view-model/ui/components/View/ViewTitleMenu';

interface ViewTitleBar {
    height: number;
    shouldHideMenuButtons: boolean;
    margin: number;
    title: {
        fontSize: number;
    };
    menu: {
        buttonSize: number;
        fontSize: number;
        containerWidth: number;
    };
}

const scaleAtom = selectAtom(contentTransformAtom, (contentTransform) => contentTransform.k);

// ビューのタイトルバーの高さや文字サイズを管理する
export const viewTitleBarAtom = atom((get): ViewTitleBar => {
    const scale = get(scaleAtom);

    const maxScale = 20 / TITLE_BAR_HEIGHT; // タイトルバーの高さが20pxになるスケール
    const minScale = 0.07;
    const shouldScale = scale <= maxScale;

    // タイトルバーの高さや文字サイズをズーム率にあわせて調整
    const height = shouldScale ? (1 / Math.max(minScale, scale)) * 20 : TITLE_BAR_HEIGHT;
    const fontSize = shouldScale ? (1 / Math.max(minScale, scale)) * 12.5 : TITLE_BAR_FONT_SIZE;
    const margin = shouldScale ? (1 / Math.max(minScale, scale)) * 2.5 : TITLE_BAR_MARGIN;

    // 引きだとメニューボタンを非表示にする
    const shouldHideMenuButtons = scale <= minScale;

    // メニューのアイコンサイズを計算する
    const menuFontSize = shouldScale ? (1 / Math.max(minScale, scale)) * 11 : 70;
    const menuButtonSize = shouldScale ? (1 / Math.max(minScale, scale)) * 14 : 90;
    const menuContainerWidth = (() => {
        if (shouldHideMenuButtons) {
            return 0;
        }

        return menuButtonSize * maxIconCount + menuButtonGap * (maxIconCount - 1);
    })();

    return {
        height,
        shouldHideMenuButtons,
        margin,
        title: {
            fontSize,
        },
        menu: {
            containerWidth: menuContainerWidth,
            fontSize: menuFontSize,
            buttonSize: menuButtonSize,
        },
    };
});

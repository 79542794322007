import { useState } from 'react';
import { WorkspaceMemberRole } from '@workspace/domain/workspace/vo/WorkspaceMemberRole';
import { RoleMemberList } from './RoleMemberList';
import { WorkspaceMemberEditModal } from '../WorkspaceMemberEditModal';
import { WorkspaceEntity } from '@workspace/domain/workspace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

type Props = {
    workspace: WorkspaceEntity;
    isEditable: boolean;
};

export const MemberSummary: React.FC<Props> = ({ workspace, isEditable }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [memberEditModalOpen, setMemberEditModalOpen] = useState<boolean>(false);

    const handleIconClick = () => {
        setMemberEditModalOpen(true);
    };

    const handleListButtonClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="flex w-full flex-col">
            <div className="mb-1 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="text-2xl font-bold">メンバー({workspace.memberRoles.length}人)</div>
                <div className="flex flex-row">
                    {isEditable && (
                        <span className="mx-2 block size-4 cursor-pointer" onClick={handleIconClick}>
                            <FontAwesomeIcon icon={faCog} className="text-lg" />
                        </span>
                    )}
                </div>
            </div>
            {workspace.memberRoles.length <= 5 || isOpen ? (
                <div>
                    <div className="flex flex-col">
                        {WorkspaceMemberRole.values().map((role) => {
                            return (
                                workspace.memberRoles.hasMembersOf(role) && (
                                    <RoleMemberList key={role} role={role} memberRoles={workspace.memberRoles} />
                                )
                            );
                        })}
                    </div>
                    {workspace.memberRoles.length > 5 && (
                        <div className="cursor-pointer pt-1 text-center text-brand" onClick={handleListButtonClick}>
                            少なく表示する
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <div className="flex h-48 flex-col overflow-hidden">
                        {WorkspaceMemberRole.values().map((role) => {
                            return (
                                workspace.memberRoles.hasMembersOf(role) && (
                                    <RoleMemberList key={role} role={role} memberRoles={workspace.memberRoles} />
                                )
                            );
                        })}
                    </div>
                    <div className="cursor-pointer pt-1 text-center text-brand" onClick={handleListButtonClick}>
                        もっと表示する
                    </div>
                </div>
            )}

            {memberEditModalOpen && (
                <WorkspaceMemberEditModal
                    isOpen={memberEditModalOpen}
                    onClose={() => setMemberEditModalOpen(false)}
                    workspace={workspace}
                />
            )}
        </div>
    );
};

import { useEffect, useState } from 'react';
import { createGroupEntityRepository, GroupEntity } from '@group/domain';
import { useGroupMyMemberRole } from './useGroupMyMemberRole';
import { isValidId } from '@schema-common/base';

type Result = {
    group: GroupEntity | null;
    loading: boolean;
};

/**
 * 指定のグループのエンティティとloadingを返します。
 * エンティティを取得できない場合には、{ group: null } を返します。
 *
 * @param groupId
 * @returns {group: GroupEntity, loading: boolean}
 */
export const useGroupEntity = (id: string): Result => {
    const [loading, setLoading] = useState<boolean>(true);
    const [group, setGroup] = useState<GroupEntity | null>(null);

    /**
     * 引数に指定された id が RTDB のパス階層で指定できない文字列パターンの場合には、非実在のフォールバック用idを返す。
     * これによって不正なRTDBパスへのアクセスによる権限エラーの発生を抑止する。
     */
    const groupId = isValidId(id) ? id : '(invalid-group-id)';

    const role = useGroupMyMemberRole(groupId);

    useEffect(() => {
        // アクセス可能なID一覧取得完了まで待つ
        if (role === undefined) return;

        // 指定の groupId がアクセス可能なID一覧に含まれない場合には、ロード完了 & null を返す
        if (role === null) {
            setLoading(false);
            setGroup(null);
            return;
        }

        setLoading(true);

        const repo = createGroupEntityRepository(groupId);
        repo.get()
            .then((group) => {
                setGroup(group);
            })
            .finally(() => {
                setLoading(false);
            });

        repo.addListener((group) => {
            setGroup(group);
        });

        return () => repo.removeListener();
    }, [groupId, role]);

    return { group, loading };
};

import { Folder } from './Folder';
import { FolderId } from '@schema-common/base';

export class FolderCollection {
    private readonly ids: Readonly<FolderId[]>;
    private readonly folders: Readonly<Record<FolderId, Folder>>;

    constructor(folders: Folder[]) {
        this.folders = folders.reduce(
            (result, folder) => {
                result[folder.id] = folder;
                return result;
            },
            {} as Record<FolderId, Folder>
        );
        this.ids = Object.values(this.folders)
            .sort(Folder.compare)
            .map(({ id }) => id);
    }

    static buildEmpty(): FolderCollection {
        return new FolderCollection([]);
    }

    count(): number {
        return this.ids.length;
    }

    all(): Folder[] {
        return this.ids.map((id) => this.folders[id]);
    }

    allIds(): FolderId[] {
        return this.ids.concat();
    }

    get(folderId: FolderId): Folder | undefined {
        return this.folders[folderId];
    }

    add(folder: Folder): FolderCollection {
        if (this.get(folder.id)) {
            console.warn(`Folder: ${folder.id} is already exists in the collection.`);
        }

        return new FolderCollection(this.all().concat(folder));
    }

    update(folder: Folder): FolderCollection {
        if (!this.get(folder.id)) {
            console.warn(`Folder: ${folder.id} is not found but added.`);
        }

        return this.remove(folder.id).add(folder);
    }

    remove(folderId: FolderId): FolderCollection {
        const folders = this.all().filter(({ id }) => id !== folderId);
        return new FolderCollection(folders);
    }

    filterByIds(ids: FolderId[]): FolderCollection {
        const folders = this.all().filter(({ id }) => ids.includes(id));
        return new FolderCollection(folders);
    }
}

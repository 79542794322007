import { Position } from '@view-model/models/common/types/ui';
import { Line } from './Line';
import Victor from 'victor';
import { PointJSON } from '@schema-common/view-model';
import { ILayout } from '@view-model/ui/layouts';

/**
 * 2次元座標を表すクラス
 */
export class Point {
    constructor(
        public readonly x: number,
        public readonly y: number
    ) {}

    static fromPosition({ x, y }: Position): Point {
        return new Point(x, y);
    }

    add(other: Position): Point {
        return this.addXY(other.x, other.y);
    }

    addX(dx: number): Point {
        return new Point(this.x + dx, this.y);
    }

    addY(dy: number): Point {
        return new Point(this.x, this.y + dy);
    }

    addXY(dx: number, dy: number): Point {
        return new Point(this.x + dx, this.y + dy);
    }

    withX(x: number): Point {
        return new Point(x, this.y);
    }

    withY(y: number): Point {
        return new Point(this.x, y);
    }

    subtract(other: Point): Point {
        return new Point(this.x - other.x, this.y - other.y);
    }

    distance(other: Point): number {
        return Math.sqrt((this.x - other.x) ** 2.0 + (this.y - other.y) ** 2.0);
    }

    /**
     * 距離の二乗を返す。
     * 主な利用目的は正確な距離精度が要求されず、sqrt()による計算コストを避けたい場合のため。
     * @param other
     */
    distanceSquared(other: Point): number {
        const dx = this.x - other.x;
        const dy = this.y - other.y;

        return dx * dx + dy * dy;
    }

    /**
     * 2点を0〜1で補間したポイントを返す
     * @param other
     * @param t 補間パラメータ（0のとき、この座標を返し、1のときotherを返す）
     */
    interpolated(other: Point, t: number): Point {
        const x = Point.interpolation(this.x, other.x, t);
        const y = Point.interpolation(this.y, other.y, t);
        return new Point(x, y);
    }

    vectorTo(other: Point): Point {
        return other.subtract(this);
    }

    /**
     * 座標が直接より上の領域（Y軸的に小さい方）にあるかを返す
     * @param line
     */
    isAbove(line: Line): boolean {
        const { p1, p2 } = line;

        // 線分の点の位置関係が逆になると判定が逆になるので点をX軸順に並べる
        const [start, end] = p1.x < p2.x ? [p1, p2] : [p2, p1];

        const v1 = Victor.fromObject(this.vectorTo(start));
        const v2 = Victor.fromObject(this.vectorTo(end));

        // 外積の符号によって点の位置関係（直線に対してどちら側にあるか）を判定
        return v1.cross(v2) < 0;
    }

    /**
     * 座標の値を絶対値に変換した点を返します。
     */
    abs(): Point {
        const { x, y } = this;
        return new Point(Math.abs(x), Math.abs(y));
    }

    /**
     * 指定されたX座標の軸に対して線対称の位置を返します。
     * @param centerX 線対称の軸になるX座標
     */
    symmetricOnX(centerX: number): Point {
        const { x, y } = this;
        return new Point(centerX * 2 - x, y);
    }

    /**
     * 指定されたY座標の軸に対して線対称の位置を返します。
     * @param centerY 線対称の軸になるY座標
     */
    symmetricOnY(centerY: number): Point {
        const { x, y } = this;
        return new Point(x, centerY * 2 - y);
    }

    isEqual(other: Point): boolean {
        return other.x === this.x && other.y === this.y;
    }

    toString(): string {
        return `(${this.x}, ${this.y})`;
    }

    /**
     * <path>, <polyline>などで利用されるSVGの座標文字列表現に変換します。
     * https://developer.mozilla.org/ja/docs/Web/SVG/Tutorial/Basic_Shapes#polyline
     */
    toSVGPoint(): string {
        return `${this.x},${this.y}`;
    }

    /**
     * 座標をSVGのtransform属性のtranslate文字列に変換します。
     * https://developer.mozilla.org/ja/docs/Web/SVG/Attribute/transform#translate
     */
    toSVGTranslate(): string {
        return `translate(${this.x}, ${this.y})`;
    }

    private static interpolation(a: number, b: number, t: number): number {
        return a * (1 - t) + b * t;
    }

    dump(): PointJSON {
        const { x, y } = this;
        return { x, y };
    }

    static load(dump: PointJSON): Point {
        return new Point(dump.x, dump.y);
    }

    toVictor(): Victor {
        return Victor.fromObject(this);
    }

    /**
     * 渡されたレイアウトにスナップした新しいPointを返します。
     * @param layout
     */
    snapTo(layout: ILayout): Point {
        const pos = layout.snapPosition(this);
        return new Point(pos.x, pos.y);
    }
}

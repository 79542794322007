import { useEffect, useRef, useState } from 'react';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { Droppable } from '@workspace/view-model-folder/ui/DragAndDrop';
import { FolderItemContent } from './FolderItemContent';
import { FolderContainer } from '@workspace/view-model-folder/domain/FolderContainer';
import { Folder } from '../../domain/Folder';
import { ViewModelId } from '@schema-common/base';

type Props = {
    folderContainer: FolderContainer;
    isDropTarget: boolean;
    isDuplicating: boolean;
    showEditMenu: boolean;
    workspace: Workspace;
    onDuplicateFolder: (folder: Folder, childViewModelIds: ViewModelId[]) => void;
};

export const FolderItem: React.FC<Props> = ({
    folderContainer,
    isDropTarget,
    isDuplicating,
    showEditMenu,
    workspace,
    onDuplicateFolder,
}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const scrollTargetFolderId = new URLSearchParams(location.search).get('scrollTargetFolderId');
    const ref = useRef<HTMLDivElement | null>(null);

    const handleToggle = () => setIsOpen((open) => !open);

    useEffect(() => {
        if (isDropTarget || folderContainer.filtered()) {
            setIsOpen(true);
        }
    }, [isDropTarget, folderContainer]);

    useEffect(() => {
        if (scrollTargetFolderId === folderContainer.folderId && ref.current) {
            const rect = ref.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (rect.bottom > windowHeight || rect.top < 0) {
                ref.current.scrollIntoView();
            }
            setIsOpen(true);
        }
    }, [folderContainer, scrollTargetFolderId, ref]);

    return (
        <Droppable id={folderContainer.folderId} hoveredClassName="rounded-lg bg-gray-300">
            <div ref={ref}>
                <FolderItemContent
                    folderContainer={folderContainer}
                    isOpen={isOpen}
                    isDuplicating={isDuplicating}
                    onClick={handleToggle}
                    onDuplicateFolder={onDuplicateFolder}
                    showEditMenu={showEditMenu}
                    workspace={workspace}
                />
            </div>
        </Droppable>
    );
};

import { ViewName } from '@view-model/domain/view';
import { useAtomValue } from 'jotai';
import { viewTitleBarAtom } from '../viewTitlaBarAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const PLACE_HOLDER = 'タイトル | Title';

type Props = {
    name: ViewName;
    viewIsLocked?: boolean;
};

export const ReadonlyViewTitle: React.FC<Props> = ({ name, viewIsLocked = false }: Props) => {
    const {
        title: { fontSize },
    } = useAtomValue(viewTitleBarAtom);

    return (
        <div className="pointer-events-none grow truncate text-center font-bold text-white" style={{ fontSize }}>
            {viewIsLocked && <FontAwesomeIcon icon={faLock} className="pr-4" />}
            {name.value ? name.value : PLACE_HOLDER}
        </div>
    );
};

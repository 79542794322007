import { EditingUser } from '../domain';
import { Key } from '@framework/domain';
import { RefBuilder, Reference, RTDBPath } from '@framework/repository';
import { ViewModelId } from '@schema-common/base';

export class EditingUserRepository {
    public constructor(private readonly viewModelId: ViewModelId) {}

    private _parentRef(): Reference {
        return RefBuilder.ref(RTDBPath.ViewModelViewer.editingUsersPath(this.viewModelId));
    }

    private _ref(key: Key): Reference {
        return RefBuilder.ref(RTDBPath.ViewModelViewer.editingUserPath(this.viewModelId, key));
    }

    async save(targetKey: Key, editingUser: EditingUser): Promise<void> {
        const ref = this._ref(targetKey);
        const value = editingUser.dump();
        // 切断時に確実に値が削除されるように、先に削除オペレーションを登録してから、値の書き込みを行う
        await ref.onDisconnect().remove();
        return ref.set(value);
    }

    async delete(targetKey: Key): Promise<void> {
        await this.deleteMulti([targetKey]);
    }

    async deleteMulti(targetKeys: Key[]): Promise<void> {
        if (targetKeys.length === 0) return;

        const ref = this._parentRef();
        const updates: Record<string, null> = {};
        targetKeys.forEach((key) => {
            updates[key.toUrlSafeString()] = null;
        });
        await ref.update(updates);
    }
}

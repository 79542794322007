import debounce from 'lodash/debounce';
import { ClientMouseCursorSet, ClientMouseCursor } from '@view-model/domain/mouse-cursor';
import { UserPublicProfile } from '@user/PublicProfile';
import { UserKey } from '@user/domain';
import { ClientId, ViewModelId } from '@schema-common/base';
import { ClientMouseCursorRepository } from '@view-model/infrastructure/mouse-cursor/ClientMouseCursorRepository';

export class ClientMouseCursorPubSub {
    private readonly repository: ClientMouseCursorRepository;
    private currentCursors: ClientMouseCursorSet;

    private readonly debouncedDeleteCursor: (cursorId: string) => void;

    private static readonly cursorDisplayTime = 3000; // ミリ秒

    constructor(
        private readonly clientId: ClientId,
        private readonly user: UserPublicProfile,
        viewModelId: ViewModelId
    ) {
        this.repository = new ClientMouseCursorRepository(viewModelId);
        this.currentCursors = ClientMouseCursorSet.empty();
        this.debouncedDeleteCursor = debounce(
            (cursorId: string) => this.repository.delete(cursorId),
            ClientMouseCursorPubSub.cursorDisplayTime
        );
    }

    async publish(x: number, y: number): Promise<void> {
        const cursor = new ClientMouseCursor(
            this.clientId,
            x,
            y,
            UserKey.buildFromId(this.user.id),
            this.user.name,
            this.user.imageUrl
        );
        this.repository.save(cursor).then();
        this.debouncedDeleteCursor(cursor.id);
    }

    async subscribe(callback: (cursors: ClientMouseCursorSet) => void): Promise<void> {
        this.currentCursors = ClientMouseCursorSet.empty();
        // 削除のロジックがうまく働かなかった場合に古いカーソルが残ってしまう可能性があるため、このタイミングでクリーンアップする
        await this.repository.deleteOldCursors();

        this.repository.onAdded((cursor: ClientMouseCursor) => {
            if (cursor.id === this.clientId) return;

            this.currentCursors = this.currentCursors.add(cursor);
            callback(this.currentCursors);
        });

        this.repository.onRemoved((cursorId: string) => {
            if (cursorId === this.clientId) return;

            this.currentCursors = this.currentCursors.remove(cursorId);
            callback(this.currentCursors);
        });

        this.repository.onChanged((cursor: ClientMouseCursor) => {
            if (cursor.id === this.clientId) return;

            this.currentCursors = this.currentCursors.add(cursor);
            callback(this.currentCursors);
        });
    }

    unsubscribe(): void {
        this.repository.offChanged();
        this.repository.offRemoved();
        this.repository.offAdded();
    }
}

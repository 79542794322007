import { ViewModelId } from '@schema-common/base';
import { useSnapshot } from '@framework/hooks';
import { RTDBPath } from '@framework/repository';
import { ViewJSON } from '@schema-app/view-model/contents/{viewModelId}/views/{viewId}/ViewJSON';
import { ViewCollection, ViewEntity } from '@view-model/domain/view';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { Position } from '@view-model/models/common/types/ui';
import { useEffect } from 'react';
import { viewModelPageContentAtom } from '@user/pages/ViewModelPage/viewModelPageContentAtom';
import { getDefaultStore } from 'jotai';
import { ViewCollectionContents } from '@view-model/domain/view/ViewCollectionContents';

interface Props {
    viewModelId: ViewModelId;
}

export const ViewModelPageContentsLoader = ({ viewModelId }: Props) => {
    const [viewPositionSet] = useSnapshot({
        path: RTDBPath.View.positionsPath(viewModelId),
        load({ getChildValues }) {
            return PositionSet.load(getChildValues() as Record<string, Position> | null);
        },
    });

    const [viewEntities] = useSnapshot({
        path: RTDBPath.View.viewsPath(viewModelId),
        load: ({ getChildValues }) => {
            return Object.values(getChildValues() as Record<string, ViewJSON>).map((json) => ViewEntity.load(json));
        },
    });

    useEffect(() => {
        return () => {
            getDefaultStore().set(viewModelPageContentAtom, (prev) => {
                const newRecord = { ...prev.viewContentsRecord };
                delete newRecord[viewModelId];
                return { viewContentsRecord: newRecord };
            });
        };
    }, [viewModelId]);

    useEffect(() => {
        if (!viewEntities || !viewPositionSet) {
            return;
        }

        const viewContents = new ViewCollectionContents(new ViewCollection(viewEntities), viewPositionSet);

        getDefaultStore().set(viewModelPageContentAtom, (prev) => ({
            viewContentsRecord: { ...prev.viewContentsRecord, [viewModelId]: viewContents },
        }));
    }, [viewEntities, viewPositionSet, viewModelId]);

    return null;
};

import { memo, useMemo } from 'react';
import { ButtonGroup } from './ButtonGroup';
import { Button } from './Button';
import { faComment, faFileAlt, faNoteSticky, faWindowMaximize } from '@fortawesome/free-regular-svg-icons';
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { useViewMenuHandlers } from '../../hooks';
import { ViewCollectionContents } from '@view-model/domain/view/ViewCollectionContents';
import { ModelCollection } from '@view-model/domain/model';
import { ModelId, ViewId } from '@schema-common/base';
import { StickyModelContentsOperation } from '@view-model/adapter';
import { Point } from '@view-model/models/common/basic';
import { isEqual } from 'lodash';

const disabledMessage = 'ビューを選択してください | Select view';

// NOTE: この型を変更した場合には、メモ化のために propsAreEqual関数も変更する必要あり
type Props = {
    readonly: boolean;
    selectedViewIds: Set<ViewId>;
    viewContents: ViewCollectionContents;
    models: ModelCollection;
    operations: Record<ModelId, StickyModelContentsOperation>;
    handleCreatingCommentAdd: (modelId: ModelId, position: Point) => void;
    onAddViewClicked: () => void;
};

const propsAreEqual = (prev: Props, next: Props) => {
    return (
        prev.readonly === next.readonly &&
        isEqual(prev.selectedViewIds, next.selectedViewIds) &&
        prev.viewContents === next.viewContents &&
        prev.models === next.models &&
        prev.operations === next.operations &&
        prev.handleCreatingCommentAdd === next.handleCreatingCommentAdd &&
        prev.onAddViewClicked === next.onAddViewClicked
    );
};

const ViewModelToolbarFn: React.FC<Props> = ({
    readonly,
    selectedViewIds,
    viewContents,
    models,
    operations,
    handleCreatingCommentAdd,
    onAddViewClicked,
}: Props) => {
    const isSingleViewSelected = useMemo(() => !!(selectedViewIds.size === 1), [selectedViewIds]);
    const { handleAddNode, handleAddZone, handleAddDescriptionPanel, handleAddComment } = useViewMenuHandlers(
        readonly,
        selectedViewIds,
        viewContents,
        models,
        operations,
        handleCreatingCommentAdd
    );

    return (
        <div className="pointer-events-none absolute left-0 top-0 ml-4 flex h-full flex-col justify-center">
            <div className="flex flex-col justify-center">
                <ButtonGroup>
                    <Button
                        onClick={handleAddNode}
                        tooltip={isSingleViewSelected ? 'ノードを追加 | Add node' : disabledMessage}
                        icon={faNoteSticky}
                        className="rounded-t-lg"
                        disabled={!isSingleViewSelected}
                    />
                    <Button
                        onClick={handleAddZone}
                        tooltip={isSingleViewSelected ? 'ゾーンを追加 | Add zone' : disabledMessage}
                        icon={faVectorSquare}
                        disabled={!isSingleViewSelected}
                    />
                    <Button
                        onClick={handleAddDescriptionPanel}
                        tooltip={isSingleViewSelected ? '説明パネルを追加 | Add document panel' : disabledMessage}
                        icon={faFileAlt}
                        disabled={!isSingleViewSelected}
                    />
                    <Button
                        onClick={handleAddComment}
                        tooltip={isSingleViewSelected ? 'コメントを追加 | Add comment' : disabledMessage}
                        icon={faComment}
                        className="rounded-b-lg"
                        disabled={!isSingleViewSelected}
                    />
                </ButtonGroup>
                <ButtonGroup className="mt-2">
                    <Button
                        onClick={onAddViewClicked}
                        tooltip="ビューを追加 | Add view"
                        icon={faWindowMaximize}
                        className="rounded-lg"
                    />
                </ButtonGroup>
            </div>
        </div>
    );
};

export const ViewModelToolbar = memo(ViewModelToolbarFn, propsAreEqual);

import { useEffect, useMemo, useRef, useState } from 'react';
import { MainMenuBox, MainMenuIcon, MenuLayout } from '@model-framework/menu';
import { useOnEnterCallback, useTextSelectable } from '@view-model/models/common/hooks';
import { WarningPopup } from '@model-framework/ui/WarningPopup';
import { Rect, Size } from '@view-model/models/common/basic';
import { faLink } from '@fortawesome/free-solid-svg-icons';

type Props = {
    menuSize: number;
    onMenuClicked(): void;
    onClose(): void;
    isOpen: boolean;
    initialURL: string | null;
    onSave(url: string | null): void;
    text: string;
};

const MENU_HEIGHT = 80;
const TOTAL_MENU_SIZE = new Size(900, MENU_HEIGHT);
const INPUT_AREA_SIZE = new Size(480, MENU_HEIGHT);
const BUTTON_AREA_SIZE = new Size(TOTAL_MENU_SIZE.width - INPUT_AREA_SIZE.width, MENU_HEIGHT);

export const HyperLinkMenuButton: React.FC<Props> = ({
    menuSize,
    onMenuClicked,
    onClose,
    isOpen,
    initialURL,
    onSave,
    text,
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [url, setURL] = useState<string | null>(initialURL);
    const [isValid, setIsValid] = useState<boolean>(true);

    useOnEnterCallback(inputRef, false, () => {
        validateURL(url);
        if (isValid) {
            onSave(url);
            onClose();
        }
    });

    const validateURL = (url: string | null) => {
        if (!url) {
            setIsValid(false);
            return;
        }

        try {
            const parsedURL = new URL(url);
            setIsValid(parsedURL.protocol === 'http:' || parsedURL.protocol === 'https:');
        } catch (_) {
            setIsValid(false);
        }
    };

    const handleChangeURL = (e: React.ChangeEvent<HTMLInputElement>) => {
        const urlSt = e.target.value;
        setURL(urlSt);
        validateURL(urlSt);
    };

    const handleSaveURL = (url: string | null) => {
        onSave(url);
        onClose();
    };

    const handleClearURL = () => {
        if (inputRef.current?.value) {
            setURL(null);
        }
        onSave(null);
        onClose();
    };

    const handleFocus = () => {
        inputRef.current?.focus();
    };

    const canNotSaveURL = !isValid || !url;
    const canNotClearURL = !initialURL;

    useEffect(() => {
        inputRef.current?.focus();
    }, [isOpen]);

    useTextSelectable(inputRef, isOpen);

    const position = MenuLayout.subMenuListTopLeft(menuSize);
    const parentRect = useMemo(() => new Rect(position, INPUT_AREA_SIZE), [position]);

    return (
        <>
            <MainMenuBox menuSize={menuSize} isSelected={isOpen} onClick={onMenuClicked} text={text} />
            <MainMenuIcon icon={faLink} menuSize={menuSize} />
            {isOpen && (
                <g transform={position.toSVGTranslate()}>
                    <rect
                        className="stroke-gray-300"
                        fill={'white'}
                        strokeWidth={2}
                        width={TOTAL_MENU_SIZE.width}
                        height={TOTAL_MENU_SIZE.height}
                    />
                    <foreignObject
                        width={INPUT_AREA_SIZE.width + BUTTON_AREA_SIZE.width}
                        height={INPUT_AREA_SIZE.height}
                    >
                        <div className="flex h-full flex-nowrap p-3">
                            <input
                                className="m-0 mr-2 flex-auto shrink grow-0 rounded px-3 py-2 text-[30px] leading-tight text-gray-700 shadow"
                                style={{ width: INPUT_AREA_SIZE.width + 'px' }}
                                defaultValue={url || ''}
                                placeholder="リンク | Link"
                                type="text"
                                ref={inputRef}
                                onChange={handleChangeURL}
                                onFocus={handleFocus}
                            />
                            <button
                                className="m-0 w-16 flex-auto cursor-pointer p-0 text-[30px] hover:bg-gray-300 disabled:cursor-not-allowed disabled:text-neutral-500"
                                onClick={() => handleSaveURL(url)}
                                disabled={canNotSaveURL}
                            >
                                保存 | Save
                            </button>
                            <button
                                className="m-0 w-16 flex-auto cursor-pointer p-0 text-[30px] hover:bg-gray-300 disabled:cursor-not-allowed disabled:text-neutral-500"
                                onClick={handleClearURL}
                                disabled={canNotClearURL}
                            >
                                削除 | Delete
                            </button>
                        </div>
                    </foreignObject>
                    {!isValid && (
                        <WarningPopup placement={'bottom'} parentRect={parentRect}>
                            リンクが正しくありません | Invalid link
                        </WarningPopup>
                    )}
                </g>
            )}
        </>
    );
};

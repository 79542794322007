import { DescriptionPanelId } from '@schema-common/base';
import { SelectedItemSet } from '@view-model/models/common/basic';

export class DescriptionPanelDisplayOrder {
    constructor(private readonly ids: DescriptionPanelId[]) {}

    /**
     * 選択状態を考慮した説明パネルの表示順をId配列として返す
     *
     * 説明パネルが単一選択されている場合には、その選択されている要素が最前面に表示されるように調整する。
     *
     * @param selectedItems
     */
    buildDisplayOrder(selectedItems: SelectedItemSet<DescriptionPanelId>): DescriptionPanelId[] {
        // 説明パネルが単一選択されていない場合には、もとの説明パネルの順序のまま表示する
        const singleSelectedId = this.getSingleSelectedPanelId(selectedItems);
        if (!singleSelectedId) {
            return this.ids;
        }

        return [...this.ids.filter((id) => singleSelectedId !== id), singleSelectedId];
    }

    /**
     * 単一選択されている説明パネルのIdを返す
     *
     * 複数要素が選択されている場合や、選択対象が説明パネル以外の場合には undefined を返す
     *
     * @param selectedItems
     * @private
     */
    private getSingleSelectedPanelId(
        selectedItems: SelectedItemSet<DescriptionPanelId>
    ): DescriptionPanelId | undefined {
        if (!selectedItems.isSingleSelected()) {
            return;
        }

        // 単一選択されている要素のIdが this.ids に含まれている == 説明パネルが単一選択されている
        const [selectedId] = selectedItems.getSelectedItems();
        if (this.ids.includes(selectedId)) {
            return selectedId;
        }

        return;
    }
}

import { useEffect, useRef } from 'react';

/**
 * 前回のstateの値を取得するフック
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * @param value
 * @param initialValue
 */
export const usePrevious = <T>(value: T, initialValue?: T): T | undefined => {
    const ref = useRef<T | undefined>(initialValue);
    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

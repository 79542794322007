import { Rect } from '@view-model/models/common/basic';
import { Viewer } from '../Viewer';
import { ViewerPopupContent } from './ViewerPopupContent';

type Props = {
    viewers: Viewer[];
    setClientViewBox: (viewBox: Rect) => void;
};

export const ViewerPopup: React.ForwardRefRenderFunction<HTMLDivElement, Props> = ({
    viewers,
    setClientViewBox,
}: Props) => {
    return (
        <>
            <div className="cursor-default px-3 py-2">合計閲覧者{viewers.length}名</div>
            {viewers.map((viewer) => (
                <ViewerPopupContent key={viewer.userId} viewer={viewer} setClientViewBox={setClientViewBox} />
            ))}
        </>
    );
};

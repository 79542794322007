import { usePopupRef } from '@framework/hooks';
import { classNames } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faFolderPlus, faPaste, faPlus } from '@fortawesome/free-solid-svg-icons';

type Props = {
    onCreateFolder(): void;
    onCreateViewModel(): void;
    onPaste(): void;
};

export const MenuButtons: React.FC<Props> = ({ onCreateFolder, onCreateViewModel, onPaste }: Props) => {
    const { isOpen, popupRef, handleClose, handleOpen } = usePopupRef<HTMLDivElement>(false);

    const handleCreateFolder = () => {
        onCreateFolder();
        handleClose();
    };

    const handlePaste = () => {
        onPaste();
        handleClose();
    };

    return (
        <div className="relative">
            <div className="inline-flex">
                <button
                    className="rounded-l border border-solid border-brand bg-white py-2 pl-4 pr-3 font-bold text-brand hover:bg-gray-200"
                    onClick={onCreateViewModel}
                >
                    <span className="inline-block w-6 text-center">
                        <FontAwesomeIcon icon={faPlus} />
                    </span>
                    新規ビューモデル
                </button>
                <button
                    className="rounded-r border-y border-r border-solid border-brand bg-white px-3 py-2 font-bold text-brand hover:bg-gray-200"
                    onClick={handleOpen}
                >
                    <FontAwesomeIcon icon={faEllipsisV} />
                </button>
            </div>

            <div
                className={classNames(
                    'absolute right-0 z-10 w-64 rounded-lg border border-gray-500 bg-white py-1 shadow-lg',
                    isOpen ? 'block' : 'hidden'
                )}
                ref={popupRef}
            >
                <button
                    className="block w-full px-2 py-1 text-left text-gray-800 hover:bg-indigo-500 hover:text-white"
                    onClick={handlePaste}
                >
                    <span className="inline-block w-8 text-center">
                        <FontAwesomeIcon icon={faPaste} />
                    </span>
                    ビューモデルをペースト
                </button>
                <button
                    className="block w-full px-2 py-1 text-left text-gray-800 hover:bg-indigo-500 hover:text-white"
                    onClick={handleCreateFolder}
                >
                    <span className="inline-block w-8 text-center">
                        <FontAwesomeIcon icon={faFolderPlus} />
                    </span>
                    新規フォルダ
                </button>
            </div>
        </div>
    );
};

import { difference } from 'lodash';
import { NodeId, StickyZoneId } from '@schema-common/base';
import { LinkPool } from './LinkPool';
import { orderItemIds } from './orderItemIds';

export type DisplayOrderTreeZoneJSON = {
    id: StickyZoneId;
    nodeIds: NodeId[];
    zones: DisplayOrderTreeZoneJSON[];
};

export class DisplayOrderTreeZone {
    public readonly id: StickyZoneId;
    public readonly nodeIds: NodeId[];
    public readonly zones: DisplayOrderTreeZone[];

    constructor(id: StickyZoneId, nodeIds: NodeId[] = [], zones: DisplayOrderTreeZone[] = []) {
        this.id = id;
        this.nodeIds = nodeIds;
        this.zones = zones;
    }

    private addNode(nodeId: NodeId): DisplayOrderTreeZone {
        return new DisplayOrderTreeZone(this.id, this.nodeIds.concat(nodeId), this.zones);
    }

    private addZone(zoneId: StickyZoneId): DisplayOrderTreeZone {
        const zone = new DisplayOrderTreeZone(zoneId);
        return new DisplayOrderTreeZone(this.id, this.nodeIds, this.zones.concat(zone));
    }

    addNodeToZone(nodeId: NodeId, zoneId: StickyZoneId): DisplayOrderTreeZone {
        if (zoneId === this.id) {
            return this.addNode(nodeId);
        }

        const zones = this.zones.map((zone) => zone.addNodeToZone(nodeId, zoneId));
        return new DisplayOrderTreeZone(this.id, this.nodeIds, zones);
    }

    addZoneToZone(childZoneId: StickyZoneId, parentZoneId: StickyZoneId): DisplayOrderTreeZone {
        if (parentZoneId === this.id) {
            return this.addZone(childZoneId);
        }

        const zones = this.zones.map((zone) => zone.addZoneToZone(childZoneId, parentZoneId));
        return new DisplayOrderTreeZone(this.id, this.nodeIds, zones);
    }

    /**
     * 指定されたidの親ゾーンリストを返す
     * @param id
     */
    ancestors(id: NodeId | StickyZoneId): StickyZoneId[] {
        if (this.nodeIds.includes(id)) return [this.id];
        if (this.zones.map((zone) => zone.id).includes(id)) return [this.id];

        for (const zone of this.zones) {
            const result = zone.ancestors(id);
            if (result.length !== 0) return [this.id, ...result];
        }

        return [];
    }

    /**
     * 子孫の中からゾーンを探して返します。見つからなかった場合はnullが返ります。
     * @param id
     */
    findZoneFromDescendants(id: NodeId | StickyZoneId): DisplayOrderTreeZone | null {
        if (this.nodeIds.includes(id)) return null;

        for (const childZone of this.zones) {
            if (childZone.id === id) return childZone;

            const zone = childZone.findZoneFromDescendants(id);
            if (zone) return zone;
        }

        return null;
    }

    /**
     * このゾーンに含まれるすべての子孫ゾーンを返します。
     */
    descendantZones(): DisplayOrderTreeZone[] {
        return this.zones.reduce((resultZones, zone) => {
            return [...resultZones, ...zone.descendantZones()];
        }, this.zones);
    }

    /**
     * このゾーンに含まれるすべての子孫ノードのIDを返します。
     */
    descendantNodeIds(): NodeId[] {
        return this.zones.reduce((ids, zone) => {
            return [...ids, ...zone.descendantNodeIds()];
        }, this.nodeIds);
    }

    /**
     * このゾーンに含まれるすべての子孫要素のIDを返します。
     * （自身のIDは除く）
     */
    descendantIds(): (NodeId | StickyZoneId)[] {
        return this.zones.reduce((ids, zone) => {
            return ids.concat([zone.id, ...zone.descendantIds()]);
        }, this.nodeIds);
    }

    order(linkPool: LinkPool, shouldExcludedNodeIds: NodeId[], shouldExcludedZoneIds: StickyZoneId[]): string[] {
        const includedNodeIds = difference(this.nodeIds, shouldExcludedNodeIds);
        const includedZones = this.zones.filter((zone) => !shouldExcludedZoneIds.includes(zone.id));

        const orderedItemIds = orderItemIds(
            linkPool,
            includedZones,
            includedNodeIds,
            shouldExcludedZoneIds,
            shouldExcludedNodeIds
        );

        return [
            // ゾーン自身が最背面
            this.id,
            ...orderedItemIds,
        ];
    }

    dump(): DisplayOrderTreeZoneJSON {
        const { id, nodeIds, zones } = this;
        return {
            id,
            nodeIds: [...nodeIds],
            zones: zones.map((zone) => zone.dump()),
        };
    }

    static load(dump: DisplayOrderTreeZoneJSON): DisplayOrderTreeZone {
        const { id, nodeIds, zones } = dump;
        return new DisplayOrderTreeZone(
            id,
            nodeIds,
            zones.map((zone) => this.load(zone))
        );
    }

    cloneNew(newIdMap: Record<NodeId | StickyZoneId, NodeId | StickyZoneId>): DisplayOrderTreeZone {
        const { id, nodeIds, zones } = this;
        const newNodeIds = nodeIds.map((id) => newIdMap[id]);
        const newZones = zones.map((zone) => zone.cloneNew(newIdMap));
        return new DisplayOrderTreeZone(newIdMap[id], newNodeIds, newZones);
    }

    isEqual(other: DisplayOrderTreeZone): boolean {
        return (
            this.id === other.id &&
            this.nodeIds.length === other.nodeIds.length &&
            this.zones.length === other.zones.length &&
            this.nodeIds.every((nodeId, index) => nodeId === other.nodeIds[index]) &&
            this.zones.every((zone, index) => zone.isEqual(other.zones[index]))
        );
    }

    /**
     * 渡されたノードID、ゾーンIDに含まれるノードID、ツリーゾーンを返します。
     *
     * このゾーンの子ゾーンに対しても再起的に呼び出し、もしこのゾーンが渡されたゾーンIDに含まれていた場合、
     * このツリーゾーンのコピー（ただし、渡されたノードID、ゾーンIDから構成される）を返します。
     *
     * このゾーンが渡されたゾーンIDに含まれていなかった場合、このツリーゾーンの直接の子ノードと、
     * 子ゾーンを再起的に呼び出して集めた結果を一つにまとめて返します。
     *
     * @param selectingIds
     */
    select(selectingIds: (NodeId | StickyZoneId)[]): [NodeId[], DisplayOrderTreeZone[]] {
        const partialNodeIds = this.nodeIds.filter((id) => selectingIds.includes(id));
        const partialZones: DisplayOrderTreeZone[] = [];

        this.zones.map((zone) => {
            const [nodeIds, zones] = zone.select(selectingIds);
            partialNodeIds.push(...nodeIds);
            partialZones.push(...zones);
        });

        if (selectingIds.includes(this.id)) {
            // 自身が選択されている場合は、子供を部分要素に置き換えて自分自身を返す
            return [[], [new DisplayOrderTreeZone(this.id, partialNodeIds, partialZones)]];
        } else {
            // 自身が選択されていない場合は、収集した部分要素をそのまま受け渡す
            return [partialNodeIds, partialZones];
        }
    }

    /**
     * 指定されたノードがこのツリーに含まれているかどうかを返す
     * @param nodeId
     */
    hasNode(nodeId: NodeId): boolean {
        const { nodeIds, zones } = this;
        return nodeIds.includes(nodeId) || zones.some((zone) => zone.hasNode(nodeId));
    }

    /**
     * 指定されたゾーンがこのツリーに含まれているかどうかを返す
     * @param zoneId
     */
    hasZone(zoneId: StickyZoneId): boolean {
        const { zones } = this;
        return zones.some((zone) => zone.id === zoneId || zone.hasZone(zoneId));
    }

    /**
     * このゾーンに含まれる全てのノードIDを返します。
     */
    allNodeIds(): NodeId[] {
        const { nodeIds, zones } = this;

        return zones.reduce((ids, zone) => [...ids, ...zone.allNodeIds()], nodeIds);
    }

    /**
     * このゾーンに含まれる全てのゾーンIDを返します。
     */
    allZoneIds(): StickyZoneId[] {
        const { id, zones } = this;

        return zones.reduce((ids, zone) => [...ids, ...zone.allZoneIds()], [id]);
    }

    hasDescendant(id: NodeId | StickyZoneId): boolean {
        return this.hasNode(id) || this.hasZone(id);
    }

    aggregateDescendantIds(selectingIds: Set<NodeId | StickyZoneId>, aggregatedIds: Set<NodeId | StickyZoneId>): void {
        // 注意: 再帰的アルゴリズムの簡潔さのため結果をミュータブルなSetオブジェクトに蓄積している
        if (aggregatedIds.has(this.id)) return;

        if (selectingIds.has(this.id)) {
            aggregatedIds.add(this.id);
            this.descendantIds().forEach((id) => aggregatedIds.add(id));
        } else {
            this.nodeIds.filter((id) => selectingIds.has(id)).forEach((id) => aggregatedIds.add(id));
            this.zones.forEach((zone) => zone.aggregateDescendantIds(selectingIds, aggregatedIds));
        }
    }

    aggregateDescendantZoneIds(selectingIds: Set<NodeId | StickyZoneId>, aggregatedIds: Set<StickyZoneId>): void {
        // 注意: 再帰的アルゴリズムの簡潔さのため結果をミュータブルなSetオブジェクトに蓄積している
        if (aggregatedIds.has(this.id)) return;

        if (selectingIds.has(this.id)) {
            aggregatedIds.add(this.id);
            this.descendantZones().forEach((zone) => aggregatedIds.add(zone.id));
        } else {
            this.zones.forEach((zone) => zone.aggregateDescendantZoneIds(selectingIds, aggregatedIds));
        }
    }

    backToFrontZone(): StickyZoneId[] {
        return [this.id, ...this.zones.reduce<StickyZoneId[]>((acc, zone) => acc.concat(zone.backToFrontZone()), [])];
    }

    findParentZoneIdOf(nodeId: NodeId): StickyZoneId | null {
        if (this.nodeIds.some((id) => id === nodeId)) return this.id;

        for (const zone of this.zones) {
            const parentZoneId = zone.findParentZoneIdOf(nodeId);
            if (parentZoneId) return parentZoneId;
        }

        return null;
    }
}

import { useState } from 'react';
import { CircleResizeHandle, RectResizeHandle } from './ResizeHandle';
import { StickyZone } from '@view-model/models/sticky/StickyZoneView';
import { useHandler } from '@framework/hooks';
import { useStickyZoneContext } from '@view-model/models/sticky/StickyZoneView/adapter';
import { Point, Size } from '@view-model/models/common/basic';
import { ModelLayout } from '@view-model/models/sticky/layout';

type Position = {
    x: number;
    y: number;
};

type SizeType = {
    width: number;
    height: number;
};

type Props = {
    stickyZone: StickyZone;
    position: Position;
    minSize?: SizeType;
};

type State = {
    dragging: boolean;

    // ドラッグ中はこの値を用いる
    x: number;
    y: number;
    width: number;
    height: number;

    // ドラッグ開始時の値を保持する
    prev: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
};

export const StickyZoneResizer: React.FC<Props> = ({
    stickyZone,
    position,
    minSize = { width: 256, height: 256 },
}: Props) => {
    const zoneAdapter = useStickyZoneContext();

    const [state, setState] = useState<State>({
        dragging: false,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        prev: {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        },
    });

    const handleResizeStart = useHandler((): void => {
        setState((prev) => ({
            ...prev,
            dragging: true,
            x: position.x,
            y: position.y,
            width: stickyZone.size.width,
            height: stickyZone.size.height,
            prev: {
                x: position.x,
                y: position.y,
                width: stickyZone.size.width,
                height: stickyZone.size.height,
            },
        }));
    });

    const handleResize = ({ x, y, width, height }: Partial<Position & SizeType>): void => {
        setState((prev) => ({
            ...prev,
            x: x ?? prev.x,
            y: y ?? prev.y,
            width: width ?? prev.width,
            height: height ?? prev.height,
        }));

        const position = { x: state.x, y: state.y };
        const size = {
            width: state.width,
            height: state.height,
        };
        zoneAdapter.handleResize(Point.fromPosition(position), Size.load(size));
    };

    const handleResizeEnd = () => {
        const position = ModelLayout.snapPosition({ x: state.x, y: state.y });
        const size = {
            width: ModelLayout.snapX(state.width),
            height: ModelLayout.snapY(state.height),
        };

        const prevPosition = { x: state.prev.x, y: state.prev.y };
        const prevSize = { width: state.prev.width, height: state.prev.height };

        zoneAdapter.handleResizeEnd(
            Point.fromPosition(prevPosition),
            Size.load(prevSize),
            Point.fromPosition(position),
            Size.load(size)
        );

        setState((prev) => ({
            ...prev,
            dragging: false,
        }));
    };

    const currentSize: SizeType = stickyZone.size;
    const resizerLineSize = 6;
    const resizerContainerStrokeSize = 24;
    const resizerCircleSize = 12;
    const circleOffset = resizerLineSize / 2; // 四隅の丸を線の幅の半分だけ外側に出す

    return (
        // NOTE: RectResizeHandleなどで使っているd3-dragの座標系を正しく計算するために座標系をリセットしてあげる
        <g transform={`translate(${-position.x}, ${-position.y})`}>
            {state.dragging && (
                <rect
                    x={position.x}
                    y={position.y}
                    width={currentSize.width}
                    height={currentSize.height}
                    fill="#eee"
                    fillOpacity="0.7"
                />
            )}

            {/* 右側 */}
            <RectResizeHandle
                width={resizerLineSize}
                height={currentSize.height}
                containerWidth={resizerContainerStrokeSize}
                containerHeight={currentSize.height}
                offsetX={position.x + currentSize.width + resizerLineSize / 2}
                offsetY={position.y + currentSize.height / 2}
                onDragStart={handleResizeStart}
                onDrag={(event) => {
                    const dx = event.x - event.dragStartX;
                    const width = Math.max(state.prev.width + dx, minSize.width);

                    handleResize({
                        width,
                    });
                }}
                onDragEnd={handleResizeEnd}
                cursor={'ew-resize'}
            />

            {/* 左側 */}
            <RectResizeHandle
                width={resizerLineSize}
                height={currentSize.height}
                containerWidth={resizerContainerStrokeSize}
                containerHeight={currentSize.height}
                offsetX={position.x + -resizerLineSize / 2}
                offsetY={position.y + currentSize.height / 2}
                onDragStart={handleResizeStart}
                onDrag={(event) => {
                    const dx = event.x - event.dragStartX;
                    const x = Math.min(state.prev.x + dx, state.prev.x + state.prev.width - minSize.width);
                    const width = Math.max(state.prev.width - dx, minSize.width);

                    handleResize({
                        x,
                        width,
                    });
                }}
                onDragEnd={handleResizeEnd}
                cursor={'ew-resize'}
            />

            {/* 上側 */}
            <RectResizeHandle
                width={currentSize.width}
                height={resizerLineSize}
                containerWidth={currentSize.width}
                containerHeight={resizerContainerStrokeSize}
                offsetX={position.x + currentSize.width / 2}
                offsetY={position.y + -resizerLineSize / 2}
                onDragStart={handleResizeStart}
                onDrag={(event) => {
                    const dy = event.y - event.dragStartY;
                    const y = Math.min(state.prev.y + dy, state.prev.y + state.prev.height - minSize?.height);
                    const height = Math.max(state.prev.height - dy, minSize?.height);

                    handleResize({
                        y,
                        height,
                    });
                }}
                onDragEnd={handleResizeEnd}
                cursor={'ns-resize'}
            />

            {/* 下側 */}
            <RectResizeHandle
                width={currentSize.width}
                height={resizerLineSize}
                containerWidth={currentSize.width}
                containerHeight={resizerContainerStrokeSize}
                offsetX={position.x + currentSize.width / 2}
                offsetY={position.y + currentSize.height + resizerLineSize / 2}
                onDragStart={handleResizeStart}
                onDrag={(event) => {
                    const dy = event.y - event.dragStartY;
                    const height = Math.max(state.prev.height + dy, minSize?.height);

                    handleResize({
                        height,
                    });
                }}
                onDragEnd={handleResizeEnd}
                cursor={'ns-resize'}
            />

            {/* 右上 */}
            <CircleResizeHandle
                r={resizerCircleSize}
                offsetX={position.x + currentSize.width + circleOffset}
                offsetY={position.y + -circleOffset}
                cursor={'nesw-resize'}
                onDragStart={handleResizeStart}
                onDrag={(event) => {
                    const dy = event.y - event.dragStartY;
                    const y = Math.min(state.prev.y + dy, state.prev.y + state.prev.height - minSize?.height);
                    const height = Math.max(state.prev.height - dy, minSize?.height);
                    const dx = event.x - event.dragStartX;
                    const width = Math.max(state.prev.width + dx, minSize.width);

                    handleResize({
                        width,
                        y,
                        height,
                    });
                }}
                onDragEnd={handleResizeEnd}
            />

            {/* 右下 */}
            <CircleResizeHandle
                r={resizerCircleSize}
                offsetX={position.x + currentSize.width + circleOffset}
                offsetY={position.y + currentSize.height + circleOffset}
                cursor={'nwse-resize'}
                onDragStart={handleResizeStart}
                onDrag={(event) => {
                    const dy = event.y - event.dragStartY;
                    const height = Math.max(state.prev.height + dy, minSize?.height);
                    const dx = event.x - event.dragStartX;
                    const width = Math.max(state.prev.width + dx, minSize.width);

                    handleResize({
                        width,
                        height,
                    });
                }}
                onDragEnd={handleResizeEnd}
            />

            {/* 左下 */}
            <CircleResizeHandle
                r={resizerCircleSize}
                offsetX={position.x + -circleOffset}
                offsetY={position.y + currentSize.height + circleOffset}
                cursor={'nesw-resize'}
                onDragStart={handleResizeStart}
                onDrag={(event) => {
                    const dy = event.y - event.dragStartY;
                    const height = Math.max(state.prev.height + dy, minSize?.height);
                    const dx = event.x - event.dragStartX;
                    const x = Math.min(state.prev.x + dx, state.prev.x + state.prev.width - minSize.width);
                    const width = Math.max(state.prev.width - dx, minSize.width);

                    handleResize({
                        x,
                        width,
                        height,
                    });
                }}
                onDragEnd={handleResizeEnd}
            />

            {/* 左上 */}
            <CircleResizeHandle
                r={resizerCircleSize}
                offsetX={position.x + -circleOffset}
                offsetY={position.y + -circleOffset}
                cursor={'nwse-resize'}
                onDragStart={handleResizeStart}
                onDrag={(event) => {
                    const dy = event.y - event.dragStartY;
                    const y = Math.min(state.prev.y + dy, state.prev.y + state.prev.height - minSize?.height);
                    const height = Math.max(state.prev.height - dy, minSize?.height);
                    const dx = event.x - event.dragStartX;
                    const x = Math.min(state.prev.x + dx, state.prev.x + state.prev.width - minSize.width);
                    const width = Math.max(state.prev.width - dx, minSize.width);

                    handleResize({
                        x,
                        width,
                        y,
                        height,
                    });
                }}
                onDragEnd={handleResizeEnd}
            />
        </g>
    );
};

import { useState, useRef, useEffect } from 'react';
import { useOnEnterCallback, useTextSelectable } from '@view-model/models/common/hooks';
import TextareaAutosize from 'react-textarea-autosize';
import { ForeignObjectAutosize2 } from '@view-model/models/common/components/ForeignObjectAutosize';
import { Size } from '@view-model/models/common/basic/Size';
import { useOtherUserEditingToast } from '../text/editing-user';

type Props = {
    text: string;
    showLabel: boolean;
    editable: boolean;
    otherUserEditing: boolean;
    onChange: (text: string) => void;
    onStartEdit: (text: string) => void;
    onEndEdit: (text: string) => void;
    onSizeChange: (size: Size) => void;
    onTextClick?: () => void;
    minWidth?: number;
};

interface State {
    isInput: boolean;
    text: string;
}

export const LinkTextView: React.FC<Props> = ({
    text,
    showLabel,
    editable,
    otherUserEditing,
    onChange,
    onStartEdit,
    onEndEdit,
    onSizeChange,
    onTextClick,
    minWidth,
}: Props) => {
    const myRef = useRef(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const notifyOtherUserEditingToast = useOtherUserEditingToast();
    const [state, setState] = useState<State>({
        isInput: false,
        text: '',
    });

    useTextSelectable(myRef);
    useTextSelectable(textareaRef);
    useOnEnterCallback(textareaRef, true);

    useEffect(() => {
        if (state.isInput && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [state.isInput]);

    const handleClick = () => {
        if (!editable) return;
        if (otherUserEditing) {
            notifyOtherUserEditingToast();
            return;
        }

        setState((prev) => ({ ...prev, isInput: true, text }));
        onTextClick?.();
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = event.target.value;
        setState((prev) => ({ ...prev, text }));
        onChange(text);
    };

    const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const t = event.target;
        t.select();
        onStartEdit(text);
    };

    const handleBlur = () => {
        setState((prev) => ({ ...prev, isInput: false }));
        onEndEdit(text);
    };

    const nameText = state.isInput ? state.text : text || '';
    const nameLabel = showLabel ? nameText || 'タイトル | Title' : nameText;
    return (
        <ForeignObjectAutosize2 onSizeChange={onSizeChange}>
            <div
                className="w-full whitespace-pre bg-[#EFEFF4] text-center align-middle text-[36px] font-bold"
                onClick={handleClick}
            >
                <div className={state.isInput ? 'block' : 'hidden'}>
                    <TextareaAutosize
                        className="w-full resize-none whitespace-pre bg-gray-200 text-center align-middle text-[36px] font-bold"
                        value={nameText}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder={nameLabel}
                        ref={textareaRef}
                        disabled={!state.isInput}
                    />
                </div>
                <div
                    className={
                        !state.isInput
                            ? 'visible h-auto px-[18px] hover:text-brand'
                            : 'invisible h-0 px-[18px] hover:text-brand'
                    }
                    style={{ minWidth: minWidth ? minWidth : 180 }}
                >
                    {nameLabel}
                </div>
            </div>
        </ForeignObjectAutosize2>
    );
};

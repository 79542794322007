type Props = {
    popupRef: React.RefObject<HTMLDivElement>;
    children: React.ReactNode;
};

export const CommentPopupMenuContainer: React.FC<Props> = ({ popupRef, children }: Props) => {
    return (
        <div ref={popupRef} className="right-0 flex flex-col bg-white text-3xl">
            {children}
        </div>
    );
};

import * as EmailValidator from 'email-validator';

export type EmailParseResult = {
    email: string;
    valid: boolean;
};

export class EmailAddressParser {
    private static readonly Separators = [',', '\t', '\n'];

    /**
     * メールアドレスを含む文字列を引数に受け取り、含まれるメールアドレスとその有効性の一覧を返します。
     *
     * @param text
     * @returns
     */
    static parse(text: string): EmailParseResult[] {
        // 空白文字だけの場合には、空配列を返す
        if (text.trim() === '') {
            return [];
        }

        // 区切り文字が含まれていれば、その区切り文字で分割した部分文字列それぞれについてパース処理を適用する
        for (const separator of this.Separators) {
            if (text.includes(separator)) {
                const parts = text.trim().split(separator);
                return parts.map((part) => this.parse(part)).flat();
            }
        }

        // < と > を含む場合には、ラベル付きのメールアドレスとみなして、メールアドレス部分のみを抽出する
        if (text.includes('<') && text.includes('>')) {
            return this.extractEmailAddress(text.trim());
        }

        // 区切り文字が含まれていない場合には、前後の空白文字を取り除いてから、メールアドレスとしての妥当性をチェックする
        return this.buildSingleResult(text);
    }

    private static buildSingleResult(text: string): EmailParseResult[] {
        const email = text.trim();
        return [
            {
                email,
                valid: EmailValidator.validate(email),
            },
        ];
    }

    /**
     * 引数に指定された文字列の中から、 < と > の間に囲まれた部分文字列を返す。
     * （正規表現に適合しない場合には、元の文字列をそのまま返す）
     */
    private static extractEmailAddress(text: string): EmailParseResult[] {
        const matched = text.match('<([^>]+)>');
        if (!matched) {
            return this.buildSingleResult(text);
        }

        const email = matched[1];
        if (EmailValidator.validate(email)) {
            return this.buildSingleResult(email);
        } else {
            return this.buildSingleResult(text);
        }
    }
}

import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { ModelCommentThreadContainer, ModelCommentThreadRepository } from '@view-model/models/sticky/ModelComment';
import { ViewEntity, ViewCollection } from '@view-model/domain/view';
import { ModelKey } from '@view-model/domain/key';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';
import { isEqual } from 'lodash';
import { ViewModelId } from '@schema-common/base';

export const useFetchCommentThreads = (
    viewModelId: ViewModelId,
    views: ViewCollection
): {
    commentThreads: ModelCommentThreadContainer[];
    firstLoading: boolean;
} => {
    const [commentThreadContainerRecords, setCommentThreadContainerRecords] = useState<
        Record<string, ModelCommentThreadContainer>
    >({});
    const [viewIdRecords, setViewIdRecords] = useState<Record<string, ModelKey>>({});
    const prevViewIdRecords = usePrevious(viewIdRecords);
    const [firstLoading, setFirstLoading] = useState<boolean>(true);

    // モデルの識別子を引数にとり、そのモデル配下のコメントスレッドコンテナーの一覧を返す
    const fetchComments = useCallback(
        async (view: ViewEntity): Promise<ModelCommentThreadContainer[]> => {
            const repo = new ModelCommentThreadRepository(viewModelId, view.modelId);
            const threadCollection = await repo.load();
            return threadCollection.list().map((thread) => new ModelCommentThreadContainer(thread, view.id));
        },
        [viewModelId]
    );

    // 初回取得
    // 初回取得に時間がかかるとローディングスピナーだけが先に表示されるため
    // レンダリング前に初回取得が終了するように useLayoutEffect を使用している
    useLayoutEffect(() => {
        Promise.all(
            views.map(async (view) => {
                return fetchComments(view);
            })
        )
            .then((nestedThreads) => {
                // nestedThreads は ModelCommentThreadContainer[][] なので、これをフラットな配列に変換したい
                const records = nestedThreads
                    .flat()
                    .reduce<typeof commentThreadContainerRecords>((result, modelCommentThread) => {
                        result[modelCommentThread.id] = modelCommentThread;
                        return result;
                    }, {});
                setCommentThreadContainerRecords(records);
            })
            .finally(() => setFirstLoading(false));
    }, [views, fetchComments]);

    // viewの追加・削除時のみ監視の開始・終了を行ないたい
    // そのために、viewModel.viewsではなく、viewのidの増減にのみ応じて監視の開始・終了する必要がある
    // このuseEffectで、viewModel.viewsの変更のうちビューの数の増減にフォーカスする
    useEffect(() => {
        const newViewIdRecords = views
            .map((view) => view)
            .reduce<typeof viewIdRecords>((result, view) => {
                result[view.id] = view.modelKey;
                return result;
            }, {});
        if (!isEqual(newViewIdRecords, prevViewIdRecords)) setViewIdRecords(newViewIdRecords);
    }, [views, prevViewIdRecords]);

    // modelCommentThreadの監視のためのuseEffect
    useEffect(() => {
        if (firstLoading) return;

        const threadRepoRecords = Object.keys(viewIdRecords).reduce<Record<string, ModelCommentThreadRepository>>(
            (result, viewId) => {
                result[viewId] = new ModelCommentThreadRepository(viewModelId, viewIdRecords[viewId].id);
                return result;
            },
            {}
        );

        // 新たに追加されたビューのコメントを監視
        Object.keys(threadRepoRecords).forEach((viewId) => {
            threadRepoRecords[viewId].addListener(
                (modelCommentThread) => {
                    const modelCommentThreadContainer = new ModelCommentThreadContainer(modelCommentThread, viewId);

                    setCommentThreadContainerRecords((prev) => {
                        const newRecords = { ...prev };
                        newRecords[modelCommentThread.id] = modelCommentThreadContainer;
                        return newRecords;
                    });
                },
                (modelCommentThread) => {
                    const modelCommentThreadContainer = new ModelCommentThreadContainer(modelCommentThread, viewId);

                    setCommentThreadContainerRecords((prev) => {
                        const newRecords = { ...prev };
                        newRecords[modelCommentThread.id] = modelCommentThreadContainer;
                        return newRecords;
                    });
                },
                (modelCommentThread) => {
                    setCommentThreadContainerRecords((prev) => {
                        const newRecords = { ...prev };
                        delete newRecords[modelCommentThread.id];
                        return newRecords;
                    });
                }
            );
        });

        return () => {
            Object.values(threadRepoRecords).forEach((threadRepo) => {
                threadRepo.removeListener();
            });
        };
    }, [viewIdRecords, viewModelId, firstLoading]);

    const commentThreads = useMemo(
        () =>
            Object.values(commentThreadContainerRecords)
                .sort(ModelCommentThreadContainer.compareByLastPostAt)
                .reverse(),
        [commentThreadContainerRecords]
    );

    return {
        commentThreads,
        firstLoading,
    };
};

import { useCallback, useMemo } from 'react';
import { ConsistencyLink } from '@view-model/domain/ConsistencyLink';
import { ViewCollectionContents } from '@view-model/domain/view/ViewCollectionContents';

export const CONSISTENCY_LINK_LINE_WIDTH = 16;

type Props = {
    consistencyLink: ConsistencyLink;
    isSelected: boolean;
    onSelectConsistencyLink(id: string | null): void;
    viewContents: ViewCollectionContents;
};

export const ConsistencyLinkView: React.FC<Props> = ({
    consistencyLink,
    isSelected,
    onSelectConsistencyLink,
    viewContents,
}: Props) => {
    const sourcePosition = useMemo(() => {
        return viewContents.getCenterPoint(consistencyLink.from.id);
    }, [viewContents, consistencyLink.from]);
    const targetPosition = useMemo(() => {
        return viewContents.getCenterPoint(consistencyLink.to.id);
    }, [viewContents, consistencyLink.to.id]);

    const handleClick = useCallback(() => {
        if (isSelected) {
            onSelectConsistencyLink(null);
        } else {
            onSelectConsistencyLink(consistencyLink.id);
        }
    }, [consistencyLink.id, isSelected, onSelectConsistencyLink]);

    return (
        <>
            {sourcePosition && targetPosition && (
                <line
                    className={
                        isSelected ? 'stroke-brand hover:stroke-brand-dark' : 'stroke-gray-600 hover:stroke-brand-dark'
                    }
                    strokeWidth={CONSISTENCY_LINK_LINE_WIDTH}
                    x1={sourcePosition.x}
                    y1={sourcePosition.y}
                    x2={targetPosition.x}
                    y2={targetPosition.y}
                    pointerEvents="all"
                    cursor="pointer"
                    onClick={handleClick}
                />
            )}
        </>
    );
};

import { useCallback, useEffect, useState } from 'react';
import { DataSnapshot, RefBuilder } from './RefBuilder';

type PrimitiveValueType = string | number | boolean | null;

/**
 * RTDB上の指定pathに保存されたプリミティブ値(テキスト、数値、真偽値)を監視するカスタムフックです。
 * @param path
 * @returns
 */
export const usePrimitiveValueListener = <T extends PrimitiveValueType>(
    path: string
): [T | null, (newValue: T | null) => Promise<void>] => {
    const [value, setValue] = useState<T | null>(null);

    useEffect(() => {
        const ref = RefBuilder.ref(path);
        const callback = (snapshot: DataSnapshot) => {
            setValue(snapshot.val());
        };

        ref.on('value', callback);
        return () => ref.off('value', callback);
    }, [path]);

    const updater = useCallback(
        async (newValue: T | null) => {
            const ref = RefBuilder.ref(path);
            await ref.set(newValue);
        },
        [path]
    );

    return [value, updater];
};

import { useEffect, useRef } from 'react';

type Props = {
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    onBlur?(): void;
    onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
    value?: string;
    type: string;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    autoSelect?: boolean;
};

export const Input: React.FC<Props> = ({
    onChange,
    onBlur,
    onKeyDown,
    value,
    type,
    placeholder,
    className,
    disabled,
    autoFocus,
    autoSelect,
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current && autoSelect) {
            inputRef.current.select();
        }
    }, [autoSelect]);
    return (
        <input
            ref={inputRef}
            value={value}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            className={[
                'appearance-none',
                'border',
                'rounded',
                'w-full',
                'py-2',
                'px-3',
                'text-gray-700',
                'leading-tight',
                'focus:outline-none',
                'focus:ring',
                className,
            ].join(' ')}
            disabled={disabled}
            autoFocus={autoFocus}
        />
    );
};

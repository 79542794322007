import Victor from 'victor';
import { INodeShape } from './INodeShape';
import { Rect } from '@view-model/models/common/basic/Rect';

type RectShapeAttributes = {
    width: number;
    height: number;
    rx?: number;
    ry?: number;
};

/**
 * 矩形のノード。
 *
 * Reactコンポーネントにしていないのは、コンポーネントとしてマウント後にref経由で参照しなくても
 * リンクの開始位置が計算できるようにするため。
 */
export class RectShape implements INodeShape {
    private readonly offsetToCenter: { x: number; y: number };
    private readonly width: number;
    private readonly height: number;
    private readonly rx: number;
    private readonly ry: number;

    constructor({ width, height, rx, ry }: RectShapeAttributes) {
        this.width = width;
        this.height = height;
        this.rx = rx || 4;
        this.ry = ry || 4;
        this.offsetToCenter = {
            x: this.width / 2,
            y: this.height / 2,
        };
    }

    /**
     * 矩形の基準位置(矩形の左上)から矩形の中心までの相対座標を返す
     *
     * (横幅、高さの半分だけオフセットした値)
     */
    public getCenterPoint(): Victor {
        return Victor.fromObject(this.offsetToCenter);
    }

    public getBorderPoint(direction: Victor, offset = 0): Victor {
        // SVG 座標だと角度が通常と反対になるので分かりやすく修正
        const angle = (-direction.angleDeg() + 360) % 360;
        offset = offset ? offset : 0;
        const height = this.height;
        const width = this.width;
        const topRightAngle = (Math.atan2(height, width) / Math.PI) * 180;

        if ((0 <= angle && angle < topRightAngle) || (360 - topRightAngle <= angle && angle < 360)) {
            return Victor.fromObject({
                x: +width / 2 + offset,
                y: -(width / 2 + offset) * Math.tan((angle / 180) * Math.PI),
            });
        } else if (topRightAngle <= angle && angle < 180 - topRightAngle) {
            return Victor.fromObject({
                x: +(height / 2 + offset) / Math.tan((angle / 180) * Math.PI),
                y: -height / 2 - offset,
            });
        } else if (180 - topRightAngle <= angle && angle < 180 + topRightAngle) {
            return Victor.fromObject({
                x: -width / 2 - offset,
                y: +(width / 2 + offset) * Math.tan((angle / 180) * Math.PI),
            });
        } else {
            return Victor.fromObject({
                x: -(height / 2 + offset) / Math.tan((angle / 180) * Math.PI),
                y: +height / 2 + offset,
            });
        }
    }

    render({ className, ...props }: { className?: string } & React.SVGProps<SVGRectElement>): React.ReactNode {
        return (
            <rect className={className} width={this.width} height={this.height} rx={this.rx} ry={this.ry} {...props} />
        );
    }

    /**
     * 現在の RectShape を基準にサイズを変更します。
     * すべてのパラメータは optional で、サイズを明示的に指定しない場合には、
     * 同じ大きさの新しい RectShape を作成して返します。
     *
     *  * width, height を明示的に指定した場合には、そのサイズの RectShape を返します
     *  * width, height を指定せず、 dWidth, dHeight を指定した場合には、現在のサイズに対して増減したサイズの RectShape を返します
     *
     *  例1)
     *    shape.resize({ dWidth: 32, dHeight: 32 })  // -> 縦横ともに32pxずつ大きな RectShape を返します
     *  例2)
     *    shape.resize({ height: 64 }) // -> 横幅は変更することなく、高さを 64px に変更した RectShape を返します
     *
     * @param width {number}
     * @param height {number}
     * @param rx {number}
     * @param ry {number}
     * @param dWidth {number}
     * @param dHeight {number}
     * @param margin {number} 縦横を margin * 2 だけ大きくする
     */
    resize({
        width,
        height,
        rx,
        ry,
        dWidth = 0,
        dHeight = 0,
        margin = 0,
    }: {
        width?: number;
        height?: number;
        rx?: number;
        ry?: number;
        dWidth?: number;
        dHeight?: number;
        margin?: number;
    }): RectShape {
        return new RectShape({
            width: width || this.width + dWidth + margin * 2,
            height: height || this.height + dHeight + margin * 2,
            rx: rx || this.rx,
            ry: ry || this.ry,
        });
    }

    isEqual(other: INodeShape): boolean {
        return (
            other instanceof RectShape &&
            this.width === other.width &&
            this.height === other.height &&
            this.rx === other.rx &&
            this.ry === other.ry
        );
    }

    getRect(): Rect {
        return Rect.fromLTRB(0, 0, this.width, this.height);
    }
}

import { useCallback } from 'react';
import { DBPath, usePersistedText } from '@framework/repository';
import { UpdateCommand, useCommandManager } from '@view-model/models/framework/command';
import { DescriptionPanelFontSize } from '../domain';

type DescriptionPanelFontSizeCommand = {
    fontSize: DescriptionPanelFontSize;
    onFontSizeChange: (currentFontSize: DescriptionPanelFontSize) => void;
};

export const useDescriptionPanelFontSizeCommand = (path: DBPath): DescriptionPanelFontSizeCommand => {
    const commandManager = useCommandManager();
    const [_fontSize, setFontSize, repository] = usePersistedText(path);
    const fontSize = DescriptionPanelFontSize.load(_fontSize || null);

    const onFontSizeChange = useCallback(
        (currentFontSize: DescriptionPanelFontSize) => {
            if (fontSize === currentFontSize) return;

            setFontSize(currentFontSize);
            const command = new UpdateCommand(fontSize, currentFontSize, repository);
            commandManager.execute(command);
        },
        [commandManager, repository, fontSize, setFontSize]
    );

    return { fontSize, onFontSizeChange };
};

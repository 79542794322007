import { getClientId } from '@framework/app';
import { EditingUser } from './domain/EditingUser';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';
import { useMemo } from 'react';

export const useEditingUserFromAuthContext = (): EditingUser | null => {
    const currentUserProfile = useCurrentUserPublicProfile();
    const editingUser = useMemo(() => {
        if (!currentUserProfile) return null;

        return EditingUser.buildFromProfile(currentUserProfile, getClientId());
    }, [currentUserProfile]);

    return editingUser;
};

import { SignUpMethodName } from './SignUpMethodName';
import { GroupJSON } from '@schema-app/groups/{groupKey}/GroupJSON';

type SignUpMethodRecord = Record<SignUpMethodName, boolean>;

const defaultAvailableSignUpMethods: SignUpMethodRecord = {
    google: true,
    quadcept: true,
    email: true,
};

export class GroupMemberSignUpMethod {
    constructor(private readonly values: SignUpMethodRecord) {}

    dump(): GroupJSON['setting']['availableSignUpMethods'] {
        return { ...this.values };
    }

    static load(dump: GroupJSON['setting']['availableSignUpMethods']): GroupMemberSignUpMethod {
        return new this({ ...dump });
    }

    static buildNew(): GroupMemberSignUpMethod {
        return new this(defaultAvailableSignUpMethods);
    }

    availableOf(methodName: SignUpMethodName): boolean {
        return this.values[methodName];
    }

    with(methodName: SignUpMethodName, value: boolean): GroupMemberSignUpMethod {
        return new GroupMemberSignUpMethod({
            ...this.values,
            [methodName]: value,
        });
    }

    /**
     * 新規ユーザ登録時に利用可能なログイン方法が 0個 のときに true を返す
     * @returns
     */
    isEmpty(): boolean {
        return Object.values(this.values).filter((value) => value).length === 0;
    }

    /**
     * 新規ユーザ登録時に利用が制限されているログイン方法が1個以上存在するときに true を返す
     * @returns
     */
    someRestricted(): boolean {
        return Object.values(this.values).filter((value) => !value).length > 0;
    }

    /**
     * 新規ユーザ登録時に利用可能なログイン方法が1個だけの場合には、そのログイン方法を返す。
     * 複数のログイン方法が有効になっている場合には、 null を返す。
     * @returns
     */
    getSingleAvailableMethod(): SignUpMethodName | null {
        const methods = SignUpMethodName.values().filter((name) => this.values[name]);
        return methods.length == 1 ? methods[0] : null;
    }
}

import { clsx, ClassValue } from 'clsx';

// パラメータ付きで利用可能な色の名前
const variableColors = [
    // デフォルトカラーパレットの名前
    // https://v1.tailwindcss.com/docs/customizing-colors#default-color-palette
    'gray',
    'red',
    'orange',
    'yellow',
    'green',
    'teal',
    'blue',
    'indigo',
    'purple',
    'pink',
    // 独自拡張の brand
    'brand',
];
// パラメータなしで利用可能な色指定の名前
const colorNames = [
    // デフォルトカラーパレットで、 black, white はバリエーションなし
    'black',
    'white',
    // カラーパレットに無い色指定として、 transparent, current を指定可能。
    'transparent',
    'current',
    // 独自拡張の brand
    'brand',
];

/**
 * tailwindの同じ役割を持ったクラス名が重複している場合に、
 * 重複を排除したクラス名文字列を返すためのユーティリティ関数。
 *
 * 役割の重複するクラスが複数含まれる場合には、後に指定されたパラメータが採用されます。
 *
 * @param classes class名のリスト (文字列、真偽値、配列、オブジェクトを指定可能)
 */
export const classNames = (...classes: ClassValue[]): string => {
    // 全体のクラス名を作成
    const classlist = clsx(...classes).split(' ');

    // 重複しているクラス名を削除する
    const result = classlist.reduce((result, c) => {
        // 一部の色の名前は、末尾に可変部分が付与されていないため、別ルールで処理する。
        // 'text-white' は 'text-color-' のような文字列を生成する。
        if (colorNames.some((colorName) => c.endsWith(`-${colorName}`))) {
            const prefix = c.replace(new RegExp(`${colorNames.join('|')}`), 'color-'); // "color"に置き換える
            return { ...result, [prefix]: c };
        }

        // 'text-blue-500' から 'text-color-' のような文字列を生成する
        const prefix = c
            .substring(0, c.lastIndexOf('-') + 1 || c.length) // 可変部分を削除
            .replace(new RegExp(`${variableColors.join('|')}`), 'color'); // "color"に置き換える

        return { ...result, [prefix]: c };
    }, {});

    // クラス名を再生成
    return Object.values(result).join(' ');
};

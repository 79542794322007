export class GroupName {
    constructor(private readonly value: string) {
        this.value = value;
    }

    toString(): string {
        return `${this.value}`;
    }

    toLowerCase(): string {
        return this.value.toLowerCase();
    }

    dump(): string {
        return this.toString();
    }

    static load(value: string): GroupName {
        return new GroupName(value);
    }

    compareTo(other: GroupName): number {
        const name = this.value.toLowerCase();
        const otherName = other.value.toLowerCase();
        return name.localeCompare(otherName);
    }
}

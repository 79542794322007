import { ViewKey } from '@view-model/domain/key';
import { CommandHelper, CommandManager, CreateCommand } from '@model-framework/command';
import { ConsistencyLink } from '@view-model/domain/ConsistencyLink';
import { ObjectRepository, RTDBPath } from '@framework/repository';
import { ConsistencyLinkId, ViewModelId } from '@schema-common/base/id';

export class ConsistencyLinksOperation {
    static handleCreate(params: {
        fromViewKey: ViewKey;
        toViewKey: ViewKey;
        viewModelId: ViewModelId;
        commandManager: CommandManager;
    }): void {
        const consistencyLink = ConsistencyLink.buildNew(params.fromViewKey, params.toViewKey);

        const repo = new ObjectRepository(
            ConsistencyLink,
            RTDBPath.ConsistencyLink.linkPath(params.viewModelId, consistencyLink.id)
        );
        const command = new CreateCommand(consistencyLink, repo);
        params.commandManager.execute(command);
    }

    static async handleDelete(params: {
        id: ConsistencyLinkId;
        viewModelId: ViewModelId;
        commandManager: CommandManager;
    }): Promise<void> {
        const command = await CommandHelper.buildDeleteCommand(
            RTDBPath.ConsistencyLink.linkPath(params.viewModelId, params.id)
        );
        if (command) {
            params.commandManager.execute(command);
        }
    }
}

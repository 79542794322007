import { useDroppable } from '@dnd-kit/core';
import { Data } from '@dnd-kit/core/dist/store';

type Props = {
    id: string;
    disabled?: boolean;
    data?: Data;
    children: React.ReactNode;
    hoveredClassName?: string;
};

export const Droppable: React.FC<Props> = ({ id, disabled, data, children, hoveredClassName }: Props) => {
    const { active, setNodeRef, isOver } = useDroppable({ id, disabled, data });

    return (
        <div ref={setNodeRef} className={active && isOver ? hoveredClassName : undefined}>
            {children}
        </div>
    );
};

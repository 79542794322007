import { useCallback } from 'react';
import { ViewId } from '@schema-common/base';
import { toast } from 'react-hot-toast';
import copy from 'copy-to-clipboard';

type Handler = () => void;

export const useCopyViewUrl = (viewId: ViewId): Handler => {
    return useCallback(() => {
        const query = new URLSearchParams();

        query.set('targetView', viewId);
        const url = `${window.location.origin}${window.location.pathname}?${query.toString()}`;

        copy(url);
        toast('コピーしました | Copied');
    }, [viewId]);
};

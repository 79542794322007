import { useMemo } from 'react';
import { useGetBookmarkedViewModelIdsContextValues } from '@group/hooks';
import { useSidebarTreeContext } from './SidebarTreeContext';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { ViewModelItem } from './ViewModelItem';
import { WorkspaceSetting } from '@workspace/domain/workspace';

export const SidebarBookmarks: React.FC = () => {
    const { bookmarkedViewModelIds } = useGetBookmarkedViewModelIdsContextValues();
    const {
        records: { viewModels, workspaces },
    } = useSidebarTreeContext();

    const sortedBookmarkedViewModels = useMemo(
        () =>
            Object.values(viewModels)
                .filter((vm) => bookmarkedViewModelIds.has(vm.id))
                .sort(ViewModelEntity.compare),
        [bookmarkedViewModelIds, viewModels]
    );

    if (sortedBookmarkedViewModels.length <= 0) {
        return (
            <div className="mx-2 mt-4">
                ビューモデルの画面上部の星印をクリックするとブックマークとして追加できます。
            </div>
        );
    }

    return (
        <>
            {sortedBookmarkedViewModels.map((vm) => (
                <ViewModelItem
                    key={vm.id}
                    viewModel={vm}
                    workspaceSetting={workspaces[vm.workspaceId]?.setting || WorkspaceSetting.buildDefault()}
                />
            ))}
        </>
    );
};

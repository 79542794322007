import { ModelContentsCollection, StickyModelContents, StickyModel } from '@view-model/domain/model';
import { StickyModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/StickyModelCascadeRepository';
import { ModelRepository } from '../ModelRepository';
import { ModelId, ViewModelId } from '@schema-common/base';

export class ModelCascadeRepository {
    private readonly repository: ModelRepository;

    constructor(private readonly viewModelId: ViewModelId) {
        this.repository = new ModelRepository(this.viewModelId);
    }

    /**
     * ModelContents全体 (ModelEntity自身と、その子供たち全て) を保存する
     *
     * @param modelContents
     */
    async saveModelContents(modelContents: StickyModelContents): Promise<void> {
        await Promise.all([this.repository.save(modelContents.modelEntity()), this.saveContents(modelContents)]);
    }

    /**
     * 指定した key の ModelEntity とその子供たちを全て取得する
     * @param modelId
     */
    async load(modelId: ModelId): Promise<StickyModelContents | undefined> {
        const model = await this.repository.load(modelId);
        if (!model) return;
        return this.loadContents(model);
    }

    /**
     * コレクション中のModelEntity自身のプロパティとその子供たちを全て保存する
     * @param models
     */
    async saveContentsCollection(models: ModelContentsCollection): Promise<void> {
        await this.repository.saveCollection(models.modelCollection());
        await Promise.all(models.list().map((model) => this.saveContents(model)));
    }

    async loadContentsCollection(): Promise<ModelContentsCollection> {
        const models = await this.repository.loadCollection();
        const loadedModels = await Promise.all(models.list().map((model) => this.loadContents(model)));
        return new ModelContentsCollection(loadedModels);
    }

    async loadContents(model: StickyModel): Promise<StickyModelContents> {
        const repository = new StickyModelCascadeRepository(this.viewModelId);
        return repository.loadContents(model);
    }

    /**
     * ModelEntityの中に持っている他の子要素を全て保存する
     *
     * @param modelContents
     */
    async saveContents(modelContents: StickyModelContents): Promise<void> {
        const repository = new StickyModelCascadeRepository(this.viewModelId);
        return modelContents.saveTo(repository);
    }

    async delete(modelId: ModelId): Promise<void> {
        return this.repository.delete(modelId);
    }
}

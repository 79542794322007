import { Direction, Point, Rect } from '@view-model/models/common/basic';

/**
 * 矩形の辺によって分割された領域（三目並べの盤面のような分割）を表すオブジェクト。
 * 矩形と点の位置関係検出に利用する。
 */
export class EdgeDividedArea {
    private constructor(
        private readonly left: number,
        private readonly top: number,
        private readonly right: number,
        private readonly bottom: number
    ) {}

    static fromRect(rect: Rect): EdgeDividedArea {
        return new EdgeDividedArea(rect.getLeft(), rect.getTop(), rect.getRight(), rect.getBottom());
    }

    /**
     * 点が領域内のどの方角にあるのかを返す。
     * 点が内側に真ん中の領域だった場合は None を返します。
     *
     *                  │      │
     *        Up | Left │  Up  │   Up | Right
     *    ──────────────┼──────┼──────────────
     *             Left │ None │        Right
     *    ──────────────┼──────┼──────────────
     *      Down | Left │ Down │ Down | Right
     *                  │      │
     *
     * @param point
     */
    directionOf(point: Point): Direction {
        const { left, top, right, bottom } = this;

        let direction = Direction.None;

        // 横方向のどの位置にあるか
        if (point.x < left) direction |= Direction.Left;
        if (point.x > right) direction |= Direction.Right;

        // 縦方向のどの位置にあるか
        if (point.y < top) direction |= Direction.Up;
        if (point.y > bottom) direction |= Direction.Down;

        return direction;
    }
}

import { PointerEvent, useCallback, useState } from 'react';
import { ViewModelList } from '@workspace/view-model-folder/ui/ViewModelList';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { EmptyMessage } from '@workspace/view-model-folder/ui/FolderItem/EmptyMessage';
import { MenuButtons } from './MenuButtons';
import { FolderEditModal } from '@workspace/view-model-folder/ui/FolderEditModal';
import { FolderDeleteModal } from '@workspace/view-model-folder/ui/FolderDeleteModal';
import { FolderContainer } from '@workspace/view-model-folder/domain/FolderContainer';
import { ViewModelCreateModal } from '../ViewModelCreateModal';
import {
    useCreateViewModelFromClipboardPayloadHandler,
    useDeleteFolder,
    useUpdateFolder,
} from '@workspace/view-model-folder/hooks';
import { Folder } from '../../domain/Folder';
import { ViewModelId } from '@schema-common/base';
import { ViewModelOperation } from '@view-model/domain/view-model';
import { useActionLogSender } from '@framework/action-log';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faFolder, faFolderOpen, faSpinner } from '@fortawesome/free-solid-svg-icons';

type Props = {
    folderContainer: FolderContainer;
    isOpen: boolean;
    isDuplicating: boolean;
    showEditMenu: boolean;
    workspace: Workspace;
    onClick(): void;
    onDuplicateFolder: (folder: Folder, childViewModelIds: ViewModelId[]) => void;
};

type ModalTarget = 'Edit' | 'Delete' | 'AddViewModel';

export const FolderItemContent: React.FC<Props> = ({
    folderContainer,
    isOpen,
    isDuplicating,
    showEditMenu,
    workspace,
    onClick,
    onDuplicateFolder,
}: Props) => {
    // @dnd-kit の useDraggable は pointerDown イベントをリスンして、ドラッグ処理を行なっている。
    // 編集メニューに対するクリック時の pointerDown イベントがドラッグとして処理されないように、
    // イベントのバブリングを防止する。
    const handlePointerDown = (e: PointerEvent<HTMLDivElement>) => e.stopPropagation();
    const folder = folderContainer.folder;
    const childViewModels = folderContainer.childViewModels;
    const showFolderEditMenu = showEditMenu && !folderContainer.filtered();
    const updateFolder = useUpdateFolder(folder);
    const deleteFolder = useDeleteFolder(workspace.id, folder);

    const actionLogSender = useActionLogSender();

    const [modalTarget, setModalTarget] = useState<ModalTarget | null>(null);

    const handleEdit = () => setModalTarget('Edit');
    const handleDelete = () => setModalTarget('Delete');
    const handleAddViewModel = () => setModalTarget('AddViewModel');
    const handleModalClose = () => setModalTarget(null);

    const createViewModelFromClipboardPayload = useCreateViewModelFromClipboardPayloadHandler(
        workspace.ownerGroupId,
        workspace.id,
        folder.id
    );

    const handlePaste = () => createViewModelFromClipboardPayload();

    const handleAddViewModelSubmit = useCallback(
        async (name: string) => {
            await ViewModelOperation.create(workspace.id, folder.id, name, actionLogSender);
            if (!isOpen) onClick();
        },
        [workspace.id, folder.id, actionLogSender, isOpen, onClick]
    );

    const handleDuplicateDirectory = () => {
        onDuplicateFolder(folderContainer.folder, folderContainer.childViewModels.allIds());
    };

    return (
        <div className="px-2">
            <div className="mb-2 flex h-12 w-full items-center rounded hover:bg-gray-200">
                <div className="flex h-full w-16 cursor-pointer items-center rounded-l text-center" onClick={onClick}>
                    <span className="pr-2 text-2xl text-gray-700">
                        <FontAwesomeIcon icon={isOpen ? faCaretDown : faCaretRight} />
                    </span>
                    <span className="text-3xl text-brand">
                        <FontAwesomeIcon icon={isOpen ? faFolderOpen : faFolder} />
                    </span>
                </div>
                <div className="flex-1 cursor-pointer truncate px-2 text-xl font-bold" onClick={onClick}>
                    {folderContainer.folderName}
                </div>

                {isDuplicating && (
                    <div>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin text-lg text-gray-700" />
                        <span className="pr-2 text-lg text-gray-700">フォルダを複製中です...</span>
                    </div>
                )}

                <div className="px-2" onPointerDown={handlePointerDown}>
                    {showFolderEditMenu && (
                        <MenuButtons
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            onAddViewModel={handleAddViewModel}
                            onPasteViewModel={handlePaste}
                            onDuplicateDirectory={handleDuplicateDirectory}
                        />
                    )}
                </div>
            </div>

            {isOpen && (
                <div className="ml-8 pb-2">
                    {childViewModels.count() === 0 ? (
                        <EmptyMessage />
                    ) : (
                        <ViewModelList viewModels={childViewModels} showEditMenu={showEditMenu} workspace={workspace} />
                    )}
                </div>
            )}

            <FolderEditModal
                folder={folder}
                isOpen={modalTarget === 'Edit'}
                onSubmit={updateFolder}
                onClose={handleModalClose}
            />

            <FolderDeleteModal
                folder={folder}
                isOpen={modalTarget === 'Delete'}
                onClose={handleModalClose}
                onSubmit={deleteFolder}
                hasChildren={folderContainer.hasChildren()}
            />

            <ViewModelCreateModal
                isOpen={modalTarget === 'AddViewModel'}
                onSubmit={handleAddViewModelSubmit}
                onClose={handleModalClose}
            />
        </div>
    );
};

import { RecordRepository } from './RecordRepository';
import { RefBuilder, Reference } from '../RefBuilder';
import { DBPath } from '@framework/repository';

// 保存対象の要素オブジェクト
interface ItemDumpable<DumpType> {
    dump(): DumpType;
}

// 保存対象の要素オブジェクトのクラス (static method のインタフェース定義)
interface ItemLoadable<DumpType, T extends ItemDumpable<DumpType>> {
    load(dump: DumpType): T;
}

// 保存対象のMapオブジェクト
interface MapDumpable<
    ValueDumpType,
    ValueType extends ItemDumpable<ValueDumpType>,
    MapDumpType extends Record<string, ValueDumpType>,
> {
    dump(): MapDumpType;
    toArray(): ValueType[];
}

// 保存対象のMapオブジェクトのクラス (static method のインタフェース定義)
interface MapLoadable<
    ValueDumpType,
    ValueType extends ItemDumpable<ValueDumpType>,
    MapDumpType extends Record<string, ValueDumpType>,
    MapType extends MapDumpable<ValueDumpType, ValueType, MapDumpType>,
> {
    load(dump: MapDumpType): MapType;
}

export class MapRepository<
    ValueDumpType,
    ValueType extends ItemDumpable<ValueDumpType>,
    MapDumpType extends Record<string, ValueDumpType>,
    MapType extends MapDumpable<ValueDumpType, ValueType, MapDumpType>,
> {
    private readonly recordRepo: RecordRepository<ValueDumpType, ValueType>;

    /**
     * 指定パス配下の値リストを操作する Repository を返します。
     * 値の集合は引数で指定された mapClass で表現されます。
     *
     * オブジェクト集合を Record で表現する場合は、 RecordRepository を使用してください。
     *
     * @param valueClass
     * @param mapClass
     * @param path
     */
    constructor(
        private readonly valueClass: ItemLoadable<ValueDumpType, ValueType>,
        private readonly mapClass: MapLoadable<ValueDumpType, ValueType, MapDumpType, MapType>,
        private readonly path: DBPath
    ) {
        this.recordRepo = new RecordRepository(this.valueClass, this.path);
    }

    private ref(): Reference {
        return RefBuilder.ref(this.path);
    }

    /**
     * 指定パス配下の値を引数に渡された map の内容で置き換えます。
     * 既存のデータがあれば削除します。
     * @param map
     */
    async save(map: MapType): Promise<void> {
        const ref = this.ref();
        await ref.set(map.dump());
    }

    /**
     * 指定パス配下に、引数で渡された map の内容を追記します。
     * 既存のデータは削除しません。
     * @param map
     */
    async saveItems(map: MapType): Promise<void> {
        const ref = this.ref();
        await ref.update(map.dump());
    }

    async delete(): Promise<void> {
        const ref = this.ref();
        await ref.remove();
    }

    async get(): Promise<MapType | null> {
        const ref = this.ref();
        const snapshot = await ref.once('value');
        const value = snapshot.val() as MapDumpType;
        if (!value) return null;

        return this.mapClass.load(value);
    }

    addListener(
        onAdded: (value: ValueType) => void,
        onChanged: (value: ValueType) => void,
        onRemoved: (value: ValueType) => void
    ): void {
        this.recordRepo.addListener(onAdded, onChanged, onRemoved);
    }

    removeListener(): void {
        this.recordRepo.removeListener();
    }
}

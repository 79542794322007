import { useMemo } from 'react';
import { UserPublicProfile, useUserPublicProfiles } from '@user/PublicProfile';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { WorkspaceMemberRole } from '@workspace/domain/workspace/vo/WorkspaceMemberRole';
import { WorkspaceMemberRoles } from '@workspace/domain/workspace/vo/WorkspaceMemberRoles';

type ItemProps = {
    profile: UserPublicProfile;
};
const MemberItem: React.FC<ItemProps> = ({ profile }: ItemProps) => {
    return (
        <li className="flex flex-row p-1">
            <UserIcon key={profile.id} {...profile} size="medium" />
            <span className="flex h-6 w-56 truncate px-2 leading-6">{profile.name}</span>
        </li>
    );
};

type Props = {
    role: WorkspaceMemberRole;
    memberRoles: WorkspaceMemberRoles;
};

export const RoleMemberList: React.FC<Props> = ({ role, memberRoles }: Props) => {
    const roleName = WorkspaceMemberRole.getLocalString(role);
    const userIds = useMemo(() => memberRoles.userIdsByRole(role), [memberRoles, role]);
    const profiles = useUserPublicProfiles(userIds);

    return (
        profiles && (
            <div className="mt-2">
                <div className="border-l-4 border-gray-500 pl-2">{roleName}</div>
                <ul>
                    {profiles.map((profile) => (
                        <MemberItem key={profile.id} profile={profile} />
                    ))}
                </ul>
            </div>
        )
    );
};

import { NodeStyle } from '@view-model/models/sticky/StickyNodeView';
import { ThemeColor } from '@view-model/models/common/color';
import { StickyZoneStyle } from '@view-model/models/sticky/StickyZoneView';
import { LinkStyle } from '@view-model/models/sticky/StickyLink';
import { LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';

/**
 * モデル要素の新規作成時に適用するスタイルを管理、提供します。
 *
 * スタイルの変更イベントを受け取り、それを元に次回の新規作成時のスタイルを返します。
 */
export class NewElementStyleProvider {
    private _nodeStyle: NodeStyle;
    private _zoneStyle: StickyZoneStyle;
    private _linkStyle: LinkStyle;

    constructor() {
        this._nodeStyle = NodeStyle.buildNew();
        this._zoneStyle = StickyZoneStyle.buildNew();
        this._linkStyle = LinkStyle.buildNew();
    }

    /**
     * ノードの色変更が起きたことをこのインスタンスに知らせて次回のスタイルに反映されるようにする。
     * @param color
     */
    nodeColorChanged(color: ThemeColor): void {
        this._nodeStyle = this._nodeStyle.withThemeColor(color);
    }

    /**
     * ノードスタイルを返す。
     */
    nodeStyle(): NodeStyle {
        return this._nodeStyle;
    }

    /**
     * ゾーンの色変更が起きたことをこのインスタンスに知らせて次回のスタイルに反映されるようにする。
     * @param color
     */
    zoneColorChanged(color: ThemeColor): void {
        this._zoneStyle = this._zoneStyle.withThemeColor(color);
    }

    /**
     * ゾーンスタイルを返す。
     */
    zoneStyle(): StickyZoneStyle {
        return this._zoneStyle;
    }

    /**
     * リンクの色変更が起きたことをこのインスタンスに知らせて次回のスタイルに反映されるようにする。
     */
    linkColorChanged(color: LinkColor): void {
        this._linkStyle = this._linkStyle.withColor(color);
    }

    /**
     * リンクの線スタイル変更が起きたことをこのインスタンスに知らせて次回のスタイルに反映されるようにする。
     */
    linkLineStyleChanged(lineStyle: LinkLineStyle): void {
        this._linkStyle = this._linkStyle.withLineStyle(lineStyle);
    }

    /**
     * リンクの矢印マーク変更が起きたことをこのインスタンスに知らせて次回のスタイルに反映されるようにする。
     */
    linkMarkStyleChanged(markStyle: LinkMarkStyle): void {
        this._linkStyle = this._linkStyle.withMarkStyle(markStyle);
    }

    /**
     * リンクのスタイルを返す
     */
    linkStyle(): LinkStyle {
        return this._linkStyle;
    }
}

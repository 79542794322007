import { useCallback } from 'react';
import { Input } from '@framework/ui/atoms/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

type Props = {
    value: string;
    onChange(value: string): void;
    onClose(): void;
    className?: string;
};

export const SearchTextBox: React.FC<Props> = ({ value, onChange, onClose, className }: Props) => {
    const handleBlur = () => {
        if (value === '') onClose();
    };
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        },
        [onChange]
    );

    return (
        <div className={['relative', className].join(' ')}>
            <Input
                type="text"
                placeholder="ビューモデル名で検索"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus
            />
            {value !== '' && (
                <div onClick={onClose} className="absolute inset-y-0 right-0 mr-2 flex cursor-pointer items-center">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </div>
            )}
        </div>
    );
};

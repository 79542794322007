import { useCallback } from 'react';
import { useActionLogSender } from '@framework/action-log';
import { Folder } from '../domain/Folder';
import { useRootFolderTreeRepository } from './useRootFolderTreeRepository';
import { RefBuilder, RTDBPath } from '@framework/repository';

type Handler = () => Promise<void>;

export const useDeleteFolder = (workspaceId: string, folder: Folder): Handler => {
    const rootFolderTreeRepository = useRootFolderTreeRepository(workspaceId);
    const actionLogSender = useActionLogSender();

    return useCallback(async () => {
        const tree = await rootFolderTreeRepository.load();

        if (tree.hasChildrenOf(folder.id)) {
            throw new Error(`The folder (id=${folder.id}) cannot delete because it contains content.`);
        }

        await RefBuilder.ref(RTDBPath.Workspace.folderPath(folder.id)).set(null);

        await rootFolderTreeRepository.removeFolder(folder.id);

        actionLogSender('view_model_folder:delete', {
            folderId: folder.id,
        });
    }, [rootFolderTreeRepository, folder.id, actionLogSender]);
};

import { ICommand } from '@model-framework/command';
import { StickyZone } from '../domain';
import { StickyZoneEntityOperation } from '../adapter';
import { PositionSet, PositionSetCreateCommand } from '@view-model/models/common/PositionSet';
import { RTDBPath } from '@framework/repository';
import { DisplayOrderRepository } from '@view-model/models/framework/display-order';
import { StickyModel } from '@view-model/domain/model';
import { ViewModelId } from '@schema-common/base';

export class StickyZoneCreateCommand implements ICommand {
    private readonly command: PositionSetCreateCommand;

    constructor(
        viewModelId: ViewModelId,
        private readonly model: StickyModel,
        private readonly entityOperation: StickyZoneEntityOperation,
        private readonly zone: StickyZone,
        zonePositionSet: PositionSet,
        private readonly displayOrderRepository: DisplayOrderRepository
    ) {
        this.command = new PositionSetCreateCommand(
            zonePositionSet,
            RTDBPath.Zone.positionsPath(viewModelId, model.key.id)
        );
    }

    do(): void {
        this.entityOperation.save(this.zone);
        this.displayOrderRepository.addZone(this.zone.id).then();
        this.command.do();
    }

    undo(): void {
        this.entityOperation.remove(this.zone.key);
        this.displayOrderRepository.removeZone(this.zone.id).then();
        this.command.undo();
    }

    redo(): void {
        this.do();
    }

    async canUndo(): Promise<boolean> {
        const zone = StickyZoneEntityOperation.getZone(this.zone.key, this.model);
        // ゾーンに対してノードを関連付ける等の操作がコマンドに含まれるようになったら、 isDeepEqual() に変更する
        return !!zone && this.zone.isEqual(zone) && (await this.command.canUndo());
    }

    async canRedo(): Promise<boolean> {
        return !StickyZoneEntityOperation.getZone(this.zone.key, this.model) && (await this.command.canRedo());
    }
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '@framework/utils';

// サイズは、 tailwindcss の font-size に対応させる
// https://v1.tailwindcss.com/docs/font-size
type Size = 'sm' | 'base' | 'lg';
const SizeStyles: Record<Size, string> = {
    sm: 'px-2 py-1 text-sm',
    base: 'px-4 py-2 text-base',
    lg: 'px-6 py-2 text-lg',
};

type Color = 'black' | 'brand';
const ColorStyles: Record<Color, string> = {
    black: 'bg-white text-black hover:bg-gray-200 border-gray-500',
    brand: 'bg-white text-brand hover:bg-gray-200 border-brand',
};

type Props = {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    color?: Color;
    size?: Size;
    onClick?(): void;
};

export const FlatButton: React.FC<Props> = ({
    children,
    className,
    disabled,
    loading,
    color = 'black',
    size = 'base',
    onClick,
}: Props) => {
    return (
        <button
            className={classNames(
                'rounded-sm border',
                SizeStyles[size],
                ColorStyles[color],
                disabled && 'cursor-not-allowed',
                className
            )}
            disabled={disabled}
            onClick={onClick}
        >
            {loading && (
                <span className={classNames(size === 'sm' ? 'pr-1' : 'pr-2')}>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                </span>
            )}
            {children}
        </button>
    );
};

import { useCallback, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { useGroupMemberUserIds } from '@group/hooks';
import { WorkspaceInternalPublicMemberRoleJSON } from '@schema-common/workspace';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { Workspace, WorkspaceSetting } from '@workspace/domain/workspace';
import { difference } from 'lodash';

export type InternalPublicSetting = WorkspaceInternalPublicMemberRoleJSON | null;
const internalPublicSettingOptions: Record<WorkspaceInternalPublicMemberRoleJSON, string> = {
    editor: '変更可能 (編集者)',
    viewer: '閲覧のみ (閲覧者)',
};

type Props = {
    onClose(): void;
    workspace: Workspace;
    internalPublicSetting: InternalPublicSetting;
};

export const ShareSettingEditModal: React.FC<Props> = ({
    onClose,
    workspace,
    internalPublicSetting: initialInternalPublicSetting,
}: Props) => {
    const groupMemberUserIds = useGroupMemberUserIds(workspace.ownerGroupId);
    const addingMemberIds = groupMemberUserIds ? difference(groupMemberUserIds, workspace.userIds()) : [];

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    // グループ内公開設定
    const [internalPublicSetting, setInternalPublicSetting] =
        useState<InternalPublicSetting>(initialInternalPublicSetting);
    const handlePrivateWorkspace = useCallback(() => setInternalPublicSetting(null), []);
    const handleInternalPublicWorkspace = useCallback(() => setInternalPublicSetting('editor'), []);
    const handleChangeInternalSetting = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (value in internalPublicSettingOptions) {
            setInternalPublicSetting(value as InternalPublicSetting);
        }
    }, []);

    // 外部公開設定
    const [isPublicSpace, setIsPublicSpace] = useState<boolean>(workspace.setting.isPublicSpace);
    const handleChangePublicSpace = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPublicSpace(event.target.checked);
    }, []);
    const [isViewModelURLShareable, setIsViewModelURLShareable] = useState<boolean>(
        workspace.setting.isViewModelURLShareable
    );
    const handleChangeViewModelURLShareable = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsViewModelURLShareable(event.target.checked);
    }, []);

    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);
        setErrorMessage('');

        const results = await Promise.all([
            WorkspaceOperation.updateSetting(
                workspace.id,
                WorkspaceSetting.load({ isPublicSpace, isViewModelURLShareable })
            ),
            WorkspaceOperation.updateInternalPublicSetting(workspace.id, internalPublicSetting),
        ]);

        if (results.every((value) => value)) {
            onClose();
        } else {
            setIsSubmitting(false);
            setErrorMessage('ワークスペースの更新に失敗しました');
        }
    }, [internalPublicSetting, isPublicSpace, isViewModelURLShareable, onClose, workspace.id]);

    return (
        <GeneralModal
            isOpen
            onClose={onClose}
            submitButton={
                <Button color="primary" onClick={handleSubmit} loading={isSubmitting}>
                    保存
                </Button>
            }
            cancelButton={
                <Button color="secondary" onClick={onClose} disabled={isSubmitting}>
                    キャンセル
                </Button>
            }
            title={'ワークスペース公開設定の変更'}
        >
            <div className="overflow-y-auto p-4" style={{ width: '600px', maxHeight: '70vh' }}>
                <div className="py-2 font-bold">グループ内公開設定</div>

                <div className="py-2">
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            name="internal-setting"
                            className="form-radio mr-1 size-6 border-brand"
                            checked={internalPublicSetting === null}
                            onChange={handlePrivateWorkspace}
                            disabled={isSubmitting}
                        />
                        プライベートワークスペース
                    </label>
                    <span className="ml-8 py-1 text-sm">ワークスペースにアクセス可能なユーザを個別に設定します。</span>

                    {initialInternalPublicSetting !== null && internalPublicSetting === null && (
                        <div className="ml-8 mt-2 rounded border border-yellow-700 bg-yellow-200 p-2 text-sm">
                            ワークスペースがグループ内公開からプライベートに変更されました。
                            <br />
                            全ユーザにアクセス権限が付与されていますので、メンバー一覧から確認/更新してください。
                        </div>
                    )}
                </div>

                <div className="py-2">
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            name="internal-setting"
                            className="form-radio mr-1 size-6 border-brand"
                            checked={internalPublicSetting !== null}
                            onChange={handleInternalPublicWorkspace}
                            disabled={isSubmitting}
                        />
                        グループ内公開ワークスペース
                    </label>
                    <span className="ml-8 py-1 text-sm">グループのユーザは全員アクセス可能にします。</span>

                    <div className="ml-8">
                        <select
                            className="form-select block w-64 py-1"
                            defaultValue={internalPublicSetting || ''} // defaultValue に null を指定できないため空文字列に fallback する
                            onChange={handleChangeInternalSetting}
                            disabled={isSubmitting || internalPublicSetting == null}
                        >
                            {Object.entries(internalPublicSettingOptions).map(([value, label]) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>

                    {internalPublicSetting !== null && addingMemberIds.length > 0 && (
                        <div className="ml-8 mt-2 rounded border border-yellow-700 bg-yellow-200 p-2 text-sm">
                            現時点でワークスペースに参加していない<b className="px-1">{addingMemberIds.length}人</b>
                            にアクセス権限が付与されます。
                        </div>
                    )}

                    {initialInternalPublicSetting !== null &&
                        internalPublicSetting !== null &&
                        initialInternalPublicSetting != internalPublicSetting && (
                            <div className="ml-8 mt-2 rounded border border-yellow-700 bg-yellow-200 p-2 text-sm">
                                アクセス権限が変更されました。
                                <br />
                                グループ内公開ワークスペースのアクセス権限の変更は、今後グループに参加するユーザに対して適用されます。すでにワークスペースに参加しているユーザのアクセス権限については、メンバー一覧から確認/更新してください。
                            </div>
                        )}
                </div>

                <div className="py-2 font-bold">外部公開設定</div>

                <div className="py-2">
                    <label className="block cursor-pointer">
                        <input
                            type="checkbox"
                            className="form-checkbox mr-1 size-6 border-brand"
                            checked={isPublicSpace}
                            onChange={handleChangePublicSpace}
                            disabled={isSubmitting}
                        />
                        ワークスペースを外部公開する
                    </label>

                    {!workspace.setting.isPublicSpace && isPublicSpace && (
                        <div className="ml-8 mt-2 rounded border border-yellow-700 bg-yellow-200 p-2 text-sm">
                            ワークスペースが外部公開に変更されました。
                            <br />
                            リンクを知っている人なら誰でもこの画面にアクセスできるようになります。
                        </div>
                    )}
                </div>

                <div className="py-2">
                    <label className="block cursor-pointer">
                        <input
                            type="checkbox"
                            className="form-checkbox mr-1 size-6 border-brand"
                            checked={isViewModelURLShareable}
                            onChange={handleChangeViewModelURLShareable}
                            disabled={isSubmitting}
                        />
                        ビューモデルごとの公開設定を利用する
                    </label>
                </div>

                {errorMessage && <div className="py-2 text-red-500">{errorMessage}</div>}
            </div>
        </GeneralModal>
    );
};

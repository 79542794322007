import { ClipboardPayload } from '@model-framework/clipboard';

const Target = 'Any'; // 情報として不要だがClipboardPayloadの互換性のためセット
const ContentType = 'tsv';

export type TSVClipboardPayload = ClipboardPayload & {
    target: typeof Target;
    contentType: typeof ContentType;
    data: string[][];
};

export namespace TSVClipboardPayload {
    export function fromData(data: string[][]): TSVClipboardPayload {
        return {
            target: Target,
            contentType: ContentType,
            data,
        };
    }
}

export const isTSVClipboardPayload = (payload: ClipboardPayload): payload is TSVClipboardPayload => {
    return payload.contentType === ContentType;
};

import { useCallback, useEffect, useState } from 'react';
import { SidebarTreeRecords, SidebarTreeContextValues } from '../SidebarTree';

type Return = {
    searchKeyword: string;
    isSearchMode: boolean;
    handleSearch: (keyword: string) => void;
};

export const useSidebarSearch = (
    { workspaces, folderTrees, folders, viewModels }: SidebarTreeRecords,
    dispatch: SidebarTreeContextValues['dispatch']
): Return => {
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    const handleSearch = useCallback((value: string) => {
        setSearchKeyword(value);
    }, []);

    useEffect(() => {
        if (!searchKeyword) {
            dispatch({ type: 'REMOVE_SEARCH_RESULT' });
            return;
        }

        dispatch({
            type: 'SEARCH',
            payload: {
                keyword: searchKeyword,
            },
        });
    }, [searchKeyword, workspaces, folderTrees, dispatch, folders, viewModels]);

    return {
        searchKeyword,
        isSearchMode: !!searchKeyword,
        handleSearch,
    };
};

type Props = {
    width: number;
    withTopDivider?: boolean;
    children: React.ReactNode;
};

export const ItemContainer: React.FC<Props> = ({ width, withTopDivider, children }: Props) => {
    return (
        <div className="px-[14px] py-2 text-3xl" style={{ width: width }}>
            {withTopDivider && <div className="mb-2 border-2 border-solid border-gray-400" />}
            {children}
        </div>
    );
};

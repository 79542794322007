export class StickyZoneText {
    readonly value: string;

    constructor(value: string) {
        // 改行や空白のみの入力を防ぐ
        if (value.match(/\S/g)) {
            this.value = value;
        } else {
            this.value = '';
        }
    }

    trim(): StickyZoneText {
        return new StickyZoneText(this.value.trim());
    }

    static load(dump: string): StickyZoneText {
        return new this(dump);
    }

    dump(): string {
        return this.value;
    }

    isEqual(other: StickyZoneText): boolean {
        return other instanceof StickyZoneText && this.value === other.value;
    }
}

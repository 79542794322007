import { useCallback, useEffect, useState } from 'react';
import { Multiplicity } from '../../domain';
import { Point, Rect } from '@view-model/models/common/basic';
import { Size } from '@view-model/models/common/types/ui';
import { LinkMultiplicityMenu } from '../LinkMultiplicityMenu';
import { MultiplicityTextParser } from '@view-model/models/common/parser/MultiplicityTextParser';
import { MultiplicityTextValidator } from '@view-model/models/common/validator/MultiplicityTextValidator';
import { LinkTextView } from '@model-framework/link';
import { usePopupRef } from '@framework/hooks';
import { WarningPopup } from '@model-framework/ui/WarningPopup';

type Props = {
    bezierPoint: Point;
    editable: boolean;
    multiplicity: Multiplicity;
    isLinkSelected: boolean;
    onUpdateMultiplicity: (multiplicity: Multiplicity) => void;
    onToggleMenu: (visible: boolean) => void;
};

export const LinkMultiplicityView: React.FC<Props> = ({
    bezierPoint,
    editable,
    multiplicity,
    isLinkSelected,
    onUpdateMultiplicity,
    onToggleMenu,
}: Props) => {
    const [position, setPosition] = useState<Point>(new Point(0, 0));
    const [rectSize, setRectSize] = useState<Size>({ width: 0, height: 0 });
    const [parentRect, setParentRect] = useState<Rect>(Rect.fromSize(0, 0));
    const [text, setText] = useState<string>(multiplicity?.toString());
    const [showWarningPopup, setShowWarningPopup] = useState<boolean>(false);
    const { isOpen, popupRef, handleOpen } = usePopupRef<SVGGElement>(false);

    const updatePosition = useCallback(
        (size: Size) => {
            const offset = new Point(size.width / 2, size.height / 2);
            setPosition(bezierPoint.subtract(offset));
        },
        [bezierPoint]
    );

    useEffect(() => {
        setText(multiplicity.toString());
    }, [multiplicity]);

    useEffect(() => {
        updatePosition(rectSize);
    }, [rectSize, updatePosition]);

    useEffect(() => {
        onToggleMenu(isOpen);
    }, [isOpen, onToggleMenu]);

    const handleSizeChanged = (size: Size) => {
        setRectSize(size);
        setParentRect(new Rect(position, rectSize));
        updatePosition(size);
    };

    const handleTextChanged = (text: string) => {
        setShowWarningPopup(!MultiplicityTextValidator.isValid(text));
        setText(text);
    };

    const handleUpdateMultiplicity = (multiplicity: Multiplicity) => {
        setText(multiplicity.toString());
        setShowWarningPopup(false);
        onUpdateMultiplicity(multiplicity);
    };

    const handleEditStart = () => {
        handleOpen();
    };

    const handleEditEnd = (text: string) => {
        const newMultiplicity = MultiplicityTextParser.parse(text);
        if (newMultiplicity === null) {
            setText(multiplicity.toString());
            setShowWarningPopup(false);
            return;
        }
        setText(newMultiplicity.toString());
        onUpdateMultiplicity(newMultiplicity);
    };

    const menuPosition = position.addX(rectSize.width / 2);

    return (
        <g ref={popupRef}>
            <g transform={position.toSVGTranslate()}>
                <LinkTextView
                    text={text}
                    showLabel={false}
                    editable={editable}
                    otherUserEditing={false} // 他のユーザーが編集中の場合でも多重度の修正は許可
                    onChange={handleTextChanged}
                    onStartEdit={handleEditStart}
                    onEndEdit={handleEditEnd}
                    onSizeChange={handleSizeChanged}
                    minWidth={50}
                />
                {showWarningPopup && (
                    <WarningPopup placement={'top'} parentRect={parentRect}>
                        入力候補を参考に入力してください
                    </WarningPopup>
                )}
            </g>
            {isOpen && isLinkSelected && (
                <g transform={menuPosition.toSVGTranslate()}>
                    <LinkMultiplicityMenu multiplicity={multiplicity} onUpdateMultiplicity={handleUpdateMultiplicity} />
                </g>
            )}
        </g>
    );
};

import { FolderMap } from './FolderMap';
import { ViewModelId, FolderId } from '@schema-common/base';

type ParentFolderId = FolderId;

type ViewModelIdMap = Record<ViewModelId, ParentFolderId>;
type FolderIdMap = Record<FolderId, ParentFolderId>;

export type FolderTreeJSON = {
    id: FolderId;
    folderIdMap?: FolderIdMap;
    viewModelIdMap?: ViewModelIdMap;
};

export class FolderTree {
    private readonly viewModelIds: Readonly<ViewModelId[]>;
    private readonly childFolderIds: Readonly<FolderId[]>;
    private readonly childFolderTrees: Readonly<FolderTree[]>;

    /**
     * ビューモデルとそれを格納するフォルダの関係をツリーとして表現するオブジェクト。
     * あるフォルダは、その配下にビューモデルとフォルダを保持することができて、再帰的な階層関係を持っている。
     * FolderTree ではそれらの識別子(id)のみを保持しており、それらの実体は外部で管理する。
     *
     * @param id
     * @param viewModelIds
     * @param folderTrees
     */
    constructor(
        public readonly id: FolderId,
        viewModelIds: ViewModelId[],
        folderTrees: FolderTree[]
    ) {
        this.viewModelIds = Array.from(new Set(viewModelIds));

        const childFolders = folderTrees.reduce(
            (result, folderTree) => {
                result[folderTree.id] = folderTree;
                return result;
            },
            {} as Record<FolderId, FolderTree>
        );
        this.childFolderIds = Object.keys(childFolders);
        this.childFolderTrees = Object.values(childFolders);
    }

    static build(id: FolderId): FolderTree {
        return new FolderTree(id, [], []);
    }

    /**
     * フォルダ、ビューモデルの階層関係を表現したフラットなJSONから、階層的な FolderTree を生成して返す
     *
     * @param dump {FolderTreeJSON}
     */
    static load(dump: FolderTreeJSON): FolderTree {
        const { id, folderIdMap, viewModelIdMap } = dump;
        const folderMap = new FolderMap(folderIdMap || {}, viewModelIdMap || {});

        return this.fromFolderMap(id, folderMap);
    }

    /**
     * フラットなデータ構造のデータ操作用オブジェクト(FolderMap)から、
     * フォルダ階層に対応したツリーオブジェクト(FolderTree)に変換します。
     *
     * @param id
     * @param folderMap
     * @private
     */
    private static fromFolderMap(id: FolderId, folderMap: FolderMap): FolderTree {
        const viewModelIds = folderMap.getViewModelIdsOf(id);
        const childFolderIds = folderMap.getFolderIdsOf(id);
        // 子孫のフォルダIDについては、それぞれフラットな FolderMap から FolderTree に再起的に変換する
        const childFolders = childFolderIds.map((id) => this.fromFolderMap(id, folderMap));

        return new FolderTree(id, viewModelIds, childFolders);
    }

    /**
     * フォルダ、ビューモデルの階層関係を表現したフラットなJSONデータを返す
     */
    dump(): FolderTreeJSON {
        const id = this.id;

        const folderIdMap: FolderTreeJSON['folderIdMap'] = {};
        const viewModelIdMap: FolderTreeJSON['viewModelIdMap'] = {};

        this.flatten().forEach((folder) => {
            folder.childFolderIds.forEach((id) => {
                folderIdMap[id] = folder.id;
            });
            folder.viewModelIds.forEach((id) => {
                viewModelIdMap[id] = folder.id;
            });
        });

        return { id, folderIdMap, viewModelIdMap };
    }

    /**
     * 自身と全ての子孫の FolderTree をフラットな配列に変換する
     * @private
     */
    private flatten(): FolderTree[] {
        const result: FolderTree[] = [this];
        this.childFolderTrees.forEach((tree) => result.push(...tree.flatten()));
        return result;
    }

    /**
     * このフォルダが直接保持しているビューモデルの識別子一覧を返す
     */
    getViewModelIds(): ViewModelId[] {
        return this.viewModelIds.concat();
    }

    /**
     * このフォルダが直接保持しているフォルダの識別子一覧を返す
     */
    getFolderIds(): FolderId[] {
        return this.childFolderIds.concat();
    }

    /**
     * 指定の folderId をこのフォルダが直接保持しているときに true を返す
     * @param folderId
     * @private
     */
    private hasChildFolder(folderId: FolderId): boolean {
        return this.childFolderIds.includes(folderId);
    }

    /**
     * 指定の viewModelId をこのフォルダが直接保持しているときに true を返す
     * @param viewModelId
     * @private
     */
    private hasChildViewModel(viewModelId: ViewModelId): boolean {
        return this.viewModelIds.includes(viewModelId);
    }

    /**
     * 指定の folderId がこのフォルダ配下に含まれているときに true を返す
     * @param folderId
     * @private
     */
    private hasFolder(folderId: FolderId): boolean {
        if (this.hasChildFolder(folderId)) {
            return true;
        }

        return this.childFolderTrees.some((child) => child.hasFolder(folderId));
    }

    /**
     * 指定の viewModelId がこのフォルダ配下に含まれているときに true を返す
     * @param viewModelId
     * @private
     */
    private hasViewModel(viewModelId: ViewModelId): boolean {
        if (this.hasChildViewModel(viewModelId)) {
            return true;
        }

        return this.childFolderTrees.some((child) => child.hasViewModel(viewModelId));
    }

    /**
     * 指定の id がこのフォルダツリー配下に含まれているときに true を返す
     * @param id
     */
    has(id: FolderId | ViewModelId): boolean {
        return this.id === id || this.hasViewModel(id) || this.hasFolder(id);
    }

    isEmpty(): boolean {
        return this.viewModelIds.length === 0 && this.childFolderIds.length === 0;
    }

    hasChildren(): boolean {
        return !this.isEmpty();
    }

    /**
     * 指定した folderId の FolderTree を返す。
     * (自身のフォルダIDを指定された場合には、自身のオブジェクトを返す)
     *
     * @param folderId
     */
    findFolder(folderId: FolderId): FolderTree | undefined {
        if (this.id === folderId) {
            return this;
        }

        for (const child of this.childFolderTrees) {
            const found = child.findFolder(folderId);
            if (found) {
                return found;
            }
        }
    }

    /**
     * 指定した viewModelId を持つ ViewModel が属する FolderTree を返す。
     *
     * @param viewModelId
     */
    findFolderOfViewModel(viewModelId: ViewModelId): FolderTree | undefined {
        if (this.viewModelIds.includes(viewModelId)) return this;

        for (const child of this.childFolderTrees) {
            const found = child.findFolderOfViewModel(viewModelId);
            if (found) {
                return found;
            }
        }
    }

    /**
     * このフォルダの子孫に位置するフォルダIDを全て列挙して返す
     */
    descendantsFolderIds(): FolderId[] {
        const ids = this.childFolderIds.concat();
        this.childFolderTrees.forEach((tree) => ids.push(...tree.descendantsFolderIds()));
        return ids;
    }

    /**
     * このフォルダの子孫に位置するビューモデルIDを全て列挙して返す
     */
    descendantsViewModelIds(): ViewModelId[] {
        const ids = this.viewModelIds.concat();
        this.childFolderTrees.forEach((tree) => ids.push(...tree.descendantsViewModelIds()));
        return ids;
    }
}

import { WorkspacePage } from '@user/pages/WorkspacePage';
import '../../App.css';
import { useParams } from 'react-router-dom';
import { useCurrentUserId } from '@framework/auth';
import { NotFoundPage } from '@user/pages/NotFoundPage';
import { ActionLogEventParamsContextProvider } from '@framework/action-log';
import { FullScreenLoading } from '@framework/ui';
import { useWorkspaceEntity } from '@workspace/application/hooks/useWorkspaceEntity';

export const WorkspaceRoutes: React.FC = () => {
    const currentUserId = useCurrentUserId();
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const { workspace, loading } = useWorkspaceEntity(workspaceId);

    if (loading) return <FullScreenLoading />;
    if (workspace === null) return <NotFoundPage />;

    // ワークスペースを表示可能なのは、公開ワークスペースに設定されているか、ユーザがワークスペースに所属している場合。
    const memberRole = currentUserId && workspace.getMemberRoleOf(currentUserId);
    const isViewable = workspace.isPublicSpace || !!memberRole;

    if (!isViewable) return <NotFoundPage />;

    return (
        <ActionLogEventParamsContextProvider workspaceId={workspace.id} workspaceName={workspace.name.toString()}>
            <WorkspacePage workspace={workspace} />
        </ActionLogEventParamsContextProvider>
    );
};

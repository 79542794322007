import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

type Props = {
    onClick(): void;
    className?: string;
};

export const SearchButton: React.FC<Props> = ({ onClick, className }: Props) => {
    return (
        <div
            className={`cursor-pointer rounded border border-solid border-brand p-2 font-bold text-brand hover:bg-gray-200 ${className}`}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faSearch} />
        </div>
    );
};

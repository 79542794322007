import { NodeKey } from '@view-model/domain/key';
import { NodeName, NodePosition } from './vo';
import { NodeStyle } from './NodeStyle';
import { StickyNode } from './StickyNode';
import { EditingUserRepository } from '@model-framework/text/editing-user';
import { NodeCollection } from './NodeCollection';
import { Id } from '@framework/domain';
import { CommandHelper, CompositeCommand, ICommand } from '@model-framework/command';
import { ModelId, NodeId, ViewModelId } from '@schema-common/base';
import { StickyNodeJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/nodes/{nodeId}/StickyNodeJSON';
import { DataSnapshot, RefBuilder, Reference, RTDBPath } from '@framework/repository';
import { ClientSelectedItemRepository } from '@model-framework/client-selected-item';

export class NodeRepository {
    private readonly editingUserRepository: EditingUserRepository;
    private readonly clientSelectedItemRepository: ClientSelectedItemRepository;

    public constructor(
        private readonly viewModelId: ViewModelId,
        private readonly modelId: ModelId
    ) {
        this.editingUserRepository = new EditingUserRepository(viewModelId);
        this.clientSelectedItemRepository = new ClientSelectedItemRepository(viewModelId, modelId);
    }

    private nodesRef(): Reference {
        return RefBuilder.ref(RTDBPath.Node.nodesPath(this.viewModelId, this.modelId));
    }

    private nodeRef(nodeId: NodeId): Reference {
        return RefBuilder.ref(RTDBPath.Node.nodePath(this.viewModelId, this.modelId, nodeId));
    }

    public async saveNode(node: StickyNode): Promise<StickyNode> {
        const nodes = await this.saveNodes([node]);
        return nodes[0];
    }

    async saveNodeName(nodeId: NodeId, name: NodeName): Promise<void> {
        return RefBuilder.ref(RTDBPath.Node.nodeNamePath(this.viewModelId, this.modelId, nodeId)).set(name.value);
    }

    public async saveNodePositions(nodePositions: [NodeKey, NodePosition][]): Promise<void> {
        if (nodePositions.length === 0) return;

        const updates: Record<string, StickyNodeJSON['position']> = {};
        nodePositions.forEach(([key, position]) => {
            updates[`${key.id}/position`] = position.dump();
        });

        await this.nodesRef().update(updates);
    }

    async getNodePositions(keys: NodeKey[]): Promise<[NodeKey, NodePosition][]> {
        if (keys.length === 0) return [];
        const nodes = new NodeCollection(await this.loadNodes());
        return nodes.filterByKeys(keys).map((node: StickyNode) => [node.key, node.position]);
    }

    async saveStyles(styles: Record<Id, NodeStyle>): Promise<void> {
        if (Object.keys(styles).length === 0) return;

        const updates: Record<string, StickyNodeJSON['style']> = {};
        Object.entries(styles).forEach(([id, style]) => {
            updates[`${id}/style`] = style.dump();
        });

        await this.nodesRef().update(updates);
    }

    async getStyle(id: NodeId): Promise<NodeStyle | null> {
        const ref = this.nodeRef(id).child('style');
        const snapshot = await ref.once('value');
        const j = snapshot.val() as StickyNodeJSON['style'];
        if (!j) return null;

        return NodeStyle.load(j);
    }

    public saveNodes(nodes: StickyNode[]): Promise<StickyNode[]> {
        const updates: Record<string, StickyNodeJSON> = {};
        nodes.forEach((node) => {
            updates[node.id] = node.dump();
        });

        return this.nodesRef()
            .update(updates)
            .then(() => nodes);
    }

    public async saveNodeCollection(nodes: NodeCollection): Promise<void> {
        await this.saveNodes(nodes.entities());
    }

    public async loadNodes(): Promise<StickyNode[]> {
        const snapshot = await this.nodesRef().once('value');
        const nodes = (snapshot.val() as Record<NodeId, StickyNodeJSON>) || {};

        return NodeCollection.load(Object.values(nodes)).entities();
    }

    public deleteNode(key: NodeKey): Promise<void> {
        return this.deleteNodes([key]);
    }

    public async deleteNodes(keys: NodeKey[]): Promise<void> {
        if (keys.length == 0) return;

        const updates: Record<string, null> = {};
        keys.forEach((key) => {
            updates[`${key.id}`] = null;
        });

        await this.nodesRef().update(updates);

        const itemIds = keys.map((key) => key.id);
        await Promise.all([
            this.clientSelectedItemRepository.deleteAllByItemIds(itemIds),
            this.editingUserRepository.deleteMulti(keys),
        ]);
    }

    onUpdateNode(nodeKey: NodeKey, callback: (node: StickyNode) => void): void {
        this.nodeRef(nodeKey.id).on('value', (snapshot) => {
            const value = snapshot.val() as StickyNodeJSON | null;
            if (!value) return;
            callback(StickyNode.load(value));
        });
    }

    offUpdateNode(nodeKey: NodeKey): void {
        this.nodeRef(nodeKey.id).off('value');
    }

    public onUpdateNodes(callback: (nodes: StickyNode[]) => void): () => void {
        const onValue = async (snapshot: DataSnapshot) => {
            const nodes = NodeCollection.load(
                Object.values((snapshot.val() ?? {}) as Record<NodeId, StickyNodeJSON>)
            ).entities();

            callback(nodes);
        };
        const ref = this.nodesRef();
        ref.on('value', onValue);

        return () => {
            ref.off('value', onValue);
        };
    }

    async buildDeleteCommand(nodeId: NodeId): Promise<ICommand | null> {
        const commands = await Promise.all([
            await CommandHelper.buildDeleteCommand(RTDBPath.Node.nodePath(this.viewModelId, this.modelId, nodeId)),
            await CommandHelper.buildDeleteCommand(
                RTDBPath.Node.descriptionPath(this.viewModelId, this.modelId, nodeId)
            ),
        ]);

        return CompositeCommand.composeOptionalCommands(...commands);
    }
}

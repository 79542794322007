import { Container, injectable } from 'inversify';
import 'reflect-metadata';
import { nanoid } from 'nanoid';
import { ClientId } from '@schema-common/base';

/**
 * ブラウザのタブ単位（同一ユーザーでも区別する）をクライアントとして表すクラス。
 *
 * ユニークなID生成とinversifyのシングルトンスコープを合わせて個別のクライアント識別を実現する。
 * https://github.com/inversify/InversifyJS/blob/master/wiki/scope.md
 */
@injectable()
class Client {
    public readonly id: ClientId;

    constructor() {
        this.id = nanoid();
    }
}

/*
 * inversify によるシングルトン化
 *
 * このファイルで実装を閉じるため、@framework/container のDIコンテナ/シンボル定義を利用しない。
 * （依存性のあるDIインジェクションのためのコンテナ/シンボル管理は不要なため）
 */
const container = new Container();

const TYPES = {
    Client: Symbol.for('Client'),
};

container.bind<Client>(TYPES.Client).to(Client).inSingletonScope();

/**
 * クライアントIDを取得する。
 *
 * @see Client
 */
export function getClientId(): ClientId {
    return container.get<Client>(TYPES.Client).id;
}

import { Icon } from '../../atoms/Icon';
import { toast, Toaster, ToastBar, ToastType } from 'react-hot-toast';

type ToastStyle = {
    background: string;
    countDownBackground: string;
    titleColor: string;
    closeColor: string;
};

const toastStyles: Record<ToastType, ToastStyle> = {
    // blank: 以前利用していた react-toast-notifications の info 相当
    blank: {
        background: '#e5fcff',
        countDownBackground: '#0097a7',
        titleColor: '#006670',
        closeColor: '#00c4d6',
    },
    success: {
        background: '#ebffe5',
        countDownBackground: '#b2ff9e',
        titleColor: '#0b3300',
        closeColor: '#2ec600',
    },
    // custom: 以前利用していた react-toast-notifications の warning 相当
    custom: {
        background: '#feffe5',
        countDownBackground: '#fbff12',
        titleColor: '#6e7000',
        closeColor: '#a5a800',
    },
    error: {
        background: '#ffe5ee',
        countDownBackground: '#ff206e',
        titleColor: '#ea0052',
        closeColor: '#ff518e',
    },
    loading: {
        background: '#e5fcff',
        countDownBackground: '#0097a7',
        titleColor: '#006670',
        closeColor: '#00c4d6',
    },
};

export const Toast = () => (
    <Toaster
        position="bottom-left"
        containerStyle={{
            left: 24,
            bottom: 32,
        }}
        toastOptions={{ duration: 5000 }}
    >
        {(t) => (
            <>
                <style>
                    {`
                        @keyframes slidein {
                            0% { transform: translate3d(-120%, 0, 0); opacity: 0; }
                            100% { transform: translate3d(0,0,0); opacity: 1; }
                        }
                        @keyframes zoomout {
                            0% { transform: scale(1); opacity: 1; }
                            100% { transform: scale(0.66); opacity: 0; }
                        }
                    `}
                </style>
                <ToastBar
                    toast={t}
                    style={{
                        boxShadow: '0px 0px 16px rgba(140,140,140, 0.4)',
                        backgroundColor: toastStyles[t.type].background,
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '400px',
                        maxWidth: '800px',
                        height: '64px',
                        padding: '0 16px',
                        borderRadius: '4px',
                        position: 'relative',
                        opacity: t.visible ? 1 : 0,
                        animation: t.visible ? 'slidein 220ms ease' : 'zoomout 100ms ease',
                    }}
                >
                    {({ message }) => (
                        <>
                            <div className="flex flex-1 items-center justify-between">
                                <div style={{ color: toastStyles[t.type].titleColor }} className={'font-bold'}>
                                    {message}
                                </div>
                                <div className="ml-4 cursor-pointer" onClick={() => toast.dismiss(t.id)}>
                                    <Icon name="close" size="lg" color={toastStyles[t.type].closeColor} />
                                </div>
                            </div>
                            {t.duration != Infinity && (
                                <div
                                    style={{ backgroundColor: toastStyles[t.type].countDownBackground }}
                                    className={'absolute left-0 top-0 h-1 w-0 animate-shrink'}
                                />
                            )}
                        </>
                    )}
                </ToastBar>
            </>
        )}
    </Toaster>
);

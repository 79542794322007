import { ModelKey } from '@view-model/domain/key';
import { ViewModelName, ViewModelSharingUserRoleType } from '@view-model/domain/view-model';
import { Timestamp } from '@framework/Timestamp';
import { ModelCollection, StickyModel } from '@view-model/domain/model';
import { WorkspaceSetting } from '@workspace/domain/workspace/vo/WorkspaceSetting';
import { ViewModelJSON } from '@schema-app/view-model/entities/{viewModelId}/ViewModelJSON';
import { GroupId, ViewModelId, WorkspaceId } from '@schema-common/base';
import { Random } from '@framework/Random';
import { isEqual } from 'lodash';

interface IViewModel {
    id: ViewModelId;
    workspaceId: WorkspaceId;
    groupId: GroupId;
    name: ViewModelName;
    createdAt: Timestamp;
    updatedAt: Timestamp;
    trashedAt?: Timestamp | null;
    sharingUserRole: ViewModelSharingUserRoleType;
    isLocked: boolean;
}

export class ViewModelEntity {
    public readonly id: ViewModelId;
    public readonly workspaceId: WorkspaceId;
    public readonly groupId: GroupId;
    public readonly name: ViewModelName;
    private models: ModelCollection;
    public readonly createdAt: Timestamp;
    public readonly updatedAt: Timestamp;
    public readonly sharingUserRole: ViewModelSharingUserRoleType;
    public readonly isLocked: boolean;
    public readonly trashedAt: Timestamp | null;

    constructor(attributes: IViewModel) {
        this.id = attributes.id;
        this.workspaceId = attributes.workspaceId;
        this.groupId = attributes.groupId;
        this.name = attributes.name;
        this.createdAt = attributes.createdAt;
        this.updatedAt = attributes.updatedAt;
        this.trashedAt = attributes.trashedAt || null;
        this.sharingUserRole = attributes.sharingUserRole;
        this.isLocked = attributes.isLocked;
        this.models = new ModelCollection();
    }

    static buildNew({
        groupId,
        workspaceId,
        name,
    }: {
        groupId: GroupId;
        workspaceId: WorkspaceId;
        name: string;
    }): ViewModelEntity {
        return new ViewModelEntity({
            id: Random.generateRandomID(30),
            workspaceId,
            groupId,
            name: new ViewModelName(name),
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            sharingUserRole: ViewModelSharingUserRoleType.None,
            isLocked: false,
        });
    }

    static load(dump: ViewModelJSON): ViewModelEntity {
        const { id, workspaceId, groupId, name, createdAt, trashedAt, updatedAt, sharingUserRole, isLocked } = dump;

        return new ViewModelEntity({
            id,
            workspaceId,
            groupId,
            isLocked,
            name: ViewModelName.load(name),
            createdAt: new Timestamp(createdAt),
            updatedAt: new Timestamp(updatedAt),
            trashedAt: trashedAt ? new Timestamp(trashedAt) : null,
            sharingUserRole: ViewModelSharingUserRoleType.load(sharingUserRole),
        });
    }

    dump(): ViewModelJSON {
        return {
            id: this.id,
            workspaceId: this.workspaceId,
            groupId: this.groupId,
            name: this.name.dump(),
            createdAt: this.createdAt.toUnixTimestamp(),
            updatedAt: this.updatedAt.toUnixTimestamp(),
            trashedAt: this.trashedAt?.toUnixTimestamp() || null,
            sharingUserRole: ViewModelSharingUserRoleType.dump(
                this.sharingUserRole
            ) as ViewModelJSON['sharingUserRole'],
            isLocked: this.isLocked,
        };
    }

    isTrashed(): boolean {
        return !!this.trashedAt;
    }

    getModels(): StickyModel[] {
        return this.models.list();
    }

    getModelCollection(): ModelCollection {
        return this.models;
    }

    addModel(model: StickyModel): ModelCollection {
        this.models = this.models.add(model);
        return this.models;
    }

    removeModel(modelKey: ModelKey): ModelCollection {
        this.models = this.models.remove(modelKey);
        return this.models;
    }

    updateModel(model: StickyModel): ModelCollection {
        this.models = this.models.remove(model.key).add(model);
        return this.models;
    }

    resetModels(): ModelCollection {
        this.models = new ModelCollection();
        return this.models;
    }

    private cloneWith(updates: {
        name?: ViewModelName;
        sharingUserRole?: ViewModelSharingUserRoleType;
        trashedAt?: Timestamp | null;
        updatedAt?: Timestamp | null;
    }): ViewModelEntity {
        const { id, workspaceId, groupId, name, createdAt, trashedAt, updatedAt, sharingUserRole, isLocked } = this;

        return new ViewModelEntity({
            id,
            workspaceId,
            groupId,
            isLocked,
            name: updates.name || name,
            createdAt,
            updatedAt: updates.updatedAt || updatedAt,
            trashedAt: updates.trashedAt === null ? null : updates.trashedAt || trashedAt,
            sharingUserRole: updates.sharingUserRole || sharingUserRole,
        });
    }

    withName(name: ViewModelName): ViewModelEntity {
        return this.cloneWith({ name });
    }

    withTrashedAt(trashedAt: Timestamp | null): ViewModelEntity {
        return this.cloneWith({ trashedAt });
    }

    withSharingUserRole(sharingUserRole: ViewModelSharingUserRoleType): ViewModelEntity {
        return this.cloneWith({ sharingUserRole });
    }

    delete(): ViewModelEntity {
        return this.cloneWith({ trashedAt: Timestamp.now() });
    }

    restore(): ViewModelEntity {
        return this.cloneWith({ trashedAt: null });
    }

    isPublicReadable(setting: WorkspaceSetting): boolean {
        // 公開ワークスペースに属するビューモデルならば読み取り可能
        if (setting.isPublicSpace) {
            return true;
        }

        // ワークスペースのURL共有設定が有効、かつ、読み取り権限が付与されているか
        const hasPermission = ViewModelSharingUserRoleType.hasReadablePermission(this.sharingUserRole);
        return setting.isViewModelURLShareable && hasPermission;
    }

    static compare(a: ViewModelEntity, b: ViewModelEntity): number {
        return a.name.compareTo(b.name);
    }

    matchQuery(query: string): boolean {
        const keywords = query.split(' ');
        return this.name.includesMulti(keywords);
    }

    moveToOtherWorkspace(destinationWorkspaceId: WorkspaceId): ViewModelEntity {
        return ViewModelEntity.load({
            ...this.dump(),
            workspaceId: destinationWorkspaceId,
        });
    }

    /**
     * 引数に指定されたViewModelEntityと updatedAt 以外の値が一致する場合に true を返します。
     * @param other
     * @returns
     */
    isEqualExcludingUpdatedAt(other: ViewModelEntity): boolean {
        const { updatedAt: _selfUpdatedAt, ...selfDump } = this.dump();
        const { updatedAt: _otherUpdatedAt, ...otherDump } = other.dump();
        return isEqual(selfDump, otherDump);
    }
}

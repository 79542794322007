/**
 * 複数選択メニュー向けのユーティリティクラス。
 * スタイルなどのenum選択値をメニューで扱うためのロジックを提供する。
 */
export class MenuItemList<T> {
    constructor(private readonly values: T[]) {}

    /**
     * すべて同じ値であればその値を返し、それ以外の場合（空リストの場合含む）は undefined を返す。
     */
    compactAsSingleValue(): T | undefined {
        const { values } = this;
        return values.every((value) => value === values[0]) ? values[0] : undefined;
    }
}

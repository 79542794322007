import { RefObject } from 'react';
import { select } from 'd3-selection';
import { drag as Drag, DragBehavior, D3DragEvent } from 'd3-drag';

export class HandleD3Drag {
    constructor(
        private readonly ref: RefObject<Element>,
        private readonly enabled: boolean = true
    ) {}

    onMounted(
        onDrag: (event: D3DragEvent<Element, unknown, unknown>) => void,
        onDragStart?: (event: D3DragEvent<Element, unknown, unknown>) => void,
        onDragEnd?: (event: D3DragEvent<Element, unknown, unknown>) => void
    ): void {
        const drag: DragBehavior<Element, unknown, unknown> = Drag();
        drag.on('drag', (event: D3DragEvent<Element, unknown, unknown>) => {
            onDrag(event);
        }).filter(() => this.enabled);

        if (onDragStart) {
            drag.on('start', (event: D3DragEvent<Element, unknown, unknown>) => {
                onDragStart(event);
            }).filter(() => this.enabled);
        }

        if (onDragEnd) {
            drag.on('end', (event: D3DragEvent<Element, unknown, unknown>) => {
                onDragEnd(event);
            }).filter(() => this.enabled);
        }

        this.ref.current && select(this.ref.current).call(drag);
    }

    onWillUnmounted(): void {
        select(this.ref.current).on('.drag', null);
    }
}

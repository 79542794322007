import { useCurrentUserId } from '@framework/auth';
import { UserIconMenu } from './UserIconMenu';
import { HeaderLoginButton } from './HeaderLoginButton';

type Props = {
    hideLoginButton?: boolean;
    children?: React.ReactNode;
};

export const TOP_NAVIGATION_HEIGHT = 48;

export const TopNavigation: React.FC<Props> = ({ hideLoginButton, children }: Props) => {
    const currentUserId = useCurrentUserId();
    return (
        <div
            className="flex w-full items-center justify-between border-b-2 bg-white"
            style={{ height: TOP_NAVIGATION_HEIGHT }}
        >
            <div className="flex items-center justify-start">
                <div className="z-0 flex items-center justify-start px-2">{children}</div>
            </div>
            <div className="relative flex items-center justify-end p-2">
                {currentUserId ? <UserIconMenu userId={currentUserId} /> : !hideLoginButton && <HeaderLoginButton />}
            </div>
        </div>
    );
};

import { EulaFooter } from '@user/EulaFooter';

import { SidebarContainer } from './SidebarContainer';
import { SidebarContext, useSidebarValues } from './SidebarContext';

type Props = {
    children: React.ReactNode;
};

export const WithSidebar: React.FC<Props> = ({ children }: Props) => {
    const {
        isSidebarVisible,
        sidebarWidth,
        isSidebarOpenButtonVisible,
        toggleSidebar,
        startResizingSidebarWidth,
        resizingSidebarWidth,
        endResizingSidebarWidth,
    } = useSidebarValues();

    return (
        <SidebarContext.Provider value={{ isSidebarVisible, isSidebarOpenButtonVisible, sidebarWidth, toggleSidebar }}>
            <div className="flex flex-row">
                <div
                    style={{ width: isSidebarVisible ? sidebarWidth : '0px', transition: 'width 0.3s' }}
                    className="relative"
                >
                    {isSidebarVisible && <SidebarContainer handleSidebarOpen={toggleSidebar} />}
                    <div
                        className="absolute right-0 top-0 h-screen w-1 border-r-2 border-gray-300 hover:cursor-col-resize hover:border-gray-500"
                        onPointerDown={startResizingSidebarWidth}
                        onPointerMove={resizingSidebarWidth}
                        onPointerUp={endResizingSidebarWidth}
                    />
                </div>

                <div
                    style={{
                        width: `${isSidebarVisible ? `calc(100vw - ${sidebarWidth}px)` : '100vw'}`,
                        transition: 'width 0.3s',
                    }}
                >
                    {children}
                </div>
            </div>
            <EulaFooter />
        </SidebarContext.Provider>
    );
};

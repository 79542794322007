/**
 * リンクの色定義
 *
 * https://docs.google.com/presentation/d/1Zgs-08VJew22lAzIoHdslqIxMiLSjXxdBW0VVAqAnTY/edit#slide=id.g1152e1ce499_0_246
 */
import { ColorMenuItem } from '@model-framework/color/ColorMenu';

export type LinkColor = 'Gray' | 'Red' | 'Blue';

const ColorCodeOf: { [key in LinkColor]: string } = {
    Gray: '#aaaaaa',
    Red: '#e57373',
    Blue: '#64b5f6',
} as const;

export namespace LinkColor {
    export const Gray: LinkColor = 'Gray';
    export const Red: LinkColor = 'Red';
    export const Blue: LinkColor = 'Blue';

    export function colorCodeOf(linkColor: LinkColor): string {
        return ColorCodeOf[linkColor];
    }

    export function menuItems(): ColorMenuItem[] {
        return Object.entries(ColorCodeOf).map(([colorName, fillColor]) => ({
            colorName,
            fillColor,
        }));
    }
}

export function isLinkColor(colorName: string): colorName is LinkColor {
    return !!ColorCodeOf[colorName as LinkColor];
}

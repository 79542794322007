import { UserKey } from '@user/domain/UserKey';
import { HuddleMessageNotificationJSON } from '@schema-app/view-model/viewer-contents/{viewModelId}/huddle/{notificationId}/HuddleMessageNotificationJSON';
import { UserPublicProfile } from '@user/PublicProfile';
import { UserId } from '@schema-common/base';

export class HuddleUser {
    public constructor(
        public readonly key: UserKey,
        public readonly name: string
    ) {}

    get id(): UserId {
        return this.key.id.toString();
    }

    dump(): HuddleMessageNotificationJSON['payload']['caller'] {
        return {
            key: this.key.toString(),
            name: this.name.toString(),
        };
    }

    static load(dump: HuddleMessageNotificationJSON['payload']['caller']): HuddleUser {
        return new HuddleUser(new UserKey(dump.key), dump.name);
    }

    static buildFromUser(user: UserPublicProfile): HuddleUser {
        return new HuddleUser(UserKey.buildFromId(user.id), user.name);
    }
}

import { newDescriptionPanelId } from './DescriptionPanelId';
import { DescriptionPanelId, ModelId, ViewModelId } from '@schema-common/base';
import { Size } from '@view-model/models/common/basic';
import { ObjectRepository, RTDBPath } from '@framework/repository';
import { CreateCommand, ICommand } from '@model-framework/command';
import { Timestamp } from '@framework/Timestamp';
import { DescriptionPanelFontSize } from './DescriptionPanelFontSize';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { DescriptionPanelJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/description-panels/{descriptionPanelId}/DescriptionPanelJSON';
import { ViewModelAssetURL } from '@user/pages/urls';
import { MarkdownParser } from '@framework/markdown';
import { AssetUrlMap } from '@view-model/domain/view-model';

export class DescriptionPanel {
    static DefaultSize = new Size(ModelLayout.GridSize * 20, ModelLayout.GridSize * 12);
    static MinimumSize = new Size(ModelLayout.GridSize * 4, ModelLayout.GridSize * 4);

    constructor(
        public readonly id: DescriptionPanelId,
        public readonly description: string,
        public readonly size: Size,
        public readonly createdAt: Timestamp,
        public readonly fontSize: DescriptionPanelFontSize
    ) {
        this.fontSize = DescriptionPanelFontSize.load(fontSize);
    }

    dump(): DescriptionPanelJSON {
        const { id, description, size, createdAt, fontSize } = this;
        return {
            id,
            description,
            size: size.dump(),
            createdAt: createdAt.toUnixTimestamp(),
            fontSize: DescriptionPanelFontSize.dump(fontSize),
        };
    }

    static load(dump: DescriptionPanelJSON): DescriptionPanel {
        const { id, description, size, createdAt, fontSize } = dump;
        return new DescriptionPanel(
            id,
            description,
            Size.load(size),
            Timestamp.fromUnixTimestamp(createdAt),
            DescriptionPanelFontSize.load(fontSize)
        );
    }

    static buildNew(description?: string): DescriptionPanel {
        const id = newDescriptionPanelId();
        return new DescriptionPanel(
            id,
            description || '',
            DescriptionPanel.DefaultSize,
            Timestamp.now(),
            DescriptionPanelFontSize.S
        );
    }

    isEqual(other: DescriptionPanel): boolean {
        return (
            this.id === other.id &&
            this.description === other.description &&
            this.size.isEqual(other.size) &&
            this.fontSize == other.fontSize
        );
    }

    withDescription(description: string): DescriptionPanel {
        const { id, size, createdAt, fontSize } = this;
        return new DescriptionPanel(id, description, size, createdAt, fontSize);
    }

    cloneNew(newId: DescriptionPanelId, createdAt: Timestamp, assetUrlMap: AssetUrlMap): DescriptionPanel {
        const { description, size, fontSize } = this;
        return new DescriptionPanel(
            newId,
            MarkdownParser.replaceUrls(description, assetUrlMap),
            size,
            createdAt,
            fontSize
        );
    }

    withSize(size: Size): DescriptionPanel {
        const { id, description, createdAt, fontSize } = this;
        return new DescriptionPanel(id, description, size, createdAt, fontSize);
    }

    buildCreateCommand(viewModelId: ViewModelId, modelId: ModelId): ICommand {
        const repository = new ObjectRepository(
            DescriptionPanel,
            RTDBPath.DescriptionPanel.panelPath(viewModelId, modelId, this.id)
        );
        return new CreateCommand(this, repository);
    }

    /**
     * 説明パネルのマークダウンを解析して、埋め込まれた view-model-assets の URL 一覧を返します。
     */
    assetUrls(): string[] {
        const tokens = MarkdownParser.filterByUrl(
            (urlString) => !!ViewModelAssetURL.fromURLString(urlString),
            MarkdownParser.parse(this.description)
        );
        const urls = tokens.map((token) => token.url as string);
        return Array.from(new Set(urls));
    }
}

import { StickyZoneKey } from '@view-model/domain/key';
import { StickyZone } from '../domain';
import { StickyModel } from '@view-model/domain/model';
import { StickyZoneRepository } from '@view-model/models/sticky/StickyZoneView';

export class StickyZoneEntityOperation {
    constructor(
        private readonly model: StickyModel,
        private readonly repository: StickyZoneRepository
    ) {}

    static getZone(key: StickyZoneKey, model: StickyModel): StickyZone | null {
        return StickyZoneEntityOperation.findZone({ key, isTarget: (zone: StickyZone) => zone !== null, model });
    }

    static findZone({
        key,
        isTarget,
        model,
    }: {
        key: StickyZoneKey;
        isTarget: (zone: StickyZone) => boolean;
        model: StickyModel;
    }): StickyZone | null {
        const zone = model.findZone(key);
        if (zone && isTarget(zone)) return zone;
        return null;
    }

    static getZones(keys: StickyZoneKey[], model: StickyModel): StickyZone[] | null {
        return this.findZones({ keys, isTarget: (zone: StickyZone) => zone !== null, model });
    }

    static findZones({
        keys,
        isTarget,
        model,
    }: {
        keys: StickyZoneKey[];
        isTarget: (zone: StickyZone, index: number) => boolean;
        model: StickyModel;
    }): StickyZone[] | null {
        const zones: StickyZone[] = [];
        const foundAll = keys.every((key, i) => {
            const zone = model.findZone(key);
            if (zone && isTarget(zone, i)) {
                zones.push(zone);
                return true;
            }
            return false;
        });

        if (foundAll) return zones;
        return null;
    }

    save(zone: StickyZone): void {
        this.saveMulti([zone]);
    }

    remove(key: StickyZoneKey): void {
        this.removeMulti([key]);
    }

    saveMulti(zones: StickyZone[]): void {
        // ゾーンオブジェクトを新たに作成すると、並び順は未設定(Infinity)の状態で作成される
        // StickyZoneCollection に含めた際に、未設定の並び順のゾーンがあれば、前後関係から適切に採番が行われる
        // (DisplayOrder オブジェクトはミュータブルになっており、Collection側からの操作で値が変更される)
        //
        // 並び順未設定(Infinity)の状態では、 Realtime Database に保存できないため、
        // 先に Collection に追加して並び順を確定させた後に、Repository経由で永続化する
        this.model.zones.replace(this.model.zones.addZones(zones).models);
        this.repository.saveZones(zones).then();
    }

    removeMulti(keys: StickyZoneKey[]): void {
        this.model.zones.replace(this.model.zones.removeKeys(keys).models);
        this.repository.deleteZones(keys).then();
    }

    existsSome(keys: StickyZoneKey[]): boolean {
        return keys.some((key) => this.model.findZone(key));
    }
}

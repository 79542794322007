import { useCallback, useRef } from 'react';

/**
 * Undo可能なテキスト編集のためのコールバックアダプタフック
 * 編集完了時に編集開始時のテキストと一緒に現在のテキストを受け取ることができる。
 * @param editCallback
 * @param endEditCallback
 */
export const useUndoableTextEdit = (
    editCallback: (text: string) => void,
    endEditCallback: (initialText: string, currentText: string) => void
): {
    onStartEdit(text: string): void;
    onEdit(text: string): void;
    onEndEdit(): void;
} => {
    const startTextRef = useRef<string | null>(null);
    const currentTextRef = useRef<string | null>(null);

    const onStartEdit = useCallback((text: string) => {
        startTextRef.current = text;
        currentTextRef.current = text;
    }, []);

    const onEdit = useCallback(
        (text: string) => {
            currentTextRef.current = text;
            editCallback(text);
        },
        [editCallback]
    );

    const onEndEdit = useCallback(() => {
        const initialText = startTextRef.current;
        const currentText = currentTextRef.current;

        if (initialText == null || currentText === null) return;

        startTextRef.current = null;
        currentTextRef.current = null;

        endEditCallback(initialText, currentText);
    }, [endEditCallback]);

    return { onStartEdit, onEdit, onEndEdit };
};

import { useMemo } from 'react';
import { PopupTextView } from '@view-model/models/framework/ui';
import { Point, Rect, Size } from '@view-model/models/common/basic';

type MenuBoxProps = {
    menuSize: number;
    isSelected: boolean;
    onClick?(): void;
};

type MainMenuBoxProps = MenuBoxProps & {
    text: string;
};

export const MainMenuBox: React.FC<MainMenuBoxProps> = ({ menuSize, text, isSelected, onClick }: MainMenuBoxProps) => {
    const parentRect = useMemo(
        () => new Rect(new Point(menuSize / 2, menuSize / 2), new Size(menuSize, menuSize)),
        [menuSize]
    );
    return (
        <PopupTextView text={text} parentRect={parentRect} color="gray" placement="top">
            <rect
                className={isSelected ? 'fill-gray-500 hover:fill-gray-300' : 'fill-white hover:fill-gray-300'}
                cursor="pointer"
                width={menuSize}
                height={menuSize}
                onClick={onClick}
            />
        </PopupTextView>
    );
};

export const SubMenuBox: React.FC<MenuBoxProps> = ({ menuSize, isSelected, onClick }: MenuBoxProps) => {
    return (
        <rect
            cursor="pointer"
            className={isSelected ? 'fill-brand' : 'fill-white'}
            width={menuSize}
            height={menuSize}
            onClick={onClick}
        />
    );
};
